import { Platform } from 'react-native'
import { navigationRef } from '../services/navigation/NavService'
import { ViewMode } from '../constants/Enum'
import Layout from '../constants/Layout'
import { NON_AUTH_PATHS } from '../constants/Path'

export function isEditMode(route: any) {
  return (route.params as any)?.mode !== ViewMode.READ_ONLY
}

export function isReadOnlyMode(route: any) {
  return (route.params as any)?.mode === ViewMode.READ_ONLY
}

export function openAuthenAsync(navigation: any, redirectRoute?: string) {
  let currentRoute = (navigationRef as any).current?.getCurrentRoute()
  let currentRouteName = currentRoute?.name
  if (
    currentRouteName &&
    currentRouteName !== 'LogIn' &&
    currentRouteName !== 'NotFound' &&
    !NON_AUTH_PATHS.includes(currentRouteName)
  ) {
    if (Platform.OS === 'web') {
      let url = `/login`
      if (redirectRoute) {
        url += `?redirectRoute=${redirectRoute}`
      } else {
        const queryParamsString = window.location.search
        const queryParams = new URLSearchParams(queryParamsString)
        if (queryParamsString && queryParams) {
          queryParams.set('redirectRoute', currentRouteName)
          url += `?${queryParams.toString()}`
        } else {
          url += `?redirectRoute=${currentRouteName}`
        }
      }
      window.location.href = url
    } else {
      navigation.replace('LogIn', {
        redirectRoute: currentRouteName,
      })
    }
  } else {
    if (Platform.OS === 'web') {
      let url = `/login`
      window.location.href = url
    } else {
      navigation.replace('LogIn')
    }
  }
}

export function openHomePage(navigation: any) {
  if (Platform.OS === 'web') {
    if (Layout.isMobileDevice) {
      let url = `/mobile-home/index`
      window.location.href = url
    } else {
      let url = `/login-success`
      window.location.href = url
    }
  } else {
    if (Layout.isMobileDevice) {
      navigation.replace('MobileHome')
    } else {
      navigation.replace('AfterLogIn')
    }
  }
}
