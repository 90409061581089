import Layout from '../../constants/Layout'
import React from 'react'
import { Animated, Easing, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import CircleProgressIndicator from './CircleProgressIndicator'

export type LoadableComponentProps = {
  loading?: boolean
  style?: StyleProp<ViewStyle> | undefined
  children?: JSX.Element
}

function LoadableComponent({ loading, style, children }: LoadableComponentProps) {
  const isMobileDevice = Layout.isMobileDevice
  const opacityAnim = React.useRef(new Animated.Value(1)).current
  const loadingOpacityAnim = React.useRef(new Animated.Value(0)).current
  React.useEffect(() => {
    if (loading) {
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 500,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(loadingOpacityAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      } as Animated.TimingAnimationConfig).start()
    } else {
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(loadingOpacityAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      } as Animated.TimingAnimationConfig).start()
    }
  }, [loading])
  return (
    <Animated.View
      style={[styles.container, { backgroundColor: loading ? '#FFFFFF' : undefined, opacity: opacityAnim }, style]}
    >
      {children}
      <Animated.View style={[styles.loadingContainer, { opacity: loadingOpacityAnim }]}>
        <CircleProgressIndicator size={isMobileDevice ? 'small' : 'large'} />
      </Animated.View>
    </Animated.View>
  )
}

export default LoadableComponent

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
