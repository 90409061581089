import React from 'react';
import {  StyleSheet, Text, View } from 'react-native'
import { useMediaQuery } from 'react-responsive'
import { tabletSmallMediaConfig } from '../../constants/Constants'
import { mathHelper } from '../helper/Mathematic'
import { vnNumberFormatter, enNumberFormatter  } from '../helper/Formatter'
import Layout from '../../constants/Layout';

export type BoxFooterProps = {
  title?: string
  type?: "row" | "column" | undefined
  backgroundColorBox? :string
  titleStyle?: Record<string, unknown>
  child?: any
}

export function BoxFooter({ title, titleStyle, child,backgroundColorBox,type }: BoxFooterProps) {
  const isMobileDevice = Layout.isMobileDevice;
  const isTabletLargeDevice = Layout.isTabletDevice;
  const isTabletDevice = useMediaQuery(tabletSmallMediaConfig);


  function getQtyValue() {
    // We need to multiply to 1000000 because qty's unit is billion
    const qtyToCount = (Number(child.props.children) ?? 0) * 1000000
    return  vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(qtyToCount ?? 0, 1000000, 3))
  }

  

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container]}>
        <Text style={mobileStyles.titleHeading}>{title}</Text>  
      <View style={[mobileStyles.childContainer,{backgroundColor: backgroundColorBox? backgroundColorBox: '#F5E6FE'}]}>
      <Text style={[mobileStyles.title, titleStyle]}>{getQtyValue()} <Text style={mobileStyles.smallTitle}> Triệu đồng</Text></Text>
      </View>
      </View>
    )
  }


  // if (isTabletDevice) {
  //   return (
  //     <View style={[tabletStyles.containerTablet]}>
  //       <Text style={tabletStyles.titleHeadingTablet}>{title}</Text>  
  //     <View style={[tabletStyles.childContainer,{backgroundColor: backgroundColorBox? backgroundColorBox: '#F5E6FE'}]}>
  //     <Text style={[tabletStyles.title, titleStyle]}>{getQtyValue()} <Text style={tabletStyles.smallTitle}> Triệu đồng</Text></Text>
  //     </View>
  //     </View>
  //   )
  // }
  
  if (isTabletLargeDevice) {
    return (
      <View style={[tabletStyles.container]}>
        <Text style={tabletStyles.titleHeading}>{title}</Text>  
      <View style={[tabletStyles.childContainer,{backgroundColor: backgroundColorBox? backgroundColorBox: '#F5E6FE'}]}>
      <Text style={[tabletStyles.title, titleStyle]}>{getQtyValue()} <Text style={tabletStyles.smallTitle}> Triệu đồng</Text></Text>
      </View>
      </View>
    )
  }


  return (
    <View style={[desktopStyles.container,type === 'column' ? desktopStyles.containerColumn: null ]}>
      <Text style={[desktopStyles.titleHeading,type === 'column' ? desktopStyles.titleHeadingColumn: null]}>{title}</Text>  
      <View style={[desktopStyles.childContainer,{backgroundColor: backgroundColorBox? backgroundColorBox: '#F5E6FE'}]}>
      <Text style={[desktopStyles.title, titleStyle]}>{getQtyValue()} 
      <Text style={desktopStyles.smallTitle}> Triệu đồng</Text></Text>
      </View>
    </View>
  )
}

export default BoxFooter

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  containerColumn: {
    flexDirection: 'column',
    marginTop: 30,
    maxWidth: 470,
    padding: 30,
    alignItems: 'flex-start',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    justifyContent: 'flex-start',
  },
  title: {
    color: '#BE63F9',
    fontSize: 30,
    fontWeight: '700',
  },
  titleHeading: {
    color: '#505D6F',
    fontSize: 16,
    textTransform:'uppercase',
    textDecorationLine: 'underline',
    maxWidth: 380,
    marginTop: 20,
    paddingRight: 20,
    fontWeight: '600'
  },
  titleHeadingColumn: {
    marginBottom: 20
  },
  childContainer: {
    backgroundColor: '#F5E6FE',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
    marginTop: 20,
    padding: 20,
    width: 'auto',
    minWidth: 340
  },
  smallTitle : {
      fontSize: 24,
      fontWeight: '400',
  }
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems:'center',
    flexWrap:'wrap'
  },
  containerTablet: {
    flexDirection: 'column',
    marginTop: 10,
    justifyContent: 'flex-start',
  },
  titleHeadingTablet: {
    color: '#505D6F',
    fontSize: 13.2667,
    textTransform:'uppercase',
    textDecorationLine: 'underline',
    marginBottom: 20,
    fontWeight: '600'
  },
  containerColumn: {
    flexDirection: 'column',
    marginTop: 30,
    maxWidth: 470,
    padding: 30,
    alignItems: 'flex-start',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    justifyContent: 'flex-start',
  },
  title: {
    color: '#BE63F9',
    fontSize: 24.875,
    fontWeight: '700',
  },
  titleHeading: {
    color: '#505D6F',
    fontSize: 13.2667,
    textTransform:'uppercase',
    textDecorationLine: 'underline',
    marginRight: 15,
    maxWidth: 310,
    marginTop: 20,
    fontWeight: '600'
  },
  titleHeadingColumn: {
    marginBottom: 20
  },
  childContainer: {
    backgroundColor: '#F5E6FE',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 58,
    marginTop: 20,
    padding: 20,
    width: 'auto',
    minWidth: 280
  },
  smallTitle : {
      fontSize: 16.5833,
      fontWeight: '400',
  }
})


const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  title: {
    color: '#BE63F9',
    fontSize: 30,
    fontWeight: '700',
  },
  titleHeading: {
    color: '#505D6F',
    fontSize: 13,
    textTransform:'uppercase',
    textDecorationLine: 'underline',
    paddingRight: 20,
    marginBottom: 20,
    fontWeight: '600'
  },
  childContainer: {
    backgroundColor: '#F5E6FE',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
  },
  smallTitle : {
      fontSize: 24,
      fontWeight: '400',
  }
})
