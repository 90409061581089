import { agentData } from '../constants/Data'
import { REGISTERED_AGENTS } from '../constants/Constants'
import { AgentOffice } from '../constants/Enum'
import AsyncStorage from '@react-native-async-storage/async-storage'

interface AgentData {
  icon: any
}

export function getAgentData(agentOfficeCode?: string): AgentData {
  if (!agentOfficeCode) return agentData.UNKNOWN
  if (REGISTERED_AGENTS.includes(agentOfficeCode as AgentOffice)) {
    let data = Object(agentData)[agentOfficeCode]
    return data ?? agentData.UNKNOWN
  }
  return agentData.UNKNOWN
}

export async function clearAgentData(): Promise<void> {
  await AsyncStorage.removeItem('accessToken')
  await AsyncStorage.removeItem('refreshToken')
  await AsyncStorage.removeItem('expiresIn')
  await AsyncStorage.removeItem('logInEfforts')
  await AsyncStorage.removeItem('latestSuccessLogInTime')
}
