import React, { forwardRef } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, Animated, Image } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'
import imgArrowDownRed from '../../assets/images/arrow-down.png'
import { useIsFocused } from '@react-navigation/native'
import LinearGradientBackground from './LinearGradientBackground'
import SizedBox from './SizedBox'
import Layout from '../../constants/Layout'

export type ExpandableListProps = {
  header?: JSX.Element
  headerStyle?: Record<string, unknown>
  expandable?: JSX.Element | null
  hideExpand?: boolean
  containerStyle?: Record<string, unknown>
  displayExpandIcon?: boolean
  styleExpandIndicator?: any
  expandLabel?: string
  collapseLabel?: string
  height?: number
}

export const ExpandableList = forwardRef(
  (
    { header, headerStyle, expandable, displayExpandIcon = true, expandLabel, collapseLabel, height, styleExpandIndicator, containerStyle, hideExpand }: ExpandableListProps,
    ref
  ) => {
    const isFocused = useIsFocused()
    const expandAnim = React.useRef(new Animated.Value(0)).current
    const spinValue = React.useMemo(() => new Animated.Value(0), [])
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [isHide, setIsHide] = React.useState(hideExpand ??  false)
    const [expandHeight, setExpandHeight] = React.useState(height ?? 0)
    const expandRefs = React.useRef()
    const isMobileDevice = Layout.isMobileDevice

    React.useImperativeHandle(ref, () => ({
      collapseExpandableView() {
        toggleExpand()
      },
    }))

    React.useEffect(() => {
      if (isFocused) {
        setExpandHeight( /* (expandRefs.current as any).offsetHeight */ height ?? 0)
        hideExpand && setIsHide(!hideExpand)
      }
    }, [expandable, height, isFocused])

    React.useEffect(() => {
      if (isExpanded) {
        Animated.timing(expandAnim, {
          toValue: expandHeight,
          duration: 300,
          useNativeDriver: false,
        } as Animated.TimingAnimationConfig).start()
        Animated.timing(spinValue, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }).start()
      } else {
        Animated.timing(expandAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        } as Animated.TimingAnimationConfig).start()
        Animated.timing(spinValue, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }).start()
      }
    }, [isExpanded, expandAnim, expandHeight, spinValue])

    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '-180deg'],
    })

    function toggleExpand() {
      setIsExpanded(!isExpanded)
      hideExpand && setIsHide(!isHide)
    }

    return (
      <View style={[styles.container,containerStyle]}>
        {/* Default data */}
        {
          !isHide &&(
        <View style={[styles.header, headerStyle]}>
          <View style={styles.headerTitle}>{header}</View>
        </View>
        )
        }
        {/* Expandable data */}

          <Animated.View style={[styles.expandable, { height: expandAnim }]}>
          <View ref={expandRefs as any}>{expandable}</View>
          </Animated.View>

        {/* Toggle button */}
        {expandable && (
          <TouchableOpacity style={[styles.expandIndicator,styleExpandIndicator]} onPress={toggleExpand} activeOpacity={1}>
            <Animated.View
              style={[styles.icon, { transform: [{ rotate: spin }] }]}
            >
              <Image source={imgArrowDownRed} style={styles.icon}/>
            </Animated.View>
            {isExpanded ? (
              <Text style={styles.collapseLabel}>{collapseLabel ?? ''}</Text>
            ) : (
              <Text style={styles.expandLabel}>{expandLabel ?? ''}</Text>
            )}
          </TouchableOpacity>
        )}
          {
            !isExpanded && !isHide && hideExpand &&
            <LinearGradientBackground colors={['rgba(255, 244, 238, 0)','rgb(255,255,255)']} locations={[0,8,0.09]} style={[globalStyles.Flex1,{position:'absolute', bottom: isMobileDevice ? 40 : 38, width: '100%'}]}>
            <SizedBox height={55} />
           </LinearGradientBackground>
          }
        
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    width: '100%',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    flex: 1,
  },
  expandable: {
    width: '100%',
    height: 100,
    overflow: 'hidden',
  },
  icon: {
    width: 13,
    height: 10.83,
    resizeMode: 'contain',
  },
  expandLabel: {
    color: '#ED1B2E',
    fontSize: 12,
    marginLeft: 10,
    minWidth: 80,
  },
  collapseLabel: {
    color: '#ED1B2E',
    fontSize: 12,
    marginLeft: 10,
    width: 80,
  },
  expandIndicator: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
  },
})

export default ExpandableList