import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'

type Props = {
  colors: string[]
  children?: any
  locations?: number[] | null;
  style?: any
}

export default function LinearGradientBackground({ colors, style, children,locations }: Props) {
  return (
    <LinearGradient colors={colors} style={style} locations={locations}>
      {children}
    </LinearGradient>
  )
}
