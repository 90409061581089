import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { styles as globalStyles, BlockAlysys, InputData, ValidateRequiredError, SizedBox } from '../common'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { updateUserProtect } from '../slices/userSlice'
import { connect, ConnectedProps } from 'react-redux'
import Layout from '../constants/Layout'
import i18n from 'i18n-js'

const mapStateToProps = (state: RootState) => ({
  inherit: state.inherit.inherit,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  saveCNA,
  updateUserProtect,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SaveInheritScreen({ inherit, userProtect, needsCartList, saveCNA, updateUserProtect }: Props) {
  const isMobileDevice = Layout.isMobileDevice

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: inherit,
  })

  useEffect(() => {
    if (userProtect?.inherit) {
      setValue('subTotal', parseFloat(userProtect?.inherit?.subTotal))
    }
  }, [userProtect])

  useEffect(() => {
    if (userProtect) {
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        inherit: {
          ...userProtect?.inherit,
          ...watch(),
        },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }, [getValues('subTotal')])

  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Để lại thừa kế"
          borderColor="#FC573B"
          child={
            <View>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Tổng giá trị tài sản thừa kế bạn muốn để lại"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => onChange(value)}
                    error={errors.subTotal && renderErrorMessage(errors.subTotal.message)}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Để lại thừa kế"
        borderColor="#FC573B"
        child={
          <View>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Tổng giá trị tài sản thừa kế bạn muốn để lại"
                  helpText="Triệu đồng"
                  type="column"
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                  error={errors.subTotal && renderErrorMessage(errors.subTotal.message)}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <SizedBox width={24} />
    </View>
  )
}

export default connector(SaveInheritScreen)

const mobileStyles = StyleSheet.create({
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
