import { StatusBar } from 'expo-status-bar'
import React, { useEffect } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'

import useCachedResources from './hooks/useCachedResources'
import useColorScheme from './hooks/useColorScheme'
import Navigation from './navigation'
import { store } from './store'
import i18n from 'i18n-js'
import vi from './assets/language/vi.json'
import { Toast } from './common'
import toastService from './services/toast/ToastService'
import LoadingIndicator from './components/LoadingIndicator'
import 'intl'
import 'intl/locale-data/jsonp/en'
import * as ScreenOrientation from 'expo-screen-orientation'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import Layout from './constants/Layout'
import LoadingMask from './components/LoadingMask'

i18n.translations = {
  vi,
}
i18n.locale = 'vi'
i18n.fallbacks = true

export const AuthContext = React.createContext({});

export function App(props: any) {
  const isLoadingComplete = useCachedResources()
  const colorScheme = useColorScheme()
  const isMobileDevice = Layout.isMobileDevice

  useEffect(() => {
    if (Platform.OS === 'android' || Platform.OS === 'ios' || Platform.OS === 'macos' || Platform.OS === 'windows') {
      if (isMobileDevice) {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)
      } else {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT)
      }
    }
  }, [])

  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <AuthContext.Provider value={props.route?.params}>
        <Provider store={store}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
            {!isMobileDevice && <LoadingIndicator />}
            <LoadingMask />
            <Toast
              ref={(ref) => {
                toastService.setRefContainer(ref!)
              }}
            />
          </SafeAreaProvider>
        </Provider>
      </AuthContext.Provider>
    )
  }
}

export type RootStackParamList = {
  App: never
}

type RouteName = RootStackParamList
const AppStack = createStackNavigator<RouteName>()

export function RootNavigator(props: any) {
  return (
    <AppStack.Navigator initialRouteName="App">
      <AppStack.Screen name="App" component={App} options={{ headerShown: false }} initialParams={props.route?.params} />
    </AppStack.Navigator>
  )
}

export const AppNavigation = () => {
  return (
    <NavigationContainer independent>
      <RootNavigator />
    </NavigationContainer>
  )
}
