import React from 'react'
import { StyleSheet, View, ScrollView, Linking, Text } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'
import Layout from '../../constants/Layout'

export type TableProps = {
  columns: any
  columnWidth?: number
  height?: number
  dataSource: any
  type?: string
  renderCell?: (cellData: any, col: any, index: number) => void
  headerContainerStyle?: object
  bodyContainerStyle?: object
  headerStyle?: object
  bodyStyle?: object
  titleColumn?: object
}

const DEFAULT_COLUMN_WIDTH = 100

export function Table({
  columns,
  columnWidth,
  height,
  dataSource,
  type,
  renderCell,
  headerContainerStyle,
  bodyContainerStyle,
  titleColumn,
  bodyStyle,
  headerStyle,
}: TableProps) {
  const isMobileDevice = Layout.isMobileDevice
  const data = {
    'Trường Công lập tại VN':
      'https://thuvienphapluat.vn/van-ban/Thue-Phi-Le-Phi/Nghi-dinh-81-2021-ND-CP-quan-ly-hoc-phi-doi-voi-co-so-giao-duc-thuoc-he-thong-giao-duc-quoc-dan-457392.aspx',
    'Trường Dân lập tại VN':
      'http://www.pvc.vn/tinchitiet/tabid/93/id/1690/Cac-truong-ngoai-cong-lap-Nam-hoc-moi-hoc-phi-moi.aspx',
    'Trường Quốc tế tại VN': 'https://kenhtuyensinh.vn/hoc-truong-quoc-te-dong-bao-nhieu-tien',
    'ĐH Công lập tại VN':
      'https://thituyensinh.ican.vn/danh-sach-muc-hoc-phi-cua-cac-truong-dai-hoc-khu-vuc-phia-nam-nam-2021/',
    'ĐH Dân lập tại VN': 'https://truongvietnam.net/hoc-phi-hoa-sen-2021/',
    'ĐH Quốc tế tại VN':
      'https://dantri.com.vn/giao-duc-huong-nghiep/nhieu-truong-dai-hoc-tang-hoc-phi-tu-nam-2021-co-dang-dong-tien-bat-gao-20210414064533093.htm',
    'Du học Singapore/Úc/Nhật': 'https://www.rmit.edu.vn/vi/hoc-tap-tai-rmit/hoc-phi',
    'Du học Châu Âu': 'https://www.hotcourses.vn/study-in-usa/student-finance/can-bao-nhieu-tien-de-du-hoc-my/',
    'Du học Mỹ': 'https://www.ef.com.vn/pg/chi-phi-du-hoc/',
    'Ung thư tuyến giáp': 'https://vtc.vn/nguoi-viet-tieu-ton-bao-nhieu-tien-dieu-tri-ung-thu-moi-nam-ar429288.html',
    'Đột quỵ': 'https://suckhoedoisong.vn/thong-tin-khong-the-bo-qua-truoc-khi-dat-stent-mach-vanh-169169100.htm',
    'Nhồi máu cơ tim':
      'https://suckhoedoisong.vn/thong-tin-khong-the-bo-qua-truoc-khi-dat-stent-mach-vanh-169169100.htm',
    'Ung thư đại tràng':
      'https://benhvienungbuouhungviet.vn/kien-thuc-ung-thu-dai-truc-trang/chi-phi-dieu-tri-ung-thu-dai-trang.html',
    'Ung thư vú': 'https://tapchiyhocvietnam.vn/index.php/vmj/article/view/648',
    'Ung thư gan': 'https://ubuou.vn/tu-van-ung-thu-gan/chi-phi-dieu-tri-ung-thu-gan.html',
    'Ung thư phổi': 'https://iims-vnm.com/tu-van/chi-phi-hoa-tri-ung-thu-phoi.html',
  } as any

  const _renderCell = (cellData: any, col: any, index: number) => {
    let style = {
      width: col.width || columnWidth || DEFAULT_COLUMN_WIDTH,
      borderBottomWidth: 1,
      alignItem:'center',
      borderBottomColor: '#E7E7E7',
      paddingLeft: col.dataIndex === 'type' ? 0 : 10,
    }
    return (
      <>
        {index === 0 ? (
          <View
            key={index}
            style={[
              styles.cell,
              styles.cellFrist,
              bodyContainerStyle,
              style,
              {
                backgroundColor: col.background,
                borderBottomWidth: col.dataIndex === 'type' && cellData !== '  ' ? 0 : 1,
                borderBottomColor: '#E7E7E7',
                alignItems: col.dataIndex === 'type' ? 'center' : 'flex-start',
              },
            ]}
          >
            <Text
              style={[
                bodyStyle,
                titleColumn,
                {
                  color: data[cellData] ? '#2F7AEB' : '#373737',
                  textDecorationLine: data[cellData] ? 'underline' : 'none',
                },
              ]}
              onPress={() => (data[cellData] ? gotoUrl(data[cellData]) : '')}
            >
              {col.dataIndex === 'answer' ? <Text style={styles.bold}>{replaceIndex(cellData)}</Text> : ''}
              {cellData ? replaceHospital(cellData) : '-'}
            </Text>
          </View>
        ) : (
          <View
            key={index}
            style={[styles.cell, bodyContainerStyle, style, { alignItems: col.text, paddingRight: col.paddingRight }]}
          >
            <Text
              style={[
                bodyStyle,
                {
                  color: data[cellData] ? '#2F7AEB' : '#373737',
                  textDecorationLine: data[cellData] ? 'underline' : 'none',
                  cursor: 'none',
                },
              ]}
              onPress={() => (data[cellData] ? gotoUrl(data[cellData]) : '')}
            >
              {cellData ? cellData : '-'}
            </Text>
          </View>
        )}
      </>
    )
  }

  const replaceIndex = (data: string) => {
    switch (data) {
      case 'Chương trình chăm sóc cơ bản':
        return 'A. '
      case 'Chương trình chăm sóc nâng cao':
        return 'B. '
      case 'Chương trình chăm sóc toàn diện':
        return 'C. '
      case 'Chương trình chăm sóc hoàn hảo':
        return 'D. '
      default:
        return ''
    }
  }

  const replaceHospital = (data: string) => {
    switch (data) {
      case 'Chương trình chăm sóc cơ bản':
        return 'Bệnh viện công'
      case 'Chương trình chăm sóc nâng cao':
        return 'Bệnh viện tư nhân'
      case 'Chương trình chăm sóc toàn diện':
        return 'Bệnh viện quốc tế'
      case 'Chương trình chăm sóc hoàn hảo':
        return 'Điều trị nước ngoài'
      default:
        return data
    }
  }

  const gotoUrl = (url: string) => {
    Linking.canOpenURL(url).then((support) => {
      if (support) {
        Linking.openURL(url)
      } else {
        console.log('No support ')
      }
    })
  }

  const _renderHeader = () => {
    return columns.map((col: any, index: number) => {
      let style = { width: col.width || columnWidth || DEFAULT_COLUMN_WIDTH }
      return (
        <>
          {index === 0 ? (
            <View key={index} style={[styles.headerItem, styles.headerItemFrist, headerContainerStyle, style]}>
              <Text style={[styles.HeaderText, headerStyle]}>
                {col.title}
              </Text>
            </View>
          ) : (
            <View key={index} style={[styles.headerItem, headerContainerStyle, style]}>
              <Text style={[styles.HeaderText, headerStyle]}>{col.title}</Text>
            </View>
          )}
        </>
      )
    })
  }

  const _renderHeaderEdu = () => {
    return (
      <>
        <View style={[styles.headerItem, styles.headerItemFrist, headerContainerStyle]}>
          <Text style={[styles.HeaderText, headerStyle]}>
            Giáo dục
            <Text style={[styles.HeaderTextHint]}>
              (Lưu ý: Mức học phí tham khảo, dự kiến tăng 10% mỗi năm và chưa bao gồm các khoản chi phí sinh hoạt, phụ
              phí khác.)
            </Text>
          </Text>
        </View>
      </>
    )
  }

  const _renderRow = (rowData: any, index: number) => {
    return (
      <View key={index} style={styles.row}>
        {columns.map((col: any, index: number) => {
          if (renderCell) {
            return renderCell(
              col.dataIndex.split('.').reduce((prev: any, curr: number) => (prev ? prev[curr] : null), rowData || null),
              col,
              index
            )
          } else {
            return _renderCell(
              col.dataIndex.split('.').reduce((prev: any, curr: number) => (prev ? prev[curr] : null), rowData || null),
              col,
              index
            )
          }
        })}
      </View>
    )
  }
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={[styles.contentContainer, { height }]}
      horizontal={true}
      bounces={false}
    >
      <View style={[globalStyles.FullWidth, { padding: isMobileDevice ? 0 : 0 }]}>
        <View style={styles.header}>{type === 'edu' ? _renderHeaderEdu() : _renderHeader()}</View>
        <ScrollView contentContainerStyle={styles.dataView}>
          {dataSource.map((rowData: object, index: number) => _renderRow(rowData, index))}
        </ScrollView>
      </View>
    </ScrollView>
  )
}

export default Table

const styles = StyleSheet.create({
  contentContainer: {
    height: 240,
    width: '100%',
  },
  container: {
    width: '100%',
  },
  hyperLink: {
    color: 'blue',
  },
  header: {
    flexDirection: 'row',
  },
  headerItem: {
    minHeight: 58,
    paddingLeft: 10,
    width: DEFAULT_COLUMN_WIDTH,
    maxWidth: 750,
    backgroundColor: '#E6F0FF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerItemFrist: {
    alignItems: 'flex-start',
    paddingLeft: 10,
  },
  HeaderText: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dataView: {
    flexGrow: 1,
    width: '100%'
  },
  bold: {
    fontWeight: '700',
  },
  HeaderTextHint: {
    fontStyle: 'italic',
    fontWeight: '400',
    paddingLeft: 5
  },
  row: {
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  cell: {
    minHeight: 50,
    width: DEFAULT_COLUMN_WIDTH,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellFrist: {
    alignItems: 'flex-start',
    paddingLeft: 15,
  },
})
