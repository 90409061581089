import 'intl';
import 'intl/locale-data/jsonp/vi';

export const vnCurrencyFormatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
})

export const enNumberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})

export const appCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  currency: 'VND',
})

export const vnNumberFormatter = new Intl.NumberFormat('vi-VN', {
  style: 'decimal',
})
