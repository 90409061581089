import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class inherit {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
  }
  

export interface inheritState {
  loading?: boolean
  inherit?: inherit
}

const initialState: inheritState = {
  loading: false,
  inherit: {
    isTemporaryCheck: true,
    // isSelect: true,
  },
}

export const updateInheritSlice = createAsyncThunk('users/updateInheritSlice', async (data) => {
  return data
});


export const inheritSlice = createSlice({
  name: 'inherit',
  initialState,
  reducers: {
    loadInheritState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.inherit = action.payload
    },
    updateInheritState: (state, action: PayloadAction<inherit>) => {
      state.inherit = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateInheritSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadInheritState, updateInheritState, } = inheritSlice.actions

const { reducer } = inheritSlice
export default reducer
