import { API_PATH } from '../constants/Path'
import { authApiService, internalApiService } from './ApiService'

class CustomAnalyzeService {
  async saveNewCna(data: any) {
    return await authApiService.post(API_PATH.CNA.CREATE, data)
  }

  async updateCna(data: any) {
    return await authApiService.put(API_PATH.CNA.UPDATE, data)
  }

  async getDetailCna(data: any) {
    return await authApiService.post(API_PATH.CNA.DETAIL, data)
  }

  async getListCnaOfAgent(data: any) {
    return await authApiService.post(API_PATH.CNA.GET_ALL, data)
  }

  async getSummaryList() {
    return await authApiService.get(API_PATH.CNA.SUMMARY)
  }

  async deleteCna(data: any) {
    return await authApiService.put(API_PATH.CNA.DELETE, data)
  }

  async getDetailCnaInternal(data: any, agentCode: any) {
    return await internalApiService.post(API_PATH.INTERNAL_CNA.GET_DETAIL.replace('{agentCode}', agentCode), data)
  }

  async geAgentInfoInternal(agentCode: any) {
    return await internalApiService.get(API_PATH.INTERNAL_CNA.GET_AGENT_INFO.replace('{agentCode}', agentCode), {})
  }

  async printPdf(data: any) {
    return await authApiService.post(API_PATH.CNA.PRINT_PDF, data)
  }

  async getChartDataInternal(data: any, agentCode: any) {
    return await internalApiService.get(API_PATH.CNA.GET_CHART_DATA.replace('{{id}}', data?.id), {})
  }

  async sendMailPdf(data: any) {
    return await authApiService.post(API_PATH.CNA.SEND_MAIL_PDF, data)
  }

  
}

export default new CustomAnalyzeService()
