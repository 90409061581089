import { API_PATH } from '../constants/Path'
import UserInfo from '../model/UserInfo'
import { authApiService, nonAuthApiService } from './ApiService'

class AuthenService {
  async signIn(code: string, password: string, captcha: string) {
    return await nonAuthApiService.post(
      API_PATH.USER.SIGN_IN,
      { username: code, password: password, captcha: captcha },
      { grantType: 'password' }
    )
  }

  async refreshToken(refreshToken: string) {
    return await nonAuthApiService.get(API_PATH.USER.SIGN_IN, {
      application: 'CNA',
      grantType: 'refreshToken',
      code: refreshToken,
    })
  }
}

export default new AuthenService()
