import * as React from 'react'
import { Text, TouchableOpacity, Image } from 'react-native'
import imgBack from '../../assets/images/back-grey.png'
import { styles as globalStyles } from '../constants/globalStyles'

export type BackButtonProps = {
  title?: string
  onPress?: () => void
}

export function BackButton({ title, onPress }: BackButtonProps) {
  return (
    <TouchableOpacity onPress={onPress} style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
      <Image
        source={imgBack}
        style={globalStyles.Icon30}
      />
      <Text>{title}</Text>
    </TouchableOpacity>
  )
}

export default BackButton