import { Block, styles as globalStyles } from '../../../common'
import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import ChartBoxPopup from './ChartBoxPopup'

type IProps = {
  customerAge: number
  productTypeSolution?: string
  data?: Array<{ policyYear: number; ape: number; fundValue: number }>
  dataTopUp?: Array<{ policyYear: number; ape: number; fundValue: number }>
  style?: Record<string, unknown>
  extraInvest?: number
  isLoadingChartData?: boolean
  editable?: boolean
  quotationFundAllocation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }
}

function SummarySolutionPopUp({
  customerAge,
  data,
  dataTopUp,
  style,
  extraInvest,
  editable,
  productTypeSolution,
  quotationFundAllocation,
  isLoadingChartData,
}: IProps) {
  return (
    <View style={[desktopStyles.container, style]} pointerEvents={editable ? 'auto' : 'none'}>
      <Block
        titleSuffix={<Text style={[globalStyles.RedText, { fontSize: 12 }]}> (2)</Text>}
        child={
          <View>
            {/* Chart here */}
            <ChartBoxPopup
              customerAge={customerAge}
              data={data}
              dataTopUp={dataTopUp}
              style={{ marginTop: 0 }}
              extraInvest={extraInvest}
              productTypeChart={productTypeSolution}
              editable={editable}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={quotationFundAllocation}
            />
          </View>
        }
      />
    </View>
  )
}

export default SummarySolutionPopUp

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
})
