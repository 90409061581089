import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { styles as globalStyles, BlockAlysys, InputData, Dialog, BoxFooter, ValidateRequiredError } from '../common'
import i18n from 'i18n-js'
import { useMediaQuery } from 'react-responsive'
import { tabletSmallMediaConfig, MAX_MONTHLY_INCOME } from '../constants/Constants'
import { saveCNA } from '../slices/cnaSlice'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { updateUserProtect } from '../slices/userSlice'
import Layout from '../constants/Layout'
import AgentWording from '../components/AgentWording'

const mapStateToProps = (state: RootState) => ({
  protectIncome: state.protectIncome.protectIncome,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {
  saveCNA,
  updateUserProtect,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function AnalysisScreen({ protectIncome, userProtect, updateUserProtect, userInfo }: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const isTabletSmallDevice = useMediaQuery(tabletSmallMediaConfig)

  const [showPopup, setShowPopup] = React.useState(false)

  function countTotal() {
    setValue('subTotal', (getValues('month') ?? 0) * (getValues('monthlyIncome') ?? 0))
  }

  const {
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: protectIncome,
    shouldUnregister: false,
  })

  useEffect(() => {
    if (userProtect) {
      if ((getValues('subTotal') ?? 0) > 0 || getValues('month') || getValues('monthlyIncome')) {
        let cnaData = {
          ...userProtect,
          id: userProtect?._id,
          protectIncome: { ...userProtect?.protectIncome, ...watch() },
        }

        updateUserProtect({
          cnaData,
        })
      }
    }
  }, [getValues('subTotal'), getValues('month'), getValues('monthlyIncome')])

  useEffect(() => {
    if (userProtect?.protectIncome) {
      setValue('month', userProtect?.protectIncome?.month)
      setValue('monthlyIncome', userProtect?.protectIncome?.monthlyIncome)
      setValue('subTotal', userProtect?.protectIncome?.subTotal)
    }
  }, [userProtect])

  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Nhu cầu bạn quan tâm"
          borderColor="#BE63F9"
          child={
            <View>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value:
                      Number(userProtect?.protectLife?.monthlyFixedExpense) > 0
                        ? Number(userProtect?.protectLife?.monthlyFixedExpense)
                        : 0.001,
                    message:
                      Number(userProtect?.protectLife?.monthlyFixedExpense) > Number(watch('monthlyIncome'))
                        ? i18n.t('NEEDS_CART.MIN_MONTHLY_INCOME_ERROR')
                        : i18n.t('VALIDATE.REQUIRED_DATA_MONEY_INCOME'),
                  },
                  max: {
                    value: MAX_MONTHLY_INCOME,
                    message: i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Thu nhập hàng tháng của bạn là bao nhiêu?"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    disabled={false}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={errors.monthlyIncome && renderErrorMessage(errors?.monthlyIncome?.message)}
                  />
                )}
                name="monthlyIncome"
              />

              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Bạn muốn bảo vệ bao nhiêu tháng thu nhập của mình?"
                    helpText="Tháng"
                    type="column"
                    placeholder="Nhập"
                    allowNagitive
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={errors.month && renderErrorMessage(errors?.month?.message)}
                  />
                )}
                name="month"
              />
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
        <Dialog
          title="Chi phí tham khảo"
          message="Thông tin kế hoạch"
          children={
            <Text>
              Nhiều khách hàng lựa chọn bảo vệ 36-60 tháng thu nhập vì 3-5 năm là thời gian cần thiết để ổn định lại
              cuộc sống nếu không may có sự cố xảy ra
            </Text>
          }
          type="default"
          showCloseIcon
          iconHeader={true}
          onRemove={() => setShowPopup(false)}
          visible={showPopup}
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title={<AgentWording agentOfficeCode={userInfo?.officeCode} wordingCode="NEEDS_ANALYST.PROTECT_INCOME.INDEX" />}
        borderColor="#BE63F9"
        child={
          <View>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value:
                    Number(userProtect?.protectLife?.monthlyFixedExpense) > 0
                      ? Number(userProtect?.protectLife?.monthlyFixedExpense)
                      : 0.001,
                  message:
                    Number(userProtect?.protectLife?.monthlyFixedExpense) > Number(watch('monthlyIncome'))
                      ? i18n.t('NEEDS_CART.MIN_MONTHLY_INCOME_ERROR')
                      : i18n.t('VALIDATE.REQUIRED_DATA_MONEY_INCOME'),
                },
                max: {
                  value: MAX_MONTHLY_INCOME,
                  message: i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Thu nhập hàng tháng của bạn là bao nhiêu?"
                  helpText="Triệu đồng"
                  type="column"
                  disabled={false}
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={errors.monthlyIncome && renderErrorMessage(errors?.monthlyIncome?.message)}
                />
              )}
              name="monthlyIncome"
            />

            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Bạn muốn bảo vệ bao nhiêu tháng thu nhập của mình?"
                  helpText="Tháng"
                  type="column"
                  placeholder="Nhập"
                  allowNagitive
                  wrapHelpTextContainer={{ width: '100%' }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={errors.month && renderErrorMessage(errors?.month?.message)}
                />
              )}
              name="month"
            />
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ value }) => (
                <BoxFooter
                  title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                  backgroundColorBox="#F5E6FE"
                  titleStyle={{ color: '#BE63F9' }}
                  child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <Dialog
        title="Chi phí tham khảo"
        message="Thông tin kế hoạch"
        children={
          <Text>
            Nhiều khách hàng lựa chọn bảo vệ 36-60 tháng thu nhập vì 3-5 năm là thời gian cần thiết để ổn định lại cuộc
            sống nếu không may có sự cố xảy ra
          </Text>
        }
        type="default"
        showCloseIcon
        iconHeader={true}
        onRemove={() => setShowPopup(false)}
        visible={showPopup}
      />
    </View>
  )
}
export default connector(AnalysisScreen)

const mobileStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    paddingTop: 0,
    width: '100%',
    flex: 1,
    // overflowY: 'scroll',
    paddingBottom: 100,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
