import React, { forwardRef } from 'react'
import { StyleSheet, TouchableOpacity, View, Animated, Image, ImageSourcePropType } from 'react-native'
import imgArrowDown from '../../assets/images/dropdown-light.png'

export type ExpandableProps = {
  header?: JSX.Element
  headerStyle?: Record<string, unknown>
  containerStyle?: Record<string, unknown>
  expandIconStyle?: Record<string, unknown>
  expandable?: JSX.Element
  displayExpandIcon?: boolean
  expandIcon?: ImageSourcePropType
  height?: number
  onExpand?: () => void
}

export const Expandable = forwardRef(
  ({ header, headerStyle, containerStyle, expandIconStyle, expandable, displayExpandIcon = true, expandIcon, height, onExpand }: ExpandableProps, ref) => {
    const expandAnim = React.useRef(new Animated.Value(0)).current
    const spinValue = React.useMemo(() => new Animated.Value(0), [])
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [expandHeight, setExpandHeight] = React.useState(height ?? 0)
    const expandRefs = React.useRef()

    React.useImperativeHandle(ref, () => ({
      getExpandedState() {
        return isExpanded
      },
      toggleExpandableView() {
        toggleExpand()
      },
      collapseExpandableView() {
        setIsExpanded(false)
      },
      expandExpandableView() {
        setIsExpanded(true)
      },
    }))

    React.useEffect(() => {
      if (height) {
        setExpandHeight(height)
      } else if ((expandRefs.current as any).offsetHeight) {
        setExpandHeight((expandRefs.current as any).offsetHeight ?? 0)
      }
    }, [expandable])

    function onUpdateExpandHeight(height: number) {
      setExpandHeight(height)
    }

    React.useEffect(() => {
      if (isExpanded) {
        Animated.timing(expandAnim, {
          toValue: expandHeight,
          duration: 400,
          useNativeDriver: false,
        } as Animated.TimingAnimationConfig).start()
        Animated.timing(spinValue, {
          toValue: 1,
          duration: 400,
          useNativeDriver: false,
        }).start()
      } else {
        Animated.timing(expandAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        } as Animated.TimingAnimationConfig).start()
        Animated.timing(spinValue, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }).start()
      }
    }, [isExpanded, expandAnim, expandHeight, spinValue])

    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '-90deg'],
    })

    function toggleExpand() {
      if (onExpand) {
        onExpand()
      } else {
        setIsExpanded(!isExpanded)
      }
    }

    return (
      <View style={containerStyle}>
        {/* Header */}
        <TouchableOpacity style={styles.header} onPress={toggleExpand} activeOpacity={1}>
          <View style={[styles.header, headerStyle]}>
            <View style={styles.headerTitle}>{header}</View>
            {displayExpandIcon && (
              <Animated.View style={{ transform: [{ rotate: spin }] }}>
                <Image source={expandIcon ?? imgArrowDown} style={[styles.icon, expandIconStyle]} />
              </Animated.View>
            )}
          </View>
        </TouchableOpacity>
        {/* Expandable view */}
        <Animated.View
          style={{
            ...styles.expandable,
            height: expandAnim && !isNaN((expandAnim as any)._value) ? expandAnim : 0,
            overflow: 'hidden',
          }}
        >
          <View ref={expandRefs as any} onLayout={(e) => {
            const { height } = e.nativeEvent.layout
            onUpdateExpandHeight(height)
          }}>{expandable}</View>
        </Animated.View>
      </View>
    )
  }
)

const styles = StyleSheet.create({
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    flex: 1,
  },
  expandable: {
    width: '100%',
    height: 100,
  },
  icon: {
    width: 13,
    height: 10.83,
    resizeMode: 'contain',
  },
})

export default Expandable
