import { Panel, SizedBox, styles as globalStyles } from '../../../common'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import imgSumCreatedPlan from '../../../assets/images/sum_created_plan.png'
import imgSumCustomerInfo from '../../../assets/images/sum_customer_info.png'
import imgSumPlanInMonth from '../../../assets/images/sum_plan_in_month.png'
import imgSumDraftPlan from '../../../assets/images/sum_draft_plan.png'
import Layout from '../../../constants/Layout'

type IProps = {
  dataUpdate?: any
}

function SummaryPlan({dataUpdate}: IProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (isMobileDevice) {
    return (
      <View>
        <SizedBox height={10} />
        <View style={mobileStyles.summaryContainer}>
          <View style={globalStyles.FullWidth}>
            <Panel panel={{ title: 'Tổng Kế hoạch đã tạo', subtitle:dataUpdate?.totalRecords, image: imgSumCreatedPlan }} />
          </View>
          <SizedBox width={9} />
          <View style={globalStyles.FullWidth}>
            <Panel panel={{ title: 'Tổng KH để lại thông tin', subtitle: dataUpdate?.totalCompletedRecords, image: imgSumCustomerInfo }} />
          </View>
        </View>
        <View style={mobileStyles.summaryContainer}>
          <View style={globalStyles.FullWidth}>
            <Panel panel={{ title: 'Tổng Kế hoạch trong tháng', subtitle: dataUpdate?.totalInMonthRecords, image: imgSumPlanInMonth }} />
          </View>
          <SizedBox width={9} />
          <View style={globalStyles.FullWidth}>
            <Panel panel={{ title: 'Tổng số Kế hoạch nháp', subtitle: dataUpdate?.totalDraftRecords, image: imgSumDraftPlan }} />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={desktopStyles.summaryContainer}>
      <View style={globalStyles.FullWidth}>
        <Panel panel={{ title: 'Tổng Kế hoạch đã tạo', subtitle:dataUpdate?.totalRecords, image: imgSumCreatedPlan }} />
      </View>
      <SizedBox width={20} />
      <View style={globalStyles.FullWidth}>
        <Panel panel={{ title: 'Tổng KH để lại thông tin', subtitle: dataUpdate?.totalCompletedRecords, image: imgSumCustomerInfo }} />
      </View>
      <SizedBox width={20} />
      <View style={globalStyles.FullWidth}>
        <Panel panel={{ title: 'Tổng Kế hoạch trong tháng', subtitle: dataUpdate?.totalInMonthRecords, image: imgSumPlanInMonth }} />
      </View>
      <SizedBox width={20} />
      <View style={globalStyles.FullWidth}>
        <Panel panel={{ title: 'Tổng số Kế hoạch nháp', subtitle: dataUpdate?.totalDraftRecords, image: imgSumDraftPlan }} />
      </View>
    </View>
  )
}

export default SummaryPlan

const desktopStyles = StyleSheet.create({
  summaryContainer: {
    flexDirection: 'row',
    paddingVertical: 16,
    width: '100%',
  },
})

const mobileStyles = StyleSheet.create({
  summaryContainer: {
    flexDirection: 'row',
    paddingTop: 9,
    width: '100%',
  },
})
