import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CNA } from '../model/CNA'
import CNADetail from '../model/CNADetail'
import { Summary } from '../model/Summary'
import CustomAnalyzeService from '../services/CustomAnalyzeService'
import cnaService from '../services/CustomAnalyzeService'

export class cnaList {
  items?: Array<CNA>
}

export interface CnaListState {
  loading?: boolean
  cnaList?: cnaList
  summaryList?: Summary
  detailCnaOnPopUp?: CNADetail
}

const initialState: CnaListState = {
  loading: false,
  cnaList: {},
  summaryList: {
    totalRecords: 0,
    totalCompletedRecords: 0,
    totalInMonthRecords: 0,
    totalDraftRecords: 0,
  },
  detailCnaOnPopUp: undefined
}

export const loadCnaListAsync = createAsyncThunk('cna-list/load', async (req: any) => {
  return req
})

export const loadSummaryAsync = createAsyncThunk('cna-list/loadSummary', async () => {
  const response = await CustomAnalyzeService.getSummaryList()
  return response
})

export const printCnaAsync = createAsyncThunk('cna-list/gen-pdf', async (req: any) => {
  const response = await CustomAnalyzeService.printPdf(req)
  return response
})

export const getCNADetailPopUp = createAsyncThunk('cna/detail', async (req: any, thunkAPI: any) => {
  try {
    let response = await cnaService.getDetailCna(req)
    return response.data
  } catch (error: any) {
    console.error(error)
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const cnaListSlice = createSlice({
  name: 'cna-list',
  initialState,
  reducers: {
    updateCnaList: (state, action: PayloadAction<any>) => {
      state.cnaList = action.payload
    },
    saveCnaList: (state, action: PayloadAction<any>) => {
      state.cnaList = action.payload
      state.loading = true
    },
    clearCNADetailPopUp: (state, action: PayloadAction<any>) => {
      state.detailCnaOnPopUp = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCnaListAsync.pending, (state, action: PayloadAction<any>) => {
        state.loading = true
      })
      .addCase(loadCnaListAsync.fulfilled, (state, action) => {
        state.loading = false
        state.cnaList = action.payload.data
      })
      .addCase(loadSummaryAsync.pending, (state, action: PayloadAction<any>) => {
        state.loading = true
      })
      .addCase(loadSummaryAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.summaryList = action.payload?.data
      })
      .addCase(getCNADetailPopUp.pending, (state) => {
        state.detailCnaOnPopUp = {}
      })
      .addCase(getCNADetailPopUp.fulfilled, (state, action) => {
        state.detailCnaOnPopUp = action.payload
      })
      .addCase(getCNADetailPopUp.rejected, (state) => {
        state.detailCnaOnPopUp = {}
      })
  },
})

export const { saveCnaList, updateCnaList, clearCNADetailPopUp } = cnaListSlice.actions

const { reducer } = cnaListSlice
export default reducer
