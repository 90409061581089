import * as React from 'react'
import { View } from 'react-native'
import parse from 'html-react-parser';

export type HtmlViewProps = {
  content?: string
}

export function HtmlView({ content }: HtmlViewProps) {
  const dom = parse(content ?? '')
  return <View>{dom}</View>
}

export default HtmlView
