import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'


export interface childenDetail {
    childAge?: number
    tuition?: number
  }
  
export class protectEdu {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
    children?: number
    childrenDetails?: Array<childenDetail>
    eduPlanAge?:number
  }
  

export interface protectEduState {
  loading?: boolean
  protectEdu?: protectEdu
}

const initialState: protectEduState = {
  loading: false,
  protectEdu: {
    isTemporaryCheck: true,
    // isSelect: true,
    children: 1,
    subTotal: 0,
    eduPlanAge: 0,
    childrenDetails: [
      {
        tuition: 0,
      childAge : 0
    }
    ]
  },
}

export const updateProtectEduSlice = createAsyncThunk('users/updateProtectEduSlice', async (data) => {
  return data
});

export const protectEduSlice = createSlice({
  name: 'protectEdu',
  initialState,
  reducers: {
    loadProtectEduState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.protectEdu = action.payload
    },
    updateProtectEduState: (state, action: PayloadAction<protectEdu>) => {
      state.protectEdu = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProtectEduSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadProtectEduState, updateProtectEduState } = protectEduSlice.actions

const { reducer } = protectEduSlice
export default reducer
