import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class Charity {
    // isSelect?: boolean 
    isTemporaryCheck?: boolean 
    subTotal?: number
  }
  

export interface charityState {
  loading?: boolean
  charity?: Charity
}

const initialState: charityState = {
  loading: false,
  charity: {
    isTemporaryCheck: true,
    // isSelect: true,
  },
}

export const updateCharitySlice = createAsyncThunk('users/updateCharitySlice', async (data) => {
  return data
});




export const charitySlice = createSlice({
  name: 'charity',
  initialState,
  reducers: {
    loadCharityState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.charity = action.payload
    },
    updateCharityState: (state, action: PayloadAction<Charity>) => {
      state.charity = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCharitySlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadCharityState, updateCharityState } = charitySlice.actions

const { reducer } = charitySlice
export default reducer
