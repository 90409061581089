import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class retirement {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
    age? : number
    countDown?: number
    spending? : number
    duration? : Number
  }
  

export interface retirementState {
  loading?: boolean
  retirement?: retirement
}

const initialState: retirementState = {
  loading: false,
  retirement: {
    isTemporaryCheck: true,
    // isSelect: true,
    subTotal: 0,
  },
}

export const updateRetirementSlice = createAsyncThunk('users/updateRetirementSlice', async (data) => {
  return data
});



export const retirementSlice = createSlice({
  name: 'retirement',
  initialState,
  reducers: {
    loadRetirementState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.retirement = action.payload
    },
    updateRetirementState: (state, action: PayloadAction<retirement>) => {
      state.retirement = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRetirementSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadRetirementState, updateRetirementState } = retirementSlice.actions

const { reducer } = retirementSlice
export default reducer
