// @ts-nocheck

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import 
  Layout
 from '../../constants/Layout'
export default class ColumnChartItem extends Component {
  render () {
    let renders = []
    let seriesCount = this.props.seriesArray.length
    for (let seriesIndex = 0; seriesIndex < seriesCount; seriesIndex++) {
      let lastElementMarginRight = 0
      if (seriesIndex === (seriesCount - 1)) {
        lastElementMarginRight = this.props.defaultMargin
      }
      renders.push(
        <View key={seriesIndex} style={[styles.bar, {
          width:  this.props.defaultWidth / seriesCount ,
          height:  this.props.seriesArray[seriesIndex].data[this.props.dataIndex]['ratioY'],
          backgroundColor:  this.props.seriesArray[seriesIndex].seriesColor,
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          left: Layout.isMobileDevice && this.props.isInvest&& this.props.isMobile && this.props.seriesArray[seriesIndex]?.seriesName === 'Số tiền nhận về' && -3,
          right: Layout.isMobileDevice  && this.props.isInvest && this.props.isMobile && this.props.seriesArray[seriesIndex]?.seriesName === 'Số tiền nhận về'&& 0,
          marginLeft:  Layout.isMobileDevice &&  this.props.isMobile ? 1 : this.props.isInvest ? 5 : 0,
          marginRight: Layout.isMobileDevice &&  this.props.isMobile ? 0 : this.props.isInvest ? 7 : 0,
          borderColor: 'transparent'
        }]} />
      )
    }
    
    return (
      <>
        <View style={{height: this.props.defaultHeight,width: `${100 / this.props.seriesCount}%`, alignItems:'center',}}>
          <View style={[styles.chartView, { width: this.props.maxWidth}]}>
            {renders}
            {this.props.tooltips}
          </View>
        </View>
      </>
    )
  }
}

const styles = StyleSheet.create({
  chartView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: '100%',
    width: 108,
    justifyContent: 'space-between',
    paddingTop: 20
  },
  bar: {
    justifyContent: 'flex-end',
    borderWidth: 1
  }
})

ColumnChartItem.propTypes = {
  seriesArray: PropTypes.array,
  onClick: PropTypes.func,
  defaultWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  defaultHeight: PropTypes.number,
  defaultMargin: PropTypes.number,
  primaryColor: PropTypes.string,
  highlightColor: PropTypes.string
}
