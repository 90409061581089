/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { ColorSchemeName } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import LogInScreen from '../screens/LoginScreen'
import LogInSuccessScreen from '../screens/LoginSuccessScreen'
import AnalysisScreen from '../screens/AnalysisScreen'
import NeedsCartScreen from '../screens/NeedsCartScreen'
import SafeLifeScreen from '../screens/SafeLifeScreen'
import SafeEduScreen from '../screens/SafeEduScreen'
import SafeAssetScreen from '../screens/SafeAssetScreen'
import SaveEduScreen from '../screens/SaveEduScreen'
import SaveCancerScreen from '../screens/SaveCancerScreen'
import SaveHealthScreen from '../screens/SaveHealthScreen'
import SaveRetireScreen from '../screens/SaveRetireScreen'
import InvestAssetScreen from '../screens/InvestAssetScreen'
import SaveCharityScreen from '../screens/SaveCharityScreen'
import SaveInheritScreen from '../screens/SaveInheritScreen'
import CustomerNeedScreen from '../screens/CustomerNeedScreen'
import ChartPageScreen from '../screens/ChartPageScreen'

import NotFoundScreen from '../screens/NotFoundScreen'
import { RootStackParamList, RootStackPublicParamList } from '../types'
import LinkingConfiguration from './LinkingConfiguration'
import NeedsOverallScreen from '../screens/NeedsOverallScreen'
import AdvisorScreen from '../screens/AdvisorScreen'
import SolutionScreen from '../screens/SolutionScreen'
import navService, { navigationRef } from '../services/navigation/NavService'
import MobileSolutionDetailScreen from '../screens/SolutionScreen/MobileSolutionDetailScreen'
import SaveFormScreen from '../screens/SaveFormScreen'
import SplashScreen from '../screens/SplashScreen'
import { connect, ConnectedProps } from 'react-redux'
import { syncAuthData, setUserInfo } from '../slices/authenSlice'
import { RootState } from '../store'
import { getCachedCNA } from '../slices/cnaSlice'
import CNAListScreen from '../screens/CNAListScreen'
import GenPDFScreen from '../screens/GenPDFScreen'
import GenPDFForEmbedIntoMail from '../screens/GenPDFScreen/PDFForEmbedIntoMail'
import MobileDetailCna from '../screens/MobileDetailCna'
import ViewAllCNAScreen from '../screens/ViewAllCNAScreen'
import CnaFilterReviewDateScreen from '../screens/MobileHomeScreen/CnaFilterReviewDateScreen'
import CnaFilterCreateTodayScreen from '../screens/MobileHomeScreen/CnaFilterCreateTodayScreen'
import CnaFilterMonthScreen from '../screens/MobileHomeScreen/CnaFilterMonthScreen'

import MobileHomeScreen from '../screens/MobileHomeScreen'
import { AuthContext } from '../App'
import { useContext } from 'react'
import Colors from '../common/constants/Colors'
import UserService from '../services/UserService'
import { setUser } from '../slices/userSlice'
import NeedsOverallEditModeScreen from '../screens/NeedsOverallEditMode'
import { NON_AUTH_PATHS } from '../constants/Path'
import { openAuthenAsync } from '../helper/RouteHelper'
import { clearAgentData } from '../helper/CnaAgentHelper'

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {
  getCachedCNA,
  syncAuthData,
  setUserInfo,
  setUser,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#F7F8FA',
  },
}

function Navigation({ syncAuthData, setUserInfo, setUser }: { colorScheme: ColorSchemeName } & Props) {
  const authContext = useContext(AuthContext)

  React.useEffect(() => {
    if (authContext) {
      syncAuthData(authContext)
    } else {
      checkAuthen()
    }
  }, [])

  async function checkAuthen() {
    let currentRoute = (navigationRef as any).current?.getCurrentRoute()
    if (currentRoute) {
      function onAuth() {
        setTimeout(() => {
          openAuthenAsync((navigationRef as any).current)
        }, 3000)
      }
      async function handleGetUserInfo() {
        if (NON_AUTH_PATHS.includes(currentRoute?.name)) {
          return
        }
        let accessToken = await AsyncStorage.getItem('accessToken')
        if (accessToken) {
          let res = (await UserService.getUserInfo()) as any
          if (res && res.data) {
            setUserInfo(res.data)
            setUser(res.data)
          } else {
            await clearAgentData()
            onAuth()
          }
        } else {
          if (currentRoute?.name !== 'LogIn') {
            onAuth()
          }
        }
      }
      handleGetUserInfo()
    }
  }

  return (
    <NavigationContainer
      independent
      linking={LinkingConfiguration as any}
      ref={navigationRef as any}
      theme={{
        dark: false,
        colors: {
          primary: Colors.primaryRed,
          background: Colors.background,
          card: Colors.whiteColor,
          text: Colors.text,
          border: Colors.lightGrey,
          notification: Colors.lightGrey,
        },
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}

export default connector(Navigation)

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
type RouteName = RootStackParamList & RootStackPublicParamList
const Stack = createStackNavigator<RouteName>()

export function RootNavigator() {
  return (
    <Stack.Navigator initialRouteName="Splash">
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Trang không tồn tại', headerShown: false }} />
      <Stack.Screen name="Splash" component={SplashScreen} options={{ title: 'CNA', headerShown: false }} />
      <Stack.Screen name="MobileHome" component={MobileHomeScreen} options={{ title: 'CNA', headerShown: false }} />
      <Stack.Screen name="LogIn" component={LogInScreen} options={{ title: 'Đăng nhập - CNA', headerShown: false }} />
      <Stack.Screen
        name="AfterLogIn"
        component={LogInSuccessScreen}
        options={{ title: 'Trang chủ - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="Analysis"
        component={AnalysisScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="CustomerNeed"
        component={CustomerNeedScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="NeedsCart"
        component={NeedsCartScreen}
        options={{ title: 'Nhu cầu - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="NeedsOverall"
        component={NeedsOverallScreen}
        options={{ title: 'Tổng quan - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="NeedsOverallEditMode"
        component={NeedsOverallEditModeScreen}
        options={{ title: 'Chỉnh sửa nhu cầu - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SafeLife"
        component={SafeLifeScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SafeEdu"
        component={SafeEduScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SafeAsset"
        component={SafeAssetScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveEdu"
        component={SaveEduScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveCancer"
        component={SaveCancerScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveHealth"
        component={SaveHealthScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveRetire"
        component={SaveRetireScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveCharity"
        component={SaveCharityScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveInherit"
        component={SaveInheritScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="InvestAsset"
        component={InvestAssetScreen}
        options={{ title: 'Phân tích - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="Advisor"
        component={AdvisorScreen}
        options={{ title: 'Tổng quan - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="Solution"
        component={SolutionScreen}
        options={{ title: 'Giải pháp - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SolutionDetail"
        component={MobileSolutionDetailScreen}
        options={{ title: 'Chi tiết giải pháp - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="SaveForm"
        component={SaveFormScreen}
        options={{ title: 'Thông tin khách hàng - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="CNAList"
        component={CNAListScreen}
        options={{ title: 'Danh sách - CNA', headerShown: false }}
      />
      <Stack.Screen name="GenPDF" component={GenPDFScreen} options={{ title: 'In PDF - CNA', headerShown: false }} />
      <Stack.Screen
        name="GenPDFForEmbedIntoMail"
        component={GenPDFForEmbedIntoMail}
        options={{ title: 'In PDF - CNA', headerShown: false }}
      />
      <Stack.Screen name="ChartPage" component={ChartPageScreen} options={{ headerShown: false }} />
      <Stack.Screen
        name="MobileDetailCna"
        component={MobileDetailCna}
        options={{ title: 'Chi tiết giải pháp - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="ViewAllCNA"
        component={ViewAllCNAScreen}
        options={{ title: 'Danh sách - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="CnaFilterReviewDate"
        component={CnaFilterReviewDateScreen}
        options={{ title: 'Danh sách - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="CnaFilterCreateToday"
        component={CnaFilterCreateTodayScreen}
        options={{ title: 'Danh sách - CNA', headerShown: false }}
      />
      <Stack.Screen
        name="CnaFilterMonth"
        component={CnaFilterMonthScreen}
        options={{ title: 'Danh sách - CNA', headerShown: false }}
      />
    </Stack.Navigator>
  )
}
