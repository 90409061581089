import { API_PATH } from '../constants/Path'
import UserInfo from '../model/UserInfo'
import { authApiService } from './ApiService'

class UserService {
  async getUserInfo() {
    return await authApiService.get(API_PATH.USER.INFO, { 'subscription-key': 'b7118a22518f443294feddc343e1b0bb' })
  }
}

export default new UserService()
