import React from 'react'
import {
  View,
  StyleSheet,
  ImageSourcePropType,
  Text,
  Image,
  Platform,
  TextInput,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
  RefreshControl,
} from 'react-native'
import { RootStackScreenProps } from '../../types'
import {
  NextButton,
  styles as globalStyles,
  TableWithTitle,
  BadgeComponent,
  ColumnProps,
  Select,
  TextInputWithIcon,
  Dialog,
  ValidateRequiredError,
  Spacer,
  vnNumberFormatter,
  SizedBox,
  CircleProgressIndicator,
} from '../../common'
import { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'
import AppHeader from '../../components/AppHeader'
import { clearCart } from '../../slices/needsCartSlice'
import { createNewLocalCNA, getAgentCnaList, deleteCNA } from '../../slices/cnaSlice'
import { getCNADetailPopUp, loadSummaryAsync, printCnaAsync, clearCNADetailPopUp } from '../../slices/cnaListSlice'
import icDropDownLight from '../../assets/images/dropdown-light.png'
import icSort from '../../assets/icon/sort.svg'
import icSortDesc from '../../assets/icon/sort-highlight-down.svg'
import icSortAsc from '../../assets/icon/sort-highlight-up.svg'
import icSearch from '../../assets/images/search.png'
import { CnaStatus, ViewMode } from '../../constants/Enum'
import i18n from 'i18n-js'
import { Controller, useForm } from 'react-hook-form'
import PlanNeedReview from './components/PlanNeedReview'
import SummaryPlan from './components/SummaryPlan'
import Pagination from './components/Pagination'
import moment from 'moment'
import { validateDate } from '../../data/validate'
import * as Locale from 'date-fns/locale'
import { SafeAreaView } from 'react-native-safe-area-context'
import imgMobileHeaderBg from '../../assets/images/mobile_header_bg.png'
import imgAvatarMask from '../../assets/images/avatar.png'
import { pageNumberRouteNameMapping } from '../../constants/Data'
import icFirecracker from '../../assets/images/firecracker.png'
import icChain from '../../assets/images/chain.png'
import { sendMailPdf } from '../../slices/saveFormSlice'
import { useIsFocused } from '@react-navigation/native'
import Layout from '../../constants/Layout'
import { createUserProtect, createUserProtectOriginal } from '../../slices/userSlice'
import 'intl'
import 'intl/locale-data/jsonp/vi'
import Calendar from '../../common/components/Calendar'
import { wait } from '../../helper/Utils'
import { StatusBar } from 'expo-status-bar'
import Rating from '../../common/components/Rating'
import { saveCNA } from '../../slices/cnaSlice'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from 'constants/Constants'
import { handleSSOPruquote } from 'helper/SsoPruquoteHelper'

const defaultDataSort = { createdAt: 'ASC' }

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.authen?.userInfo,
  cnaList: state?.cna?.agentCnaList,
  summaryList: state?.cnaList?.summaryList,
  loadingCnaList: state?.cna?.loadingCnaList,
  detailCnaOnPopUp: state?.cnaList?.detailCnaOnPopUp,
  loadingDeleteCNA: state?.cna.loadingDeleteCna,
})

const mapDispatchToProps = {
  clearCart,
  createNewLocalCNA,
  createUserProtect,
  createUserProtectOriginal,
  getAgentCnaList,
  deleteCNA,
  loadSummaryAsync,
  printCnaAsync,
  sendMailPdf,
  getCNADetailPopUp,
  clearCNADetailPopUp,
  saveCNA,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'AfterLogIn'> & PropsFromRedux & {}

function CNAListScreen({
  route,
  navigation,
  userInfo,
  clearCart,
  createNewLocalCNA,
  createUserProtect,
  createUserProtectOriginal,
  cnaList,
  summaryList,
  loadingDeleteCNA,
  loadingCnaList,
  detailCnaOnPopUp,
  clearCNADetailPopUp,
  getAgentCnaList,
  deleteCNA,
  loadSummaryAsync,
  printCnaAsync,
  sendMailPdf,
  getCNADetailPopUp,
  saveCNA,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const isFocused = useIsFocused()

  const [showDatePicker, setShowDatePicker] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState<string>()
  const [datePickerSelectedDate, setDatePickerSelectedDate] = React.useState<Date>()
  const [filterTable, setFilterTable] = React.useState<Array<any>>([])
  const [pageSize] = React.useState(DEFAULT_PAGE_SIZE)
  const [pageIndex, setPageIndex] = React.useState(DEFAULT_PAGE_INDEX)
  const [dataFilters, setDataFilters] = React.useState<Array<any>>([])
  const [dataSorts, setDataSorts] = React.useState<any>(defaultDataSort)
  const [showDetailPlan, setShowDetailPlan] = React.useState(false)
  const [showWarningDeletePlan, setShowWarningDeletePlan] = React.useState(false)
  const [currentViewDetailPlan, setCurrentViewDetailPlan] = React.useState<any>({})
  const [showConfirmShareDialog, setShowConfirmShareDialog] = React.useState(false)
  const [showSharePDFSuccessDialog, setShowSharePDFSuccessDialog] = React.useState(false)
  const [showSharePDFFailDialog, setShowSharePDFFailDialog] = React.useState(false)
  const [showLoadingSendEmailDialog, setShowLoadingSendEmailDialog] = React.useState(false)
  const [record, setRecord] = React.useState('')
  const [refreshing, setRefreshing] = React.useState(false)
  const [defaultValue] = React.useState([undefined])

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  React.useEffect(() => {
    if (isFocused) {
      let detailId = route.params ? route.params['detail_id'] : undefined
      if (detailId) {
        getCNADetailPopUp({ id: detailId })
      }
    }
  }, [isFocused])

  React.useEffect(() => {
    let detailId = route.params ? route.params['detail_id'] : undefined
    if (isFocused && detailCnaOnPopUp && detailCnaOnPopUp?._id && detailId) {
      setCurrentViewDetailPlan({
        cnaCode: detailCnaOnPopUp?.cnaCode,
        createdAt: detailCnaOnPopUp?.createdAt,
        customerAge: detailCnaOnPopUp?.customerAge,
        customerEmail: detailCnaOnPopUp?.customerEmail,
        customerGender: detailCnaOnPopUp?.customerGender,
        customerName: detailCnaOnPopUp?.customerName,
        customerPhone: detailCnaOnPopUp?.customerPhone,
        lastEditStep: detailCnaOnPopUp?.lastEditStep,
        notes: detailCnaOnPopUp?.notes,
        maritalStatus: detailCnaOnPopUp?.maritalStatus,
        monthlyFixedExpense: detailCnaOnPopUp?.protectLife?.monthlyFixedExpense,
        monthlyIncome: detailCnaOnPopUp?.protectIncome?.monthlyIncome,
        needTerm: detailCnaOnPopUp?.needTerm,
        needTotal: detailCnaOnPopUp?.needTotal,
        product: detailCnaOnPopUp?.product,
        reminiscentName: detailCnaOnPopUp?.reminiscentName,
        status: detailCnaOnPopUp?.status,
        sumAssured: detailCnaOnPopUp?.sumAssured,
        topUp: detailCnaOnPopUp?.topUp,
        _id: detailCnaOnPopUp?._id,
      })
      setShowDetailPlan(true)
    }
  }, [isFocused, detailCnaOnPopUp])

  React.useEffect(() => {
    getCnaList({ page: pageIndex, limit: pageSize, dataFilters: dataFilters, dataSorts: [dataSorts] })
  }, [pageIndex])

  function getCnaList(body: any) {
    getAgentCnaList(body)
  }

  function convertCNAStatusToBadgeStatus(cnaStatus: string) {
    switch (cnaStatus) {
      case CnaStatus.DRAFT:
      case CnaStatus.DRAFT_INDEX:
        return 'warning'
      case CnaStatus.COMPLETED:
      case CnaStatus.COMPLETED_INDEX:
        return 'success'
      case CnaStatus.NEED_CONTACT_INFO:
      case CnaStatus.NEED_CONTACT_INFO_INDEX:
        return 'error'
      default:
        return 'primary'
    }
  }

  function onSubmitFilter(data: any) {
    setPageIndex(1)
    let newDataFilters = []
    if (data?.customerMail) {
      newDataFilters.push({
        name: 'customerEmail',
        value: data?.customerMail,
        type: 'String',
        operator: 'search',
      })
    }
    if (data?.customerPhone) {
      newDataFilters.push({
        name: 'customerPhone',
        value: data?.customerPhone,
        type: 'String',
        operator: 'search',
      })
    }
    if (data?.name) {
      newDataFilters.push({
        name: 'customerName',
        value: data?.name,
        type: 'String',
        operator: 'search',
      })
    }
    if (data?.status && data.status.length > 0) {
      newDataFilters.push({
        name: 'status',
        value: data?.status,
        type: 'String',
        operator: 'in',
      })
    } else {
      newDataFilters.push({
        name: 'status',
        value: Object.values(CnaStatus),
        type: 'String',
        operator: 'in',
      })
    }
    if (selectedDate) {
      newDataFilters.push({
        name: 'createdAt',
        value: moment(selectedDate, 'DD/MM/YYYY').add(7, 'hours').toISOString(),
        type: 'Date',
        operator: 'eq',
      })
    }
    setDataFilters([...newDataFilters])
    setFilterTable([...newDataFilters])
    getCnaList({ page: pageIndex, limit: pageSize, dataFilters: newDataFilters, dataSorts: [dataSorts] })
  }

  function onSort(dataName: string) {
    let newSort = {}
    if (dataSorts[dataName]) {
      if (dataSorts[dataName] === 'ASC') {
        newSort = { [dataName]: 'DESC' }
      } else {
        newSort = { [dataName]: 'ASC' }
      }
    } else {
      newSort = { [dataName]: 'ASC' }
    }
    setDataSorts(newSort)
    getCnaList({ page: pageIndex, limit: pageSize, dataFilters: dataFilters, dataSorts: [newSort] })
  }

  function onReviewCna(cnaId: string) {
    setShowDetailPlan(false)
    navigation.navigate('Solution', { ...(route.params as any), id: cnaId, mode: ViewMode.READ_ONLY })
  }

  function getSortIcon(fieldName: string) {
    if (fieldName) {
      if (dataSorts[fieldName] === 'ASC') {
        return icSortAsc
      } else if (dataSorts[fieldName] === 'DESC') {
        return icSortDesc
      } else {
        return icSort
      }
    } else {
      return icSort
    }
  }

  function onViewDetail(record: any) {
    setShowDetailPlan(true)
    setCurrentViewDetailPlan(record)
  }

  const columns = [
    {
      title: 'Tên gợi nhớ',
      dataIndex: 'reminiscentName',
      width: 160,
      headerStyle: {
        alignItems: 'flex-start',
      },
      cellStyle: {
        alignItems: 'flex-start',
      },
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('reminiscentName')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                paddingLeft: 20,
                textAlign: 'left',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('reminiscentName') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <TouchableOpacity
            onPress={() => {
              if (value) {
                setShowDetailPlan(true)
                setCurrentViewDetailPlan(record)
              }
            }}
          >
            <Text
              style={{
                color: '#2F7AEB',
                fontSize: 16,
                fontWeight: '600',
                paddingLeft: 20,
                textAlign: 'left',
                width: '100%',
              }}
            >
              {value ?? '-'}
            </Text>
          </TouchableOpacity>
        )
      },
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customerName',
      headerStyle: {
        alignItems: 'flex-start',
      },
      cellStyle: {
        alignItems: 'flex-start',
      },
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('customerName')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                paddingLeft: 20,
                textAlign: 'left',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('customerName') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <Text
            style={{
              color: '#505D6F',
              fontSize: 16,
              paddingLeft: 20,
              textAlign: 'left',
              width: '100%',
            }}
          >
            {value ?? '-'}
          </Text>
        )
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('createdAt')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('createdAt') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <Text
            style={{
              color: '#505D6F',
              fontSize: 16,
              textAlign: 'center',
              width: '100%',
            }}
          >
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </Text>
        )
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customerPhone',
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('customerPhone')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('customerPhone') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <Text
            style={{
              color: '#505D6F',
              fontSize: 16,
              textAlign: 'center',
              width: '100%',
            }}
          >
            {value ?? '-'}
          </Text>
        )
      },
    },
    {
      title: 'Email',
      dataIndex: 'customerEmail',
      headerStyle: {
        alignItems: 'flex-start',
      },
      cellStyle: {
        alignItems: 'flex-start',
        width: '100%',
      },
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('customerEmail')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                paddingLeft: 20,
                textAlign: 'left',
                width: '100%',
              }}
              numberOfLines={2}
              ellipsizeMode="head"
            >
              {title}
            </Text>
            <Image source={getSortIcon('customerEmail') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <Text
            style={{
              color: '#505D6F',
              fontSize: 16,
              paddingLeft: 20,
              textAlign: 'left',
              maxWidth: '100%',
              width: '100%',
            }}
          >
            {value ?? '-'}
          </Text>
        )
      },
    },
    {
      title: 'Tổng nhu cầu \n(triệu đồng)',
      dataIndex: 'needTotal',
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('needTotal')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('needTotal') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <Text
            style={{
              color: '#505D6F',
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {value ? vnNumberFormatter.format(parseFloat(value)) : '-'}
          </Text>
        )
      },
    },
    {
      title: 'Mức độ tiềm năng',
      dataIndex: 'potenialRating',
      width: 180,
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('potenialRating')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('potenialRating') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return <Rating ratingValue={value} enable={false} />
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 112,
      renderHeader: (title: string) => {
        return (
          <TouchableOpacity
            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FloatLeft]}
            onPress={() => onSort('status')}
          >
            <Text
              style={{
                color: '#505D6F',
                fontSize: 15,
                fontWeight: '600',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {title}
            </Text>
            <Image source={getSortIcon('status') as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        )
      },
      renderCell: (value: any, record: any) => {
        return (
          <TouchableOpacity
            onPress={() => {
              onViewDetail(record)
            }}
          >
            <BadgeComponent
              value={i18n.t(`CNA_STATUS.${value}`)}
              status={convertCNAStatusToBadgeStatus(`${value}`)}
              badgeStyle={{ width: 112 }}
            />
          </TouchableOpacity>
        )
      },
    },
  ]

  function setLastStep(id: any) {
    navigation.navigate(
      currentViewDetailPlan?.lastEditStep
        ? pageNumberRouteNameMapping[currentViewDetailPlan.lastEditStep]
        : 'NeedsCart',
      {
        ...(route.params as any),
        id: id,
      }
    )
  }

  function onCreateNewCNA() {
    clearCart({})
    createNewLocalCNA({})
    createUserProtect({})
    createUserProtectOriginal({})
    navigation.push('NeedsCart')
  }

  async function onDeleteCna(id: string) {
    await deleteCNA({ id })
      .unwrap()
      .then(() => {
        setShowDetailPlan(false)
        setCurrentViewDetailPlan({})
        setShowWarningDeletePlan(false)
        if (isMobileDevice) {
          navigation.replace('MobileHome')
        } else {
          loadSummaryAsync()
          setRecord(id)
          getCnaList({
            page: pageIndex,
            limit: pageSize,
            dataFilters: filterTable ? filterTable : dataFilters,
            dataSorts: [dataSorts],
          })
        }
      })
  }

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    loadSummaryAsync()
    wait(2000).then(() => setRefreshing(false))
  }, [])

  function onSendMailPdf() {
    setShowConfirmShareDialog(false)
    setShowLoadingSendEmailDialog(true)
    sendMailPdf({ id: currentViewDetailPlan?._id }).then((data: any) => {
      setShowLoadingSendEmailDialog(false)
      if (data.error) {
        // ** We have updated the logic of showing sending fail dialog here,
        // in all cases, sending PDF file successfully or fail always show success dialog
        // setShowSharePDFFailDialog(true)
        setShowSharePDFSuccessDialog(true)
      } else {
        setShowSharePDFSuccessDialog(true)
      }
    })
  }

  async function onUpdateAttributes(attrs: any) {
    let body = {
      ...attrs,
      _id: attrs.id,
    }
    const dataCheck = {
      id: currentViewDetailPlan?._id,
      potenialRating: currentViewDetailPlan?.potenialRating !== 0 ? currentViewDetailPlan?.potenialRating : null,
      notes: currentViewDetailPlan?.notes,
      _id: currentViewDetailPlan?._id,
    }

    if (JSON.stringify(body) !== JSON.stringify(dataCheck)) {
      await saveCNA(body)
      await loadSummaryAsync()
      setRecord(Math.random().toString(36).substring(2, 15))
      getCnaList({
        page: pageIndex,
        limit: pageSize,
        dataFilters: filterTable ? filterTable : dataFilters,
        dataSorts: [dataSorts],
      })
    }
  }

  function renderModals() {
    return (
      <>
        <Calendar
          open={showDatePicker}
          containerStyles={desktopStyles.calendarContainer}
          date={datePickerSelectedDate}
          color={'#ED1B2E'}
          locale={Locale.vi}
          onChange={(date?: Date) => {
            setShowDatePicker(false)
            setDatePickerSelectedDate(date)
            setSelectedDate(moment(date).format('DD/MM/YYYY'))
            setValue('createdAt', moment(date).format('DD/MM/YYYY'))
          }}
          onCancel={() => setShowDatePicker(false)}
        />
        <Dialog
          visible={showDetailPlan}
          title={i18n.t('CNA_LIST.PLAN_INFO')}
          type="details"
          showCloseIcon={true}
          contentStyle={{ paddingHorizontal: 20, paddingVertical: 0 }}
          dialogStyle={{ maxWidth: 690, maxHeight: Layout.window.height < 800 ? Layout.window.height * 0.95 : 625 }}
          onRemove={() => {
            setShowDetailPlan(false)
            setCurrentViewDetailPlan({})
            navigation.setParams({ ...(route.params as any), detail_id: undefined })
            navigation.navigate('CNAList')
          }}
          data={{
            ...currentViewDetailPlan,
            nick_name: currentViewDetailPlan?.reminiscentName ?? '',
            name: currentViewDetailPlan?.customerName ?? '',
            age: currentViewDetailPlan?.customerAge ?? '',
            phone: currentViewDetailPlan?.customerPhone ?? '',
            email: currentViewDetailPlan?.customerEmail ?? '',
            id_plan: currentViewDetailPlan?.cnaCode ?? '',
            date_create: currentViewDetailPlan?.createdAt
              ? moment(currentViewDetailPlan?.createdAt).format('DD/MM/YYYY HH:mm')
              : '',
            total: currentViewDetailPlan?.needTotal ? vnNumberFormatter.format(currentViewDetailPlan?.needTotal) : '',
            status: currentViewDetailPlan?.status,
            monthly_income: currentViewDetailPlan?.monthlyIncome,
            fixed_money: currentViewDetailPlan?.monthlyFixedExpense,
            marital_status: currentViewDetailPlan?.maritalStatus
              ? i18n.t(`MARITAL_STATUS.${currentViewDetailPlan?.maritalStatus}`)
              : '',
            potentialRating: currentViewDetailPlan?.potenialRating,
            notes: currentViewDetailPlan?.notes,
          }}
          disablePreview={
            `${currentViewDetailPlan?.status}` !== CnaStatus.COMPLETED &&
            `${currentViewDetailPlan?.status}` !== CnaStatus.COMPLETED_INDEX &&
            `${currentViewDetailPlan?.status}` !== CnaStatus.NEED_CONTACT_INFO &&
            `${currentViewDetailPlan?.status}` !== CnaStatus.NEED_CONTACT_INFO_INDEX
          }
          disableShare={!currentViewDetailPlan?.customerEmail}
          onEditCna={(item: any) => {
            clearCNADetailPopUp({})
            setShowDetailPlan(false)
            setCurrentViewDetailPlan({})
            setLastStep(item._id)
          }}
          onDeleteCna={(item: any) => {
            setShowWarningDeletePlan(true)
          }}
          onShareCna={(item: any) => setShowConfirmShareDialog(true)}
          onReviewCna={(item: any) => onReviewCna(item._id)}
          onUpdateAttributes={onUpdateAttributes}
          onSSOPruquote={(cnaId: string) => handleSSOPruquote?.(cnaId)}
        ></Dialog>
        <Dialog
          visible={showWarningDeletePlan}
          type="delete"
          title={i18n.t('CNA_LIST.DELETE_PLAN')}
          dataDelete={{
            title: i18n.t('CNA_LIST.CONFIRM_DELETE'),
            description: i18n.t('CNA_LIST.DELETE_WARNING'),
          }}
          loadingDeleteCna={loadingDeleteCNA}
          dialogStyle={{ maxWidth: 400 }}
          onOk={() => {
            onDeleteCna(currentViewDetailPlan?._id)
          }}
          onRemove={() => setShowWarningDeletePlan(false)}
        ></Dialog>
        {/* Confirm share pdf dialog */}
        <Dialog
          type="delete"
          visible={showConfirmShareDialog}
          showCloseIcon
          dataDelete={{
            title: i18n.t('SAVE_FORM.SHARE_PDF'),
            description: i18n.t('SAVE_FORM.CONFIRM_SHARE_PDF'),
          }}
          okText={i18n.t('COMMON.YES')}
          cancelText={i18n.t('COMMON.NO')}
          onRemove={() => setShowConfirmShareDialog(false)}
          dialogStyle={{ maxWidth: 400 }}
          onOk={onSendMailPdf}
          onCancel={() => setShowConfirmShareDialog(false)}
        />
        {/* Loading send mail pdf */}
        <Dialog
          type="default"
          visible={showLoadingSendEmailDialog}
          showCloseIcon={false}
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
        >
          <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Text style={[globalStyles.TextCommon]}>{`${i18n.t('SAVE_FORM.SENDING_PDF_TO_EMAIL')} ${
              currentViewDetailPlan?.customerEmail
            }`}</Text>
            <SizedBox height={15} />
            <CircleProgressIndicator />
          </View>
        </Dialog>
        {/* Share pdf successfully dialog */}
        <Dialog
          type="default"
          visible={showSharePDFSuccessDialog}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
          onRemove={() => {
            setShowSharePDFSuccessDialog(false)
          }}
        >
          <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Image source={icFirecracker as ImageSourcePropType} style={{ width: 72, height: 72 }} />
            <SizedBox height={15} />
            <Text style={[globalStyles.TextCommon]}>{i18n.t('SAVE_FORM.SHARE_SUCCESSFUL')}</Text>
          </View>
        </Dialog>
        {/* Share pdf fail dialog */}
        <Dialog
          type="default"
          visible={showSharePDFFailDialog}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
          onRemove={() => {
            setShowSharePDFFailDialog(false)
          }}
        >
          <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Image source={icChain as ImageSourcePropType} style={{ width: 72, height: 72 }} />
            <SizedBox height={15} />
            <Text style={[globalStyles.TextCommon, { textAlign: 'center' }]}>{i18n.t('SAVE_FORM.SHARE_FAIL')}</Text>
          </View>
        </Dialog>
      </>
    )
  }

  function renderDatePickerComponent() {
    if (Platform.OS === 'web') {
      return (
        <View style={desktopStyles.filterDateContainer}>
          <Controller
            control={control}
            rules={{ ...validateDate, required: false }}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                style={desktopStyles.filterLabel}
                placeholder={i18n.t('COMMON.CREATED_DATE')}
                value={
                  selectedDate && selectedDate.length >= 10 && moment(selectedDate).isValid()
                    ? moment(selectedDate).format('DD/MM/YYYY')
                    : selectedDate
                }
                onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
                  setSelectedDate((e.target as any).value)
                  onChange((e.target as any).value)
                }}
              />
            )}
            name="createdAt"
            defaultValue=""
          />
          <TouchableOpacity onPress={() => setShowDatePicker(true)}>
            <Image source={icDropDownLight as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        </View>
      )
    }
    {
      return (
        <View style={desktopStyles.filterDateContainer}>
          <TextInput style={desktopStyles.filterLabel} placeholder={i18n.t('COMMON.CREATED_DATE')} />
          <TouchableOpacity>
            <Image source={icDropDownLight as ImageSourcePropType} style={globalStyles.Icon12} />
          </TouchableOpacity>
        </View>
      )
    }
  }

  function renderFilters() {
    return (
      <View style={desktopStyles.filterContainer}>
        {renderDatePickerComponent()}
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextInputWithIcon
              placeholder={i18n.t('CNA_LIST.INPUT_PHONE')}
              containerStyle={desktopStyles.filterInputContainer}
              placeholderTextColor="#A7ACB6"
              onChange={onChange}
              textStyle={desktopStyles.filterInput}
            />
          )}
          name="customerPhone"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextInputWithIcon
              placeholder={i18n.t('CNA_LIST.INPUT_EMAIL')}
              containerStyle={desktopStyles.filterInputEmailContainer}
              placeholderTextColor="#A7ACB6"
              onChange={onChange}
              textStyle={desktopStyles.filterInput}
            />
          )}
          name="customerMail"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <Select
              selectOption={{
                options: [
                  { label: i18n.t(`CNA_STATUS.ALL`), value: undefined },
                  { label: i18n.t(`CNA_STATUS.${CnaStatus.COMPLETED}`), value: CnaStatus.COMPLETED },
                  { label: i18n.t(`CNA_STATUS.${CnaStatus.DRAFT}`), value: CnaStatus.DRAFT },
                  { label: i18n.t(`CNA_STATUS.${CnaStatus.NEED_CONTACT_INFO}`), value: CnaStatus.NEED_CONTACT_INFO },
                ],
                style: desktopStyles.filterDropdownStatus,
                dropDownContainerStyle: desktopStyles.dropDownContainerStyle,
                listItemLabelStyle: desktopStyles.listItemLabelStyle,
                labelStyle: desktopStyles.labelStyle,
                placeholderStyle: desktopStyles.placeholderStyle,
                placeholder: i18n.t('COMMON.STATUS'),
                arrowUpIcon: <Image source={icDropDownLight as ImageSourcePropType} style={globalStyles.Icon12} />,
                arrowDownIcon: <Image source={icDropDownLight as ImageSourcePropType} style={globalStyles.Icon12} />,
                multiple: true,
                value: getValues('status')
                  ? getValues('status')?.map((e: string) => (e ? i18n.t(`CNA_STATUS.${e}`) : 'Tất cả'))
                  : [],
                defaultValue: defaultValue,
              }}
              onChange={(value?: Array<string | undefined>) => {
                if (value && value?.includes(undefined)) {
                  setValue('status', undefined)
                } else {
                  setValue('status', value)
                }
              }}
            />
          )}
          name="status"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextInputWithIcon
              placeholder={i18n.t('CNA_LIST.INPUT_NAME')}
              containerStyle={desktopStyles.filterInputNameContainer}
              placeholderTextColor="#A7ACB6"
              onChange={onChange}
              textStyle={desktopStyles.filterInput}
            />
          )}
          name="name"
          defaultValue=""
        />
        <TouchableOpacity style={desktopStyles.filterButtonContainer} onPress={handleSubmit(onSubmitFilter)}>
          <Image source={icSearch as ImageSourcePropType} style={globalStyles.Icon25} />
        </TouchableOpacity>
      </View>
    )
  }

  function renderPaging(totalPage?: number, currentPage?: number) {
    return (
      <Pagination
        total={cnaList?.totalRecords ?? 0}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageChanged={(page) => {
          setPageIndex(page)
        }}
      />
    )
  }

  if (isMobileDevice) {
    return (
      <View style={[globalStyles.mobileContainer]}>
        <StatusBar style="light" />
        <View style={[mobileStyles.appBarContainer]}>
          <ImageBackground source={imgMobileHeaderBg as ImageSourcePropType} style={mobileStyles.appBarImageBg}>
            {userInfo?.profilePicture ? (
              <Image source={{ uri: userInfo?.profilePicture }} style={mobileStyles.avatar} />
            ) : (
              <Image source={imgAvatarMask} style={mobileStyles.avatar} />
            )}
            <View style={{ marginLeft: 20 }}>
              <Text style={{ color: '#FFF', fontSize: 16 }}>Welcome,</Text>
              <Text style={{ color: '#FFF', fontSize: 18, fontWeight: 'bold', marginTop: 8 }}>
                ID {userInfo?.agentCode}
              </Text>
            </View>
          </ImageBackground>
        </View>
        <View style={[globalStyles.mobileContent, { top: 131 }]}>
          <ScrollView
            contentContainerStyle={[
              mobileStyles.content,
              globalStyles.Background,
              { marginBottom: Platform.OS === 'ios' ? -34 : 0 },
            ]}
            style={globalStyles.Background}
            refreshControl={
              Platform.OS === 'web' ? undefined : (
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} style={{ paddingHorizontal: 0 }} />
              )
            }
          >
            {/* Summary */}
            <SummaryPlan dataUpdate={summaryList} />

            {/* Plan need review */}
            <PlanNeedReview navigation={navigation} updateRecord={record} onViewDetail={onViewDetail} />
          </ScrollView>
        </View>
        {renderModals()}
      </View>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <AppHeader navigation={navigation} agentCode={userInfo?.agentCode} />
      <ScrollView
        contentContainerStyle={[globalStyles.content]}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {/* Title */}
        <View style={desktopStyles.titleContainer}>
          <Text style={desktopStyles.label}>{i18n.t('CNA_LIST.INDIVIDUAL_FINANCE_PLAN')}</Text>
          <NextButton
            title={i18n.t('CNA_LIST.CREATE_NEW_CNA')}
            onPress={onCreateNewCNA}
            buttonStyle={desktopStyles.createCNAButton}
          />
        </View>
        {/* Summary */}
        <SummaryPlan dataUpdate={summaryList} />

        {/* Plan need review */}
        <PlanNeedReview navigation={navigation} updateRecord={record} onViewDetail={onViewDetail} />

        {/* List CNA */}
        <View style={desktopStyles.cnaListContainer}>
          <Text style={desktopStyles.cnaListTitle}>{i18n.t('CNA_LIST.PLAN_LIST')}</Text>
          {isTabletDevice ? (
            <View style={desktopStyles.tableFilterContainerTablet}>
              <View style={desktopStyles.filterContainerTablet}>{renderFilters()}</View>
              <View style={desktopStyles.pagingContainerTablet}>{renderPaging()}</View>
            </View>
          ) : (
            <View style={desktopStyles.tableFilterContainer}>
              <View style={desktopStyles.filterContainer}>{renderFilters()}</View>
              <View style={desktopStyles.pagingContainer}>{renderPaging()}</View>
            </View>
          )}
          {errors.createdAt && (
            <ValidateRequiredError
              title={i18n.t('VALIDATE.DATE')}
              style={{ marginLeft: 26, marginTop: -15, marginBottom: 15 }}
            />
          )}
          <TableWithTitle
            loading={loadingCnaList}
            columns={columns as Array<ColumnProps>}
            dataSource={cnaList && cnaList.items ? cnaList.items : []}
            tableHead={[]}
            tableTitle={[]}
            tableData={[]}
            headerContainerStyle={{
              backgroundColor: '#F7F8FA',
              paddingHorizontal: 20,
            }}
            rowStyle={{
              borderBottomWidth: 0,
              backgroundColor: '#FFF',
              paddingHorizontal: 20,
              paddingVertical: 10,
              borderTopWidth: 1,
              borderColor: '#EFF0F3',
            }}
            headerStyle={{
              color: '#505D6F',
              fontSize: 14,
              fontWeight: 'normal',
            }}
            isHaveTitle={true}
            titleStyle={{
              fontWeight: 'normal',
              fontSize: 14,
              color: '#505D6F',
            }}
            bodyStyle={{
              fontSize: 14,
              fontWeight: '600',
              color: '#505D6F',
              paddingVertical: 10,
            }}
            bodyContainerStyle={{ width: '100%' }}
          />
          <View style={desktopStyles.tableFilterContainer}>
            <Spacer />
            <View style={desktopStyles.pagingContainer}>{renderPaging()}</View>
          </View>
        </View>
      </ScrollView>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(CNAListScreen)

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F7F8FA',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    paddingBottom: 43,
  },
  content: {
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    paddingBottom: 43,
    marginTop: 61,
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  label: {
    fontSize: 26,
    fontWeight: 'bold',
    flex: 1,
  },
  createCNAButton: {
    marginTop: 0,
    paddingHorizontal: 16,
  },
  summaryContainer: {
    flexDirection: 'row',
    paddingVertical: 16,
  },
  highlightPlanContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
  },
  highlightPlanArea: {
    padding: 20,
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    flex: 1,
    height: 289,
  },
  highlightPlanAreaHeaderContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  highlightPlanAreaSwitch: {
    flex: 1,
    marginLeft: 36,
  },
  highlightPlanAreaTitle: {
    color: '#505D6F',
    fontSize: 18,
    fontWeight: 'bold',
  },
  cnaListContainer: {
    width: '100%',
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    marginTop: 42,
  },
  cnaListTitle: {
    color: '#505D6F',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 22,
    marginLeft: 16,
  },
  tableFilterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 14,
    paddingHorizontal: 16,
    zIndex: 1000,
  },
  tableFilterContainerTablet: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 14,
    paddingHorizontal: 16,
    minHeight: 60,
    zIndex: 1000,
    flexWrap: 'wrap',
  },
  filterContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // flexWrap: 'wrap',
  },
  filterContainerTablet: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: 1,
  },
  filterDateContainer: {
    width: 140,
    height: 55,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    padding: 16,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  filterLabel: {
    color: '#A7ACB6',
    fontSize: 14,
    width: 100,
  },
  filterInputContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 55,
    backgroundColor: '#FFF',
    width: 160,
    paddingLeft: 0,
    fontSize: 14,
  },
  filterInputEmailContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 55,
    backgroundColor: '#FFF',
    width: 120,
    paddingLeft: 0,
    fontSize: 14,
  },
  filterInputNameContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 55,
    backgroundColor: '#FFF',
    width: 150,
    paddingLeft: 0,
    fontSize: 14,
  },
  filterInput: {
    fontSize: 14,
    width: '100%',
    paddingRight: 0,
  },
  filterDropdownStatus: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 55,
    backgroundColor: '#FFF',
    width: 120,
  },
  filterButtonContainer: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF',
    height: 55,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderWidth: 1,
    borderColor: '#D7DFFF',
  },
  pagingContainer: {},
  pagingContainerTablet: {
    width: '100%',
    zIndex: 0,
  },
  calendarContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: '#D7DFFF',
    borderWidth: 1,
    width: '101.5%',
    marginLeft: -1,
    paddingVertical: 0,
  },
  listItemLabelStyle: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  labelStyle: {
    fontWeight: 'normal',
    fontSize: 14,
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 15,
    fontWeight: 'normal',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F7F8FA',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    paddingBottom: 143,
  },
  appBarContainer: {
    width: '100%',
    height: 131,
  },
  appBarImageBg: {
    resizeMode: 'cover',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: 40,
    paddingTop: 60,
  },
  content: {
    paddingHorizontal: 16,
    padding: 0,
    width: '100%',
    paddingTop: 0,
    paddingBottom: 16,
    marginBottom: 0,
    overflow: 'scroll',
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  label: {
    fontSize: 26,
    fontWeight: 'bold',
    flex: 1,
  },
  createCNAButton: {
    marginTop: 0,
  },
  summaryContainer: {
    flexDirection: 'row',
    paddingVertical: 16,
  },
  highlightPlanContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
  },
  highlightPlanArea: {
    padding: 20,
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    flex: 1,
    height: 289,
  },
  highlightPlanAreaHeaderContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginBottom: 8,
  },
  highlightPlanAreaSwitch: {
    flex: 1,
    marginLeft: 36,
  },
  highlightPlanAreaTitle: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: 'bold',
  },
  cnaListContainer: {
    width: '100%',
    marginTop: 20,
  },
  cnaListTitle: {
    color: '#505D6F',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 22,
    marginLeft: 42,
  },
  tableFilterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 14,
    paddingHorizontal: 16,
    zIndex: 1000,
  },
  filterContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  filterDateContainer: {
    width: 150,
    height: 55,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    padding: 16,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  filterLabel: {
    color: '#A7ACB6',
    fontSize: 15,
    width: 100,
  },
  filterInputContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 55,
    backgroundColor: '#FFF',
    width: 179,
    paddingLeft: 0,
  },
  filterDropdownStatus: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 55,
    backgroundColor: '#FFF',
    width: 120,
  },
  filterButtonContainer: {
    paddingHorizontal: 33.7,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF',
    height: 55,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderWidth: 1,
    borderColor: '#D7DFFF',
  },
  pagingContainer: {},
  calendarContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: '#D7DFFF',
    borderWidth: 1,
    width: '101.5%',
    marginLeft: -1,
    paddingVertical: 0,
  },
  listItemLabelStyle: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  labelStyle: {
    fontWeight: 'normal',
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 15,
    fontWeight: 'normal',
  },
  avatar: {
    width: 55,
    height: 55,
    resizeMode: 'cover',
    borderRadius: 28,
  },
})
