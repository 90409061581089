import React from 'react'
import { View, StyleSheet, Text, ImageSourcePropType, Image } from 'react-native'
import imgWarnings from '../assets/images/ic_warning.png'
import moment from 'moment'
import Layout from '../constants/Layout'

type Props = {
  title: string
  fromDateTime: Date
  toDateTime: Date
}

function BannerNotification({ title, fromDateTime, toDateTime }: Props) {
  const isMobileDevice = Layout.isMobileDevice

  if (toDateTime && moment().isBefore(toDateTime)) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.container}>
          <Image source={imgWarnings as ImageSourcePropType} style={mobileStyles.icon} />
          <Text style={mobileStyles.title}>{title}</Text>
        </View>
      )
    } else
      return (
        <View style={desktopStyles.container}>
          <Image source={imgWarnings as ImageSourcePropType} style={desktopStyles.icon} />
          <Text style={desktopStyles.title}>{title}</Text>
        </View>
      )
  } else {
    return <View></View>
  }
}

export default BannerNotification

const desktopStyles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 8,
    backgroundColor: 'rgb(237, 27, 46)',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
    marginLeft: 8,
  },
  icon: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 8,
    backgroundColor: 'rgb(237, 27, 46)',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    marginLeft: 8,
  },
  icon: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },
})
