import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity, TextInput, Image } from 'react-native'
import { FilledButton, SizedBox, styles as globalStyles } from '../../../common'
import i18n from 'i18n-js'
import icStar from '../../../assets/images/star.png'
import icStarHighlight from '../../../assets/images/star-highlight.png'
import Layout from '../../../constants/Layout'

type IProps = {
  onClose: (rating: number, comment?: string) => void
  loading: boolean
}

function RatingDialog({ onClose, loading }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const [ratingValue, setRatingValue] = React.useState<number>(5)
  const [comment, setComment] = React.useState<string>()

  if (isMobileDevice) {
    return (
      <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', padding: 0 }]}>
        <View style={[globalStyles.DisplayFlexCol, globalStyles.Center]}>
          <Text style={[globalStyles.DialogTitle, ]}>{i18n.t('SOLUTION.RATING')}</Text>
          <Text style={[{ fontSize: 15, marginTop: 18, paddingHorizontal: 0, textAlign: 'center' }]}>
            {i18n.t('SOLUTION.RATING_SUGGEST')}
          </Text>
        </View>
        <View style={globalStyles.Center}>
          <View
            style={[
              {
                paddingHorizontal: 20,
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: 32,
                marginBottom: 32,
              },
            ]}
          >
            <View style={{ flexDirection: 'row' }}>
              {[1, 2, 3, 4, 5].map((e, i) => {
                return (
                  <TouchableOpacity style={{ marginLeft: i > 0 ? 30 : 0 }} key={i} onPress={() => setRatingValue(i + 1)}>
                    <Image
                      source={e <= ratingValue ? icStarHighlight : icStar}
                      style={globalStyles.Icon20}
                    />
                  </TouchableOpacity>
                )
              })}
            </View>
          </View>
          <View style={{ width: '100%' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 8 }}>{i18n.t('SOLUTION.FEEDBACK')}</Text>
              <TextInput
                multiline
                numberOfLines={4}
                style={styles.inputComment}
                value={comment}
                onChangeText={(s: string) => setComment(s)}
              />
          </View>
          <FilledButton
            title={i18n.t('COMMON.CONFIRM')}
            onPress={() => {
              onClose(ratingValue, comment)
            }}
            buttonStyle={globalStyles.CommonButton}
            titleStyle={{ fontSize: 14 }}
            loading={loading}
          />
          <SizedBox height={20} />
        </View>
      </View>
    )
  }

  return (
    <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', padding: 20 }]}>
      <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, globalStyles.FullWidth]}>
        <Text style={[globalStyles.DialogTitle, globalStyles.FullWidth]}>{i18n.t('SOLUTION.RATING')}</Text>
        <Text
          style={[globalStyles.FullWidth, { fontSize: 16, marginTop: 18, paddingHorizontal: 50, textAlign: 'center' }]}
        >
          {i18n.t('SOLUTION.RATING_SUGGEST')}
        </Text>
      </View>
      <View style={globalStyles.Center}>
        <View
          style={[
            globalStyles.FullWidth,
            { paddingHorizontal: 20, justifyContent: 'center', flexDirection: 'row', marginTop: 32, marginBottom: 32 },
          ]}
        >
          <View style={{ flexDirection: 'row' }}>
            {[1, 2, 3, 4, 5].map((e, i) => {
              return (
                <TouchableOpacity style={{ marginLeft: i > 0 ? 30 : 0 }} onPress={() => setRatingValue(i + 1)}>
                  <Image
                    source={e <= ratingValue ? icStarHighlight : icStar}
                    style={globalStyles.Icon40}
                  />
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
        <View style={{ width: '100%' }}>
          <View style={[globalStyles.FullWidth]}>
            <Text style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 8 }}>{i18n.t('SOLUTION.FEEDBACK')}</Text>
            <TextInput
              multiline
              numberOfLines={5}
              style={styles.inputComment}
              value={comment}
              onChangeText={(s: string) => setComment(s)}
            />
          </View>
        </View>
        <FilledButton
          title={i18n.t('COMMON.CONFIRM')}
          onPress={() => {
            onClose(ratingValue, comment)
          }}
          buttonStyle={globalStyles.CommonButton}
          titleStyle={{ fontSize: 14 }}
          loading={loading}
        />
      </View>
    </View>
  )
}

export default RatingDialog

const styles = StyleSheet.create({
  inputComment: {
    backgroundColor: '#FAFBFF',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderRadius: 10,
    textAlignVertical : 'top',
    marginBottom: 30,
    padding: 16,
  },
})
