import React from 'react'
import WebView, { WebViewMessageEvent } from 'react-native-webview'

const isReady = () => typeof window !== 'undefined' && typeof (window as any).grecaptcha !== 'undefined'

let readyCheck: NodeJS.Timeout

export type Props = {
  className?: string
  onloadCallbackName?: string
  elementID?: string
  onloadCallback?: () => void
  verifyCallback?: (recaptchaToken: any) => void
  expiredCallback?: () => void
  render?: string
  siteKey?: string
  theme?: string
  type?: string
  verifyCallbackName?: string
  expiredCallbackName?: string
  size?: string
  tabindex?: string
  hl?: string
  badge?: string
  url?: string
  onMessage?: (event: WebViewMessageEvent) => void
  onCancelVerified?: () => void
}

type IState = {
  ready: any
  widget: any
}

const defaultProps = {
  elementID: 'g-recaptcha',
  onloadCallback: undefined,
  onloadCallbackName: 'onloadCallback',
  verifyCallback: undefined,
  verifyCallbackName: 'verifyCallback',
  expiredCallback: undefined,
  expiredCallbackName: 'expiredCallback',
  render: 'onload',
  siteKey: undefined,
  theme: 'light',
  type: 'image',
  size: 'normal',
  tabindex: '0',
  hl: 'en',
  badge: 'bottomright',
}

export class GoogleReCaptcha extends React.Component<Props, IState> {
  static defaultProps: {
    elementID: string
    onloadCallback: undefined
    onloadCallbackName: string
    verifyCallback: undefined
    verifyCallbackName: string
    expiredCallback: undefined
    expiredCallbackName: string
    render: string
    siteKey: undefined
    theme: string
    type: string
    size: string
    tabindex: string
    hl: string
    badge: string
  }
  constructor(props: Props) {
    super(props)
    this._renderGrecaptcha = this._renderGrecaptcha.bind(this)
    this.reset = this.reset.bind(this)
    this.execute = this.execute.bind(this)
    this.state = {
      ready: isReady(),
      widget: null,
    }

    if (!this.state.ready) {
      readyCheck = setInterval(this._updateReadyState.bind(this), 1000)
    }
  }

  componentDidMount() {
    if (this.state.ready) {
      this._renderGrecaptcha()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    const { render, onloadCallback } = this.props

    if (render === 'explicit' && onloadCallback && this.state.ready && !prevState.ready) {
      this._renderGrecaptcha()
    }
  }

  componentWillUnmount() {
    clearInterval(readyCheck)
  }

  reset() {
    const { ready, widget } = this.state
    if (ready && widget !== null) {
      ;(window as any).grecaptcha.reset(widget)
    }
  }

  execute() {
    const { ready, widget } = this.state
    if (ready && widget !== null) {
      ;(window as any).grecaptcha.execute(widget)
    }
  }

  _updateReadyState() {
    if (isReady()) {
      this.setState({
        ready: true,
      })

      clearInterval(readyCheck)
    }
  }

  _renderGrecaptcha() {
    this.setState(
      {
        widget: (window as any).grecaptcha.render(this.props.elementID, {
          sitekey: this.props.siteKey,
          callback: this.props.verifyCallback ? this.props.verifyCallback : undefined,
          theme: this.props.theme,
          type: this.props.type,
          size: this.props.size,
          tabindex: this.props.tabindex,
          hl: this.props.hl,
          badge: this.props.badge,
          'expired-callback': this.props.expiredCallback ? this.props.expiredCallback : undefined,
        }),
      },
      () => {
        if (this.props.onloadCallback) {
          this.props.onloadCallback()
        }
      }
    )
  }

  render() {
    if (this.props.render === 'explicit' && this.props.onloadCallback) {
      return (
        <div
          id={this.props.elementID}
          data-onloadcallbackname={this.props.onloadCallbackName}
          data-verifycallbackname={this.props.verifyCallbackName}
        />
      )
    } else {
      return (
        <div
          id={this.props.elementID}
          className="g-recaptcha"
          data-sitekey={this.props.siteKey}
          data-theme={this.props.theme}
          data-type={this.props.type}
          data-size={this.props.size}
          data-badge={this.props.badge}
          data-tabindex={this.props.tabindex}
        />
      )
    }
  }
}

GoogleReCaptcha.defaultProps = defaultProps

export default GoogleReCaptcha
