import { i18nAgentWording } from 'components/AgentWording'
import UserInfoData from '../../model/UserInfoData'
import * as React from 'react'
import { View, StyleSheet, TouchableOpacity, Text, FlatList, Image } from 'react-native'

import { Card, CheckBox } from 'react-native-elements'
import Layout from '../../constants/Layout'
export type SideTabViewProps = {
  data?: any
  screen?: any
  title?: string
  dataActive?: any
  userInfo?: UserInfoData
  style?: Record<string, unknown>
  onPress?: (item: any) => void
}

export function SideTabView({ data, userInfo, screen, onPress, dataActive }: SideTabViewProps) {

  const isTabletDevice = Layout.isTabletDevice;

  function switchCase(i: string) { 
    if (dataActive?._id) {
      let checkVadilateEdu = true
      dataActive?.eduPlan?.childrenDetails &&
      dataActive?.eduPlan?.childrenDetails.every((x:any) => {
          checkVadilateEdu = x.isSchool ? x.schoolFees !== 0 && x.schoolYears !== 0 : true
          if (!checkVadilateEdu) {
            return 
          }
          checkVadilateEdu = x.isUniversity ? x.universityFees !== 0 && x.universityYears !== 0 : true
          if (!checkVadilateEdu) {
            return 
          }
          checkVadilateEdu = x.isAfterUni ? x.afterUniFees !== 0 && x.afterUniYears !== 0 : true
          if (!checkVadilateEdu) {
            return 
          }
          return true
        })
        const checkSaveEdu =
        dataActive?.eduPlan?.childrenDetails && dataActive?.eduPlan?.childrenDetails?.some((x: any) => x.childAge === 0)

        const checkProtectEdu =  dataActive?.protectEdu?.childrenDetails  &&
        dataActive?.protectEdu?.childrenDetails?.every(function (x: any) {
          if (Number(x.childAge) > 0 &&  Number(x.childAge) <= Number(dataActive?.protectEdu?.eduPlanAge) &&  Number(x.tuition !== 0)) {
            return true;
          }
          return false
        }) 
      switch (i) { 
        case 'Bảo vệ thu nhập':
          if (Number(dataActive?.protectIncome?.subTotal) <= 0 && dataActive?.protectIncome?.isTemporaryCheck === true || Number(dataActive?.protectIncome?.monthlyIncome) > 10000 ||  dataActive?.protectLife?.monthlyFixedExpense && dataActive?.protectIncome?.monthlyIncome && Number(dataActive?.protectIncome?.monthlyIncome) < Number(dataActive?.protectLife?.monthlyFixedExpense)  ) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Bảo vệ tiêu chuẩn cuộc sống':
          if (Number(dataActive?.protectLife?.subTotal) <= 0 && dataActive?.protectLife?.isTemporaryCheck === true || 
          !dataActive?.protectLife?.month && dataActive?.protectLife?.isTemporaryCheck === true || 
          dataActive?.protectLife?.isSubsidy === true && !dataActive?.protectLife?.monthlySubsidy ||   dataActive?.protectLife?.monthlyFixedExpense && dataActive?.protectIncome?.monthlyIncome && Number(dataActive?.protectIncome?.monthlyIncome) < Number(dataActive?.protectLife?.monthlyFixedExpense) || Number(dataActive?.protectLife?.monthlyFixedExpense) > 10000 ||
          dataActive?.protectLife?.isSubsidy === true && !dataActive?.protectLife?.subsidyMonth ) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Bảo vệ tài sản':
          if (  (Number(dataActive?.protectAsset?.subTotal) <= 0  && dataActive?.protectAsset?.isTemporaryCheck === true) ||
          (dataActive?.protectAsset?.collateral?.isCheck === true && !dataActive?.protectAsset?.collateral?.total) ||
       (dataActive?.protectAsset?.saving?.isCheck === true && !dataActive?.protectAsset?.saving?.total) ||
       (dataActive?.protectAsset?.otherAssets?.isCheck === true && !dataActive?.protectAsset?.otherAssets?.total)) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Bảo vệ kế hoạch giáo dục cho con':
          if ((Number(dataActive?.protectEdu?.subTotal) <= 0 && dataActive?.protectEdu?.isTemporaryCheck === true) ||
          checkProtectEdu === false ||  dataActive?.protectEdu?.childrenDetails && dataActive?.protectEdu?.childrenDetails.some((x: any) =>  Number(x.tuition || 0) === 0)) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Bảo vệ trước bệnh hiểm nghèo':
          if (Number(dataActive?.hcFatalDisease?.subTotal) > 99 && dataActive?.hcFatalDisease?.isTemporaryCheck === true) {
            return 'active'
          } else {
            return 'deactive'
          }
        case 'Chăm sóc Y tế':
          if (Number(dataActive?.hcPlan?.subTotal) > 0 && dataActive?.hcPlan?.isTemporaryCheck === true ) {
            return 'active'
          } else {
            return 'deactive'
          }
        case 'Tích luỹ cho kế hoạch giáo dục':
          if ( (checkSaveEdu === true && dataActive?.eduPlan?.isSelect === true) ||
          (checkSaveEdu === null && dataActive?.eduPlan?.isSelect === true) ||
          (!checkVadilateEdu && dataActive?.eduPlan?.isSelect === true) || 
          Number(dataActive?.eduPlan?.subTotal) <= 0  && dataActive?.eduPlan?.isTemporaryCheck === true ) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Chuẩn bị cho tuổi hưu an nhàn':
          if ((Number(dataActive?.retirement?.subTotal) <= 0 && dataActive?.retirement?.isTemporaryCheck === true) ||
          (Number(dataActive?.retirement?.countDown) <= 0 && dataActive?.retirement?.isTemporaryCheck === true) ||
          (Number(dataActive?.retirement?.countDown) > 100 && dataActive?.retirement?.isTemporaryCheck === true)) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Đầu tư gia tăng tài sản':
          if ( (Number(dataActive?.investment?.subTotal) <= 0 &&
          dataActive?.investment?.isTemporaryCheck === true  ||
          !dataActive?.investment?.year) &&  dataActive?.investment?.isTemporaryCheck === true  || 
        (dataActive?.investment?.isPrepare === true &&
          Number(dataActive?.investment?.saveMoney) >= Number(dataActive?.investment?.money) &&
          dataActive?.investment?.isTemporaryCheck === true) ||
        (dataActive?.investment?.isPrepare === true &&
          Number(dataActive?.investment?.saveMoney) === 0 &&
          dataActive?.investment?.isTemporaryCheck === true)) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Để lại thừa kế':
          if (Number(dataActive?.inherit?.subTotal) > 0 && dataActive?.inherit?.isTemporaryCheck === true) {
            return 'active'
          } else {
            return 'deactive'
          }
        case 'Từ thiện':
          if (Number(dataActive?.charity?.subTotal) > 0 && dataActive?.charity?.isTemporaryCheck === true) {
            return 'active'
          } else {
            return 'deactive'
          }
  
        default:
          return 'active'
      }
    } else return ''
  }

  if (isTabletDevice) {
    return (
      <View style={styles.container}>
      <View style={tabletStyles.sideBar}>
        {data.filter((x: any) => x.code === "PROTECT").length > 0 &&
          <>
            <Text style={styles.Heading}>Bảo vệ</Text>
            <FlatList
              data={data.filter((x: any) => x.code === 'PROTECT') || []}
              keyExtractor={item => item.screen}
              style={[styles.flatListStyle]}
              renderItem={({ item, index }) => (
                <View >
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#F5E6FE' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                          <>
                            <CheckBox
                              title=''
                              checkedColor={'red'}
                              checked={true}
                            />
                            <Image
                              style={tabletStyles.tinyLogo}
                              source={{
                                uri: item.image
                              }}
                            />
                          </>
                          :
                          <>
                            <CheckBox
                              title=''
                              checked={false}
                            />
                            <Image
                              style={tabletStyles.tinyLogo}
                              source={{
                                uri: item.image
                              }}
                            />
                          </>
                        }
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#BE63F9' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>

                    </View>
                  </Card>
                </View>

              )}
            />
          </>
        }
        {data.filter((x: any) => x.code === 'EDUCATION').length > 0 &&
          <>
            <Text style={styles.Heading}>Giáo dục</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'EDUCATION') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#DFF3E7' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                         <>
                         <CheckBox
                           title=''
                           checkedColor={'red'}
                           checked={true}
                         />
                         <Image
                           style={tabletStyles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                       :
                       <>
                         <CheckBox
                           title=''
                           checked={false}
                         />
                         <Image
                           style={tabletStyles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                     }
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#27AE60' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Card>
                </View>

              )}
            />
          </>
        }

        {data.filter((x: any) => x.code === 'MEDICINE').length > 0 &&
          <>
            <Text style={styles.Heading}>Chăm sóc sức khỏe</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'MEDICINE') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#E3F8FA' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                         <>
                         <CheckBox
                           title=''
                           checkedColor={'red'}
                           checked={true}
                         />
                         <Image
                           style={tabletStyles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                       :
                       <>
                         <CheckBox
                           title=''
                           checked={false}
                         />
                         <Image
                           style={tabletStyles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                     }
                        
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#26C6DA' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>

                    </View>
                  </Card>
                </View>

              )}
            />
          </>
        }

        {data.filter((x: any) => x.code === 'RETIREMENT').length > 0 &&
          <View>
            <Text style={styles.Heading}>Hưu trí</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'RETIREMENT') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#FFF9DD' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                         <>
                         <CheckBox
                           title=''
                           checkedColor={'red'}
                           checked={true}
                         />
                         <Image
                           style={tabletStyles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                       :
                       <>
                         <CheckBox
                           title=''
                           checked={false}
                         />
                         <Image
                           style={tabletStyles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                     }
                      <Text style={[styles.titleList, item.screen === screen ? { color: '#FF9900' } : null]}>
                        {i18nAgentWording({
                          agentOfficeCode: userInfo?.officeCode,
                          wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                        })}
                      </Text>
                    </TouchableOpacity>
                    </View>
                  </Card>
                </View>

              )}
            />
          </View>
        }
        {data.filter((x: any) => x.code === 'OTHER').length > 0 &&
          <View>
            <Text style={styles.Heading}>Khác</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'OTHER') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#FFE6E2' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                          <>
                          <CheckBox
                            title=''
                            checkedColor={'red'}
                            checked={true}
                          />
                          <Image
                            style={tabletStyles.tinyLogo}
                            source={{
                              uri: item.image
                            }}
                          />
                        </>
                        :
                        <>
                          <CheckBox
                            title=''
                            checked={false}
                          />
                          <Image
                            style={tabletStyles.tinyLogo}
                            source={{
                              uri: item.image
                            }}
                          />
                        </>
                      }
                     
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#FC573B' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Card>
                </View>

              )}
            />
          </View>
        }



      </View>
    </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.sideBar}>
        {data.filter((x: any) => x.code === "PROTECT").length > 0 &&
          <>
            <Text style={styles.Heading}>Bảo vệ</Text>
            <FlatList
              data={data.filter((x: any) => x.code === 'PROTECT') || []}
              keyExtractor={item => item.screen}
              style={[styles.flatListStyle]}
              renderItem={({ item, index }) => (
                <View >
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#F5E6FE' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                          <>
                            <CheckBox
                              title=''
                              checkedColor={'red'}
                              checked={true}
                            />
                            <Image
                              style={styles.tinyLogo}
                              source={{
                                uri: item.image
                              }}
                            />
                          </>
                          :
                          <>
                            <CheckBox
                              title=''
                              checked={false}
                            />
                            <Image
                              style={styles.tinyLogo}
                              source={{
                                uri: item.image
                              }}
                            />
                          </>
                        }
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#BE63F9' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Card>
                </View>

              )}
            />
          </>
        }
        {data.filter((x: any) => x.code === 'EDUCATION').length > 0 &&
          <>
            <Text style={styles.Heading}>Giáo dục</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'EDUCATION') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#DFF3E7' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                         <>
                         <CheckBox
                           title=''
                           checkedColor={'red'}
                           checked={true}
                         />
                         <Image
                           style={styles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                       :
                       <>
                         <CheckBox
                           title=''
                           checked={false}
                         />
                         <Image
                           style={styles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                     }
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#27AE60' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Card>
                </View>

              )}
            />
          </>
        }

        {data.filter((x: any) => x.code === 'MEDICINE').length > 0 &&
          <>
            <Text style={styles.Heading}>Chăm sóc sức khỏe</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'MEDICINE') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#E3F8FA' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                         <>
                         <CheckBox
                           title=''
                           checkedColor={'red'}
                           checked={true}
                         />
                         <Image
                           style={styles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                       :
                       <>
                         <CheckBox
                           title=''
                           checked={false}
                         />
                         <Image
                           style={styles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                     }
                        
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#26C6DA' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>

                    </View>
                  </Card>
                </View>

              )}
            />
          </>
        }

        {data.filter((x: any) => x.code === 'RETIREMENT').length > 0 &&
          <View>
            <Text style={styles.Heading}>Hưu trí</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'RETIREMENT') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#FFF9DD' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                         <>
                         <CheckBox
                           title=''
                           checkedColor={'red'}
                           checked={true}
                         />
                         <Image
                           style={styles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                       :
                       <>
                         <CheckBox
                           title=''
                           checked={false}
                         />
                         <Image
                           style={styles.tinyLogo}
                           source={{
                             uri: item.image
                           }}
                         />
                       </>
                     }
                        
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#FF9900' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>

                    </View>
                  </Card>
                </View>

              )}
            />
          </View>
        }
        {data.filter((x: any) => x.code === 'OTHER').length > 0 &&
          <View>
            <Text style={styles.Heading}>Khác</Text>

            <FlatList
              data={data.filter((x: any) => x.code === 'OTHER') || []}
              style={styles.flatListStyle}
              keyExtractor={item => item.screen}
              renderItem={({ item, index }) => (
                <View key={item.screen}>
                  <Card containerStyle={[styles.cardContainer, item.screen === screen ? [styles.cardContainerCheck, { backgroundColor: '#FFE6E2' }] : null]}>
                    <View style={styles.card}>
                      <TouchableOpacity style={styles.cardItem} onPress={() => {
                        onPress && onPress(item);
                      }}>
                        {switchCase(item.title) === 'active' ?
                          <>
                          <CheckBox
                            title=''
                            checkedColor={'red'}
                            checked={true}
                          />
                          <Image
                            style={styles.tinyLogo}
                            source={{
                              uri: item.image
                            }}
                          />
                        </>
                        :
                        <>
                          <CheckBox
                            title=''
                            checked={false}
                          />
                          <Image
                            style={styles.tinyLogo}
                            source={{
                              uri: item.image
                            }}
                          />
                        </>
                      }
                     
                        <Text style={[styles.titleList, item.screen === screen ? { color: '#FC573B' } : null]}>
                          {i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`
                          })}
                        </Text>
                      </TouchableOpacity>

                    </View>
                  </Card>
                </View>

              )}
            />
          </View>
        }
      </View>
    </View>
  )
}

export default SideTabView

const cardWidth = 320;



const tabletStyles = StyleSheet.create({
  sideBar: {
    width: 270,
    flexDirection: 'column',
  },
  tinyLogo: {
    width: 50,
    height: 50,
    marginRight: 15
  },
  titleList: {
    fontWeight: 'bold',
    fontSize: 13,
    maxWidth: 150
  },
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // width: cardWidth,
    borderWidth: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 16,
    // paddingHorizontal: 16,
    padding: 15,
    backgroundColor: '#fff'
  },
  sideBar: {
    width: 300,
    flexDirection: 'column',
  },
  Heading: {
    color: '#505D6F',
    fontSize: 15,
    fontWeight: '700',
    marginBottom: 10,
    paddingVertical: 15
  },
  flatListStyle: {
    overflow: 'visible',
    flexDirection: 'column',
    paddingBottom: 16,
  },
  tinyLogo: {
    width: 60,
    height: 60,
    marginRight: 15
  },
  selectAllDisable: {
    color: '#BDBDBD',
  },
  selectAll: {
    color: '#D62828',
    fontSize: 14,
    fontWeight: '400',
  },
  titleList: {
    fontWeight: 'bold',
    maxWidth: 150
  },
  titleListHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    marginLeft: 8,
  },
  checkList: {
    margin: 0,
    padding: 0
  },
  cardItem: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center'
  },
  cardContainer: {
    borderWidth: 0,
    margin: 0,
    shadowColor: 'transparent',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    // position: 'relative',
    // width: 323,
  },
  cardContainerCheck: {
    borderTopRightRadius: 50,
    borderBottomEndRadius: 50,
    borderTopLeftRadius: 8,
    borderBottomStartRadius: 8,
  },


  card: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width: cardWidth,
    height: 76,
  },
  cardHeader: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 20,
    paddingBottom: 0,
    alignItems: 'center',
  }
});