import * as React from 'react'
import { Image, StyleSheet, View, TextInput } from 'react-native'

export type TextInputWithIconProps = {
  placeholder?: string
  iconLeft?: any
  iconRight?: any
  onChange?: (s: string) => void
  containerStyle?: Record<string, unknown>
  textStyle?: Record<string, unknown>
  placeholderTextColor?: any
}

export function TextInputWithIcon({
  placeholder,
  iconLeft,
  iconRight,
  onChange,
  containerStyle,
  textStyle,
  placeholderTextColor,
}: TextInputWithIconProps) {
  return (
    <View style={[style.textInputContainer, containerStyle]}>
      {iconLeft && <Image source={iconLeft} style={style.icon} />}
      <TextInput
        style={[style.textInput, textStyle]}
        placeholder={placeholder}
        onChangeText={onChange}
        placeholderTextColor={placeholderTextColor}
      />
      {iconRight && <Image source={iconRight} style={style.icon} />}
    </View>
  )
}

export default TextInputWithIcon

const style = StyleSheet.create({
  icon: {
    width: 30,
    height: 30,
  },
  textInputContainer: {
    flexDirection: 'row',
    marginTop: 40,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    borderRadius: 16,
    paddingHorizontal: 16,
    width: 'min-content',
  },
  textInput: {
    fontSize: 16,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderWidth: 0,
    borderColor: '#fff',
  },
})
