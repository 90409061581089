export const numberOnly = /^[0-9]*$/

export const validateRequired = {
  required: true,
}

export const validateAge = {
  required: true,
  min: 18,
  max: 65,
  pattern: numberOnly,
}

export const validateNumber = {
  required: true,
  min: 0,
  pattern: numberOnly,
}

export const validateMoney = {
  required: true,
  min: 0,
  pattern: numberOnly,
}
