// @ts-nocheck

import React, { Component } from 'react'
import { View, StyleSheet, Animated, Easing, Text, Platform, ImageSourcePropType,ImageBackground, Image } from 'react-native'
import ColumnChartItem from './column-chart-item'
import {initData, drawYAxis, drawYAxisLabels, drawGuideLine, drawXAxis, drawXAxisLabels, initDataInvest} from './common'
import {
  mathHelper,
  vnNumberFormatter,
  CircleProgressIndicator,
  styles as globalStyles
} from '../../common'
import backgroundTooltips from '../../assets/images/backgroundTooltips.png'
import backgroundTooltipsInvest from '../../assets/images/backgroundTooltipsInvest.png'

import 
  Layout
 from '../../constants/Layout'

type IProps = {
  defaultColumnWidth?: number | string | undefined
  maxColumnWidth?: number | string | undefined
  defaultColumnWidthInvest?: number | string | undefined
  width?: number | string | undefined
  height?: number | string | undefined
  loading?: boolean
  popUpStyle?: boolean
  extraInvest?: boolean
  productType?: string
  data?: Array<{ seriesName: string; data: Array<{ x: string; y: number }>; color: any }>
  chartInvesment?: Array<{ seriesName: string; data: Array<{ x: string; y: number }>; color: any }>
  wrapperStyle?: Record<string, unknown>
}

export default class ColumnChart extends Component<IProps> {
  
  constructor (props) {
    super(props)
    let defaultGap = this.props.defaultColumnWidth + this.props.defaultColumnMargin
    let newState = initData(this.props.data, this.props.height, defaultGap, this.props.numberOfYAxisGuideLine)
    let newStateInvest = initDataInvest(this.props.chartInvesment, this.props.height, defaultGap, this.props.numberOfYAxisGuideLine)
    this.state = {
      sortedData: newState.sortedData,
      dataSort :  newState.dataSort,
      max: newState.max,
      selectedIndex: null,
      fadeAnim: new Animated.Value(0),
      guideArray: newState.guideArray,
      gap: defaultGap,

      sortedDataInvest: newStateInvest.sortedDataInvest,
      dataSortInvest :  newStateInvest.dataSortInvest,
      max: newStateInvest.max,
      fadeAnimInvest: new Animated.Value(0),
      guideArrayInvest: newStateInvest.guideArrayInvest,
    }

    this.scrollView = null

    this.renderColumns = this.renderColumns.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.drawTooltip = this.drawTooltip.bind(this)
    this.renderTooltips = this.renderTooltips.bind(this)

    this.drawTooltipInvest = this.drawTooltipInvest.bind(this)
    this.renderTooltipsInvest = this.renderTooltipsInvest.bind(this)
  }



  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.data !== this.props.data || nextProps.data[0].data !== this.props.data[0].data) {
      this.setState(Object.assign({
        fadeAnim: new Animated.Value(0)
      }, initData(nextProps.data, this.props.height, this.state.gap, this.props.numberOfYAxisGuideLine,this.props.chartInvesment)), () => {
        Animated.timing(this.state.fadeAnim, { toValue: 1, easing: Easing.bounce, duration: 1000, useNativeDriver: true }).start()
      })
    }
    if (nextProps.chartInvesment !== this.props.chartInvesment || nextProps.chartInvesment[0]?.data !== this.props.chartInvesment[0]?.data) {
      this.setState(Object.assign({
        fadeAnimInvest: new Animated.Value(0)
      }, 
      initDataInvest(nextProps.chartInvesment, this.props.height, this.state.gap, this.props.numberOfYAxisGuideLine)), () => {
        Animated.timing(this.state.fadeAnimInvest, { toValue: 1, easing: Easing.bounce, duration: 1000, useNativeDriver: true }).start()
      })
    }
  }

  
  renderColumns (fadeAnim,isMobileDevice) {
    let seriesArray = this.state.sortedData
    let seriesCount = seriesArray.length
    let renderColumns = []
    if (seriesCount > 0) {
      let standardSeries = seriesArray[0]
      let dataCount = standardSeries.data.length
      for (let i = 0; i < dataCount; i++) {
        renderColumns.push(
          <ColumnChartItem key={i} seriesArray={this.state.sortedData}
            dataIndex={i}
            defaultWidth={this.props.defaultColumnWidth }
            defaultHeight={this.props.height + 20}
            maxWidth={this.props.maxColumnWidth}
            seriesCount={dataCount}
            defaultMargin={this.props.defaultColumnMargin}
            defaultBorderColor={this.props.defaultBorderColor}
            isSelected={i}
            isInvest={this.props.extraInvest}
            opacity={0.5}
            isMobile={isMobileDevice}
            tooltips={this.drawTooltip(i)}
            highlightColor={this.props.highlightColor}
             />
        )
      }
    }
    return (
      <Animated.View style={[styles.chartView, {
        width :'100%',
        position: 'absolute',
        left: -3,
        paddingHorizontal: 5,
        zIndex: 0,
        transform: [{scaleY: fadeAnim}],
        marginBottom: this.props.minValue && this.state.guideArray && this.state.guideArray.length > 0 ? -1 * this.state.guideArray[0][2] * this.props.minValue : null
      }]}>
         
        {renderColumns}
      </Animated.View>
    )
  }

  renderColumnsInvest (fadeAnimInvest,isMobileDevice) {
    let seriesArray = this.state.sortedDataInvest
    let seriesCount = seriesArray?.length
    let renderColumns = []
    if (seriesCount > 0) {
      let standardSeries = seriesArray[0]
      let dataCount = standardSeries.data.length
      for (let i = 0; i < dataCount; i++) {
        renderColumns.push(
          <ColumnChartItem key={i} seriesArray={this.state.sortedDataInvest}
            dataIndex={i}
            defaultWidth={this.props.defaultColumnWidthInvest}
            defaultHeight={this.props.height + 20}
            seriesCount={dataCount}
            maxWidth={this.props.maxColumnWidth}
            defaultBorderColor={this.props.defaultBorderColor}
            isSelected={i}
            investPopup={this.props.chartInvesment ? true : false}
            opacity={1}
            tooltips={this.drawTooltipInvest(i)}
            highlightColor={this.props.highlightColor}
             />
        )
      }
    }
    return (
      <Animated.View style={[styles.chartView, {
        width :'100%',
    
        transform: [{scaleY: fadeAnimInvest}],
        marginBottom: this.props.minValue && this.state.guideArrayInvest && this.state.guideArrayInvest.length > 0 ? -1 * this.state.guideArrayInvest[0][2] * this.props.minValue : null
      }]}>
         
        {renderColumns}
      </Animated.View>
    )
  }

  handleClick (event, index) {
    this.setState({
      selectedIndex: index
    }, () => {
      if (typeof this.props.onPress === 'function') {
        this.props.onPress(index)
      }
    })
  }

  renderContent (type) {
    switch (type) {
      default:
        '0%'
  }
}
  drawTooltip (selectedIndex) {
    if (typeof (selectedIndex) === 'number' && selectedIndex >= 0) {
      let standardSeries = this.state.sortedData[1];
      if (!standardSeries) {
        console.warn('standardSeries is null')
        return null
      }
      let seriesCount = this.state.sortedData.length
      let plusGap = 10 * seriesCount
      if (this.state.sortedData.length === 1) {
        plusGap = 0
      } else if (selectedIndex === standardSeries.data.length - 1) {
        plusGap = -50
      }
      // 차트 width를 마지막에 늘려야겠음.

      let left =   this.renderContent(selectedIndex);
      let tooltipRenders = []

      if (this.props.popUpStyle === true){
        for (let i = 0; i < this.state.sortedData.length; i++) {
          let series = this.state.sortedData[i]
         
          tooltipRenders.push(
            <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 5, alignItems: 'center',}}>
              <View style={[mobileChartStyles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
              <Text style={mobileChartStyles.tooltipValue}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))} triệu</Text>
            </View>
          )
        }
        return (
          <View style={[styles.tooltipWrapper, { left: -14, 
            minWidth: this.props.defaultColumnWidth,
            width: Platform.OS === 'web' ? 'max-content' : null,
          bottom : (this.state.dataSort[selectedIndex].ratioY + 25 ) }]}>
             <View style={{width: '100%'}}>
           <ImageBackground source={ this.props.extraInvest === false  ? backgroundTooltipsInvest as ImageSourcePropType : backgroundTooltips as ImageSourcePropType } imageStyle={{resizeMode: 'contain', borderRadius: 5}} style={styles.tooltip}>
          {tooltipRenders}
            </ImageBackground>
            </View>
          </View>
        )
      }
      

      if (Layout.isMobileDevice){
        for (let i = 0; i < this.state.sortedData.length; i++) {
          let series = this.state.sortedData[i]
         
          tooltipRenders.push(
            <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 5, alignItems: 'center',}}>
              <View style={[mobileChartStyles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
              <Text style={mobileChartStyles.tooltipValue}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))}</Text>
            </View>
          )
        }
        return (
          <View style={[mobileChartStyles.tooltipWrapper, { left: -16, 
            width: 'auto',
            minWidth: this.props.defaultColumnWidth + 25,
            bottom : this.props.extraInvest === false ? this.state.dataSort[selectedIndex]?.ratioY + 25  : (this.state.dataSortInvest[selectedIndex]?.ratioY + 25 )  }]}>
            <View style={{width: '100%'}}>
           <ImageBackground source={ this.props.extraInvest === false  ? backgroundTooltipsInvest as ImageSourcePropType : backgroundTooltips as ImageSourcePropType } imageStyle={{resizeMode: 'contain', borderRadius: 5}} style={styles.tooltip}>
          {tooltipRenders}
            </ImageBackground>
            </View>
          </View>
        )
      }

      if (Layout.isTabletDevice){
        for (let i = 0; i < this.state.sortedData.length; i++) {
          let series = this.state.sortedData[i]
          tooltipRenders.push(
            <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 10, alignItems: 'center',}}>
              <View style={[styles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
              <Text style={styles.tooltipValueTablet}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))} triệu</Text>
            </View>
          )
        }
        return (
          <View style={[mobileChartStyles.tooltipWrapper, { left:  -18, 
            width: 'max-content',
            minWidth: this.props.defaultColumnWidth + 25,
            bottom : this.props.extraInvest === false ? this.state.dataSort[selectedIndex]?.ratioY + 25  : (this.state.dataSortInvest[selectedIndex]?.ratioY + 25 )  }]}>
            <View style={{width: '100%'}}>
          <ImageBackground source={ this.props.extraInvest === false  ? backgroundTooltipsInvest as ImageSourcePropType : backgroundTooltips as ImageSourcePropType } imageStyle={{resizeMode: 'contain', borderRadius: 5}} style={styles.tooltip}>
          {tooltipRenders}
            </ImageBackground>
          </View>
          </View>
        )
      }

      for (let i = 0; i < this.state.sortedData.length; i++) {
        let series = this.state.sortedData[i]
        tooltipRenders.push(
          <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 10, alignItems: 'center',}}>
            <View style={[styles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
            <Text style={styles.tooltipValue}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))} triệu</Text>
          </View>
        )
      }
      return (
        <View style={[styles.tooltipWrapper, { left:  -18, 
          width: Platform.OS === 'web' ? 'max-content' : null,
          minWidth: this.props.defaultColumnWidth + 50,
          bottom : this.props.extraInvest === false ? this.state.dataSort[selectedIndex]?.ratioY + 25  : (this.state.dataSortInvest[selectedIndex]?.ratioY + 25 )  }]}>
             <View style={{width: '100%'}}>
          <ImageBackground source={ this.props.extraInvest === false  ? backgroundTooltipsInvest as ImageSourcePropType : backgroundTooltips as ImageSourcePropType } imageStyle={{resizeMode: 'contain', borderRadius: 5}} style={styles.tooltip}>
          {tooltipRenders}
            </ImageBackground>
          </View>
        </View>
      )
    } else {
      return null
    }
  }

  drawTooltipInvest (selectedIndex) {
    if (typeof (selectedIndex) === 'number' && selectedIndex >= 0) {
      let standardSeries = this.state.sortedDataInvest[1];
      if (!standardSeries) {
        console.warn('standardSeries is null')
        return null
      }
   
      let seriesCount = this.state.sortedDataInvest.length
      let plusGap = 10 * seriesCount
      if (this.state.sortedDataInvest.length === 1) {
        plusGap = 0
      } else if (selectedIndex === standardSeries.data.length - 1) {
        plusGap = -50
      }
      // 차트 width를 마지막에 늘려야겠음.

      let left =   this.renderContent(selectedIndex);
      let tooltipRenders = []

      if (this.props.popUpStyle === true){
        for (let i = 0; i < this.state.sortedDataInvest.length; i++) {
          let series = this.state.sortedDataInvest[i]
         
          tooltipRenders.push(
            <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 5, alignItems: 'center',}}>
              <View style={[mobileChartStyles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
              <Text style={mobileChartStyles.tooltipValue}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))} triệu</Text>
            </View>
          )
        }
        return (
          <View style={[styles.tooltipWrapper, { left: -13, 
            minWidth: this.props.defaultColumnWidth,
            width: Platform.OS === 'web' ? 'max-content' : 'auto',
            bottom : (this.state.dataSortInvest[selectedIndex].ratioY + 80 ) }]}>
                <View style={{width: '100%'}}>
            <ImageBackground source={backgroundTooltipsInvest as ImageSourcePropType} imageStyle={{borderRadius: 5,resizeMode: 'contain'}}  style={styles.tooltip}>
          {tooltipRenders}
            </ImageBackground>
            </View>
          </View>
        )
      }
      

      if (Layout.isMobileDevice){
        for (let i = 0; i < this.state.sortedDataInvest.length; i++) {
          let series = this.state.sortedDataInvest[i]
         
          tooltipRenders.push(
            <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 5, alignItems: 'center',}}>
              <View style={[mobileChartStyles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
              <Text style={mobileChartStyles.tooltipValue}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))}</Text>
            </View>
          )
        }
        return (
          <View style={[mobileChartStyles.tooltipWrapper, { left: -16, 
            width: 'auto',
            width: Platform.OS === 'web' ? 'max-content' : 'auto',
            bottom : (this.state.dataSortInvest[selectedIndex].ratioY + 70 ) }]}>
            <View style={{width: '100%'}}>
            <ImageBackground source={backgroundTooltipsInvest as ImageSourcePropType} imageStyle={{borderRadius: 5,resizeMode: 'contain'}}  style={styles.tooltip}>
          {tooltipRenders}
            </ImageBackground>
            </View>
          </View>
        )
      }

      if (Layout.isTabletDevice){
        for (let i = 0; i < this.state.sortedDataInvest.length; i++) {
          let series = this.state.sortedDataInvest[i]
         
          tooltipRenders.push(
            <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 10, alignItems: 'center',}}>
              <View style={[styles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
              <Text style={styles.tooltipValueTablet}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))} triệu</Text>
            </View>
          )
        }
        return (
          <View style={[mobileChartStyles.tooltipWrapper, { left:  -18, 
            width: 'max-content',
            minWidth: this.props.defaultColumnWidth + 25,
          bottom : (this.state.dataSortInvest[selectedIndex].ratioY + 130 ) }]}>
            <View style={{width: '100%'}}>
          <ImageBackground source={backgroundTooltipsInvest as ImageSourcePropType} imageStyle={{borderRadius: 5,resizeMode: 'contain'}}  style={styles.tooltipWithoutInvest}>
          {tooltipRenders}
            </ImageBackground>
          </View>
          </View>
        )
      }

      for (let i = 0; i < this.state.sortedDataInvest.length; i++) {
        let series = this.state.sortedDataInvest[i]
        tooltipRenders.push(
          <View key={'tooltipText-' + i} style={{flexDirection: 'row', marginBottom: 10, alignItems: 'center',}}>
            <View style={[styles.tooltipColor, {backgroundColor: !series.seriesColor ? this.props.primaryColor : series.seriesColor}]} />
            <Text style={styles.tooltipValue}>{vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(series.data[selectedIndex]['y'], 1000000, 0))} triệu</Text>
          </View>
        )
      }
      return (
        <View style={[styles.tooltipWrapper, { left:  -18, 
          width: Platform.OS === 'web' ? 'max-content' : null,
          minWidth: this.props.defaultColumnWidth + 50,
          bottom : (this.state.dataSortInvest[selectedIndex].ratioY + (this.props.extraInvest ? 130 : 50))}]}>
          <View style={{width: '100%'}}>
          <ImageBackground source={backgroundTooltipsInvest as ImageSourcePropType} imageStyle={{borderRadius: 5,resizeMode: 'contain'}}  style={styles.tooltipWithoutInvest}>
          {tooltipRenders}
            </ImageBackground>
          </View>
        </View>
      )
    } else {
      return null
    }
  }


  renderTooltips () {
    if (this.props.popUpStyle === true){
      return(
        this.state.sortedData.map((x, i) =>(
          <View key={i} style={styles.tooltipsWraper}>
             <Text style={[styles.colorTooltips,{backgroundColor:x.seriesColor }]}></Text>
              <Text style={popUpStyles.tooltipsText}>{x.seriesName} </Text>
              { i !== 0 && <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>}
            </View>
        ))
        )
    }
    if (Layout.isMobileDevice){
      return(
        this.state.sortedData.map((x, i) =>(
          <View key={i} style={mobileChartStyles.tooltipsWraper}>
             <Text style={[mobileChartStyles.colorTooltips,{backgroundColor:x.seriesColor }]}></Text>
              <Text style={mobileChartStyles.tooltipsText}>{x.seriesName} </Text>
              { i !== 0 && <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>{this.props.productType}</Text>}
            </View>
        ))
        )
    }

    if (Layout.isTabletDevice){
      return(
        this.state.sortedData.map((x, i) =>(
          <View key={i} style={styles.tooltipsWraper}>
          <Text style={[styles.colorTooltips,{backgroundColor:x.seriesColor }]}></Text>
           <Text style={styles.tooltipsText}>{x.seriesName}</Text>
           { i !== 0 && <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}> {this.props.productType}</Text>}
         </View>
        ))
        )
    }
    return(
      this.state.sortedData.map((x, i) =>(
        <View key={i} style={styles.tooltipsWraper}>
        <Text style={[styles.colorTooltips,{backgroundColor:x.seriesColor }]}></Text>
         <Text style={styles.tooltipsText}>{x.seriesName} </Text>
         { i !== 0 && <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}> {this.props.productType}</Text>}
       </View>
      ))
      )
  }

  renderTooltipsInvest () {
    if (this.props.popUpStyle === true){
      return(
        <View style={popUpStyles.tooltipsInvestWrap}>
        <View  style={[popUpStyles.tooltipsWraperInvest,{marginBottom: 0}]}>
        <View style={{width: '85%', margin: 'auto',}}>
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
          <Text style={[mobileChartStyles.colorTooltipsInvestLarge, {backgroundColor: '#50719F'}]}></Text>
         <Text style={{ fontWeight: '700', color: '#50719F',fontSize: 13}}>Số tiền trước khi đầu tư thêm</Text>
         </View>
         <View  style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
           <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#FFCF86'}]}></Text>
             <Text style={[mobileChartStyles.tooltipsTextInvest]}>Số tiền tham gia</Text>
             </View>
             <View style={{flexDirection: 'row', alignItems: 'center'}}>
             <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#83EBF9'}]}></Text>
             <Text style={mobileChartStyles.tooltipsTextInvest}>Số tiền nhận về</Text>
             <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
             </View>
                 
             </View>
          </View>
          </View>
          <View  style={[popUpStyles.tooltipsWraperInvest,{marginBottom: 0}]}>
          <View style={{width: '85%', margin: 'auto'}}>
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
          <Text style={[mobileChartStyles.colorTooltipsInvestLarge,{backgroundColor: '#2F4564'}]}></Text>
          <Text style={{fontWeight: '700', color: '#2F4564',fontSize: 13}}>Số tiền sau khi đầu tư thêm</Text>
         </View>
         <View  style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#FF9900'}]}></Text>
             <Text style={mobileChartStyles.tooltipsText}>Số tiền tham gia</Text>
             </View>
             <View style={{flexDirection: 'row', alignItems: 'center'}}>
             <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#26C6DA'}]}></Text>
             <Text style={mobileChartStyles.tooltipsText}>Số tiền nhận về</Text>
             <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
             </View>
             </View>
          </View>
          </View>
          </View>
        )
    }
    if (Layout.isMobileDevice){
      return(
        <View style={mobileChartStyles.tooltipsInvestWrap}>
        <View  style={[mobileChartStyles.tooltipsWraperInvest]}>
        <View style={{width: '85%', margin: 'auto', marginBottom: 30}}>
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 30}}>
          <Text style={[mobileChartStyles.colorTooltipsInvestLarge, {backgroundColor: '#50719F'}]}></Text>
         <Text style={{ fontWeight: '700', color: '#50719F',fontSize: 13}}>Số tiền trước khi đầu tư thêm </Text>
         </View>
         <View  style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
           <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#FFCF86'}]}></Text>
             <Text style={[mobileChartStyles.tooltipsTextInvest]}>Số tiền tham gia</Text>
             </View>
             <View style={{flexDirection: 'row', alignItems: 'center'}}>
             <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#83EBF9'}]}></Text>
             <Text style={mobileChartStyles.tooltipsTextInvest}>Số tiền nhận về</Text>
             <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
             </View>
                 
             </View>
          </View>
          </View>
          <View  style={[mobileChartStyles.tooltipsWraperInvest,{marginBottom: 0}]}>
          <View style={{width: '85%', margin: 'auto'}}>
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 30}}>
          <Text style={[mobileChartStyles.colorTooltipsInvestLarge,{backgroundColor: '#2F4564'}]}></Text>
          <Text style={{fontWeight: '700', color: '#2F4564',fontSize: 13}}>Số tiền sau khi đầu tư thêm</Text>
         </View>
         <View  style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#FF9900'}]}></Text>
             <Text style={mobileChartStyles.tooltipsText}>Số tiền tham gia</Text>
             </View>
             <View style={{flexDirection: 'row', alignItems: 'center'}}>
             <Text style={[mobileChartStyles.colorTooltipsInvestSmall,{backgroundColor: '#26C6DA'}]}></Text>
             <Text style={mobileChartStyles.tooltipsText}>Số tiền nhận về</Text>
             <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
             </View>
             </View>
          </View>
          </View>
          </View>
        )
    }

    if (Layout.isTabletDevice){
      return(
        <View style={styles.tooltipsInvestWrap}>
     <View  style={[styles.tooltipsWraperInvest, {borderRightColor: '#C4C4C4', borderRightWidth: 1,}]}>
     <View style={{width: '80%', margin: 'auto'}}>
       <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 30}}>
       <Text style={[styles.colorTooltipsInvestLarge, {backgroundColor: '#50719F'}]}></Text>
      <Text style={{paddingLeft: 30, fontWeight: '700', color: '#50719F',fontSize: 18}}>Số tiền trước khi đầu tư thêm </Text>
      </View>
      <View  style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
       <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#FFCF86'}]}></Text>
          <Text style={[styles.tooltipsTextInvest]}>Số tiền tham gia</Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#83EBF9'}]}></Text>
          <Text style={styles.tooltipsTextInvest}>Số tiền nhận về</Text>
          <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
          </View>
              
          </View>
       </View>
       </View>
       <View  style={[styles.tooltipsWraperInvest]}>
       <View style={{width: '80%', margin: 'auto'}}>
       <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 30}}>
       <Text style={[styles.colorTooltipsInvestLarge,{backgroundColor: '#2F4564'}]}></Text>
       <Text style={{paddingLeft: 30, fontWeight: '700', color: '#2F4564',fontSize: 18}}>Số tiền sau khi đầu tư thêm </Text>
      </View>
      <View  style={{flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
       <View style={{flexDirection: 'row', alignItems: 'center'}}>
       <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#FF9900'}]}></Text>
          <Text style={styles.tooltipsText}>Số tiền tham gia</Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#26C6DA'}]}></Text>
          <Text style={styles.tooltipsText}>Số tiền nhận về</Text>
          <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
          </View>
          </View>
       </View>
       </View>
       </View>
   )
    }
    return(
         <View style={styles.tooltipsInvestWrap}>
      <View  style={[styles.tooltipsWraperInvest, {borderRightColor: '#C4C4C4', borderRightWidth: 1,}]}>
      <View style={{width: '80%', margin: 'auto'}}>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 30}}>
        <Text style={[styles.colorTooltipsInvestLarge, {backgroundColor: '#50719F'}]}></Text>
       <Text style={{paddingLeft: 30, fontWeight: '700', color: '#50719F',fontSize: 18}}>Số tiền trước khi đầu tư thêm </Text>
       </View>
       <View  style={{flexDirection: 'row', alignItems: 'center', width: '80%', justifyContent: 'space-between'}}>
       <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#FFCF86'}]}></Text>
          <Text style={[styles.tooltipsTextInvest]}>Số tiền tham gia</Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#83EBF9'}]}></Text>
          <Text style={styles.tooltipsTextInvest}>Số tiền nhận về</Text>
          <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
          </View>
              
          </View>
        </View>
        </View>
        <View  style={[styles.tooltipsWraperInvest]}>
        <View style={{width: '80%', margin: 'auto'}}>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 30}}>
        <Text style={[styles.colorTooltipsInvestLarge,{backgroundColor: '#2F4564'}]}></Text>
        <Text style={{paddingLeft: 30, fontWeight: '700', color: '#2F4564',fontSize: 18}}>Số tiền sau khi đầu tư thêm </Text>
       </View>
       <View  style={{flexDirection: 'row', alignItems: 'center', width: '80%', justifyContent: 'space-between'}}>
       <View style={{flexDirection: 'row', alignItems: 'center'}}>
       <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#FF9900'}]}></Text>
          <Text style={styles.tooltipsText}>Số tiền tham gia</Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[styles.colorTooltipsInvestSmall,{backgroundColor: '#26C6DA'}]}></Text>
          <Text style={styles.tooltipsText}>Số tiền nhận về</Text>
          <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>  {this.props.productType}</Text>
          </View>
          </View>
        </View>
        </View>
        </View>
    )
  }


  render () {

    let {fadeAnim,fadeAnimInvest} = this.state
    if (this.state.sortedData && this.state.sortedData.length === 0) return null
    if (this.state.sortedDataInvest && this.state.sortedDataInvest.length === 0) return null

    if (this.props.popUpStyle === true){
      return (
        <View style={styles.wrapChart}>
        <View style={StyleSheet.flatten([mobileChartStyles.wrapper, this.props.wrapperStyle, {
          backgroundColor: this.props.backgroundColor,
    
        }])}>
          <View style={{paddingRight: 5, flexDirection: 'column-reverse', height: this.props.height + 120 }}>
            {this.props.showYAxisLabel &&
            drawYAxisLabels( this.props.extraInvest ? this.state.guideArrayInvest : this.state.guideArray, this.props.height + 20, this.props.minValue, this.props.labelColor, this.props.yAxisSymbol,Layout.isMobileDevice)
            }
            </View>
          <View style={mobileChartStyles.mainContainer}>
            {
              this.props.loading
              ? (
                <View style={styles.noDataContainer}>
                  <CircleProgressIndicator />
                </View>
              )
              : (
                <View ref={scrollView => this.scrollView = scrollView}  style={{width:'100%'}}>
                  <View style={{width:'100%'}}>
                    <View  style={[styles.chartContainer, {height: this.props.height + 120}]}>
                      {drawYAxis(this.props.yAxisColor)}
                      {drawGuideLine( this.props.extraInvest ? this.state.guideArrayInvest : this.state.guideArray, this.props.yAxisGridLineColor)}
                      {
                        this.props.extraInvest && 
                        this.renderColumnsInvest(fadeAnimInvest)
                      }
                      {this.renderColumns(fadeAnim)}  
                    </View>
                    {drawXAxis(this.props.xAxisColor)}
                    </View>
                    {drawXAxis(this.props.xAxisColor)}
                    <View >
                      {this.props.showXAxisLabel &&
                        drawXAxisLabels(this.state.sortedData[0].data, this.state.gap, this.props.labelColor, this.props.showEvenNumberXaxisLabel,this.state.selectedIndex,Layout.isMobileDevice,this.props.popUpStyle)}
                    </View>
                  </View>
              )
            }     
          </View>
        </View>
        {
            this.props.extraInvest ? 
              <View >{this.renderTooltipsInvest()}</View>
              : <View style={mobileChartStyles.tooltips}>{this.renderTooltips()}</View>
          }
        </View>
      )
    }
    if (Layout.isMobileDevice){
      return (
        <View style={styles.wrapChart}>
        <View style={StyleSheet.flatten([mobileChartStyles.wrapper, this.props.wrapperStyle, {
          backgroundColor: this.props.backgroundColor,
    
        }])}>
          <View style={{paddingRight: 5, flexDirection: 'column-reverse', height:  this.props.height + 150}}>

          {this.props.showYAxisLabel &&
            drawYAxisLabels( this.props.extraInvest ? this.state.guideArrayInvest : this.state.guideArray, this.props.height + 20, this.props.minValue, this.props.labelColor, this.props.yAxisSymbol,Layout.isMobileDevice)}
          </View>
          <View style={mobileChartStyles.mainContainer}>

            {
              this.props.loading
              ? (
                <View style={styles.noDataContainer}>
                  <CircleProgressIndicator />
                </View>
              )
              : (
                <View ref={scrollView => this.scrollView = scrollView}  style={{width:'100%'}}>
                  <View style={{width:'100%'}}>
                    <View  style={[styles.chartContainer, {height: this.props.height + 150}]}>
                    {drawYAxis(this.props.yAxisColor)}
                    {drawGuideLine( this.props.extraInvest ? this.state.guideArrayInvest : this.state.guideArray, this.props.yAxisGridLineColor)}
                      {
                        this.props.extraInvest && 
                        this.renderColumnsInvest(fadeAnimInvest,Layout.isMobileDevice)
                      }
                      {this.renderColumns(fadeAnim,Layout.isMobileDevice)}  
                    </View>
                    {drawXAxis(this.props.xAxisColor)}
                    <View >
                    {this.props.showXAxisLabel &&
                      drawXAxisLabels(this.state.sortedData[0].data, this.state.gap, this.props.labelColor, this.props.showEvenNumberXaxisLabel,this.state.selectedIndex,Layout.isMobileDevice)}
                    </View>
                  </View>
                </View>
              )
            }     
          </View>
        </View>
        {
            this.props.extraInvest ? 
              <View >{this.renderTooltipsInvest()}</View>
              : <View style={mobileChartStyles.tooltips}>{this.renderTooltips()}</View>
          }
        </View>
      )
    }

   
    return (
      <View style={styles.wrapChart}>
            <View style={StyleSheet.flatten([styles.wrapper, this.props.wrapperStyle, {
        backgroundColor: this.props.backgroundColor,
      }])}>
        <View style={{paddingRight: 5, flexDirection: 'column-reverse', height: this.props.extraInvest ?  this.props.height + 250 : this.props.height + 120 }}>
       
          {this.props.showYAxisLabel &&
            drawYAxisLabels( this.props.extraInvest ? this.state.guideArrayInvest : this.state.guideArray, this.props.height + 20, this.props.minValue, this.props.labelColor, this.props.yAxisSymbol,Layout.isMobileDevice)}
        </View>
        <View style={styles.mainContainer}>
          {
            this.props.loading
            ? (
              <View style={styles.noDataContainer}>
                <CircleProgressIndicator />
              </View>
            )
            : (
              <View ref={scrollView => this.scrollView = scrollView}  style={{width:'100%'}}>
                <View style={{width:'100%'}}>
                  <View  style={[styles.chartContainer, {height: this.props.extraInvest ?  this.props.height + 250 : this.props.height + 120}]}>
                    {drawYAxis(this.props.yAxisColor)}
                    {drawGuideLine( this.props.extraInvest ? this.state.guideArrayInvest : this.state.guideArray, this.props.yAxisGridLineColor)}
                      {
                        this.props.extraInvest && 
                        this.renderColumnsInvest(fadeAnimInvest,Layout.isMobileDevice)
                      }
                      {this.renderColumns(fadeAnim,Layout.isMobileDevice)}  
                  </View>
                  {drawXAxis(this.props.xAxisColor)}
                  <View >
                    {this.props.showXAxisLabel &&
                      drawXAxisLabels(this.state.sortedData[0].data, this.state.gap, this.props.labelColor, this.props.showEvenNumberXaxisLabel,this.state.selectedIndex,Layout.isMobileDevice)}
                  </View>
                </View>
              </View>
            )
          }     
        </View>
      </View>
          {
            this.props.extraInvest ? 
              <View style={styles.tooltipsInvest}>{this.renderTooltipsInvest()}</View>
              : <View style={styles.tooltips}>{this.renderTooltips()}</View>
          }
      </View>
  
    )
  }
}

const styles = StyleSheet.create({
  wrapChart: {
    width: '100%'
  },
  wrapper: {
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
    paddingHorizontal: 20,
    paddingTop: 0,
    paddingBottom: 20,
  },
  mainContainer: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    height: '100%',
    flex: 1,
  },
  tooltipsInvest: {
    padding: 15,
    marginTop: 40,
  },
  tooltipsInvestWrap: {
    borderColor: '#50719f3d',
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: 'row',
    paddingVertical: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  colorTooltipsInvestSmall: {
    height: 16,
    width: 16,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: 'red'
  },
  colorTooltipsInvestLarge: {
    height: 28,
    width: 28,
    marginRight: 20,
  },
  tooltipsWraperInvest: {
    flexDirection: 'column',
    width: '50%'
  },
  tooltipsTextInvest: {
    fontSize: 16,
    fontWeight: '600',
    color: '#505D6F'
  },

  tooltips: {
    flexDirection: 'row',
    width: 400,
    margin: 'auto',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorTooltips: {
    height: 16,
    width: 16,
    marginRight: 20,
    borderRadius: 8,
  },
  tooltipsWraper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltipsText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#505D6F'
  },

  chartContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    margin: 0,
    // height: 520,
    paddingRight: 10,
  },
  chartView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: '100%'
  },
  tooltipWrapper: {
    position: 'absolute',
    // height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  tooltip: {
    padding: 15,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipWithoutInvest: {
    padding: 15,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipTitle: {fontSize: 10},
  tooltipValue: {fontWeight: 'bold', fontSize: 15, color: '#fff',},
  tooltipValueTablet: {fontWeight: 'bold', fontSize: 14, color: '#fff'},
  tooltipColor: {
    width: 12,
    marginRight: 10,
    height: 12,
    borderRadius: 6,
  },
  noDataContainer: {
    height: 400,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
})

const popUpStyles = StyleSheet.create({
  tooltipsText: {
    fontSize: 11,
    color: '#505D6F'
  },
  tooltipsInvestWrap: {
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipsWraperInvest: {
    flexDirection: 'column',
    width: '48%',
    padding: 10,
    borderColor: '#50719F',
    borderWidth: 1,
    borderRadius: 12,
  },
});

const mobileChartStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    width: '100%',
    // overflow: 'hidden',
    padding: 10,
  },
  tooltips: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorTooltipsInvestLarge: {
    height: 12,
    width: 12,
    marginRight: 20,
  },
  colorTooltipsInvestSmall: {
    height: 7.5,
    width: 7.5,
    borderRadius: 9999999,
    marginRight: 10,
  },
  colorTooltips: {
    height: 12,
    width: 12,
    marginRight: 10,
    borderRadius: 6,
  },
  tooltipsTextInvest: {
    fontSize: 11,
    fontWeight: '600',
    color: '#505D6F'
  },

  tooltipsWraperPopup: {
    flexDirection: 'row',
    marginTop: 30,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
    margin: 'auto'
  },
  tooltipsInvestWrap: {
    borderColor: '#50719f3d',
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: 'column',
    paddingVertical: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  tooltipsWraper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltipsText: {
    fontSize: 11,
    color: '#505D6F'
  },
  tooltipsWraperInvest: {
    width: '100%',
    marginBottom: 30
  },
  tooltipWrapper: {
    position: 'absolute',
    // height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  tooltipsTextTablet: {
    fontSize: 14.925,
    fontWeight: '600',
    color: '#505D6F'
  },

  tooltip: {
    backgroundColor: '#505D6F',
    borderRadius: 5,
    borderColor: '#AAAAAA',
    borderWidth: 1,
    padding: 5,
    paddingBottom:0,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.8
  },
  tooltipTitle: {fontSize: 8},
  tooltipValue: {fontWeight: 'bold', fontSize: 8, color: '#fff'},
  tooltipColor: {
    width: 8,
    marginRight: 5,
    height: 8,
    borderRadius: 4,
  },
  mainContainer: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    height: '100%',
    flex: 1,
  },
})

ColumnChart.defaultProps = {
  data: [],
  chartInvesment: [],
  height: 100,
  productType: false,
  popUpStyle: false,
  defaultColumnWidth: 104,
  defaultColumnWidthInvest: 104,
  defaultColumnMargin: 20,
  defaultBorderColor: '#FFFFFF',
  primaryColor: '#297AB1',
  highlightColor: 'red',
  showEvenNumberXaxisLabel: true,
  initialScrollPosition: {x: 0, y: 0, animated: true},
  initialScrollTimeOut: 300,
  showYAxisLabel: true,
  showXAxisLabel: true,
  yAxisSymbol: ''
}