import React from 'react'
import { StyleSheet, Text, TouchableHighlight } from 'react-native';

export type ButtonQuantitProps = {
    backgroundColor?: string
    underlayColor ? : any
    label: string
    disabled?: boolean
    onPress?: () => void
  }

  
export default function ButtonQuantity ({backgroundColor, underlayColor, label, onPress, disabled }: ButtonQuantitProps) {

  return(
    <TouchableHighlight 
      style={[styles.btn, { backgroundColor : !disabled ? backgroundColor : '#fff'}]} 
      underlayColor={underlayColor}
      onPress={onPress}
      disabled={disabled}
    >
      <Text style={styles.label}>{label}</Text>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    justifyContent: 'center',  
    width: 27,
    height: 27,
    margin: 8,
    borderRadius: 8,
    borderWidth: 1.5,
    borderColor: '#E0E0E0'
  },
  label: { 
    color: '#E0E0E0',
    fontSize: 20,
    position: 'relative',
    top: -3
  },
});

