const primaryRed = '#E02020'
const primaryRedGradientStart = '#FD9481'
const primaryRedGradientEnd = '#FD9481'
const gray = '#999999'
const black = '#333333'
const lightGrey = '#ECEDF0'
const secondaryGreen = '#00D6B7'
const secondaryPink = '#FF8B8C'
const secondaryPurple = '#6E5EFF'
const secondaryYellow = '#FFC55F'
const white = '#FFFFFF'
const grey = '#F9F9F9'
const tintColorLight = '#2f95dc'
const tintColorDark = '#fff'
const primaryColor = '#ED1B2E'
const whiteColor = '#fff'
const blackColor = '#000'
const text = '#fff'
const background = '#F7F8FA'
const tabIconDefault = '#ccc'
const statusBarColor = '#D62828'
const placeHolderColor = '#E0E0E0'
const mainTextColor = '#2B2D42'
const titleTextColor = '#505D6F'
const commonTextColor = '#505D6F'
const accentTextColor = '#FF9900'
const primaryTextColor = 'ED1B2E'
const borderColor = '#ECF0FF'
const accentBackgroundColor = '#ECF0FF'
const disableTextColor = '#B8B8B8'
const textBoxBackground = '#FAFBFF'
const disabledColor = '#B8B8B8'
const lineColor = '#151515'

export default {
  light: {
    text: text,
    background: background,
    tint: tintColorLight,
    tabIconDefault: tabIconDefault,
    tabIconSelected: tintColorLight,
    primaryRed: primaryRed,
    primaryRedGradientStart: primaryRedGradientStart,
    primaryRedGradientEnd: primaryRedGradientEnd,
    gray: gray,
    black: black,
    lightGrey: lightGrey,
    secondaryGreen: secondaryGreen,
    secondaryPink: secondaryPink,
    secondaryPurple: secondaryPurple,
    secondaryYellow: secondaryYellow,
    white: white,
    grey: grey,
    statusBarColor: statusBarColor,
    placeHolderColor: placeHolderColor,
    mainTextColor: mainTextColor,
    borderColor: borderColor,
    lineColor: lineColor,
    accentBackgroundColor: accentBackgroundColor,
    titleTextColor: titleTextColor,
    accentTextColor: accentTextColor,
    primaryTextColor: primaryTextColor,
    disableTextColor: disableTextColor,
    commonTextColor: commonTextColor,
    textBoxBackground: textBoxBackground,
    disabledColor: disabledColor,
  },
  dark: {
    text: text,
    background: background,
    tint: tintColorLight,
    tabIconDefault: tabIconDefault,
    tabIconSelected: tintColorDark,
    primaryRed: primaryRed,
    primaryRedGradientStart: primaryRedGradientStart,
    primaryRedGradientEnd: primaryRedGradientEnd,
    gray: gray,
    black: black,
    lightGrey: lightGrey,
    secondaryGreen: secondaryGreen,
    secondaryPink: secondaryPink,
    secondaryPurple: secondaryPurple,
    secondaryYellow: secondaryYellow,
    white: white,
    grey: grey,
    statusBarColor: statusBarColor,
    placeHolderColor: placeHolderColor,
    mainTextColor: mainTextColor,
    borderColor: borderColor,
    lineColor: lineColor,
    accentBackgroundColor: accentBackgroundColor,
    titleTextColor: titleTextColor,
    accentTextColor: accentTextColor,
    primaryTextColor: primaryTextColor,
    disableTextColor: disableTextColor,
    commonTextColor: commonTextColor,
    textBoxBackground: textBoxBackground,
    disabledColor: disabledColor,
  },
  primaryRed: primaryRed,
  primaryRedGradientStart: primaryRedGradientStart,
  primaryRedGradientEnd: primaryRedGradientEnd,
  text: text,
  background: background,
  gray: gray,
  black: black,
  lightGrey: lightGrey,
  secondaryGreen: secondaryGreen,
  secondaryPink: secondaryPink,
  secondaryPurple: secondaryPurple,
  secondaryYellow: secondaryYellow,
  white: white,
  grey: grey,
  tintColorLight: tintColorLight,
  tintColorDark: tintColorDark,
  primaryColor: primaryColor,
  whiteColor: whiteColor,
  blackColor: blackColor,
  statusBarColor: statusBarColor,
  placeHolderColor: placeHolderColor,
  mainTextColor: mainTextColor,
  borderColor: borderColor,
  lineColor: lineColor,
  accentBackgroundColor: accentBackgroundColor,
  titleTextColor: titleTextColor,
  accentTextColor: accentTextColor,
  primaryTextColor: primaryTextColor,
  disableTextColor: disableTextColor,
  commonTextColor: commonTextColor,
  textBoxBackground: textBoxBackground,
  disabledColor: disabledColor,
}
