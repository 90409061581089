
// @ts-nocheck
import _ from 'lodash'
import React from 'react'
import {View, Text } from 'react-native'
import 
  Layout
 from '../../constants/Layout'


function flattenData (data) {
  let numberCount = 0
  let objectWithYCount = 0
  let multiSeriesCount = 0
  let length = data.length
  data.map((obj) => {
    if (typeof obj === 'number') {
      numberCount++
    } else if (typeof obj === 'object') {
      if (typeof obj.y === 'number') {
        objectWithYCount++
      } else if (Array.isArray(obj.data)) {
        multiSeriesCount++
      }
    }
  })

  if (numberCount === length || objectWithYCount === length) {
    return [{
      seriesName: '',
      data: data
    }]
  } else if (multiSeriesCount === length) {
    return data
  } else {
    return [{
      seriesName: '',
      data: []
    }]
  }
}

function getMaxValue (data) {
  let values = []

  data.map((value) => {
    if (typeof value === 'number') {
      values.push(value)
    } else if (typeof value === 'object') {
      if (typeof value.y === 'number') {
        values.push(value.y)
      } else if (Array.isArray(value.data)) {
        value.data.map((v) => {
          if (typeof v === 'number') {
            values.push(v)
          } else if (typeof v === 'object' && typeof v.y === 'number') {
            values.push(v.y)
          }
        })
      }
    }
  })

  if (values.length === 0) return 0
  
  return Math.max.apply(null, values)
}

export const initData = (dataProp, height, gap, numberOfPoints = 5,dataPropData ) => {
  let guideArray, max, sortedData,dataSort
  if (!dataProp || !Array.isArray(dataProp) || dataProp.length === 0) {
    return {
      sortedData: [],
      max: 0,
      guideArray: []
    }
  }

  max = Math.max(getMaxValue(dataPropData && dataPropData[0]?.data.length > 0 ? dataPropData : dataProp))
  guideArray = getGuideArray(max, height, numberOfPoints, dataProp)
  dataProp = flattenData(dataProp)
  sortedData = refineData(dataProp, max, height, gap)
  dataSort  = refineData(dataProp, max, height, gap).map(x => x.data).reduce( (final,current) => {
    for (var i =0 ;i < final.length; ++i){
      if (current[i].ratioY > final[i].ratioY){
        final[i].ratioY = current[i].ratioY;
      }else{
        final[i].ratioY = final[i].ratioY 
      }
    }
    return final 
  })
  return {
    selectedIndex: null,
    nowHeight: 200,
    nowWidth: 200,
    scrollPosition: 0,
    nowX: 0,
    nowY: 0,
    sortedData: sortedData,
    dataSort: dataSort,
    max: max,
    guideArray: guideArray,
  }
}

export const initDataInvest = (dataProp, height, gap, numberOfPoints = 5,) => {
  let guideArray, max, sortedData,dataSort
  if (!dataProp || !Array.isArray(dataProp) || dataProp.length === 0) {
    return {
      sortedData: [],
      max: 0,
      guideArray: []
    }
  }

  max = Math.max(getMaxValue(dataProp))
  guideArray = getGuideArray(max, height, numberOfPoints,dataProp)
  dataProp = flattenData(dataProp)
  sortedData = refineData(dataProp, max, height, gap)
  dataSort  = refineData(dataProp, max, height, gap).map(x => x.data).reduce( (final,current) => {
    for (var i =0 ;i < final.length; ++i){
      if (current[i].ratioY > final[i].ratioY){
        final[i].ratioY = current[i].ratioY;
      }else{
        final[i].ratioY = final[i].ratioY 
      }
    }
    return final 
  })
  return {
    selectedIndex: null,
    nowHeight: 200,
    nowWidth: 200,
    scrollPosition: 0,
    nowX: 0,
    nowY: 0,
    sortedDataInvest: sortedData,
    dataSortInvest: dataSort,
    maxInvest: max,
    guideArrayInvest: guideArray
  }
}


export const refineData = (flattenData, max, height, gap) => {
  let result = []
  flattenData.map((series) => {
    let dataProp = series.data
    let object = {
      seriesName: series.seriesName,
      seriesColor: series.color
    }
    let data = []
    let length = dataProp.length
    let simpleTypeCount = 0
    let objectTypeCount = 0

    for (let i = 0; i < length; i++) {
      let maxClone = max

      if (maxClone === 0) {
        maxClone = 1
      }
      let dataObject = {}
      if (typeof dataProp[i] === 'number') {
        simpleTypeCount++
        dataObject.ratioY = dataProp[i] / maxClone * height
        dataObject.y = dataProp[i]
        dataObject = {
          gap: i * gap,
          ratioY: dataProp[i] / maxClone * height,
          y: dataProp[i]
        }
      } else if (typeof dataProp[i] === 'object') {
        let isEmpty = false
        if (dataProp[i].y === null) {
          let nullCount = 0
          for (let j = i; j < dataProp.length; j++) {
            if (dataProp[j].y) {
              break
            } else {
              nullCount++
            }
          }
          dataProp[i].y = dataProp[i - 1].y + (dataProp[i + nullCount].y - dataProp[i - 1].y) / (nullCount + 1)
          isEmpty = true
          /* if (dataProp[i + 1] && dataProp[i - 1]) {
            dataProp[i].y = (dataProp[i - 1].y + dataProp[i + 1].y) / 2
            isEmpty = true
          } */
        }
        if (typeof dataProp[i].y === 'number' && dataProp[i].x) {
          objectTypeCount++
          dataObject = {
            gap: i * gap,
            ratioY: dataProp[i].y / maxClone * height,
            x: dataProp[i].x,
            y: dataProp[i].y,
            isEmpty: isEmpty
          }
        }
      }
   
      data.push(dataObject)
    }

    // validation
    let isValidate = false
    if (simpleTypeCount === length || objectTypeCount === length) {
      isValidate = true
    }

    if (isValidate) {
      object.data = data.sort((a, b) => {
        return a['gap'] - b['gap']
        // return a[0] - b[0]
      })
    } else {
      object.data = []
    }
    result.push(object)
  })
  return result
}

export const getGuideArray = (max, height, numberOfPoints = 3,dataProp) => {
  let x = max
  let min = dataProp[1].data[0]?.y > dataProp[0].data[0]?.y ? dataProp[1].data[0]?.y : dataProp[0].data[0]?.y
  let arr = []
  let length
  let temp
  let postfix = 'B'

  if (x === 0) {
    return []
  }
  x = x / 1000000000
  temp = 1000000000
  length = x.toString().length
  min = min  / 1000000000
  for (let i = 1; i < 4; i++) {
    if (i === 1) {
      arr.push([ (min * i) , (min/x)* height , 1 * temp / max * height])
    }else if (i === 2) {
      arr.push([((min + x)/2) , ((min + x) /2 /x)* height  , 1 * temp / max * height])
    }else{
      arr.push([(x) , height, 1 * temp / max * height])
    }
  }
  return arr
}

export const drawYAxis = (color = '#e0e0e0') => {
  return (
    <View style={{
      borderRightWidth: 0,
      borderColor: color,
      width: 1,
      height: '100%',
      marginRight: 0

    }} />

  )
}

export const drawYAxisLabels = (arr, height, minValue, color = '#BDBDBD', symbol='',isMobileDevice) => {
  if (isMobileDevice){
    return (
      <View style={{
        width: 20 + 5*symbol.length,
        height: height,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginBottom: minValue && arr && arr.length > 0 ? -1 * arr[0][2] * minValue : null,
      }}>
  
        {arr.length === 0 ? (
          null
        ) : arr.map((v, i) => {
        
          if (v[1] > height-5) return null
          return (
            <View
              key={'guide' + i}
              style={{
                left: 0,
                bottom: v[1] -  7,
                position: 'absolute'
              }}>
              <Text style={{fontSize: 9, color: color,fontWeight: 'bold'}}>{ Math.round(v[0]*1000) + ' ' + symbol}</Text>
            </View>
          )
        })}
  
      </View>
    )
  }
  
  return (
    <View style={{
      width: 50 + 5*symbol.length,
      height: height,
      justifyContent: 'flex-end',
      alignItems: 'end',
      marginBottom: minValue && arr && arr.length > 0 ? -1 * arr[0][2] * minValue : null,
    }}>

      {arr.length === 0 ? (
        null
      ) : arr.map((v, i) => {
      
        if (v[1] > height-5) return null
        return (
          <View
            key={'guide' + i}
            style={{
              bottom: v[1] - 10 ,
              position: 'absolute'
            }}>
            <Text style={{fontSize: 15, color: color,fontWeight: 'bold'}}>{ Math.round(v[0]*1000) + ' ' + symbol}</Text>
          </View>
        )
      })}

    </View>
  )
}
export const drawGuideLine = (arr, color = '#e0e0e0') => {
  return (
    <View style={{
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute'
    }}>

      {arr.map((v, i) => {
        return (
          <View
            key={'guide' + i}
            style={{
              width: '100%',
              borderTopWidth: 1,
               borderStyle: 'dashed',
              borderTopColor: color,
              bottom: v[1] - 2,
              position: 'absolute'
            }} />
        )
      })}

    </View>
  )
}

export const numberWithCommas = (x, summary = true) => {
  let postfix = ''
  if (summary) {
    if (x >= 1000 && x < 1000000) {
      postfix = 'K'
      x = Math.round(x / 100) / 10
    } else if (x >= 1000000 && x < 1000000000) {
      postfix = 'M'
      x = Math.round(x / 100000) / 10
    } else if (x >= 1000000000 && x < 1000000000000) {
      postfix = 'B'
      x = Math.round(x / 100000000) / 10
    }
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + postfix
}

export const drawXAxis = (color = '#e0e0e0') => {
  return (
    <View style={{
      width: '100%',
      borderTopWidth: 1,
      borderTopColor: color
    }} />
  )
}

export const drawXAxisLabels = (sortedData, gap, color = '#000000', showEvenNumberXaxisLabel,selectedIndex ,isMobileDevice,popUpStyle ) => {
  if (popUpStyle === true) {
    return (
      <View style={{
        width: '100%',
        paddingVertical: 10,
        flexDirection: 'row',
        height: 10
      }}>
        {sortedData.map((data, i) => {
          // if (data[3] && i % 2 === 1) {
          if (data['x'] || !showEvenNumberXaxisLabel) {
            return (
              <View key={'label' + i} style={{
                width:  `${100 / sortedData.length}%`,
                alignItems: 'center',
                marginLeft : -3
              }}>
                <Text style={{fontSize: 9,color:  selectedIndex === i || (i === 0 && !selectedIndex )?   color : '#BDBDBD',fontWeight: 'bold',fontSize:13}}>
                  {
                    // data[3]
                    data['x']
                  }
                </Text>
              </View>
            )
          } else {
            return null
          }
        })}
      </View>
    )
  }
  if (isMobileDevice){
    return (
      <View style={{
        width: '100%',
        paddingVertical: 10,
        marginLeft: 0,
        flexDirection: 'row',
        alignContent: 'center',
        height: 50
      }}>
        {sortedData.map((data, i) => {
          // if (data[3] && i % 2 === 1) {
          if (data['x'] || !showEvenNumberXaxisLabel) {
            return (
              <View key={'label' + i} style={{
                width:  `${100 / sortedData.length}%`,
                alignItems: 'center',
                marginLeft : i === 0 ? 0: -2
              }}>
                <Text style={{fontSize: 9,color:  selectedIndex === i || (i === 0 && !selectedIndex )?   color : '#BDBDBD',fontWeight: 'bold',fontSize:13}}>
                  {
                    // data[3]
                    data['x']
                  }
                </Text>
              </View>
            )
          } else {
            return null
          }
        })}
      </View>
    )
  }
  return (
    <View style={{
      width: '100%',
      paddingVertical: 10,
      marginLeft: -10,
      flexDirection: 'row',
      height: 10
    }}>
      {sortedData.map((data, i) => {
        // if (data[3] && i % 2 === 1) {
        if (data['x'] || !showEvenNumberXaxisLabel) {
          return (
            <View key={'label' + i} style={{
              width:  `${100 / sortedData.length}%`,
              alignItems: 'center',
              marginLeft : i === 0 ?  0 : null
            }}>
              <Text style={{fontSize: 15,color:  selectedIndex === i || (i === 0 && !selectedIndex )?   color : '#BDBDBD',fontWeight: 'bold',fontSize:16}}>
                {
                  // data[3]
                  data['x']
                }
              </Text>
            </View>
          )
        } else {
          return null
        }
      })}
    </View>
  )
}