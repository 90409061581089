import Layout from '../../constants/Layout'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'

export type BlockProps = {
  title?: string
  titleStyle?: Record<string, unknown>
  child?: JSX.Element
  showTitle?: boolean
  containerStyle?: Record<string, unknown>
  childContainerStyle?: Record<string, unknown>
  titleSuffix?: JSX.Element
  withChildBackground?: boolean
}

export function Block({
  title,
  titleStyle,
  child,
  showTitle = true,
  containerStyle,
  childContainerStyle,
  titleSuffix,
  withChildBackground = true,
}: BlockProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container]}>
        {showTitle && title && (
          <View style={[mobileStyles.titleContainer, titleStyle]}>
            <Text style={[mobileStyles.title, titleStyle]}>{title}</Text>
            {titleSuffix && <View>{titleSuffix}</View>}
          </View>
        )}
        <View style={[withChildBackground ? mobileStyles.childContainer : {}, childContainerStyle]}>{child}</View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={[tabletStyles.container, containerStyle]}>
        <View style={[globalStyles.DisplayFlexRow]}>
          {title && showTitle && <Text style={[tabletStyles.title, titleStyle]}>{title}</Text>}
          {titleSuffix && <View>{titleSuffix}</View>}
        </View>
        <View style={[withChildBackground ? tabletStyles.childContainer : {}, childContainerStyle]}>{child}</View>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, containerStyle]}>
      <View style={[globalStyles.DisplayFlexRow]}>
        {title && showTitle && <Text style={[desktopStyles.title, titleStyle]}>{title}</Text>}
        {titleSuffix && <View>{titleSuffix}</View>}
      </View>
      <View style={[withChildBackground ? desktopStyles.childContainer : {}, childContainerStyle]}>{child}</View>
    </View>
  )
}

export default Block

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  title: {
    color: '#505D6F',
    fontSize: 24,
    fontWeight: '700',
    marginLeft: 8,
    marginBottom: 15,
  },
  childContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: '#505D6F',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  title: {
    color: '#505D6F',
    fontSize: 20,
    fontWeight: '700',
    marginLeft: 8,
    marginBottom: 8,
  },
  childContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: '#DADADA',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 10,
  },
  title: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  childContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 10,
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
})
