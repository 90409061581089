import { dataSourceHealthCarePlan, dataSourceHealthCarePlanMaternity } from '../constants/Data'
import { MAX_MATERNITY_AGE, MIN_MATERNITY_AGE } from '../constants/Constants'
import CNADetail from '../model/CNADetail'
import { ProductType } from 'constants/Enum'

function isHospitalDataMaternity(age?: number, gender?: 'FEMALE' | 'MALE' | string) {
  if (!age || !gender) {
    return false
  }
  if (MIN_MATERNITY_AGE < age && MAX_MATERNITY_AGE > age && gender === 'FEMALE') {
    return true
  }
  return false
}

function getHospitalDataBasedOnAgeAndGender(age?: number, gender?: 'FEMALE' | 'MALE' | string) {
  if (isHospitalDataMaternity(age, gender)) {
    return dataSourceHealthCarePlanMaternity
  } else {
    return dataSourceHealthCarePlan
  }
}

function countTotalHealthCarePlanData({
  boarding,
  outPatient,
  dentistry,
  maternity,
  age,
  gender,
}: {
  boarding?: number
  outPatient?: number
  dentistry?: number
  maternity?: number
  age?: number
  gender?: string
}) {
  if (!age || !gender) {
    return (boarding ?? 0) + (outPatient ?? 0) + (dentistry ?? 0)
  }
  if (MIN_MATERNITY_AGE < age && MAX_MATERNITY_AGE > age && gender === 'FEMALE') {
    return (boarding ?? 0) + (outPatient ?? 0) + (dentistry ?? 0) + (maternity ?? 0)
  }
  return (boarding ?? 0) + (outPatient ?? 0) + (dentistry ?? 0)
}

function getHealthcarePlanDetail({
  boarding,
  outPatient,
  dentistry,
  maternity,
  age,
  gender,
}: {
  boarding?: number
  outPatient?: number
  dentistry?: number
  maternity?: number
  age?: number
  gender?: string
}) {
  if (isHospitalDataMaternity(age, gender)) {
    return {
      boarding: boarding,
      outPatient: outPatient,
      dentistry: dentistry,
      maternity: maternity,
    }
  } else {
    return {
      boarding: boarding,
      outPatient: outPatient,
      dentistry: dentistry,
    }
  }
}

/**
 * Validate APE value with multi conditions, please read BSR carefully before editing
 * @param cna Checking CNA
 * @param ape Result APE from formula
 * @return validate message translation code and value
 */
function validateAPE(cna?: CNADetail, ape?: number) {
  if (!cna || !ape) {
    return undefined
  }
  if (cna?.product === ProductType.ILP) {
    if (cna?.hcFatalDisease?.isSelect) {
      if (ape < 12000000) {
        return { translateMessageCode: 'VALIDATE.UPDATE_VALID_APE', value: 12000000 }
      }
    } else {
      if (ape < 11000000) {
        return { translateMessageCode: 'VALIDATE.UPDATE_VALID_APE', value: 11000000 }
      }
    }
  } else if (cna?.product === ProductType.ULP) {
    if (ape < 8000000) {
      return { translateMessageCode: 'VALIDATE.UPDATE_VALID_APE', value: 8000000 }
    }
  }
  return undefined
}

export default {
  getHospitalDataBasedOnAgeAndGender,
  countTotalHealthCarePlanData,
  isHospitalDataMaternity,
  getHealthcarePlanDetail,
  validateAPE,
} as const
