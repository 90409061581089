import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, FlatList } from 'react-native'
import icOrangeHeart from '../../../assets/images/orange-heart.png'
import icCircleCheck from '../../../assets/images/circle-check.png'
import { vnNumberFormatter, styles as globalStyles } from '../../../common'
import i18n from 'i18n-js'

type IProps = {
  data?: Array<{
    title: string
    value?: number
    show?: boolean
    isRequired?: boolean
    requiredValue?: string
    showPlaceholder?: boolean
  }>
  style?: Record<string, unknown>
}

function SummaryMainBenefit({ data, style }: IProps) {
  function renderItem(
    index: number,
    title: string,
    value: any,
    show?: boolean,
    isRequired?: boolean,
    requiredValue?: string,
    showPlaceholder?: boolean
  ) {
    return (
      <View style={desktopStyles.infoItemContainer}>
        <Image style={desktopStyles.infoItemIcon} source={icCircleCheck as ImageSourcePropType} />
        <Text style={desktopStyles.infoItemTitle}>{title}</Text>
        <Text style={[globalStyles.ErrorText, { fontSize: 10, marginTop: -2, marginLeft: 4 }]}>
          {isRequired ? (requiredValue ? requiredValue : '*') : ''}
        </Text>
        {show ? (
          <Text style={desktopStyles.infoItemValue}>
            {value ? `${vnNumberFormatter.format(value)} ${i18n.t('COMMON.MILLION_VND').toLowerCase()}` : showPlaceholder ? '-' : ''}
          </Text>
        ) : (
          <Text style={desktopStyles.infoItemValue}>-</Text>
        )}
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Image source={icOrangeHeart as ImageSourcePropType} style={desktopStyles.iconTitle} />
        <Text style={desktopStyles.title}>Quyền lợi bảo hiểm trước rủi ro</Text>
      </View>
      <View style={desktopStyles.infoContainer}>
        <FlatList
          data={data}
          renderItem={(item) => {
            return (
              <View key={item.index} style={{ width: '100%' }}>
                {renderItem(
                  item.index,
                  item.item.title,
                  item.item.value,
                  item.item.show,
                  item.item.isRequired,
                  item.item.requiredValue,
                  item.item.showPlaceholder
                )}
              </View>
            )
          }}
        />
      </View>
    </View>
  )
}

export default SummaryMainBenefit

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 4,
    borderColor: '#FFAFAF',
    borderWidth: 2,
    borderRadius: 12,
    height: 190,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 0,
    paddingHorizontal: 15.51,
    width: '100%',
  },
  infoItemContainer: {
    paddingVertical: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    flex: 1,
    fontSize: 12.44,
    color: '#F77F00',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
})
