import React, { useEffect } from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import { CircleProgressIndicator } from '../common'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { LinearProgress } from 'react-native-elements'
import Layout from '../constants/Layout'

const mapStateToProps = (state: RootState) => ({
  show: state.common.loading,
  // loadingValue: state.common.loadingValue,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type LoadingIndicatorProps = PropsFromRedux & {}

function LoadingIndicator({ show }: LoadingIndicatorProps) {
  const isMobileDevice = Layout.isMobileDevice
  const [showLoading, setShowLoading] = React.useState(false)

  useEffect(() => {
    if (show) {
      setShowLoading(show)
    } else {
      setTimeout(() => {
        setShowLoading(show)
      }, 2000)
    }
  }, [show])

  const renderCircleLoading = () => {
    return (
      <Modal transparent animationType="fade" visible={showLoading}>
        <View style={[mobileStyles.container]}>
          <CircleProgressIndicator />
        </View>
      </Modal>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container, { display: showLoading ? 'flex' : 'none' }]}>
        {showLoading && <LinearProgress variant="indeterminate" color="#F5A1AB" />}
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, { display: showLoading ? 'flex' : 'none' }]}>
      {showLoading && <LinearProgress variant="indeterminate" color="#ED1B2E" style={{ height: 3 }}/>}
    </View>
  )
}

export default connector(LoadingIndicator)

const desktopStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 52,
    left: 0,
    right: 0,
    zIndex: 1001,
  },
  containerBlurBackground: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0000001F',
    zIndex: 1001,
  },
})
