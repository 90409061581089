import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../store'

const mapStateToProps = (state: RootState) => ({
  show: state.common.loading,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type LoadingMaskProps = PropsFromRedux & {}

function LoadingMask({ show }: LoadingMaskProps) {
  return show ? <View style={styles.maskBg} /> : <></>
}

export default connector(LoadingMask)

const styles = StyleSheet.create({
  maskBg: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    alignItems: 'center',
    zIndex: 9999,
    elevation: 9999,
    position: 'absolute',
  },
})
