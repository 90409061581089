import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Needs from '../model/Needs'
import ProfileInfo from '../model/ProfileInfo'
import CustomAnalyzeService from '../services/CustomAnalyzeService'

export interface NeedsCartState {
  loading?: boolean
  needsCartList: Array<Needs>
  profileInfo?: ProfileInfo
}

const initialState: NeedsCartState = {
  loading: false,
  needsCartList: [],
  profileInfo: undefined,
}

export const saveNeedsCartAsync = createAsyncThunk('needs-car/save', async (req: any) => {
  // const response = await CustomAnalyzeService.saveNeedsCart(req)
  return req
})

const swapProducts = (origin: Array<Needs>, newItem: Needs) => {
  let sameCodeExistedItemIndex = origin.findIndex((e) => e.code === newItem.code)
  let temp = [...origin]
  if (sameCodeExistedItemIndex >= 0) {
    temp[sameCodeExistedItemIndex] = newItem
  } else {
    temp.push(newItem)
  }
  return temp
}

export const updateProfile = createAsyncThunk('needs-car/profile', async (req: any) => {
  return req
})

export const needsCardSlice = createSlice({
  name: 'needs-cart',
  initialState,
  reducers: {
    updateNeedsCartFromCache: (state, action: PayloadAction<Array<Needs>>) => {
      state.needsCartList = action.payload
    },
    saveNeedsCart: (state, action: PayloadAction<any>) => { 
      state.needsCartList = swapProducts(state.needsCartList, action.payload)
      state.loading = true
    },
    updateSelectedStatus: (state, action: PayloadAction<{ code: string; id: number; isChecked: boolean }>) => {
      let temp = [...state.needsCartList]
      let { code, id, isChecked } = action.payload
      temp.forEach((e, i) => {
        if (e.code === code) {
          if (e.data) {
            let tempData = [...e.data]
            e.data.forEach((m, n) => {
              if (m.id === id) {
                tempData[n] = { ...m, isChecked: isChecked }
              }
            })
            temp[i] = { ...e, data: tempData }
          }
        }
      })

      state.needsCartList = swapProducts(state.needsCartList, action.payload)
      state.loading = true
    },
    clearCart: (state, action: PayloadAction<ProfileInfo>) => {
      state.needsCartList = []
      state.profileInfo = undefined
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveNeedsCartAsync.pending, (state, action: PayloadAction<any>) => {
        state.loading = true
      })
      .addCase(saveNeedsCartAsync.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profileInfo = { ...action.payload, age: parseInt(action.payload.age?.toString() ?? '0') }
        state.loading = true
      })
  },
})

export const { saveNeedsCart, clearCart, updateNeedsCartFromCache, updateSelectedStatus } = needsCardSlice.actions

const { reducer } = needsCardSlice
export default reducer
