import HtmlView from '../../../common/components/HtmlView'
import React from 'react'
import { StyleSheet, View, Text, useWindowDimensions, FlatList } from 'react-native'

type IProps = {
  dataNote?: Array<{ content: string; index: any }>
  style?: Record<string, unknown>
}

function ExclusionTerms({ dataNote, style }: IProps) {
  const { width } = useWindowDimensions()

  function renderNoteItem(content: string, index: any) {
    return (
      <View style={desktopStyles.noteInfoItemContainer}>
        <Text style={desktopStyles.noteInfoItemValue}>
          <HtmlView content={content} />
        </Text>
      </View>
    )
  }

  return (
    <View style={desktopStyles.contentContainer}>
      <View style={[desktopStyles.container, style]}>
        <View style={desktopStyles.titleContainer}>
          <Text style={desktopStyles.title}>Ghi chú</Text>
        </View>
        <View style={desktopStyles.infoContainer}>
          <FlatList
            data={dataNote}
            renderItem={(item) => {
              return (
                <View key={item.index} style={{ width: '100%' }}>
                  {renderNoteItem(item.item.content, item.item.index)}
                </View>
              )
            }}
            numColumns={1}
          />
        </View>
      </View>
    </View>
  )
}

export default ExclusionTerms

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  pageTitle: {
    fontSize: 17,
    fontWeight: '600',
    color: '#505D6F',
    marginBottom: 13,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#ED1B2E',
    fontStyle: 'italic',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    marginTop: 11,
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 100,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 40,
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    fontSize: 13,
    fontWeight: '400',
    color: '#ED1B2E',
  },
  infoItemValue: {
    flex: 1,
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '400',
    marginLeft: 4,
    textAlign: 'left',
  },
  rowDataContentItem: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  rowDataContentItemTitle: {
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
    marginRight: 4,
    textAlign: 'left',
  },
  rowDataContentItemContent: {
    flex: 1,
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '400',
    marginLeft: 4,
    textAlign: 'left',
  },
  rowDataContentLabel: {
    fontWeight: '600',
    fontSize: 13,
    textAlign: 'left',
    marginBottom: 16,
  },
  contentContainer: {
    paddingHorizontal: 40,
    paddingVertical: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    flexDirection: 'column',
    flex: 1,
  },
  contentTitle: {
    color: '#585c6c',
  },
  rowDataContentContainer: {
    marginTop: 20,
  },
  noteInfoItemContainer: {
    paddingVertical: 9,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 10,
  },
  noteInfoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  noteInfoItemTitle: {
    fontSize: 13,
    fontWeight: '400',
    color: '#ED1B2E',
    fontStyle: 'italic',
  },
  noteInfoItemValue: {
    flex: 1,
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '400',
    marginLeft: 4,
    textAlign: 'left',
    fontStyle: 'italic',
    lineHeight: 20,
  },
})
