import React, { useEffect } from 'react'
import { View, StyleSheet, Text, ImageSourcePropType, Image} from 'react-native'
import {
  styles as globalStyles,
  BlockAlysys,
  InputData,
  Dialog,
  Table,
  Tooltips,
  SizedBox,
  ValidateRequiredError,
} from '../common'

import { Controller, useForm } from 'react-hook-form'
import { updateProfile } from '../slices/needsCartSlice'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { updateUserProtect } from '../slices/userSlice'
import { renderError } from '../helper/CnaHelper'
import { connect, ConnectedProps } from 'react-redux'
import Layout from '../constants/Layout'
import i18n from 'i18n-js'
import icDocument from '../assets/icon/document.png'

const mapStateToProps = (state: RootState) => ({
  hcFatalDisease: state.hcFatalDisease.hcFatalDisease,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  updateProfile,
  saveCNA,
  updateUserProtect,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SaveCancerScreen({
  userProtect,
  updateUserProtect,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice

  const [showPopup, setShowPopup] = React.useState(false)


  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  useEffect(() => {
    if (userProtect?.hcFatalDisease) {
      setValue('subTotal', userProtect?.hcFatalDisease?.subTotal)
    }
  }, [userProtect])

  useEffect(() => {
    if (userProtect && getValues('subTotal') > 0) {
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        hcFatalDisease: {
          ...userProtect?.hcFatalDisease,
          isTemporaryCheck: true,
          subTotal: Number(watch()?.subTotal),
        },
      }
      updateUserProtect({
        cnaData,
      })
    }
 
  }, [getValues('subTotal')])

  const columnsCustomer = [
    {
      title: 'Bệnh hiểm nghèo',
      dataIndex: 'name',
      width: '50%',
    },
    {
      title: '',
      dataIndex: 'age',
      text: 'center',
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'money',
      text: 'center',
      width: '20%',
    },
  ]

  const dataSourceCustomer = [
    { id: 1, name: 'Ung thư tuyến giáp', age: 60, money: 'triệu VND' },
    { id: 2, name: 'Đột quỵ', age: '80-150', money: 'triệu VND' },
    { id: 3, name: 'Nhồi máu cơ tim', age: '80-150', money: 'triệu VND' },
    { id: 4, name: 'Ung thư đại tràng', age: 850, money: 'triệu VND' },
    { id: 5, name: 'Ung thư vú', age: 700, money: 'triệu VND' },
    { id: 6, name: 'Ung thư gan', age: 800, money: 'triệu VND' },
    { id: 7, name: 'Ung thư phổi', age: 500, money: 'triệu VND' },
  ]

  

  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="bảo vệ trước bệnh hiểm nghèo"
          tooltips={
            <Tooltips
              title="Xem chi phí tham khảo tại Việt Nam"
              onPress={() => setShowPopup(true)}
              styles={{ position: 'absolute', right: 20, bottom: 25 }}
              image={true}
            />
          }
          borderColor="#26C6DA"
          child={
            <View>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 100,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_CANCER'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Bạn muốn được bảo vệ trước bệnh hiểm nghèo ở mức nào?"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => onChange(value)}
                    error={errors.subTotal && renderErrorMessage(errors.subTotal.message)}
                  />
                )}
                defaultValue=""
                name="subTotal"
              />
              <Text style={mobileStyles.helpText}>
                Số tiền bảo vệ trước bệnh hiểm nghèo tối thiểu là 100 triệu đồng
              </Text>
            </View>
          }
        />
        <Dialog
           title={
          <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
            <Image
             source={icDocument as ImageSourcePropType}
             style={globalStyles.iconDesktop}
           />
              <Text style={[globalStyles.DialogTitle]}>Chi phí tham khảo</Text>
           </View>
         }
          children={<Table dataSource={dataSourceCustomer} columns={columnsCustomer} columnWidth={200}  />}
          type="inform"
          titleContainerStyle={{marginBottom: 15}}
          dialogStyle={{width: '100%', paddingHorizontal: isMobileDevice? 15 : 20, paddingTop: 0 }}
          contentStyle={{ paddingHorizontal: isMobileDevice? 15 : 20 }}
          showCloseIcon
          iconHeader={true}
          onClose={() => setShowPopup(false)}
          visible={showPopup}
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="bảo vệ trước bệnh hiểm nghèo"
        tooltips={<Tooltips title="Xem chi phí tham khảo tại Việt Nam" onPress={() => setShowPopup(true)} image={true}  />}
        borderColor="#26C6DA"
        child={
          <View style={{marginTop: 10}}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 100,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_CANCER'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Bạn muốn được bảo vệ trước bệnh hiểm nghèo ở mức nào?"
                  helpText="Triệu đồng"
                  type="column"
                  placeholder="Nhập"
                  stylesInput={{ marginBottom: 10 }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                  error={errors.subTotal && renderErrorMessage(errors.subTotal.message)}
                  />
              )}
              defaultValue=""
              name="subTotal"
            />

            <Text style={desktopStyles.helpText}>Số tiền bảo vệ trước bệnh hiểm nghèo tối thiểu là 100 triệu đồng</Text>
          </View>
        }
      />
      <SizedBox width={24} />
      <Dialog
         title={
          <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
            <Image
             source={icDocument as ImageSourcePropType}
             style={globalStyles.iconDesktop}
           />
              <Text style={[globalStyles.DialogTitle]}>Chi phí tham khảo</Text>
           </View>
         }
        children={<Table dataSource={dataSourceCustomer} columns={columnsCustomer} columnWidth={200} />}
        type="inform"
        showCloseIcon
        titleContainerStyle={{marginBottom: 20}}
        dialogStyle={{ minWidth: 522, paddingTop: 0 }}
        contentStyle={{ paddingHorizontal: isMobileDevice? 15 : 20, paddingTop: 0 }}
        onClose={() => setShowPopup(false)}
        visible={showPopup}
      />
    </View>
  )
}

export default connector(SaveCancerScreen)

const desktopStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    height: '100%',
  },
  helpAnalysis: {
    flexDirection: 'row',
  },
  individualDataContainer: {
    backgroundColor: '#fff',
    marginRight: 25,
    maxHeight: 700,
  },
  safeTitle: {
    fontSize: 16,
    marginBottom: 30,
    fontWeight: '600',
  },
  helpText: {
    color: '#26C6DA',
    fontSize: 14,
  },
  heading: {
    marginTop: 0,
    paddingTop: 14,
  },
})

const mobileStyles = StyleSheet.create({
  helpText: {
    color: '#26C6DA',
    fontSize: 12,
  },
})
