import React from 'react'
import { StyleSheet, View, Text, useWindowDimensions, FlatList, Linking } from 'react-native'
import i18n from 'i18n-js'
import RenderHtml from 'react-native-render-html'
import Layout from '../../../constants/Layout'

type IProps = {
  /**
   * Array of notes
   *
   * content - content of notes
   *
   * index - note index
   */
  data?: Array<{ content: string; index: any }>
  style?: Record<string, unknown>
}

function SummaryNotes({ data, style }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const { width } = useWindowDimensions()

  function renderItem(content: string) {
    return (
      <View style={desktopStyles.noteInfoItemContainer}>
        <View style={desktopStyles.noteInfoItemValue}>
          <RenderHtml
            html={content}
            onLinkPress={(evt, href) => {
              Linking.openURL(href)
            }}
            tagsStyles={{ span: { textAlign: 'justify', whiteSpace: 'initial' } }}
          />
        </View>
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container, style]}>
        <View style={mobileStyles.titleContainer}>
          <Text style={mobileStyles.title}>{i18n.t('COMMON.NOTE')}</Text>
        </View>
        <View style={mobileStyles.infoContainer}>
          {data?.map((e, i: number) => {
            return (
              <View key={i} style={{ width: '100%' }}>
                {renderItem(e.content)}
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Text style={desktopStyles.title}>{i18n.t('COMMON.NOTE')}</Text>
      </View>
      <View style={desktopStyles.infoContainer}>
        <FlatList
          data={data}
          renderItem={(item) => {
            return (
              <View key={item.index} style={{ width: '100%' }}>
                {renderItem(item.item.content)}
              </View>
            )
          }}
          numColumns={1}
        />
      </View>
    </View>
  )
}

export default SummaryNotes

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 17.66,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 15,
    color: '#ED1B2E',
    fontWeight: 'bold',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    marginTop: 11,
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 100,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 40,
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    fontSize: 15,
    fontWeight: '400',
    color: '#ED1B2E',
  },
  infoItemValue: {
    flex: 1,
    fontSize: 15,
    color: '#585c6c',
    fontWeight: '400',
    marginLeft: 4,
    textAlign: 'left',
  },
  noteInfoItemContainer: {
    paddingVertical: 9,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 10,
  },
  noteInfoItemValue: {
    flex: 1,
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '400',
    textAlign: 'justify',
    fontStyle: 'italic',
    lineHeight: 20,
    // whiteSpace: 'initial',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 15,
    color: '#ED1B2E',
    fontWeight: 'bold',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  infoContainer: {
    flexDirection: 'column',
    width: '100%',
    marginTop: 11,
  },
  infoItemContainer: {
    paddingVertical: 9,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 20,
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    fontSize: 13,
    fontWeight: '400',
    color: '#ED1B2E',
  },
  infoItemValue: {
    flex: 1,
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '400',
    marginLeft: 4,
    textAlign: 'justify',
    // whiteSpace: 'initial',
  },
})
