import Needs from '../model/Needs'
import i18n from 'i18n-js'

export function checkCnaNeedsInputted(needsCartList?: Array<Needs>) {
  if (!needsCartList || needsCartList.length <= 0) {
    return false
  }
  let inputted = false
  for (let category of needsCartList) {
    if (category?.data?.find((e) => e?.isChecked === true && e?.qty > 0)) {
      inputted = true
      break
    }
  }
  return inputted
}


export function renderError(error?: any) {
  if (!error) {
    return null
  }
  if (error.type === 'required') {
    return i18n.t('VALIDATE.REQUIRED_DATA')
  }
  return null
}