import React, { useState, useEffect, useRef } from 'react'
import {
  ImageBackground,
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  ImageSourcePropType,
  Platform,
  ScrollView,
} from 'react-native'
import pruLogo from '../assets/images/pru-logo.png'
import imgJustBg from '../assets/images/just_bg.png'
import imgDecorTag from '../assets/images/decor_tag.png'
import imgBoxes from '../assets/images/boxes.png'
import { RootStackParamList, RootStackScreenProps } from '../types'
import { Link, useIsFocused } from '@react-navigation/native'
import icProfile from '../assets/icon/profile.png'
import icLock from '../assets/icon/lock.png'
import { SafeAreaView } from 'react-native-safe-area-context'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import { MAX_LOGIN_EFFORTS, RESET_EFFORT_TIMES_IN_SECONDS } from '../constants/Constants'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useForm, Controller } from 'react-hook-form'
import { FilledButton, SizedBox, ValidateRequiredError, GoogleReCaptchaComponent } from '../common'
import { RootState } from '../store'
import { signIn } from '../slices/authenSlice'
import { connect, ConnectedProps } from 'react-redux'
import i18n from 'i18n-js'
import toastService from '../services/toast/ToastService'
import ApiException from '../model/exception/ApiException'
import exceptionCode from '../data/exceptionCode'
import Layout from '../constants/Layout'
import { configEnv, EnvironmentSingleton } from '../config'
import BannerNotification from '../components/BannerNotification'
import { notiMaintenanceFrom2022March12thTo2022March13th } from '../data/noti'
import moment from 'moment'
import { AgentBlockingStatusCode } from '../constants/Enum'

const { googleSiteKey } = configEnv(new EnvironmentSingleton().getEnv())

const mapStateToProps = (state: RootState) => ({
  userInfoData: state.user.userInfo,
  loading: state.authen.loading,
  authenError: state.authen.error,
})

const mapDispatchToProps = {
  signIn,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'LogIn'> & PropsFromRedux & {}

function LogInScreen({ navigation, signIn, loading, authenError, userInfoData, route }: Props) {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  const isFocused = useIsFocused()

  const googleCatchaRef = useRef<any>()

  const isTabletDevice = Layout.isTabletDevice
  const isMobileDevice = Layout.isMobileDevice

  const [isSavePassword, setIsSavePassword] = useState<boolean>(false)
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(Platform.OS !== 'web')
  const [showVerifiedCaptchaDialog, setShowVerifiedCaptchaDialog] = useState<boolean>(false)
  const [captcha, setCaptcha] = useState<string>()

  useEffect(() => {
    getSavedUserName()
  }, [isFocused])

  useEffect(() => {
    handleAuthorizedCallback()
  }, [userInfoData])

  useEffect(() => {
    let errorData = authenError as ApiException
    if (authenError) {
      handleError(errorData)
    }
  }, [authenError])

  async function handleAuthorizedCallback() {
    let accessToken = await AsyncStorage.getItem('accessToken')
    if (userInfoData && accessToken) {
      onRouteHome()
    }
  }

  async function handleError(errorData: ApiException) {
    try {
      function showToastNextLoginTimeChance() {
        googleCatchaRef.current.onLoadRecaptcha()
        toastService.error(i18n.t('LOGIN.LOGIN_EFFORT', { x: RESET_EFFORT_TIMES_IN_SECONDS / 60 }))
      }
      if (errorData && errorData.code === exceptionCode.BAD_REQUEST) {
        let logInTime = JSON.parse(errorData.message as any).count
        let logInTimeNo = parseInt(logInTime ?? '0')
        if (logInTimeNo && MAX_LOGIN_EFFORTS - logInTimeNo > 0) {
          googleCatchaRef.current.onLoadRecaptcha()
          toastService.error(
            i18n.t('LOGIN.WRONG_USER_NAME_OR_PASSWORD', { x: MAX_LOGIN_EFFORTS - parseInt(logInTime ?? '0') })
          )
        } else {
          showToastNextLoginTimeChance()
        }
      } else if (errorData && errorData.code === exceptionCode.FORBIDDEN) {
        const messageStatusCode = JSON.parse(errorData.message as any).statusCode
        switch (messageStatusCode) {
          case AgentBlockingStatusCode.E001:
            toastService.error(i18n.t('MESS.USER_NOT_EXISTED'))
            break
          case AgentBlockingStatusCode.E002:
            toastService.error(i18n.t('MESS.USER_TERMINATED'))
            break
          default:
            showToastNextLoginTimeChance()
            break
        }
      } else {
        toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
      }
    } catch (error) {
      console.log(error)
      googleCatchaRef.current.onLoadRecaptcha()
      toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
    }
  }

  async function getSavedUserName() {
    let savedUser = await AsyncStorage.getItem('savedUserName')
    if (savedUser) {
      setValue('code', savedUser)
      setIsSavePassword(true)
    } else {
      setValue('code', '')
      setIsSavePassword(false)
    }
  }

  function onToggleSavePassword(value: boolean) {
    setIsSavePassword(value)
  }

  function onRouteHome() {
    function handleRouteHome() {
      if (isMobileDevice) {
        navigation.replace('MobileHome')
      } else {
        navigation.replace('AfterLogIn')
      }
    }
    let params = route.params as any
    if (params) {
      let redirectRoute = params.redirectRoute as string
      try {
        if (redirectRoute && redirectRoute.trim() !== '') {
          delete params.redirectRoute
          redirectRoute = redirectRoute.replace('/', '')
          navigation.replace(redirectRoute as any, params)
        } else {
          handleRouteHome()
        }
      } catch (err) {
        console.error(err)
        handleRouteHome()
      }
    } else {
      handleRouteHome()
    }
  }

  async function onSubmit(data: any) {
    if (isSavePassword) {
      AsyncStorage.setItem('savedUserName', `${data.code}`)
    } else {
      AsyncStorage.setItem('savedUserName', '')
    }
    if (Platform.OS === 'android' || Platform.OS === 'ios' || Platform.OS === 'macos' || Platform.OS === 'windows') {
      setShowVerifiedCaptchaDialog(true)
    } else {
      let submitData = { ...data, captcha }
      signIn(submitData)
        .unwrap()
        .then((value) => {
          if (value) {
            onRouteHome()
          }
        })
        .catch((e) => console.error(e))
    }
  }

  function onVerifyReCaptchaCallback(recaptchaToken: any) {
    if (Platform.OS === 'android' || Platform.OS === 'ios' || Platform.OS === 'macos' || Platform.OS === 'windows') {
      setShowVerifiedCaptchaDialog(false)
      setValue('captcha', recaptchaToken, { shouldValidate: true })
      signIn({ ...getValues(), captcha: recaptchaToken })
        .unwrap()
        .then((value) => {
          if (value) {
            onRouteHome()
          }
        })
        .catch((e) => console.error(e))
    } else {
      setValue('captcha', recaptchaToken, { shouldValidate: true })
      setCaptcha(recaptchaToken)
      setCaptchaVerified(true)
    }
  }

  function renderInputCodeError(error?: any) {
    if (!error) {
      return null
    }
    if (error.type === 'required') {
      return <ValidateRequiredError title={i18n.t('VALIDATE.REQUIRED')} />
    }
    return null
  }

  function renderInputPasswordError(error?: any) {
    if (!error) {
      return null
    }
    if (error.type === 'required') {
      return <ValidateRequiredError title={i18n.t('VALIDATE.REQUIRED')} />
    }
    return null
  }

  if (isMobileDevice) {
    return (
      <SafeAreaView>
        <BannerNotification
          title={i18n.t('MESS.MAINTENANCE_NOTI', {
            fromDate: moment(notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime).format('DD/MM/YYYY'),
            toDate: moment(notiMaintenanceFrom2022March12thTo2022March13th.toDateTime).format('DD/MM/YYYY'),
            fromTime: moment(notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime).format('HH[h]mm'),
            toTime: moment(notiMaintenanceFrom2022March12thTo2022March13th.toDateTime).format('HH[h]mm'),
          })}
          fromDateTime={notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime}
          toDateTime={notiMaintenanceFrom2022March12thTo2022March13th.toDateTime}
        />
        <ScrollView contentContainerStyle={mobileStyles.container}>
          <ImageBackground
            source={imgJustBg as ImageSourcePropType}
            style={[mobileStyles.bgImg, { transform: [{ scaleX: -1 }] }]}
          >
            <Image source={imgBoxes} style={[tabletStyles.boxes, { width: 311, height: 311 }]} />
            <Text style={[mobileStyles.logoText, { transform: [{ scaleX: -1 }] }]}>
              {i18n.t('LOGIN.SLOGAN_1_MOBILE')}
            </Text>
          </ImageBackground>
          <View style={mobileStyles.content}>
            <View style={{ flexDirection: 'row' }}>
              <View style={mobileStyles.signInLabelContainer}>
                <Text style={mobileStyles.signInLabel}>{i18n.t('LOGIN.INDEX')}</Text>
              </View>
              <View style={{ flex: 1 }}></View>
            </View>
            <SizedBox height={20} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ onChange, onBlur, value }) => (
                <View style={mobileStyles.textInputContainer}>
                  <Image source={icProfile as ImageSourcePropType} style={mobileStyles.icon} />
                  <TextInput
                    style={mobileStyles.textInput}
                    placeholder={i18n.t('LOGIN.AUTHORIZED_DEALER_CODE')}
                    selectionColor="#fff"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                </View>
              )}
              name="code"
              defaultValue=""
            />
            {renderInputCodeError(errors.code)}
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ onChange, onBlur, value }) => (
                <View style={mobileStyles.textInputContainer}>
                  <Image source={icLock as ImageSourcePropType} style={mobileStyles.icon} />
                  <TextInput
                    style={mobileStyles.textInput}
                    placeholder={i18n.t('LOGIN.PASSWORD')}
                    secureTextEntry
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                </View>
              )}
              name="password"
              defaultValue=""
            />
            {renderInputCodeError(errors.password)}
            <View style={mobileStyles.forgetPassContainer}>
              <View style={mobileStyles.checkboxSavePasswordContainer}>
                {isSavePassword ? (
                  <BouncyCheckbox
                    key="check-true"
                    isChecked={true}
                    onPress={onToggleSavePassword}
                    useNativeDriver={false}
                    iconStyle={mobileStyles.checkboxSavePassword}
                    disableText
                  />
                ) : (
                  <BouncyCheckbox
                    key="check-false"
                    isChecked={false}
                    onPress={onToggleSavePassword}
                    useNativeDriver={false}
                    iconStyle={mobileStyles.checkboxSavePassword}
                    disableText
                  />
                )}
                <Text style={mobileStyles.checkboxSavePasswordLabel}>{i18n.t('LOGIN.SAVE_PASSWORD')}</Text>
              </View>
              <Link to={{ screen: 'LogIn' }} style={mobileStyles.forgetPass}>
                {i18n.t('LOGIN.FORGET_PASSWORD')}
              </Link>
            </View>
            <GoogleReCaptchaComponent
              ref={googleCatchaRef}
              googleSiteKey={googleSiteKey}
              verifyCallback={(recaptchaToken: any) => onVerifyReCaptchaCallback(recaptchaToken)}
              show={showVerifiedCaptchaDialog}
              onCancel={() => setShowVerifiedCaptchaDialog(false)}
            />
            <View style={mobileStyles.btnSignInContainer}>
              <FilledButton title={i18n.t('LOGIN.INDEX')} loading={loading} onPress={handleSubmit(onSubmit)} />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={tabletStyles.container}>
        <BannerNotification
          title={i18n.t('MESS.MAINTENANCE_NOTI', {
            fromDate: moment(notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime).format('DD/MM/YYYY'),
            toDate: moment(notiMaintenanceFrom2022March12thTo2022March13th.toDateTime).format('DD/MM/YYYY'),
            fromTime: moment(notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime).format('HH[h]mm'),
            toTime: moment(notiMaintenanceFrom2022March12thTo2022March13th.toDateTime).format('HH[h]mm'),
          })}
          fromDateTime={notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime}
          toDateTime={notiMaintenanceFrom2022March12thTo2022March13th.toDateTime}
        />
        <ImageBackground source={imgJustBg as ImageSourcePropType} style={[tabletStyles.bgImg]}>
          <Image
            source={imgDecorTag}
            style={[
              tabletStyles.decorTag,
              {
                top: Layout.window.height * 0.35,
                left: Layout.window.height * 0.06,
                width: (240 * Layout.window.height) / 1440,
                height: (400 * Layout.window.height) / 1440,
              },
            ]}
          />
          <Image
            source={imgBoxes}
            style={[tabletStyles.boxes, { width: Layout.window.height, height: Layout.window.height }]}
          />
          <View style={tabletStyles.displayEnd}>
            <View style={[tabletStyles.top]}>
              <View style={tabletStyles.logoContainer}>
                <Image source={pruLogo as ImageSourcePropType} style={tabletStyles.imgLogo}></Image>
                <Text style={tabletStyles.logoText}>{i18n.t('LOGIN.SLOGAN_1')}</Text>
              </View>
            </View>
          </View>
          <View style={tabletStyles.displayEnd}>
            <View style={tabletStyles.bottom}>
              <View style={{ flexDirection: 'row' }}>
                <View style={[tabletStyles.signInLabelContainer, { marginTop: 20 }]}>
                  <Text style={tabletStyles.signInLabel}>{i18n.t('LOGIN.INDEX')}</Text>
                </View>
                <View style={{ flex: 1 }}></View>
              </View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, onBlur, value }) => (
                  <View style={tabletStyles.textInputContainer}>
                    <Image source={icProfile as ImageSourcePropType} style={tabletStyles.icon} />
                    <TextInput
                      style={tabletStyles.textInput}
                      placeholder={i18n.t('LOGIN.AUTHORIZED_DEALER_CODE')}
                      selectionColor="#fff"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                )}
                name="code"
                defaultValue=""
              />
              {renderInputCodeError(errors.code)}
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange, onBlur, value }) => (
                  <View style={tabletStyles.textInputContainer}>
                    <Image source={icLock as ImageSourcePropType} style={tabletStyles.icon} />
                    <TextInput
                      style={tabletStyles.textInput}
                      placeholder={i18n.t('LOGIN.PASSWORD')}
                      secureTextEntry
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                )}
                name="password"
                defaultValue=""
              />
              {renderInputPasswordError(errors.password)}
              <View style={tabletStyles.forgetPassContainer}>
                <View style={tabletStyles.checkboxSavePasswordContainer}>
                  {isSavePassword ? (
                    <BouncyCheckbox
                      key="check-true"
                      isChecked={true}
                      onPress={onToggleSavePassword}
                      useNativeDriver={false}
                      iconStyle={tabletStyles.checkboxSavePassword}
                      disableText
                    />
                  ) : (
                    <BouncyCheckbox
                      key="check-false"
                      isChecked={false}
                      onPress={onToggleSavePassword}
                      useNativeDriver={false}
                      iconStyle={tabletStyles.checkboxSavePassword}
                      disableText
                    />
                  )}
                  <Text style={tabletStyles.checkboxSavePasswordLabel}>{i18n.t('LOGIN.SAVE_PASSWORD')}</Text>
                </View>
                <Link to={{ screen: 'LogIn' }} style={tabletStyles.forgetPass}>
                  {i18n.t('LOGIN.FORGET_PASSWORD')}
                </Link>
              </View>
              <GoogleReCaptchaComponent
                ref={googleCatchaRef}
                googleSiteKey={googleSiteKey}
                verifyCallback={(recaptchaToken: any) => onVerifyReCaptchaCallback(recaptchaToken)}
                show={showVerifiedCaptchaDialog}
                onCancel={() => setShowVerifiedCaptchaDialog(false)}
              />
              <View style={tabletStyles.btnSignInContainer}>
                <FilledButton
                  title={i18n.t('LOGIN.INDEX')}
                  loading={loading}
                  onPress={handleSubmit(onSubmit)}
                  disabled={!captchaVerified}
                  buttonStyle={{ marginTop: 0 }}
                />
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
    )
  }

  return (
    <View style={desktopStyles.container}>
      <BannerNotification
        title={i18n.t('MESS.MAINTENANCE_NOTI', {
          fromDate: moment(notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime).format('DD/MM/YYYY'),
          toDate: moment(notiMaintenanceFrom2022March12thTo2022March13th.toDateTime).format('DD/MM/YYYY'),
          fromTime: moment(notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime).format('HH[h]mm'),
          toTime: moment(notiMaintenanceFrom2022March12thTo2022March13th.toDateTime).format('HH[h]mm'),
        })}
        fromDateTime={notiMaintenanceFrom2022March12thTo2022March13th.fromDateTime}
        toDateTime={notiMaintenanceFrom2022March12thTo2022March13th.toDateTime}
      />
      <ImageBackground source={imgJustBg as ImageSourcePropType} resizeMode="cover" style={desktopStyles.bgImg}>
        <Image
          source={imgDecorTag}
          style={[
            desktopStyles.decorTag,
            {
              top: Layout.window.height * 0.35,
              left: Layout.window.height * 0.07,
              width: (240 * Layout.window.height) / 1440,
              height: (400 * Layout.window.height) / 1440,
            },
          ]}
        />
        <Image
          source={imgBoxes}
          style={[desktopStyles.boxes, { width: Layout.window.height, height: Layout.window.height }]}
        />
        <View style={desktopStyles.left}>
          <View style={{ flexDirection: 'row' }}>
            {/* <View style={{ flex: 1 }}></View> */}
            <View style={desktopStyles.logoContainer}>
              <Image source={pruLogo as ImageSourcePropType} style={desktopStyles.imgLogo}></Image>
              <Text style={desktopStyles.logoText}>{i18n.t('LOGIN.SLOGAN_1')}</Text>
            </View>
          </View>
          <View style={{ flex: 1 }} />
        </View>
        <View style={desktopStyles.right}>
          <View style={desktopStyles.rightContent}>
            <View style={{ flexDirection: 'row' }}>
              <View style={desktopStyles.signInLabelContainer}>
                <Text style={desktopStyles.signInLabel}>{i18n.t('LOGIN.INDEX')}</Text>
              </View>
              <View style={{ flex: 1 }}></View>
            </View>
            <SizedBox height={20} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ onChange, onBlur, value }) => (
                <View style={desktopStyles.textInputContainer}>
                  <Image source={icProfile as ImageSourcePropType} style={desktopStyles.icon} />
                  <TextInput
                    style={desktopStyles.textInput}
                    placeholder={i18n.t('LOGIN.AUTHORIZED_DEALER_CODE')}
                    selectionColor="#fff"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                </View>
              )}
              name="code"
              defaultValue=""
            />
            {renderInputCodeError(errors.code)}
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ onChange, onBlur, value }) => (
                <View style={desktopStyles.textInputContainer}>
                  <Image source={icLock as ImageSourcePropType} style={desktopStyles.icon} />
                  <TextInput
                    style={desktopStyles.textInput}
                    placeholder={i18n.t('LOGIN.PASSWORD')}
                    secureTextEntry
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    onSubmitEditing={(e) => {
                      onChange((e.target as any).value)
                      handleSubmit(onSubmit)()
                    }}
                  />
                </View>
              )}
              name="password"
              defaultValue=""
            />
            {renderInputPasswordError(errors.password)}
            <View style={desktopStyles.forgetPassContainer}>
              <View style={desktopStyles.checkboxSavePasswordContainer}>
                {isSavePassword ? (
                  <BouncyCheckbox
                    key="check-true"
                    isChecked={true}
                    onPress={onToggleSavePassword}
                    useNativeDriver={false}
                    iconStyle={desktopStyles.checkboxSavePassword}
                    disableText
                  />
                ) : (
                  <BouncyCheckbox
                    key="check-false"
                    isChecked={false}
                    onPress={onToggleSavePassword}
                    useNativeDriver={false}
                    iconStyle={desktopStyles.checkboxSavePassword}
                    disableText
                  />
                )}
                <Text style={desktopStyles.checkboxSavePasswordLabel}>{i18n.t('LOGIN.SAVE_PASSWORD')}</Text>
              </View>
              <Link to={{ screen: 'LogIn' }} style={desktopStyles.forgetPass}>
                {i18n.t('LOGIN.FORGET_PASSWORD')}
              </Link>
            </View>
            <GoogleReCaptchaComponent
              ref={googleCatchaRef}
              googleSiteKey={googleSiteKey}
              verifyCallback={(recaptchaToken: any) => onVerifyReCaptchaCallback(recaptchaToken)}
            />
            <View style={desktopStyles.btnSignInContainer}>
              <FilledButton
                title={i18n.t('LOGIN.INDEX')}
                loading={loading}
                onPress={handleSubmit(onSubmit)}
                disabled={!captchaVerified}
              />
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

export default connector(LogInScreen)

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
    flex: 1,
  },
  displayEnd: {
    flexDirection: 'row',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
  bgImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  decorTag: {
    position: 'absolute',
    width: 120,
    height: 200,
    zIndex: 998,
    left: 50,
    top: 250,
  },
  boxes: {
    position: 'absolute',
    width: 600,
    height: 600,
    zIndex: 1,
    left: 0,
    top: 0,
  },
  top: {
    padding: 40,
    flexDirection: 'column',
  },
  bottom: {
    paddingRight: 40,
    flexDirection: 'column',
  },
  left: {
    flex: 1,
    paddingTop: 50,
    paddingLeft: 60,
    flexDirection: 'column',
    zIndex: 3,
  },
  logoContainer: {
    paddingHorizontal: 35,
    paddingVertical: 20,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor:
      'radial-gradient(148.54% 148.54% at 20.32% 104.31%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%)',
  },
  right: {
    flex: 1,
    padding: 135,
    flexDirection: 'row',
    justifyContent: 'center',
    zIndex: 3,
  },
  rightContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 400,
    width: '100%',
  },
  imgLogo: {
    width: 165,
    height: 110,
    marginRight: 16,
  },
  logoText: {
    fontSize: 35,
    color: '#fff',
    fontWeight: '800',
    flex: 1,
    textAlign: 'right',
  },
  signInLabelContainer: {
    borderBottomWidth: 4,
    borderBottomColor: '#D62828',
    paddingBottom: 8,
    borderRadius: 3,
  },
  signInLabel: {
    fontWeight: '700',
    fontSize: 40,
    color: '#4F4F4F',
  },
  icon: {
    width: 30,
    height: 30,
  },
  textInputContainer: {
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    borderRadius: 16,
    paddingHorizontal: 16,
    minWidth: 400,
    backgroundColor: 'rgba(250, 251, 255, 0.3)',
  },
  textInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderWidth: 0,
    borderColor: '#fff',
  },
  forgetPassContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxSavePasswordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 30,
  },
  checkboxSavePassword: {
    borderWidth: 2,
    borderColor: '#fff',
    borderRadius: 8,
  },
  checkboxSavePasswordLabel: {
    marginLeft: 8,
  },
  forgetPass: {
    flex: 1,
    textAlign: 'right',
    color: '#ED1B2E',
  },
  btnSignInContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnSignIn: {
    width: 187,
    height: 60,
    marginTop: 40,
    alignItems: 'center',
    display: 'flex',
    padding: 20,
    backgroundColor: '#ED1B2E',
    borderRadius: 20,
    color: '#fff',
    shadowColor: '#ED1B2E',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
  },
  btnSignInLabel: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  displayEnd: {
    flexDirection: 'row',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    zIndex: 3,
  },
  decorTag: {
    position: 'absolute',
    width: 120,
    height: 200,
    zIndex: 998,
    left: 50,
    top: 250,
  },
  boxes: {
    position: 'absolute',
    width: 600,
    height: 600,
    zIndex: 1,
    left: 0,
    top: 0,
  },
  bgImg: {
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
  },
  top: {
    padding: 40,
    paddingBottom: 0,
    flexDirection: 'column',
  },
  bottom: {
    paddingRight: 40,
    flexDirection: 'column',
  },
  left: {
    flex: 1,
    paddingTop: 50,
    paddingLeft: 60,
    flexDirection: 'column',
  },
  logoContainer: {
    padding: 35,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor:
      'radial-gradient(148.54% 148.54% at 20.32% 104.31%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%)',
  },
  right: {
    padding: 100,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imgLogo: {
    width: 165,
    height: 110,
    marginRight: 16,
  },
  logoText: {
    fontSize: 35,
    color: '#fff',
    fontWeight: '800',
    flex: 1,
    textAlign: 'right',
  },
  signInLabelContainer: {
    borderBottomWidth: 4,
    borderBottomColor: '#D62828',
    paddingBottom: 8,
    borderRadius: 3,
  },
  signInLabel: {
    fontWeight: '700',
    fontSize: 40,
    color: '#4F4F4F',
  },
  icon: {
    width: 30,
    height: 30,
  },
  textInputContainer: {
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    borderRadius: 16,
    paddingHorizontal: 16,
    minWidth: 400,
    backgroundColor: 'rgba(250, 251, 255, 0.3)',
  },
  textInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderWidth: 0,
    borderColor: '#fff',
  },
  forgetPassContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxSavePasswordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 30,
  },
  checkboxSavePassword: {
    borderWidth: 2,
    borderColor: '#fff',
    borderRadius: 8,
  },
  checkboxSavePasswordLabel: {
    marginLeft: 8,
  },
  forgetPass: {
    flex: 1,
    textAlign: 'right',
    color: '#ED1B2E',
  },
  btnSignInContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnSignIn: {
    width: 187,
    height: 60,
    marginTop: 40,
    alignItems: 'center',
    display: 'flex',
    padding: 20,
    backgroundColor: '#ED1B2E',
    borderRadius: 20,
    color: '#fff',
    shadowColor: '#ED1B2E',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
  },
  btnSignInLabel: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
  },
  bgImg: {
    width: '100%',
    height: 311,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: '#FFF',
  },
  content: {
    backgroundColor: '#fff',
    width: '100%',
    padding: 20,
    paddingBottom: 100,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flex: 1,
  },
  logoContainer: {
    flex: 1,
    padding: 35,
    borderRadius: 24,
    flexDirection: 'row',
    backgroundColor:
      'radial-gradient(148.54% 148.54% at 20.32% 104.31%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.06) 100%)',
    height: 200,
  },
  imgLogo: {
    width: 165,
    height: 110,
    marginRight: 16,
  },
  decorTag: {
    position: 'absolute',
    width: 120,
    height: 200,
    zIndex: 998,
    left: 50,
    top: 250,
  },
  boxes: {
    position: 'absolute',
    width: 600,
    height: 600,
    zIndex: 1,
    left: 0,
    top: 0,
  },
  logoText: {
    fontSize: 25,
    color: '#fff',
    fontWeight: '800',
    marginTop: '10%',
    zIndex: 3,
  },
  signInLabelContainer: {
    borderBottomWidth: 4,
    borderBottomColor: '#D62828',
    paddingBottom: 8,
    borderRadius: 3,
  },
  signInLabel: {
    fontWeight: '700',
    fontSize: 28,
    color: '#4F4F4F',
  },
  icon: {
    width: 24,
    height: 24,
  },
  textInputContainer: {
    flexDirection: 'row',
    marginTop: 22,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    backgroundColor: '#FAFBFF',
    borderRadius: 10,
    paddingHorizontal: 16,
  },
  textInput: {
    fontSize: 13,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 11,
    borderWidth: 0,
    borderColor: '#fff',
  },
  forgetPassContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxSavePasswordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 30,
  },
  checkboxSavePassword: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
  },
  checkboxSavePasswordLabel: {
    marginLeft: 8,
  },
  forgetPass: {
    flex: 1,
    textAlign: 'right',
    color: '#ED1B2E',
  },
  btnSignInContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnSignIn: {
    width: 187,
    height: 60,
    marginTop: 40,
    alignItems: 'center',
    display: 'flex',
    padding: 20,
    backgroundColor: '#ED1B2E',
    borderRadius: 20,
    color: '#fff',
    shadowColor: '#ED1B2E',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
  },
  btnSignInLabel: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20,
  },
})
