import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
import { AnyRecord } from 'dns'
// import ProtectIncome from '../model/protectIncome'

export interface protectAssetObject {
    isCheck?: boolean
    total?: number
    tuition?: number
  }

export class protectAsset {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
    collateral? : protectAssetObject
    saving?: protectAssetObject
    otherAssets?: protectAssetObject
  }
  

export interface protectAssetState {
  loading?: boolean
  protectAsset?: protectAsset
}

const initialState: protectAssetState = {
  loading: false,
  protectAsset: {
    isTemporaryCheck: true,
    // isSelect: true,
    subTotal: 0,
    collateral: {
      isCheck: false,
      total: 0,
    },
    otherAssets: {
      isCheck: false,
      total: 0,
    },
    saving: {
      isCheck: false,
      total: 0,
    }
  },
}

export const updateProtectAssetSlice = createAsyncThunk('users/updateProtectAssetSlice',  async (req: any, thunkAPI: any) => {
  return req
});




export const investmentSlice = createSlice({
  name: 'protectAsset',
  initialState,
  reducers: {
    loadProtectAssetState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.protectAsset = action.payload
    },
    updateProtectAssetState: (state, action: PayloadAction<any>) => {
      state.protectAsset = action.payload.protectAsset
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProtectAssetSlice.fulfilled, (state, action: any) => {
        state.loading = false
        state.protectAsset = action.payload
      })
  },
})

export const { loadProtectAssetState, updateProtectAssetState } = investmentSlice.actions

const { reducer } = investmentSlice
export default reducer
