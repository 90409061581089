import React, { useEffect } from 'react'
import { View, StyleSheet, Text, ScrollView, LogBox } from 'react-native'
import { RootStackScreenProps } from '../../types'
import { styles as globalStyles, SizedBox, Dialog, StepButton, mathHelper } from '../../common'
import { SafeAreaView } from 'react-native-safe-area-context'
import i18n from 'i18n-js'
import { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'
import SummaryInfo from './components/SummaryInfo'
import SummaryNeeds from './components/SummaryNeeds'
import { dataNotesILP, dataNotesULP, defaultQuotationFundAllocation, needsData } from '../../constants/Data'
import { updateNeedsOverall } from '../../slices/needsOverallSlice'
import SummarySolution from './components/SummarySolution'
import SummaryMainBenefit from './components/SummaryMainBenefit'
import SummaryHealthCareProgram from './components/SummayHealthCareProgram'
import SummaryNotes from './components/SummaryNotes'
import FeeDialog from './components/FeeDialog'
import ProfitTable from './components/ProfitTable'
import ListFatalDisease from './components/ListFatalDisease'
import { saveCNA, getCNADetail, getCachedCNA } from '../../slices/cnaSlice'
import { useIsFocused } from '@react-navigation/native'
import { ProductType, ViewMode } from '../../constants/Enum'
import ReviewDialog from './components/ReviewDialog'
import RatingDialog from './components/RatingDialog'
import moment from 'moment'
import { getChartData } from '../../slices/solutionSlice'
import ExtraInvestDialog from './components/ExtraInvestDialog'
import toastService from '../../services/toast/ToastService'
import CustomerConfirmDialog from './components/CustomerConfirmDialog'
import AgentConfirmDialog from './components/AgentConfirmDialog'
import StepAppHeader from '../../components/StepAppHeader'
import MobileSolutionDetailScreen from './MobileSolutionDetailScreen'
import { getStatusToSave, CreateCnaPageStep } from '../../helper/CnaStatusWorkFlow'
import MobileStepIndicator from '../../components/MobileStepIndicator'
import CommonAppBar from '../../components/CommonAppBar'
import Layout from '../../constants/Layout'
import BeneficiaryTable from '../../components/BeneficiaryTable'
import FeeHealthCare from './components/FeeHealthCare'
import { HealthCarePlan } from '../../model/HealthCarePlan'
import dataProvider from '../../data/dataProvider'

const mapStateToProps = (state: RootState) => ({
  loading: state.advisor.loading,
  needsOverallList: state.needsOverall.needsOverallList,
  needsCartList: state.needsCart.needsCartList,
  profileData: state.needsCart.profileInfo,
  periodPlan: state.needsOverall.planPeriodTime,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  loadingSaveDraft: state?.cna.loadingSaveDraft,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
  productType: state?.advisor.productType,
  chartData: state?.solution.chartData,
  isLoadingChartData: state?.solution.loadingChartData,
  userInfo: state?.user?.userInfo,
})

const mapDispatchToProps = {
  updateNeedsOverall,
  getCNADetail,
  getCachedCNA,
  saveCNA,
  getChartData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'Solution'> & PropsFromRedux & {}

function SolutionScreen({
  route,
  navigation,
  loading,
  needsOverallList,
  updateNeedsOverall,
  profileData,
  periodPlan,
  loadingSaveDraft,
  loadingSaveContinue,
  getCNADetail,
  getCachedCNA,
  saveCNA,
  currentHandlingCNA,
  productType,
  getChartData,
  chartData,
  isLoadingChartData,
  needsCartList,
  userInfo,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const isFocused = useIsFocused()

  const [showCancelWarningModel, setShowCancelWarningModel] = React.useState(false)
  const [showFeeModal, setShowFeeModal] = React.useState(false)
  const [showReviewModal, setShowReviewModal] = React.useState(false)
  const [showRatingModal, setShowRatingModal] = React.useState(false)
  const [showExtraInvestModal, setShowExtraInvestModal] = React.useState(false)
  const [showCustomerConfirmModal, setShowCustomerConfirmModal] = React.useState(false)
  const [showAgentConfirmModal, setShowAgentConfirmModal] = React.useState(false)
  const [showBeneficiaryModal, setShowBeneficiaryModal] = React.useState(false)
  const [showHealthCare, setShowHealthCare] = React.useState(false)
  const [showAnswer, setShowAnswer] = React.useState<string | undefined>('Nội trú')
  const [showFatalDisease, setShowFatalDisease] = React.useState<string | undefined>('Bệnh hiểm nghèo')
  const [showFatalDiseasePopUp, setShowFatalDiseasePopUp] = React.useState(false)
  const [showProfitModal, setShowProfitModal] = React.useState(false)
  const [isSavingComplete, setIsSavingComplete] = React.useState(false)
  const [showSolutionDetail, setShowSolutionDetail] = React.useState(false)
  const [selectedHcPlan, setSelectedHcPlan] = React.useState<HealthCarePlan>()

  useEffect(() => {
    let data: Array<any> = []
    needsData.forEach((e) => data.push(...e.data))
    updateNeedsOverall(data)
    LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
  }, [])

  React.useEffect(() => {
    let hospitalTypeDataList = dataProvider.getHospitalDataBasedOnAgeAndGender(
      currentHandlingCNA?.customerAge,
      currentHandlingCNA?.customerGender
    )
    setSelectedHcPlan(hospitalTypeDataList.find((x) => x.answer === currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer))
  }, [currentHandlingCNA])

  useEffect(() => {
    if (isFocused) {
      onInitData()
    } else {
      setShowCancelWarningModel(false)
      setShowFeeModal(false)
      setShowHealthCare(false)
      setShowFatalDiseasePopUp(false)
      setShowReviewModal(false)
      setShowRatingModal(false)
      setShowExtraInvestModal(false)
      setShowCustomerConfirmModal(false)
      setShowAgentConfirmModal(false)
      setShowSolutionDetail(false)
    }
  }, [isFocused])

  async function onInitData() {
    const id = (route.params as any)?.id
    if (id) {
      Promise.all([
        getCNADetail({ id }),
        getChartData({ id: (route.params as any)?.id, withoutTopup: true }),
        getChartData({ id: (route.params as any)?.id, withoutTopup: false }),
      ])
        .then((data: any) => {
          let cnaData = data[0].payload
          let cnaDataTopUp = data[2].payload?.result.map((x: any) => {
            return {
              ...x,
              ape: cnaData?.topUp ? Number(x.ape) + cnaData?.topUp * 1000000 : Number(x.ape),
            }
          })

          let body = {
            ...cnaData,
            id: cnaData?._id,
            ape:
              data[1] && data[1].payload?.result && data[1].payload?.result?.length > 0
                ? data[1].payload?.result[0]?.ape
                : 0,
            quotations: data[1].payload?.result,
            quotationWithoutTopups: data[0].payload?.topUp !== 0 && data[0].payload?.topUp ? cnaDataTopUp : null,
          } as any

          if (Number(cnaData?.hcFatalDisease?.total) > Number(cnaData?.sumAssured)) {
            body.hcFatalDisease = {
              ...cnaData?.hcFatalDisease,
              total: mathHelper.getExtraProfitByFatalDisease(cnaData),
            }
          }

          onUpdateQuotations(body)
        })
        .catch((err) => toastService.error(i18n.t('MESS.UNKNOWN_ERROR')))
    }
  }

  /**
   * Handle cancel create CNA
   */
  function onCancel() {
    setShowCancelWarningModel(true)
  }

  function countTotalValue(needsOverallList: Array<any>) {
    let totalValue = 0
    needsOverallList.forEach((k) => {
      if (k.isChecked) {
        totalValue += k.qty
      }
    })
    return totalValue
  }

  function onConfirm() {
    navigation.navigate('SaveForm', route.params)
  }

  function onSaveDraft() {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      isSavingDraft: true,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.NEEDS_CART,
        currentHandlingCNA?.customerPhone,
        true
      ),
    } as any
    return saveCNA(body)
  }

  function onUpdateSatisfied(isSatisfaction?: boolean) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      lastEditStep:
        currentHandlingCNA?.lastEditStep && currentHandlingCNA?.lastEditStep > 7 ? currentHandlingCNA?.lastEditStep : 7,
      isSatisfaction: isSatisfaction,
      isSavingContinue: true,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.SOLUTION,
        currentHandlingCNA?.customerPhone,
        false
      ),
    } as any
    return saveCNA(body)
  }

  function onComplete(rating: number, comment?: string) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      lastEditStep:
        currentHandlingCNA?.lastEditStep && currentHandlingCNA?.lastEditStep > 7 ? currentHandlingCNA?.lastEditStep : 7,
      rate: rating,
      feedback: comment,
      solutionDate: moment().toISOString(),
    } as any
    saveCNA(body)
      .unwrap()
      .then(() => {
        setIsSavingComplete(false)
        setShowRatingModal(false)
        setShowCustomerConfirmModal(true)
      })
  }

  function onUpdateQuotation(data: any) {
    let total = 0
    total += data.vietNamEquity
    total += data.growth
    total += data.balanced
    total += data.stable
    total += data.vietNamBond
    total += data.preserved
    if (total > 100) {
      toastService.error(i18n.t('SOLUTION.WARNING_TOTAL_ALLOTMENT_VALUE_MAX'))
      return
    } else if (total < 100) {
      toastService.error(i18n.t('SOLUTION.WARNING_TOTAL_ALLOTMENT_VALUE_MIN'))
      return
    }
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      quotationFundAllocation: data,
    } as any
    saveCNA(body)
      .unwrap()
      .then((data) => {
        onInitData()
      })
  }

  function onUpdateQuotations(body: any) {
    saveCNA(body)
      .unwrap()
      .then((data) => {
        const id = (route.params as any)?.id
        if (id && id !== 'new') {
          // getCachedCNA({ id })
        }
      })
  }

  function onAddExtraInvest(extraInvestValue?: number) {
    setShowExtraInvestModal(false)
    if (extraInvestValue !== currentHandlingCNA?.topUp) {
      let body = {
        ...currentHandlingCNA,
        id: currentHandlingCNA?._id,
        topUp: extraInvestValue,
      } as any
      saveCNA(body)
        .unwrap()
        .then((data) => {
          onInitData()
        })
    }
  }

  function onCustomerConfirm(isCustomerConfirmed?: boolean) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      isCustomerConfirmed: isCustomerConfirmed,
    } as any
    setIsSavingComplete(true)
    saveCNA(body)
      .unwrap()
      .then((data) => {
        onInitData()
        setIsSavingComplete(false)
        setShowCustomerConfirmModal(false)
        setShowAgentConfirmModal(true)
      })
  }

  function onAgentConfirm(isAgentConfirmed?: boolean) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      isAgentConfirmed: isAgentConfirmed,
    } as any
    setIsSavingComplete(true)
    saveCNA(body)
      .unwrap()
      .then((data) => {
        setIsSavingComplete(false)
        setShowAgentConfirmModal(false)
        navigation.navigate('SaveForm', route.params)
      })
  }

  function setPopupBenefit(title?: string) {
    setShowHealthCare(true)
    setShowAnswer(title)
  }

  function setPopupFatalDisease(title?: string) {
    setShowFatalDiseasePopUp(true)
    setShowFatalDisease(title)
  }

  function onGoBack() {
    if (currentHandlingCNA?.product === ProductType.ILP) {
      navigation.navigate('Advisor', route.params)
    } else {
      navigation.navigate('NeedsOverall', route.params)
    }
  }

  function checkEditable() {
    return (route.params as any)?.mode !== ViewMode.READ_ONLY
  }

  function renderModals() {
    return (
      <>
        {/* Cancel model  */}
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showCancelWarningModel}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.KEEP_CANCEL')}
          cancelText={i18n.t('COMMON.CONTINUE')}
          onOk={() => {
            setShowCancelWarningModel(false)
            navigation.navigate('MobileHome')
          }}
          onCancel={() => {
            setShowCancelWarningModel(false)
          }}
        >
          <Text></Text>
        </Dialog>
        {/* Fee modal  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showFeeModal}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ borderRadius: 12, padding: 0, paddingTop: 0 }}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1, globalStyles.fontSize18]}>Các loại phí</Text>
            </View>
          }
          onClose={() => setShowFeeModal(false)}
        >
          <FeeDialog productType={currentHandlingCNA?.product as ProductType} />
        </Dialog>
        {/* ProfitModal  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showProfitModal}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ paddingHorizontal: 16, margin: 20, paddingTop: 10 }}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1]}></Text>
            </View>
          }
          onClose={() => setShowProfitModal(false)}
        >
          <ProfitTable />
        </Dialog>
        {/* Fatal disease modal  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showFatalDiseasePopUp}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ borderRadius: 12, padding: 0 }}
          onClose={() => setShowFatalDiseasePopUp(false)}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1, globalStyles.fontSize18]}>
                {showFatalDisease}
              </Text>
            </View>
          }
        >
          <ListFatalDisease
            productType={currentHandlingCNA?.product as ProductType}
            except={showFatalDisease === 'Bệnh hiểm nghèo' ? true : false}
          />
        </Dialog>
        {/* Fee HealCare modal  */}
        <Dialog
          type="inform"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          showCloseIcon
          visible={showHealthCare}
          onClose={() => setShowHealthCare(false)}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ paddingTop: 0 }}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1, globalStyles.fontSize18]}></Text>
            </View>
          }
        >
          <FeeHealthCare answerType={showAnswer} answer={currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer} />
        </Dialog>
        {/* Review dialog */}
        <Dialog
          type="default"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showReviewModal}
          contentStyle={{ padding: 0, paddingTop: 10 }}
          dialogStyle={{ paddingBottom: 0, margin: 20, borderRadius: 12, maxWidth: 522 }}
        >
          <ReviewDialog
            loading={loadingSaveContinue}
            onClose={function (isUserSatisfied?: boolean): void {
              onUpdateSatisfied(isUserSatisfied)
                .unwrap()
                .then(() => {
                  setTimeout(() => {
                    if (isUserSatisfied) {
                      setShowReviewModal(false)
                      setShowRatingModal(true)
                    } else {
                      setShowReviewModal(false)
                    }
                  }, 500)
                })
            }}
          />
        </Dialog>
        {/* Rating dialog */}
        <Dialog
          type="default"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showRatingModal}
          contentStyle={{ padding: isMobileDevice ? 20 : 0 }}
          dialogStyle={{ padding: 0, margin: 20, borderRadius: 12, maxWidth: 522 }}
        >
          <RatingDialog
            onClose={(rating: number, comment?: string) => {
              setIsSavingComplete(true)
              onComplete(rating, comment)
            }}
            loading={isSavingComplete}
          />
        </Dialog>
        {/* Add extra invest dialog */}
        <Dialog
          type="inform"
          visible={showExtraInvestModal}
          showCloseIcon
          contentStyle={{ padding: 0 }}
          dialogStyle={{ padding: 0, borderRadius: 12, maxWidth: 522, width: '99%' }}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1]}>{i18n.t('SOLUTION.EXTRA_INVESTING')}</Text>
            </View>
          }
          onClose={() => setShowExtraInvestModal(false)}
        >
          <ExtraInvestDialog
            extraInvest={currentHandlingCNA?.topUp}
            onClose={onAddExtraInvest}
            maxApe={currentHandlingCNA?.quotations}
            productTypeSolution={currentHandlingCNA?.product}
          />
        </Dialog>
        {/* Customer confirm dialog */}
        <Dialog
          type="default"
          visible={showCustomerConfirmModal}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ padding: 0, borderRadius: 12, maxWidth: 522, width: '99%' }}
        >
          <CustomerConfirmDialog onClose={onCustomerConfirm} loading={isSavingComplete} />
        </Dialog>
        {/* Agent confirm dialog */}
        <Dialog
          type="default"
          visible={showAgentConfirmModal}
          contentStyle={{ maxHeight: 500, padding: 0 }}
          dialogStyle={{ padding: 0, margin: 20, borderRadius: 12, maxWidth: 522, width: '99%' }}
        >
          <AgentConfirmDialog onClose={onAgentConfirm} loading={isSavingComplete} />
        </Dialog>
        {/* Beneficiary dialog */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showBeneficiaryModal}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ padding: 0, margin: 20, maxWidth: 1300, width: '99%', paddingTop: 0 }}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1]}>
                QUYỀN LỢI CHI TIẾT CÁC GÓI CHĂM SÓC Y TẾ
              </Text>
            </View>
          }
          onClose={() => setShowBeneficiaryModal(false)}
        >
          <BeneficiaryTable />
        </Dialog>
      </>
    )
  }

  function renderMobileAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('NEEDS_CART.NEW')}
        navigation={navigation}
        onBack={onGoBack}
        onGoHome={() => onSaveDraft()}
      />
    )
  }

  function renderSolutionMobileDetailAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('SOLUTION.INDEX')}
        navigation={navigation}
        onBack={() => {
          let newParams = route.params || ({} as any)
          delete newParams?.popup
          navigation.replace('Solution', newParams)
        }}
        onGoHome={() => {
          setShowSolutionDetail(false)
          return onSaveDraft()
        }}
      />
    )
  }

  function renderMobileStepIndicator() {
    return (
      <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
        <MobileStepIndicator
          route={route}
          navigation={navigation}
          cnaData={currentHandlingCNA}
          needsCartList={needsCartList}
          currentPosition={2}
          loadingSaveDraft={loadingSaveDraft}
          onSaveDraft={onSaveDraft}
        />
      </View>
    )
  }

  if (isMobileDevice) {
    if (showSolutionDetail || (route.params as any)?.popup === 'chart') {
      return (
        <SafeAreaView
          style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
          edges={['right', 'left', 'top']}
        >
          <View style={globalStyles.mobileContent}>
            {renderSolutionMobileDetailAppBar()}
            <ScrollView contentContainerStyle={globalStyles.Flex1}>
              <MobileSolutionDetailScreen
                navigation={navigation}
                route={route}
                onFinish={onConfirm}
                chartData={chartData}
                isLoadingChartData={isLoadingChartData}
                onUpdateQuotation={onUpdateQuotation}
                onOpenExtraInvestModal={() => setShowExtraInvestModal(true)}
                onShowPopupBenefit={setPopupBenefit}
                onShowBeneficiary={() => setShowBeneficiaryModal(true)}
              />
            </ScrollView>
          </View>
          {renderMobileStepIndicator()}
          {renderModals()}
        </SafeAreaView>
      )
    }
    return (
      <SafeAreaView
        style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative, { paddingHorizontal: 0 }]}
        edges={['right', 'left', 'top']}
      >
        <View style={globalStyles.mobileContent}>
          {renderMobileAppBar()}
          <ScrollView contentContainerStyle={[mobileStyles.contentContainer]}>
            <SummaryInfo
              name={currentHandlingCNA?.reminiscentName}
              createdDate={currentHandlingCNA?.createdAt}
              gender={profileData?.genderName}
              age={profileData?.age}
              maritalStatus={profileData?.maritalStatus}
            />
            <SummaryNeeds
              userInfo={userInfo}
              needsData={needsOverallList?.filter((e) => e.isChecked)}
              productType={currentHandlingCNA?.product}
              protectValueTotal={currentHandlingCNA?.needTotal}
              style={{ marginTop: 24.04 }}
              sumAssured={currentHandlingCNA?.sumAssured}
              ape={
                currentHandlingCNA?.quotations && currentHandlingCNA?.quotations.length > 0
                  ? currentHandlingCNA?.quotations[0]?.ape
                  : 0
              }
              hcPlanDetail={selectedHcPlan?.label}
              periodPlan={currentHandlingCNA?.needTerm}
              onOpenFeeModal={() => setShowFeeModal(true)}
              onViewSolutionDetail={() => setShowSolutionDetail(true)}
            />
            <SummaryNotes
              data={currentHandlingCNA?.product === ProductType.ILP ? dataNotesILP : dataNotesULP}
              style={{ marginTop: 24.87 }}
            />
            <SizedBox height={150} />
          </ScrollView>
        </View>
        {renderMobileStepIndicator()}
        {renderModals()}
      </SafeAreaView>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
        <StepAppHeader
          route={route}
          navigation={navigation}
          currentPosition={3}
          onSaveDraft={onSaveDraft}
          cnaData={currentHandlingCNA}
          needsCartList={needsCartList}
        />
        <ScrollView style={globalStyles.content}>
          <SummaryInfo
            name={currentHandlingCNA?.reminiscentName}
            createdDate={currentHandlingCNA?.createdAt}
            gender={profileData?.genderName}
            age={profileData?.age}
            maritalStatus={profileData?.maritalStatus}
          />
          <SummaryNeeds
            userInfo={userInfo}
            needsData={needsOverallList?.filter((e) => e.isChecked)}
            productType={currentHandlingCNA?.product}
            protectValueTotal={currentHandlingCNA?.needTotal}
            style={{ marginTop: 24.04 }}
            onOpenFeeModal={() => setShowFeeModal(true)}
            sumAssured={currentHandlingCNA?.sumAssured}
            periodPlan={currentHandlingCNA?.needTerm}
            ape={
              currentHandlingCNA?.quotations && currentHandlingCNA?.quotations.length > 0
                ? currentHandlingCNA?.quotations[0]?.ape
                : 0
            }
            hcPlanDetail={selectedHcPlan?.label}
          />

          <SummaryMainBenefit
            data={[
              {
                title: 'Số tiền bảo hiểm quyền lợi Tử vong / TTTB&VV',
                value: currentHandlingCNA?.sumAssured,
                show: true,
                showPlaceholder: true,
              },
              {
                title: 'Tử vong do tai nạn, cộng thêm',
                value: mathHelper.getExtraProfitFatalByAccident(currentHandlingCNA),
                show: true,
                showPlaceholder: true,
              },
              {
                title: 'Bệnh hiểm nghèo',
                value: currentHandlingCNA?.hcFatalDisease?.isSelect
                  ? mathHelper.getExtraProfitByFatalDisease(currentHandlingCNA)
                  : 0,
                show: true,
                showReference: currentHandlingCNA?.hcFatalDisease?.isSelect ? true : false,
              },
              {
                title: 'Miễn thu phí bệnh hiểm nghèo',
                value: currentHandlingCNA?.hcFatalDisease?.isSelect ? 0 : 0,
                show: true,
                showReference: true,
              },
            ]}
            onShowPopupFatalDisease={setPopupFatalDisease}
            style={{ marginTop: 24.87 }}
          />
          <SummaryHealthCareProgram
            data={[
              {
                title: 'Nội trú',
                value: currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.boarding ? selectedHcPlan?.boarding : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
              {
                title: 'Ngoại trú',
                value:
                  currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.outPatient ? selectedHcPlan?.outPatient : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
              {
                title: 'Nha khoa',
                value:
                  currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.dentistry ? selectedHcPlan?.dentistry : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
              {
                title: 'Thai sản',
                value:
                  currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.maternity ? selectedHcPlan?.maternity : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
            ]}
            style={{ marginTop: 24.87 }}
            onShowPopupBenefit={setPopupBenefit}
            onShowBeneficiary={() => setShowBeneficiaryModal(true)}
          />
          <SummarySolution
            customerAge={currentHandlingCNA?.customerAge ?? 0}
            data={currentHandlingCNA?.quotations}
            dataTopUp={currentHandlingCNA?.quotationWithoutTopups}
            productTypeSolution={currentHandlingCNA?.product}
            extraInvest={currentHandlingCNA?.topUp}
            isLoadingChartData={isLoadingChartData}
            editable={(route.params as any)?.mode !== ViewMode.READ_ONLY}
            onConfirm={onUpdateQuotation}
            quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
            onOpenExtraInvestModal={() => setShowExtraInvestModal(true)}
            onOpenProfitModal={() => setShowProfitModal(true)}
            style={{ marginTop: 24.87 }}
          />
          <SummaryNotes
            data={currentHandlingCNA?.product === ProductType.ILP ? dataNotesILP : dataNotesULP}
            style={{ marginTop: 24.87 }}
          />
          <StepButton onBack={onGoBack} onNext={onConfirm} nextText={i18n.t('COMMON.COMPLETE')} />
        </ScrollView>
        {renderModals()}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <StepAppHeader
        route={route}
        navigation={navigation}
        currentPosition={3}
        onSaveDraft={onSaveDraft}
        cnaData={currentHandlingCNA}
        needsCartList={needsCartList}
      />
      <ScrollView style={globalStyles.content}>
        <SummaryInfo
          name={currentHandlingCNA?.reminiscentName}
          createdDate={currentHandlingCNA?.createdAt}
          gender={profileData?.genderName}
          age={profileData?.age}
          maritalStatus={profileData?.maritalStatus}
        />
        <SummaryNeeds
          userInfo={userInfo}
          needsData={needsOverallList?.filter((e) => e.isChecked)}
          productType={currentHandlingCNA?.product}
          protectValueTotal={currentHandlingCNA?.needTotal}
          style={{ marginTop: 24.04 }}
          onOpenFeeModal={() => setShowFeeModal(true)}
          sumAssured={currentHandlingCNA?.sumAssured}
          periodPlan={currentHandlingCNA?.needTerm}
          ape={
            currentHandlingCNA?.quotations && currentHandlingCNA?.quotations.length > 0
              ? currentHandlingCNA?.quotations[0]?.ape
              : 0
          }
          hcPlanDetail={selectedHcPlan?.label}
        />

        <SummaryMainBenefit
          data={[
            {
              title: 'Số tiền bảo hiểm quyền lợi Tử vong / TTTB&VV',
              value: currentHandlingCNA?.sumAssured,
              show: true,
              showPlaceholder: true,
            },
            {
              title: 'Tử vong do tai nạn, cộng thêm',
              value: mathHelper.getExtraProfitFatalByAccident(currentHandlingCNA),
              show: true,
              showPlaceholder: true,
            },
            {
              title: 'Bệnh hiểm nghèo',
              value: currentHandlingCNA?.hcFatalDisease?.isSelect
                ? mathHelper.getExtraProfitByFatalDisease(currentHandlingCNA)
                : 0,
              show: true,
              showReference: currentHandlingCNA?.hcFatalDisease?.isSelect ? true : false,
            },
            {
              title: 'Miễn thu phí bệnh hiểm nghèo',
              value: currentHandlingCNA?.hcFatalDisease?.isSelect ? 0 : 0,
              show: false,
              showReference: true,
            },
          ]}
          onShowPopupFatalDisease={setPopupFatalDisease}
          style={{ marginTop: 24.87 }}
        />
        <SummaryHealthCareProgram
          data={[
            {
              title: 'Nội trú',
              value: currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.boarding ? selectedHcPlan?.boarding : 0,
              answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
            },
            {
              title: 'Ngoại trú',
              value:
                currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.outPatient ? selectedHcPlan?.outPatient : 0,
              answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
            },
            {
              title: 'Nha khoa',
              value: currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.dentistry ? selectedHcPlan?.dentistry : 0,
              answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
            },
            {
              title: 'Thai sản',
              value: currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.maternity ? selectedHcPlan?.maternity : 0,
              answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
            },
          ]}
          style={{ marginTop: 24.87 }}
          onShowPopupBenefit={setPopupBenefit}
          onShowBeneficiary={() => setShowBeneficiaryModal(true)}
        />

        <SummarySolution
          customerAge={currentHandlingCNA?.customerAge ?? 0}
          style={{ marginTop: 24.87 }}
          data={currentHandlingCNA?.quotations}
          dataTopUp={currentHandlingCNA?.quotationWithoutTopups}
          productTypeSolution={currentHandlingCNA?.product}
          extraInvest={currentHandlingCNA?.topUp}
          isLoadingChartData={isLoadingChartData}
          editable={(route.params as any)?.mode !== ViewMode.READ_ONLY}
          onConfirm={onUpdateQuotation}
          quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
          onOpenExtraInvestModal={() => setShowExtraInvestModal(true)}
          onOpenProfitModal={() => setShowProfitModal(true)}
        />

        <SummaryNotes
          data={currentHandlingCNA?.product === ProductType.ILP ? dataNotesILP : dataNotesULP}
          style={{ marginTop: 24.87 }}
        />
        <StepButton onBack={onGoBack} onNext={onConfirm} nextText={i18n.t('COMMON.COMPLETE')} />
      </ScrollView>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(SolutionScreen)

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F7F8FA',
  },
  contentContainer: {
    paddingHorizontal: 33.17,
    paddingVertical: 16.58,
    paddingTop: 82,
    flex: 1,
    overflow: 'scroll',
  },
  fatalDeceaseContainer: {
    flexDirection: 'column',
  },
  fatalDeceaseTooltipsTitle: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  fatalDeceaseTooltipsContent: {
    color: '#505D6F',
    fontSize: 15,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F7F8FA',
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingVertical: 16.58,
    paddingTop: 82,
    flex: 1,
    overflow: 'scroll',
  },
  fatalDeceaseContainer: {
    flexDirection: 'column',
  },
  fatalDeceaseTooltipsTitle: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  fatalDeceaseTooltipsContent: {
    color: '#505D6F',
    fontSize: 15,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    padding: 0,
    paddingTop: 56,
    backgroundColor: '#F7F8FA',
    paddingHorizontal: 16,
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    paddingVertical: 0,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    paddingTop: 20,
    paddingBottom: 0,
    marginBottom: 0,
    overflow: 'scroll',
  },
  stepIndicatorContainer: {
    bottom: 16,
    left: 16,
    right: 16,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  headerContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    color: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
    marginTop: 20,
  },
  headerLabel: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
})
