export enum CnaStatus {
  DRAFT = 'DRAFT',
  NEED_CONTACT_INFO = 'NEED_CONTACT_INFO',
  COMPLETED = 'COMPLETED',
  DRAFT_INDEX = '0',
  NEED_CONTACT_INFO_INDEX = '2',
  COMPLETED_INDEX = '1',
}

export enum ApeDisplayType {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
}

export enum ProductType {
  ULP = 'ULP',
  ILP = 'ILP',
}

export enum PageNumberMapping {
  NeedsCart = 3,
  CustomerNeed = 4,
  NeedsOverall = 5,
  Advisor = 6,
  Solution = 7,
}

export enum NeedsCategory {
  PROTECT = 'PROTECT',
  EDUCATION = 'EDUCATION',
  MEDICINE = 'MEDICINE',
  RETIREMENT = 'RETIREMENT',
  OTHER = 'OTHER',
}

export enum ViewMode {
  READ_ONLY = 'read-only',
  EDITABLE = 'editable',
}

export enum AgentBlockingStatusCode {
  E001 = 'E001',
  E002 = 'E002',
}

export enum AgentOffice {
  VIB = 'VIB',
}
