import { Toast } from '../../common'
import { ImageSourcePropType } from 'react-native'
import icWarning from '../../assets/images/warning.png'
import icCheckGreen from '../../assets/images/check-circle.png'

const DURATION = 3000

class ToastService {
  private ref?: Toast

  setRefContainer(ref?: Toast) {
    this.ref = ref
  }

  success = (message: string = '', code = '', duration: number = DURATION) => {
    try {
      this.ref?.showToast({
        id: Math.random(),
        message,
        code,
        duration,
        backgroundColor: 'white',
        colorText: '#00C853',
        icon: icCheckGreen,
      })
    } catch (error) {
      console.error(error)
    }
  }

  error = (message: string = '', code = '', duration: number = DURATION) => {
    try {
      this.ref?.showToast({
        id: Math.random(),
        message,
        code,
        duration,
        backgroundColor: '#fabbc0',
        colorText: '#ED1B2E',
        icon: icWarning,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export default new ToastService()
