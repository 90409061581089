import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image } from 'react-native'
import moment from 'moment'
import icCalendar from '../../../assets/images/calendar-2.png'
import ic2Heart from '../../../assets/images/2-heart.png'
import icUsers from '../../../assets/images/2-user.png'
import icProfile from '../../../assets/images/profile.png'
import { Block, SizedBox, styles as globalStyles } from '../../../common'
import i18n from 'i18n-js'
import Layout from '../../../constants/Layout'
import imgAvatar from '../../../assets/images/avatar.png'
import Colors from '../../../common/constants/Colors'

type IProps = {
  name?: string
  createdDate?: string
  gender?: string
  age?: number
  maritalStatus?: string
  style?: Record<string, unknown>
}

function SummaryInfo({ name, createdDate, gender, age, style, maritalStatus }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  function renderInfoItem(
    icon?: ImageSourcePropType,
    title?: string,
    value?: string,
    containerStyle?: Record<string, unknown>
  ) {
    if (isMobileDevice) {
      return (
        <View style={[mobileStyles.infoItemContainer, containerStyle]}>
          <Image source={icon as ImageSourcePropType} style={mobileStyles.infoItemIcon} />
          <Text style={mobileStyles.infoItemTitle}>{title}</Text>
          <Text style={mobileStyles.infoItemValue}>{value}</Text>
        </View>
      )
    }
    if (isTabletDevice) {
      return (
        <View style={[tabletStyles.infoItemContainer, containerStyle]}>
          <Image source={icon as ImageSourcePropType} style={tabletStyles.infoItemIcon} />
          <Text style={tabletStyles.infoItemTitle}>{title}</Text>
          <Text style={tabletStyles.infoItemValue}>{value}</Text>
        </View>
      )
    }
    return (
      <View style={[desktopStyles.infoItemContainer, containerStyle]}>
        <Image source={icon as ImageSourcePropType} style={desktopStyles.infoItemIcon} />
        <Text style={desktopStyles.infoItemTitle}>{title}</Text>
        <Text style={desktopStyles.infoItemValue}>{value}</Text>
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <Block
        title={i18n.t('SOLUTION.PRUDENTIAL_SOLUTION')}
        withChildBackground={false}
        child={
          <View style={[mobileStyles.container, style]}>
            <Text style={mobileStyles.title}>{i18n.t('SOLUTION.CUSTOMER_INFO')}</Text>
            <SizedBox height={16} />
            <View style={mobileStyles.avatarContainer}>
              <Image source={imgAvatar as ImageSourcePropType} style={mobileStyles.avatar} />
              <Text style={mobileStyles.name}>{name ?? ''}</Text>
            </View>
            {renderInfoItem(
              icCalendar as ImageSourcePropType,
              i18n.t('SOLUTION.PLAN_CREATED_DATE'),
              createdDate ? moment(createdDate).format('DD/MM/YYYY') : ''
            )}
            <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
              {renderInfoItem(icUsers as ImageSourcePropType, i18n.t('COMMON.GENDER'), gender, { flex: 1 })}
              <SizedBox width={16} />
              {renderInfoItem(icProfile as ImageSourcePropType, i18n.t('COMMON.AGE'), `${age ?? ''}`, { flex: 1 })}
            </View>
            {renderInfoItem(
              ic2Heart as ImageSourcePropType,
              i18n.t('COMMON.MARITAL_STATUS'),
              `${maritalStatus ? i18n.t(`MARITAL_STATUS.${maritalStatus}`) : ''}`
            )}
          </View>
        }
      />
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.avatarContainer}>
        <Image source={imgAvatar as ImageSourcePropType} style={desktopStyles.avatar} />
        <Text style={desktopStyles.name}>{name ?? ''}</Text>
      </View>
      <View style={desktopStyles.infoContainer}>
        {renderInfoItem(icUsers as ImageSourcePropType, i18n.t('COMMON.GENDER'), gender)}
        {renderInfoItem(icProfile as ImageSourcePropType, i18n.t('COMMON.AGE'), `${age ?? ''}`)}
        {renderInfoItem(
          ic2Heart as ImageSourcePropType,
          i18n.t('COMMON.MARITAL_STATUS'),
          `${maritalStatus ? i18n.t(`MARITAL_STATUS.${maritalStatus}`) : ''}`
        )}
        {renderInfoItem(
          icCalendar as ImageSourcePropType,
          i18n.t('SOLUTION.PLAN_CREATED_DATE'),
          createdDate ? moment(createdDate).format('DD/MM/YYYY') : ''
        )}
      </View>
    </View>
  )
}

export default SummaryInfo

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 200,
    paddingLeft: 16,
  },
  avatar: {
    width: 38,
    height: 38,
  },
  name: {
    fontSize: 18,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
    textTransform: 'uppercase',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
    marginVertical: 4,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    paddingLeft: 40,
  },
  avatar: {
    width: 38,
    height: 38,
  },
  name: {
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
    textTransform: 'uppercase',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFBFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    paddingVertical: 13,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 16,
    color: '#505D6F',
    fontWeight: '600',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    width: '100%',
  },
  infoItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 8,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    padding: 16,
    backgroundColor: '#FFF',
    borderRadius: 19,
    width: '100%',
  },
  avatar: {
    width: 38,
    height: 38,
  },
  name: {
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
    textTransform: 'uppercase',
  },
})
