import React from 'react'
import { StyleSheet, View, Text, Image, FlatList } from 'react-native'
import icBlueHeart from '../../../assets/images/blue-heart.png'
import icCircleCheck from '../../../assets/images/circle-check.png'
import { vnNumberFormatter, Spacer } from '../../../common'
import i18n from 'i18n-js'

const cardWidth = 250

type IProps = {
  /**
   * Array of health care program
   *
   * title - name of program
   *
   * value - value of program
   */
  data?: Array<{ title: string; value?: number }>
  style?: Record<string, unknown>
}

function SummaryHealthCareProgram({ data, style }: IProps) {
  function renderItem(title: string, value?: number) {
    return (
      <View style={desktopStyles.infoItemContainer}>
        <Image source={icCircleCheck} style={desktopStyles.infoItemIcon} />
        <Text style={desktopStyles.infoItemTitle}>{title}</Text>
        <Text style={desktopStyles.infoItemValue}>
          {value ? `${vnNumberFormatter.format(value)} ${i18n.t('COMMON.MILLION_VND').toLowerCase()}` : '-'}
        </Text>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Image source={icBlueHeart} style={desktopStyles.iconTitle} />
        <Text style={desktopStyles.title}>{i18n.t('SOLUTION.HEALTHCARE_PROGRAM')}</Text>
        <Spacer />
      </View>
      <View style={desktopStyles.infoContainer}>
        <FlatList
          data={data}
          scrollEnabled={false}
          renderItem={(item) => {
            return (
              <View key={item.index} style={{ width: '100%' }}>
                {renderItem(item.item.title, item.item.value)}
              </View>
            )
          }}
        />
      </View>
    </View>
  )
}

export default SummaryHealthCareProgram

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 4,
    borderColor: '#FFAFAF',
    borderWidth: 2,
    borderRadius: 12,
    height: 190,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 12,
    paddingHorizontal: 15.51,
    width: '100%',
  },
  infoItemContainer: {
    paddingVertical: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    flex: 1,
    fontSize: 12.44,
    color: '#F77F00',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  viewFeeContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  viewFeeIcon: {
    width: 18,
    height: 20,
  },
  viewFeeLabel: {
    color: '#2F7AEB',
    fontStyle: 'italic',
    fontSize: 15,
    marginLeft: 12,
  },
})
