import React from 'react'
import {
  StackActions,
} from '@react-navigation/native'

export const navigationRef = React.createRef()

export class NavService {
  private routeNamePrev: string = ''

  navigate(routeName: string, params?: {}) {
    try {
      const currentRouter = (navigationRef.current as any)?.getCurrentRoute()
      if (currentRouter && currentRouter.name) {
        this.routeNamePrev = currentRouter.name
      } else {
        this.routeNamePrev = ''
      }
      ;(navigationRef.current as any)?.navigate(routeName as never, params as never)
    } catch (error) {
      console.error(error)
    }
  }

  push(routeName: string, params?: {}) {
    try {
      ;(navigationRef.current as any)?.dispatch(StackActions.push(routeName as never, params as never))
    } catch (error) {
      console.error(error)
    }
  }

  replace(routeName: string, params?: {}) {
    try {
      ;(navigationRef.current as any)?.dispatch(StackActions.replace(routeName as never, params as never))
    } catch (error) {
      console.error(error)
    }
  }
}

export default new NavService()
