import React from 'react'
import { Animated, Easing, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

export type LoadableComponentProps = {
  triggerAnimation?: boolean
  style?: StyleProp<ViewStyle> | undefined
  children?: JSX.Element
}

function DelayAppearComponent({ triggerAnimation, style, children }: LoadableComponentProps) {
  const topAnim = React.useRef(new Animated.Value(10)).current
  const opacityAnim = React.useRef(new Animated.Value(0)).current
  React.useEffect(() => {
    if (triggerAnimation) {
      Animated.timing(topAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      } as Animated.TimingAnimationConfig).start()
    }
  }, [triggerAnimation])
  return (
    <Animated.View style={[styles.container, { top: topAnim, opacity: opacityAnim }, style]}>{children}</Animated.View>
  )
}

export default DelayAppearComponent

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
})
