import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import cnaService from '../services/CustomAnalyzeService'

export interface SaveFormState {
  loading?: boolean
}

const initialState: SaveFormState = {
  loading: false,
}

export const sendMailPdf = createAsyncThunk('save-form/send-mail-pdf', async (req: any, thunkAPI: any) => {
  try {
    let response = await cnaService.sendMailPdf(req)
    return response.data
  } catch (error: any) {
      console.log(error)
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const saveFormSlice = createSlice({
  name: 'save-form',
  initialState,
  reducers: {
    updateSaveForm: (state, action: PayloadAction<Array<any>>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMailPdf.pending, (state) => {
        state.loading = true
      })
      .addCase(sendMailPdf.fulfilled, (state, action) => {
        state.loading = action.payload?.result
      })
      .addCase(sendMailPdf.rejected, (state) => {
        state.loading = false
      })
  },
})

export const { updateSaveForm } = saveFormSlice.actions

const { reducer } = saveFormSlice
export default reducer
