import { mathHelper } from '../helper/Mathematic'
import i18n from 'i18n-js'

/**
 * Convert currency based on value
 *
 * @param value vietnam dong
 */
export const vnCurrencyUnitFormatter = (value: number) => {
  if ((value ?? 0) >= 1000000000) {
    return mathHelper.divideWithDynamicPrecisionResult(value ?? 0, 1000000000, 1) + ' ' + i18n.t('COMMON.BILLION')
  } else {
    return mathHelper.divideWithDynamicPrecisionResult(value ?? 0, 1000000, 0) + ' ' + i18n.t('COMMON.MILLION')
  }
}
