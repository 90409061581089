import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image } from 'react-native'
import icRedHeart from '../../../assets/images/red-heart.png'
import { vnNumberFormatter, SizedBox, Spacer } from '../../../common'
import ColumnChart from '../../Charts/ColumnChart'
import i18n from 'i18n-js'
import { styles as globalStyles } from '../../../common'
import icRate from '../../../assets/images/rate.png'
import icCirce from '../../../assets/images/circle.png'
import InputChart from './InputChart'

type IProps = {
  customerAge: number
  data?: Array<{ policyYear: number; ape: number; fundValue: number }>
  style?: Record<string, unknown>
  quotationFundAllocation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }
  productTypeChart?: string
  extraInvest?: number
  isLoadingChartData?: boolean
  showInvestInfo?: boolean
  showFundInfo?: boolean
  onConfirm?: (quotation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }) => void
  onOpenExtraInvestModal?: () => void
}

const patternILPData = [
  {
    seriesName: 'Số tiền tham gia',
    data: new Array<{ x: string; y: number }>(),
    color: '#FF9900',
  },
  {
    seriesName: 'Số tiền nhận về',
    data: new Array<{ x: string; y: number }>(),
    color: '#26C6DA',
  },
]

function ChartBoxILP({
  data,
  customerAge,
  style,
  quotationFundAllocation,
  productTypeChart,
  extraInvest,
  isLoadingChartData,
  showInvestInfo = true,
  showFundInfo = true,
  onConfirm,
  onOpenExtraInvestModal,
}: IProps) {
  const [chartData, setChartData] = React.useState<any>(patternILPData)
  const [quotationData, setQuotationData] = React.useState<{
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }>()

  React.useEffect(() => {
    setQuotationData(quotationFundAllocation)
  }, [quotationFundAllocation])

  React.useEffect(() => {
    if (data && productTypeChart) {
      let temp = chartData
      temp[0].data = []
      temp[1].data = []
      for (let i = 0; i < data.length; i++) {
        if (i === 0 || (i + 1) % 5 === 0) {
          temp[0].data.push({
            x: `${customerAge + data[i]?.policyYear} tuổi`,
            y: data[i]?.ape,
          })
          temp[1].data.push({
            x: `${customerAge + data[i]?.policyYear} tuổi`,
            y: data[i]?.fundValue,
          })
        }
      }
      setChartData([...temp])
    }
  }, [data, productTypeChart])

  function updateQuotation(key: string, value: string) {
    setQuotationData({
      ...quotationData,
      [key]: value ? parseFloat(value) : null,
    })
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Image source={icRedHeart as ImageSourcePropType} style={desktopStyles.iconTitle} />
        <Text style={desktopStyles.title}>Giá trị tài khoản nhận về khi cuộc sống bình yên</Text>
      </View>
      <ColumnChart
        width={'100%'}
        height={150}
        data={chartData as any}
        defaultColumnWidth={104}
        maxColumnWidth={108}
        productType={'(3)'}
        loading={isLoadingChartData}
        extraInvest={false}
        chartInvesment={chartData}
      />
      {showInvestInfo && (
        <>
          <View style={desktopStyles.infoContainer}>
            <View style={desktopStyles.headWrapper}>
              <View style={desktopStyles.heading}>
                <Text style={desktopStyles.titleHightlight}>Giá trị đầu tư</Text>
                <Text style={[globalStyles.RedText, { fontSize: 10 }]}>&nbsp;(4)</Text>
                <Spacer />
                <View style={desktopStyles.investBox}>
                  <Text>Tổng giá trị đầu tư thêm vào năm đầu tiên:</Text>
                  <Text style={desktopStyles.investNumber}>{`${vnNumberFormatter.format(extraInvest ?? 0)} ${i18n.t(
                    'COMMON.MILLION_VND'
                  )}`}</Text>
                </View>
              </View>
            </View>
          </View>
          {showFundInfo ? (
            <>
              <View style={desktopStyles.infoContainerChart}>
                <InputChart
                  title="Quỹ PruLink Cổ phiếu VN"
                  quotationKey="vietNamEquity"
                  type="input"
                  style={desktopStyles.infoContainerBox}
                  onChange={updateQuotation}
                  bottomNumber={9.0}
                  value={quotationData?.vietNamEquity}
                />
                <InputChart
                  title="Quỹ PruLink Tăng trưởng"
                  quotationKey="growth"
                  type="input"
                  style={desktopStyles.infoContainerBox}
                  bottomNumber={8.7}
                  onChange={updateQuotation}
                  value={quotationData?.growth}
                />
                <InputChart
                  title="Quỹ PruLink Cân bằng"
                  quotationKey="balanced"
                  type="input"
                  style={desktopStyles.infoContainerBox}
                  bottomNumber={8.5}
                  onChange={updateQuotation}
                  value={quotationData?.balanced}
                />
                <InputChart
                  title="Quỹ PruLink Bền vững"
                  quotationKey="stable"
                  type="input"
                  style={desktopStyles.infoContainerBox}
                  bottomNumber={8.3}
                  onChange={updateQuotation}
                  value={quotationData?.stable}
                />
                <InputChart
                  title="Quỹ PruLink Trái phiếu VN"
                  quotationKey="vietNamBond"
                  type="input"
                  style={desktopStyles.infoContainerBox}
                  bottomNumber={8.0}
                  onChange={updateQuotation}
                  value={quotationData?.vietNamBond}
                />
                <InputChart
                  title="Quỹ PruLink Bảo toàn"
                  quotationKey="preserved"
                  type="input"
                  style={desktopStyles.infoContainerBox}
                  bottomNumber={7.0}
                  onChange={updateQuotation}
                  value={quotationData?.preserved}
                />
              </View>
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                <SizedBox width={20} />
                <View style={[globalStyles.DisplayFlexRow, globalStyles.VerticalCenter]}>
                  <Image source={icRate as ImageSourcePropType} style={globalStyles.Icon18} />
                  <SizedBox width={8} />
                  <Text style={[globalStyles.CommonSubTitle, { fontSize: 12 }]}>Tỷ lệ đầu tư vào mỗi Quỹ PruLink</Text>
                  <Text style={[globalStyles.RedText, { fontSize: 10 }]}> (5)</Text>
                </View>
                <View
                  style={[
                    globalStyles.Flex1,
                    globalStyles.DisplayFlexCol,
                  ]}
                >
                  <View
                    style={[
                      globalStyles.Flex1,
                      globalStyles.DisplayFlexRow,
                      globalStyles.VerticalCenter,
                      globalStyles.FloatRight,
                    ]}
                  >
                    <Text style={[globalStyles.CommonSubTitle, { fontSize: 12 }]}>
                      Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                    </Text>
                    <Text style={[globalStyles.RedText, { fontSize: 10 }]}> (6)</Text>
                  </View>
                  <View
                    style={[
                      globalStyles.Flex1,
                      globalStyles.DisplayFlexRow,
                      globalStyles.VerticalCenter,
                      globalStyles.FloatRight,
                    ]}
                  >
                    <Image source={icCirce as ImageSourcePropType} style={globalStyles.Icon14} />
                    <SizedBox width={8} />
                    <Text style={[globalStyles.CommonSubTitle, { fontSize: 12 }]}>
                      Mức lãi suất các quỹ PruLink dùng để minh họa "Số tiền nhận về
                    </Text>
                    <Text style={[globalStyles.RedText, { fontSize: 10 }]}> (3)</Text>
                    <Text style={[globalStyles.CommonSubTitle, { fontSize: 12 }]}>
                      "
                    </Text>
                  </View>
                </View>
                
                <SizedBox width={20} />
              </View>
            </>
          ) : (
            <SizedBox height={10} />
          )}
        </>
      )}
    </View>
  )
}

export default ChartBoxILP

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 5.14,
    borderColor: '#FFAFAF',
    borderWidth: 2,
    borderRadius: 12,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 15,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  titleHightlight: {
    color: '#ED1B2E',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 20,
  },
  heading: {
    color: '#505D6F',
    fontSize: 20,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  investBox: {
    paddingHorizontal: 25,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    borderRadius: 20,
    fontSize: 18,
    color: '#505D6F',
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  investNumber: {
    fontSize: 20,
    paddingVertical: 7,
    fontWeight: '600',
    color: '#1F1F20',
    marginLeft: 50,
  },
  headWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  infoContainer: {
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 30,
    marginTop: 20,
    paddingVertical: 6,
    paddingHorizontal: 8,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
  },
  infoContainerChart: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  infoContainerBox: {
    width: '16%',
  },
  infoContainerBoxTotal: {
    width: '16%',
    backgroundColor: '#fff',
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 100,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    flex: 1,
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  buttonConfirm: {
    marginTop: 0,
    paddingVertical: 0,
    paddingHorizontal: 21,
    height: 41,
    borderRadius: 10,
  },
  IconChart: {
    height: 25,
    width: 18,
  },
})
