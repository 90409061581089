import { Dialog } from '../common'
import React from 'react'
import { StyleSheet } from 'react-native'
import i18n from 'i18n-js'

export type ConfirmSavePopUpProps = {
  isShow: boolean
  onOk: () => void
  onCancel: () => void
  onClose: () => void
}

function ConfirmSavePopUp({ isShow, onOk, onCancel, onClose }: ConfirmSavePopUpProps) {

  return (
    <Dialog
      type="confirm"
      visible={isShow}
      showCloseIcon
      dataDelete={{
        title: i18n.t('COMMON.NOTI'),
        description: i18n.t('MESS.CONFIRM_LEAVING'),
      }}
      okText={i18n.t('COMMON.YES')}
      cancelText={i18n.t('COMMON.NO')}
      onRemove={onClose}
      onOk={onOk}
      onCancel={onCancel}
      contentStyle={{ maxWidth: 500, padding: 0, paddingTop: 0 }}
      dialogStyle={{ maxWidth: 500, padding: 0 }}
    />
  )
}

export default ConfirmSavePopUp

const desktopStyles = StyleSheet.create({})
