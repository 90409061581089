import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'


export interface hcPlanDetail {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    answer?: string
    boarding?: number
    outPatient?: number
    dentistry? : number
  }
  


export class hcPlanState {
  loading?: boolean
  hcPlan?: hcPlanDetail
}

const initialState: hcPlanState = {
  loading: false,
  hcPlan: {
    isTemporaryCheck: true,
    // isSelect: true,
    answer: '',
    boarding: 0,
    outPatient: 0,
    dentistry : 0
  },
}

export const updateHcPlanSlice = createAsyncThunk('users/updateHcPlanSlice', async (data) => {
  return data
});



export const hcPlanSlice = createSlice({
  name: 'hcPlan',
  initialState,
  reducers: {
    loadHcPlanState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.hcPlan = action.payload
    },
    updateHcPlanState: (state, action: any) => {
      state.hcPlan = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateHcPlanSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadHcPlanState, updateHcPlanState } = hcPlanSlice.actions

const { reducer } = hcPlanSlice
export default reducer
