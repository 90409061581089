// components/PopUp.js
import * as React from 'react'
import { SafeAreaView, StyleSheet, View, Text, Image ,ImageSourcePropType,TouchableOpacity } from 'react-native';
import icDocument from '../../assets/icon/document.png'
import Layout from '../../constants/Layout';


export type TooltipsProps = {
    image?: boolean
    title:  string
    titleStyle?: object
    styles? :object
    onPress?: () => void
    titleWrapper?: object
}


export function Tooltips({ image, title, titleStyle,titleWrapper ,styles , onPress}: TooltipsProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  if (isMobileDevice) {
    return (
      <SafeAreaView style={styles}>
            <TouchableOpacity onPress={onPress} >
        <View style={[mobileStyle.defaultWrapper, titleWrapper]}>
           {
             image ?
               <Image
               source={icDocument as ImageSourcePropType}
               style={TooltipsStyle.icon}
             />
               : null
           }
         <Text style={[TooltipsStyle.defaultTitle, titleStyle]}>{title}</Text>
                </View>
                </TouchableOpacity>
    </SafeAreaView>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={styles}>
            <TouchableOpacity onPress={onPress} >
        <View style={[tabletStyle.defaultWrapper, titleWrapper]}>
           {
             image ?
               <Image
               source={icDocument as ImageSourcePropType}
               style={TooltipsStyle.icon}
             />
               : null
           }
         <Text style={[tabletStyle.defaultTitle, titleStyle]}>{title}</Text>
                </View>
                </TouchableOpacity>
    </SafeAreaView>
    )
  }

  return (
    <SafeAreaView  style={styles}>
            <TouchableOpacity onPress={onPress}>
        <View style={[TooltipsStyle.defaultWrapper, titleWrapper]}>
           {
             image ?
               <Image
               source={icDocument as ImageSourcePropType}
               style={TooltipsStyle.icon}
             />
               : null
           }
         <Text style={[TooltipsStyle.defaultTitle, titleStyle]}>{title}</Text>
                </View>
                </TouchableOpacity>
    </SafeAreaView>
  );
}


export const TooltipsStyle = StyleSheet.create({
  icon: {
    width: 18,
    height: 20,
    marginRight: 10
  },
  defaultWrapper: {
    flexDirection: 'row',
    alignItems:'center',
},
defaultTitle: {
  color: "#2F7AEB",
  fontSize: 14,
  fontStyle: 'italic',
},
})

const tabletStyle = StyleSheet.create({
  icon: {
    width: 15,
    height: 17,
    marginRight: 10
  },
  defaultWrapper: {
    flexDirection: 'row',
    alignItems:'center',
},
defaultTitle: {
  color: "#2F7AEB",
  fontSize: 11.6083,
  fontStyle: 'italic',
},
})

const mobileStyle = StyleSheet.create({
  defaultWrapper: {
    flexDirection: 'row',
    marginTop: 20
  },
})


export default Tooltips
