import { IMAGES } from '../assets/images'
import IcNeeds1 from '../assets/images/needs/needs-1.png'
import IcNeeds1Outline from '../assets/images/needs/needs-1-outline.png'
import IcNeeds1Disabled from '../assets/images/needs-disabled/needs-1.png'
import IcNeeds2 from '../assets/images/needs/needs-2.png'
import IcNeeds2Outline from '../assets/images/needs/needs-2-outline.png'
import IcNeeds2Disabled from '../assets/images/needs-disabled/needs-2.png'
import IcNeeds3 from '../assets/images/needs/needs-3.png'
import IcNeeds3Outline from '../assets/images/needs/needs-3-outline.png'
import IcNeeds3Disabled from '../assets/images/needs-disabled/needs-3.png'
import IcNeeds4 from '../assets/images/needs/needs-4.png'
import IcNeeds4Outline from '../assets/images/needs/needs-4-outline.png'
import IcNeeds4Disabled from '../assets/images/needs-disabled/needs-4.png'
import IcNeeds5 from '../assets/images/needs/needs-5.png'
import IcNeeds5Outline from '../assets/images/needs/needs-5-outline.png'
import IcNeeds5Disabled from '../assets/images/needs-disabled/needs-5.png'
import IcNeeds6 from '../assets/images/needs/needs-6.png'
import IcNeeds6Outline from '../assets/images/needs/needs-6-outline.png'
import IcNeeds6Disabled from '../assets/images/needs-disabled/needs-6.png'
import IcNeeds7 from '../assets/images/needs/needs-7.png'
import IcNeeds7Outline from '../assets/images/needs/needs-7-outline.png'
import IcNeeds7Disabled from '../assets/images/needs-disabled/needs-7.png'
import IcNeeds8 from '../assets/images/needs/needs-8.png'
import IcNeeds8Outline from '../assets/images/needs/needs-8-outline.png'
import IcNeeds8Disabled from '../assets/images/needs-disabled/needs-8.png'
import IcNeeds9 from '../assets/images/needs/needs-9.png'
import IcNeeds9Outline from '../assets/images/needs/needs-9-outline.png'
import IcNeeds9Disabled from '../assets/images/needs-disabled/needs-9.png'
import IcNeeds10 from '../assets/images/needs/needs-10.png'
import IcNeeds10Outline from '../assets/images/needs/needs-10-outline.png'
import IcNeeds10Disabled from '../assets/images/needs-disabled/needs-10.png'
import IcNeeds11 from '../assets/images/needs/needs-11.png'
import IcNeeds11Outline from '../assets/images/needs/needs-11-outline.png'
import IcNeeds11Disabled from '../assets/images/needs-disabled/needs-11.png'
import imgWoman from '../assets/images/woman.png'
import imgMan from '../assets/images/man.png'
import imgVIB from '../assets/images/banks/vib.png'
import { HealthCarePlan } from '../model/HealthCarePlan'

export const createCNASteps = ['Nhu cầu', 'Phân tích', 'Giải pháp']

export const stepList = ['Nhu cầu', 'Phân tích', 'Tổng quan', 'Giải pháp']

export const DEFAULT_CUSTOMER_GENDER = 'FEMALE'

export const genders = [
  { code: 'FEMALE', name: 'Nữ', thumbnail: imgWoman },
  { code: 'MALE', name: 'Nam', thumbnail: imgMan },
]

export const dataScreen = [
  { code: 'PROTECT', title: 'Bảo vệ thu nhập', image: IMAGES.imgProtectIncome, screen: 'Analysis' },
  { code: 'PROTECT', title: 'Bảo vệ tiêu chuẩn cuộc sống', image: IMAGES.imgProtectLifeStandard, screen: 'SafeLife' },
  {
    code: 'PROTECT',
    title: 'Bảo vệ kế hoạch giáo dục cho con',
    image: IMAGES.imgProtectDescendantEducation,
    screen: 'SafeEdu',
  },
  { code: 'PROTECT', title: 'Bảo vệ tài sản', image: IMAGES.imgProtectAssets, screen: 'SafeAsset' },
  {
    code: 'EDUCATION',
    title: 'Tích luỹ cho kế hoạch giáo dục',
    image: IMAGES.imgAccumulateForEducation,
    screen: 'SaveEdu',
  },
  { code: 'MEDICINE', title: 'Chăm sóc Y Tế', image: IMAGES.imgMedicineCare, screen: 'SaveHealth' },
  {
    code: 'MEDICINE',
    title: 'Bảo vệ trước bệnh hiểm nghèo',
    image: IMAGES.imgProtectFatalDisease,
    screen: 'SaveCancer',
  },
  { code: 'RETIREMENT', title: 'An nhàn tuổi già', image: IMAGES.imgRetirePlan, screen: 'SaveRetire' },
  { code: 'OTHER', title: 'Đầu tư gia tăng tài sản', image: IMAGES.imgInvestRaiseAssets, screen: 'InvestAsset' },
  { code: 'OTHER', title: 'Để lại thừa kế', image: IMAGES.imgInheritances, screen: 'SaveInherit' },
  { code: 'OTHER', title: 'Từ thiện', image: IMAGES.imgCharity, screen: 'SaveCharity' },
]

export const needProtectDataList = [
  {
    id: 1,
    title: 'Bảo vệ thu nhập',
    image: IMAGES.imgProtectIncome,
    isChecked: false,
    themeColor: '#BE63F9',
    icon: IcNeeds1,
    iconOutline: IcNeeds1Outline,
    disabledIcon: IcNeeds1Disabled,
    qty: 0,
    code: 'PROTECT',
    key: 'protectIncome',
    screen: 'Analysis',
  },
  {
    id: 2,
    title: 'Bảo vệ tiêu chuẩn cuộc sống',
    image: IMAGES.imgProtectLifeStandard,
    isChecked: false,
    themeColor: '#BE63F9',
    icon: IcNeeds2,
    iconOutline: IcNeeds2Outline,
    disabledIcon: IcNeeds2Disabled,
    qty: 0,
    code: 'PROTECT',
    key: 'protectLife',
    screen: 'SafeLife',
  },
  {
    id: 3,
    title: 'Bảo vệ tài sản',
    image: IMAGES.imgProtectAssets,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#BE63F9',
    icon: IcNeeds3,
    iconOutline: IcNeeds3Outline,
    disabledIcon: IcNeeds3Disabled,
    qty: 0,
    code: 'PROTECT',
    key: 'protectAsset',
    screen: 'SafeAsset',
  },
  {
    id: 4,
    title: 'Bảo vệ kế hoạch giáo dục cho con',
    image: IMAGES.imgProtectDescendantEducation,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#BE63F9',
    icon: IcNeeds4,
    iconOutline: IcNeeds4Outline,
    disabledIcon: IcNeeds4Disabled,
    qty: 0,
    code: 'PROTECT',
    key: 'protectEdu',
    screen: 'SafeEdu',
  },
]

export const needEducationDataList = [
  {
    id: 5,
    title: 'Tích luỹ cho kế hoạch giáo dục',
    image: IMAGES.imgAccumulateForEducation,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#27AE60',
    icon: IcNeeds5,
    iconOutline: IcNeeds5Outline,
    disabledIcon: IcNeeds5Disabled,
    qty: 0,
    code: 'EDUCATION',
    key: 'eduPlan',
    screen: 'SaveEdu',
  },
]

export const needHealthCareDataList = [
  {
    id: 6,
    title: 'Bảo vệ trước bệnh hiểm nghèo',
    image: IMAGES.imgProtectFatalDisease,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#26C6DA',
    icon: IcNeeds6,
    iconOutline: IcNeeds6Outline,
    disabledIcon: IcNeeds6Disabled,
    qty: 0,
    code: 'MEDICINE',
    key: 'hcFatalDisease',
    screen: 'SaveCancer',
  },
  {
    id: 7,
    title: 'Chăm sóc Y tế',
    image: IMAGES.imgMedicineCare,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#26C6DA',
    icon: IcNeeds7,
    iconOutline: IcNeeds7Outline,
    disabledIcon: IcNeeds7Disabled,
    qty: 0,
    code: 'MEDICINE',
    key: 'hcPlan',
    screen: 'SaveHealth',
  },
]

export const needRetirePreparationDataList = [
  {
    id: 8,
    title: 'Chuẩn bị cho tuổi hưu an nhàn',
    image: IMAGES.imgRetirePlan,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#FF9900',
    icon: IcNeeds8,
    iconOutline: IcNeeds8Outline,
    disabledIcon: IcNeeds8Disabled,
    qty: 0,
    code: 'RETIREMENT',
    key: 'retirement',
    screen: 'SaveRetire',
  },
]

export const otherNeedDataList = [
  {
    id: 9,
    title: 'Đầu tư gia tăng tài sản',
    image: IMAGES.imgInvestRaiseAssets,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#FC573B',
    icon: IcNeeds9,
    iconOutline: IcNeeds9Outline,
    disabledIcon: IcNeeds9Disabled,
    qty: 0,
    code: 'OTHER',
    key: 'investment',
    screen: 'InvestAsset',
  },
  {
    id: 10,
    title: 'Để lại thừa kế',
    image: IMAGES.imgInheritances,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#FC573B',
    icon: IcNeeds10,
    iconOutline: IcNeeds10Outline,
    disabledIcon: IcNeeds10Disabled,
    qty: 0,
    code: 'OTHER',
    key: 'inherit',
    screen: 'SaveInherit',
  },
  {
    id: 11,
    title: 'Từ thiện',
    image: IMAGES.imgCharity,
    isChecked: false,
    isTemporaryCheck: false,
    themeColor: '#FC573B',
    icon: IcNeeds11,
    iconOutline: IcNeeds11Outline,
    disabledIcon: IcNeeds11Disabled,
    qty: 0,
    code: 'OTHER',
    key: 'charity',
    screen: 'SaveCharity',
  },
]

export const needsData = [
  {
    code: 'PROTECT',
    title: 'Bảo vệ',
    data: needProtectDataList,
  },
  {
    code: 'EDUCATION',
    title: 'Giáo dục',
    data: needEducationDataList,
  },
  {
    code: 'MEDICINE',
    title: 'Chăm sóc Y tế',
    data: needHealthCareDataList,
  },
  {
    code: 'RETIREMENT',
    title: 'Hưu trí',
    data: needRetirePreparationDataList,
  },
  {
    code: 'OTHER',
    title: 'Khác',
    data: otherNeedDataList,
  },
]

export const martialStatusOptions = [
  { label: 'Độc thân', value: 'SINGLE' },
  { label: 'Đã kết hôn', value: 'MARRIED' },
  { label: 'Khác', value: 'DIVORCED' },
]

export const ages = [
  [18, 24],
  [25, 35],
  [36, 45],
  [46, 55],
  [56, 65],
  [65, 0],
]

export const monthlyIncomes = [
  [0, 10000000],
  [10000000, 15000000],
  [15000000, 20000000],
  [20000000, 25000000],
  [25000000, 30000000],
  [30000000, 35000000],
  [35000000, 50000000],
  [50000000, 0],
]

export const monthlyExpenses = [
  [0, 5000000],
  [5000000, 7000000],
  [8000000, 12000000],
  [13000000, 17000000],
  [18000000, 25000000],
  [25000000, 0],
]

export const yesNoOptions = [
  { value: true, text: 'Có' },
  { value: false, text: 'Không' },
]

export const productTypes = [
  { value: 'ILP', text: 'Có thể không cần lãi suất cam kết' },
  { value: 'ULP', text: 'Phải có lãi suất cam kết cho dù thấp' },
]

export const dataNotesILP = [
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(1)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Tất cả thông tin minh họa bên trên chỉ nhằm mục đích tham khảo. Tư vấn viên vui lòng sử dụng Bảng minh họa quyền lợi để tư vấn chi tiết cho khách hàng.<br />Chi tiết về quyền lợi bảo hiểm, điều kiện áp dụng và điều khoản loại trừ được quy định tại Quy tắc và Điều khoản sản phẩm do Bộ Tài chính phê duyệt. Do đó, Tư vấn viên nên đề xuất Khách hàng vui lòng tham khảo thêm Quy tắc & Điều khoản sản phẩm để hiểu rõ các quyền lợi và đặc tính của sản phẩm.</span>',
    index: '(1)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(2)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Phí bảo hiểm hàng năm/ hàng tháng là con số ước tính, dựa trên giả định hợp đồng bảo hiểm chỉ có một (01) Người được bảo hiểm đồng thời là Bên mua bảo hiểm và điều kiện thẩm định sức khỏe tiêu chuẩn. Phí bảo hiểm này đã bao gồm Quyền lợi bảo hiểm trước các rủi ro và Thẻ sức khỏe chăm sóc Khách hàng và gia đình nêu trên.</span>',
    index: '(2)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(3)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Khách hàng có thể Đầu tư thêm (tối đa 10 lần phí bảo hiểm cơ bản năm hợp đồng đầu tiên/năm) để đáp ứng cho các nhu cầu giáo dục, hưu trí và các nhu cầu khác (nếu có).</span>',
    index: '(3)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(4)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Số tiền tham gia ở từng quỹ Prulink trong Công cụ này đang là sự lựa chọn theo ý muốn của Khách hàng, mà chưa được dựa vào kết quả phân tích từ Phiếu khảo sát mức độ chấp nhận rủi ro. Do đó, quỹ Prulink mà Khách hàng chọn ở Công cụ này có thể khác với quỹ Prulink được đề xuất trong Phiếu khảo sát mức độ chấp nhận rủi ro khi yêu cầu bảo hiểm.</span>',
    index: '(4)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(5)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Tỷ suất lợi nhuận bình quân thực tế trong quá khứ chỉ mang tính chất tham khảo, không có ý nghĩa cam kết kết quả đầu tư trong tương lai. Kết quả hoạt động trong thực tế có thể thay đổi, có thể tăng/giảm hoặc có thể cho kết quả âm do tác động của thị trường tài chính. Khách hàng có thể tham khảo thêm bảng tỉ suất lợi nhuận bình quân thực tế cập nhật tại website <a href="http://eastspring.com/" target="_blank" style="color:#2F7AEB">Trang chủ (eastspring.com)</a>.</span>',
    index: '(5)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(6)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Số tiền nhận về đã bao gồm khoản phí đầu tư thêm (nếu có). Số tiền nhận về này đang được minh họa ở mức lãi suất cao là 9.00% (đối với Quỹ Prulink Cố phiếu VN), 8.7% (đối với Quỹ Prulink Tăng Trưởng), 8.5% (đối với Quỹ Prulink Cân Bằng), 8.3% (đối với Quỹ Prulink Bền Vững), 8.0% (đối với Quỹ Prulink Trái Phiếu VN), 7.00% (đối với Quỹ Prulink Bảo toàn) với giả định là KH không thực hiện rút một phần giá trị quỹ. Đây là lãi suất minh họa ở mức cao, các kịch bản lãi suất khác, cụ thể thấp và trung bình, được minh họa chi tiết tại Bảng minh họa quyền lợi sản phẩm. Tỷ suất đầu tư thưc tế của các quỹ hoàn toàn có thể khác mức lãi suất này, do đó, Số tiền thực tế nhận về này có thể cao/thấp hơn tùy thuộc vào kết quả đầu tư.</span>',
    index: '(6)',
  },
]

export const dataNotesULP = [
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(1)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Tất cả thông tin minh họa bên trên chỉ nhằm mục đích tham khảo. Tư vấn viên vui lòng sử dụng Bảng minh họa quyền lợi để tư vấn chi tiết cho khách hàng.<br />Chi tiết về quyền lợi bảo hiểm, điều kiện áp dụng và điều khoản loại trừ được quy định tại Quy tắc và Điều khoản sản phẩm do Bộ Tài chính phê duyệt. Do đó, Tư vấn viên nên đề xuất Khách hàng vui lòng tham khảo thêm Quy tắc & Điều khoản sản phẩm để hiểu rõ các quyền lợi và đặc tính của sản phẩm.</span>',
    index: '(1)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(2)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Phí bảo hiểm hàng năm/ hàng tháng là con số ước tính, dựa trên giả định hợp đồng bảo hiểm chỉ có một (01) Người được bảo hiểm đồng thời là Bên mua bảo hiểm và điều kiện thẩm định sức khỏe tiêu chuẩn. Phí bảo hiểm này đã bao gồm Quyền lợi bảo hiểm trước các rủi ro và Thẻ sức khỏe chăm sóc Khách hàng và gia đình nêu trên.</span>',
    index: '(2)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(3)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Khách hàng có thể Tích lũy thêm (tối đa 5 lần phí bảo hiểm cơ bản năm hợp đồng đầu tiên/năm) để đáp ứng cho các nhu cầu giáo dục, hưu trí và các nhu cầu khác (nếu có).</span>',
    index: '(3)',
  },
  {
    content:
      '<span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(4)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Số tiền nhận về đang được minh họa ở mức lãi suất là 5% (không đảm bảo) với giả định là KH không thực hiện rút giá trị tài khoản hợp đồng. Số tiền thực tế nhận được có thể cao/thấp hơn tùy thuộc vào kết quả đầu tư của Quỹ liên kết chung nhưng không thấp hơn mức lãi suất cam kết.</span>',
    index: '(4)',
  },
]

export const dataNotesILPGenPdf = [
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(1)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Tất cả thông tin minh họa bên trên chỉ nhằm mục đích tham khảo. Tư vấn viên vui lòng sử dụng Bảng minh họa quyền lợi để tư vấn chi tiết cho khách hàng.<br />Chi tiết về quyền lợi bảo hiểm, điều kiện áp dụng và điều khoản loại trừ được quy định tại Quy tắc và Điều khoản sản phẩm do Bộ Tài chính phê duyệt. Do đó, Tư vấn viên nên đề xuất Khách hàng vui lòng tham khảo thêm Quy tắc & Điều khoản sản phẩm để hiểu rõ các quyền lợi và đặc tính của sản phẩm.</span></div>',
    index: '(1)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(2)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Phí bảo hiểm hàng năm/ hàng tháng là con số ước tính, dựa trên giả định hợp đồng bảo hiểm chỉ có một (01) Người được bảo hiểm đồng thời là Bên mua bảo hiểm và điều kiện thẩm định sức khỏe tiêu chuẩn. Phí bảo hiểm này đã bao gồm Quyền lợi bảo hiểm trước các rủi ro và Thẻ sức khỏe chăm sóc Khách hàng và gia đình nêu trên.</span></div>',
    index: '(2)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(3)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Số tiền nhận về đã bao gồm khoản phí đầu tư thêm (nếu có). Số tiền nhận về này đang được minh họa ở mức lãi suất cao là 9.00% (đối với Quỹ Prulink Cố phiếu VN), 8.7% (đối với Quỹ Prulink Tăng Trưởng), 8.5% (đối với Quỹ Prulink Cân Bằng), 8.3% (đối với Quỹ Prulink Bền Vững), 8.0% (đối với Quỹ Prulink Trái Phiếu VN), 7.00% (đối với Quỹ Prulink Bảo toàn) với giả định là KH không thực hiện rút một phần giá trị quỹ. Đây là lãi suất minh họa ở mức cao, các kịch bản lãi suất khác, cụ thể thấp và trung bình, được minh họa chi tiết tại Bảng minh họa quyền lợi sản phẩm. Tỷ suất đầu tư thưc tế của các quỹ hoàn toàn có thể khác mức lãi suất này, do đó, Số tiền thực tế nhận về này có thể cao/thấp hơn tùy thuộc vào kết quả đầu tư.</span></div>',
    index: '(3)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(4)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Khách hàng có thể Đầu tư thêm (tối đa 10 lần phí bảo hiểm cơ bản năm hợp đồng đầu tiên/năm) để đáp ứng cho các nhu cầu giáo dục, hưu trí và các nhu cầu khác (nếu có).</span></div>',
    index: '(4)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(5)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Số tiền tham gia ở từng quỹ Prulink trong Công cụ này đang là sự lựa chọn theo ý muốn của Khách hàng, mà chưa được dựa vào kết quả phân tích từ Phiếu khảo sát mức độ chấp nhận rủi ro. Do đó, quỹ Prulink mà Khách hàng chọn ở Công cụ này có thể khác với quỹ Prulink được đề xuất trong Phiếu khảo sát mức độ chấp nhận rủi ro khi yêu cầu bảo hiểm.</span></div>',
    index: '(5)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(6)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Tỷ suất lợi nhuận bình quân thực tế trong quá khứ chỉ mang tính chất tham khảo, không có ý nghĩa cam kết kết quả đầu tư trong tương lai. Kết quả hoạt động trong thực tế có thể thay đổi, có thể tăng/giảm hoặc có thể cho kết quả âm do tác động của thị trường tài chính. Khách hàng có thể tham khảo thêm bảng tỉ suất lợi nhuận bình quân thực tế cập nhật tại website <a href="http://eastspring.com/" target="_blank" style="color:#2F7AEB">Trang chủ (eastspring.com)</a>.</span></div>',
    index: '(6)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">*</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Kết quả đầu tư của Quỹ là Giá trị quỹ của hợp đồng tại ngày xảy ra sự kiện bảo hiểm TV/TTTB&VV</span></div>',
    index: '*',
  },
]

export const dataNotesULPGenPdf = [
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(1)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Phí bảo hiểm hàng năm/ hàng tháng là con số ước tính, dựa trên giả định hợp đồng bảo hiểm chỉ có một (01) Người được bảo hiểm đồng thời là Bên mua bảo hiểm và điều kiện thẩm định sức khỏe tiêu chuẩn. Phí bảo hiểm này đã bao gồm Quyền lợi bảo hiểm trước các rủi ro và Thẻ sức khỏe chăm sóc Khách hàng và gia đình nêu trên.</span></div>',
    index: '(1)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(2)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Tất cả thông tin minh họa bên trên chỉ nhằm mục đích tham khảo. Tư vấn viên vui lòng sử dụng Bảng minh họa quyền lợi để tư vấn chi tiết cho khách hàng.<br />Chi tiết về quyền lợi bảo hiểm, điều kiện áp dụng và điều khoản loại trừ được quy định tại Quy tắc và Điều khoản sản phẩm do Bộ Tài chính phê duyệt. Do đó, Tư vấn viên nên đề xuất Khách hàng vui lòng tham khảo thêm Quy tắc & Điều khoản sản phẩm để hiểu rõ các quyền lợi và đặc tính của sản phẩm.</span></div>',
    index: '(2)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(3)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Khi xảy ra sự kiện bảo hiểm TV/TTTB&VV, KH sẽ nhận được giá trị lớn hơn [số tiền bảo hiểm, giá trị tài khoản cơ bản] cộng thêm giá trị tài khoản tích lũy thêm (nếu có).</span></div>',
    index: '(3)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(4)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Số tiền nhận về đang được minh họa ở mức lãi suất là 5% (không đảm bảo) với giả định là KH không thực hiện rút giá trị tài khoản hợp đồng. Số tiền thực tế nhận được có thể cao/thấp hơn tùy thuộc vào kết quả đầu tư của Quỹ liên kết chung nhưng không thấp hơn mức lãi suất cam kết.</span></div>',
    index: '(4)',
  },
  {
    content:
      '<div><span style="font-size: 13; font-weight: 400; color: #ED1B2E; font-style: italic">(5)</span><span style="font-size: 13; color: #585c6c; font-weight: 400; margin-left: 4px; text-align: left, font-style: italic">Khách hàng có thể Tích lũy thêm (tối đa 5 lần phí bảo hiểm cơ bản năm hợp đồng đầu tiên/năm) để đáp ứng cho các nhu cầu giáo dục, hưu trí và các nhu cầu khác (nếu có).</span></div>',
    index: '(5)',
  },
]

export const dataImportantNotesULPPrintPdf = [
  {
    title: '01',
    content:
      'Các quyền lợi và điều kiện bảo hiểm sẽ tuân theo đúng quy tắc & Điều khoản sản phẩm được Bộ Tài Chính phê duyệt, do đó, Tư vấn viên nên để xuất khách hàng tham khảo thêm Quy tắc & Điều khoản sản phẩm để hiểu rõ các quyền lợi và đặc tính của sản phẩm. Sản phẩm này được cung cấp bởi Công ty TNHH Bảo hiểm nhân thọ Prudential Việt Nam và phân phối bởi Ngân hàng đối tác.',
  },
  {
    title: '02',
    content:
      'Biểu mẫu này được sử dụng với mục đích hỗ trợ Tư vấn viên trong việc khơi gợi / tìm hiểu sơ bộ nhu cầu và khả năng tài chính của khách hàng. Việc sử dụng biểu mẫu này sẽ không thay thế cho các biểu mẫu khác thuộc quy trình bán hàng thông thường như Bảng tài liệu minh họa bán hàng, Bảng phân tích nhu cầu khách hàng, Bảng đánh giá mức độ chấp nhận rủi ro của khách hàng.',
  },
  {
    title: '03',
    content:
      'Tư vấn viên không được phép sử dụng các thông tin cá nhân của khách hàng ghi nhận trong biểu mẫu này cho bất kỳ mục đích nào khác, trừ khi có sự đồng ý bằng văn bản của Prudential và/hoặc khách hàng.',
  },
  {
    title: '04',
    content:
      'Chi tiết nhu cầu bảo vệ thu nhập, bảo vệ kế hoạch giáo dục cho con, bảo vệ tiêu chuẩn cuộc sống, bảo vệ trước bệnh hiểm nghèo, chăm sóc y tế được tính theo ý muốn của khách hàng cho từng nhu cầu cụ thể này, dựa trên các thông tin tham khảo được Prudential thu thập từ nhiều nguồn công khai khác nhau trên internet.',
  },
  {
    title: '05',
    content:
      'Bằng việc nhận được Bảng kết quả Phân tích cầu bảo hiểm này từ Prudential, Quý khách hiểu và đồng ý rằng Quý khách đã đồng ý (thông qua Tư vấn viên) cho Prudential được sử dụng thông tin cá nhân của mình để gửi Bảng kết quả Phân tích sơ bộ nhu cầu tài chính cho Quý khách vào từng thời điểm. Quý khách có thể tìm hiểu Chính sách bảo mật thông tin của Prudential để hiểu thêm về cách thức Prudential thu thập, xử lý và lưu trữ thông tin của Quý khách tại đường dẫn: <a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/" target="_blank" style="color:#2F7AEB">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a>',
  },
]

export const dataImportantNotesILPPrintPdf = [
  {
    title: '01',
    content:
      'Các quyền lợi và điều kiện bảo hiểm sẽ tuân theo đúng quy tắc & Điều khoản sản phẩm được Bộ Tài Chính phê duyệt, do đó, Tư vấn viên nên để xuất khách hàng tham khảo thêm Quy tắc & Điều khoản sản phẩm để hiểu rõ các quyền lợi và đặc tính của sản phẩm. Sản phẩm này được cung cấp bởi Công ty TNHH Bảo hiểm nhân thọ Prudential Việt Nam và phân phối bởi Ngân hàng đối tác.',
  },
  {
    title: '02',
    content:
      'Biểu mẫu này được sử dụng với mục đích hỗ trợ Tư vấn viên trong việc khơi gợi / tìm hiểu sơ bộ nhu cầu và khả năng tài chính của khách hàng. Việc sử dụng biểu mẫu này sẽ không thay thế cho các biểu mẫu khác thuộc quy trình bán hàng thông thường như Bảng tài liệu minh họa bán hàng, Bảng phân tích nhu cầu khách hàng, Bảng đánh giá mức độ chấp nhận rủi ro của khách hàng.',
  },
  {
    title: '03',
    content:
      'Tư vấn viên không được phép sử dụng các thông tin cá nhân của khách hàng ghi nhận trong biểu mẫu này cho bất kỳ mục đích nào khác, trừ khi có sự đồng ý bằng văn bản của Prudential và/hoặc khách hàng.',
  },
  {
    title: '04',
    content:
      'Chi tiết nhu cầu bảo vệ thu nhập, bảo vệ kế hoạch giáo dục cho con, bảo vệ tiêu chuẩn cuộc sống, bảo vệ trước bệnh hiểm nghèo, chăm sóc y tế được tính theo ý muốn của khách hàng cho từng nhu cầu cụ thể này, dựa trên các thông tin tham khảo được Prudential thu thập từ nhiều nguồn công khai khác nhau trên internet.',
  },
  {
    title: '05',
    content:
      'Bằng việc nhận được Bảng kết quả Phân tích cầu bảo hiểm này từ Prudential, Quý khách hiểu và đồng ý rằng Quý khách đã đồng ý (thông qua Tư vấn viên) cho Prudential được sử dụng thông tin cá nhân của mình để gửi Bảng kết quả Phân tích sơ bộ nhu cầu tài chính cho Quý khách vào từng thời điểm. Quý khách có thể tìm hiểu Chính sách bảo mật thông tin của Prudential để hiểu thêm về cách thức Prudential thu thập, xử lý và lưu trữ thông tin của Quý khách tại đường dẫn: <a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/" target="_blank" style="color:#2F7AEB">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a>',
  },
]

export const dataExclusiveTermsPrintPdf = [
  {
    title: ' Loại trừ trường hợp tử vong',
    content: [
      'Hành động tự tử dù trong trạng thái tinh thần bình thường hay mất trí trong vòng hai (02) năm kể từ Ngày hiệu lực hợp đồng hoặc Ngày khôi phục hiệu lực hợp đồng gần nhất (nếu có), tùy thuộc ngày nào xảy ra sau. Đối với phần tăng thêm của Số tiền bảo hiểm (nếu có) loại trừ do nguyên nhân tự tử theo quy định tại điều này sẽ được áp dụng trong vòng 24 tháng tính từ ngày có hiệu lực của việc tăng số tiền bảo hiểm hoặc khôi phục hiệu lực gần nhất, tùy ngày nào đến sau; hoặc',
      'Do hành vi cố ý của Bên mua bảo hiểm hoặc Người được bảo hiểm hoặc người nhận quyền lợi bảo hiểm; hoặc',
      'Nhiễm HIV, bị AIDS, hoặc những bệnh liên quan đến AIDS ngoại trừ trường hợp nhiễm HIV khi đang thực hiện nhiệm vụ tại nơi làm việc như là một nhân viên y tế hoặc công an, cảnh sát; hoặc',
      'Tử hình do bị thi hành án tử hình.',
    ],
  },
  {
    title: ' Loại trừ trường hợp thương tật toàn bộ vĩnh viễn',
    content: [
      'Đã xảy ra trước Ngày hiệu lực hợp đồng hoặc trước Ngày khôi phục hiệu lực hợp đồng gần nhất (nếu có) hoặc trước Ngày chấp thuận yêu cầu tăng Số tiền bảo hiểm gần nhất (nếu có), tùy ngày nào đến sau; hoặc....',
      'Tự tử không thành dẫn đến Thương tật toàn bộ vĩnh viễn hay tự gây thương tích của Người được bảo hiểm dù trong trạng thái bình thường hay mất trí; hoặc',
      'Tham gia các hoạt động hàng không, trừ khi với tư cách là hành khách trên các chuyến bay dân dụng; hoặc',
      'Hành vi cố ý của Bên mua bảo hiểm hoặc Người được bảo hiểm hoặc người nhận quyền lợi bảo hiểm.',
    ],
  },
]

export const resetCnaData = {
  accident: null,
  agentCode: null,
  ape: null,
  charity: { isSelect: false, isTemporaryCheck: false, total: null, subTotal: null },
  cnaCode: null,
  createdAt: null,
  customerAge: null,
  customerEmail: null,
  customerGender: null,
  customerName: null,
  customerPhone: null,
  eduPlan: {
    isSelect: false,
    isTemporaryCheck: false,
    children: null,
    total: null,
    childrenDetails: null,
    subTotal: null,
  },
  feedback: null,
  hcFatalDisease: { isSelect: false, isTemporaryCheck: false, total: null, subTotal: null },
  hcPlan: { isSelect: false, isTemporaryCheck: false, total: null, hcPlanDetail: null, subTotal: null },
  inherit: { isSelect: false, isTemporaryCheck: false, total: null, subTotal: null },
  investment: {
    isSelect: false,
    isTemporaryCheck: false,
    money: null,
    term: null,
    isPrepare: false,
    saveMoney: null,
    total: null,
    subTotal: null,
  },
  isSatisfaction: false,
  maritalStatus: null,
  needTerm: null,
  needTotal: null,
  needSubTotal: null,
  product: null,
  profitPercent: null,
  protectAsset: {
    isSelect: false,
    isTemporaryCheck: false,
    total: null,
    subTotal: null,
    collateral: null,
    saving: null,
    otherAssets: null,
  },
  protectEdu: {
    isSelect: false,
    isTemporaryCheck: false,
    children: null,
    total: null,
    eduPlanAge: null,
    childrenDetails: null,
    subTotal: null,
  },
  protectIncome: {
    isSelect: false,
    isTemporaryCheck: false,
    month: null,
    monthlyIncome: 0,
    total: null,
    subTotal: null,
  },
  protectLife: {
    isSelect: false,
    isTemporaryCheck: false,
    isSubsidy: false,
    month: null,
    monthlyFixedExpense: 0,
    monthlySubsidy: null,
    subsidyMonth: null,
    total: null,
    subTotal: null,
  },
  questionObjects: null,
  quotationFundAllocation: null,
  quotations: null,
  rate: null,
  reminiscentName: null,
  retirement: {
    isSelect: false,
    isTemporaryCheck: false,
    age: null,
    countDown: null,
    spending: null,
    duration: null,
    total: null,
    subTotal: null,
  },
  reviewDate: null,
  solutionDate: null,
  status: null,
  stringCreatedDate: null,
  sumAssured: null,
  topUp: null,
  updatedAt: null,
  _id: null,
}

export const defaultQuotationFundAllocation = {
  vietNamEquity: 100,
  growth: 0,
  balanced: 0,
  stable: 0,
  vietNamBond: 0,
  preserved: 0,
}

export const pageNumberRouteNameMapping = {
  3: 'NeedsCart',
  4: 'CustomerNeed',
  5: 'NeedsOverall',
  6: 'Advisor',
  7: 'Solution',
} as any

export const tableFeeColumns = [
  {
    title: 'Năm hợp đồng',
    dataIndex: 'contractYear',
    width: 200,
  },
  {
    title: 'Năm 1',
    dataIndex: 'contractYear1',
  },
  {
    title: 'Năm 2',
    dataIndex: 'contractYear2',
  },
  {
    title: 'Năm 3',
    dataIndex: 'contractYear3',
  },
  {
    title: 'Năm 4',
    dataIndex: 'contractYear4',
  },
  {
    title: 'Năm 5+',
    dataIndex: 'contractYear5',
  },
]

export const tableFeeDataSourceILP = [
  {
    contractYear: 'Phí bảo hiểm cơ bản đóng cho năm HĐ',
    contractYear1: '85%',
    contractYear2: '75%',
    contractYear3: '10%',
    contractYear4: '5%',
    contractYear5: '0%',
  },
  {
    contractYear: 'Phí bảo hiểm đóng thêm',
    contractYear1: '5%',
    contractYear2: '5%',
    contractYear3: '5%',
    contractYear4: '5%',
    contractYear5: '5%',
  },
]

export const freefatalDiseaseULP = {
  name: '<span style="font-weight: bold">BẢO HIỂM TỪ BỎ THU PHÍ BỆNH LÝ NGHIÊM TRỌNG<br>(DANH SÁCH 09 (CHÍN) BỆNH LÝ NGHIÊM TRỌNG)</span> ',
  listFreeULP: [
    '1. Tai biến mạch máu não',
    '2. Hôn mê',
    '3. Bệnh nhồi máu cơ tim',
    '4. Ung thư đe dọa tính mạng',
    '5. Phẫu thuật ghép tạng chủ',
    '6. Viêm gan siêu vi thể tối cấp',
    '7. Phẫu thuật thay van tim qua mổ tim hở',
    '8. Phẫu thuật sọ não do bệnh của não bộ',
    '9. Suy thận giai đoạn cuối',
  ],
}

export const fatalDiseaseULP = {
  name: '<span style="font-weight: bold;">BẢO HIỂM BỆNH LÝ NGHIÊM TRỌNG<br>(DANH SÁCH 09 (CHÍN) BỆNH LÝ NGHIÊM TRỌNG)</span> ',
  listULP: [
    '1. Tai biến mạch máu não',
    '2. Hôn mê',
    '3. Bệnh nhồi máu cơ tim',
    '4. Ung thư đe dọa tính mạng',
    '5. Phẫu thuật ghép tạng chủ',
    '6. Viêm gan siêu vi thể tối cấp',
    '7. Phẫu thuật thay van tim qua mổ tim hở',
    '8. Phẫu thuật sọ não do bệnh của não bộ',
    '9. Suy thận giai đoạn cuối',
  ],
}

export const freefatalDiseaseILP = {
  name: '<span style="font-weight: bold;">BẢO HIỂM MIỄN ĐÓNG PHÍ BỆNH LÝ NGHIÊM TRỌNG <br>DANH MỤC BỆNH LÝ NGHIÊM TRỌNG GIAI ĐOẠN SAU</span> ',
  listFreeILP: [
    '1. Ung thư đe dọa tính mạng',
    '2. Suy thận giai đoạn cuối',
    '3. Phẫu thuật ghép tạng chủ',
    '4. Phẫu thuật ghép tủy xương',
    '5. Bệnh phổi giai đoạn cuối',
    '6. Bệnh gan nghiêm trọng',
    '7. Viêm gan vi-rút tối cấp',
    '8. Bệnh thiếu máu bất sản',
    '9. Viêm tụy mạn tính tái phát',
    '10. Viêm thận do bệnh Lupus ban đỏ hệ thống',
    '11. U tủy tuyến thượng thận',
    '12. Bệnh nang tủy thận',
    '13. Viêm gan tự miễn mạn tính',
    '14. Nhồi máu cơ tim',
    '15. Bệnh động mạch vành cần phẫu thuật',
    '16. Phẫu thuật van tim',
    '17. Phẫu thuật động mạch chủ',
    '18. Tăng áp lực động mạch phổi nguyên phát',
    '19. Bệnh cơ tim',
    '20. Các bệnh động mạch vành khác',
    '21. Viêm nội tâm mạc nhiễm trùng',
    '22. Đột quỵ',
    '23. Hôn mê 96 giờ',
    '24. U não lành tính',
    '25. Chấn thương đầu nghiêm trọng',
    '26. Liệt',
    '27. Viêm não',
    '28. Viêm màng não vi khuẩn',
    '29. Phẫu thuật sọ não',
    '30. Bệnh tế bào thần kinh vận động',
    '31. Bệnh xơ cứng rải rác',
    '32. Loạn dưỡng cơ',
    '33. Bệnh Creutzfeldt- Jakob',
    '34. Bệnh Parkinson',
    '35. Xơ cứng cột bên teo cơ',
    '36. Bệnh Alzheimer/ Sa sút trí tuệ nghiêm trọng',
    '37. Bại liệt',
    '38. Bệnh nhược cơ',
    '39. Bệnh loãng xương nghiêm trọng',
    '40. Liệt tứ chi do chấn thương tủy sống',
    '41. Gãy cột sống hoàn toàn do tai nạn',
    '42. Phẫu thuật phình động mạch não',
    '43. Hội chứng Apallic',
    '44. Liệt trên nhân tiến triển',
    '45. Cắt cụt các chi',
    '46. Điếc',
    '47. Mù',
    '48. Câm',
    '49. Bỏng nặng',
    '50. Xơ cứng bì tiến triển',
    '51. Nhiễm HIV do truyền máu',
    '52. Nhiễm HIV do nghề nghiệp',
    '53. Viêm đa khớp dạng thấp nặng',
    '54. Bệnh chân voi',
    '55. Viêm hoại tử nghiêm trọng',
    '56. Suy tuyến thượng mạn tính ',
    '57. Phẫu thuật cắt bỏ đại tràng do viêm loét',
    '58. Bệnh Crohn',
    '59. Phẫu thuật điều trị vẹo cột sống',
  ],
}

export const fatalDiseaseILP = [
  {
    headerFixed:
      '<span style="font-weight: bold;">BẢO HIỂM BỆNH LÝ NGHIÊM TRỌNG<br>(BAO GỒM 99 BỆNH LÝ NGHIÊM TRỌNG)</span>',
    name: 'BỆNH LÝ NGHIÊM TRỌNG GIAI ĐOẠN ĐẦU',
    listFatal: [
      {
        title: 'NHÓM 1',
        content: [
          '1. Ung thư biểu mô tại chỗ của một số cơ quan',
          '2. Ung thư giai đoạn đầu của các cơ quan chuyên biệt',
          '3. Phẫu thuật lấy một bên thận',
          '4. Ghép ruột non ',
          '5. Ghép giác mạc',
          '6. Phẫu thuật lấy một bên phổi',
          '7. Đặt màng lọc tĩnh mạch chủ',
          '8. Phẫu thuật gan',
          '9. Phẫu thuật tái tạo đường mật',
          '10. Hen phế quản nặng',
          '11. Thiếu máu bất sản hoặc Suy tủy xương có khả năng hồi phục',
          '12. Hội chứng xơ hóa tủy xương/ Xơ tủy nguyên phát',
          '13. Viêm tụy hoại tử xuất huyết cấp tính',
        ],
      },
      {
        title: 'NHÓM 2',
        content: [
          '14. Đặt máy tạo nhịp tim',
          '15. Đặt máy khử rung tim',
          '16. Bắc cầu trực tiếp động mạch vành có xâm lấn tối thiểu',
          '17. Phẫu thuật xâm lấn tối thiểu động mạch chủ',
          '18. Phương pháp tạo hình hoặc rạch van tim',
          '19. Phẫu thuật tạo hình mạch',
          '20. Phẫu thuật cắt bỏ màng ngoài tim',
          '21. Tăng áp lực động mạch phổi nguyên phát giai đoạn sớm',
          '22. Phình động mạch chủ',
        ],
      },
      {
        title: ' NHÓM 3',
        content: [
          '23. Phẫu thuật động mạch cảnh',
          '24. Đặt ống thông não bộ (shunt)',
          '25. Hôn mê 48 giờ',
          '26. Phẫu thuật cắt bỏ u tuyến yên bằng đường qua xoang bướm hoặc đường Mũi',
          '27. Mất một chi',
          '28. Loãng xương có gãy xương',
          '29. Bệnh động kinh nghiêm trọng',
          '30. Bệnh Parkinson giai đoạn sớm',
          '31. Viêm tủy do lao',
          '32. Phẫu thuật huyết khối xoang hang',
        ],
      },
      {
        title: 'NHÓM 4',
        content: ['33. Phẫu thuật cấy ghép ốc tai', '34. Mất thị lực một mắt', '35. Bỏng sâu diện tích nhỏ'],
      },
    ],
  },

  {
    name: 'BỆNH LÝ NGHIÊM TRỌNG GIAI ĐOẠN SAU',
    listFatal: [
      {
        title: 'NHÓM 1',
        content: [
          '36. Ung thư đe dọa tính mạng',
          '37. Suy thận giai đoạn cuối',
          '38. Phẫu thuật ghép tạng chủ',
          '39. Phẫu thuật ghép tủy xương',
          '40. Bệnh phổi giai đoạn cuối',
          '41. Bệnh gan nghiêm trọng',
          '42. Viêm gan vi-rút tối cấp',
          '43. Bệnh thiếu máu bất sản',
          '44. Viêm tụy mạn tính tái phát',
          '45. Viêm thận do bệnh Lupus ban đỏ hệ thống',
          '46. U tủy tuyến thượng thận',
          '47. Bệnh nang tủy thận',
          '48. Viêm gan tự miễn mạn tính',
        ],
      },
      {
        title: 'NHÓM 2',
        content: [
          '49. Nhồi máu cơ tim',
          '50. Bệnh động mạch vành cần phẫu thuật',
          '51. Phẫu thuật van tim',
          '52. Phẫu thuật động mạch chủ',
          '53. Tăng áp lực động mạch phổi nguyên phát',
          '54. Bệnh cơ tim',
          '55. Các bệnh động mạch vành khác',
          '56. Viêm nội tâm mạc nhiễm trùng',
        ],
      },
      {
        title: 'NHÓM 3',
        content: [
          '57. Đột quỵ',
          '58. Hôn mê 96 giờ',
          '59. U não lành tính',
          '60. Chấn thương đầu nghiêm trọng',
          '61. Liệt',
          '62. Viêm não',
          '63. Viêm màng não vi khuẩn',
          '64. Phẫu thuật sọ não',
          '65. Bệnh tế bào thần kinh vận động',
          '66. Bệnh xơ cứng rải rác',
          '67. Loạn dưỡng cơ',
          '68. Bệnh Creutzfeldt- Jakob',
          '69. Bệnh Parkinson',
          '70. Xơ cứng cột bên teo cơ',
          '71. Bệnh Alzheimer/ Sa sút trí tuệ nghiêm trọng',
          '72. Bại liệt',
          '73. Bệnh nhược cơ',
          '74. Bệnh loãng xương nghiêm trọng',
          '75. Liệt tứ chi do chấn thương tủy sống',
          '76. Gãy cột sống hoàn toàn do tai nạn',
          '77. Phẫu thuật phình động mạch não',
          '78. Hội chứng Apallic',
          '79. Liệt trên nhân tiến triển',
          '80. Cắt cụt các chi',
        ],
      },
      {
        title: 'NHÓM 4',
        content: [
          '81. Điếc',
          '82. Mù',
          '83. Câm',
          '84. Bỏng nặng',
          '85. Xơ cứng bì tiến triển',
          '86. Nhiễm HIV do truyền máu',
          '87. Nhiễm HIV do nghề nghiệp',
          '88. Viêm đa khớp dạng thấp nặng',
          '89. Bệnh chân voi',
          '90. Viêm hoại tử nghiêm trọng',
          '91. Suy tuyến thượng thận mạn tính',
          '92. Phẫu thuật cắt bỏ đại tràng do viêm loét',
          '93. Bệnh Crohn',
          '94. Phẫu thuật điều trị vẹo cột sống',
          '95. Viêm khớp dạng thấp thiếu niên nghiêm trọng (bệnh still)',
          '96. Bệnh Kawasaki',
          '97. Sốt thấp khớp có tổn thương van tim',
          '98. Bệnh máu khó đông nghiêm trọng',
          '99. Bệnh xương thủy tinh',
        ],
      },
    ],
  },
]

export const treatmentBenefits = [
  {
    name: 'D: GÓI NƯỚC NGOÀI',
    backgroundColor: '#DFF3E7',
    smallTitle: 'Chương trình chăm sóc cơ bản',
    listFatal: [
      {
        title: 'NHÓM 1',
        content: [
          '<span style="font-weight: bold;color: #373737;">Phạm vi địa lý</span>',
          '2. Ung thư giai đoạn đầu của các cơ quan chuyên biệt',
          '3. Phẫu thuật lấy một bên thận',
          '4. Ghép ruột non ',
          '5. Ghép giác mạc',
          '6. Phẫu thuật lấy một bên phổi',
          '7. Đặt màng lọc tĩnh mạch chủ',
          '8. Phẫu thuật gan',
          '9. Phẫu thuật tái tạo đường mật',
          '10. Hen phế quản nặng',
          '11. Thiếu máu bất sản hoặc Suy tủy xương có khả năng hồi phục',
          '12. Hội chứng xơ hóa tủy xương/ Xơ tủy nguyên phát',
          '13. Viêm tụy hoại tử xuất huyết cấp tính',
        ],
      },
      {
        title: 'NHÓM 2',
        content: [
          '14. Đặt máy tạo nhịp tim',
          '15. Đặt máy khử rung tim',
          '16. Bắc cầu trực tiếp động mạch vành có xâm lấn tối thiểu',
          '17. Phẫu thuật xâm lấn tối thiểu động mạch chủ',
          '18. Phương pháp tạo hình hoặc rạch van tim',
          '19. Phẫu thuật tạo hình mạch',
          '20. Phẫu thuật cắt bỏ màng ngoài tim',
          '21. Tăng áp lực động mạch phổi nguyên phát giai đoạn sớm',
          '22. Phình động mạch chủ',
        ],
      },
      {
        title: ' NHÓM 3',
        content: [
          '23. Phẫu thuật động mạch cảnh',
          '24. Đặt ống thông não bộ (shunt)',
          '25. Hôn mê 48 giờ',
          '26. Phẫu thuật cắt bỏ u tuyến yên bằng đường qua xoang bướm hoặc đường Mũi',
          '27. Mất một chi',
          '28. Loãng xương có gãy xương',
          '29. Bệnh động kinh nghiêm trọng',
          '30. Bệnh Parkinson giai đoạn sớm',
          '31. Viêm tủy do lao',
          '32. Phẫu thuật huyết khối xoang hang',
        ],
      },
      {
        title: 'NHÓM 4',
        content: ['33. Phẫu thuật cấy ghép ốc tai', '34. Mất thị lực một mắt', '35. Bỏng sâu diện tích nhỏ'],
      },
    ],
  },

  {
    name: 'BỆNH LÝ NGHIÊM TRỌNG GIAI ĐOẠN SAU',
    listFatal: [
      {
        title: 'NHÓM 1',
        content: [
          '36. Ung thư đe dọa tính mạng',
          '37. Suy thận giai đoạn cuối',
          '38. Phẫu thuật ghép tạng chủ',
          '39. Phẫu thuật ghép tủy xương',
          '40. Bệnh phổi giai đoạn cuối',
          '41. Bệnh gan nghiêm trọng',
          '42. Viêm gan vi-rút tối cấp',
          '43. Bệnh thiếu máu bất sản',
          '44. Viêm tụy mạn tính tái phát',
          '45. Viêm thận do bệnh Lupus ban đỏ hệ thống',
          '46. U tủy tuyến thượng thận',
          '47. Bệnh nang tủy thận',
          '48. Viêm gan tự miễn mạn tính',
        ],
      },
      {
        title: 'NHÓM 2',
        content: [
          '49. Nhồi máu cơ tim',
          '50. Bệnh động mạch vành cần phẫu thuật',
          '51. Phẫu thuật van tim',
          '52. Phẫu thuật động mạch chủ',
          '53. Tăng áp lực động mạch phổi nguyên phát',
          '54. Bệnh cơ tim',
          '55. Các bệnh động mạch vành khác',
          '56. Viêm nội tâm mạc nhiễm trùng',
        ],
      },
      {
        title: 'NHÓM 3',
        content: [
          '57. Đột quỵ',
          '58. Hôn mê 96 giờ',
          '59. U não lành tính',
          '60. Chấn thương đầu nghiêm trọng',
          '61. Liệt',
          '62. Viêm não',
          '63. Viêm màng não vi khuẩn',
          '64. Phẫu thuật sọ não',
          '65. Bệnh tế bào thần kinh vận động',
          '66. Bệnh xơ cứng rải rác',
          '67. Loạn dưỡng cơ',
          '68. Bệnh Creutzfeldt- Jakob',
          '69. Bệnh Parkinson',
          '70. Xơ cứng cột bên teo cơ',
          '71. Bệnh Alzheimer/ Sa sút trí tuệ nghiêm trọng',
          '72. Bại liệt',
          '73. Bệnh nhược cơ',
          '74. Bệnh loãng xương nghiêm trọng',
          '75. Liệt tứ chi do chấn thương tủy sống',
          '76. Gãy cột sống hoàn toàn do tai nạn',
          '77. Phẫu thuật phình động mạch não',
          '78. Hội chứng Apallic',
          '79. Liệt trên nhân tiến triển',
          '80. Cắt cụt các chi',
        ],
      },
      {
        title: 'NHÓM 4',
        content: [
          '81. Điếc',
          '82. Mù',
          '83. Câm',
          '84. Bỏng nặng',
          '85. Xơ cứng bì tiến triển',
          '86. Nhiễm HIV do truyền máu',
          '87. Nhiễm HIV do nghề nghiệp',
          '88. Viêm đa khớp dạng thấp nặng',
          '89. Bệnh chân voi',
          '90. Viêm hoại tử nghiêm trọng',
          '91. Suy tuyến thượng thận mạn tính',
          '92. Phẫu thuật cắt bỏ đại tràng do viêm loét',
          '93. Bệnh Crohn',
          '94. Phẫu thuật điều trị vẹo cột sống',
          '95. Viêm khớp dạng thấp thiếu niên nghiêm trọng (bệnh still)',
          '96. Bệnh Kawasaki',
          '97. Sốt thấp khớp có tổn thương van tim',
          '98. Bệnh máu khó đông nghiêm trọng',
          '99. Bệnh xương thủy tinh',
        ],
      },
    ],
  },
]

export const tableFeeDataSourceULP = [
  {
    contractYear: 'Phí BH cơ bản cho sản phẩm BH chính',
    contractYear1: '85%',
    contractYear2: '75%',
    contractYear3: '10%',
    contractYear4: '5%',
    contractYear5: '0%',
  },
  {
    contractYear: 'Phí BH cơ bản cho sản phẩm BH bổ trợ Nhóm 1',
    contractYear1: '70%',
    contractYear2: '65%',
    contractYear3: '10%',
    contractYear4: '5%',
    contractYear5: '0%',
  },
  {
    contractYear: 'Phí bảo hiểm tích lũy thêm',
    contractYear1: '2%',
    contractYear2: '2%',
    contractYear3: '2%',
    contractYear4: '2%',
    contractYear5: '2%',
  },
]

export const tableInterestRateColumns = [
  {
    title: 'Năm hợp đồng',
    dataIndex: 'contractYear',
  },
  {
    title: 'Năm 1',
    dataIndex: 'contractYear1',
  },
  {
    title: 'Năm 2-5',
    dataIndex: 'contractYear2',
  },
  {
    title: 'Năm 6-10',
    dataIndex: 'contractYear3',
  },
  {
    title: 'Năm 11-15',
    dataIndex: 'contractYear4',
  },
  {
    title: 'Năm 16+',
    dataIndex: 'contractYear5',
  },
]

export const tableInterestRateDataSource = [
  {
    contractYear: 'Lãi suất cam kết',
    contractYear1: '4%',
    contractYear2: '3%',
    contractYear3: '2.5%',
    contractYear4: '2%',
    contractYear5: '1%',
  },
]

export const tableFundManageFeeColumns = [
  {
    title: 'Quỹ PruLink',
    dataIndex: 'pruLinkFund',
  },
  {
    title: 'Cổ phiếu VN',
    dataIndex: 'vnStock',
  },
  {
    title: 'Tăng trưởng',
    dataIndex: 'grow',
  },
  {
    title: 'Cân bằng',
    dataIndex: 'balance',
  },
  {
    title: 'Bền vững',
    dataIndex: 'enduring',
  },
  {
    title: 'Trái phiếu VN',
    dataIndex: 'vnBond',
  },
  {
    title: 'Bảo toàn',
    dataIndex: 'reserve',
  },
]

export const tableFundManageFeeDataSource = [
  {
    pruLinkFund: 'Phí quản lý quỹ',
    vnStock: '2.50%',
    grow: '2.00%',
    balance: '1.75%',
    enduring: '1.50%',
    vnBond: '1.00%',
    reserve: '1.00%',
  },
]

export const tableBeneficiary = [
  {
    groupTitle: '<span style="font-weight: bold;color: #FC573B;font-size:15px;">QUYỀN LỢI ĐIỀU TRỊ NỘI TRÚ</span>',
    titleBenefit: 'QUYỀN LỢI ĐIỀU TRỊ NỘI TRÚ',
    helpText: `<span>(Thời gian chờ 30 ngày (*))</span>`,
    type: 'Nội trú',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Phạm vi địa lý</span>',
        contentA: '<span>Việt Nam</span>',
        contentB: '<span>Việt Nam</span>',
        contentC: '<span>Việt Nam</span>',
        contentD: '<span>Đông Nam Á <span style="color: red;">(**)</span></span>',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Giới hạn tối đa hàng năm</span>',
        contentA: '<span>100.000.000/năm</span>',
        contentB: '<span>200.000.000/năm</span>',
        contentC: '<span>400.000.000/năm</span>',
        contentD: '<span>1.000.000.000/năm</span>',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Quyền lợi gia tăng</span>',
        contentA: '<span>Không áp dụng</span>',
        contentB: '<span>200.000.000/năm</span>',
        contentC: '<span>400.000.000/năm</span>',
        contentD: '<span>1.000.000.000/năm</span>',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Điều trị ung thư</span></br><span style="color: #373737;">Chi phí Điều Trị Nội Trú; Điều Trị Ngoại Trú (áp dụng cho xạ trị, hóa trị, liệu pháp trúng đích) Thời gian chờ 90 ngày</span>',
        contentA: '<span>Theo chi phí thực tế</span>',
        contentB: '<span>Theo chi phí thực tế</span>',
        contentC: '<span>Theo chi phí thực tế</span>',
        contentD: '<span>Theo chi phí thực tế</span>',
      },
    ],
  },
  {
    groupTitle: '<span style="font-weight: bold;font-size:15px;">CÁC GIỚI HẠN PHỤ</span>',
    titleBenefit: 'CÁC GIỚI HẠN PHỤ',
    type: 'Nội trú',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Giới hạn phụ</span>',
        contentA: '<span>50.000.000/Đợt nằm viện</span>',
        contentB: '<span>100.000.000/Đợt nằm viện</span>',
        contentC: '<span>200.000.000/Đợt nằm viện</span>',
        contentD: '<span>250.000.000/Đợt nằm viện</span>',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí Giường và Phòng Tối đa 80 ngày/năm</span>',
        contentA: '<span>600.000/ngày</span>',
        contentB: '<span>1.250.000/ngày</span>',
        contentC: '<span>2.000.000/ngày</span>',
        contentD: '<span>6.000.000/ngày</span>',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Phụ cấp nằm viện tại Bệnh viện công</span></br><span style="color: #373737;"><span style="color: red;">(*)</span> bao gồm trong Giới hạn phụ đối với chi phí Giường và Phòng ở trên. Áp dụng đối với việc Điều trị Nội trú tại Việt Nam từ 3 ngày trở lên, tối đa 30 ngày/năm</span>',
        contentA: '<span>100.000/ngày<span style="color: red;">(*)</span></span>',
        contentB: '<span>250.000/ngày<span style="color: red;">(*)</span></span>',
        contentC: '<span>500.000/ngày<span style="color: red;">(*)</span></span>',
        contentD: '<span>1.000.000/ngày<span style="color: red;">(*)</span></span>',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí Phòng chăm sóc đặc biệt (ICU) Tối đa 30 ngày/năm</span>',
        contentA: '1.000.000/ngày',
        contentB: '2.000.000/ngày',
        contentC: '4.000.000/ngày',
        contentD: '12.000.000/ngày',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí giường dành cho thân nhân Tối đa 30 ngày/năm</span>',
        contentA: '300.000/ngày',
        contentB: '625.000/ngày',
        contentC: '1.000.000/ngày',
        contentD: '2.500.000/ngày',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí Điều trị trước khi nhập viện Trong vòng 30 ngày trước khi nhập viện</span>',
        contentA: 'Theo chi phí thực tế',
        contentB: 'Theo chi phí thực tế',
        contentC: 'Theo chi phí thực tế',
        contentD: 'Theo chi phí thực tế',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí y tế nội trú khác</span>',
        contentA: 'Theo chi phí thực tế',
        contentB: 'Theo chi phí thực tế',
        contentC: 'Theo chi phí thực tế',
        contentD: 'Theo chi phí thực tế',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí phẫu thuật</span>',
        contentA: '12.000.000/Đợt nằm viện',
        contentB: '25.000.000/Đợt nằm viện',
        contentC: '50.000.000/Đợt nằm viện',
        contentD: '100.000.000/Đợt nằm viện',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí điều trị sau khi xuất viện</br><span style="color: #373737;">Trong vòng 60 ngày sau khi xuất viện, tối đa 5 lần/năm<span></span>',
        contentA: 'Theo chi phí thực tế',
        contentB: 'Theo chi phí thực tế',
        contentC: 'Theo chi phí thực tế',
        contentD: 'Theo chi phí thực tế',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí Dịch vụ chăm sóc y tế tại nhà Tối đa 10 ngày/năm</span>',
        contentA: '120.000/ngày',
        contentB: '250.000/ngày',
        contentC: '500.000/ngày',
        contentD: '1.000.000/ngày',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí cấy ghép nội tạng (thận, tim, gan, phổi, tủy xương, tụy) Thời gian chờ 90 ngày, 1 lần duy nhất trọn đời</span>',
        contentA: '25.000.000/lần ghép cho</br>NĐBH</br>12.000.000/lần ghép</br>cho Người hiến tạng',
        contentB: '50.000.000/lần ghép cho</br>NĐBH</br>25.000.000/lần ghép</br>cho Người hiến tạng',
        contentC: '175.000.000/lần ghép cho</br>NĐBH</br>25.000.000/lần ghép</br>cho Người hiến tạng',
        contentD: '500.000.000/lần ghép cho</br>NĐBH</br>25.000.000/lần ghép</br>cho Người hiến tạng',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí tái tạo tuyến vú sau đoạn nhũ Đối với Ung thư vú</span>',
        contentA: 'Không áp dụng',
        contentB: 'Không áp dụng',
        contentC: 'Không áp dụng',
        contentD: '30.000.000/lần duy nhất trọn đời',
      },
    ],
  },
  {
    groupTitle: '<span style="font-weight: bold;font-size:15px;">CÁC QUYỀN LỢI ĐIỀU TRỊ NGOẠI TRÚ ĐẶC BIỆT</span>',
    type: 'Nội trú',
    titleBenefit: 'CÁC QUYỀN LỢI ĐIỀU TRỊ NGOẠI TRÚ ĐẶC BIỆT',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí Phẫu thuật trong ngày</span>',
        contentA: '1.700.000/năm',
        contentB: '3.500.000/năm',
        contentC: '10.000.000/năm',
        contentD: '50.000.000/năm',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí lọc thận (chạy thận)</span>',
        contentA: 'Không áp dụng',
        contentB: 'Không áp dụng',
        contentC: '10.000.000/năm',
        contentD: '50.000.000/năm',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí cấp cứu do Tai nạn</span>',
        contentA: '1.700.000/Tai nạn',
        contentB: '3.500.000/Tai nạn',
        contentC: '4.000.000/Tai nạn',
        contentD: '15.000.000/Tai nạn',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí điều trị nha khoa cấp cứu do Tai nạn</span>',
        contentA: 'Không áp dụng',
        contentB: 'Không áp dụng',
        contentC: '4.000.000/Tai nạn',
        contentD: '15.000.000/Tai nạn',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí xe cứu thương trong nước</span>',
        contentA: '1.700.000/năm',
        contentB: '3.750.000/năm',
        contentC: '4.000.000/năm',
        contentD: '15.000.000/năm',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Kính nội nhãn đa tiêu cự</span>',
        contentA: 'Không áp dụng',
        contentB: 'Không áp dụng',
        contentC: 'Không áp dụng',
        contentD: '5.000.000/mắt, tối đa 2 mắt trọn đời',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Biến chứng thai sản Thời gian chờ 270 ngày</span>',
        contentA: 'Không áp dụng',
        contentB: '15.000.000/năm',
        contentC: '40.000.000/năm',
        contentD: '60.000.000/năm',
      },
    ],
  },
  {
    groupTitle: '<span style="font-weight: bold;font-size:15px;color: #FC573B;">QUYỀN LỢI ĐIỀU TRỊ NGOẠI TRÚ</span>',
    titleBenefit: 'QUYỀN LỢI ĐIỀU TRỊ NGOẠI TRÚ',
    helpText: '<span>(Thời gian chờ 30 ngày (*))</span>',
    type: 'Ngoại trú',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Giới hạn tối đa hàng năm</span>',
        contentA: '',
        contentB: '',
        contentC:
          '12.000.000/năm</br>Áp dụng 20% Đồng chi trả cho mỗi Lần khám tại Bệnh viện tư/Phòng khám tư Không áp dụng Đồng chi trả cho mỗi Lần khám tại Bệnh viện công/Phòng khám công',
        contentD:
          '40.000.000/năm</br>Áp dụng 20% Đồng chi trả cho mỗi Lần khám tại Bệnh viện tư/Phòng khám tư Không áp dụng Đồng chi trả cho mỗi Lần khám tại Bệnh viện công/Phòng khám công',
      },
    ],
  },
  {
    groupTitle: '<span style="font-weight: bold;font-size:15px;">CÁC GIỚI HẠN PHỤ</span>',
    type: 'Ngoại trú',
    titleBenefit: 'CÁC GIỚI HẠN PHỤ',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Giới hạn/Lần khám</span>',
        contentA: '',
        contentB: '',
        contentC: '1.500.000/Lần khám</br>Tối đa 8 Lần khám/năm',
        contentD: '5.000.000/Lần khám</br>Tối đa 8 Lần khám/năm',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí khám của Bác sỹ</span>',
        contentA: '',
        contentB: '',
        contentC: 'Theo chi phí thực tế',
        contentD: 'Theo chi phí thực tế',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí Thuốc điều trị</span>',
        contentA: '',
        contentB: '',
        contentC: '625.000/Lần khám',
        contentD: '1.000.000/Lần khám',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí X-quang và các xét nghiệm trong phòng thí nghiệm</span>',
        contentA: '',
        contentB: '',
        contentC: '1.000.000/Lần khám',
        contentD: '2.000.000/Lần khám',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí xét ngiệm khác chỉ định bởi Bác sỹ để chẩn đoán hoặc điều trị</span>',
        contentA: '',
        contentB: '',
        contentC: 'Theo chi phí thực tế',
        contentD: 'Theo chi phí thực tế',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Vật lý trị liệu</span>',
        contentA: '',
        contentB: '',
        contentC: '1.000.000/năm',
        contentD: '2.000.000/năm',
      },
    ],
  },
  {
    groupTitle: '<span style="font-weight: bold;font-size:15px;color: #FC573B;">QUYỀN LỢI CHĂM SÓC NHA KHOA</span>',
    type: 'Nha khoa',
    titleBenefit: 'QUYỀN LỢI CHĂM SÓC NHA KHOA',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Giới hạn bảo hiểm tối đa hàng năm</span>',
        contentA: '',
        contentB: '',
        contentC:
          '5.000.000/năm</br>Áp dụng 20% Đồng chi trả cho mỗi Lần khám tại Bệnh viện tư/Phòng khám tư Không áp dụng Đồng chi trả cho mỗi Lần khám tại Bệnh viện công/Phòng khám công',
        contentD:
          '20.000.000/năm</br>Áp dụng 20% Đồng chi trả cho mỗi Lần khám tại Bệnh viện tư/Phòng khám tư Không áp dụng Đồng chi trả cho mỗi Lần khám tại Bệnh viện công/Phòng khám công',
      },
    ],
  },
  {
    groupTitle: '<span style="font-weight: bold;font-size:15px;">CÁC GIỚI HẠN PHỤ</span>',
    titleBenefit: 'CÁC GIỚI HẠN PHỤ',
    type: 'Nha khoa',
    content: [
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Quyền lợi giai đoạn 1:&nbsp;</span><span style="color: #373737;">Chi phí y tế dự phòng</span></br>
          <span style="font-weight: bold;color: #373737;">Thời gian chờ 30 ngày, tối đa 2 lần một năm</span>
          <ul>
            <li>Khám và xét nghiệm kiểm tra</li>
            <li>X-quang</li>
            <li>Nhổ răng bệnh lý (không cần phẫu thuật)</li>
          </ul>
        `,
        contentA: '',
        contentB: '',
        contentC: '600.000/Lần khám',
        contentD: '2.000.000/Lần khám',
      },
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Quyền lợi giai đoạn 2:&nbsp;</span><span style="color: #373737;">Điều trị bảo tồn</span></br>
          <span style="font-weight: bold;color: #373737;">Thời gian chờ 180 ngày</span>
          <ul>
            <li>Điều trị nha chu, viêm nướu</li>
            <li>Trám răng (amalgam, resin, composite hoặc các chấtliệu tương đương), tối đa 2 răng/ năm</li>
            <li>Phẫu thuật nhổ răng bệnh lý, tối đa 2 răng/ năm</li>
          </ul>
        `,
        contentA: '',
        contentB: '',
        contentC: 'Theo chi phí thực tế',
        contentD: 'Theo chi phí thực tế',
      },
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Quyền lợi giai đoạn 3:&nbsp;</span><span style="color: #373737;">Điều trị phục hình răng</span></br>
          <span style="font-weight: bold;color: #373737;">Thời gian chờ 365 ngày</span>
          <ul>
            <li>Chữa tủy răng, tối đa 2 tủy răng/năm</li>
            <li>Bọc răng hoặc cắm implant, tối đa 2 răng/năm</li>
          </ul>
        `,
        contentA: '',
        contentB: '',
        contentC: 'Không áp dụng',
        contentD: 'Theo chi phí thực tế',
      },
    ],
  },
  {
    groupTitle: '<div style="display:flex;justify-content:center;width:100%;"><span style="font-weight: bold;font-size:15px;color: #FC573B;">QUYỀN LỢI CHĂM SÓC THAI SẢN</span></div></br>',
    titleBenefit: `QUYỀN LỢI CHĂM SÓC THAI SẢN`,
    helpText: `<span>QUYỀN LỢI CHĂM SÓC THAI SẢN Chỉ được áp dụng đối với NĐBH là nữ trong độ tuổi từ 18 đến 49 tuổi với điều kiện tuổi của NĐBH khi tham gia Chương trình chăm sóc cơ bản (có Quyền lợi Bổ sung tùy chọn) không quá 45 tuổi (1 kỳ thai sản cho mỗi Năm hiệu lực, Thời gian chờ 270 ngày)</span>`,
    type: 'Thai sản',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Giới hạn bảo hiểm tối đa hàng năm</span>',
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '60.000.000/năm',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Chi phí sinh thường</span>',
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '30.000.000/năm',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Chi phí sinh mổ hoặc sinh có hỗ trợ (sử dụng giác hút hoặc kẹp forceps)</span>',
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '60.000.000/năm',
      },
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Chi phí Giường và Phòng</span></br>
          <span style="font-size: 14px;color: #373737;margin-top: 8px;">Tối đa 30 ngày/năm</span>
        `,
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '3.000.000/ngày',
      },
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Chi phí phòng chăm sóc đặc biệt (ICU)</span></br>
          <span style="font-size: 14px;color: #373737;margin-top: 8px;">Tối đa 30 ngày/năm</span>
        `,
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '6.000.000/ngày',
      },
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Chi phí khám trước và sau sinh</span></br>
          <span style="font-size: 14px;color: #373737;margin-top: 8px;">Tối đa 15 ngày/năm</span>
        `,
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '3.000.000/ngày',
      },
      {
        title: `
          <span style="font-weight: bold;color: #373737;">Chi phí dưỡng nhi</span></br>
          <span style="font-size: 14px;color: #373737;margin-top: 8px;">Trong vòng 7 ngày kể từ ngày sinh, tối đa 15 ngày/năm</span>
        `,
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '3.000.000/ngày',
      },
    ],
  },
]

export const beneficiaryNotes = `
  <p>
    <span><span style="color: red;">(*)</span> Không xét Thời gian chờ cho Tai nạn.</span></br>
    <span>Thời gian chờ của Bệnh đặc biệt là 90 ngày. Tham khảo danh sách Bệnh đặc biệt ở PHỤ LỤC của Quy tắc, Điều khoản Sản phẩm bảo hiểm bổ trợ PRU-HÀNH TRANG VUI KHỎE.</span>
  </p>
  <p>
    <span><span style="color: red;">(**)</span> Đông Nam Á bao gồm các nước Việt Nam, Laos, Cambodia, Thailand, Myanmar, Indonesia, Malaysia, Singapore, Brunei và Philippines.</span>
  </p>
`


export const beneficiaryNotesTerm = `
<p>
<span>Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo Quy tắc, Điều khoản Sản phẩm bổ trợ Pru-Hành Trang Vui Khỏe.</span>
</p>
`

export const beneficiaryNotesOthers = `
  <p>
    <span><span style="color: red;">(*)</span> Không xét Thời gian chờ cho Tai nạn.</span></br>
    <span>Thời gian chờ của Bệnh đặc biệt là 90 ngày. Tham khảo danh sách Bệnh đặc biệt ở PHỤ LỤC của Quy tắc, Điều khoản Sản phẩm bảo hiểm bổ trợ PRU-HÀNH TRANG VUI KHỎE.</span>
  </p>
`

export const tableProfit = [
  {
    groupTitle: '<span style="font-weight: bold;color: #FC573B;font-size:15px;">QUYỀN LỢI ĐIỀU TRỊ NỘI TRÚ</span>',
    titleBenefit: 'QUYỀN LỢI ĐIỀU TRỊ NỘI TRÚ',
    helpText: `<span style="font-weight: bold;color: #373737;">(Thời gian chờ 30 ngày (*))</span>`,
    type: 'Nội trú',
    content: [
      {
        title: '<span style="font-weight: bold;color: #373737;">Cổ phiếu VN</span>',
        contentA: '<span>20.5%</span>',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Tăng trưởng</span>',
        contentA: '<span>17.5%</span>',
      },
      {
        title: '<span style="font-weight: bold;color: #373737;">Cân bằng</span>',
        contentA: '<span>14.9%</span>',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Bền vững</span>',
        contentA: '<span>12.2%</span>',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Trái phiếu VN</span>',
        contentA: '<span>8.1%</span>',
      },
      {
        title:
          '<span style="font-weight: bold;color: #373737;">Bảo toàn</span>',
        contentA: '<span>4.8%</span>',
      },
    ],
  },
]

export const contractManageFee = 40000

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'K',
  'L',
  'M',
  'L',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'X',
  'Y',
  'Z',
]

export const dataSourceHealthCarePlan: Array<HealthCarePlan> = [
  {
    key: 'A',
    answer: 'Chương trình chăm sóc cơ bản',
    label: 'A. Chương trình chăm sóc cơ bản',
    boarding: 100,
    outPatient: 0,
    dentistry: 0,
  },
  {
    key: 'B',
    answer: 'Chương trình chăm sóc nâng cao',
    label: 'B. Chương trình chăm sóc nâng cao',
    boarding: 200,
    outPatient: 0,
    dentistry: 0,
  },
  {
    key: 'C',
    answer: 'Chương trình chăm sóc toàn diện',
    label: 'C. Chương trình chăm sóc toàn diện',
    boarding: 400,
    outPatient: 12,
    dentistry: 5,
  },
  {
    key: 'D',
    answer: 'Chương trình chăm sóc hoàn hảo',
    label: 'D. Chương trình chăm sóc hoàn hảo',
    boarding: 1000,
    outPatient: 40,
    dentistry: 20,
  },
]

export const dataSourceHealthCarePlanMaternity: Array<HealthCarePlan> = [
  {
    key: 'A',
    answer: 'Chương trình chăm sóc cơ bản',
    label: 'A. Chương trình chăm sóc cơ bản',
    boarding: 100,
    outPatient: 0,
    dentistry: 0,
    maternity: 0,
  },
  {
    key: 'B',
    answer: 'Chương trình chăm sóc nâng cao',
    label: 'B. Chương trình chăm sóc nâng cao',
    boarding: 200,
    outPatient: 0,
    dentistry: 0,
    maternity: 0,
  },
  {
    key: 'C',
    answer: 'Chương trình chăm sóc toàn diện',
    label: 'C. Chương trình chăm sóc toàn diện',
    boarding: 400,
    outPatient: 12,
    dentistry: 5,
    maternity: 0,
  },
  {
    key: 'D',
    answer: 'Chương trình chăm sóc hoàn hảo',
    label: 'D. Chương trình chăm sóc hoàn hảo',
    boarding: 1000,
    outPatient: 40,
    dentistry: 20,
    maternity: 60,
  },
]

export const agentData = {
  'VIB': {
    icon: imgVIB,
  },
  'UNKNOWN': {
    icon: undefined,
  }
}
