import {
  AppBar,
  BadgeComponent,
  CircleProgressIndicator,
  Dialog,
  vnNumberFormatter,
  SizedBox,
  styles as globalStyles,
} from '../common'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../store'
import { RootStackScreenProps } from '../types'
import imgArrowBackWhite from '../assets/images/back.png'
import imgSendWhite from '../assets/images/send.png'
import imgSendDisabled from '../assets/images/send-disabled.png'
import imgEyeWhite from '../assets/images/eye.png'
import imgEyeDisabled from '../assets/images/eye-disabled.png'
import imgDeleteWhite from '../assets/images/delete.png'
import { ImageSourcePropType, View, StyleSheet, TextInput as ReactTextInput, ScrollView } from 'react-native'
import { Image, Text, TouchableOpacity } from 'react-native'
import i18n from 'i18n-js'
import { useIsFocused } from '@react-navigation/native'
import { deleteCNA, getCachedCNA, saveCNA } from '../slices/cnaSlice'
import { clearCart } from '../slices/needsCartSlice'
import moment from 'moment'
import { CnaStatus, ViewMode } from '../constants/Enum'
import { pageNumberRouteNameMapping } from '../constants/Data'
import imgFirecracker from '../assets/images/firecracker.png'
import imgChain from '../assets/images/chain.png'
import { sendMailPdf } from '../slices/saveFormSlice'
import Layout from '../constants/Layout'
import { StatusBar } from 'expo-status-bar'
import { createNewLocalCNA } from '../slices/cnaSlice'
import Rating from '../common/components/Rating'
import Colors from '../common/constants/Colors'
import pruquoteBackIcon from '../assets/images/pruquote-back-white.png'
import { handleSSOPruquote } from 'helper/SsoPruquoteHelper'

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  loadingDeleteCNA: state?.cna.loadingDeleteCna,
})

const mapDispatchToProps = {
  getCachedCNA,
  clearCart,
  deleteCNA,
  createNewLocalCNA,
  sendMailPdf,
  saveCNA,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'MobileDetailCna'> & PropsFromRedux & {}

function MobileDetailCna({
  route,
  navigation,
  currentHandlingCNA,
  loadingDeleteCNA,
  createNewLocalCNA,
  getCachedCNA,
  deleteCNA,
  sendMailPdf,
  saveCNA,
}: Props) {
  const isFocused = useIsFocused()
  const isMobileDevice = Layout.isMobileDevice

  const [showWarningDeletePlan, setShowWarningDeletePlan] = React.useState(false)
  const [showConfirmShareDialog, setShowConfirmShareDialog] = React.useState(false)
  const [showSharePDFSuccessDialog, setShowSharePDFSuccessDialog] = React.useState(false)
  const [showSharePDFFailDialog, setShowSharePDFFailDialog] = React.useState(false)
  const [showLoadingSendEmailDialog, setShowLoadingSendEmailDialog] = React.useState(false)
  const [notes, setNotes] = React.useState<string | undefined>()

  React.useEffect(() => {
    const id = (route.params as any)?.id
    if (id && isFocused) {
      getCachedCNA({ id })
    }
  }, [isFocused])

  React.useEffect(() => {
    setNotes(currentHandlingCNA?.notes)
  }, [currentHandlingCNA])

  const switchStatus = (status?: CnaStatus) => {
    switch (status) {
      case CnaStatus.DRAFT:
      case CnaStatus.DRAFT_INDEX:
        return 'warning'
      case CnaStatus.COMPLETED:
      case CnaStatus.COMPLETED_INDEX:
        return 'success'
      case CnaStatus.NEED_CONTACT_INFO:
      case CnaStatus.NEED_CONTACT_INFO_INDEX:
        return 'error'
      default:
        return 'primary'
    }
  }

  function onDeleteCna(id?: string) {
    deleteCNA({ id })
      .unwrap()
      .then(async () => {
        setShowWarningDeletePlan(false)
        if (isMobileDevice) {
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            navigation.navigate('MobileHome')
          }
        } else {
          navigation.replace('CNAList')
        }
      })
  }

  function onChangedRateValue(ratingValue?: number) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      potenialRating: ratingValue,
    } as any
    saveCNA(body)
  }

  function onUpdateNotes(notes?: string) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      notes: notes,
    } as any
    saveCNA(body)
  }

  function setLastStep(id: any) {
    navigation.navigate(
      currentHandlingCNA?.lastEditStep ? pageNumberRouteNameMapping[currentHandlingCNA.lastEditStep] : 'NeedsCart',
      {
        ...(route.params as any),
        id: id,
      }
    )
  }

  function onSendMailPdf() {
    setShowConfirmShareDialog(false)
    setShowLoadingSendEmailDialog(true)
    sendMailPdf({ id: currentHandlingCNA?._id }).then((data: any) => {
      setShowLoadingSendEmailDialog(false)
      if (data.error) {
        setShowSharePDFFailDialog(true)
      } else {
        setShowSharePDFSuccessDialog(true)
      }
    })
  }

  function checkDisablePreviewStatus() {
    return (
      `${currentHandlingCNA?.status}` !== CnaStatus.COMPLETED &&
      `${currentHandlingCNA?.status}` !== CnaStatus.COMPLETED_INDEX &&
      `${currentHandlingCNA?.status}` !== CnaStatus.NEED_CONTACT_INFO &&
      `${currentHandlingCNA?.status}` !== CnaStatus.NEED_CONTACT_INFO_INDEX
    )
  }

  function onReviewCna() {
    navigation.navigate('Solution', { ...(route.params as any), id: currentHandlingCNA?._id, mode: ViewMode.READ_ONLY })
  }

  function renderModals() {
    return (
      <>
        <Dialog
          visible={showWarningDeletePlan}
          type="delete"
          title={i18n.t('CNA_LIST.DELETE_PLAN')}
          dataDelete={{
            title: i18n.t('CNA_LIST.CONFIRM_DELETE'),
            description: i18n.t('CNA_LIST.DELETE_WARNING'),
          }}
          loadingDeleteCna={loadingDeleteCNA}
          onOk={() => {
            onDeleteCna(currentHandlingCNA?._id)
          }}
          onRemove={() => setShowWarningDeletePlan(false)}
        ></Dialog>
        {/* Confirm share pdf dialog */}
        <Dialog
          type="delete"
          visible={showConfirmShareDialog}
          showCloseIcon
          dataDelete={{
            title: i18n.t('SAVE_FORM.SHARE_PDF'),
            description: i18n.t('SAVE_FORM.CONFIRM_SHARE_PDF'),
          }}
          okText={i18n.t('COMMON.YES')}
          cancelText={i18n.t('COMMON.NO')}
          onRemove={() => setShowConfirmShareDialog(false)}
          onOk={onSendMailPdf}
          onCancel={() => setShowConfirmShareDialog(false)}
        />
        {/* Loading send mail pdf */}
        <Dialog
          type="default"
          visible={showLoadingSendEmailDialog}
          showCloseIcon={false}
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: 300 }}
        >
          <View style={[globalStyles.Background, globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Text style={[globalStyles.TextCommon]}>{`${i18n.t('SAVE_FORM.SENDING_PDF_TO_EMAIL')} ${
              currentHandlingCNA?.customerEmail
            }`}</Text>
            <SizedBox height={15} />
            <CircleProgressIndicator />
          </View>
        </Dialog>
        {/* Share pdf successfully dialog */}
        <Dialog
          type="default"
          visible={showSharePDFSuccessDialog}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: 300 }}
          onRemove={() => {
            setShowSharePDFSuccessDialog(false)
          }}
        >
          <View style={[globalStyles.Background, globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Image source={imgFirecracker} style={{ width: 72, height: 72 }} />
            <SizedBox height={15} />
            <Text style={[globalStyles.TextCommon]}>{i18n.t('SAVE_FORM.SHARE_SUCCESSFUL')}</Text>
          </View>
        </Dialog>
        {/* Share pdf fail dialog */}
        <Dialog
          type="default"
          visible={showSharePDFFailDialog}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: 300 }}
          onRemove={() => {
            setShowSharePDFFailDialog(false)
          }}
        >
          <View style={[globalStyles.Background, globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Image source={imgChain} style={{ width: 72, height: 72 }} />
            <SizedBox height={15} />
            <Text style={[globalStyles.TextCommon, { textAlign: 'center' }]}>{i18n.t('SAVE_FORM.SHARE_FAIL')}</Text>
          </View>
        </Dialog>
      </>
    )
  }

  return (
    <SafeAreaView
      style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
      edges={['right', 'left', 'top']}
    >
      <StatusBar style="light" />
      <View style={globalStyles.mobileContent}>
        <AppBar
          leading={
            <TouchableOpacity
              onPress={() => {
                createNewLocalCNA({})
                if (navigation.canGoBack()) {
                  navigation.goBack()
                } else {
                  navigation.navigate('MobileHome')
                }
              }}
            >
              <Image source={imgArrowBackWhite} style={globalStyles.Icon24} />
            </TouchableOpacity>
          }
          title={<Text></Text>}
          trailing={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
              <TouchableOpacity
                disabled={checkDisablePreviewStatus()}
                style={{ paddingHorizontal: 8 }}
                onPress={() => currentHandlingCNA?._id && handleSSOPruquote?.(currentHandlingCNA?._id)}
              >
                <Image source={pruquoteBackIcon} style={globalStyles.Icon20} />
              </TouchableOpacity>
              {
                <TouchableOpacity
                  onPress={() => onReviewCna()}
                  style={{ paddingHorizontal: 8 }}
                  disabled={checkDisablePreviewStatus()}
                >
                  <Image
                    source={(checkDisablePreviewStatus() ? imgEyeDisabled : imgEyeWhite) as ImageSourcePropType}
                    style={globalStyles.Icon20}
                  />
                </TouchableOpacity>
              }
              {currentHandlingCNA?.customerEmail ? (
                <TouchableOpacity
                  style={{ paddingHorizontal: 16 }}
                  onPress={() => setShowConfirmShareDialog(true)}
                  disabled={!currentHandlingCNA?.customerEmail}
                >
                  <Image
                    source={currentHandlingCNA?.customerEmail ? imgSendWhite : imgSendDisabled}
                    style={globalStyles.Icon18}
                  />
                </TouchableOpacity>
              ) : (
                <></>
              )}
              <TouchableOpacity style={{ paddingHorizontal: 8 }} onPress={() => setShowWarningDeletePlan(true)}>
                <Image source={imgDeleteWhite} style={globalStyles.Icon20} />
              </TouchableOpacity>
            </View>
          }
          containerStyle={globalStyles.AppBarContainer}
        />
        {currentHandlingCNA ? (
          <ScrollView style={[styles.defaultContent]}>
            <View style={styles.boxCard}>
              <View style={styles.item}>
                <Text>{i18n.t('SAVE_FORM.REMINISCENT_NAME')}</Text>
                <Text style={[styles.title, styles.idHightLight]}>{currentHandlingCNA?.reminiscentName ?? '-'}</Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('SAVE_FORM.FULL_NAME')}</Text>
                <Text style={styles.title}>{currentHandlingCNA?.customerName ?? '-'}</Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.AGE')}</Text>
                <Text style={styles.title}>
                  {(currentHandlingCNA?.customerAge ?? 0) > 0 ? currentHandlingCNA?.customerAge : '-'}
                </Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.PHONE')}</Text>
                <Text style={styles.title}>{currentHandlingCNA?.customerPhone ?? '-'}</Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.EMAIL')}</Text>
                <Text style={styles.title}>{currentHandlingCNA?.customerEmail ?? '-'}</Text>
              </View>
              <View style={styles.line}></View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.POTENTIAL_LEVEL')}</Text>
                <Rating ratingValue={currentHandlingCNA?.potenialRating ?? 0} onChanged={onChangedRateValue} />
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.PLAN_CODE')}</Text>
                <Text style={[styles.title, styles.idHightLight]}>{currentHandlingCNA?.cnaCode ?? '-'}</Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.CREATED_DATE')}</Text>
                <Text style={styles.title}>
                  {currentHandlingCNA?.createdAt
                    ? moment(currentHandlingCNA?.createdAt).format('DD/MM/YYYY HH:mm')
                    : ''}
                </Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.NEED_TOTAL')}</Text>
                <Text style={[styles.title, styles.id]}>
                  {currentHandlingCNA?.needTotal
                    ? vnNumberFormatter.format(currentHandlingCNA?.needTotal) + ' ' + i18n.t('COMMON.MILLION')
                    : '-'}
                </Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.STATUS')}</Text>
                <Text style={styles.title}>
                  <BadgeComponent
                    value={currentHandlingCNA?.status ? i18n.t(`CNA_STATUS.${currentHandlingCNA?.status}`) : ''}
                    status={switchStatus(currentHandlingCNA?.status)}
                    transparent
                  />
                </Text>
              </View>
              <View style={styles.line}></View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.MONTHLY_INCOME')}</Text>
                <Text style={styles.title}>
                  {vnNumberFormatter.format(
                    currentHandlingCNA?.protectIncome?.monthlyIncome
                      ? currentHandlingCNA?.protectIncome?.monthlyIncome * 1000000
                      : 0
                  )}
                </Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.MONTHLY_EXPENSE')}</Text>
                <Text style={styles.title}>
                  {vnNumberFormatter.format(
                    currentHandlingCNA?.protectLife?.monthlyFixedExpense
                      ? currentHandlingCNA?.protectLife?.monthlyFixedExpense * 1000000
                      : 0
                  )}
                </Text>
              </View>
              <View style={styles.item}>
                <Text>{i18n.t('COMMON.MARITAL_STATUS')}</Text>
                <Text style={styles.title}>
                  {currentHandlingCNA?.maritalStatus
                    ? i18n.t(`MARITAL_STATUS.${currentHandlingCNA?.maritalStatus}`)
                    : ''}
                </Text>
              </View>
              <View>
                <ReactTextInput
                  multiline
                  numberOfLines={5}
                  style={styles.inputComment}
                  defaultValue={currentHandlingCNA?.notes}
                  placeholder="Ghi chú"
                  placeholderTextColor="#E0E0E0"
                  onChangeText={(s: string) => setNotes(s)}
                  onBlur={() => onUpdateNotes(notes)}
                  onSubmitEditing={(e) => onUpdateNotes((e.target as any).value)}
                />
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={[globalStyles.mobileContainer, globalStyles.Center]}>
            <CircleProgressIndicator />
          </View>
        )}
        <View style={[globalStyles.PositionAbsolute, styles.buttonEditContainer]}>
          <View style={styles.buttonEdit}>
            <TouchableOpacity
              style={{
                backgroundColor: '#ED1B2E',
                padding: 12,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 20,
              }}
              onPress={() => setLastStep(currentHandlingCNA?._id)}
            >
              <Text style={{ color: '#FFF', fontWeight: 'bold' }}>{i18n.t('COMMON.EDIT_PLAN')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(MobileDetailCna)

const styles = StyleSheet.create({
  defaultContent: {
    marginBottom: 76,
    padding: 16,
  },
  boxCard: {
    padding: 16,
    backgroundColor: '#FFF',
    borderRadius: 10,
  },
  boxConfirm: {
    textAlign: 'center',
    marginBottom: 10,
  },
  boxShare: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
    fontSize: 15,
  },
  id: {
    color: '#ED1B2E',
    fontWeight: '600',
  },
  idHightLight: {
    fontWeight: '600',
  },
  title: {
    color: '#1F1F20',
  },
  line: {
    backgroundColor: '#EDF2F4',
    width: '100%',
    height: 2,
    marginBottom: 20,
  },
  buttonEditContainer: { height: 76, bottom: 0, left: 0, right: 0, flexDirection: 'row' },
  buttonEdit: {
    flex: 1,
    padding: 16,
    backgroundColor: '#FFF',
  },
  inputComment: {
    backgroundColor: Colors.textBoxBackground,
    borderWidth: 1,
    borderColor: Colors.borderColor,
    borderRadius: 10,
    textAlignVertical: 'top',
    marginBottom: 0,
    padding: 16,
  },
})
