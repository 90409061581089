import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import FilledButton from './FilledButton'
import OutlinedButton from './OutlinedButton'

export type StepButtonProps = {
  onBack: () => void
  onNext?: () => void
  disabledOnBack?: boolean
  disabledOnNext?: boolean
  loadingOnNext?: boolean
  marginTop?: number
  styleButton?: object
  nextText?: string
  backText?: string
  buttonWidth?: number
}

export function StepButton({ onBack, onNext, disabledOnBack, disabledOnNext, loadingOnNext, marginTop, styleButton, backText, nextText, buttonWidth }: StepButtonProps) {
  return (
    <View style={[styles.container,styleButton]}>
      <OutlinedButton
        title={backText ?? "Quay lại"}
        onPress={onBack}
        disabled={disabledOnBack}
        buttonStyle={{ width: buttonWidth ?? 130, paddingHorizontal: 0, marginTop: marginTop }}
        titleStyle={{ fontSize: 15, fontWeight: '700' }}
      />
      <FilledButton
        title={nextText ?? "Tiếp tục"}
        buttonStyle={{ width: buttonWidth ?? 130, marginLeft: 16, paddingHorizontal: 0, marginTop: marginTop }}
        onPress={onNext}
        disabled={disabledOnNext}
        titleStyle={{ fontSize: 15, fontWeight: '700' }}
        loading={loadingOnNext}
      />
    </View>
  )
}

export default StepButton

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
