import * as React from 'react'
import { StyleSheet, Image, ImageSourcePropType } from 'react-native'
import logo from '../assets/images/splash-logo.png'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { RootStackScreenProps } from '../types'
import AsyncStorage from '@react-native-async-storage/async-storage'
import ApiException from '../model/exception/ApiException'
import exceptionCode from '../data/exceptionCode'
import toastService from '../services/toast/ToastService'
import i18n from 'i18n-js'
import { AuthContext } from '../App'
import Layout from '../constants/Layout'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaView } from 'react-native-safe-area-context'
import UserService from '../services/UserService'
import { setUserInfo } from '../slices/authenSlice'
import { setUser } from '../slices/userSlice'
import { openAuthenAsync } from '../helper/RouteHelper'

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.userInfo,
  loading: state.authen.loading,
  authenError: state.authen.error,
  userError: state.user.error,
})

const mapDispatchToProps = {
  setUserInfo,
  setUser,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'Splash'> & PropsFromRedux & {}

function SplashScreen({ navigation, userInfo, loading, userError, setUserInfo, setUser }: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const authContext = React.useContext(AuthContext)
  React.useEffect(() => {
    setTimeout(() => {
      if (!authContext) {
        checkAuthenState()
      } else {
        onNextScreen()
      }
    }, 2000)
  }, [])

  React.useEffect(() => {
    let errorData = userError as ApiException
    if (errorData) {
      if (errorData.code === exceptionCode.FORBIDDEN) {
        AsyncStorage.setItem('accessToken', '')
        AsyncStorage.setItem('refreshToken', '')
        toastService.error(i18n.t('MESS.TOKEN_EXPIRED'))
        setTimeout(() => {
          navigation.replace('LogIn')
        }, 2000)
      } else {
        toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
      }
    }
  }, [userError])

  function onNextScreen() {
    if (isMobileDevice) {
      navigation.replace('MobileHome')
    } else {
      navigation.replace('AfterLogIn')
    }
  }

  function onLogIn() {
    openAuthenAsync(navigation)
  }

  async function checkAuthenState() {
    if (userInfo) {
      onNextScreen()
    } else {
      // Check token
      let accessToken = await AsyncStorage.getItem('accessToken')
      if (accessToken) {
        UserService.getUserInfo()
        .then((res) => {
          if (res) {
            setUserInfo(res.data)
            setUser(res.data)
            onNextScreen()
          } else {
            onLogIn()
          }
        })
      } else {
        onLogIn()
      }
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="light" />
      <Image source={logo as ImageSourcePropType} style={styles.image} />
    </SafeAreaView>
  )
}

export default connector(SplashScreen)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
  },
  image: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
})
