import React, { Component } from 'react'
import { Animated, Text, View, Image, ImageSourcePropType } from 'react-native'
import i18n from 'i18n-js'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToastProps {}

interface IToastMessageProps {
  id?: number
  message?: string
  code?: string
  params?: Record<string, unknown>
  duration?: number
  position?: 'top' | ' bottom'
  backgroundColor?: string
  colorText?: string
  icon?: ImageSourcePropType
  onHideToastMess?: (id?: number) => void
}

interface IToastMessageState {
  message?: string
  code?: string
  params?: Record<string, unknown>
  duration?: number
  position?: 'top' | ' bottom'
  backgroundColor?: string
  colorText?: string
  icon?: ImageSourcePropType
  toastHeight?: number
}

export interface IToastOption {
  id?: number
  message?: string
  code?: string
  params?: Record<string, unknown>
  duration?: number
  position?: 'top' | ' bottom'
  backgroundColor: string
  colorText?: string
  icon?: ImageSourcePropType
}

const defaultState = {
  isShow: false,
  message: '',
  code: '',
  params: {},
  position: 'top',
  colorText: '#ED1B2E',
  backgroundColor: '#fabbc0',
  icon: '',
  messagesList: new Array<IToastOption>(),
}

export class Toast extends Component<IToastProps> {
  private timerID: any = 0
  state = {
    ...defaultState,
  }

  showToast = (option: IToastOption) => {
    const {
      message = '',
      code = '',
      params = {},
      position = defaultState.position,
      colorText = defaultState.colorText,
      backgroundColor = defaultState.backgroundColor,
      duration = 4000,
      icon = defaultState.icon,
    } = option
    let messagesList = this.state.messagesList
    if (!messagesList.find((e) => e.code === code)) {
      messagesList.push(option)
      this.setState({
        ...{
          message,
          code,
          params,
          position,
          colorText,
          backgroundColor,
          duration,
          icon,
        },
        isShow: true,
        messagesList: messagesList,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHideToast = (id?: number) => {
    let index = this.state.messagesList.findIndex((e) => e.id === id)
    let temp = [...this.state.messagesList]
    temp.splice(index, 1)
    this.setState({
      messagesList: [...temp],
    })
  }

  renderIcon = () => {
    const { icon = defaultState.icon } = this.state
    if (icon) {
      return <Image source={icon as ImageSourcePropType} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
    }
    return null
  }

  render() {
    const { position = defaultState.position } = this.state
    return (
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          zIndex: 9999,
          elevation: 9999,
          position: 'absolute',
          top: position === 'top' ? '5%' : '90%',
        }}
      >
        {this.state.messagesList.map((e, i) => {
          return (
            <ToastMessage
              key={e.id}
              id={e.id}
              message={e.code ? i18n.t('code', e.params) : e.message ?? ''}
              code={e.code}
              params={e.params}
              duration={e.duration}
              position={e.position}
              backgroundColor={e.backgroundColor}
              colorText={e.colorText}
              icon={e.icon}
              onHideToastMess={(id?: number) => this.onHideToast(id)}
            />
          )
        })}
      </View>
    )
  }
  componentWillUnmount() {
    this.timerID && clearTimeout(this.timerID)
  }
}

class ToastMessage extends Component<IToastMessageProps, IToastMessageState> {
  private timerID: any = 0
  private animateOpacityValue = new Animated.Value(1)
  private animateMarginTopValue = new Animated.Value(-100)

  constructor(props: IToastMessageProps) {
    super(props)
    this.state = {
      message: props.message,
      code: props.code,
      params: props.params,
      position: 'top',
      colorText: 'white',
      backgroundColor: props.backgroundColor,
      icon: props.icon,
      toastHeight: 0,
    }
  }

  componentDidMount() {
    this.animateShow()
  }

  componentWillUnmount() {
    clearTimeout(this.timerID)
  }

  animateShow = () => {
    Animated.timing(this.animateMarginTopValue, {
      useNativeDriver: false,
      toValue: 8,
      duration: 300,
    }).start((callback) => {
      this.hideToast()
    })
  }

  renderIcon = () => {
    const { icon = defaultState.icon } = this.state
    if (icon) {
      return <Image source={icon as ImageSourcePropType} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
    }
    return null
  }

  hideToast = () => {
    this.timerID = setTimeout(() => {
      Animated.timing(this.animateOpacityValue, {
        useNativeDriver: false,
        toValue: 0,
        duration: 300,
      }).start()
      Animated.timing(this.animateMarginTopValue, {
        useNativeDriver: false,
        toValue: -(this.state.toastHeight ?? 0),
        duration: 300,
      }).start((callback) => {
        if (this.props.onHideToastMess) {
          this.props.onHideToastMess(this.props.id)
        }
      })
    }, 5000)
  }

  render() {
    const { message = '', code = '', params = {}, backgroundColor = defaultState.backgroundColor } = this.state
    return (
      <Animated.View
        style={{
          opacity: this.animateOpacityValue,
          backgroundColor: backgroundColor,
          width: 'auto',
          maxWidth: '90%',
          paddingHorizontal: 2,
          paddingVertical: 13.17,
          borderRadius: 12.5,
          justifyContent: 'center',
          marginTop: this.animateMarginTopValue,
        }}
        onLayout={(event) => {
          var { x, y, width, height } = event.nativeEvent.layout
          this.setState({ toastHeight: height })
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 8,
          }}
        >
          {this.renderIcon()}
          <Text
            numberOfLines={2}
            style={{
              marginLeft: 5,
              color: '#ED1B2E',
              fontSize: 15,
              alignSelf: 'stretch',
              textAlign: 'center',
            }}
          >
            {code ? i18n.t('code', params) : message}
          </Text>
        </View>
      </Animated.View>
    )
  }
}

export default Toast
