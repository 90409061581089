import React, { useState } from 'react';
import { StyleSheet, Text, View, TextInput, KeyboardAvoidingView } from 'react-native';
import { CheckBox, Icon } from 'react-native-elements'
import Tooltips from './Tooltips'
import NumberFormat from 'react-number-format'
import Layout from '../../constants/Layout';


export type InputDataProps = {
  helpText?: string
  title?: string
  value?: any
  stylesInput?: object
  type?: "row" | "column" | undefined
  placeholder?: string
  wrapHelpTextContainer?: object
  checkboxTitle?: string
  titleStyle?: object
  toolstips?: boolean
  textUnderline?: boolean
  toolstipsTitle?: string
  styleWraphelpText?: object
  error?: React.ReactNode
  onPress?: () => void
  onBlur?: (value: any) => void
  onChangeText?: (value: any) => void
  isAllowed?: (values: any) => boolean;
  maxWidthInput?: any
  helpTextWrap?: object
  checkbox?: boolean
  maxSectionStyle?: object
  widthInput?: any
  allowNagitive?: boolean
  disabled?: boolean
}


export function InputData({ helpText, disabled, value, error,maxSectionStyle,maxWidthInput,helpTextWrap, isAllowed,wrapHelpTextContainer,allowNagitive,widthInput, titleStyle, placeholder, stylesInput, styleWraphelpText, title, type, checkbox, textUnderline, checkboxTitle, toolstips, toolstipsTitle, onPress, onChangeText, onBlur }: InputDataProps) {
  const [checked, setChecked] = useState(false);
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice


  if (isMobileDevice) {
    return (
      <KeyboardAvoidingView style={[mobileStyles.container, stylesInput, type === 'row' ? mobileStyles.containerRow : null, disabled === true ? mobileStyles.disabled : null]} pointerEvents={disabled ? 'none' : 'auto'}>

      <View style={[mobileStyles.tittleWrap,titleStyle]}><Text style={[mobileStyles.titleInput,textUnderline ? mobileStyles.SectionStyleTextUnderline : null, type === 'row' ? mobileStyles.titleInputRow : null]}>{title}</Text></View>
        <View style={[mobileStyles.wrapHelpText, checkbox && type === 'column' ? mobileStyles.wrapHelpTextColumn : null, styleWraphelpText, wrapHelpTextContainer]}>
        {/* <Text style={[mobileStyles.titleInput, textUnderline ? mobileStyles.SectionStyleTextUnderline : null, type === 'column' ? mobileStyles.titleInputRow : null]}>{title}</Text> */}
          <View style={[mobileStyles.SectionStyle, disabled === true || checkbox && checked === false ? mobileStyles.SectionStyleDisabled : null]}>
            
            <NumberFormat
              value={value}
              placeholder={placeholder}
              style={{
                textAlign: 'right',
                paddingRight: 10,
                width: '100%',
                border: 'none',
                outline: 'none',
                fontSize: 13,
                color: '#000'
              }}
            isAllowed={isAllowed}
            thousandSeparator="."
            decimalSeparator=","
            isNumericString={true}
            decimalScale={allowNagitive? 0 : 3}
            prefix={''}
            allowNegative={false}
            onValueChange={(values) => {
              const { formattedValue, value } = values;

              onChangeText && onChangeText(value)
            }}
              renderText={value => (
                <TextInput
                  underlineColorAndroid="transparent"
                  style={[mobileStyles.mobileInput, { textAlign: 'right' }]}
                  placeholder={placeholder}
                  onChangeText={(values) => {
                    onChangeText && onChangeText(values)
                  }}
                value={value}
                keyboardType="numeric"
              />
            )}
            />

            <View style={mobileStyles.TextWrap} ><Text style={mobileStyles.TextStyle}>{helpText}</Text>
            </View>
          </View>
          {error && error}

          {
            toolstips && toolstipsTitle ?
              <Tooltips title={toolstipsTitle} onPress={onPress} image={true} styles={mobileStyles.TextWrapTooltips} />
              : null
          }

        </View>
      </KeyboardAvoidingView>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={[tabletStyles.container, stylesInput, type === 'row' ? tabletStyles.containerRow : null, disabled === true ?  tabletStyles.disabled : null]}  pointerEvents={disabled ? 'none' : 'auto'}>
        {
          checkbox ?
            <CheckBox
              title={checkboxTitle}
              containerStyle={tabletStyles.checkBoxContainer}
              textStyle={[tabletStyles.titleInput, type === 'row' && checkbox && checked === false ? tabletStyles.titleInputRowDisable : tabletStyles.titleInputRow]}
              size={26}
              checkedIcon={<Icon
                name='check'
                type='font-awesome'
                size={15}
                containerStyle={tabletStyles.styleIcon}
                color='#ED1B2E'
                tvParallaxProperties={undefined}
              />}
              uncheckedColor='#D7DFFF'
              checked={checked}
              onPress={() => setChecked(!checked)}
            /> : null
        }
        {
          !checkboxTitle ?
            checkbox && checked === false ?
              title && <Text style={[titleStyle,tabletStyles.titleInput, type === 'row' && checkbox && checked === false ? tabletStyles.titleInputRowDisable : tabletStyles.titleInputRow]}>{title}</Text>
              : title && <Text style={[tabletStyles.titleInput, textUnderline ? tabletStyles.SectionStyleTextUnderline : null, type === 'row' ? tabletStyles.titleInputRow : null, titleStyle]}>{title}</Text>
            : null
        }
        <View style={[tabletStyles.wrapHelpText, checkbox && type === 'column' ? tabletStyles.wrapHelpTextColumn : null, wrapHelpTextContainer]}>
          {
            checkboxTitle ?
              checkbox && checked === false && checkboxTitle ?
                title && <Text style={[tabletStyles.titleInput, type === 'column' && checkbox && checked === false ? tabletStyles.titleInputRowDisable : tabletStyles.titleInputRow]}>{title}</Text>
                : title && <Text style={[tabletStyles.titleInput, textUnderline ? tabletStyles.SectionStyleTextUnderline : null, type === 'column' ? tabletStyles.titleInputRow : null]}>{title}</Text>
              : null
          }
          <View style={[tabletStyles.SectionStyle,maxSectionStyle, disabled === true || checkbox && checked === false ? tabletStyles.SectionStyleDisabled : null]}>
          
            <NumberFormat
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              style={{
                 textAlign: 'right',
                paddingRight: 10,
                border: 'none',
                outline: 'none',
                width: widthInput ? widthInput : '100%',
                maxWidth: maxWidthInput? maxWidthInput : 155,
                fontSize: 13.2667,
                // color: '#BDBDBD'
              }}
              thousandSeparator="."
              decimalSeparator=","
              isNumericString={true}
              decimalScale={allowNagitive? 0 : 3}
              prefix={''}
              allowNegative={false}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
  
                onChangeText && onChangeText(value)
              }}
            />
  
  
            <View style={[tabletStyles.TextWrap,helpTextWrap]} ><Text style={tabletStyles.TextStyle}>{helpText}</Text>
            </View>
          </View>

  
        </View>
        {error && error}
       {
            toolstips && toolstipsTitle ?
              <Tooltips title={toolstipsTitle} onPress={onPress} image={true} styles={tabletStyles.TextWrapTooltips} />
              : null
          }
      </View>
    )
  }
  return (
    <View style={[styles.container, stylesInput, type === 'row' ? styles.containerRow : null, disabled === true ?  styles.disabled : null]}  >
      {
        checkbox ?
          <CheckBox
            title={checkboxTitle}
            containerStyle={styles.checkBoxContainer}
            textStyle={[styles.titleInput, type === 'row' && checkbox && checked === false ? styles.titleInputRowDisable : styles.titleInputRow]}
            size={26}
            checkedIcon={<Icon
              name='check'
              type='font-awesome'
              size={15}
              containerStyle={styles.styleIcon}
              color='#ED1B2E'
              tvParallaxProperties={undefined}
            />}
            uncheckedColor='#D7DFFF'
            checked={checked}
            onPress={() => setChecked(!checked)}
          /> : null
      }
      {
        !checkboxTitle ?
          checkbox && checked === false ?
            title && <Text style={[titleStyle,styles.titleInput, type === 'row' && checkbox && checked === false ? styles.titleInputRowDisable : styles.titleInputRow]}>{title}</Text>
            : title && <Text style={[styles.titleInput, textUnderline ? styles.SectionStyleTextUnderline : null, type === 'row' ? styles.titleInputRow : null, titleStyle]}>{title}</Text>
          : null
      }
      <View style={[styles.wrapHelpText, checkbox && type === 'column' ? styles.wrapHelpTextColumn : null, wrapHelpTextContainer]}>
        {
          checkboxTitle ?
            checkbox && checked === false && checkboxTitle ?
              title && <Text style={[styles.titleInput, type === 'column' && checkbox && checked === false ? styles.titleInputRowDisable : styles.titleInputRow]}>{title}</Text>
              : title && <Text style={[styles.titleInput, textUnderline ? styles.SectionStyleTextUnderline : null, type === 'column' ? styles.titleInputRow : null]}>{title}</Text>
            : null
        }
        <View style={[styles.SectionStyle,maxSectionStyle, disabled === true || checkbox && checked === false ? styles.SectionStyleDisabled : null]}>

        <NumberFormat
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              style={{
                 textAlign: 'right',
                paddingRight: 10,
                border: 'none',
                outline: 'none',
                width: widthInput ? widthInput : '100%',
                maxWidth: maxWidthInput? maxWidthInput : 155,
                fontSize: 16,
                // color: '#BDBDBD'
              }}
              thousandSeparator="."
              decimalSeparator=","
              isNumericString={true}
              decimalScale={allowNagitive? 0 : 3}
              prefix={''}
              allowNegative={false}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
  
                onChangeText && onChangeText(value)
              }}
            />


          <View style={[styles.TextWrap,helpTextWrap]} ><Text style={styles.TextStyle}>{helpText}</Text>
          </View>
        </View>

        {
          toolstips && toolstipsTitle ?
            <Tooltips title={toolstipsTitle} onPress={onPress} image={true} styles={styles.TextWrapTooltips} />
            : null
        }

      </View>
      {error && error}

    </View>
  )
}

const styles = StyleSheet.create({
  TextWrapTooltips: {
    marginLeft: 0
  },

  container: {
    justifyContent: 'flex-start',
    marginBottom: 15,
    width: '100%'
  },
  disabled: {
    opacity: 0.4,
    // pointEvent: 'none'
  },
  containerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapHelpText: {
    flexDirection: 'row',
    width: 'calc(100% - 95px)',
    alignItems: 'flex-end',
    flexWrap: 'wrap'
  },
  wrapHelpTextColumn: {
    alignItems: 'center',
    marginLeft: 35
  },
  checkBoxContainer: {
    borderWidth: 0,
    marginLeft: 0,
    marginBottom: 20,
    padding: 0,
    backgroundColor: 'transparent'
  },
  checkBoxText: {
    fontWeight: '400',
    color: '#373737',
    paddingBottom: 2,
    fontSize: 16
  },
  titleInputRow: {
    marginBottom: 0,
    marginRight: 10,
    minWidth: 100
  },
  SectionStyleTextUnderline: {
    textDecorationLine: 'underline'
  },
  titleInputRowDisable: {
    marginBottom: 0,
    marginRight: 20,
    minWidth: 90,
    opacity: 0.5
  },
  titleInput: {
    fontWeight: '600',
    color: '#505D6F',
    fontSize: 16,
    marginBottom: 20
  },
  InputStyle: {
    textAlign: 'right',
    paddingRight: 10,
    fontSize: 16,
    color: '#000'
  },
  SectionStyleDisabled: {
    opacity: 0.5
  },
  SectionStyle: {
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    height: 40,
    maxWidth: 340,
    width: 'auto',
    // marginRight: 30,
    paddingLeft: 15,
    borderRadius: 10,
  },
  styleIcon: {
    borderRadius: 5,
    backgroundColor: '#ed1b2e4d',
    width: 23,
    height: 23,
    alignItems: 'center',
    justifyContent: 'center'
  },
  TextWrap: {
    padding: 10,
    zIndex: 9,
    // minWidth: 130,
    borderLeftWidth: 2,
    // justifyContent: 'center',
    alignItems: 'center',
    borderLeftColor: '#D7DFFF',
  },
  TextStyle: {
    fontWeight: '600',
    color: '#000',
    fontSize: 16
  }
});

const tabletStyles = StyleSheet.create({
  TextWrapTooltips: {
    marginTop: 10
  },
  container: {
    justifyContent: 'flex-start',
    marginBottom: 10,
    width: '100%'
  },
  disabled: {
    opacity: 0.4,
    // pointEvent: 'none'
  },
  containerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapHelpText: {
    flexDirection: 'row',
    width: 'calc(100% - 95px)',
    alignItems: 'flex-end',
    flexWrap: 'wrap'
  },
  wrapHelpTextColumn: {
    alignItems: 'center',
    marginLeft: 35
  },
  checkBoxContainer: {
    borderWidth: 0,
    marginLeft: 0,
    marginBottom: 20,
    padding: 0,
    backgroundColor: 'transparent'
  },
  checkBoxText: {
    fontWeight: '400',
    color: '#373737',
    paddingBottom: 2,
    fontSize: 13.2667
  },
  titleInputRow: {
    marginBottom: 0,
    marginRight: 10,
    minWidth: 100
  },
  SectionStyleTextUnderline: {
    textDecorationLine: 'underline'
  },
  titleInputRowDisable: {
    marginBottom: 0,
    marginRight: 20,
    minWidth: 90,
    opacity: 0.5
  },
  titleInput: {
    fontWeight: '600',
    color: '#505D6F',
    fontSize: 13.2667,
    marginBottom: 15
  },
  InputStyle: {
     textAlign: 'right',
    paddingRight: 10,
    fontSize: 13.2667,
    color: '#000'
  },
  SectionStyleDisabled: {
    opacity: 0.5
  },
  SectionStyle: {
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    height: 36,
    maxWidth: 340,
    width: 'auto',
    paddingLeft: 15,
    // marginRight: 30,
    borderRadius: 10,
  },
  styleIcon: {
    borderRadius: 5,
    backgroundColor: '#ed1b2e4d',
    width: 23,
    height: 23,
    alignItems: 'center',
    justifyContent: 'center'
  },
  TextWrap: {
    padding: 10,
    zIndex: 9,
    minWidth: 96,
    borderLeftWidth: 2,
    // justifyContent: 'center',
    alignItems: 'center',
    borderLeftColor: '#D7DFFF',
  },
  TextStyle: {
    fontWeight: '600',
    color: '#000',
    fontSize: 12.4375
  }
});


const mobileStyles = StyleSheet.create({
  TextWrapTooltips: {
    marginLeft: 40
  },
  disabled: {
    opacity: 0.4,
  },
  container: {
    justifyContent: 'flex-start',
    marginBottom: 15,
    width: '100%'
  },
  mobileInput: {
    fontSize: 13,
    fontWeight: '600',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 0,
    borderColor: '#fff',
  },
  containerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tittleWrap: {
    flexShrink: 20,
  },
  wrapHelpText: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexShrink : 40,
    width: '100%'
  },
  wrapHelpTextColumn: {
    alignItems: 'center',
    marginLeft: 35
  },
  checkBoxContainer: {
    borderWidth: 0,
    marginLeft: 0,
    marginBottom: 20,
    padding: 0,
    backgroundColor: 'transparent'
  },
  checkBoxText: {
    fontWeight: '400',
    color: '#373737',
    paddingBottom: 2,
    fontSize: 13
  },
  titleInputRow: {
    marginBottom: 0,
    minWidth: 100
  },
  SectionStyleTextUnderline: {
    textDecorationLine: 'underline'
  },
  titleInputRowDisable: {
    marginBottom: 0,
    marginRight: 10,
    minWidth: 100,
    opacity: 0.5
  },
  titleInput: {
    fontWeight: '600',
    color: '#505D6F',
    fontSize: 13,
    marginBottom: 15
  },
  InputStyle: {
    textAlign: 'right',
    paddingRight: 10,
    width: '100%',
    fontSize: 13,
    color: '#000'
  },
  SectionStyleDisabled: {
    opacity: 0.5
  },
  SectionStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    height: 40,
    width: '100%',
    borderRadius: 10,
  },
  styleIcon: {
    borderRadius: 5,
    backgroundColor: '#ed1b2e4d',
    width: 23,
    height: 23,
    alignItems: 'center',
    justifyContent: 'center'
  },
  TextWrap: {
    padding: 10,
    zIndex: 9,
    minWidth: 96,
    borderLeftWidth: 2,
    // justifyContent: 'center',
    alignItems: 'center',
    borderLeftColor: '#D7DFFF',
  },
  TextStyle: {
    fontWeight: '600',
    color: '#000',
    fontSize: 13
  }
})
