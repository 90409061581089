import React from 'react'
import { StyleSheet, View, Text, Linking } from 'react-native'
import {
  FilledButton,
  SizedBox,
  styles as globalStyles,
  ToggleRadioButton,
  CustomScrollView,
} from '../../../common'
import i18n from 'i18n-js'
import RenderHtml from 'react-native-render-html'
import { Controller, useForm } from 'react-hook-form'
import Layout from '../../../constants/Layout'

const policyConfirmNoti = `
  <div style="margin-top: 8px; text-align: justify;"><span style="color: #505D6F; font-size: 14px; font-weight: normal; text-align: justify;">Nếu bạn chọn “Đồng ý” nghĩa là bạn đã hiểu các Chính sách bảo mật thông tin của Prudential thể hiện tại <a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/" target="_blank" style="color:#2F7AEB">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a> và cho phép chúng tôi thu thập, xử lý thông tin của bạn.</span></div>
`

type IProps = {
  onClose: (isCustomerConfirmed: boolean) => void
  loading: boolean
}

function CustomerConfirmDialog({ onClose, loading }: IProps) {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()
  const isMobileDevice = Layout.isMobileDevice
  const [enableContinue, setEnableContinue] = React.useState(false)

  function onSubmit(data: any) {
    onClose(data.isCustomerConfirmed)
  }

  if (isMobileDevice) {
    return (
      <CustomScrollView style={{ paddingLeft: 10, paddingRight: 5 }} height={412}>
        <Text style={[globalStyles.DialogTitle, globalStyles.Width100]}>{i18n.t('COMMON.PRIVACY_POLICY')}</Text>
        <Text style={[globalStyles.DialogTitle, globalStyles.Width100]}>{i18n.t('COMMON.FOR_CUSTOMER')}</Text>
        <SizedBox height={20} />
        <View style={desktopStyles.noticeContainer}>
          <RenderHtml html={policyConfirmNoti} onLinkPress={(evt, href) => { Linking.openURL(href) }} />
        </View>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, onBlur, value }) => (
            <ToggleRadioButton
              title=""
              data={[
                {
                  value: true,
                  text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                },
              ]}
              direction="column"
              value={getValues('isCustomerConfirmed')}
              containerStyle={{ alignItems: 'flex-start' }}
              onChange={(value: any) => {
                setValue('isCustomerConfirmed', value)
                setEnableContinue(value === true)
              }}
              radioItemContainerStyle={{ maxWidth: 'none' }}
            />
          )}
          name="isCustomerConfirmed"
          defaultValue=""
        />
        <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
          <FilledButton
            title={i18n.t('COMMON.CONFIRM')}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={globalStyles.CommonButton}
            titleStyle={{ fontSize: 14 }}
            disabled={!enableContinue}
            loading={loading}
          />
        </View>
        <SizedBox height={22} />
      </CustomScrollView>
    )
  }

  return (
    <CustomScrollView style={{ paddingHorizontal: 10 }} height={360}>
      <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, globalStyles.Width100]}>
        <Text style={[globalStyles.DialogTitle, globalStyles.Width100, { marginTop: 0 }]}>{i18n.t('COMMON.PRIVACY_POLICY')}</Text>
        <Text style={[globalStyles.DialogTitle, globalStyles.Width100]}>{i18n.t('COMMON.FOR_CUSTOMER')}</Text>
      </View>
      <SizedBox height={20} />
      <View>
        <View style={desktopStyles.noticeContainer}>
          <RenderHtml html={policyConfirmNoti} onLinkPress={(evt, href) => { Linking.openURL(href) }} />
        </View>
      </View>
      <View style={[globalStyles.Width100]}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, onBlur, value }) => (
            <ToggleRadioButton
              title=""
              data={[
                {
                  value: true,
                  text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                },
              ]}
              direction="column"
              value={getValues('isCustomerConfirmed')}
              containerStyle={{ alignItems: 'flex-start' }}
              onChange={(value: any) => {
                setValue('isCustomerConfirmed', value)
                setEnableContinue(value === true)
              }}
              radioItemContainerStyle={{ maxWidth: 'none' }}
            />
          )}
          name="isCustomerConfirmed"
          defaultValue=""
        />
      </View>
      <SizedBox height={22} />
      <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
        <FilledButton
          title={i18n.t('COMMON.CONFIRM')}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={globalStyles.CommonButton}
          titleStyle={{ fontSize: 14 }}
          disabled={!enableContinue}
          loading={loading}
        />
      </View>
      <SizedBox height={22} />
    </CustomScrollView>
  )
}

export default CustomerConfirmDialog

const desktopStyles = StyleSheet.create({
  inputComment: {
    backgroundColor: '#FAFBFF',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderRadius: 10,
    marginBottom: 30,
    padding: 16,
  },
  noticeContainer: {
    backgroundColor: '#00000000',
    textAlign: 'justify',
  },
})
