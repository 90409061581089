import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import {
  styles as globalStyles,
  BlockAlysys,
  InputData,
  Quantity,
  BoxFooter,
  ValidateRequiredError,
  SizedBox,
} from '../common'
import { useMediaQuery } from 'react-responsive'
import {
  tabletMediumMediaConfig,
} from '../constants/Constants'
import i18n from 'i18n-js'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { updateProfile } from '../slices/needsCartSlice'
import { saveCNA } from '../slices/cnaSlice'
import { connect, ConnectedProps } from 'react-redux'
import { updateUserProtect } from '../slices/userSlice'
import Layout from '../constants/Layout'
import { i18nAgentWording } from '../components/AgentWording'

const mapStateToProps = (state: RootState) => ({
  protectEdu: state.protectEdu.protectEdu,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {
  updateProfile,
  saveCNA,
  updateUserProtect,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SafeEduScreen({
  userInfo,
  userProtect,
  protectEdu,
  updateUserProtect
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice
  const isTabletSmallDevice = useMediaQuery(tabletMediumMediaConfig)


  function countTotal() {
    const data = getValues(`childrenDetails`)
      ?.map((x: any) => {
        return Number((getValues(`eduPlanAge`) || 0) - x.childAge) * x.tuition * 12
      })
      .reduce((prev, next) => prev + next)
    setValue('subTotal',  Number(data) )
    if (data === 0 ) {
      checkDataAppend()
    }
  }

  const dataAppend = {
    childAge: 0,
    tuition: 0,
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
    reset,
    errors
  } = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: protectEdu
  })

  useEffect(() => {
    if (userProtect?.protectEdu?.childrenDetails) {
      reset(userProtect?.protectEdu, { errors: true })
    }
  }, [userProtect])

  function checkDataAppend() {
    if (userProtect){
      const dataAppend = [...Array(Number(watch()?.children)).keys()]
      const dataChild = userProtect?.eduPlan?.childrenDetails || 1
      const dataAdd = dataAppend?.map((x, index) => {
        return (
          dataChild[index] || {
            isSchool: false,
            isUniversity: false,
            isAfterUni: false,
            childAge: 0,
            schoolFees: 0,
            schoolYears: 0,
            universityFees: 0,
            universityYears: 0,
            afterUniFees: 0,
            afterUniYears: 0,
          }
        )
      })
      const dataEduPlan =
        (dataAdd &&
          dataAdd.map((x, index) => ({
            ...x,
            childAge: watch()?.childrenDetails &&  Number(watch()?.childrenDetails[index]?.childAge) || 0,
          }))) ||
        1
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        eduPlan:
          userProtect?.eduPlan?.isSelect === true
            ? {
                ...userProtect?.eduPlan,
                children: Number(watch().children),
                childrenDetails: dataEduPlan ? dataEduPlan : dataAdd,
              }
            : userProtect?.eduPlan,
        protectEdu: {
          ...userProtect?.protectEdu,...JSON.parse(JSON.stringify(watch())),
          // subTotal: !checkTotal ? watch().subTotal: 0,
          isSelect: userProtect?.protectEdu?.isSelect,
        },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }


  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  function renderErrorMessageChild(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} style={{marginBottom: 10, marginTop: 0}} />
        </View>
      )
    } else {
      return <></>
    }
  }


  useEffect(() => {
    
      checkDataAppend()
      
  }, [getValues('subTotal')])

  function getAppendComponent(value: number) {
    let appendComponent = []
    for (let i = 0; i < value; i++) {
      appendComponent.push(renderView(i))
    }
    return appendComponent
  }

  function renderView(i: number) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.boxEduChild} key={i}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                  title={`Con thứ ${switchChild(i)}`}
                  helpText="Tuổi"
                  allowNagitive
                  type="column"
                  textUnderline
                  placeholder="Nhập"
                  stylesInput={{ marginBottom: 10 }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={async (value) => {
                    onChange(value)
                    if (Number(value) < (watch('eduPlanAge') || 0)) {
                      clearErrors(`eduPlanAge`)
                    } else {
                      setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại' })
                    }
                    countTotal()
                  }}
                />
               {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && renderErrorMessageChild(errors?.childrenDetails[i]?.childAge?.message)}

              </View>
            )}
            name={`childrenDetails.${i}.childAge`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
          />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={{ width: '100%', marginBottom: 10 }}>
              <InputData
                title={i18nAgentWording({
                  agentOfficeCode: userInfo?.officeCode,
                  wordingCode: `NEEDS_ANALYST.PROTECT_EDU.MONTHLY_TUITION`
                })}
                helpText="Triệu đồng"
                type="column"
                placeholder="Nhập"
                stylesInput={{ marginBottom: 10 }}
                value={value !== 0 ? value : ''}
                onBlur={onBlur}
                onChangeText={(value) => {
                  onChange(value)
                  countTotal()
                }}
              />
            {errors?.childrenDetails && errors?.childrenDetails[i]?.tuition && renderErrorMessageChild(errors?.childrenDetails[i]?.tuition?.message)}

            </View>
            )}
            name={`childrenDetails.${i}.tuition`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
          />
        </View>
      )
    }
    if (isTabletSmallDevice) {
      return (
        <View style={mobileStyles.boxEduChild} key={i}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[globalStyles.Flex1]}>
                <InputData
                  title={`Con thứ ${switchChild(i)}`}
                  helpText="Tuổi"
                  allowNagitive
                  type="column"
                  textUnderline
                  placeholder="Nhập"
                  stylesInput={{ marginBottom: 10 }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={async (value) => {
                    onChange(value)
                    if (Number(value) < (watch('eduPlanAge') || 0)) {
                      clearErrors(`eduPlanAge`)
                    } else {
                      setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại' })
                    }
                    countTotal()
                  }}
                />
               {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && renderErrorMessageChild(errors?.childrenDetails[i]?.childAge?.message)}

              </View>
            )}
            name={`childrenDetails.${i}.childAge`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
          />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[globalStyles.Flex1]}>
                <InputData
                  title={i18nAgentWording({
                    agentOfficeCode: userInfo?.officeCode,
                    wordingCode: `NEEDS_ANALYST.PROTECT_EDU.MONTHLY_TUITION`
                  })}
                  helpText="Triệu đồng"
                  type="column"
                  placeholder="Nhập"
                  stylesInput={{ marginBottom: 10 }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                />
              {errors?.childrenDetails && errors?.childrenDetails[i]?.tuition && renderErrorMessageChild(errors?.childrenDetails[i]?.tuition?.message)}

              </View>
            )}
            name={`childrenDetails.${i}.tuition`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
          />
        </View>
      )
    }
    if (isLaptopDevice) {
      return (
        <View style={desktopStyles.boxEduChild} key={i}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[globalStyles.Flex1]}>
                <InputData
                  title={`Con thứ ${switchChild(i)}`}
                  helpText="Tuổi"
                  allowNagitive
                  type="row"
                  placeholder="Nhập"
                  textUnderline
                  widthInput={90}
                  wrapHelpTextContainer={{ width: 'auto' }}
                  stylesInput={{ width: 'auto', marginBottom: 10}}
                  helpTextWrap={{ paddingHorizontal: 5 }}
                  maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={async (value) => {
                    onChange(value)
                    if (Number(value) < (watch('eduPlanAge') || 0)) {
                      clearErrors(`eduPlanAge`)
                    } else {
                      setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại' })
                    }
                    countTotal()
                  }}
                />
               {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && renderErrorMessageChild(errors?.childrenDetails[i]?.childAge?.message)}
              </View>
            )}
            name={`childrenDetails.${i}.childAge`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
          />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[globalStyles.Flex1]}>
                <InputData
                  title={i18nAgentWording({
                    agentOfficeCode: userInfo?.officeCode,
                    wordingCode: `NEEDS_ANALYST.PROTECT_EDU.MONTHLY_TUITION`
                  })}
                  helpText="Triệu đồng"
                  type="row"
                  placeholder="Nhập"
                  titleStyle={{ minWidth: 135 }}
                  widthInput={90}
                  wrapHelpTextContainer={{ width: 'auto' }}
                  stylesInput={{ width: 'auto', marginBottom: 10 }}
                  helpTextWrap={{ paddingHorizontal: 5 }}
                  maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                />
                {errors?.childrenDetails && errors?.childrenDetails[i]?.tuition && renderErrorMessageChild(errors?.childrenDetails[i]?.tuition?.message)}
              </View>
            )}
            name={`childrenDetails.${i}.tuition`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
          />
        </View>
      )
    }

    return (
      <View style={desktopStyles.boxEduChild} key={i}>
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <View style={[globalStyles.Flex1]}>
              <InputData
                title={`Con thứ ${switchChild(i)}`}
                helpText="Tuổi"
                allowNagitive
                type="row"
                placeholder="Nhập"
                textUnderline
                stylesInput={{ width: 'auto', marginBottom: 10 }}
                value={value !== 0 ? value : ''}
                onBlur={onBlur}
                onChangeText={async (value) => {
                  onChange(value)
                  countTotal()
                  if (Number(value) < (watch('eduPlanAge') || 0)) {
                    clearErrors(`eduPlanAge`)
                  } else {
                    setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại' })
                  }
           
                }}
              />
                 {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && renderErrorMessageChild(errors?.childrenDetails[i]?.childAge?.message)}
            </View>
          )}
          name={`childrenDetails.${i}.childAge`}
             rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
          />
        <Controller 
          control={control}
          render={({ onChange, onBlur, value }) => (
            <View style={[globalStyles.Flex1]}>
              <InputData
                title={i18nAgentWording({
                  agentOfficeCode: userInfo?.officeCode,
                  wordingCode: `NEEDS_ANALYST.PROTECT_EDU.MONTHLY_TUITION`
                })}
                helpText="Triệu đồng"
                type="row"
                placeholder="Nhập"
                titleStyle={{ minWidth: 155 }}
                widthInput={90}
                wrapHelpTextContainer={{ width: 'auto' }}
                stylesInput={{ width: 'auto', marginBottom: 10 }}
                value={value !== 0 ? value : ''}
                onBlur={onBlur}
                onChangeText={(value) => {
                  onChange(value)
                  countTotal()
                }}
              />
              {errors?.childrenDetails && errors?.childrenDetails[i]?.tuition && renderErrorMessageChild(errors?.childrenDetails[i]?.tuition?.message)}
            </View>
          )}
          name={`childrenDetails.${i}.tuition`}
             rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
        />
      </View>
    )
  }

  function switchChild(i: number) {
    switch (i) {
      case 0:
        return 'nhất'
      case 1:
        return 'hai'
      case 2:
        return 'ba'
      case 3:
        return 'bốn'
      case 4:
        return 'năm'
      case 5:
        return 'sáu'
      case 6:
        return 'bảy'
      case 7:
        return 'tám'
      case 8:
        return 'chín'
      case 9:
        return 'mười'
      default:
        return 'nhất'
    }
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          borderColor="#BE63F9"
          child={
            <View>
              <Controller
                control={control}
                   rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
                render={({ onChange, value }) => (
                  <>
                    <Quantity
                      title="Gia đình bạn có bao nhiêu con?"
                      min={1}
                      start={value || 0}
                      value={value}
                      onChange={ async (value) => {
                        if (value < getValues('children')) {
                        await  onChange(value)
                        await  setValue('childrenDetails', getValues('childrenDetails').slice(0, value))
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length + 1}`])
                          countTotal()
                          checkDataAppend()
                        } else {
                          setValue('childrenDetails',  [...getValues('childrenDetails'), dataAppend])
                          onChange(value)
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length - 1}`])
                          checkDataAppend()
                        }
                      }}
                      max={10}
                    />
                    {getAppendComponent(value)}
                  </>
                )}
                name="children"
              />
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: Math.max( ...watch('childrenDetails')&&  watch('childrenDetails')?.map(x => x.childAge)) + 1 ,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_AGE_EDU'),
                  },
                }}

                render={({ onChange, value, onBlur }) => (
                  <InputData
                    title={i18nAgentWording({
                      agentOfficeCode: userInfo?.officeCode,
                      wordingCode: `NEEDS_ANALYST.PROTECT_EDU.HOW_LONG`
                    })}
                    helpText="Tuổi"
                    allowNagitive
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={async (value) => {
                      onChange(value)
                      countTotal()
                      const dataChild = watch('childrenDetails');
                      if(dataChild && dataChild.every( x => Number(x.childAge) <(watch('eduPlanAge') || 0))){
                        clearErrors(`eduPlanAge`)
                      }else{
                        setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại',  type: "manual", })
                      }
                    }}
                    error={
                      errors.eduPlanAge && renderErrorMessage(errors.eduPlanAge.message)
                    }
                  />
                )}
                name="eduPlanAge"
              />
              <Controller
                control={control}
                   rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
      </View>
    )
  }


  if (isLaptopDevice) {
    return (
      <View>
        <BlockAlysys
          title={i18nAgentWording({
            agentOfficeCode: userInfo?.officeCode,
            wordingCode: 'NEEDS_ANALYST.LIST.protectEdu'
          })}
          borderColor="#BE63F9"
          child={
            <View>
              <Controller
                control={control}
                   rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
                render={({ onChange, value }) => (
                  <>
                    <Quantity
                      title="Gia đình bạn có bao nhiêu con?"
                      min={1}
                      start={value || 1}
                      value={value}
                      onChange={ async (value) => {
                        if (value < getValues('children')) {
                        await  onChange(value)
                        await  setValue('childrenDetails', getValues('childrenDetails').slice(0, value))
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length + 1}`])
                          countTotal()
                          checkDataAppend()
                        } else {
                          setValue('childrenDetails',  [...getValues('childrenDetails'), dataAppend])
                          onChange(value)
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length - 1}`])
                          checkDataAppend()
                        }
                      }}
                      max={10}
                    />
                    {getAppendComponent(value)}
                  </>
                )}
                name="children"
              />
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: Math.max( ...watch('childrenDetails')&&  watch('childrenDetails')?.map(x => x.childAge)) + 1 ,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_AGE_EDU'),
                  },
                }}

                render={({ onChange, value, onBlur }) => (
                  <InputData
                    title={i18nAgentWording({
                      agentOfficeCode: userInfo?.officeCode,
                      wordingCode: `NEEDS_ANALYST.PROTECT_EDU.HOW_LONG`
                    })}
                    helpText="Tuổi"
                    allowNagitive
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={async (value) => {
                      onChange(value)
                      countTotal()
                      const dataChild = watch('childrenDetails');
                      if(dataChild && dataChild.every( x => Number(x.childAge) <(watch('eduPlanAge') || 0))){
                        clearErrors(`eduPlanAge`)
                      }else{
                        setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại',  type: "manual", })
                      }
                    }}
                    error={
                      errors.eduPlanAge && renderErrorMessage(errors.eduPlanAge.message)
                    }
                  />
                )}
                name="eduPlanAge"
              />

              <Controller
                control={control}
                   rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    type="column"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title={i18nAgentWording({
          agentOfficeCode: userInfo?.officeCode,
          wordingCode: 'NEEDS_ANALYST.LIST.protectEdu'
        })}
        borderColor="#BE63F9"
        child={
          <View>
            <Controller
              control={control}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
              render={({ onChange, value }) => (
                <>
                  <Quantity
                    title="Gia đình bạn có bao nhiêu con?"
                    min={1}
                    start={value || 1}
                    value={value}
                    onChange={ async (value) => {
                      if (value < getValues('children')) {
                      await  onChange(value)
                      await  setValue('childrenDetails', getValues('childrenDetails').slice(0, value))
                        clearErrors([`childrenDetails.${getValues('childrenDetails').length + 1}`])
                        countTotal()
                        checkDataAppend()
                      } else {
                        setValue('childrenDetails',  [...getValues('childrenDetails'), dataAppend])
                        onChange(value)
                        clearErrors([`childrenDetails.${getValues('childrenDetails').length - 1}`])
                        checkDataAppend()
                      }
                    }}
                    max={10}
                  />
                  {getAppendComponent(value)}
                </>
              )}
              name="children"
            />

            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: Math.max( ...watch('childrenDetails')&&  watch('childrenDetails')?.map(x => x.childAge)) + 1 ,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_AGE_EDU'),
                },
              }}
              render={({ onChange, value, onBlur }) => (
                <InputData
                  title={i18nAgentWording({
                    agentOfficeCode: userInfo?.officeCode,
                    wordingCode: `NEEDS_ANALYST.PROTECT_EDU.HOW_LONG`
                  })}
                  helpText="Tuổi"
                  allowNagitive
                  type="column"
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={async (value) => {
                    onChange(value)
                    countTotal()
                    const dataChild = watch('childrenDetails');
                    if(dataChild && dataChild.every( x => Number(x.childAge) <(watch('eduPlanAge') || 0))){
                      clearErrors(`eduPlanAge`)
                    }else{
                      setError('eduPlanAge', { message: 'Vui lòng nhập tuổi bảo vệ lớn hơn tuổi hiện tại',  type: "manual", })
                    }
                  }}
                  error={
                    errors.eduPlanAge && renderErrorMessage(errors.eduPlanAge.message)
                  }
                />
              )}
              name="eduPlanAge"
            />

            <Controller
              control={control}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
              
              render={({ value }) => (
                <BoxFooter
                  title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                  backgroundColorBox="#F5E6FE"
                  titleStyle={{ color: '#BE63F9' }}
                  child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <SizedBox width={24} />
    </View>
  )
}

export default connector(SafeEduScreen)

const desktopStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    height: '100%',
  },
  helpAnalysis: {
    flexDirection: 'row',
  },
  individualDataContainer: {
    backgroundColor: '#fff',
    marginRight: 25,
    // overflowY: 'scroll',
    maxHeight: 700,
  },
  boxEduChild: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})

const mobileStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  boxEduChild: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    width: '100%',
    flex: 1,
    // overflowY: 'scroll',
    paddingBottom: 100,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
