import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NeedsOverallState {
  loading?: boolean
  needsOverallList: Array<any>
  planPeriodTime?: number
}

const initialState: NeedsOverallState = {
  loading: false,
  needsOverallList: [],
  planPeriodTime: 20,
}

export const needsOverallSlice = createSlice({
  name: 'needs-overall',
  initialState,
  reducers: {
    updateNeedsOverall: (state, action: PayloadAction<Array<any>>) => {
      state.needsOverallList = action.payload
    },
    savePlanPeriodTime: (state, action: PayloadAction<number>) => {
        state.planPeriodTime = action.payload
    }
  },
  extraReducers: (builder) => {},
})

export const { updateNeedsOverall, savePlanPeriodTime } = needsOverallSlice.actions

const { reducer } = needsOverallSlice
export default reducer
