import { ProfileInfoTab } from './ProfileInfoTab'
import * as React from 'react'
import { StyleSheet, Text, View, Image, ImageSourcePropType, TouchableOpacity, Animated, TouchableWithoutFeedback } from 'react-native'
import imgLeftCircle from '../../assets/images/circle-chevron-left-grey.png'

export type EditProfileTabProps = {
  profileData?: any
  onUpdateProfile: (data: any) => void
}

/**
 * @deprecated
 * Use edit profile tab from apps/cna-app/components/EditProfileTab instead
 */
export function EditProfileTab({ onUpdateProfile }: EditProfileTabProps) {
  const [showProfileTab, setShowProfileTab] = React.useState(false)
  const [showBlurBg, setShowBlurBg] = React.useState(false)
  const transformAnim = React.useRef(new Animated.Value(-335)).current
  const opacityAnim = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    if (showProfileTab) {
      Animated.timing(transformAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      setShowBlurBg(true)
      Animated.timing(opacityAnim, {
        toValue: 0.2,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
    } else {
      Animated.timing(transformAnim, {
        toValue: -335,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(opacityAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start(() => {
        setShowBlurBg(false)
      })
    }
  }, [opacityAnim, showProfileTab, transformAnim])

  function onUpdate(profileData: any) {
    onUpdateProfile(profileData)
    setShowProfileTab(false)
  }

  return (
    <>
      <TouchableWithoutFeedback onPress={() => setShowProfileTab(!showProfileTab)} style={styles.touchContainer}>
        <View style={styles.container}>
          <Image source={imgLeftCircle as ImageSourcePropType} style={styles.icon} />
          <Text style={styles.label}>Thông tin của bạn</Text>
        </View>
      </TouchableWithoutFeedback>
      {showBlurBg && (
        <Animated.View style={[styles.blurBg, { opacity: opacityAnim }]}>
          <TouchableOpacity onPress={() => setShowProfileTab(!showProfileTab)} style={styles.blurBg}>
            <View style={styles.blurBg}></View>
          </TouchableOpacity>
        </Animated.View>
      )}
      <Animated.View style={[styles.profileTabContainer, { right: transformAnim }]}>
        <ProfileInfoTab onUpdateProfile={onUpdate} />
      </Animated.View>
    </>
  )
}

export default EditProfileTab

const styles = StyleSheet.create({
  touchContainer: {
    transform: [{ rotateZ: '-90deg' }],
    width: 310,
    position: 'absolute',
    right: -140,
    top: '30%',
  },
  container: {
    width: 310,
    height: 40,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: '#D62828',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  label: {
    flex: 1,
    textAlign: 'center',
    color: '#FFF',
    fontSize: 16,
    fontWeight: '700',
  },
  icon: {
    width: 24,
    height: 24,
    transform: [{ rotateZ: '90deg' }],
  },
  profileTabContainer: {
    position: 'absolute',
    right: -335,
    top: 0,
  },
  blurBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'grey',
    opacity: 0.2,
  },
})
