import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserInfoData from '../model/UserInfoData'
import { genders, needsData, productTypes } from '../constants/Data'
import CNADetail from '../model/CNADetail'
import ProfileInfo from '../model/ProfileInfo'
import cnaService from '../services/CustomAnalyzeService'
import { updateAnswers, setProductType } from './advisorSlice'
import { hideLoading } from './commonSlice'
import { updateNeedsCartFromCache, updateProfile } from './needsCartSlice'
import { updateNeedsOverall } from './needsOverallSlice'
import { updateUserProtect } from './userSlice'

export interface CNAState {
  loadingChartData?: boolean
  currentHandlingCNA?: CNADetail
  userInfo?: UserInfoData
  error?: any
}

const initialState: CNAState = {}

export const getInternalCNADetail = createAsyncThunk('gen-pdf/cna-detail', async (req: any, thunkAPI: any) => {
  try {
    let responses = await Promise.all([
      cnaService.getDetailCnaInternal(req, req.agentCode),
      cnaService.geAgentInfoInternal(req.agentCode)
    ])
    let cnaData = responses[0].data as CNADetail
    // Update local profile data
    thunkAPI.dispatch(
      updateProfile({
        gender: cnaData?.customerGender,
        genderName: genders.find((e) => e.code === cnaData?.customerGender)?.name,
        age: cnaData?.customerAge,
        monthlyIncome: cnaData?.protectIncome?.monthlyIncome,
        monthlyExpense: cnaData?.protectLife?.monthlyFixedExpense,
        maritalStatus: cnaData?.maritalStatus,
      } as ProfileInfo)
    )

    thunkAPI.dispatch(
      updateUserProtect({
        cnaData,
      })
    )

    // Update needs cart data
    let patternNeedsData = [...needsData]
    patternNeedsData.forEach((e, i) => {
      let temp = [...e.data]
      temp.forEach((k, j) => {
        if ((cnaData as any)[k.key]) {
          temp[j] = { ...temp[j], isChecked: (cnaData as any)[k.key]?.isSelect, qty: (cnaData as any)[k.key]?.total }
        }
      })
      patternNeedsData[i] = { ...e, data: temp }
    })
    thunkAPI.dispatch(updateNeedsCartFromCache(patternNeedsData))

    // Update needs overall
    let data: Array<any> = []
    patternNeedsData.forEach((e) => {
      if (e.data) {
        e.data.forEach((e) => {
          if (e.isChecked) {
            data.push({
              ...e,
            })
          }
        })
      }
    })
    thunkAPI.dispatch(updateNeedsOverall([...data]))

    // Update questionnaire
    thunkAPI.dispatch(updateAnswers(cnaData?.questionObjects))

    // Update product type
    thunkAPI.dispatch(setProductType(productTypes.find((e) => e.value === cnaData?.product)))

    thunkAPI.dispatch(hideLoading({}))
    return responses
  } catch (error: any) {
    throw new Error(error?.message)
  }
})

export const genPdfSlice = createSlice({
  name: 'genPdf',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInternalCNADetail.pending, (state) => {})
      .addCase(getInternalCNADetail.fulfilled, (state, action) => {
        state.currentHandlingCNA = action.payload[0].data
        state.userInfo = action.payload[1].data as UserInfoData
      })
      .addCase(getInternalCNADetail.rejected, (state, action) => {
        state.error = action.error?.message
      })
  },
})

const { reducer } = genPdfSlice
export default reducer
