export interface IObjectPromise<T = any> {
  [key: string]: () => Promise<T>
}

const promiseAllObjects = async (promiseObj: IObjectPromise = {}) => {
  const list = []
  for (let index = 0; index < Object.keys(promiseObj).length; index++) {
    const key = Object.keys(promiseObj)[index]
    list.push(promiseObj[key]())
  }
  const res = await Promise.all(list)
  const output = {}
  for (let index = 0; index < Object.keys(promiseObj).length; index++) {
    const key = Object.keys(promiseObj)[index]
    Object.assign(output, {
      [key]: res[index],
    })
  }
  return output
}

export const utils = {
  promiseAllObjects,
}
