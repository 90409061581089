import React from 'react'
import { StyleSheet, View, Text, Linking } from 'react-native'
import {
  CustomScrollView,
  FilledButton,
  SizedBox,
  styles as globalStyles,
  ToggleRadioButton,
} from '../../../common'
import i18n from 'i18n-js'
import RenderHtml from 'react-native-render-html'
import { Controller, useForm } from 'react-hook-form'
import Layout from '../../../constants/Layout'

const content = `
  <div style="margin-top: 0px; text-align: justify;"><span style="color: #505D6F; font-size: 13px; font-weight: normal; text-align: justify;">Công cụ này sẽ thu thập, xử lý và lưu trữ lại các thông tin cá nhân bao gồm cả thông tin định danh (nếu có) do Khách hàng tiềm năng/Khách hàng cung cấp phục vụ mục đích (i) thực hiện khảo sát nhu cầu bảo hiểm, (ii) gửi Bảng kết quả Phân tích nhu cầu tài chính từ Công cụ này cho Khách hàng, (iii) nâng cao chất lượng dịch vụ cũng như nghiên cứu và thiết kế những sản phẩm bảo hiểm phù hợp hơn với nhu cầu của Khách hàng. Do đó, khi Tư vấn viên - sau đây gọi tắt là TVV - cung cấp cho Prudential thông tin cá nhân về (các) Khách hàng tiềm năng/Khách hàng, TVV cần được chỉ định và ủy quyền bởi (các) Khách hàng tiềm năng/Khách hàng này, theo quy định của pháp luật, để hành động thay cho họ. Cụ thể, TVV có trách nhiệm phải:</span></div>
  <div style="margin-top: 16px; text-align: justify;"><span style="color: #505D6F; font-size: 13px; font-weight: normal; text-align: justify;">1.	Giải thích rõ cho (các) Khách hàng tiềm năng/Khách hàng về loại thông tin cá nhân mà Prudential sẽ được thu thập/xử lý/lưu trữ và mục đích của việc thu thập/ xử lý /lưu trữ đó.</span></div>
  <div style="margin-top: 16px; text-align: justify;"><span style="color: #505D6F; font-size: 13px; font-weight: normal; text-align: justify;">2.	Nhận được sự đồng ý của (các) Khách hàng tiềm năng/Khách hàng trước khi thu thập thông tin cá nhân của họ cũng như trước khi gửi Bảng kết quả Phân tích nhu cầu tài chính từ Công cụ này đến địa chỉ email hoặc ứng dụng mà (các) Khách hàng tiềm năng/Khách hàng này sử dụng. </span></div>
  <div style="margin-top: 16px; text-align: justify;"><span style="color: #505D6F; font-size: 13px; font-weight: normal; text-align: justify;">Ngoài ra, TVV cũng cần tìm hiểu đồng thời hướng dẫn (các) Khách hàng tiềm năng/Khách hàng tìm hiểu về Chính sách bảo mật thông tin của Prudential để họ hiểu cách thức Prudential thu thập, xử lý và chuyển giao thông tin của họ tại đường dẫn: <a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a></span></div>
  <div style="margin-top: 16px; text-align: justify;"><span style="color: #505D6F; font-size: 13px; font-weight: normal; text-align: justify;">Bằng việc nhấn vào nút xác nhận dưới đây, TVV đã hiểu, đồng ý và cam kết thực hiện đầy đủ những nội dung quy định nêu trên. </span></div>
`

type IProps = {
  onClose: (isCustomerConfirmed: boolean) => void
  loading: boolean
}

function AgentConfirmDialog({ onClose, loading }: IProps) {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()
  const isMobileDevice = Layout.isMobileDevice
  const [enableContinue, setEnableContinue] = React.useState(false)

  function onSubmit(data: any) {
    onClose(data.isCustomerConfirmed)
  }

  if (isMobileDevice) {
    return (
      <CustomScrollView style={{ paddingLeft: 10, paddingRight: 5, paddingTop: 5 }}>
        <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, globalStyles.FullWidth]}>
          <Text style={[globalStyles.DialogTitle, globalStyles.Width100]}>{i18n.t('COMMON.PRIVACY_POLICY')}</Text>
          <Text style={[globalStyles.DialogTitle, globalStyles.Width100]}>{i18n.t('COMMON.FOR_AGENT')}</Text>
          <SizedBox height={30} />
        </View>
        <View style={styles.noticeContainer}>
          <RenderHtml html={content} onLinkPress={(evt, href) => { Linking.openURL(href) }} />
        </View>
        <View style={[globalStyles.FullWidth]}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange, onBlur, value }) => (
              <ToggleRadioButton
                title=""
                data={[
                  {
                    value: true,
                    text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                  },
                ]}
                direction="column"
                value={getValues('isCustomerConfirmed')}
                containerStyle={{ alignItems: 'flex-start' }}
                onChange={(value: any) => {
                  setValue('isCustomerConfirmed', value)
                  setEnableContinue(value === true)
                }}
                radioItemContainerStyle={{ maxWidth: 'none' }}
              />
            )}
            name="isCustomerConfirmed"
            defaultValue=""
          />
        </View>
        <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
          <FilledButton
            title={i18n.t('COMMON.CONFIRM')}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={globalStyles.CommonButton}
            titleStyle={{ fontSize: 14 }}
            disabled={!enableContinue}
            loading={loading}
          />
        </View>
        <SizedBox height={22} />
      </CustomScrollView>
    )
  }

  return (
    <CustomScrollView style={{ paddingLeft: 5, paddingTop: 10 }}>
      <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, globalStyles.FullWidth]}>
        <Text style={[globalStyles.DialogTitle, globalStyles.FullWidth]}>{i18n.t('COMMON.PRIVACY_POLICY')}</Text>
        <SizedBox height={10} />
        <Text style={[globalStyles.DialogTitle, globalStyles.FullWidth]}>{i18n.t('COMMON.FOR_AGENT')}</Text>
        <SizedBox height={30} />
      </View>
      <View style={styles.noticeContainer}>
        <RenderHtml html={content} onLinkPress={(evt, href) => { Linking.openURL(href) }} />
      </View>
      <View style={[globalStyles.FullWidth]}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, onBlur, value }) => (
            <ToggleRadioButton
              title=""
              data={[
                {
                  value: true,
                  text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                },
              ]}
              direction="column"
              value={getValues('isCustomerConfirmed')}
              containerStyle={{ alignItems: 'flex-start', marginTop: 10 }}
              onChange={(value: any) => {
                setValue('isCustomerConfirmed', value)
                setEnableContinue(value === true)
              }}
              radioItemContainerStyle={{ maxWidth: 'none' }}
            />
          )}
          name="isCustomerConfirmed"
          defaultValue=""
        />
      </View>
      <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
        <FilledButton
          title={i18n.t('COMMON.CONFIRM')}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={globalStyles.CommonButton}
          titleStyle={{ fontSize: 14 }}
          disabled={!enableContinue}
          loading={loading}
        />
      </View>
      <SizedBox height={22} />
    </CustomScrollView>
  )
}

export default AgentConfirmDialog

const styles = StyleSheet.create({
  inputComment: {
    backgroundColor: '#FAFBFF',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderRadius: 10,
    marginBottom: 20,
    padding: 16,
  },
  noticeContainer: {
    backgroundColor: '#00000000',
    textAlign: 'justify',
    // whiteSpace: 'initial',
  },
})
