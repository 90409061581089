import { AgentOffice } from './Enum'

export const xLargeScreenMediaConfig = {
  maxDeviceWidth: 4000,
  query: '(max-device-width: 4000px)',
}

export const laptopMediaConfig = {
  maxDeviceWidth: 1500,
  query: '(max-device-width: 1500px)',
}

export const laptopMediumMediaConfig = {
  maxDeviceWidth: 1300,
  query: '(max-device-width: 1300px)',
}

export const tabletMediaConfig = {
  maxDeviceWidth: 1194,
  query: '(max-device-width: 1194px)',
}

export const tabletMediumMediaConfig = {
  maxDeviceWidth: 1150,
  query: '(max-device-width: 1150px)',
}

export const tabletSmallMediaConfig = {
  maxDeviceWidth: 992,
  query: '(max-device-width: 992px)',
}

export const mobileMediaConfig = {
  maxDeviceWidth: 768,
  query: '(max-device-width: 768px)',
}

export const MAX_LOGIN_EFFORTS = 5

export const RESET_EFFORT_TIMES_IN_SECONDS = 1800

export const QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE = 'Không, tôi chưa từng'

export const DEFAULT_PRODUCT_TYPE_CODE = 'ULP'

export const APP_BAR_HEIGHT = 56

export const MIN_FATAL_DECEASE_VALUE = 100

export const MAX_MONTHLY_INCOME = 10000

export const MIN_NEED_TERM = 5

export const MIN_AGE = 18

export const MAX_AGE = 65

export const MIN_MATERNITY_AGE = 18

export const MAX_MATERNITY_AGE = 50

export const DEFAULT_PAGE_SIZE = 20

export const DEFAULT_PAGE_INDEX = 1

export const REGISTERED_AGENTS = [AgentOffice.VIB]
