import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image } from 'react-native'
import ic2PileOfCoin from '../../../assets/images/2-pile-of-coin.png'
import icPileOfCoin from '../../../assets/images/pile-of-coin.png'
import icHandWithCoin from '../../../assets/images/hand-with-coin.png'
import icTrend from '../../../assets/images/trend.png'
import icDefectClock from '../../../assets/images/defect-clock.png'
import icHandWith3Coin from '../../../assets/images/hand-with-3-coin.png'
import { Select, SizedBox, ValidateRequiredError } from '../../../common'
import i18n from 'i18n-js'
import Layout from '../../../constants/Layout'
import { FlatList, TextInput } from 'react-native-gesture-handler'
import Colors from '../../../common/constants/Colors'
import { styles as globalStyles } from '../../../common/constants/globalStyles'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { productTypes } from 'constants/Data'
import icDropDownLight from '../../../assets/images/dropdown-light.png'
import { MIN_NEED_TERM } from '../../../constants/Constants'

type IProps = {
  needTerm?: number
  productType?: string
  monthlyIncome?: number
  monthlyExpense?: number
  formControl?: any
  formRegister?: any
  errors?: any
  onChangeNeedTerm?: (value?: number | string) => void
  onChangeProductType?: (value?: number | string) => void
  onChangeMonthlyIncome?: (value?: number | string) => void
  onChangeMonthlyExpense?: (value?: number | string) => void
}

function SummaryInfo({
  needTerm,
  productType,
  monthlyIncome,
  monthlyExpense,
  formControl,
  formRegister,
  errors,
  onChangeNeedTerm,
  onChangeProductType,
  onChangeMonthlyIncome,
  onChangeMonthlyExpense,
}: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  function renderInfoItem(icon?: ImageSourcePropType, title?: string, child?: JSX.Element) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.infoItemContainer}>
          <View style={mobileStyles.infoItemTitleContainer}>
            <Image source={icon as ImageSourcePropType} style={mobileStyles.infoItemIcon} />
            <SizedBox width={16} />
            {title && <Text style={globalStyles.CommonTextTablet}>{title}</Text>}
          </View>
          <SizedBox height={10} />
          {child && <View style={[globalStyles.FullWidth, globalStyles.Flex1]}>{child}</View>}
        </View>
      )
    }
    if (isTabletDevice) {
      return (
        <View style={tabletStyles.infoItemContainer}>
          <View style={tabletStyles.infoItemTitleContainer}>
            <Image source={icon as ImageSourcePropType} style={tabletStyles.infoItemIcon} />
            <SizedBox width={16} />
            {title && <Text style={globalStyles.CommonTextTablet}>{title}</Text>}
          </View>
          {child && <View style={{ flex: 1 }}>{child}</View>}
        </View>
      )
    }
    return (
      <View style={desktopStyles.infoItemContainer}>
        <View style={desktopStyles.infoItemTitleContainer}>
          <Image source={icon as ImageSourcePropType} style={desktopStyles.infoItemIcon} />
          <SizedBox width={16} />
          {title && <Text style={globalStyles.CommonText}>{title}</Text>}
        </View>
        {child && <View style={{ flex: 1 }}>{child}</View>}
      </View>
    )
  }

  const InputNumberItem = ({
    hint,
    unit,
    value,
    strError,
    onChange,
    editable,
    formName,
    validateRules,
  }: {
    hint?: string
    unit?: string
    value?: any
    strError?: string
    onChange?: (s?: string | number | undefined) => void
    editable?: boolean
    formName?: string
    validateRules?: any
  }) => {
    if (isTabletDevice) {
      return (
        <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1]}>
          <View style={tabletStyles.inputBlockContainer}>
            <View style={tabletStyles.inputBlockContainerLeft}>
              {formName ? (
                <Controller
                  control={formControl}
                  rules={validateRules}
                  ref={formRegister}
                  render={({ onChange, onBlur, value }) => (
                    <NumberFormat
                      placeholder={hint}
                      style={{
                        width: '100%',
                        fontSize: 13,
                        fontWeight: 600,
                        borderWidth: 0,
                        borderColor: '#fff',
                        backgroundColor: 'transparent',
                        textAlign: 'end',
                      }}
                      disabled={!!!editable}
                      value={value}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={0}
                      type="text"
                      prefix={''}
                      onValueChange={(values: any, sourceInfo: any) => {
                        const { value } = values
                        onChange && onChange(value)
                      }}
                      renderText={(value) => (
                        <TextInput
                          underlineColorAndroid="transparent"
                          placeholderTextColor="#E0E0E0"
                          editable={editable}
                          onChangeText={(value) => {
                            onChange && onChange(value)
                          }}
                          value={value}
                          keyboardType="numeric"
                        />
                      )}
                    />
                  )}
                  name={formName ?? ''}
                />
              ) : (
                <NumberFormat
                  placeholder={hint}
                  style={{
                    width: '100%',
                    fontSize: 13,
                    fontWeight: 600,
                    borderWidth: 0,
                    borderColor: '#fff',
                    backgroundColor: 'transparent',
                    textAlign: 'end',
                  }}
                  disabled={!!!editable}
                  value={value}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={0}
                  type="text"
                  prefix={''}
                  onValueChange={(values: any, sourceInfo: any) => {
                    const { value } = values
                    onChange && onChange(parseFloat(value))
                  }}
                  renderText={(value) => (
                    <TextInput
                      underlineColorAndroid="transparent"
                      placeholderTextColor="#E0E0E0"
                      editable={editable}
                      onChangeText={(value) => {
                        onChange && onChange(parseFloat(value))
                      }}
                      value={value}
                      keyboardType="numeric"
                    />
                  )}
                />
              )}
            </View>
            <View style={tabletStyles.inputBlockContainerRight}>
              <Text style={tabletStyles.unit}>{unit}</Text>
            </View>
          </View>
          {strError && (
            <View style={globalStyles.FloatLeft}>
              <Text style={[globalStyles.ErrorText]}>{strError}</Text>
            </View>
          )}
        </View>
      )
    }

    return (
      <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1]}>
        <View style={desktopStyles.inputBlockContainer}>
          <View style={desktopStyles.inputBlockContainerLeft}>
            {formName ? (
              <Controller
                control={formControl}
                rules={validateRules}
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    placeholder={hint}
                    style={{
                      width: '100%',
                      fontSize: 15,
                      fontWeight: 600,
                      borderWidth: 0,
                      borderColor: '#fff',
                      backgroundColor: 'transparent',
                      textAlign: 'end',
                    }}
                    disabled={!!!editable}
                    value={value}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={3}
                    type="text"
                    prefix={''}
                    onValueChange={(values: any, sourceInfo: any) => {
                      const { value } = values
                      onChange && onChange(value)
                    }}
                    renderText={(value) => (
                      <TextInput
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#E0E0E0"
                        editable={editable}
                        onChangeText={(value) => {
                          onChange && onChange(value)
                        }}
                        value={value}
                        keyboardType="numeric"
                      />
                    )}
                  />
                )}
                name={formName}
              />
            ) : (
              <NumberFormat
                placeholder={hint}
                style={{
                  width: '100%',
                  fontSize: 15,
                  fontWeight: 600,
                  borderWidth: 0,
                  borderColor: '#fff',
                  backgroundColor: 'transparent',
                  textAlign: 'end',
                }}
                disabled={!!!editable}
                value={value}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={3}
                type="text"
                prefix={''}
                onValueChange={(values: any, sourceInfo: any) => {
                  const { value } = values
                  onChange && onChange(parseFloat(value))
                }}
                renderText={(value) => (
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholderTextColor="#E0E0E0"
                    editable={editable}
                    onChangeText={(value) => {
                      onChange && onChange(parseFloat(value))
                    }}
                    value={value}
                    keyboardType="numeric"
                  />
                )}
              />
            )}
          </View>
          <View style={desktopStyles.inputBlockContainerRight}>
            <Text style={desktopStyles.unit}>{unit}</Text>
          </View>
        </View>
        {strError && (
          <View style={globalStyles.FloatLeft}>
            <Text style={[globalStyles.ErrorText]}>{strError}</Text>
          </View>
        )}
      </View>
    )
  }

  const ProductTypeSelect = () => {
    return (
      <View style={desktopStyles.filterStatusContainer}>
        <Select
          selectOption={{
            options: productTypes.map((e, i) => {
              return {
                label: e.text,
                value: e.value,
              }
            }),
            style: desktopStyles.filterDropdownStatus,
            dropDownContainerStyle: desktopStyles.dropDownContainerStyle,
            listItemLabelStyle: desktopStyles.listItemLabelStyle,
            labelStyle: desktopStyles.labelStyle,
            placeholderStyle: desktopStyles.placeholderStyle,
            placeholder: i18n.t('NEEDS_OVERALL_EDIT_MODE.INVEST_EXPECTATION'),
            arrowUpIcon: <Image source={icDropDownLight} style={globalStyles.Icon12} />,
            arrowDownIcon: <Image source={icDropDownLight} style={globalStyles.Icon12} />,
            defaultValue: productType,
          }}
          onChange={(value?: any) => {
            onChangeProductType && onChangeProductType(value)
          }}
        />
      </View>
    )
  }

  const data = [
    {
      icon: icDefectClock,
      title: i18n.t('NEEDS_OVERALL_EDIT_MODE.JOIN_TERM'),
      child: (
        <InputNumberItem
          hint={i18n.t('COMMON.INPUT')}
          unit={i18n.t('COMMON.YEAR')}
          value={needTerm}
          strError={errors?.needTerm?.message}
          onChange={onChangeNeedTerm}
          editable={true}
          formName={'needTerm'}
          validateRules={{
            required: {
              value: true,
              message: i18n.t('VALIDATE.REQUIRED'),
            },
            min: {
              value: MIN_NEED_TERM,
              message: i18n.t('NEEDS_OVERALL_EDIT_MODE.MIN_PLAN_TERM'),
            },
          }}
        />
      ),
    },
    {
      icon: icTrend,
      title: i18n.t('NEEDS_OVERALL_EDIT_MODE.INVEST_EXPECTATION'),
      child: ProductTypeSelect(),
    },
    {
      icon: icPileOfCoin,
      title: i18n.t('NEEDS_OVERALL_EDIT_MODE.MONTHLY_INCOME'),
      child: (
        <InputNumberItem
          hint={i18n.t('COMMON.INPUT')}
          unit={i18n.t('COMMON.MILLION_VND')}
          value={monthlyIncome ?? 0}
          onChange={onChangeMonthlyIncome}
          editable={false}
        />
      ),
    },
    {
      icon: ic2PileOfCoin,
      title: i18n.t('NEEDS_OVERALL_EDIT_MODE.ANNUAL_INCOME'),
      child: (
        <InputNumberItem
          hint={i18n.t('COMMON.INPUT')}
          unit={i18n.t('COMMON.MILLION_VND')}
          value={(monthlyIncome ?? 0) * 12}
          onChange={(s?: string | number | undefined) => {}}
          editable={false}
        />
      ),
    },
    {
      icon: icHandWithCoin,
      title: i18n.t('NEEDS_OVERALL_EDIT_MODE.MONTHLY_EXPENSE'),
      child: (
        <InputNumberItem
          hint={i18n.t('COMMON.INPUT')}
          unit={i18n.t('COMMON.MILLION_VND')}
          value={monthlyExpense ?? 0}
          onChange={(s?: string | number | undefined) => {}}
          editable={false}
        />
      ),
    },
    {
      icon: icHandWith3Coin,
      title: i18n.t('NEEDS_OVERALL_EDIT_MODE.ANNUAL_EXPENSE'),
      child: (
        <InputNumberItem
          hint={i18n.t('COMMON.INPUT')}
          unit={i18n.t('COMMON.MILLION_VND')}
          value={(monthlyExpense ?? 0) * 12}
          onChange={(s?: string | number | undefined) => {}}
          editable={false}
        />
      ),
    },
  ]

  if (isMobileDevice) {
    return (
      <View style={mobileStyles.container}>
        <View style={[mobileStyles.contentContainer]}>
          <View style={mobileStyles.listContainer}>
            <FlatList
              numColumns={1}
              data={data}
              renderItem={({ item, index }) => renderInfoItem(item.icon, item.title, item.child)}
              CellRendererComponent={({ children, index, style, ...props }) => {
                // Handle z-index issue of select box
                const cellStyle = [
                  style,
                  {
                    width: '100%',
                    zIndex: data.length - index,
                    elevation: data.length - index,
                  },
                ]
                return (
                  <View style={cellStyle} index={index} {...props}>
                    {children}
                  </View>
                )
              }}
            />
          </View>
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={tabletStyles.container}>
        <Text style={tabletStyles.title}>{i18n.t('NEEDS_OVERALL_EDIT_MODE.NEEDS_INFO')}</Text>
        <SizedBox height={16} />
        <View style={[tabletStyles.contentContainer, globalStyles.Shadow]}>
          <View style={tabletStyles.listContainer}>
            <FlatList
              numColumns={2}
              data={data}
              renderItem={({ item, index }) => renderInfoItem(item.icon, item.title, item.child)}
              CellRendererComponent={({ children, index, style, ...props }) => {
                // Handle z-index issue of select box
                const cellStyle = [
                  style,
                  {
                    zIndex: data.length - index,
                    elevation: data.length - index,
                  },
                ]
                return (
                  <View style={cellStyle} index={index} {...props}>
                    {children}
                  </View>
                )
              }}
            />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={desktopStyles.container}>
      <Text style={desktopStyles.title}>{i18n.t('NEEDS_OVERALL_EDIT_MODE.NEEDS_INFO')}</Text>
      <SizedBox height={16} />
      <View style={[desktopStyles.contentContainer, globalStyles.Shadow]}>
        <View style={desktopStyles.listContainer}>
          <FlatList
            numColumns={2}
            data={data}
            renderItem={({ item, index }) => renderInfoItem(item.icon, item.title, item.child)}
            CellRendererComponent={({ children, index, style, ...props }) => {
              // Handle z-index issue of select box
              const cellStyle = [
                style,
                {
                  zIndex: data.length - index,
                  elevation: data.length - index,
                },
              ]
              return (
                <View style={cellStyle} index={index} {...props}>
                  {children}
                </View>
              )
            }}
          />
        </View>
      </View>
    </View>
  )
}

export default SummaryInfo

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 35,
  },
  listContainer: {
    width: '100%',
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    paddingHorizontal: 27,
    paddingVertical: 30,
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 18,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.mainTextColor,
  },
  title: {
    fontSize: 24,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    width: '50%',
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoItemTitleContainer: {
    flexDirection: 'row',
    minWidth: 210,
  },
  infoItemIcon: {
    width: 19,
    height: 19,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  filterStatusContainer: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    zIndex: 1,
  },
  itemSelect: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
  filterDropdownStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: '#FFF',
    height: 50,
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: Colors.borderColor,
    borderWidth: 1.5,
    width: '100%',
    paddingVertical: 0,
  },
  listItemLabelStyle: {
    fontSize: 14,
    fontWeight: 'normal',
    paddingVertical: 10,
  },
  labelStyle: {
    fontWeight: '500',
    fontSize: 14,
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 15,
    fontWeight: 'normal',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
  },
  listContainer: {
    width: '100%',
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    padding: 10,
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 16,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 13,
    color: Colors.mainTextColor,
  },
  title: {
    fontSize: 20,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    width: '50%',
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoItemTitleContainer: {
    flexDirection: 'row',
    minWidth: 210,
  },
  infoItemIcon: {
    width: 19,
    height: 19,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 13,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 13,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  filterStatusContainer: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    zIndex: 1,
  },
  itemSelect: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
  filterDropdownStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: '#FFF',
    height: 50,
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: Colors.borderColor,
    borderWidth: 1.5,
    width: '100%',
    paddingVertical: 0,
  },
  listItemLabelStyle: {
    fontSize: 13,
    fontWeight: 'normal',
    paddingVertical: 10,
  },
  labelStyle: {
    fontWeight: '500',
    fontSize: 13,
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 13,
    fontWeight: 'normal',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingVertical: 13,
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
  },
  listContainer: {
    width: '100%',
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 0,
    width: '100%',
  },
  infoItemTitleContainer: {
    flexDirection: 'row',
    minWidth: 210,
    paddingLeft: 8,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 8,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
})
