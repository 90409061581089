import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { CircleProgressIndicator, CustomScrollView, SizedBox } from '../../../common'
import { ProductType } from '../../../constants/Enum'
import { fatalDiseaseULP, fatalDiseaseILP, freefatalDiseaseULP, freefatalDiseaseILP } from '../../../constants/Data'
import Layout from '../../../constants/Layout'
import RenderHtml from 'react-native-render-html'

type IProps = {
  productType?: ProductType
  except?: boolean
}

function ListFatalDisease({ productType, except }: IProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (isMobileDevice) {
    if (productType === ProductType.ILP && except) {
      return (
        <View style={{ maxHeight: 550 }}>
          <SizedBox height={20} />
          <View style={[desktopStyles.heading]}>
            <Text style={mobilesStyles.headingText}>
              <RenderHtml html={fatalDiseaseULP?.name} />
            </Text>
          </View>
          <CustomScrollView
            style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
            containerStyle={{ padding: 0, height: '100%', flex: 1 }}
          >
            <View>
              {fatalDiseaseULP?.listULP.map((x: any, index: number) => {
                return (
                  <View style={[desktopStyles.listPathoLogical]} key={index}>
                    <View>
                      <Text style={[mobilesStyles.listPathoLogicalText]}>{x}</Text>
                    </View>
                  </View>
                )
              })}
            </View>
            <View style={mobilesStyles.infoContainer}>
              <View style={tabletStyles.fatalDeceaseContainer}>
                <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
                <SizedBox height={8} />
                <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                  - Số tiền bảo hiểm tối thiểu bệnh hiểm nghèo bằng 100 triệu đồng.
                </Text>
                <SizedBox height={4} />
                <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                  - Số tiền bảo hiểm tối đa của sản phẩm bổ trợ Bảo hiểm bệnh lý nghiêm trọng không vượt quá số tiền bảo
                  hiểm của sản phẩm chính.
                </Text>
                <SizedBox height={4} />
                <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                  - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                  Quy tắc, Điều khoản Sản phẩm Bảo hiểm bệnh lý nghiêm trọng
                </Text>
              </View>
            </View>
          </CustomScrollView>
          <SizedBox height={10} />
        </View>
      )
    } else if (productType === ProductType.ULP && except) {
      return (
        <View style={{ maxHeight: 550 }}>
           <SizedBox height={20} />
          <View style={[desktopStyles.heading]}>
            <Text style={mobilesStyles.headingText}>
              <RenderHtml html={fatalDiseaseILP[0]?.headerFixed} />
            </Text>
          </View>
          <CustomScrollView
            style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
            containerStyle={{ padding: 0, height: '100%', flex: 1 }}
          >
          <View>
            {fatalDiseaseILP.map((item: any, i: number) => {
              return (
                <>
                  {item?.header && (
                    <View style={[desktopStyles.heading]} key={i}>
                      <Text style={mobilesStyles.headingText}>
                        <RenderHtml html={item?.header} />
                      </Text>
                    </View>
                  )}
                  <View>
                    <Text style={mobilesStyles.smallHeading}>{item?.name}</Text>
                  </View>
  
                  {item.listFatal.map((x: any, index: number) => {
                    return (
                      <>
                        <View style={[desktopStyles.listPathoLogical]}>
                          <Text style={[mobilesStyles.listPathoLogicalGroup]}>{x?.title}</Text>
                        </View>
                        {x?.content.map((y: any, indexContent: number) => {
                          return (
                            <View style={[mobilesStyles.listPathoLogicalItem]} key={indexContent}>
                              <Text style={[mobilesStyles.listPathoLogicalText]}>{y}</Text>
                            </View>
                          )
                        })}
                      </>
                    )
                  })}
                </>
              )
            })}
            <View style={mobilesStyles.infoContainer}>
              <View style={tabletStyles.fatalDeceaseContainer}>
                <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
                <SizedBox height={8} />
                <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                  - Số tiền bảo hiểm tối thiểu bệnh hiểm nghèo bằng 100 triệu đồng.
                </Text>
                <SizedBox height={4} />
                <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                  - Số tiền bảo hiểm tối đa của sản phẩm bổ trợ Bảo hiểm bệnh lý nghiêm trọng không vượt quá số tiền bảo
                  hiểm của sản phẩm chính.
                </Text>
                <SizedBox height={4} />
                <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                  - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                  Quy tắc, Điều khoản Sản phẩm Bảo hiểm bệnh lý nghiêm trọng
                </Text>
              </View>
            </View>
          </View>
        </CustomScrollView>
        </View>
      )
    } else if (productType === ProductType.ULP && !except) {
      return (
        <View style={{ maxHeight: 550 }}>
          <SizedBox height={20} />
          <View style={[desktopStyles.heading]}>
            <Text style={mobilesStyles.headingText}>
              <RenderHtml html={freefatalDiseaseILP?.name} />
            </Text>
          </View>
          <CustomScrollView
            style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
            containerStyle={{ padding: 0, height: '100%', flex: 1 }}
          >
            <View>
              {freefatalDiseaseILP?.listFreeILP.map((x: any, index: number) => {
                return (
                  <View style={[desktopStyles.listPathoLogical]} key={index}>
                    <View style={[mobilesStyles.listPathoLogicalItem]}>
                      <Text style={[mobilesStyles.listPathoLogicalText]}>{x}</Text>
                    </View>
                  </View>
                )
              })}
              <View style={mobilesStyles.infoContainer}>
                <View style={tabletStyles.fatalDeceaseContainer}>
                  <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
                  <SizedBox height={8} />
                  <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                    - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                    Quy tắc, Điều khoản Sản phẩm Bảo hiểm miễn đóng phí bệnh lý nghiêm trọng.
                  </Text>
                </View>
              </View>
            </View>
          </CustomScrollView>
          <SizedBox height={10} />
        </View>
      )
    } else if (productType === ProductType.ILP && !except) {
      return (
        <View style={{ maxHeight: 550 }}>
          <SizedBox height={20} />
          <View style={[desktopStyles.heading]}>
            <Text style={mobilesStyles.headingText}>
              <RenderHtml html={freefatalDiseaseULP?.name} />
            </Text>
          </View>
          <CustomScrollView
            style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
            containerStyle={{ padding: 0, height: '100%', flex: 1 }}
          >
            <View>
              {freefatalDiseaseULP?.listFreeULP.map((x: any, index: number) => {
                return (
                  <View style={[desktopStyles.listPathoLogical]} key={index}>
                    <View style={[mobilesStyles.listPathoLogicalItem]}>
                      <Text style={[mobilesStyles.listPathoLogicalText]}>{x}</Text>
                    </View>
                  </View>
                )
              })}
              <View style={mobilesStyles.infoContainer}>
                <View style={tabletStyles.fatalDeceaseContainer}>
                  <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
                  <SizedBox height={8} />
                  <Text style={mobilesStyles.fatalDeceaseTooltipsContent}>
                    - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                    Quy tắc, Điều khoản Sản phẩm Bảo hiểm từ bỏ thu phí bệnh lý nghiêm trọng.
                  </Text>
                </View>
              </View>
            </View>
          </CustomScrollView>
          <SizedBox height={10} />
        </View>
      )
    }
  }
  if (productType === ProductType.ILP && except) {
    return (
      <View style={{ maxHeight: 550 }}>
        <SizedBox height={20} />
        <View style={[desktopStyles.heading]}>
          <Text style={desktopStyles.headingText}>
            <RenderHtml html={fatalDiseaseULP?.name} />
          </Text>
        </View>
        <CustomScrollView
          style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
          <View>
            {fatalDiseaseULP?.listULP.map((x: any, index: number) => {
              return (
                <View style={[desktopStyles.listPathoLogical]} key={index}>
                  <View style={[desktopStyles.listPathoLogicalItem]}>
                    <Text style={[desktopStyles.listPathoLogicalText]}>{x}</Text>
                  </View>
                </View>
              )
            })}
          </View>
          <View style={tabletStyles.infoContainer}>
            <View style={tabletStyles.fatalDeceaseContainer}>
              <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
              <SizedBox height={8} />
              <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                - Số tiền bảo hiểm tối thiểu bệnh hiểm nghèo bằng 100 triệu đồng.
              </Text>
              <SizedBox height={4} />
              <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                - Số tiền bảo hiểm tối đa của sản phẩm bổ trợ Bảo hiểm bệnh lý nghiêm trọng không vượt quá số tiền bảo
                hiểm của sản phẩm chính.
              </Text>
              <SizedBox height={4} />
              <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                Quy tắc, Điều khoản Sản phẩm Bảo hiểm bệnh lý nghiêm trọng
              </Text>
            </View>
          </View>
        </CustomScrollView>
        <SizedBox height={10} />
      </View>
    )
  } else if (productType === ProductType.ULP && except) {
    return (
      <View style={{ maxHeight: 550 }}>
         <SizedBox height={20} />
        <View style={[desktopStyles.heading]}>
          <Text style={desktopStyles.headingText}>
            <RenderHtml html={fatalDiseaseILP[0]?.headerFixed} />
          </Text>
        </View>
        <CustomScrollView
          style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
        <View>
          {fatalDiseaseILP.map((item: any, i: number) => {
            return (
              <>
                {item?.header && (
                  <View style={[desktopStyles.heading]} key={i}>
                    <Text style={desktopStyles.headingText}>
                      <RenderHtml html={item?.header} />
                    </Text>
                  </View>
                )}
                <View>
                  <Text style={desktopStyles.smallHeading}>{item?.name}</Text>
                </View>

                {item.listFatal.map((x: any, index: number) => {
                  return (
                    <>
                      <View style={[desktopStyles.listPathoLogical]}>
                        <Text style={[desktopStyles.listPathoLogicalGroup]}>{x?.title}</Text>
                      </View>
                      {x?.content.map((y: any, indexContent: number) => {
                        return (
                          <View style={[desktopStyles.listPathoLogicalItem]} key={indexContent}>
                            <Text style={[desktopStyles.listPathoLogicalText]}>{y}</Text>
                          </View>
                        )
                      })}
                    </>
                  )
                })}
              </>
            )
          })}
          <View style={tabletStyles.infoContainer}>
            <View style={tabletStyles.fatalDeceaseContainer}>
              <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
              <SizedBox height={8} />
              <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                - Số tiền bảo hiểm tối thiểu bệnh hiểm nghèo bằng 100 triệu đồng.
              </Text>
              <SizedBox height={4} />
              <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                - Số tiền bảo hiểm tối đa của sản phẩm bổ trợ Bảo hiểm bệnh lý nghiêm trọng không vượt quá số tiền bảo
                hiểm của sản phẩm chính.
              </Text>
              <SizedBox height={4} />
              <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                Quy tắc, Điều khoản Sản phẩm Bảo hiểm bệnh lý nghiêm trọng
              </Text>
            </View>
          </View>
        </View>
      </CustomScrollView>
      </View>
    )
  } else if (productType === ProductType.ULP && !except) {
    return (
      <View style={{ maxHeight: 550 }}>
        <SizedBox height={20} />
        <View style={[desktopStyles.heading]}>
          <Text style={desktopStyles.headingText}>
            <RenderHtml html={freefatalDiseaseILP?.name} />
          </Text>
        </View>
        <CustomScrollView
          style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
          <View>
            {freefatalDiseaseILP?.listFreeILP.map((x: any, index: number) => {
              return (
                <View style={[desktopStyles.listPathoLogical]} key={index}>
                  <View style={[desktopStyles.listPathoLogicalItem]}>
                    <Text style={[desktopStyles.listPathoLogicalText]}>{x}</Text>
                  </View>
                </View>
              )
            })}
            <View style={tabletStyles.infoContainer}>
              <View style={tabletStyles.fatalDeceaseContainer}>
                <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
                <SizedBox height={8} />
                <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                  - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                  Quy tắc, Điều khoản Sản phẩm Bảo hiểm miễn đóng phí bệnh lý nghiêm trọng.
                </Text>
              </View>
            </View>
          </View>
        </CustomScrollView>
        <SizedBox height={10} />
      </View>
    )
  } else if (productType === ProductType.ILP && !except) {
    return (
      <View style={{ maxHeight: 550 }}>
        <SizedBox height={20} />
        <View style={[desktopStyles.heading]}>
          <Text style={desktopStyles.headingText}>
            <RenderHtml html={freefatalDiseaseULP?.name} />
          </Text>
        </View>
        <CustomScrollView
          style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
          <View>
            {freefatalDiseaseULP?.listFreeULP.map((x: any, index: number) => {
              return (
                <View style={[desktopStyles.listPathoLogical]} key={index}>
                  <View style={[desktopStyles.listPathoLogicalItem]}>
                    <Text style={[desktopStyles.listPathoLogicalText]}>{x}</Text>
                  </View>
                </View>
              )
            })}
            <View style={tabletStyles.infoContainer}>
              <View style={tabletStyles.fatalDeceaseContainer}>
                <Text style={tabletStyles.fatalDeceaseTooltipsTitle}>Lưu ý</Text>
                <SizedBox height={8} />
                <Text style={tabletStyles.fatalDeceaseTooltipsContent}>
                  - Để biết thêm chi tiết về quyền lợi, điều kiện chi trả, điều khoản loại trừ, vui lòng tham khảo
                  Quy tắc, Điều khoản Sản phẩm Bảo hiểm từ bỏ thu phí bệnh lý nghiêm trọng.
                </Text>
              </View>
            </View>
          </View>
        </CustomScrollView>
        <SizedBox height={10} />
      </View>
    )
  } else {
    return (
      <View>
        <CircleProgressIndicator />
      </View>
    )
  }
}

export default ListFatalDisease

const desktopStyles = StyleSheet.create({
  container: {
    maxWidth: 784,
    maxHeight: 600,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    marginHorizontal: 30,
    marginBottom: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
    maxWidth: '80%',
    margin: 'auto',
    // whiteSpace: 'initial',
  },
  smallHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FC573B',
    marginTop: 10,
    marginBottom: 5,
  },
  listContent: {
    marginVertical: 20,
    width: '100%',
  },
  listPathoLogicalItem: {
    padding: 15,
  },

  listPathoLogicalGroup: {
    fontWeight: '700',
    fontSize: 16,
    color: '#373737',
    marginTop: 15,
  },
  listPathoLogicalText: {
    fontWeight: '700',
    fontSize: 16,
    paddingVertical: 13,
    borderBottomWidth: 1,
    borderBottomColor: '#E7E7E7',
    color: '#373737',
  },
  listPathoLogical: {},
})

const tabletStyles = StyleSheet.create({
  fatalDeceaseContainer: {
    flexDirection: 'column',
  },
  fatalDeceaseTooltipsTitle: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  fatalDeceaseTooltipsContent: {
    color: '#505D6F',
    fontSize: 15,
  },
  infoContainer: {
    paddingVertical: 23,
    paddingHorizontal: 56,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
    marginVertical: 10,
  },
  listPathoLogical: {},
})


const mobilesStyles = StyleSheet.create({
  headingText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
    maxWidth: '80%',
    margin: 'auto',
  },
  smallHeading: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#FC573B',
    marginTop: 10,
    marginBottom: 5,
  },
  infoContainer: {
    paddingVertical: 23,
    paddingHorizontal: 23,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
    marginVertical: 10,
  },
  listPathoLogicalText: {
    fontWeight: '700',
    fontSize: 12,
    paddingVertical: 13,
    borderBottomWidth: 1,
    borderBottomColor: '#E7E7E7',
    color: '#373737',
  },
  listPathoLogicalItem: {
    padding: 5
  },
  fatalDeceaseTooltipsContent: {
    color: '#505D6F',
    fontSize: 13,
  },
  listPathoLogicalGroup: {
    fontWeight: '700',
    fontSize: 13,
    color: '#373737',
    marginTop: 15,
  },
})

