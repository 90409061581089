import { API_PATH } from '../constants/Path'
import { authApiService } from './ApiService'

class SolutionService {
  async getChartData(data: any) {
    return await authApiService.post(API_PATH.CNA.GET_CHART_DATA, data)
  }
  async getTempApeData(data: any) {
    return await authApiService.post(API_PATH.CNA.GET_TEMP_APE_DATA, data)
  }
}

export default new SolutionService()
