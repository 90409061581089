import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, Modal, FlatList, TouchableOpacity } from 'react-native'
import icOrangeHeart from '../../../assets/images/orange-heart.png'
import icCircleCheck from '../../../assets/images/circle-check.png'
import Layout from '../../../constants/Layout'
import { vnNumberFormatter, Hoverable, SizedBox, styles as globalStyles, tabletMediaConfig } from '../../../common'
import { useMediaQuery } from 'react-responsive'
import i18n from 'i18n-js'
import icInfoRed from '../../../assets/images/info-red.png'

const cardWidth = 250

type IProps = {
  /**
   * Array of benefits
   *
   * title - name of benefit
   *
   * value - value of benefit (million vietnam dong)
   */
  data?: Array<{
    title: string
    value?: number
    show?: boolean
    isRequired?: boolean
    tooltips?: JSX.Element
    showPlaceholder?: boolean
    showReference?: boolean
  }>
  onShowPopupFatalDisease?: (title: string) => void
  style?: Record<string, unknown>
}

function SummaryMainBenefit({ data, style ,onShowPopupFatalDisease}: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const [tooltipsData, setTooltipData] = React.useState<{ index: number; x: any; y: any }>()
  const [hoveringTooltips, setHoveringTooltips] = React.useState<boolean>()

  React.useEffect(() => {
    if (!hoveringTooltips) {
      onTurnOffTooltips()
    }
  }, [hoveringTooltips])

  function getColumnCount() {
    const ratio = Layout.window.width / 2 / cardWidth
    if (ratio < 2) {
      return 1
    }
    return Math.round(ratio)
  }

  function onTurnOffTooltips() {
    setTimeout(() => {
      if (!hoveringTooltips) {
        setTooltipData(undefined)
      }
    }, 100)
  }

  function renderItem(
    index: number,
    title: string,
    value: number | undefined,
    show?: boolean,
    isRequired?: boolean,
    tooltips?: JSX.Element,
    showPlaceholder?: boolean,
    showReference?: boolean
  ) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.infoItemContainer}>
        <View style={mobileStyles.infoItemTitleContainer}>

          <Image source={icCircleCheck} style={mobileStyles.infoItemIcon} />
          <Text style={mobileStyles.infoItemTitle}>{title}</Text>
          </View>
        <View style={desktopStyles.titleContainer}>
          {show ? (
            <Text style={mobileStyles.infoItemValue}>
              {value ? `${vnNumberFormatter.format(value)} ${i18n.t('COMMON.MILLION')}` :  showPlaceholder || !showReference ? '-' : ''}
            </Text>
          ) : (
            <Text style={mobileStyles.infoItemValue}>-</Text>
          )}
          {showReference && 
                <>
                  <SizedBox width={4} />
                  <Image source={icInfoRed as ImageSourcePropType} style={desktopStyles.infoIcon} />
                  <SizedBox width={4} />
                  <TouchableOpacity onPress={() => onShowPopupFatalDisease &&  onShowPopupFatalDisease(title)}>
          <Text style={[globalStyles.ErrorText, { fontSize: 10, marginTop: 0 }]}>(Tham khảo)</Text>
          </TouchableOpacity>
                </>   
              }
              </View>
          
        </View>
      )
    }
    return (
      <Hoverable
        onHoverIn={(e: any) => {
          if (tooltips) {
            var rect = e.target.getBoundingClientRect()
            setTooltipData({ index: index, x: rect?.left, y: -30 })
            setHoveringTooltips(true)
          }
        }}
        onHoverOut={(e: any) => {
          if (tooltips) {
            setHoveringTooltips(false)
          }
        }}
      >
        {(isHovered: boolean) => (
          <View style={desktopStyles.infoItemContainer}>
            <Image style={desktopStyles.infoItemIcon} source={icCircleCheck as ImageSourcePropType} />
            <View style={desktopStyles.infoItemTitleContainer}>
              <Text style={desktopStyles.infoItemTitle}>{title}</Text>
              <Text style={globalStyles.ErrorText}>{isRequired ? '*' : ''}</Text>
            
            </View>
            <View style={desktopStyles.titleContainer}>
            {show && (
              <Text style={desktopStyles.infoItemValue}>
                {value && value > 0
                  ? `${vnNumberFormatter.format(value)} ${i18n.t('COMMON.MILLION')}`
                  : showPlaceholder || !showReference
                  ? '-'
                  : ''}
              </Text>
            )}
             {showReference && 
                <>
                  <SizedBox width={4} />
                  <Image source={icInfoRed as ImageSourcePropType} style={desktopStyles.infoIcon} />
                  <SizedBox width={4} />
                  <TouchableOpacity onPress={() => onShowPopupFatalDisease &&  onShowPopupFatalDisease(title)}>
          <Text style={[globalStyles.ErrorText, { fontSize: 10, marginTop: 0 }]}>(Tham khảo)</Text>
          </TouchableOpacity>
                </>
                
              }
              </View>
          </View>
        )}
      </Hoverable>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container, style]}>
        <View style={mobileStyles.titleContainer}>
          <Image source={icOrangeHeart} style={mobileStyles.iconTitle} />
          <Text style={mobileStyles.title}>{i18n.t('SOLUTION.MAIN_BENEFIT')}</Text>
        </View>
        <View style={mobileStyles.infoContainer}>
          {data?.map((item, i) => {
            return (
              <View key={i} style={{ width: `${100 / getColumnCount()}%` }}>
                {renderItem(i, item.title, item.value, item.show, item.isRequired, item.tooltips, item.showPlaceholder, item.showReference)}
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={[tabletStyles.container, style]}>
        <View style={tabletStyles.titleContainer}>
          <Image source={icOrangeHeart as ImageSourcePropType} style={tabletStyles.iconTitle} />
          <Text style={tabletStyles.title}>{i18n.t('SOLUTION.MAIN_BENEFIT')}</Text>
        </View>
        <View style={tabletStyles.infoContainer}>
          <FlatList
            data={data}
            renderItem={(item) => {
              return (
                <View key={item.index} style={{ width: `${100 / getColumnCount()}%` }}>
                  {renderItem(
                    item.index,
                    item.item.title,
                    item.item.value,
                    item.item.show,
                    item.item.isRequired,
                    item.item.tooltips,
                    item.item.showPlaceholder,
                    item.item.showReference
                  )}
                </View>
              )
            }}
            numColumns={getColumnCount()}
          />
          {((tooltipsData && tooltipsData.index && data && data[tooltipsData.index]) || hoveringTooltips) && (
            <Hoverable
              onHoverIn={() => {
                setHoveringTooltips(true)
              }}
              onHoverOut={() => {
                setHoveringTooltips(false)
              }}
            >
              <View style={[tabletStyles.tooltipContainer, { top: tooltipsData?.y, left: tooltipsData?.x }]}>
                {tooltipsData && tooltipsData.index && data && data[tooltipsData.index]
                  ? data[tooltipsData.index].tooltips
                  : null}
              </View>
            </Hoverable>
          )}
        </View>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Image source={icOrangeHeart} style={desktopStyles.iconTitle} />
        <Text style={desktopStyles.title}>{i18n.t('SOLUTION.MAIN_BENEFIT')}</Text>
      </View>
      <View style={desktopStyles.infoContainer}>
        <FlatList
          data={data}
          renderItem={(item) => {
            return (
              <View key={item.index} style={{ width: '50%' }}>
                {renderItem(
                  item.index,
                  item.item.title,
                  item.item.value,
                  item.item.show,
                  item.item.isRequired,
                  item.item.tooltips,
                  item.item.showPlaceholder,
                  item.item.showReference
                )}
              </View>
            )
          }}
          numColumns={2}
        />
        {((tooltipsData && tooltipsData.index && data && data[tooltipsData.index]) || hoveringTooltips) && (
          <Hoverable
            onHoverIn={() => {
              setHoveringTooltips(true)
            }}
            onHoverOut={() => {
              setHoveringTooltips(false)
            }}
          >
            <View style={[desktopStyles.tooltipContainer, { top: tooltipsData?.y, left: tooltipsData?.x }]}>
              {tooltipsData && tooltipsData.index && data && data[tooltipsData.index]
                ? data[tooltipsData.index].tooltips
                : null}
            </View>
          </Hoverable>
        )}
      </View>
    </View>
  )
}

export default SummaryMainBenefit

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 18.14,
    zIndex: 1000,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 20,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 28.19,
    paddingHorizontal: 31.51,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 24.5,
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 50,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitleContainer: {
    marginLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  tooltipContainer: {
    position: 'absolute',
    zIndex: 10000,
    top: -25,
    left: 60,
    paddingVertical: 23,
    paddingHorizontal: 56,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  infoIcon: {
    width: 10,
    height: 10,
    resizeMode: 'contain',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 18.14,
    zIndex: 1000,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  infoItemTitleContainer: {
    marginLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  title: {
    fontSize: 16.5,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 28.19,
    paddingHorizontal: 31.51,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 24.5,
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 50,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
    flex: 1,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  tooltipContainer: {
    position: 'absolute',
    zIndex: 10000,
    top: -25,
    left: 60,
    paddingVertical: 23,
    paddingHorizontal: 56,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 14,
    paddingVertical: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 30,
    height: 30,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '700',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 24.5,
    flexWrap: 'wrap',
  },
  infoItemContainer: {
    paddingVertical: 9,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  infoItemTitle: {
    flex: 1,
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
    minWidth: 90,
  },
})
