import * as React from 'react'
import { ActivityIndicator, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'

export type TextButtonProps = {
  title: string
  onPress?: () => void
  buttonStyle?: Record<string, unknown>
  labelStyle?: Record<string, unknown>
  loading?: boolean
  prefix?: JSX.Element
  suffix?: JSX.Element
}

export function TextButton({ title, buttonStyle, prefix, suffix, labelStyle, onPress, loading }: TextButtonProps) {
  return (
    <TouchableOpacity style={[styles.button, buttonStyle]} onPress={onPress}>
      {loading && (
        <View style={{ marginRight: 8 }}>
          <ActivityIndicator color="#ED1B2E" />
        </View>
      )}
      {prefix && <View style={{ marginRight: 8 }}>{prefix}</View>}
      <Text style={[styles.label, labelStyle]}>{title}</Text>
      {suffix && <View style={{ marginLeft: 8 }}>{suffix}</View>}
    </TouchableOpacity>
  )
}

export default TextButton

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#ED1B2E',
  },
})
