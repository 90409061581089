import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authenReducer from './slices/authenSlice'
import needsCartReducer from './slices/needsCartSlice'
import needsOverallReducer from './slices/needsOverallSlice'
import safeLifeReducer from './slices/safeLifeSlice'
import advisorSliceReducer from './slices/advisorSlice'
import protectIncomeReducer from './slices/protectIncomeSlice'
import cnaListReducer from './slices/cnaListSlice'
import charityReducer from './slices/charitySlice'
import eduPlanReducer from './slices/eduPlanSlice'
import hcFatalDiseaseReducer from './slices/hcFatalDiseaseSlice'
import hcPlanReducer from './slices/hcPlanSlice'
import inheritReducer from './slices/inheritSlice'
import investmentReducer from './slices/investmentSlice'
import protectAssetReducer from './slices/protectAssetSlice'
import protectEduReducer from './slices/protectEduSlice'
import protectLifeReducer from './slices/protectLifeSlice'
import retirementReducer from './slices/retirementSlice'
import userReducer from './slices/userSlice'
import cnaReducer from './slices/cnaSlice'
import commonReducer from './slices/commonSlice'
import genPdfReducer from './slices/genPdfSlice'
import solutionReducer from './slices/solutionSlice'
import saveFormReducer from './slices/saveFormSlice'
import viewAllCnaReducer from './slices/viewAllCnaSlice'

export const store = configureStore({
  reducer: {
    authen: authenReducer,
    needsCart: needsCartReducer,
    needsOverall: needsOverallReducer,
    safeLife: safeLifeReducer,
    advisor: advisorSliceReducer,
    protectIncome: protectIncomeReducer,
    charity: charityReducer,
    eduPlan: eduPlanReducer,
    hcFatalDisease: hcFatalDiseaseReducer,
    hcPlan: hcPlanReducer,
    inherit: inheritReducer,
    investment: investmentReducer,
    protectAsset: protectAssetReducer,
    protectEdu: protectEduReducer,
    protectLife: protectLifeReducer,
    retirement: retirementReducer,
    cnaList: cnaListReducer,
    user: userReducer,
    cna: cnaReducer,
    common: commonReducer,
    genPdf: genPdfReducer,
    solution: solutionReducer,
    saveForm: saveFormReducer,
    viewAllCna: viewAllCnaReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
