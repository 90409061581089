import React from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'
import i18n from 'i18n-js'
import { REGISTERED_AGENTS } from '../constants/Constants'
import { AgentOffice } from '../constants/Enum'

export type AgentWordingProps = {
  agentOfficeCode?: string
  wordingCode?: string
  style?: StyleProp<TextStyle>
}

export const i18nAgentWording = ({ agentOfficeCode, wordingCode }: AgentWordingProps) => {
  if (agentOfficeCode && REGISTERED_AGENTS.includes(agentOfficeCode as AgentOffice) && wordingCode) {
    return i18n.t(`${wordingCode}_${agentOfficeCode}`, { defaultValue: i18n.t(wordingCode, { defaultValue: '' }) })
  }
  return i18n.t(wordingCode || '', { defaultValue: '' })
}

const AgentWording = ({ agentOfficeCode, wordingCode, style }: AgentWordingProps) => {
  return <Text style={style}>{i18nAgentWording({ agentOfficeCode, wordingCode })}</Text>
}

export default AgentWording
