import React, { MutableRefObject } from 'react'
import { StyleSheet, View, Text, Animated } from 'react-native'
import { CustomScrollView, Expandable, Line, SizedBox, styles as globalStyles } from '../common'
import { beneficiaryNotes, tableBeneficiary, beneficiaryNotesTerm } from '../constants/Data'
import Layout from '../constants/Layout'
import RenderHtml from 'react-native-render-html'
import imgArrowRightCircleRed from '../assets/images/arrow-right-circle-red.png'
import i18n from 'i18n-js'

type IProps = {}

function BeneficiaryTable({}: IProps) {
  const isMobileDevice = Layout.isMobileDevice

  const expandableRefs = [React.useRef<any>(), React.useRef<any>(), React.useRef<any>(), React.useRef<any>()]

  const [isExpandingData, setIsExpandingData] = React.useState(false)

  const expandPopUpHeightAnim = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    Animated.timing(expandPopUpHeightAnim, {
      toValue: isExpandingData ? 550 : 280,
      duration: 300,
      useNativeDriver: false,
    } as Animated.TimingAnimationConfig).start()
  }, [isExpandingData])

  function renderBeneficiaryGroup(
    item: {
      groupTitle: string
      helpText: string
      content: {
        title: string
        contentA: string
        contentB: string
        contentC: string
        contentD: string
      }[]
    },
    index: number,
    option?: {
      renderA?: boolean
      renderB?: boolean
      renderC?: boolean
      renderD?: boolean
    },
  ) {
    if (isMobileDevice) {
      return (
        <View key={index} style={mobileStyles.groupItemContainer}>
          {/* Group title */}
          <View style={[mobileStyles.groupItemTitleContainer]}>
            <View style={[globalStyles.FullWidth, globalStyles.Center]}>
              <RenderHtml html={item.groupTitle} />
              {item?.helpText && <RenderHtml html={item?.helpText} />}
            </View>
          </View>
          {/* Group content */}
          <View style={[mobileStyles.groupItemContentContainer]}>
            {item.content.map((e, i) => {
              return (
                <View key={i} style={[mobileStyles.itemContentContainer]}>
                  <View style={mobileStyles.titleContainer}>
                    <View style={mobileStyles.itemRowTitleContainer}>
                      <RenderHtml html={e.title} />
                    </View>
                  </View>
                  <View style={mobileStyles.contentContainer}>
                    <View style={mobileStyles.contentRowContainer}>
                      {option?.renderA && (
                        <View style={mobileStyles.contentItemContainer}>
                          <RenderHtml html={e.contentA} />
                        </View>
                      )}
                      {option?.renderB && (
                        <View style={mobileStyles.contentItemContainer}>
                          <RenderHtml html={e.contentB} />
                        </View>
                      )}
                      {option?.renderC && (
                        <View style={mobileStyles.contentItemContainer}>
                          <RenderHtml html={e.contentC} />
                        </View>
                      )}
                      {option?.renderD && (
                        <View style={mobileStyles.contentItemContainer}>
                          <RenderHtml html={e.contentD} />
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      )
    }

    return (
      <View key={index} style={desktopStyles.groupItemContainer}>
        {/* Group title */}
        <View style={[desktopStyles.groupItemTitleContainer]}>
          <View style={desktopStyles.titleContainer}></View>
          <View style={desktopStyles.contentContainer}>
            <View style={desktopStyles.groupTitleContainer}>
              <RenderHtml html={item.groupTitle} />
              {item?.helpText && <RenderHtml html={item?.helpText} />}
            </View>
          </View>
        </View>
        {/* Group content */}
        <View style={[desktopStyles.groupItemContentContainer]}>
          {item.content.map((e, i) => {
            return (
              <View  key={i} style={[desktopStyles.itemContentContainer]}>
                <View style={desktopStyles.titleContainer}>
                  <View style={desktopStyles.itemRowTitleContainer}>
                    <RenderHtml html={e.title} />
                  </View>
                </View>
                <View style={desktopStyles.contentContainer}>
                  <View style={desktopStyles.contentRowContainer}>
                    <View style={desktopStyles.contentItemContainer}>
                      <RenderHtml html={e.contentA} />
                    </View>
                    <View style={desktopStyles.contentItemContainer}>
                      <RenderHtml html={e.contentB} />
                    </View>
                    <View style={desktopStyles.contentItemContainer}>
                      <RenderHtml html={e.contentC} />
                    </View>
                    <View style={desktopStyles.contentItemContainer}>
                      <RenderHtml html={e.contentD} />
                    </View>
                  </View>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  const ExpandableListTitle = ({
    title,
    titleStyle,
    subTitle,
    children,
    containerStyle,
    height,
    reference,
    index,
  }: {
    title: string
    titleStyle: Record<string, unknown>
    subTitle?: string
    children: JSX.Element
    containerStyle: Record<string, unknown>
    height: number
    reference: MutableRefObject<any>
    index: number
  }) => {
    return (
      <Expandable
        ref={reference}
        header={
          <View style={globalStyles.DisplayFlexCol}>
            <Text style={globalStyles.MobileTitle}>{title}</Text>
            {/* Subtite gonna here */}
            {/* <SizedBox height={8} /> */}
            {/* <Text style={globalStyles.MobileSubTitle}>{subTitle}</Text> */}
          </View>
        }
        headerStyle={titleStyle}
        expandable={children}
        expandIcon={imgArrowRightCircleRed}
        expandIconStyle={{ width: 20, height: 20 }}
        containerStyle={containerStyle}
        height={height}
        onExpand={() => {
          expandableRefs.forEach((e, i) => {
            if (index !== i) {
              ;(expandableRefs[i].current as any)?.collapseExpandableView()
            } else {
              if ((expandableRefs[i].current as any)?.getExpandedState()) {
                setIsExpandingData(false)
                ;(expandableRefs[i].current as any)?.collapseExpandableView()
              } else {
                setTimeout(() => {
                  setIsExpandingData(true)
                }, 200)
                setTimeout(() => {
                  ;(expandableRefs[i].current as any)?.expandExpandableView()
                }, 400)
              }
            }
          })
        }}
      />
    )
  }

  if (isMobileDevice) {
    return (
      <Animated.View style={{ maxHeight: expandPopUpHeightAnim }}>
        <SizedBox height={20} />
        <CustomScrollView
          style={{ paddingLeft: 20, paddingRight: 15, paddingTop: 0 }}
          containerStyle={{ height: '100%', flex: 1, paddingTop: 0 }}
        >
          <View>
            <ExpandableListTitle
              reference={expandableRefs[0]}
              index={0}
              title={i18n.t('BENEFICIARY.A')}
              titleStyle={{
                flex: 1,
                color: '#373737',
                fontFamily: 'Noto Sans',
                paddingHorizontal: 20,
                paddingVertical: 12,
                backgroundColor: '#F5E6FE',
              }}
              children={
                <View>
                  {tableBeneficiary.map((item: any, index: number) => {
                    return renderBeneficiaryGroup(item, index, { renderA: true })
                  })}
                  <RenderHtml html={beneficiaryNotes} />
                  <RenderHtml html={beneficiaryNotesTerm} />
                </View>
              }
              containerStyle={mobileStyles.listTitleContainer}
              height={3800}
            />
            <SizedBox height={16} />
            <ExpandableListTitle
              reference={expandableRefs[1]}
              index={1}
              title={i18n.t('BENEFICIARY.B')}
              titleStyle={{
                flex: 1,
                color: '#373737',
                fontFamily: 'Noto Sans',
                paddingHorizontal: 20,
                paddingVertical: 12,
                backgroundColor: '#FFF9DD',
              }}
              children={
                <View>
                  {tableBeneficiary.map((item: any, index: number) => {
                    return renderBeneficiaryGroup(item, index, { renderB: true })
                  })}
                  <RenderHtml html={beneficiaryNotes} />
                  <RenderHtml html={beneficiaryNotesTerm} />
                </View>
              }
              containerStyle={mobileStyles.listTitleContainer}
              height={3800}
            />
            <SizedBox height={16} />
            <ExpandableListTitle
              reference={expandableRefs[2]}
              index={2}
              title={i18n.t('BENEFICIARY.C')}
              subTitle={i18n.t('BENEFICIARY.C_SUB')}
              titleStyle={{
                flex: 1,
                color: '#373737',
                fontFamily: 'Noto Sans',
                paddingHorizontal: 20,
                paddingVertical: 12,
                backgroundColor: '#E3F8FA',
              }}
              children={
                <View>
                  {tableBeneficiary.map((item: any, index: number) => {
                    return renderBeneficiaryGroup(item, index, { renderC: true })
                  })}
                  <RenderHtml html={beneficiaryNotes} />
                  <RenderHtml html={beneficiaryNotesTerm} />
                </View>
              }
              containerStyle={mobileStyles.listTitleContainer}
              height={3800}
            />
            <SizedBox height={16} />
            <ExpandableListTitle
              reference={expandableRefs[3]}
              index={3}
              title={i18n.t('BENEFICIARY.D')}
              titleStyle={{
                flex: 1,
                color: '#373737',
                fontFamily: 'Noto Sans',
                paddingHorizontal: 20,
                paddingVertical: 12,
                backgroundColor: '#DFF3E7',
              }}
              children={
                <View>
                  {tableBeneficiary.map((item: any, index: number) => {
                    return renderBeneficiaryGroup(item, index, { renderC: true })
                  })}
                  <RenderHtml html={beneficiaryNotes} />
                  <RenderHtml html={beneficiaryNotesTerm} />
                </View>
              }
              containerStyle={mobileStyles.listTitleContainer}
              height={3800}
            />
          </View>
        </CustomScrollView>
        <SizedBox height={20} />
      </Animated.View>
    )
  }

  return (
    <View style={{ maxHeight: 550 }}>
      <SizedBox height={20} />
      <View style={{ paddingLeft: 30, paddingRight: 30 }}>
        <View style={[desktopStyles.itemContentContainer]}>
          <View style={desktopStyles.titleContainer}>
            <View style={desktopStyles.itemRowTitleContainer}></View>
          </View>
          <View style={desktopStyles.contentContainer}>
            <View style={[desktopStyles.contentRowContainer, { paddingVertical: 0 }]}>
              <View
                style={[
                  desktopStyles.contentItemContainer,
                  { backgroundColor: '#F5E6FE', paddingVertical: 20, marginLeft: 4 },
                ]}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#BE63F9',
                  }}
                >
                  {i18n.t('BENEFICIARY.A')}
                </Text>
              </View>
              <View
                style={[
                  desktopStyles.contentItemContainer,
                  { backgroundColor: '#FFF9DD', paddingVertical: 20, marginLeft: 4 },
                ]}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#FF9900',
                  }}
                >
                  {i18n.t('BENEFICIARY.B')}
                </Text>
              </View>
              <View
                style={[
                  desktopStyles.contentItemContainer,
                  { backgroundColor: '#E3F8FA', paddingVertical: 20, marginLeft: 4 },
                ]}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#26C6DA',
                  }}
                >
                  {i18n.t('BENEFICIARY.C')}
                </Text>
              </View>
              <View
                style={[
                  desktopStyles.contentItemContainer,
                  { backgroundColor: '#DFF3E7', paddingVertical: 20, marginLeft: 4 },
                ]}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#27AE60',
                  }}
                >
                  {i18n.t('BENEFICIARY.D')}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Line lineStyle="solid" orientation="horizontal" />
      <CustomScrollView
        style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
        containerStyle={{ padding: 0, height: '100%', flex: 1 }}
      >
        <View>
          <>
            {tableBeneficiary.map((item: any, index: number) => {
              return renderBeneficiaryGroup(item, index)
            })}
            <RenderHtml html={beneficiaryNotes} />
            <RenderHtml html={beneficiaryNotesTerm} />
          </>
        </View>
      </CustomScrollView>
      <SizedBox height={30} />
    </View>
  )
}

export default BeneficiaryTable

const desktopStyles = StyleSheet.create({
  container: {
    maxWidth: 784,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
  },
  groupItemContainer: {},
  groupItemTitleContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    borderColor: '#ECF0FF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
  },
  titleContainer: {
    flex: 25,
  },
  itemRowTitleContainer: {
    backgroundColor: '#ECF0FF',
    borderWidth: 1,
    borderColor: '#ECF0FF',
    paddingHorizontal: 10,
    paddingVertical: 6,
    height: '100%',
  },
  contentContainer: {
    flex: 75,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  contentItemContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#373737',
    fontFamily: 'Noto Sans',
    paddingHorizontal: 16,
  },
  groupTitleContainer: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  groupItemContentContainer: {},
  contentRowContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingVertical: 8,
    alignItems: 'stretch',
  },
  itemContentContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: '#E7E7E7',
    display: 'flex',
  },
})

const mobileStyles = StyleSheet.create({
  listTitleContainer: {
    backgroundColor: '#FAFBFF',
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  listTitleHeaderContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#373737',
    fontFamily: 'Noto Sans',
    marginLeft: 4,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  container: {
    maxWidth: 784,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
  },
  groupItemContainer: {},
  groupItemTitleContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    borderColor: '#ECF0FF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
  },
  titleContainer: {
    flex: 60,
  },
  itemRowTitleContainer: {
    backgroundColor: '#ECF0FF',
    borderWidth: 1,
    borderColor: '#ECF0FF',
    paddingHorizontal: 10,
    paddingVertical: 6,
    height: '100%',
  },
  contentContainer: {
    flex: 40,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  contentItemContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#373737',
    fontFamily: 'Noto Sans',
    marginLeft: 4,
  },
  groupTitleContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  groupItemContentContainer: {},
  contentRowContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingVertical: 8,
    alignItems: 'stretch',
  },
  itemContentContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: '#E7E7E7',
    display: 'flex',
  },
})
