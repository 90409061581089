import Layout from '../../constants/Layout'
import * as React from 'react'
import { Text, View, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'

export type FilledButtonProps = {
  type?: 'large' | 'small'
  title?: string
  buttonStyle?: Record<string, unknown>
  titleStyle?: Record<string, unknown>
  prefix?: JSX.Element
  suffix?: JSX.Element
  loading?: boolean
  disabled?: boolean
  onPress?: () => void
}

export function FilledButton({
  title,
  buttonStyle,
  titleStyle,
  prefix,
  suffix,
  loading,
  disabled,
  type,
  onPress,
}: FilledButtonProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (isMobileDevice || type === 'small') {
    return (
      <TouchableOpacity
        disabled={disabled || loading}
        onPress={onPress}
        style={[
          mobileStyles.button,
          buttonStyle,
          disabled ? { backgroundColor: '#BDBDBD', shadowColor: '#BDBDBD' } : {},
        ]}
      >
        {loading ? (
          <View style={{ marginRight: 8 }}>
            <ActivityIndicator color="#FFFFFF" />
          </View>
        ) : prefix ? (
          <View style={{ marginRight: 8 }}>{prefix}</View>
        ) : (
          <></>
        )}

        <Text style={[mobileStyles.title, titleStyle, disabled ? { color: '#FFF' } : {}]}>{title}</Text>
        {suffix && <View style={{ marginLeft: 8 }}>{suffix}</View>}
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      disabled={disabled || loading}
      onPress={onPress}
      style={[
        desktopStyles.button,
        buttonStyle,
        disabled ? { backgroundColor: '#BDBDBD', shadowColor: '#BDBDBD' } : {},
      ]}
    >
      {loading ? (
        <View style={{ marginRight: 8 }}>
          <ActivityIndicator color="#FFFFFF" />
        </View>
      ) : prefix ? (
        <View style={{ marginRight: 8 }}>{prefix}</View>
      ) : (
        <></>
      )}
      <Text style={[desktopStyles.title, titleStyle, disabled ? { color: '#FFF' } : {}]}>{title}</Text>
      {suffix && <View style={{ marginLeft: 8 }}>{suffix}</View>}
    </TouchableOpacity>
  )
}

export default FilledButton

const desktopStyles = StyleSheet.create({
  button: {
    height: 50,
    flexDirection: 'row',
    marginTop: 40,
    paddingHorizontal: 30,
    alignItems: 'center',
    display: 'flex',
    padding: 20,
    backgroundColor: '#ED1B2E',
    borderRadius: 20,
    color: '#fff',
    shadowColor: '#ED1B2E',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 5,
    justifyContent: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
})

const mobileStyles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    marginTop: 40,
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#ED1B2E',
    color: '#fff',
    shadowColor: '#ED1B2E',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 5,
    justifyContent: 'center',
    height: 41,
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderRadius: 12,
    fontSize: 14,
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 14,
  },
})
