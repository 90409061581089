// /constants/globalStyles.js
import { FontSizeValue } from '../../helper/UIHelper'
import { StyleSheet } from 'react-native'
import Colors from './Colors'

export const styles = StyleSheet.create({
  mobileContainerSafeArea: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'space-between',
    backgroundColor: '#D62828',
  },
  mobileContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  mobileContent: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
    backgroundColor: '#F7F8FA',
  },
  content: {
    backgroundColor: '#F7F8FA',
    top: 60,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingTop: 12,
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  contentMobile: {
    backgroundColor: '#F7F8FA',
    top: 60,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    overflow: 'scroll',
    paddingTop: 12,
    paddingHorizontal: 10,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    backgroundColor: '#F7F8FA',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  TaskBox: {
    flex: 1,
    color: '#333',
    fontSize: 16,
    backgroundColor: '#26c6da',
  },
  CheckBox: {
    borderColor: '#26c6da',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 1,
    backgroundColor: 'transparent',
    height: 18,
    width: 18,
  },
  GlowCheckbox: {
    borderColor: '#eee',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 1,
    backgroundColor: '#eee',
    color: 'transparent',
    height: 20,
    width: 20,
  },
  GlowText: {
    backgroundColor: '#eee',
    color: 'transparent',
  },
  ListItem: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: 48,
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  ListItemInputTask: {
    backgroundColor: 'transparent',
    width: '95%',
    padding: 10,
    fontFamily: 'NunitoSans',
    fontSize: 14,
    lineHeight: 20,
    fontStyle: 'normal',
  },
  ListItemInputTaskArchived: {
    color: '#aaa',
    width: '95%',
    padding: 10,
    fontFamily: 'NunitoSans',
    fontSize: 14,
    lineHeight: 20,
    fontStyle: 'normal',
  },
  LoadingItem: {
    alignItems: 'center',
    backgroundColor: 'white',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    flex: 1,
    height: 48,
    justifyContent: 'space-around',
    paddingLeft: 16,
    width: '100%',
  },
  ListItems: {
    flex: 1,
    backgroundColor: 'white',
    minHeight: 288,
  },
  WrapperMessage: {
    position: 'absolute',
    top: '40%',
    right: 0,
    bottom: 'auto',
    left: 0,
    width: 'auto',
    height: 'auto',
    textAlign: 'center',
  },
  PageListsShow: {
    minHeight: '100vh',
    backgroundColor: 'white',
  },
  PageListsShowhead: {
    backgroundColor: '#d3edf4',
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  TitleMessage: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'NunitoSans',
    fontWeight: '800',
    color: '#555',
  },
  SubtitleMessage: {
    fontSize: 14,
    lineHeight: 20,
    color: '#666',
    fontFamily: 'NunitoSans',
  },
  titlepage: {
    fontSize: 20,
    lineHeight: 24,
  },
  TitleWrapper: {
    fontFamily: 'NunitoSans',
    fontWeight: '800',
    color: '#1c3f53',
    maxWidth: '100%',
  },
  ErrorText: {
    color: 'red',
    fontSize: 14,
    marginTop: 8,
  },
  ErrorTextMobile: {
    color: 'red',
    fontSize: 12,
    marginTop: 8,
  },
  Icon9: {
    width: 9,
    height: 9,
    resizeMode: 'contain',
  },
  Icon12: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
  },
  Icon14: {
    width: 14,
    height: 14,
    resizeMode: 'contain',
  },
  Icon15: {
    width: 15,
    height: 15,
    resizeMode: 'contain',
  },
  Icon16: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
  },
  Icon18: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
  },
  Icon20: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  Icon22: {
    width: 22,
    height: 22,
    resizeMode: 'contain',
  },
  Icon24: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  Icon25: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },
  Icon26: {
    width: 26,
    height: 26,
    resizeMode: 'contain',
  },
  Icon30: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  Icon32: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  Icon40: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  Icon42: {
    width: 42,
    height: 42,
    resizeMode: 'contain',
  },
  Icon60: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
  Icon70: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
  },
  Icon80: {
    width: 80,
    height: 80,
    resizeMode: 'contain',
  },
  DisplayFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  DisplayFlexCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  Center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  VerticalCenter: {
    alignItems: 'center',
  },
  RedText: {
    color: 'red',
  },
  NoteText: {
    color: 'red',
    fontSize: 10,
  },
  RedTextHint: {
    position: 'relative',
    fontSize: 12,
    left: 3,
    color: 'red',
    top: 0,
  },
  Expanded: {
    flex: 1,
  },
  PositionRelative: {
    position: 'relative',
  },
  PositionAbsolute: {
    position: 'absolute',
  },
  AppBarContainer: {
    padding: 16,
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    height: 56,
    zIndex: 1000,
    backgroundColor: '#D62828',
    alignItems: 'center',
  },
  AppBarTitle: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '700',
    color: '#fff',
  },
  CommonPadding8: {
    padding: 8,
  },
  CommonPadding12: {
    padding: 12,
  },
  CommonMargin12: {
    margin: 12,
  },
  CommonMarginHorizontal12: {
    marginHorizontal: 12,
  },
  CommonMarginHorizontal16: {
    marginHorizontal: 16,
  },
  FloatLeft: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  FloatRight: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  AlignItemsRight: {
    alignItems: 'flex-end',
  },
  AlignItemsEnd: {
    alignItems: 'flex-end',
  },
  AlignItemsCenter: {
    alignItems: 'center',
  },
  errorMes: {
    color: 'red',
    marginTop: 10,
    position: 'absolute',
    bottom: 6,
  },
  errorMessage: {
    color: 'red',
    width: '100%',
    marginTop: 10,
  },
  DialogTitle: {
    fontSize: FontSizeValue(13),
    fontWeight: 'bold',
    color: '#505D6F',
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  DialogTitleMobile: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#505D6F',
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  FullHeight: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },
  FullWidth: {
    flex: 1,
    width: '100%',
  },
  Width100: {
    width: '100%',
  },
  Height100: {
    height: '100%',
  },
  Flex1: {
    flex: 1,
  },
  CommonButton: {
    minWidth: 120,
    height: 41,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 0,
  },
  CommonSubTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  CommonSubTitleNote: {
    color: '#2F7AEB',
    fontStyle: 'italic',
    fontSize: 12,
    marginLeft: 3,
    fontWeight: '400',
  },
  CommonSubTitleTablet: {
    fontSize: 12.4375,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  OutlineStyle: {
    borderColor: '#ED1B2E',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 16,
  },
  OutlineDisabledStyle: {
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 16,
  },
  FilledStyle: {
    backgroundColor: '#ED1B2E',
    borderColor: '#ED1B2E',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 16,
  },
  AccentText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#ED1B2E',
  },
  DisabledText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#E0E0E0',
  },
  WhiteText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#FFF',
  },
  Background: {
    backgroundColor: '#F7F8FA',
  },
  BackgroundTransparent: {
    backgroundColor: '#00000000',
  },
  TextCommon: {
    fontSize: 15,
    fontWeight: '300',
    color: '#505D6F',
  },
  noDataContainer: {
    height: 710,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
  mobileNoDataContainer: {
    height: 200,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  mobileNoDataPage: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 710,
  },
  ColumnWidth: {
    width: '25%',
  },
  Separator: {
    height: 20,
    width: 1.66,
    borderRadius: 2.5,
    backgroundColor: '#D7DFFF',
  },
  Disable: {
    color: '#BABABA',
  },
  DesktopTextInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderWidth: 0,
    borderColor: '#fff',
  },
  TabletTextInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderWidth: 0,
    borderColor: '#fff',
  },
  MobileTextInput: {
    fontSize: 13,
    fontWeight: '600',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 0,
    borderColor: '#fff',
  },
  TextAlignCenter: {
    textAlign: 'center',
  },
  Shadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  CommonText: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.commonTextColor,
  },
  CommonTextTablet: {
    fontSize: 13,
    fontWeight: '500',
    color: Colors.commonTextColor,
  },
  fontSize18: {
    fontSize: 18,
  },
  OverFlowHidden: {
    overflow: 'hidden',
  },
  Card: {
    backgroundColor: '#fff',
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 10,
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  MobileTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: Colors.titleTextColor,
  },
  MobileSubTitle: {
    fontSize: 13,
    color: Colors.titleTextColor,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  Bullet: {
    fontSize: 13,
    fontWeight: '700',
  },
  iconDesktop: {
    width: 30,
    height: 30,
  },
  fullWidthHeight: {
    width: '100%',
    height: '100%',
  },
})
