const appEnv = {
  DEV: {
    host: 'https://pru-api-nprd.prudential.com.vn',
    ocpKey: 'b7118a22518f443294feddc343e1b0bb',
    subscriptionKey: 'b7118a22518f443294feddc343e1b0bb',
    apim: '/pruforce/cna/dev1',
    ingress: 'https://cna-process-dev.aks-lb1-vnlife-uat-az1-z9llwh.pru.intranet.asia',
    googleSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    reCaptchaBaseUrl: 'http://localhost:3000',
    pruquoteRedirectUrl: 'https://pruquotecloud-uat.prudential.com.vn/pruquote-uat/index.aspx',
  },
  UAT: {
    host: 'https://pru-api-nprd.prudential.com.vn',
    ocpKey: '383a935eda66470e96822f701c0b9bbd',
    subscriptionKey: '383a935eda66470e96822f701c0b9bbd',
    apim: '/pruforce/cna/uat1',
    ingress: 'https://cna-process-uat.aks-lb1-vnlife-uat-az2-orch2h.pru.intranet.asia',
    googleSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    reCaptchaBaseUrl: 'http://localhost:3000',
    pruquoteRedirectUrl: 'https://pruquotecloud-uat.prudential.com.vn/pruquote-uat/index.aspx',
  },
  PRD: {
    host: 'https://pru-api-prod.prudential.com.vn',
    ocpKey: '5116788fdeb547e78a4c4c60664b1cb7',
    subscriptionKey: '34d3349af0f54d76b41a1a02a0868573',
    apim: '/pruforce/cna/v3',
    ingress: 'https://cna-process.aks-lb1-vnlife-prd-az2-aipbuk.pru.intranet.asia',
    googleSiteKey: '6Ldu_J0eAAAAAPveTwdAumG05FPpw0iWsfYjd2O1',
    reCaptchaBaseUrl: 'https://cna.prudential.com.vn',
    pruquoteRedirectUrl: 'https://pruquote.prudential.com.vn/pruquote/index.aspx',
  },
}

export default appEnv
