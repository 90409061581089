import * as React from 'react'
import { StyleSheet, Text, View, Image, ImageSourcePropType } from 'react-native'
import { Slider } from 'react-native-elements'
import i18n from 'i18n-js'
import { styles as globalStyles } from '../constants/globalStyles'
import { mathHelper } from '../helper/Mathematic'
import { vnNumberFormatter } from '../helper/Formatter'
import Layout from '../../constants/Layout'
import Colors from '../../common/constants/Colors'
import SizedBox from './SizedBox'

export type NeedsOverallItemProps = {
  item?: any
  icon?: JSX.Element
  /**
   * Quantity of selected needs, unit is billion
   */
  qty?: number
  isChecked?: boolean
  onCheck?: (value: boolean) => void
  sliderValue?: number
  renderItemTite?: (key: string) => string
}

export function NeedsOverallItem({ item, qty, isChecked, onCheck, sliderValue, renderItemTite }: NeedsOverallItemProps) {
  const isMobileDevice = Layout.isMobileDevice

  function getQtyValue() {
    // We need to multiply to 1000000 because qty's unit is billion
    const qtyToCount = (qty ?? 0) * 1000000
    return (
      vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(qtyToCount ?? 0, 1000000, 3)) +
      ' ' +
      i18n.t('COMMON.MILLION')
    )
  }

  if (isMobileDevice) {
    return (
      <View style={mobileStyles.container}>
        <View style={mobileStyles.infoContainer}>
          <View style={mobileStyles.titleContainer}>
            <View style={mobileStyles.iconContainer}>
              <Image
                source={isChecked ? item.icon : (item.disabledIcon as ImageSourcePropType)}
                style={globalStyles.Icon40}
              />
            </View>
            <View style={[globalStyles.DisplayFlexCol]}>
              <Text style={[mobileStyles.title, { color: isChecked ? '#70777E' : '#E0E0E0' }]} numberOfLines={2}>
                {renderItemTite ? renderItemTite(item.key) : item.title}
              </Text>
              <Text style={[mobileStyles.sliderLabel, { color: isChecked ? item.themeColor : '#E0E0E0' }]}>
                {getQtyValue()}
              </Text>
            </View>
          </View>
          <View style={mobileStyles.sliderContainer}>
            <Slider
              style={mobileStyles.slider}
              minimumValue={0}
              maximumValue={1}
              value={sliderValue}
              minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
              maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
              disabled
              thumbStyle={{ width: 0, height: 0 }}
              trackStyle={{ borderRadius: 9999, height: 6 }}
            />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={desktopStyles.container}>
      <View style={desktopStyles.infoContainer}>
        <View style={desktopStyles.iconContainer}>
          <Image
            source={isChecked ? item.icon : (item.disabledIcon as ImageSourcePropType)}
            style={globalStyles.Icon70}
          />
        </View>
        <View style={desktopStyles.titleContainer}>
          <Text style={[desktopStyles.title, { color: isChecked ? '#70777E' : '#E0E0E0' }]} numberOfLines={2}>
            {renderItemTite ? renderItemTite(item.key) : item.title}
          </Text>
          <SizedBox height={10} />
          <Text style={[desktopStyles.sliderLabel, { color: isChecked ? item.themeColor : '#E0E0E0' }]}>
            {getQtyValue()}
          </Text>
          {/* <Checkbox checked={isChecked} onChange={onCheck} containerStyle={desktopStyles.checkboxContainer} /> */}
        </View>
      </View>

      <View style={desktopStyles.sliderContainer}>
        <Slider
          style={{ width: '90%', height: 20 }}
          minimumValue={0}
          maximumValue={1}
          value={sliderValue}
          minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
          maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
          disabled
          thumbStyle={{ width: 0, height: 0 }}
          trackStyle={{ borderRadius: 9999, height: 13 }}
        />
      </View>
    </View>
  )
}

export default NeedsOverallItem

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    borderRadius: 20,
    borderColor: Colors.borderColor,
    borderWidth: 1,
    padding: 16,
    paddingRight: 0,
  },
  iconContainer: {
    marginRight: 10,
  },
  infoContainer: {
    flexDirection: 'row',
  },
  titleContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  checkbox: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
  },
  title: {
    fontSize: 14,
    maxWidth: 163,
    height: 38,
  },
  sliderContainer: {
    marginTop: 9,
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  checkboxContainer: {
    minWidth: 0,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    marginVertical: 8,
  },
  checkboxContainer: {
    marginTop: 8,
    marginRight: 18,
  },
  iconContainer: {
    marginRight: 10,
  },
  infoContainer: {
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
  },
  title: {
    fontSize: 14,
    height: 28,
  },
  sliderContainer: {
    marginTop: 8,
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  slider: {
    width: '98%',
    height: 20,
  },
})
