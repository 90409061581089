import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { RootStackScreenProps } from '../../types'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'
import SummaryNeeds from './components/SummaryNeeds'
import {
  defaultQuotationFundAllocation,
  needsData,
} from '../../constants/Data'
import { updateNeedsOverall } from '../../slices/needsOverallSlice'
import { getInternalCNADetail } from '../../slices/genPdfSlice'
import { ProductType } from '../../constants/Enum'
import SummarySolutionILP from './components/SummarySolutionILP'
import SummarySolutionULP from './components/SummarySolutionULP'
import SummaryBenefitILP from './components/SummaryBenefitILP'
import SummaryBenefitULP from './components/SummaryBenefitULP'
import { HealthCarePlan } from '../../model/HealthCarePlan'
import dataProvider from '../../data/dataProvider'

const mapStateToProps = (state: RootState) => ({
  loading: state.advisor.loading,
  needsOverallList: state.needsOverall.needsOverallList,
  currentHandlingCNA: state?.genPdf.currentHandlingCNA,
  isLoadingChartData: state?.genPdf.loadingChartData,
  error: state?.genPdf.error,
})

const mapDispatchToProps = {
  updateNeedsOverall,
  getInternalCNADetail,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'Solution'> & PropsFromRedux & {}

function GenPDFScreen({
  route,
  navigation,
  loading,
  error,
  needsOverallList,
  updateNeedsOverall,
  currentHandlingCNA,
  getInternalCNADetail,
  isLoadingChartData,
}: Props) {

  const [selectedHcPlan, setSelectedHcPlan] = React.useState<HealthCarePlan>()

  useEffect(() => {
    let data: Array<any> = []
    needsData.forEach((e) => data.push(...e.data))
    updateNeedsOverall(data)
    onInitData()
  }, [])

  React.useEffect(() => {
    let hospitalTypeDataList = dataProvider.getHospitalDataBasedOnAgeAndGender(
      currentHandlingCNA?.customerAge,
      currentHandlingCNA?.customerGender
    )
    setSelectedHcPlan(hospitalTypeDataList.find((x) => x.answer === currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer))
  }, [currentHandlingCNA])

  function onInitData() {
    const id = (route.params as any)?.id
    const agentCode = (route.params as any)?.agentCode
    if (id) {
      Promise.all([getInternalCNADetail({ id, agentCode })])
    }
  }

  if (currentHandlingCNA?.product === ProductType.ILP) {
    return (
      <SafeAreaView style={desktopStyles.container}>
        <View style={desktopStyles.page}>
          <View style={desktopStyles.contentContainer}>
            <SummaryNeeds
              needsData={needsOverallList}
              style={{ marginTop: 8 }}
              sumAssured={currentHandlingCNA?.sumAssured}
              ape={currentHandlingCNA?.ape}
              periodPlan={currentHandlingCNA?.needTerm}
              showNeedsDetail={false}
              hcPlanDetail={selectedHcPlan?.label}
            />
            <SummaryBenefitILP cna={currentHandlingCNA} hospitalData={selectedHcPlan} />
            <SummarySolutionILP
              showInvestInfo
              showFundInfo={false}
              customerAge={currentHandlingCNA?.customerAge ?? 0}
              style={{ marginTop: 8 }}
              data={currentHandlingCNA?.quotationWithoutTopups ? currentHandlingCNA?.quotationWithoutTopups :  currentHandlingCNA?.quotations}
              productTypeSolution={ProductType.ILP}
              extraInvest={currentHandlingCNA?.topUp}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
            />
          </View>
        </View>
      </SafeAreaView>
    )
  } else {
    return (
      <SafeAreaView style={desktopStyles.container}>
        <View style={desktopStyles.page}>
          <View style={desktopStyles.contentContainer}>
            <SummaryNeeds
              needsData={needsOverallList}
              style={{ marginTop: 8 }}
              sumAssured={currentHandlingCNA?.sumAssured}
              ape={currentHandlingCNA?.ape}
              periodPlan={currentHandlingCNA?.needTerm}
              showNeedsDetail={false}
              hcPlanDetail={selectedHcPlan?.label}
            />
            <SummaryBenefitULP cna={currentHandlingCNA} hospitalData={selectedHcPlan} />
            <SummarySolutionULP
              showInvestInfo
              customerAge={currentHandlingCNA?.customerAge ?? 0}
              style={{ marginTop: 8 }}
              data={currentHandlingCNA?.quotationWithoutTopups ? currentHandlingCNA?.quotationWithoutTopups :  currentHandlingCNA?.quotations}
              productTypeSolution={ProductType.ULP}
              extraInvest={currentHandlingCNA?.topUp}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
            />
          </View>
        </View>
      </SafeAreaView>
    )
  }
}

export default connector(GenPDFScreen)

const desktopStyles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: 14,
  },
  container: {
    backgroundColor: '#FFF',
    borderRadius: 14,
  },
  contentContainer: {
    paddingLeft: 16,
    paddingRight: 28,
    paddingVertical: 16.58,
    backgroundColor: '#FFF',
    flex: 1,
    flexDirection: 'column',
    borderRadius: 14,
  },
  headerImage: {
    resizeMode: 'contain',
  },
  pageNumberContainer: {
    marginTop: 10,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pageNumber: {
    color: '#ED1B2E',
    fontSize: 13,
    fontWeight: '600',
  },
})
