import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import {
  styles as globalStyles,
  BlockAlysys,
  InputData,
  Dialog,
  RadioButton,
  BoxFooter,
  SizedBox,
  ValidateRequiredError,
} from '../common'
import { useMediaQuery } from 'react-responsive'
import { tabletSmallMediaConfig,MAX_MONTHLY_INCOME} from '../constants/Constants'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { updateProfile } from '../slices/needsCartSlice'
import { saveCNA } from '../slices/cnaSlice'
import { updateUserProtect } from '../slices/userSlice'
import { connect, ConnectedProps } from 'react-redux'
import Layout from '../constants/Layout'
import { renderError } from '../helper/CnaHelper'
import i18n from 'i18n-js'

const mapStateToProps = (state: RootState) => ({
  protectLife: state.protectLife.protectLife,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  updateProfile,
  saveCNA,
  updateUserProtect,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SafeLifeScreen({ protectLife, needsCartList, saveCNA, userProtect, updateUserProtect }: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice
  const isTabletSmallDevice = useMediaQuery(tabletSmallMediaConfig)

  const [showPopup, setShowPopup] = React.useState(false)

  const data = [
    {
      value: true,
      text: 'Có',
    },
    {
      value: false,
      text: 'Không',
    },
  ]

  const {
    control,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: protectLife,
    shouldUnregister: false,
  })

  useEffect(() => {
    if (watch('subTotal') > 0) {
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        protectLife: watch(),
      }
      updateUserProtect({
        cnaData,
      })
    }
  }, [watch('subTotal'), watch('isSubsidy'), watch('monthlySubsidy'), watch('subsidyMonth')])

  useEffect(() => {
    if (userProtect?.protectLife) {
      setValue('month', userProtect?.protectLife?.month)
      setValue('monthlySubsidy', userProtect?.protectLife?.monthlySubsidy)
      setValue('monthlyFixedExpense', userProtect?.protectLife?.monthlyFixedExpense)
      setValue('subTotal', userProtect?.protectLife?.subTotal)
      setValue('isSubsidy', userProtect?.protectLife?.isSubsidy)
      setValue('subsidyMonth', userProtect?.protectLife?.subsidyMonth)
    }
  }, [userProtect])

  function countTotal() {
    const dataTotal = parseFloat(
      Number(watch('monthlyFixedExpense') || 0) * Number(watch('month') || 0) +
        Number(watch('monthlySubsidy') || 0) * Number(watch('subsidyMonth') || 0)
    )
    setValue('subTotal', dataTotal)
    if (dataTotal === 0) {
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        protectLife: { ...userProtect?.protectLife, ...watch() },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }

  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Bảo vệ tiêu chuẩn cuộc sống"
          borderColor="#BE63F9"
          child={
            <View>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_FIXED'),
                  },
                  max: {
                    value:  Number(userProtect?.protectIncome?.monthlyIncome) > 0  && !userProtect?.protectIncome?.isSelect || Number(userProtect?.protectIncome?.monthlyIncome) >= 0  && userProtect?.protectIncome?.isSelect   ?  Number(userProtect?.protectIncome?.monthlyIncome) : MAX_MONTHLY_INCOME,
                    message:  Number(userProtect?.protectIncome?.monthlyIncome) > 0  && !userProtect?.protectIncome?.isSelect || Number(userProtect?.protectIncome?.monthlyIncome) >= 0  && userProtect?.protectIncome?.isSelect   ?  i18n.t('NEEDS_CART.MONTHLY_EXPENSE_NOT_GREATER_THAN_MONTHLY_INCOME') : i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Chi phí trung bình hàng tháng cho cuộc sống của bạn:"
                    helpText="Triệu đồng"
                    type="column"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                  
                    disabled={false}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={errors.monthlyFixedExpense && renderErrorMessage(errors?.monthlyFixedExpense?.message)}
                    placeholder="Nhập"
                  />
                )}
                name="monthlyFixedExpense"
              />
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Bạn muốn bảo vệ tiêu chuẩn cuộc sống hiện tại trong bao lâu?"
                    helpText="Tháng"
                    type="column"
                    allowNagitive
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={errors.month && renderErrorMessage(errors?.month?.message)}
                    placeholder="Nhập"
                  />
                )}
                name="month"
              />

              <Controller
                control={control}
                rules={{}}
                render={({ onChange, value }) => (
                  <RadioButton
                    title="Bạn có đang phải chu cấp tài chính cho ai không?"
                    data={data}
                    value={value}
                    margin
                    dafaultValue={value}
                    error={
                      errors.isSubsidy &&  renderErrorMessage(errors?.isSubsidy?.message)
                    }
                    onChange={(value) => {
                      onChange(value)
                      setValue('monthlySubsidy', 0)
                      setValue('subsidyMonth', 0)
                      clearErrors('monthlySubsidy')
                      clearErrors('subsidyMonth')
                      countTotal()
                    }}
                  />
                )}
                defaultValue={false}
                name="isSubsidy"
              />

              <Controller
                control={control}
                rules={{
                  required: {
                    value: watch('isSubsidy') ? true : false,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Số tiền chu cấp trong 1 tháng:"
                    helpText="Triệu đồng"
                    type="column"
                    value={value !== 0 ? value : ''}
                    disabled={watch('isSubsidy') === false ? true : false}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.monthlySubsidy &&
                      watch('isSubsidy') === true &&
                      renderErrorMessage(errors?.monthlySubsidy?.message)
                    }
                    placeholder="Nhập"
                  />
                )}
                name="monthlySubsidy"
              />

              <Controller
                control={control}
                rules={{
                  required: {
                    value: watch('isSubsidy') ? true : false,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Thời gian thực hiện chu cấp tài chính:"
                    helpText="Tháng"
                    type="column"
                    allowNagitive
                    value={value !== 0 ? value : ''}
                    disabled={watch('isSubsidy') === false ? true : false}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.monthlySubsidy &&
                      watch('isSubsidy') === true &&
                      renderErrorMessage(errors?.subsidyMonth?.message)
                    }
                    placeholder="Nhập"
                  />
                )}
                defaultValue=""
                name="subsidyMonth"
              />

              <Controller
                control={control}
                rules={{ required: false }}
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
        <Dialog
          title="Chi phí tham khảo"
          message="Thông tin kế hoạch"
          children={
            <Text>
              Nhiều khách hàng lựa chọn bảo vệ 36-60 tháng thu nhập vì 3-5 năm là thời gian cần thiết để ổn định lại
              cuộc sống nếu không may có sự cố xảy ra
            </Text>
          }
          type="default"
          showCloseIcon
          iconHeader={true}
          onRemove={() => setShowPopup(false)}
          visible={showPopup}
        />
      </View>
    )
  }

  if (isLaptopDevice) {
    return (
      <View style={{ flex: 1, position: 'relative' }}>
        <BlockAlysys
          title="Bảo vệ tiêu chuẩn cuộc sống"
          borderColor="#BE63F9"
          child={
            <View>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_FIXED'),
                  },
                  max: {
                    value:  Number(userProtect?.protectIncome?.monthlyIncome) > 0  && !userProtect?.protectIncome?.isSelect || Number(userProtect?.protectIncome?.monthlyIncome) >= 0  && userProtect?.protectIncome?.isSelect   ?  Number(userProtect?.protectIncome?.monthlyIncome) : MAX_MONTHLY_INCOME,
                    message:  Number(userProtect?.protectIncome?.monthlyIncome) > 0  && !userProtect?.protectIncome?.isSelect || Number(userProtect?.protectIncome?.monthlyIncome) >= 0  && userProtect?.protectIncome?.isSelect   ?  i18n.t('NEEDS_CART.MONTHLY_EXPENSE_NOT_GREATER_THAN_MONTHLY_INCOME') : i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Chi phí trung bình hàng tháng cho cuộc sống của bạn:"
                    helpText="Triệu đồng"
                    type="column"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    disabled={false}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={errors.monthlyFixedExpense && renderErrorMessage(errors?.monthlyFixedExpense?.message)}
                    placeholder="Nhập"
                  />
                )}
                name="monthlyFixedExpense"
              />
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Bạn muốn bảo vệ tiêu chuẩn cuộc sống hiện tại trong bao lâu?"
                    helpText="Tháng"
                    type="column"
                    allowNagitive
                    value={value !== 0 ? value : ''}
                    wrapHelpTextContainer={{ width: '100%' }}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={errors.month && renderErrorMessage(errors?.month?.message)}
                    placeholder="Nhập"
                  />
                )}
                name="month"
              />

              <Controller
                control={control}
                rules={{}}
                render={({ onChange, value }) => (
                  <RadioButton
                    title="Bạn có đang phải chu cấp tài chính cho ai không?"
                    data={data}
                    value={value}
                    margin
                    dafaultValue={value}
                    error={errors.isSubsidy && renderErrorMessage(errors?.isSubsidy?.message)}
                    onChange={(value) => {
                      onChange(value)
                      setValue('monthlySubsidy', 0)
                      setValue('subsidyMonth', 0)
                      clearErrors('monthlySubsidy')
                      clearErrors('subsidyMonth')
                      countTotal()
                    }}
                  />
                )}
                defaultValue={false}
                name="isSubsidy"
              />

              <Controller
                control={control}
                rules={{
                  required: {
                    value: watch('isSubsidy') ? true : false,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Số tiền chu cấp trong 1 tháng:"
                    helpText="Triệu đồng"
                    type="column"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    disabled={watch('isSubsidy') === false ? true : false}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.monthlySubsidy &&
                      watch('isSubsidy') === true &&
                      renderErrorMessage(errors?.monthlySubsidy?.message)
                    }
                    placeholder="Nhập"
                  />
                )}
                name="monthlySubsidy"
              />
              <Controller
                control={control}
                rules={{
                  required: {
                    value: watch('isSubsidy') ? true : false,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Thời gian thực hiện chu cấp tài chính:"
                    helpText="Tháng"
                    type="column"
                    allowNagitive
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    disabled={watch('isSubsidy') === false ? true : false}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.subsidyMonth &&
                      watch('isSubsidy') === true &&
                      renderErrorMessage(errors?.subsidyMonth?.message)
                    }
                    placeholder="Nhập"
                  />
                )}
                defaultValue=""
                name="subsidyMonth"
              />

              <Controller
                control={control}
                rules={{ required: false }}
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
        <SizedBox width={24} />
        <Dialog
          title="Chi phí tham khảo"
          message="Thông tin kế hoạch"
          children={
            <Text>
              Nhiều khách hàng lựa chọn bảo vệ 36-60 tháng thu nhập vì 3-5 năm là thời gian cần thiết để ổn định lại
              cuộc sống nếu không may có sự cố xảy ra
            </Text>
          }
          type="default"
          showCloseIcon
          iconHeader={true}
          onRemove={() => setShowPopup(false)}
          visible={showPopup}
        />
      </View>
    )
  }
  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Bảo vệ tiêu chuẩn cuộc sống"
        borderColor="#BE63F9"
        child={
          <View>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_FIXED'),
                },
                max: {
                  value:  Number(userProtect?.protectIncome?.monthlyIncome) > 0  && !userProtect?.protectIncome?.isSelect || Number(userProtect?.protectIncome?.monthlyIncome) >= 0  && userProtect?.protectIncome?.isSelect   ?  Number(userProtect?.protectIncome?.monthlyIncome) : MAX_MONTHLY_INCOME,
                  message:  Number(userProtect?.protectIncome?.monthlyIncome) > 0  && !userProtect?.protectIncome?.isSelect || Number(userProtect?.protectIncome?.monthlyIncome) >= 0  && userProtect?.protectIncome?.isSelect   ?  i18n.t('NEEDS_CART.MONTHLY_EXPENSE_NOT_GREATER_THAN_MONTHLY_INCOME') : i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Chi phí trung bình hàng tháng cho cuộc sống của bạn:"
                  helpText="Triệu đồng"
                  type="column"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  disabled={false}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={errors.monthlyFixedExpense && renderErrorMessage(errors?.monthlyFixedExpense?.message)}
                  placeholder="Nhập"
                />
              )}
              name="monthlyFixedExpense"
            />
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Bạn muốn bảo vệ tiêu chuẩn cuộc sống hiện tại trong bao lâu?"
                  helpText="Tháng"
                  type="column"
                  allowNagitive
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={errors.month && renderErrorMessage(errors?.month?.message)}
                  placeholder="Nhập"
                  wrapHelpTextContainer={{ width: '100%' }}
                />
              )}
              name="month"
            />

            <Controller
              control={control}
              rules={{}}
              render={({ onChange, value }) => (
                <RadioButton
                  title="Bạn có đang phải chu cấp tài chính cho ai không?"
                  data={data}
                  value={value}
                  margin
                  dafaultValue={value}
                  error={errors.isSubsidy && renderErrorMessage(errors?.isSubsidy?.message)}
                  onChange={(value) => {
                    onChange(value)
                    setValue('monthlySubsidy', 0)
                    setValue('subsidyMonth', 0)
                    clearErrors('monthlySubsidy')
                    clearErrors('subsidyMonth')
                    countTotal()
                  }}
                />
              )}
              defaultValue={false}
              name="isSubsidy"
            />

            <Controller
              control={control}
              rules={{
                required: {
                  value: watch('isSubsidy') ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Số tiền chu cấp trong 1 tháng:"
                  helpText="Triệu đồng"
                  type="column"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  disabled={watch('isSubsidy') === false ? true : false}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={
                    errors.monthlySubsidy &&
                    watch('isSubsidy') === true &&
                    renderErrorMessage(errors?.monthlySubsidy?.message)
                  }
                  placeholder="Nhập"
                />
              )}
              name="monthlySubsidy"
            />
            <Controller
              control={control}
              rules={{
                required: {
                  value: watch('isSubsidy') ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Thời gian thực hiện chu cấp tài chính:"
                  helpText="Tháng"
                  type="column"
                  allowNagitive
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  disabled={watch('isSubsidy') === false ? true : false}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={
                    errors.subsidyMonth &&
                    watch('isSubsidy') === true &&
                    renderErrorMessage(errors?.subsidyMonth?.message)
                  }
                  placeholder="Nhập"
                />
              )}
              defaultValue=""
              name="subsidyMonth"
            />

            <Controller
              control={control}
              rules={{ required: watch('isSubsidy') ? true : false }}
              render={({ value }) => (
                <BoxFooter
                  title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                  backgroundColorBox="#F5E6FE"
                  titleStyle={{ color: '#BE63F9' }}
                  child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <SizedBox width={24} />
      <Dialog
        title="Chi phí tham khảo"
        message="Thông tin kế hoạch"
        children={
          <Text>
            Nhiều khách hàng lựa chọn bảo vệ 36-60 tháng thu nhập vì 3-5 năm là thời gian cần thiết để ổn định lại cuộc
            sống nếu không may có sự cố xảy ra
          </Text>
        }
        type="default"
        showCloseIcon
        iconHeader={true}
        onRemove={() => setShowPopup(false)}
        visible={showPopup}
      />
    </View>
  )
}

export default connector(SafeLifeScreen)

const mobileStyles = StyleSheet.create({
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
