import { CircleProgressIndicator } from './CircleProgressIndicator'
import React from 'react'
import { StyleSheet, View, ScrollView, Text } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'
import Layout from '../../constants/Layout'

export type ColumnProps = {
  title: string
  dataIndex: string
  width: number
  renderHeader: (title: any) => JSX.Element
  renderCell: (value: any, record: any) => JSX.Element
}

export type TableComponentProps = {
  tableHead?: Array<any>
  tableTitle?: Array<any>
  tableData?: Array<Array<any>>
  columns: Array<ColumnProps>
  columnWidth?: number
  height?: number
  noHeader?: boolean
  noDataStyle?: Record<string, unknown>
  dataSource: any
  headerContainerStyle?: Record<string, unknown>
  headerCellStyle?: Record<string, unknown>
  bodyContainerStyle?: Record<string, unknown>
  headerStyle?: Record<string, unknown>
  bodyStyle?: Record<string, unknown>
  renderHead?: (cellData: any, col: any, index: number) => void
  renderTitle?: (cellData: any, col: any, index: number) => void
  renderCell?: (cellData: any, col: any, index: number) => void
  containerStyle?: Record<string, unknown>
  rowStyle?: Record<string, unknown>
  isHaveTitle?: boolean
  titleStyle?: Record<string, unknown>
  loading?: boolean
}

const DEFAULT_COLUMN_WIDTH = 100

export function TableWithTitle({
  columns,
  columnWidth,
  height,
  dataSource,
  headerContainerStyle,
  headerCellStyle,
  bodyContainerStyle,
  bodyStyle,
  headerStyle,
  tableHead,
  tableTitle,
  tableData,
  noHeader,
  noDataStyle,
  renderTitle,
  renderHead,
  renderCell,
  containerStyle,
  rowStyle,
  isHaveTitle,
  titleStyle,
  loading,
}: TableComponentProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (!tableHead) {
    throw Error('Table head is required')
  }

  if (!tableTitle) {
    throw Error('Table title is required')
  }

  if (!tableTitle) {
    throw Error('Table title is required')
  }

  const _renderCell = (cellData: any, col: any, rowIndex: number, colIndex: number, styles: any) => {
    const style = { width: col.width, flex: col.width ? undefined : 1 }
    if (col.renderCell) {
      return (
        <View
          key={colIndex}
          style={[
            styles.cell,
            bodyContainerStyle,
            style,
            col.cellStyle ? col.cellStyle : null,
            { minHeight: isMobileDevice ? 10 : 38 },
          ]}
        >
          {col.renderCell(cellData, dataSource[rowIndex])}
        </View>
      )
    }
    return colIndex === 0 ? (
      <View
        key={colIndex}
        style={[styles.cell, styles.cellFirst, bodyContainerStyle, style, { minHeight: isMobileDevice ? 10 : 38 }]}
      >
        <Text style={[bodyStyle, isHaveTitle && titleStyle ? titleStyle : {}]}>
          {col.dataIndex === 'name' ? <Text style={styles.bold}>{cellData}</Text> : ''}
          {cellData}
        </Text>
      </View>
    ) : (
      <View key={colIndex} style={[styles.cell, bodyContainerStyle, style, { minHeight: isMobileDevice ? 10 : 38 }]}>
        <Text style={bodyStyle}>{cellData}</Text>
      </View>
    )
  }

  const _renderHeader = (styles: any) => {
    return columns.map((col: any, index: number) => {
      const style = { width: col.width, flex: col.width ? undefined : 1 }
      if (col.renderHeader) {
        return (
          <View
            key={index}
            style={[
              styles.headerItem,
              headerCellStyle,
              style,
              col.headerStyle ? col.headerStyle : null,
              { minHeight: isMobileDevice ? 10 : 38 },
            ]}
          >
            {col.renderHeader(col.title)}
          </View>
        )
      }
      return index === 0 ? (
        <View
          key={index}
          style={[
            styles.headerItem,
            styles.headerItemFirst,
            headerCellStyle,
            style,
            { minHeight: isMobileDevice ? 10 : 58 },
          ]}
        >
          <Text style={[styles.HeaderText, headerStyle]}>{col.title}</Text>
        </View>
      ) : (
        <View key={index} style={[styles.headerItem, headerCellStyle, style, { minHeight: isMobileDevice ? 10 : 58 }]}>
          <Text style={[styles.HeaderText, headerStyle]}>{col.title}</Text>
        </View>
      )
    })
  }

  const _renderRow = (rowData: any, rowIndex: number, styles: any) => {
    return (
      <View key={rowIndex} style={[styles.row, rowStyle]}>
        {columns.map((col: any, colIndex: number) => {
          if (renderCell) {
            return renderCell(
              col.dataIndex.split('.').reduce((prev: any, curr: number) => (prev ? prev[curr] : null), rowData || null),
              col,
              colIndex
            )
          } else {
            return _renderCell(
              col.dataIndex.split('.').reduce((prev: any, curr: number) => (prev ? prev[curr] : null), rowData || null),
              col,
              rowIndex,
              colIndex,
              styles
            )
          }
        })}
      </View>
    )
  }

  function renderNoDataView() {
    return (
      <View style={[styles.noDataContainer, noDataStyle]}>
        <Text>Không tìm thấy dữ liệu</Text>
      </View>
    )
  }

  function renderLoadingView() {
    return (
      <View style={styles.noDataContainer}>
        <CircleProgressIndicator />
      </View>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={[styles.contentContainer, containerStyle, { height }]}
      horizontal={true}
      bounces={false}
    >
      <View
        style={[
          globalStyles.FullWidth,
          { paddingTop: isMobileDevice ? 10 : 0, paddingHorizontal: isMobileDevice ? 10 : 0 },
        ]}
      >
        {!noHeader && (
          <View style={[styles.header, headerContainerStyle]}>
            {_renderHeader(isMobileDevice ? mobileStyles : styles)}
          </View>
        )}
        <ScrollView contentContainerStyle={styles.dataView}>
          {loading
            ? renderLoadingView()
            : dataSource && dataSource.length
            ? dataSource.map((rowData: any, index: number) =>
                _renderRow(rowData, index, isMobileDevice ? mobileStyles : styles)
              )
            : renderNoDataView()}
        </ScrollView>
      </View>
    </ScrollView>
  )
}

export default TableWithTitle

const styles = StyleSheet.create({
  contentContainer: {
    height: 240,
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    flexDirection: 'row',
    width: '100%',
  },
  headerItem: {
    minHeight: 50,
    width: DEFAULT_COLUMN_WIDTH,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerItemFirst: {
    alignItems: 'flex-start',
    paddingLeft: 20,
  },
  HeaderText: {
    fontWeight: 'bold',
  },
  dataView: {
    flexGrow: 1,
    width: '100%',
    overflow: 'hidden',
  },
  bold: {
    fontWeight: '700',
  },
  dataViewContent: {
    width: '100%',
    overflow: 'hidden',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'baseline',
  },
  cell: {
    minHeight: 50,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  cellFirst: {
    alignItems: 'flex-start',
    paddingLeft: 20,
    paddingRight: 10,
  },
  noDataContainer: {
    height: 710,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
})

const mobileStyles = StyleSheet.create({
  contentContainer: {
    height: 240,
    width: '100%',
  },
  container: {
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    flexDirection: 'row',
    width: '100%',
  },
  headerItem: {
    width: DEFAULT_COLUMN_WIDTH,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingRight: 8,
  },
  headerItemFirst: {
    alignItems: 'flex-start',
    maxWidth: 100,
    paddingRight: 10,
    minWidth: 50,
  },
  HeaderText: {
    fontWeight: 'bold',
  },
  dataView: {
    flexGrow: 1,
    width: '100%',
    overflow: 'hidden',
  },
  bold: {
    fontWeight: '700',
  },
  dataViewContent: {
    width: '100%',
    overflow: 'hidden',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'baseline',
  },
  cell: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    width: '100%',
  },
  cellFirst: {
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 10,
    maxWidth: 100,
    minWidth: 50,
  },
  noDataContainer: {
    height: 710,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
})
