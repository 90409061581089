import React, { useState, useEffect } from 'react';
import {
    Text,
    View,
    StyleSheet,
    FlatList,
    Image,
    TouchableOpacity,
    ImageSourcePropType,
    Platform,
} from 'react-native';
import { CheckBox, Card } from 'react-native-elements'
import imgTick from '../../assets/images/tick-square.png'
import imgUntick from '../../assets/images/untick-square.png'
import { styles as globalStyles } from '../constants/globalStyles'
import Layout from '../../constants/Layout'
import { useMediaQuery } from 'react-responsive'
import { mobileMediaConfig, tabletMediaConfig } from '../../constants/Constants'

const cardWidth = 255;
const cardWidthTablet = 220;
const cardWidthMobile = 248;

const styles = StyleSheet.create({
    flatListStyle: {
        flexDirection: 'column',
        paddingTop: 8,
        paddingBottom: 0,
    },
    tinyLogo: {
        width: 60,
        height: 60,
        marginRight: 15
    },
    selectAllDisable: {
        color: '#BDBDBD',
        marginRight: 8,
    },
    selectAll: {
        color: '#D62828',
        fontSize: 14,
        fontWeight: '400',
        marginRight: 8,
    },
    titleList: {
        fontWeight: 'bold',
        color: '#70777E',
    },
    titleListHeader: {
        fontWeight: 'bold',
        fontSize: 15,
        flex: 1,
        marginLeft: 8,
    },
    checkList: {
        margin: 0,
        padding: 0
    },
    cardItem: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    cardContainer: {
        borderWidth: 0,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 5,
        paddingHorizontal: 16,
        paddingVertical: 12,
        marginRight: 0,
        marginBottom: 8,
        minHeight: 86,
        flexDirection: 'row',
        alignItems: 'center',
    },
    card: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: cardWidth,
    },
    cardHeader: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        height: 25,
        paddingBottom: 0,
        alignItems: 'center',
    }
});

export const tabletStyles = StyleSheet.create({
    flatListStyle: {
        flexDirection: 'column',
        paddingBottom: 16,
    },
    tinyLogo: {
        width: 46,
        height: 46,
        marginRight: 15
    },
    selectAllDisable: {
        color: '#BDBDBD',
        fontSize: 12,
    },
    selectAll: {
        color: '#D62828',
        fontSize: 12,
        fontWeight: '400',
    },
    titleList: {
        fontWeight: '700',
        fontSize: 13,
        color: '#70777E',
        flex: 1,
    },
    titleListHeader: {
        fontWeight: '700',
        fontSize: 12,
        flex: 1,
        marginLeft: 8,
    },
    checkList: {
        margin: 0,
        padding: 0
    },
    cardItem: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center'
    },
    cardContainer: {
        borderWidth: 1,
        borderColor: '#FFFFFF',
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 5,
        paddingHorizontal: 9,
        paddingVertical: 7,
        marginRight: 0,
        minHeight: 79,
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    cardContainerHighlight: {
        borderColor: '#D7DFFF',
        borderWidth: 1,
        borderRadius: 12,
    },
    card: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    cardHeader: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        alignItems: 'center',
        height: 25,
    }
});

export const mobileStyles = StyleSheet.create({
    flatListStyle: {
        flexDirection: 'column',
        paddingBottom: 16,
    },
    tinyLogo: {
        width: 46,
        height: 46,
        marginRight: 15
    },
    selectAllDisable: {
        color: '#BDBDBD',
    },
    selectAll: {
        color: '#D62828',
        fontSize: 14,
        fontWeight: '400',
    },
    titleList: {
        fontWeight: '700',
        fontSize: 13,
        color: '#70777E',
        flex: 1,
    },
    titleListHeader: {
        fontWeight: '700',
        fontSize: 13,
        flex: 1,
        marginLeft: 8,
    },
    checkList: {
        margin: 0,
        padding: 0
    },
    cardItem: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center'
    },
    cardContainer: {
        borderWidth: 1,
        borderColor: '#FFFFFF',
        borderRadius: 12,
        shadowColor: 'grey',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 5,
        paddingHorizontal: 9,
        paddingVertical: 7,
        marginRight: 0,
        marginTop: 8,
        marginBottom: 8,
    },
    cardContainerHighlight: {
        borderColor: '#D7DFFF',
        borderWidth: 1,
        borderRadius: 12,
    },
    card: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: cardWidth,
    },
    cardHeader: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 20,
        paddingBottom: 0,
        alignItems: 'center',
    }
});

export type PanelSelectItem = {
    data: any[]
    title: string
    code: string
    tempSelectedList?: number[]
    selectedList?: number[]
    renderItemTite?: (key: string) => string
    onChange: (code: string, updatedData: any[]) => void
}

export function PanelSelect({ data, title, code, tempSelectedList, selectedList, renderItemTite, onChange }: PanelSelectItem) {
    const isMobileDevice = Layout.isMobileDevice
    const isTabletDevice = useMediaQuery(tabletMediaConfig)

    const [products, setProducts] = useState(data);
    const [selectedAll, setSelectedAll] = useState(true);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [selected, setSelected] = useState<boolean>(false);
    const [selectedAllChecker, setSelectedAllChecker] = useState<boolean>(false);

    useEffect(() => {
        setProducts([...data])
    }, [data])

    useEffect(() => {
        setSelected((selectedList?.length ?? 0) > 0 || (tempSelectedList?.length ?? 0) > 0);
        setSelectedAllChecker(selectedList?.length === products?.length);
    }, [products?.length, selectedList])

    const getColumnCount = () => {
        let width = cardWidth
        if (isMobileDevice) {
            width = cardWidthMobile
        }
        if (isTabletDevice) {
            width = cardWidthTablet
        }
        const ratio = (Layout.window.width - 520) / width;
        if (ratio < 2) {
            return 1;
        }
        return Math.floor(ratio)
    }

    const handleChange = (id: number) => {
        const temp = products.map((product: any) => {
            if (id === product.id) {
                if (selectedList?.includes(product.id) || tempSelectedList?.includes(product.id)) {
                    return { ...product, isChecked: false, isTemporaryCheck: false };
                } else {
                    return { ...product, isChecked: true, isTemporaryCheck: true };
                }
            } else {
                return { ...product, isTemporaryCheck: tempSelectedList?.includes(product.id), isChecked: selectedList?.includes(product.id) };
            }
        });
        onChange(code, temp);
    };

    const handleAcceptAll = () => {
        const tempUser = data.map((data: any) => {
            return { ...data, isChecked: true, isTemporaryCheck: true };
        });
        setProducts(tempUser);
        onChange(code, tempUser)
    }

    const handleUnSelectAll = () => {
        const tempUser = data.map((data: any) => {
            return { ...data, isChecked: false, isTemporaryCheck: false };
        });
        setProducts(tempUser);
        onChange(code, tempUser)
    }

    const handleRemove = () => {
        const tempUser = data.map((data: any) => {
            return { ...data, isChecked: false, isTemporaryCheck: false };
        });
        setProducts(tempUser);
        setSelectedAll(true);
        onChange(code, tempUser)
    }

    const handleExpand = (expanded: boolean) => {
        setIsExpanded(expanded)
    }

    const isHighlight = (id: number) => {
        return selectedList?.includes(id) || tempSelectedList?.includes(id)
    }

    const renderFlatList = (renderData: any) => {
        if (isMobileDevice) {
            return (
                <FlatList
                    data={renderData}
                    style={styles.flatListStyle}
                    numColumns={getColumnCount()}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            onPress={() => {
                                handleChange(item.id);
                            }}
                            style={[
                                mobileStyles.cardContainer,
                                isHighlight(item.id) ? mobileStyles.cardContainerHighlight : null,
                            ]}
                        >
                            <View style={[mobileStyles.card, { width: '100%' }]}>
                                <Image source={item.icon} style={mobileStyles.tinyLogo} />
                                <Text style={mobileStyles.titleList}>{renderItemTite ? renderItemTite(item.key) : item.title}</Text>
                                {
                                    isHighlight(item.id)
                                        ? <CheckBox
                                            onPress={() => {
                                                handleChange(item.id);
                                            }}
                                            iconType='font-awesome'
                                            checkedIcon='check'
                                            checkedColor='green'
                                            size={20}
                                            checked={true}
                                            containerStyle={{ margin: 0 }}
                                        />
                                        : null
                                }
    
                            </View>
                        </TouchableOpacity>
                    )}
                />
            );
        }

        if (isTabletDevice) {
            return (
                <FlatList
                    data={renderData}
                    style={styles.flatListStyle}
                    numColumns={getColumnCount()}
                    renderItem={({ item, index }) => (
                        <TouchableOpacity
                            key={item.id}
                            style={
                                [
                                    styles.cardItem,
                                    {
                                        width: `${100 / getColumnCount() - 3}%`,
                                        marginLeft: 16,
                                        marginTop: 8,
                                        marginBottom: 8,
                                    }
                                ]
                            }
                            onPress={() => {
                                handleChange(item.id);
                            }}
                        >
                            <View style={[
                                tabletStyles.cardContainer,
                                isHighlight(item.id) ? tabletStyles.cardContainerHighlight : null
                            ]}>
                                <View style={[tabletStyles.card]}>
                                    <Image source={item.icon} style={mobileStyles.tinyLogo} />
                                    <Text style={tabletStyles.titleList}>{renderItemTite ? renderItemTite(item.key) : item.title}</Text>
                                    {
                                        isHighlight(item.id)
                                        ? <CheckBox
                                            onPress={() => {
                                                handleChange(item.id);
                                            }}
                                            iconType='font-awesome'
                                            checkedIcon='check'
                                            checkedColor='green'
                                            size={20}
                                            checked={true}
                                        />
                                        : null
                                    }
        
                                </View>
                            </View>
                        </TouchableOpacity>
                    )}
                />
            );
        }

        return (
            <FlatList
                data={renderData}
                style={styles.flatListStyle}
                numColumns={getColumnCount()}
                renderItem={({ item }) => (
                    <TouchableOpacity
                        key={item.id}
                        style={
                            [
                                styles.cardItem,
                                {
                                    width: `${100 / getColumnCount() - 3}%`,
                                    marginLeft: 16,
                                    marginTop: 8,
                                    marginBottom: 8,
                                }
                            ]
                        }
                        onPress={() => {
                            handleChange(item.id);
                        }}
                    >
                        <View style={[
                            tabletStyles.cardContainer,
                            isHighlight(item.id) ? tabletStyles.cardContainerHighlight : null
                        ]}>
                            <View style={[tabletStyles.card]}>
                                <Image source={item.icon} style={mobileStyles.tinyLogo} />
                                <Text style={tabletStyles.titleList}>{renderItemTite ? renderItemTite(item.key) : item.title}</Text>
                                {
                                    isHighlight(item.id)
                                    ? <CheckBox
                                        onPress={() => {
                                            handleChange(item.id);
                                        }}
                                        iconType='font-awesome'
                                        checkedIcon='check'
                                        checkedColor='green'
                                        size={20}
                                        checked={true}
                                    />
                                    : null
                                }
    
                            </View>
                        </View>
                    </TouchableOpacity>
                )}
            />
        );
    };

    if (isMobileDevice) {
        return (
            <View>
                <View style={mobileStyles.cardHeader}>
                    {
                        selectedAll === false || selected
                        ?   <CheckBox
                                checked={true}
                                containerStyle={styles.checkList}
                                onPress={handleUnSelectAll}
                                checkedIcon={
                                    <Image source={imgTick} style={globalStyles.Icon25} />
                                }
                                size={20}
                            />
                        :   <CheckBox
                                checked={true}
                                containerStyle={styles.checkList}
                                onPress={() => {
                                    setIsExpanded(true)
                                    handleAcceptAll()
                                }}
                                checkedIcon={
                                    <Image source={imgUntick} style={globalStyles.Icon25} />
                                }
                                size={20}
                            />
                    }
                    <TouchableOpacity style={{ flex: 1 }} onPress={() => handleExpand(!isExpanded)}>
                        <Text style={[mobileStyles.titleListHeader, { marginTop: Platform.OS === 'web' ? 0 : 5 }]}>{title}</Text>
                    </TouchableOpacity>
                    {isExpanded && (
                        <TouchableOpacity onPress={handleAcceptAll} disabled={selectedAllChecker === true ? true : false} >
                            <Text style={[selectedAllChecker === false ? styles.selectAll : styles.selectAllDisable]}>
                                Chọn hết
                            </Text>
                        </TouchableOpacity>
                    )}
                </View>
                {isExpanded && <View>{renderFlatList(products)}</View>}
            </View>
        );
    }

    if (isTabletDevice) {
        return (
            <View>
                <View style={tabletStyles.cardHeader}>
                    {
                        selectedAll === false || selected
                        ?   <CheckBox
                                checked={true}
                                containerStyle={tabletStyles.checkList}
                                onPress={handleRemove}
                                checkedIcon={
                                    <Image source={imgTick} style={globalStyles.Icon25} />
                                }
                                size={20}
                            />
                        : null
                    }
                    <Text style={tabletStyles.titleListHeader}>{title}</Text>
                    <TouchableOpacity onPress={handleAcceptAll} disabled={selectedAllChecker === true ? true : false} >
                        <Text style={[selectedAllChecker === false ? tabletStyles.selectAll : tabletStyles.selectAllDisable]}>
                            Chọn hết
                        </Text>
                    </TouchableOpacity>
                </View>
                <View>{renderFlatList(products)}</View>
            </View>
        );
    }

    return (
        <View>
            <View style={styles.cardHeader}>
                {
                    selectedAll === false || selected
                    ?   <CheckBox
                            checked={true}
                            containerStyle={styles.checkList}
                            onPress={handleRemove}
                            checkedIcon={
                                <Image source={imgTick} style={globalStyles.Icon25} />
                            }
                            size={20}
                        />
                    : null
                }
                <Text style={styles.titleListHeader}>{title}</Text>
                <TouchableOpacity onPress={handleAcceptAll} disabled={selectedAllChecker === true ? true : false} >
                    <Text style={[selectedAllChecker === false ? styles.selectAll : styles.selectAllDisable]}>
                        Chọn hết
                    </Text>
                </TouchableOpacity>
            </View>
            <View>{renderFlatList(products)}</View>
        </View>
    );
}

export default PanelSelect