import React from 'react'
import { View, Text, TextInput, Image, Platform, ScrollView, LogBox, KeyboardAvoidingView } from 'react-native'
import { RootStackScreenProps } from '../../types'
import {
  styles as globalStyles,
  Block,
  PanelSelect,
  StepButton,
  SizedBox,
  ProvideYourInfoButton,
  BackButton,
  Dialog,
  GenderSwitch,
  ValidateRequiredError,
  ExpandableSelect,
  FilledButton,
  mathHelper,
} from '../../common'
import {
  DEFAULT_CUSTOMER_GENDER,
  martialStatusOptions,
  needEducationDataList,
  needHealthCareDataList,
  needProtectDataList,
  needRetirePreparationDataList,
  otherNeedDataList,
  productTypes,
  resetCnaData,
  yesNoOptions,
} from '../../constants/Data'
import { Controller, useForm } from 'react-hook-form'
import i18n from 'i18n-js'
import { RootState } from '../../store'
import { saveNeedsCart, updateProfile, clearCart } from '../../slices/needsCartSlice'
import { connect, ConnectedProps } from 'react-redux'
import ProfileInfo from '../../model/ProfileInfo'
import { useIsFocused } from '@react-navigation/native'
import icWarning from '../../assets/images/warning.png'
import NumberFormat from 'react-number-format'
import Needs from '../../model/Needs'
import { saveCNA, getCachedCNA } from '../../slices/cnaSlice'
import toastService from '../../services/toast/ToastService'
import { NeedsCategory, ViewMode, PageNumberMapping, AgentOffice } from '../../constants/Enum'
import StepAppHeader from '../../components/StepAppHeader'
import { CreateCnaPageStep, getStatusToSave } from '../../helper/CnaStatusWorkFlow'
import MobileStepIndicator from '../../components/MobileStepIndicator'
import CommonAppBar from '../../components/CommonAppBar'
import { SafeAreaView } from 'react-native-safe-area-context'
import Layout from '../../constants/Layout'
import imgMoneyBag from '../../assets/images/money_bag.png'
import imgCoin from '../../assets/images/coin.png'
import imgCalendar2 from '../../assets/images/calendar-2.png'
import { RadioListTitle } from '../../common/components/RadioListTitle'
import { MAX_AGE, MAX_MONTHLY_INCOME, MIN_AGE, MIN_NEED_TERM } from '../../constants/Constants'
import ConfirmSavePopUp from '../../components/ConfirmSavePopUp'
import _ from 'lodash'
import dataProvider from '../../data/dataProvider'
import { desktopStyles, tabletStyles, mobileStyles } from './styles'
import AgentComponent from 'components/AgentComponent'
import { i18nAgentWording } from 'components/AgentWording'

const mapStateToProps = (state: RootState) => ({
  needsCartList: state.needsCart.needsCartList,
  userInfo: state?.user?.userInfo,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  customerProfile: state?.needsCart.profileInfo,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
  loadingSaveDraft: state?.cna.loadingSaveDraft,
})

const mapDispatchToProps = {
  saveNeedsCart,
  updateProfile,
  clearCart,
  saveCNA,
  getCachedCNA,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'NeedsCart'> & PropsFromRedux & {}

function NeedsCartScreen({
  route,
  navigation,
  saveNeedsCart,
  needsCartList,
  updateProfile,
  userInfo,
  clearCart,
  saveCNA,
  currentHandlingCNA,
  customerProfile,
  loadingSaveContinue,
  getCachedCNA,
  loadingSaveDraft,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const scrollRef = React.useRef<ScrollView>()

  const [showEditProfile, setShowEditProfile] = React.useState(false)
  const [showCancelWarningModel, setShowCancelWarningModel] = React.useState(false)
  const [maritalStatusIndexSelected, setMaritalStatusIndexSelected] = React.useState<number>()
  const [showContentPopUpUpdateLate, setShowContentPopUpUpdateLate] = React.useState(false)
  const [, setGender] = React.useState('FEMALE')
  const [productType, setProductType] = React.useState<{ value: string; text: string } | undefined>()
  const [showConfirmLeavingAlert, setShowConfirmLeavingAlert] = React.useState(false)
  const [monthlyIncome, setMonthlyIncome] = React.useState(0)
  
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    shouldUnregister: false,
  })
  const isFocused = useIsFocused()
  React.useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
  }, [])

  React.useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      // Object.values(errors)[0].ref.scrollIntoView({ behavior: 'smooth' })
      // Object.values(errors)[0].ref.focus()
    }
  }, [errors])

  const onError = (errors: any, e: any) => {
    scrollRef.current?.scrollTo({ y: 0, x: 0, animated: true })
  }

  React.useEffect(() => {
    setShowCancelWarningModel(false)
    const id = (route.params as any)?.id
    if (id && isFocused) {
      getCachedCNA({ id })
    } else {
      clearCart({})
      reset({
        age: '',
        gender: '',
        maritalStatus: '',
        monthlyIncome: '',
        monthlyExpense: '',
      })
      setMaritalStatusIndexSelected(undefined)
    }
  }, [isFocused])

  React.useEffect(() => {
    setTimeout(() => {
      setValue('age', customerProfile?.age ? customerProfile?.age : undefined)
      setValue('gender', customerProfile?.gender)
      setValue('maritalStatus', customerProfile?.maritalStatus)
      setValue('monthlyIncome', customerProfile?.monthlyIncome ? customerProfile?.monthlyIncome : undefined)
      setValue('monthlyExpense', customerProfile?.monthlyExpense ? customerProfile?.monthlyExpense : undefined)
      setMaritalStatusIndexSelected(martialStatusOptions.findIndex((e) => e.value === customerProfile?.maritalStatus))
    }, 500)
  }, [customerProfile])

  React.useEffect(() => {
    setTimeout(() => {
      setValue('needTerm', currentHandlingCNA?.needTerm)
      setValue('reminiscentName', currentHandlingCNA?.reminiscentName ? currentHandlingCNA?.reminiscentName : undefined)
      setValue('extraCustomerInfo.isBorrowed', currentHandlingCNA?.extraCustomerInfo?.isBorrowed)
      const product = productTypes.find((e) => e.value === currentHandlingCNA?.product)
      setProductType(product)
      setValue('product', product)
    }, 500)
  }, [currentHandlingCNA])

  function checkEditable() {
    return (route.params as any)?.mode !== ViewMode.READ_ONLY
  }

  function onChangeGender(code: string) {
    setGender(code)
    setValue('gender', code)
  }

  async function onSubmit(data: any) {
    let tempSelectNeedsData: Array<any> = []
    needsCartList.forEach((e) => {
      if (e.data) {
        e.data.forEach((e) => {
          if (e.isTemporaryCheck || e.isChecked) {
            tempSelectNeedsData.push(e)
          }
        })
      }
    })

    let categoryCodes = [...new Set(tempSelectNeedsData.map((e) => e.code))]
    if (categoryCodes.length === 1 && tempSelectNeedsData[0].code === NeedsCategory.MEDICINE) {
      setShowContentPopUpUpdateLate(true)
      return
    }
    updateProfile(data as ProfileInfo)
      .unwrap()
      .then(() => {
        onSaveCna(false, true)
          .unwrap()
          .then((data) => {
            const id = (route.params as any)?.id
            if (!id) {
              navigation.navigate('NeedsCart', { ...(route.params as any), id: data?.id })
              setTimeout(() => {
                navigation.push('CustomerNeed', { ...(route.params as any), id: data?.id })
              }, 100)
            } else {
              navigation.setParams({ ...(route.params as any) })
              navigation.push('CustomerNeed', { ...(route.params as any) })
            }
          })
          .catch((err) => toastService.error(i18n.t('MESS.UNKNOWN_ERROR')))
      })
  }

  function onBack() {
    if (checkDataHaveBeenSaved()) {
      if (isMobileDevice) {
        navigation.replace('MobileHome')
      } else {
        navigation.replace('AfterLogIn')
      }
    } else {
      setShowConfirmLeavingAlert(true)
    }
  }

  function onSaveDraft(data?: any) {
    onSaveCna(true)
      .unwrap()
      .then((data: any) => {
        const id = (route.params as any)?.id
        if (!id) {
          navigation.replace('NeedsCart', { ...(route.params as any), id: data?.id })
        }
      })
  }

  function onUpdateNeedsCart(code: string, data: any[]) {
    saveNeedsCart({ code, data })
  }

  function getTempSelectedNeedsItemId(needsCartList: Array<Needs>, code: string) {
    return needsCartList
      .find((e) => e.code === code)
      ?.data?.map((e) => {
        if (e.isTemporaryCheck) {
          return e.id
        }
      })
      .filter((e) => e !== undefined)
  }

  function getSelectedNeedsItemId(needsCartList: Array<Needs>, code: string) {
    return needsCartList
      .find((e) => e.code === code)
      ?.data?.map((e) => {
        if (e.isChecked) {
          return e.id
        }
      })
      .filter((e) => e !== undefined)
  }

  function onSaveCna(isSavingDraft?: boolean, isSavingContinue?: boolean) {
    let body = getDisplayingCNAData(isSavingDraft, isSavingContinue)
    return saveCNA(body)
  }

  function checkDataAppendProtectEdu() {
    const dataAppend = [...Array(Number(currentHandlingCNA?.protectEdu?.children)).keys()]
    const dataChild = currentHandlingCNA?.eduPlan?.childrenDetails || []
    const dataAdd = dataAppend?.map((x, index) => {
      return (
        dataChild[index] || {
          isSchool: false,
          isUniversity: false,
          isAfterUni: false,
          childAge: 0,
          schoolFees: 0,
          schoolYears: 0,
          universityFees: 0,
          universityYears: 0,
          afterUniFees: 0,
          afterUniYears: 0,
        }
      )
    })

    const dataEduPlan =
      (dataAdd &&
        dataAdd.map((x, index) => ({
          ...x,
          childAge: currentHandlingCNA?.protectEdu?.childrenDetails[index].childAge,
        }))) ||
      1
    return dataEduPlan
  }

  function checkDataAppendEduPlan() {
    const dataAppend = [...Array(Number(currentHandlingCNA?.eduPlan?.children)).keys()]
    const dataChild = currentHandlingCNA?.protectEdu?.childrenDetails || []
    const dataAdd = dataAppend.map((x, index) => {
      return (
        dataChild[index] || {
          childAge: 0,
          tuition: 0,
        }
      )
    })

    const dataEduPlan =
      (dataAdd &&
        dataAdd.map((x, index) => ({
          ...x,
          childAge: currentHandlingCNA?.eduPlan?.childrenDetails[index].childAge,
        }))) ||
      1
    return dataEduPlan
  }

  function checkContinueAvailability() {
    let continueAvailability = false
    for (let category of needsCartList) {
      if (category?.data?.find((e) => e.isTemporaryCheck === true)) {
        continueAvailability = true
        break
      }
    }
    return continueAvailability
  }

  function renderMobileAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('NEEDS_CART.NEW')}
        navigation={navigation}
        onBack={onBack}
        onGoHome={() => onSaveCna(true)}
      />
    )
  }

  function onUpdateProduct(key?: string) {
    let product = productTypes.find((e) => e.value === key)
    setProductType(product)
    setValue('product', product?.value)
  }

  function getDisplayingCNAData(isSavingDraft?: boolean, isSavingContinue?: boolean) {
    let body = {
      ...currentHandlingCNA,
      isSavingDraft: isSavingDraft,
      isSavingContinue: isSavingContinue,
      id: currentHandlingCNA?._id,
      agentCode: userInfo?.sub,
      extraCustomerInfo: {
        isBorrowed: getValues('extraCustomerInfo.isBorrowed'),
        bank: getValues('extraCustomerInfo.bank'),
      },
      lastEditStep: !isSavingDraft ? PageNumberMapping.CustomerNeed : PageNumberMapping.NeedsCart,
      customerGender: getValues('gender') ?? DEFAULT_CUSTOMER_GENDER,
      customerAge: getValues('age') ? parseInt(getValues('age')) : 0,
      maritalStatus: getValues('maritalStatus'),
      reminiscentName: getValues('reminiscentName'),
      needTerm: getValues('needTerm') ? parseInt(getValues('needTerm')) : 0,
      product: productType?.value,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.NEEDS_CART,
        currentHandlingCNA?.customerPhone,
        isSavingDraft
      ),
    } as any
    // If user changed product type, need to reset topup, quotation, ape value
    if (productType?.value !== currentHandlingCNA?.product) {
      body = {
        ...body,
        topUp: 0,
        quotations: null,
        ape: 0,
        apeSub: 0,
      }
    }
    // If user changed gender or age, need to update healthcare plan value with below rule:
    // If currentHandlingCNA?.hcPlan?.hcPlanDetail.answer is D (Chương trình chăm sóc hoàn hảo),
    // user's gender is FEMALE and user's age is between 18-49, then healthcare plan should include
    // maternity value
    if (currentHandlingCNA?.hcPlan?.hcPlanDetail) {
      let healthcarePlanDataList = dataProvider.getHospitalDataBasedOnAgeAndGender(
        parseInt(getValues('age')),
        getValues('gender')
      )
      let healthcarePlanData = healthcarePlanDataList.find(
        (e) => e.answer === currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer
      )
      let hcPlan = {
        ...currentHandlingCNA?.hcPlan,
        isTemporaryCheck: currentHandlingCNA?.hcPlan?.isTemporaryCheck,
        isChecked: currentHandlingCNA?.hcPlan?.isSelect,
        hcPlanDetail: healthcarePlanData,
        hcPlanDetailSub: healthcarePlanData,
        subTotal: dataProvider.countTotalHealthCarePlanData({
          ...healthcarePlanData,
          age: parseInt(getValues('age')),
          gender: getValues('gender'),
        }),
        total: dataProvider.countTotalHealthCarePlanData({
          ...healthcarePlanData,
          age: parseInt(getValues('age')),
          gender: getValues('gender'),
        }),
      }
      body = {
        ...body,
        hcPlan: hcPlan,
      }
    }
    if (!getValues('maritalStatus')) {
      delete body.maritalStatus
    }
    needsCartList.forEach((e) => {
      if (e && e.data && e.data.length) {
        e.data.forEach((f) => {
          if (f) {
            let obj = body[f.key]
            if (f.isChecked && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: true,
                  isTemporaryCheck: true,
                }
              } else {
                body[f.key] = {
                  isSelect: true,
                  isTemporaryCheck: true,
                }
              }
            } else if (!f.isChecked && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: false,
                  isTemporaryCheck: true,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: true,
                }
              }
            } else if (!f.isChecked && !f.isTemporaryCheck) {
              let resetObj = (resetCnaData as any)[f.key]
              if (resetObj) {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                  ...resetObj,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                }
              }
            }
          }
        })
      }
    })
    let eduPlan = body.eduPlan
    let protectEdu = body.protectEdu

    if (
      protectEdu?.childrenDetails &&
      !eduPlan?.childrenDetails &&
      eduPlan?.isTemporaryCheck === true &&
      protectEdu?.isTemporaryCheck === true
    ) {
      body.eduPlan = {
        ...body.eduPlan,
        children: body.protectEdu?.children,
        total: 0,
        childrenDetails: checkDataAppendProtectEdu(),
      }
    }
    if (
      eduPlan?.childrenDetails &&
      !protectEdu?.childrenDetails &&
      protectEdu?.isTemporaryCheck === true &&
      protectEdu?.isTemporaryCheck === true
    ) {
      body.protectEdu = {
        ...body.eduPlan,
        children: body.eduPlan?.children,
        total: 0,
        childrenDetails: checkDataAppendEduPlan(),
      }
    }
    if (body.protectIncome?.isSelect) {
      let monthlyIncome = getValues('monthlyIncome')
      let protectIncomeMonths = body.protectIncome?.month ?? 0
      body.protectIncome = {
        ...body.protectIncome,
        monthlyIncome: monthlyIncome ? parseFloat(monthlyIncome) : null,
        subTotal: monthlyIncome && protectIncomeMonths ? monthlyIncome * protectIncomeMonths : 0,
      }
      if (monthlyIncome && currentHandlingCNA?.protectIncome?.monthlyIncome !== parseFloat(monthlyIncome)) {
        body.protectIncome = {
          ...body.protectIncome,
          total: mathHelper.countProtectIncomeTotal(parseFloat(monthlyIncome), body.protectIncome.month),
        }
      }
    }
    if (body.protectLife?.isSelect) {
      let monthlyFixedExpense = getValues('monthlyExpense')
      let protectLifeMonths = body.protectLife?.month ?? 0
      let isSubsidy = body.protectLife?.isSubsidy
      let monthlySubsidy = body.protectLife?.monthlySubsidy
      let subsidyMonth = body.protectLife?.subsidyMonth
      let protectLifeTotal = monthlyFixedExpense && protectLifeMonths ? monthlyFixedExpense * protectLifeMonths : 0
      if (isSubsidy && subsidyMonth && monthlySubsidy) {
        protectLifeTotal += subsidyMonth * monthlySubsidy
      }
      body.protectLife = {
        ...body.protectLife,
        monthlyFixedExpense: getValues('monthlyExpense') ? parseFloat(getValues('monthlyExpense')) : null,
        subTotal: protectLifeTotal,
      }
      if (
        monthlyFixedExpense &&
        currentHandlingCNA?.protectLife?.monthlyFixedExpense !== parseFloat(monthlyFixedExpense)
      ) {
        body.protectLife = {
          ...body.protectLife,
          total: mathHelper.countProtectLifeTotal(
            parseFloat(monthlyFixedExpense),
            body.protectLife.month,
            body.protectLife.monthlySubsidy,
            body.protectLife.subsidyMonth
          ),
        }
      }
    }
    if (body.retirement?.isSelect || body.retirement?.isTemporaryCheck) {
      let customerAge = getValues('age')
      let retiredAge = body.retirement?.age ?? 0
      let duration = body.retirement?.duration ?? 0
      let countDown = (retiredAge ?? 0) - (customerAge ?? 0)
      if (customerAge && retiredAge) {
        body.retirement = {
          ...body.retirement,
          countDown: countDown,
          subTotal: mathHelper.countRetirementAnnualTotal(duration, body.retirement?.spending),
        }
      }
      if (customerAge && currentHandlingCNA?.ape !== parseInt(customerAge)) {
        body.retirement = {
          ...body.retirement,
          total: mathHelper.countRetirementAnnualTotal(duration, body.retirement?.spending),
        }
      }
    }
    return body
  }

  function checkDataHaveBeenSaved() {
    if (!currentHandlingCNA?._id) {
      return false
    }
    let displayingCNAData = getDisplayingCNAData(false, false)
    let initData = { ...currentHandlingCNA }
    delete initData.agentCode
    delete initData.lastEditStep
    delete displayingCNAData.isSavingDraft
    delete displayingCNAData.isSavingContinue
    delete displayingCNAData.id
    delete displayingCNAData.lastEditStep
    delete displayingCNAData.agentCode
    if (_.isEqual(initData, displayingCNAData)) {
      return true
    }
    return false
  }

  function renderErrorMessage(error?: string, style?: any) {
    if (error) {
      return (
        <View style={globalStyles.FloatRight}>
          <ValidateRequiredError title={error} style={style} />
        </View>
      )
    } else {
      return <></>
    }
  }

  function renderProfileInfoTab() {
    if (isMobileDevice) {
      return (
        <View>
          <View style={mobileStyles.headerContainer}>
            <Text style={mobileStyles.headerLabel}>{i18n.t('NEEDS_CART.YOUR_INFO')}</Text>
          </View>
          <View style={mobileStyles.container} pointerEvents={checkEditable() ? 'auto' : 'none'}>
            {/* Gender */}
            <View style={mobileStyles.genderContainer}>
              <Text style={mobileStyles.formItemLabel}>{i18n.t('NEEDS_CART.YOUR_GENDER_ARE')}</Text>
              <GenderSwitch value={getValues('gender')} onChange={onChangeGender} />
            </View>
            {/* Name */}
            <View style={mobileStyles.formItemContainer}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <View style={globalStyles.DisplayFlexRow}>
                      <Text style={mobileStyles.textInputLabel}>{i18n.t('NEEDS_CART.NAME')}</Text>
                      <Text style={globalStyles.RedText}>&nbsp;*</Text>
                    </View>
                    <View style={mobileStyles.textInputContainer}>
                      <TextInput
                        style={mobileStyles.textInput}
                        placeholder={i18n.t('COMMON.INPUT')}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    </View>
                  </View>
                )}
                name="reminiscentName"
                defaultValue=""
              />
              {renderErrorMessage(errors?.reminiscentName?.message)}
            </View>
            {/* Age */}
            <View style={mobileStyles.formItemContainer}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED'),
                  },
                  min: {
                    value: MIN_AGE,
                    message: i18n.t('VALIDATE.AGE'),
                  },
                  max: {
                    value: currentHandlingCNA?.retirement?.age ? currentHandlingCNA?.retirement?.age - 1 : MAX_AGE,
                    message:
                      (currentHandlingCNA?.retirement?.isTemporaryCheck || currentHandlingCNA?.retirement?.isSelect) &&
                      currentHandlingCNA?.retirement?.age - 1
                        ? i18n.t('VALIDATE.AGE_MUST_LOWER_THAN_RETIREMENT_AGE')
                        : i18n.t('VALIDATE.AGE'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <View style={globalStyles.DisplayFlexRow}>
                      <Text style={mobileStyles.textInputLabel}>{i18n.t('NEEDS_CART.AGE')}</Text>
                      <Text style={globalStyles.RedText}>&nbsp;*</Text>
                    </View>
                    <View style={mobileStyles.textInputContainer}>
                      <NumberFormat
                        placeholder={i18n.t('COMMON.INPUT')}
                        style={{
                          fontSize: 13,
                          fontWeight: 600,
                          flex: 1,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderWidth: 0,
                          borderColor: '#fff',
                          borderRadius: 20,
                        }}
                        value={value}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={0}
                        prefix={''}
                        disabled={!checkEditable()}
                        displayType="text"
                        onValueChange={(values) => {
                          const { value } = values

                          onChange(value)
                          setValue('age', value)
                        }}
                        renderText={(value) => (
                          <TextInput
                            underlineColorAndroid="transparent"
                            style={mobileStyles.textInput}
                            placeholder={i18n.t('COMMON.INPUT')}
                            onChangeText={(value) => {
                              onChange(value)
                              setValue('age', value)
                            }}
                            value={value}
                            keyboardType="numeric"
                          />
                        )}
                      />
                    </View>
                  </View>
                )}
                name="age"
                defaultValue=""
              />
              {renderErrorMessage(errors?.age?.message)}
            </View>
            {/* Marital status */}
            <View style={mobileStyles.maritalStatusContainer}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <View style={globalStyles.DisplayFlexRow}>
                      <Text style={mobileStyles.textInputLabel}>{i18n.t('NEEDS_CART.MARITAL_STATUS')}</Text>
                      <Text style={globalStyles.RedText}>&nbsp;*</Text>
                    </View>
                    <ExpandableSelect<any>
                      data={martialStatusOptions}
                      onSelect={(value: any, index: number) => {
                        setMaritalStatusIndexSelected(index)
                        onChange(value.value)
                      }}
                      currentSelectIndex={maritalStatusIndexSelected}
                      renderItem={(item: any) => {
                        return <Text style={mobileStyles.itemSelect}>{item?.label}</Text>
                      }}
                      height={155}
                    />
                  </View>
                )}
                name="maritalStatus"
              />
              {renderErrorMessage(errors?.maritalStatus?.message)}
            </View>
            {/* Extra customer information */}
            <AgentComponent
              agentOfficeCode={userInfo?.officeCode}
              agentComponentMap={
                new Map([
                  [
                    AgentOffice.VIB,
                    <View style={[mobileStyles.formItemContainer, { marginTop: 10 }]}>
                      <Controller
                        control={control}
                        rules={{
                          required:
                            watch('extraCustomerInfo.isBorrowed') === undefined
                              ? {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                }
                              : false,
                        }}
                        render={({ onChange, onBlur, value }) => (
                          <View>
                            <View style={globalStyles.DisplayFlexRow}>
                              <Text style={mobileStyles.textInputLabel}>{i18n.t('NEEDS_CART.IS_BORROWER_AT_VIB')}</Text>
                              <Text style={globalStyles.RedText}>&nbsp;*</Text>
                            </View>
                            <RadioListTitle
                              title=""
                              data={yesNoOptions}
                              direction="row"
                              defaultValue={value}
                              onChange={(key) => {
                                setValue('extraCustomerInfo.isBorrowed', key === true)
                                setValue('extraCustomerInfo.bank', AgentOffice.VIB)
                                clearErrors('extraCustomerInfo.isBorrowed')
                              }}
                              containerStyle={{
                                marginTop: 5,
                                marginBottom: 5,
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                              }}
                              radioItemContainerStyle={{
                                width: '50%',
                                minWidth: 'none',
                                maxWidth: 'none',
                                justifyContent: 'flex-start',
                                marginRight: 16,
                                fontSize: 16,
                              }}
                              error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                            />
                          </View>
                        )}
                        name="extraCustomerInfo.isBorrowed"
                      />
                      {renderErrorMessage(errors?.extraCustomerInfo?.isBorrowed?.message)}
                    </View>,
                  ],
                ])
              }
              renderDefault={(agentOfficeCode) => <View></View>}
            />
          </View>
          <View style={mobileStyles.continueButton}>
            <FilledButton
              title={i18n.t('COMMON.CONTINUE')}
              onPress={handleSubmit(onSubmit)}
              disabled={!checkContinueAvailability()}
              buttonStyle={{
                width: 100,
                height: 41,
                paddingHorizontal: 16,
                paddingVertical: 10,
                borderRadius: 8,
                marginTop: 32,
              }}
              titleStyle={{ fontSize: 14 }}
              loading={loadingSaveContinue}
            />
          </View>
        </View>
      )
    }

    return (
      <View style={desktopStyles.profileContainer}>
        <View style={desktopStyles.headerContainer}>
          <Text style={desktopStyles.headerLabel}>{i18n.t('NEEDS_CART.YOUR_INFO')}</Text>
        </View>
        <View style={globalStyles.CommonPadding12}>
          {/* Gender */}
          <View style={desktopStyles.genderContainer}>
            <Text style={desktopStyles.formItemLabel}>{i18n.t('NEEDS_CART.YOUR_GENDER_ARE')}</Text>
            <GenderSwitch value={getValues('gender')} onChange={onChangeGender} />
          </View>
          {/* Name */}
          <View style={desktopStyles.formItemContainer}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={desktopStyles.textInputLabel}>{i18n.t('NEEDS_CART.NAME')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={desktopStyles.textInputContainer}>
                    <TextInput
                      style={desktopStyles.textInput}
                      placeholder={i18n.t('COMMON.INPUT')}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                </View>
              )}
              name="reminiscentName"
              defaultValue=""
            />
            {renderErrorMessage(errors?.reminiscentName?.message)}
          </View>
          {/* Age */}
          <View style={desktopStyles.formItemContainer}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED'),
                },
                min: {
                  value: MIN_AGE,
                  message: i18n.t('VALIDATE.AGE'),
                },
                max: {
                  value: currentHandlingCNA?.retirement?.age ? currentHandlingCNA?.retirement?.age - 1 : MAX_AGE,
                  message:
                    (currentHandlingCNA?.retirement?.isTemporaryCheck || currentHandlingCNA?.retirement?.isSelect) &&
                    currentHandlingCNA?.retirement?.age - 1
                      ? i18n.t('VALIDATE.AGE_MUST_LOWER_THAN_RETIREMENT_AGE')
                      : i18n.t('VALIDATE.AGE'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={desktopStyles.textInputLabel}>{i18n.t('NEEDS_CART.AGE')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={desktopStyles.textInputContainer}>
                    <NumberFormat
                      placeholder={i18n.t('COMMON.INPUT')}
                      style={{
                        fontSize: 16,
                        flex: 1,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 16,
                        paddingBottom: 16,
                        borderWidth: 0,
                        borderColor: '#fff',
                        borderRadius: 20,
                        backgroundColor: '#FAFBFF',
                      }}
                      value={value}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={0}
                      prefix={''}
                      displayType="text"
                      onValueChange={(values) => {
                        const { value } = values
                        onChange(value)
                        setValue('age', value)
                      }}
                      renderText={(value) => (
                        <TextInput
                          underlineColorAndroid="transparent"
                          style={desktopStyles.textInput}
                          placeholder={i18n.t('COMMON.INPUT')}
                          onChangeText={(value) => {
                            onChange(value)
                            setValue('age', value)
                          }}
                          value={value}
                          keyboardType="numeric"
                        />
                      )}
                    />
                  </View>
                </View>
              )}
              name="age"
              defaultValue=""
            />
            {renderErrorMessage(errors?.age?.message)}
          </View>
          {/* Marital status */}
          <View style={desktopStyles.maritalStatusContainer}>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED'),
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={desktopStyles.textInputLabel}>{i18n.t('NEEDS_CART.MARITAL_STATUS')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <ExpandableSelect<any>
                    data={martialStatusOptions}
                    onSelect={(value: any, index: number) => {
                      setMaritalStatusIndexSelected(index)
                      onChange(value.value)
                    }}
                    currentSelectIndex={maritalStatusIndexSelected}
                    renderItem={(item: any) => {
                      return <Text style={desktopStyles.itemSelect}>{item?.label}</Text>
                    }}
                    height={155}
                  />
                </View>
              )}
              name="maritalStatus"
            />
            {renderErrorMessage(errors?.maritalStatus?.message)}
          </View>
          {/* Extra customer information */}
          <AgentComponent
            agentOfficeCode={userInfo?.officeCode}
            agentComponentMap={
              new Map([
                [
                  AgentOffice.VIB,
                  <View style={[desktopStyles.formItemContainer, { marginTop: 10 }]}>
                    <Controller
                      control={control}
                      rules={{
                        required:
                          watch('extraCustomerInfo.isBorrowed') === undefined
                            ? {
                                value: true,
                                message: i18n.t('VALIDATE.REQUIRED'),
                              }
                            : false,
                      }}
                      render={({ onChange, onBlur, value }) => (
                        <View>
                          <View style={globalStyles.DisplayFlexRow}>
                            <Text style={desktopStyles.textInputLabel}>{i18n.t('NEEDS_CART.IS_BORROWER_AT_VIB')}</Text>
                            <Text style={globalStyles.RedText}>&nbsp;*</Text>
                          </View>
                          <RadioListTitle
                            title=""
                            data={yesNoOptions}
                            direction="row"
                            defaultValue={value}
                            onChange={(key) => {
                              setValue('extraCustomerInfo.isBorrowed', key === true)
                              setValue('extraCustomerInfo.bank', AgentOffice.VIB)
                              clearErrors('extraCustomerInfo.isBorrowed')
                            }}
                            containerStyle={{
                              marginTop: 5,
                              marginBottom: 5,
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                            }}
                            radioItemContainerStyle={{
                              width: '70%',
                              minWidth: 'none',
                              maxWidth: 'none',
                              justifyContent: 'flex-start',
                              marginRight: 16,
                              fontSize: 16,
                            }}
                            error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                          />
                        </View>
                      )}
                      name="extraCustomerInfo.isBorrowed"
                    />
                    {renderErrorMessage(errors?.extraCustomerInfo?.isBorrowed?.message)}
                  </View>,
                ],
              ])
            }
            renderDefault={(agentOfficeCode) => <View></View>}
          />
        </View>
      </View>
    )
  }

  function renderModals() {
    return (
      <>
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showCancelWarningModel}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.KEEP_CANCEL')}
          cancelText={i18n.t('COMMON.CONTINUE')}
          onOk={() => {
            setShowCancelWarningModel(false)
            if (isMobileDevice) {
              navigation.navigate('MobileHome')
            } else {
              navigation.navigate('AfterLogIn')
            }
          }}
          onCancel={() => {
            setShowCancelWarningModel(false)
          }}
        >
          <Text></Text>
        </Dialog>
        {/* Warning pop update late  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showContentPopUpUpdateLate}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ maxWidth: 550, margin: 20, borderRadius: 12, paddingTop: 0 }}
          onClose={() => setShowContentPopUpUpdateLate(false)}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
              <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.Flex1]}>
                <Image style={globalStyles.Icon25} source={icWarning} />
                <Text style={globalStyles.DialogTitle}>{i18n.t('COMMON.WARNING')}</Text>
              </View>
            </View>
          }
        >
          <View style={globalStyles.Center}>
            <View style={{ maxWidth: 516, marginTop: 20, paddingHorizontal: 16 }}>
              <Text style={{ fontSize: 15, fontWeight: 'normal', textAlign: 'center', color: '#505D6F' }}>
                {i18n.t('NEEDS_CART.POP_UPDATE_LATE_CONTENT')}
              </Text>
            </View>
          </View>
        </Dialog>
        {/* Confirm leaving */}
        <ConfirmSavePopUp
          isShow={showConfirmLeavingAlert}
          onOk={() => {
            onSaveCna(true)
              ?.unwrap()
              .then((_) => {
                setShowConfirmLeavingAlert(false)
                navigation.replace('AfterLogIn')
              })
          }}
          onCancel={() => {
            setShowConfirmLeavingAlert(false)
            navigation.replace('AfterLogIn')
          }}
          onClose={() => setShowConfirmLeavingAlert(false)}
        />
      </>
    )
  }

  const Needs = () => {
    return (
      <>
        <PanelSelect
          title={i18n.t('NEEDS_CATEGORY.PROTECT')}
          data={needProtectDataList}
          code={NeedsCategory.PROTECT}
          onChange={onUpdateNeedsCart}
          tempSelectedList={getTempSelectedNeedsItemId(needsCartList, NeedsCategory.PROTECT)}
          selectedList={getSelectedNeedsItemId(needsCartList, NeedsCategory.PROTECT)}
          renderItemTite={(key: string) =>
            i18nAgentWording({ agentOfficeCode: userInfo?.officeCode, wordingCode: `NEEDS_ANALYST.LIST.${key}` })
          }
        />
        <SizedBox height={12} />
        <PanelSelect
          title={i18n.t('NEEDS_CATEGORY.EDUCATION')}
          data={needEducationDataList}
          code={NeedsCategory.EDUCATION}
          onChange={onUpdateNeedsCart}
          tempSelectedList={getTempSelectedNeedsItemId(needsCartList, NeedsCategory.EDUCATION)}
          selectedList={getSelectedNeedsItemId(needsCartList, NeedsCategory.EDUCATION)}
          renderItemTite={(key: string) =>
            i18nAgentWording({ agentOfficeCode: userInfo?.officeCode, wordingCode: `NEEDS_ANALYST.LIST.${key}` })
          }
        />
        <SizedBox height={12} />
        <PanelSelect
          title={i18n.t('NEEDS_CATEGORY.MEDICINE')}
          data={needHealthCareDataList}
          code={NeedsCategory.MEDICINE}
          onChange={onUpdateNeedsCart}
          tempSelectedList={getTempSelectedNeedsItemId(needsCartList, NeedsCategory.MEDICINE)}
          selectedList={getSelectedNeedsItemId(needsCartList, NeedsCategory.MEDICINE)}
          renderItemTite={(key: string) =>
            i18nAgentWording({ agentOfficeCode: userInfo?.officeCode, wordingCode: `NEEDS_ANALYST.LIST.${key}` })
          }
        />
        <SizedBox height={12} />
        <PanelSelect
          title={i18n.t('NEEDS_CATEGORY.RETIREMENT')}
          data={needRetirePreparationDataList}
          code={NeedsCategory.RETIREMENT}
          onChange={onUpdateNeedsCart}
          tempSelectedList={getTempSelectedNeedsItemId(needsCartList, NeedsCategory.RETIREMENT)}
          selectedList={getSelectedNeedsItemId(needsCartList, NeedsCategory.RETIREMENT)}
          renderItemTite={(key: string) =>
            i18nAgentWording({ agentOfficeCode: userInfo?.officeCode, wordingCode: `NEEDS_ANALYST.LIST.${key}` })
          }
        />
        <SizedBox height={12} />
        <PanelSelect
          title={i18n.t('NEEDS_CATEGORY.OTHER')}
          data={otherNeedDataList}
          code={NeedsCategory.OTHER}
          onChange={onUpdateNeedsCart}
          tempSelectedList={getTempSelectedNeedsItemId(needsCartList, NeedsCategory.OTHER)}
          selectedList={getSelectedNeedsItemId(needsCartList, NeedsCategory.OTHER)}
          renderItemTite={(key: string) =>
            i18nAgentWording({ agentOfficeCode: userInfo?.officeCode, wordingCode: `NEEDS_ANALYST.LIST.${key}` })
          }
        />
      </>
    )
  }

  const renderCustomerIncomeSurvey = () => {
    if (isMobileDevice) {
      return (
        <>
          <View style={mobileStyles.needsDataContainerBlock}>
            <Block
              title={i18n.t('NEEDS_CART.EXPECTATION_ABOUT_INSURANCE')}
              child={
                <View style={[mobileStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
                  <View style={mobileStyles.inputInfoContainer}>
                    <Text style={mobileStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.FINANCE_STATUS')}</Text>
                    <SizedBox height={22} />
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                        <View style={mobileStyles.inputBlockContainer}>
                          <View style={mobileStyles.inputBlockContainerLeft}>
                            <Image source={imgMoneyBag} style={globalStyles.Icon26} />
                            <Controller
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                },
                                min: {
                                  value: 0.001,
                                  message: i18n.t('NEEDS_CART.MIN_MONTHLY_INCOME'),
                                },
                                max: {
                                  value: MAX_MONTHLY_INCOME,
                                  message: i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                                },
                              }}
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  placeholder={i18n.t('NEEDS_CART.MONTHLY_INCOME_HINT_MOBILE')}
                                  style={{
                                    fontSize: 13,
                                    minWidth: '300px',
                                    marginLeft: 8,
                                    borderWidth: 0,
                                    borderColor: '#fff',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                  }}
                                  value={value}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={3}
                                  type="text"
                                  prefix={''}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    onChange(value)
                                    setValue('monthlyIncome', value)
                                    setMonthlyIncome(
                                      parseFloat(
                                        getValues('monthlyIncome') && getValues('monthlyIncome') !== ''
                                          ? getValues('monthlyIncome')
                                          : '0'
                                      )
                                    )
                                  }}
                                  renderText={(value) => (
                                    <TextInput
                                      underlineColorAndroid="transparent"
                                      placeholderTextColor="#E0E0E0"
                                      onChangeText={(value) => {
                                        onChange(value)
                                        setValue('monthlyIncome', value)
                                        setMonthlyIncome(
                                          parseFloat(
                                            getValues('monthlyIncome') && getValues('monthlyIncome') !== ''
                                              ? getValues('monthlyIncome')
                                              : '0'
                                          )
                                        )
                                      }}
                                      value={value}
                                      keyboardType="numeric"
                                    />
                                  )}
                                />
                              )}
                              name="monthlyIncome"
                              defaultValue=""
                            />
                          </View>
                          <View style={mobileStyles.inputBlockContainerRight}>
                            <Text style={mobileStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                          </View>
                        </View>
                        {renderErrorMessage(errors?.monthlyIncome?.message)}
                      </View>
                      <SizedBox height={20} />
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                        <View style={mobileStyles.inputBlockContainer}>
                          <View style={mobileStyles.inputBlockContainerLeft}>
                            <Image source={imgCoin} style={globalStyles.Icon26} />
                            <Controller
                              control={control}
                              rules={{
                                valueAsNumber: true,
                                required: {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                },
                                min: {
                                  value: 0.001,
                                  message: i18n.t('NEEDS_CART.MIN_MONTHLY_EXPENSE'),
                                },
                                max: {
                                  value: monthlyIncome,
                                  message: i18n.t('NEEDS_CART.MONTHLY_EXPENSE_NOT_GREATER_THAN_MONTHLY_INCOME'),
                                },
                              }}
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  placeholder={i18n.t('NEEDS_CART.MONTHLY_EXPENSES_HINT_MOBILE')}
                                  style={{
                                    fontSize: 13,
                                    minWidth: '300px',
                                    marginLeft: 8,
                                    borderWidth: 0,
                                    borderColor: '#fff',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                  }}
                                  value={value}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={3}
                                  type="text"
                                  prefix={''}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    onChange(value)
                                    setValue('monthlyExpense', value)
                                  }}
                                  renderText={(value) => (
                                    <TextInput
                                      underlineColorAndroid="transparent"
                                      placeholderTextColor="#E0E0E0"
                                      onChangeText={(value) => {
                                        onChange(value)
                                        setValue('monthlyExpense', value)
                                      }}
                                      value={value}
                                      keyboardType="numeric"
                                    />
                                  )}
                                />
                              )}
                              name="monthlyExpense"
                              defaultValue=""
                            />
                          </View>
                          <View style={mobileStyles.inputBlockContainerRight}>
                            <Text style={mobileStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                          </View>
                        </View>
                        {renderErrorMessage(errors?.monthlyExpense?.message)}
                      </View>
                    </View>
                  </View>
                </View>
              }
            />
          </View>
          <View style={[mobileStyles.needsDataContainerBlock]}>
            <Block
              showTitle={false}
              child={
                <View style={[mobileStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
                  <View style={mobileStyles.inputInfoContainer}>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <Text style={mobileStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INVESTMENT_EXPECTATION')}</Text>
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.AlignItemsRight]}>
                        <Controller
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: i18n.t('VALIDATE.REQUIRED'),
                            },
                          }}
                          render={({ onChange, onBlur, value }) => (
                            <RadioListTitle
                              title=""
                              data={productTypes}
                              direction="column"
                              defaultValue={productType?.value}
                              onChange={(key: string) => onUpdateProduct(key)}
                              containerStyle={{
                                width: '100%',
                                marginTop: 0,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                              }}
                              radioItemContainerStyle={{
                                minWidth: 'none',
                                maxWidth: 'none',
                                width: 'fit-content',
                                marginTop: 16,
                                justifyContent: 'flex-start',
                                marginRight: 16,
                              }}
                              error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                            />
                          )}
                          name="product"
                          defaultValue=""
                        />
                      </View>
                    </View>
                    <SizedBox height={20} />
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <Text style={mobileStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.NEED_TERM')}</Text>
                      <SizedBox height={16} />
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                        <View style={mobileStyles.inputBlockContainer}>
                          <View style={mobileStyles.inputBlockContainerLeft}>
                            <Image source={imgCalendar2} style={globalStyles.Icon26} />
                            <Controller
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                },
                                min: {
                                  value: MIN_NEED_TERM,
                                  message: i18n.t('VALIDATE.MIN_PLAN_TERM'),
                                },
                              }}
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  placeholder={i18n.t('NEEDS_CART.NEED_TERM_HINT')}
                                  style={{
                                    fontSize: 13,
                                    minWidth: '300px',
                                    marginLeft: 8,
                                    borderWidth: 0,
                                    borderColor: '#fff',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                  }}
                                  value={value}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={3}
                                  type="text"
                                  prefix={''}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    onChange(value)
                                    setValue('needTerm', value)
                                  }}
                                  renderText={(value) => (
                                    <TextInput
                                      underlineColorAndroid="transparent"
                                      placeholderTextColor="#E0E0E0"
                                      onChangeText={(value) => {
                                        onChange(value)
                                        setValue('needTerm', value)
                                      }}
                                      value={value}
                                      keyboardType="numeric"
                                    />
                                  )}
                                />
                              )}
                              name="needTerm"
                              defaultValue=""
                            />
                          </View>
                          <View style={mobileStyles.inputBlockContainerRight}>
                            <Text style={mobileStyles.unit}>{i18n.t('COMMON.YEAR')}</Text>
                          </View>
                        </View>
                        {renderErrorMessage(errors?.needTerm?.message)}
                      </View>
                    </View>
                  </View>
                </View>
              }
            />
          </View>
        </>
      )
    }
    if (isTabletDevice) {
      return (
        <>
          <View style={tabletStyles.needsDataContainerBlock}>
            <Block
              title={i18n.t('NEEDS_CART.EXPECTATION_ABOUT_INSURANCE')}
              child={
                <View style={[tabletStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
                  <View style={tabletStyles.inputInfoContainer}>
                    <Text style={tabletStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.FINANCE_STATUS')}</Text>
                    <SizedBox height={22} />
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                        <View style={tabletStyles.inputBlockContainer}>
                          <View style={tabletStyles.inputBlockContainerLeft}>
                            <Image source={imgMoneyBag} style={globalStyles.Icon26} />
                            <Controller
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                },
                                min: {
                                  value: 0.001,
                                  message: i18n.t('NEEDS_CART.MIN_MONTHLY_INCOME'),
                                },
                                max: {
                                  value: MAX_MONTHLY_INCOME,
                                  message: i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                                },
                              }}
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  placeholder={i18n.t('NEEDS_CART.MONTHLY_INCOME_HINT')}
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 600,
                                    minWidth: '300px',
                                    marginLeft: 17,
                                    borderWidth: 0,
                                    borderColor: '#fff',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                  }}
                                  value={value}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={3}
                                  type="text"
                                  prefix={''}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    onChange(value)
                                    setValue('monthlyIncome', value)
                                    setMonthlyIncome(
                                      parseFloat(
                                        getValues('monthlyIncome') && getValues('monthlyIncome') !== ''
                                          ? getValues('monthlyIncome')
                                          : '0'
                                      )
                                    )
                                  }}
                                  renderText={(value) => (
                                    <TextInput
                                      underlineColorAndroid="transparent"
                                      placeholderTextColor="#E0E0E0"
                                      onChangeText={(value) => {
                                        onChange(value)
                                        setValue('monthlyIncome', value)
                                        setMonthlyIncome(
                                          parseFloat(
                                            getValues('monthlyIncome') && getValues('monthlyIncome') !== ''
                                              ? getValues('monthlyIncome')
                                              : '0'
                                          )
                                        )
                                      }}
                                      value={value}
                                      keyboardType="numeric"
                                    />
                                  )}
                                />
                              )}
                              name="monthlyIncome"
                              defaultValue=""
                            />
                          </View>
                          <View style={tabletStyles.inputBlockContainerRight}>
                            <Text style={tabletStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                          </View>
                        </View>
                        {renderErrorMessage(errors?.monthlyIncome?.message)}
                      </View>
                      <SizedBox height={20} />
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                        <View style={tabletStyles.inputBlockContainer}>
                          <View style={tabletStyles.inputBlockContainerLeft}>
                            <Image source={imgCoin} style={globalStyles.Icon26} />
                            <Controller
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                },
                                min: {
                                  value: 0.001,
                                  message: i18n.t('NEEDS_CART.MIN_MONTHLY_EXPENSE'),
                                },
                                max: {
                                  value: monthlyIncome,
                                  message: i18n.t('NEEDS_CART.MONTHLY_EXPENSE_NOT_GREATER_THAN_MONTHLY_INCOME'),
                                },
                              }}
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  placeholder={i18n.t('NEEDS_CART.MONTHLY_EXPENSES_HINT')}
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 600,
                                    minWidth: '300px',
                                    marginLeft: 17,
                                    borderWidth: 0,
                                    borderColor: '#fff',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                  }}
                                  value={value}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={3}
                                  type="text"
                                  prefix={''}
                                  onValueChange={(values: any) => {
                                    const { value } = values

                                    onChange(value)
                                    setValue('monthlyExpense', value)
                                  }}
                                  renderText={(value) => (
                                    <TextInput
                                      underlineColorAndroid="transparent"
                                      placeholderTextColor="#E0E0E0"
                                      onChangeText={(value) => {
                                        onChange(value)
                                        setValue('monthlyExpense', value)
                                      }}
                                      value={value}
                                      keyboardType="numeric"
                                    />
                                  )}
                                />
                              )}
                              name="monthlyExpense"
                              defaultValue=""
                            />
                          </View>
                          <View style={tabletStyles.inputBlockContainerRight}>
                            <Text style={tabletStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                          </View>
                        </View>
                        {renderErrorMessage(errors?.monthlyExpense?.message)}
                      </View>
                    </View>
                  </View>
                </View>
              }
            />
          </View>
          <View style={[tabletStyles.needsDataContainerBlock]}>
            <Block
              showTitle={false}
              child={
                <View style={[tabletStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
                  <View style={tabletStyles.inputInfoContainer}>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <Text style={tabletStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INVESTMENT_EXPECTATION')}</Text>
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.AlignItemsRight]}>
                        <Controller
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: i18n.t('VALIDATE.REQUIRED'),
                            },
                          }}
                          render={({ onChange, onBlur, value }) => (
                            <RadioListTitle
                              title=""
                              data={productTypes}
                              direction="row"
                              defaultValue={productType?.value}
                              onChange={(key: string) => onUpdateProduct(key)}
                              containerStyle={{
                                width: '100%',
                                marginTop: 0,
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: 0,
                              }}
                              radioItemContainerStyle={{
                                minWidth: 'none',
                                maxWidth: 'none',
                                width: 'fit-content',
                                marginTop: 16,
                                alignItems: 'center',
                                marginRight: 16,
                              }}
                              error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                            />
                          )}
                          name="product"
                          defaultValue=""
                        />
                      </View>
                    </View>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <Text style={tabletStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.NEED_TERM')}</Text>
                      <SizedBox height={16} />
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                        <View style={tabletStyles.inputBlockContainer}>
                          <View style={tabletStyles.inputBlockContainerLeft}>
                            <Image source={imgCalendar2} style={globalStyles.Icon26} />
                            <Controller
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: i18n.t('VALIDATE.REQUIRED'),
                                },
                                min: {
                                  value: MIN_NEED_TERM,
                                  message: i18n.t('VALIDATE.MIN_PLAN_TERM'),
                                },
                              }}
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  placeholder={i18n.t('NEEDS_CART.NEED_TERM_HINT')}
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 600,
                                    minWidth: '300px',
                                    marginLeft: 17,
                                    borderWidth: 0,
                                    borderColor: '#fff',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                  }}
                                  value={value}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  type="text"
                                  prefix={''}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    onChange(value)
                                    setValue('needTerm', value)
                                  }}
                                  renderText={(value) => (
                                    <TextInput
                                      underlineColorAndroid="transparent"
                                      placeholderTextColor="#E0E0E0"
                                      onChangeText={(value) => {
                                        onChange(value)
                                        setValue('needTerm', value)
                                      }}
                                      value={value}
                                      keyboardType="numeric"
                                    />
                                  )}
                                />
                              )}
                              name="needTerm"
                              defaultValue=""
                            />
                          </View>
                          <View style={tabletStyles.inputBlockContainerRight}>
                            <Text style={tabletStyles.unit}>{i18n.t('COMMON.YEAR')}</Text>
                          </View>
                        </View>
                        {renderErrorMessage(errors?.needTerm?.message)}
                      </View>
                    </View>
                  </View>
                </View>
              }
            />
          </View>
        </>
      )
    }
    return (
      <>
        <View style={desktopStyles.needsDataContainerBlock}>
          <Block
            title={i18n.t('NEEDS_CART.EXPECTATION_ABOUT_INSURANCE')}
            child={
              <View style={[desktopStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
                <View style={desktopStyles.inputInfoContainer}>
                  <Text style={desktopStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.FINANCE_STATUS')}</Text>
                  <SizedBox height={22} />
                  <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <View style={desktopStyles.inputBlockContainer}>
                        <View style={desktopStyles.inputBlockContainerLeft}>
                          <Image source={imgMoneyBag} style={globalStyles.Icon26} />
                          <Controller
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: i18n.t('VALIDATE.REQUIRED'),
                              },
                              min: {
                                value: 0.001,
                                message: i18n.t('NEEDS_CART.MIN_MONTHLY_INCOME'),
                              },
                              max: {
                                value: MAX_MONTHLY_INCOME,
                                message: i18n.t('NEEDS_CART.MAX_MONTHLY_INCOME'),
                              },
                            }}
                            render={({ onChange, onBlur, value }) => (
                              <NumberFormat
                                placeholder={i18n.t('NEEDS_CART.MONTHLY_INCOME_HINT')}
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                  minWidth: '300px',
                                  marginLeft: 17,
                                  borderWidth: 0,
                                  borderColor: '#fff',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }}
                                value={value}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={3}
                                isNumericString={true}
                                type="text"
                                prefix={''}
                                onValueChange={(values: any) => {
                                  const { value } = values
                                  onChange(value)
                                  setValue('monthlyIncome', value)
                                  setMonthlyIncome(
                                    parseFloat(
                                      getValues('monthlyIncome') && getValues('monthlyIncome') !== ''
                                        ? getValues('monthlyIncome')
                                        : '0'
                                    )
                                  )
                                }}
                                renderText={(value) => (
                                  <TextInput
                                    underlineColorAndroid="transparent"
                                    placeholderTextColor="#E0E0E0"
                                    onChangeText={(value) => {
                                      onChange(value)
                                      setValue('monthlyIncome', value)
                                      setMonthlyIncome(
                                        parseFloat(
                                          getValues('monthlyIncome') && getValues('monthlyIncome') !== ''
                                            ? getValues('monthlyIncome')
                                            : '0'
                                        )
                                      )
                                    }}
                                    value={value}
                                    keyboardType="numeric"
                                  />
                                )}
                              />
                            )}
                            name="monthlyIncome"
                            defaultValue=""
                          />
                        </View>
                        <View style={desktopStyles.inputBlockContainerRight}>
                          <Text style={desktopStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                        </View>
                      </View>
                      {renderErrorMessage(errors?.monthlyIncome?.message)}
                    </View>
                    <SizedBox width={30} />
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                      <View style={desktopStyles.inputBlockContainer}>
                        <View style={desktopStyles.inputBlockContainerLeft}>
                          <Image source={imgCoin} style={globalStyles.Icon26} />
                          <Controller
                            control={control}
                            rules={{
                              valueAsNumber: true,
                              required: {
                                value: true,
                                message: i18n.t('VALIDATE.REQUIRED'),
                              },
                              min: {
                                value: 0.001,
                                message: i18n.t('NEEDS_CART.MIN_MONTHLY_EXPENSE'),
                              },
                              max: {
                                value: monthlyIncome,
                                message: i18n.t('NEEDS_CART.MONTHLY_EXPENSE_NOT_GREATER_THAN_MONTHLY_INCOME'),
                              },
                            }}
                            render={({ onChange, onBlur, value }) => (
                              <NumberFormat
                                placeholder={i18n.t('NEEDS_CART.MONTHLY_EXPENSES_HINT')}
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                  minWidth: '300px',
                                  marginLeft: 17,
                                  borderWidth: 0,
                                  borderColor: '#fff',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }}
                                value={value}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={3}
                                isNumericString={true}
                                type="text"
                                prefix={''}
                                onValueChange={(values: any) => {
                                  const { value } = values

                                  onChange(value)
                                  setValue('monthlyExpense', value)
                                }}
                                renderText={(value) => (
                                  <TextInput
                                    underlineColorAndroid="transparent"
                                    placeholderTextColor="#E0E0E0"
                                    onChangeText={(value) => {
                                      onChange(value)
                                      setValue('monthlyExpense', value)
                                    }}
                                    value={value}
                                    keyboardType="numeric"
                                  />
                                )}
                              />
                            )}
                            name="monthlyExpense"
                            defaultValue=""
                          />
                        </View>
                        <View style={desktopStyles.inputBlockContainerRight}>
                          <Text style={desktopStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                        </View>
                      </View>
                      {renderErrorMessage(errors?.monthlyExpense?.message)}
                    </View>
                  </View>
                </View>
              </View>
            }
          />
        </View>
        <View style={[desktopStyles.needsDataContainerBlock]}>
          <Block
            showTitle={false}
            child={
              <View style={[desktopStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
                <View style={desktopStyles.inputInfoContainer}>
                  <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                    <Text style={desktopStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INVESTMENT_EXPECTATION')}</Text>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.AlignItemsRight]}>
                      <Controller
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: i18n.t('VALIDATE.REQUIRED'),
                          },
                        }}
                        render={({ onChange, onBlur, value }) => (
                          <RadioListTitle
                            title=""
                            data={productTypes}
                            direction="row"
                            defaultValue={productType?.value}
                            onChange={(key: string) => onUpdateProduct(key)}
                            containerStyle={{ width: '100%', marginTop: 0, justifyContent: 'flex-end' }}
                            radioItemContainerStyle={{
                              minWidth: 'none',
                              maxWidth: 'none',
                              width: 'fit-content',
                              marginTop: 0,
                              justifyContent: 'flex-end',
                            }}
                            error={
                              !productType ? (
                                renderErrorMessage(errors?.product?.message, { textAlign: 'right' })
                              ) : (
                                <></>
                              )
                            }
                          />
                        )}
                        name="product"
                        defaultValue=""
                      />
                    </View>
                  </View>
                  <SizedBox height={41} />
                  <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
                    <Text style={desktopStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.NEED_TERM')}</Text>
                    <SizedBox width={40} />
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1]}>
                      <View style={desktopStyles.inputBlockContainer}>
                        <View style={desktopStyles.inputBlockContainerLeft}>
                          <Image source={imgCalendar2} style={globalStyles.Icon26} />
                          <Controller
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: i18n.t('VALIDATE.REQUIRED'),
                              },
                              min: {
                                value: MIN_NEED_TERM,
                                message: i18n.t('VALIDATE.MIN_PLAN_TERM'),
                              },
                            }}
                            render={({ onChange, onBlur, value }) => (
                              <NumberFormat
                                placeholder={i18n.t('NEEDS_CART.NEED_TERM_HINT')}
                                style={{
                                  fontSize: 15,
                                  fontWeight: 600,
                                  minWidth: '300px',
                                  marginLeft: 17,
                                  borderWidth: 0,
                                  borderColor: '#fff',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }}
                                value={value}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={0}
                                isNumericString={true}
                                type="text"
                                prefix={''}
                                onValueChange={(values: any) => {
                                  const { value } = values
                                  onChange(value)
                                  setValue('needTerm', value)
                                }}
                                renderText={(value) => (
                                  <TextInput
                                    underlineColorAndroid="transparent"
                                    placeholderTextColor="#E0E0E0"
                                    onChangeText={(value) => {
                                      onChange(value)
                                      setValue('needTerm', value)
                                    }}
                                    value={value}
                                    keyboardType="numeric"
                                  />
                                )}
                              />
                            )}
                            name="needTerm"
                            defaultValue=""
                          />
                        </View>
                        <View style={desktopStyles.inputBlockContainerRight}>
                          <Text style={desktopStyles.unit}>{i18n.t('COMMON.YEAR')}</Text>
                        </View>
                      </View>
                      <View>{renderErrorMessage(errors?.needTerm?.message)}</View>
                    </View>
                  </View>
                </View>
              </View>
            }
          />
        </View>
      </>
    )
  }

  if (isMobileDevice) {
    if (showEditProfile) {
      return (
        <KeyboardAvoidingView style={{ flex: 1 }} enabled behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <SafeAreaView
            style={[globalStyles.PositionRelative, globalStyles.mobileContainerSafeArea]}
            edges={['right', 'left', 'top']}
          >
            <View style={globalStyles.mobileContent}>
              {renderMobileAppBar()}
              <ScrollView contentContainerStyle={[mobileStyles.needsDataContainer]}>
                <View style={mobileStyles.backButtonContainer}>
                  <BackButton title={i18n.t('COMMON.BACK')} onPress={() => setShowEditProfile(false)} />
                </View>
                <View style={mobileStyles.individualDataContainer}>{renderProfileInfoTab()}</View>
              </ScrollView>
            </View>
            <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
              <MobileStepIndicator
                route={route}
                navigation={navigation}
                cnaData={currentHandlingCNA}
                needsCartList={needsCartList}
                currentPosition={0}
                loadingSaveDraft={loadingSaveDraft}
                onSaveDraft={handleSubmit(onSaveDraft)}
              />
            </View>
            {renderModals()}
          </SafeAreaView>
        </KeyboardAvoidingView>
      )
    }

    return (
      <KeyboardAvoidingView style={{ flex: 1 }} enabled behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <SafeAreaView
          style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
          edges={['right', 'left', 'top']}
        >
          <View style={globalStyles.mobileContent}>
            {renderMobileAppBar()}
            <ScrollView contentContainerStyle={[mobileStyles.needsDataContainer]}>
              <View pointerEvents={checkEditable() ? 'auto' : 'none'}>
                {/* {renderCustomerIncomeSurvey()} */}
                <Block
                  title={i18n.t('NEEDS_CART.INDEX')}
                  child={
                    <View style={mobileStyles.needsContainer}>
                      {Needs()}
                      <SizedBox height={16} />
                    </View>
                  }
                />
              </View>
              <ProvideYourInfoButton onPress={handleSubmit((_: any) => setShowEditProfile(true))} />
              <SizedBox height={120} />
            </ScrollView>
          </View>
          <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
            <MobileStepIndicator
              route={route}
              navigation={navigation}
              currentPosition={0}
              loadingSaveDraft={loadingSaveDraft}
              cnaData={currentHandlingCNA}
              needsCartList={needsCartList}
              onSaveDraft={handleSubmit(onSaveDraft, onError)}
            />
          </View>
          {renderModals()}
        </SafeAreaView>
      </KeyboardAvoidingView>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}>
        <StepAppHeader
          route={route}
          navigation={navigation}
          currentPosition={0}
          onSaveDraft={onSaveDraft}
          cnaData={currentHandlingCNA}
          needsCartList={needsCartList}
        />
        <ScrollView
          contentContainerStyle={[globalStyles.content]}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <View style={globalStyles.DisplayFlexRow} pointerEvents={checkEditable() ? 'auto' : 'none'}>
            <View style={[globalStyles.DisplayFlexCol, tabletStyles.needsDataContainer]}>
              <View style={tabletStyles.needsDataContainerBlock}>
                <Block
                  child={
                    <View style={[tabletStyles.needsContainer]}>
                      <Text style={tabletStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INDEX')}</Text>
                      <SizedBox height={25} />
                      <View style={tabletStyles.needsContent}>
                        <View>{Needs()}</View>
                        <SizedBox width={24} />
                      </View>
                    </View>
                  }
                />
                <SizedBox width={24} />
              </View>
            </View>
            <View style={tabletStyles.individualDataContainer}>
              <View style={tabletStyles.individualData}>{renderProfileInfoTab()}</View>
            </View>
          </View>
          <View>
            <StepButton
              onBack={onBack}
              onNext={handleSubmit(onSubmit, onError)}
              disabledOnNext={!checkContinueAvailability()}
              loadingOnNext={loadingSaveContinue}
            />
          </View>
          <SizedBox height={30} />
        </ScrollView>
        {renderModals()}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <StepAppHeader
        route={route}
        navigation={navigation}
        currentPosition={0}
        onSaveDraft={onSaveDraft}
        cnaData={currentHandlingCNA}
        needsCartList={needsCartList}
      />
      <ScrollView
        ref={scrollRef as any}
        contentContainerStyle={[globalStyles.content]}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <View style={globalStyles.DisplayFlexRow} pointerEvents={checkEditable() ? 'auto' : 'none'}>
          <View style={[globalStyles.DisplayFlexCol, desktopStyles.needsDataContainer]}>
            <View style={desktopStyles.needsDataContainerBlock}>
              <Block
                child={
                  <View style={[desktopStyles.needsContainer]}>
                    <Text style={desktopStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INDEX')}</Text>
                    <SizedBox height={18} />
                    <View style={desktopStyles.needsContent}>
                      <View>{Needs()}</View>
                      <SizedBox width={24} />
                    </View>
                  </View>
                }
              />
              <SizedBox width={24} />
            </View>
          </View>
          <View style={desktopStyles.individualDataContainer}>
            <View style={desktopStyles.individualData}>{renderProfileInfoTab()}</View>
          </View>
        </View>
        <View>
          <StepButton
            onBack={onBack}
            onNext={handleSubmit(onSubmit, onError)}
            disabledOnNext={!checkContinueAvailability()}
            loadingOnNext={loadingSaveContinue}
          />
        </View>
        <SizedBox height={30} />
      </ScrollView>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(NeedsCartScreen)
