import React, { useEffect } from 'react'
import { View, StyleSheet, Text, TextInput } from 'react-native'
import { styles as globalStyles, BlockAlysys, InputData, ValidateRequiredError, BoxFooter, SizedBox } from '../common'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { connect, ConnectedProps } from 'react-redux'
import { updateUserProtect } from '../slices/userSlice'
import Layout from '../constants/Layout'
import i18n from 'i18n-js'
import { i18nAgentWording } from '../components/AgentWording'

const mapStateToProps = (state: RootState) => ({
  retirement: state.retirement.retirement,
  profileInfo: state.needsCart.profileInfo,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {
  saveCNA,
  updateUserProtect,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SaveRetireScreen({
  userInfo,
  userProtect,
  retirement,
  profileInfo, updateUserProtect }: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  const MAX_VAL = 100
  const withValueLimit = ({ value }) => value <= MAX_VAL

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: retirement,
    shouldUnregister: false,
  })

  useEffect(() => {
    if (userProtect?.retirement) {
      reset(userProtect?.retirement, {
        errors: true,
      })
    }
  }, [userProtect])

  useEffect(() => {
    if (userProtect) {
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        retirement: { ...userProtect?.retirement, ...watch() },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }, [getValues('subTotal'), getValues('countDown'), getValues('age'), getValues('spending'), getValues('duration')])

  
  function renderError(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} style={{marginBottom: 10, marginTop: 0}} />
        </View>
      )
    } else {
      return <></>
    }
  }

  function countTotal() {
    const data = Number(getValues('duration')) * 12 * Number(getValues('spending'))
    setValue('subTotal', data)
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Chuẩn bị cho tuổi hưu an nhàn"
          borderColor="#FF9900"
          child={
            <View>
              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                  },
                }}
                ref={register}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Bạn dự định sẽ nghỉ hưu ở tuổi nào"
                    helpText="Tuổi"
                    type="column"
                    placeholder="Nhập"
                    allowNagitive
                    isAllowed={withValueLimit}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      setValue('countDown', Number(value - (profileInfo?.age ?? 0)))
                      countTotal()
                    }}
                    error={errors.age &&  <View style={{marginTop: 10, width: "100%"}}>{renderError(errors.age.message)}</View>}
                  />
                )}
                name="age"
                defaultValue=""
              />

              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={[globalStyles.PositionRelative, desktopStyles.marginBottom30]} pointerEvents={'none'}>
                    <View style={mobileStyles.boxYear}>
                      <Text style={mobileStyles.boxYearText}>Sau</Text>

                      <View style={mobileStyles.inputContainer}>
                        <TextInput
                          style={mobileStyles.input}
                          maxLength={2}
                          onChangeText={(text) => onChange(text)}
                          defaultValue={(value ?? 0) > 0 ? `${value}` : ''}
                          onBlur={onBlur}
                        />
                        <View style={mobileStyles.icon}>
                          <Text style={[mobileStyles.RedText]}>năm</Text>
                        </View>
                      </View>
                      <View>
                        <Text style={mobileStyles.boxYearText}>tính từ thời điểm hiện tại.</Text>
                      </View>
                    </View>
                    {getValues('age') && getValues('age') <= profileInfo?.age ? (
                      <Text style={globalStyles.errorMessage}>Vui lòng nhập tuổi lớn hơn tuổi hiện tại</Text>
                    ) : null}
                  </View>
                )}
                name="countDown"
              />

              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Dự định chi tiêu hàng tháng trong thời gian nghỉ hưu của bạn:"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.spending && <Text style={globalStyles.errorMessage}>{renderError(errors.spending.message)}</Text>
                    }
                  />
                )}
                name="spending"
              />

              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title={i18nAgentWording({
                      agentOfficeCode: userInfo?.officeCode,
                      wordingCode: 'NEEDS_ANALYST.RETIREMENT.HOW_LONG'
                    })}
                    helpText="Năm"
                    allowNagitive
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.duration && <Text style={globalStyles.errorMessage}>{renderError(errors.duration.message)}</Text>
                    }
                  />
                )}
                name="duration"
              />

              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                }}
                defaultValue=""
                render={({ value }) => (
                  <BoxFooter
                    title="Như vậy, số tiền bạn cần có khi bắt đầu tuổi hưu an nhàn là :"
                    backgroundColorBox="#FFF9DD"
                    titleStyle={{ color: '#FF9900' }}
                    child={<Text>{value}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={{ flex: 1, position: 'relative' }}>
        <BlockAlysys
          title="Chuẩn bị cho tuổi hưu an nhàn"
          borderColor="#FF9900"
          child={
            <View>
              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Bạn dự định sẽ nghỉ hưu ở tuổi nào?"
                    helpText="Tuổi"
                    type="column"
                    isAllowed={withValueLimit}
                    allowNagitive
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      setValue('countDown', Number(value - (profileInfo?.age ?? 0)))
                      countTotal()
                    }}
                    error={errors.age && <Text style={globalStyles.errorMessage}>{renderError(errors.age.message)}</Text>}
                  />
                )}
                name="age"
              />
              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={[globalStyles.PositionRelative, desktopStyles.marginBottom30]} pointerEvents={'none'}>
                    <View style={desktopStyles.boxYear}>
                      <Text style={desktopStyles.boxYearTextTablet}>Sau</Text>

                      <View style={desktopStyles.inputContainer}>
                        <TextInput
                          style={desktopStyles.input}
                          maxLength={2}
                          onChangeText={(text) => onChange(text)}
                          defaultValue={(value ?? 0) > 0 ? `${value}` : ''}
                          onBlur={onBlur}
                        />
                        <View style={desktopStyles.icon}>
                          <Text style={[desktopStyles.RedTextTablet]}>năm</Text>
                        </View>
                      </View>
                      <View>
                        <Text style={desktopStyles.boxYearTextTablet}>tính từ thời điểm hiện tại.</Text>
                      </View>
                    </View>
                    {getValues('age') && getValues('age') <= profileInfo?.age ? (
                      <Text style={globalStyles.errorMessage}>Vui lòng nhập tuổi lớn hơn tuổi hiện tại</Text>
                    ) : null}
                  </View>
                )}
                name="countDown"
              />

              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Dự định chi tiêu hàng tháng trong thời gian nghỉ hưu của bạn:"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.spending && <Text style={globalStyles.errorMessage}>{renderError(errors.spending.message)}</Text>
                    }
                  />
                )}
                name="spending"
              />

              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title={i18nAgentWording({
                      agentOfficeCode: userInfo?.officeCode,
                      wordingCode: 'NEEDS_ANALYST.RETIREMENT.HOW_LONG'
                    })}
                    helpText="Năm"
                    allowNagitive
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                    error={
                      errors.duration && <Text style={globalStyles.errorMessage}>{renderError(errors.duration.message)}</Text>
                    }
                  />
                )}
                name="duration"
              />
              <Controller
                control={control}
                    rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
                defaultValue=""
                render={({ value }) => (
                  <BoxFooter
                    title="Như vậy, số tiền bạn cần có khi bắt đầu tuổi hưu an nhàn là :"
                    backgroundColorBox="#FFF9DD"
                    titleStyle={{ color: '#FF9900' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
        <SizedBox width={24} />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Chuẩn bị cho tuổi hưu an nhàn"
        borderColor="#FF9900"
        child={
          <View>
            <Controller
              control={control}
                  rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                  },
                }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Bạn dự định sẽ nghỉ hưu ở tuổi nào?"
                  helpText="Tuổi"
                  type="column"
                  allowNagitive
                  placeholder="Nhập"
                  isAllowed={withValueLimit}
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    setValue('countDown', Number(value - (profileInfo?.age ?? 0)))
                    countTotal()
                  }}
                  error={errors.age && <Text style={globalStyles.errorMessage}>{renderError(errors.age.message)}</Text>}
                />
              )}
              name="age"
            />
            <Controller
              control={control}
                  rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <View style={[globalStyles.PositionRelative, desktopStyles.marginBottom30]} pointerEvents={'none'}>
                  <View style={desktopStyles.boxYear}>
                    <Text style={desktopStyles.boxYearText}>Sau</Text>

                    <View style={desktopStyles.inputContainer}>
                      <TextInput
                        style={desktopStyles.input}
                        maxLength={2}
                        onChangeText={(text) => onChange(text)}
                        defaultValue={(value ?? 0) > 0 ? `${value}` : ''}
                        onBlur={onBlur}
                      />
                      <View style={desktopStyles.icon}>
                        <Text style={[desktopStyles.RedText]}>năm</Text>
                      </View>
                    </View>
                    <View>
                      <Text style={desktopStyles.boxYearText}>tính từ thời điểm hiện tại.</Text>
                    </View>
                  </View>
                  {getValues('age') && getValues('age') <= profileInfo?.age ? (
                    <Text style={globalStyles.errorMessage}>Vui lòng nhập tuổi lớn hơn tuổi hiện tại</Text>
                  ) : null}
                </View>
              )}
              name="countDown"
            />

            <Controller
              control={control}
                  rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Dự định chi tiêu hàng tháng trong thời gian nghỉ hưu của bạn:"
                  helpText="Triệu đồng"
                  type="column"
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={
                    errors.spending && <Text style={globalStyles.errorMessage}>{renderError(errors.spending.message)}</Text>
                  }
                />
              )}
              name="spending"
            />

            <Controller
              control={control}
                  rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONTH'),
                  },
                }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title={i18nAgentWording({
                    agentOfficeCode: userInfo?.officeCode,
                    wordingCode: 'NEEDS_ANALYST.RETIREMENT.HOW_LONG'
                  })}
                  helpText="Năm"
                  allowNagitive
                  type="column"
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    countTotal()
                  }}
                  error={
                    errors.duration && <Text style={globalStyles.errorMessage}>{renderError(errors.duration.message)}</Text>
                  }
                />
              )}
              name="duration"
            />
            <Controller
              control={control}
                  rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                }}
              defaultValue=""
              render={({ value }) => (
                <BoxFooter
                  title="Như vậy, số tiền bạn cần có khi bắt đầu tuổi hưu an nhàn là :"
                  backgroundColorBox="#FFF9DD"
                  titleStyle={{ color: '#FF9900' }}
                  child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <SizedBox width={24} />
    </View>
  )
}
export default connector(SaveRetireScreen)

const desktopStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    height: '100%',
  },
  helpAnalysis: {
    flexDirection: 'row',
  },
  individualDataContainer: {
    backgroundColor: '#fff',
    marginRight: 25,
    // overflowY: 'scroll',
    maxHeight: 700,
  },
  boxEdu: {
    flexDirection: 'column',
    paddingHorizontal: 25,
    paddingVertical: 30,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#D7DFFF',
  },
  boxEduChild: {
    flexDirection: 'row',
  },
  RedText: {
    color: 'red',
    fontSize: 15,
    fontWeight: 'bold',
  },
  boxEduText: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 20,
    fontSize: 16,
  },
  boxYear: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    // marginBottom: 30,
  },
  marginBottom30: {
    marginBottom: 20,
  },
  RedTextTablet: {
    color: 'red',
    fontSize: 13.2667,
    fontWeight: 'bold',
  },
  boxEduTextTablet: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 20,
    fontSize: 13.2667,
  },
  boxYearTextTablet: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 13.2667,
    fontWeight: 'bold',
  },
  inputContainer: {
    justifyContent: 'center',
    maxWidth: 90,
    borderColor: '#D7DFFF',
    borderWidth: 1.5,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  input: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontWeight: 'bold',
    fontSize: 16,
    color: '#ED1B2E',
  },
  icon: {
    position: 'absolute',
    right: 15,
    fontWeight: 'bold',
    fontSize: 16,
    color: '#ED1B2E',
  },
  boxYearText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#373737',
  },
})

const mobileStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  boxEduChild: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  RedText: {
    color: 'red',
    fontSize: 12,
    fontWeight: 'bold',
  },
  boxEduText: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 20,
    fontSize: 13,
  },
  boxYear: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 'bold',
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    width: '100%',
    flex: 1,
    // overflowY: 'scroll',
    paddingBottom: 100,
  },
  boxYearText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#373737',
  },
  inputContainer: {
    justifyContent: 'center',
    maxWidth: 75,
    borderColor: '#D7DFFF',
    borderWidth: 1.5,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  input: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    fontWeight: 'bold',
    fontSize: 13,
    height: 40,
    width: 75,
    color: '#ED1B2E',
  },
  icon: {
    position: 'absolute',
    right: 15,
    fontWeight: 'bold',
    fontSize: 13,
    color: '#ED1B2E',
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
