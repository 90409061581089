import { SizedBox, styles as globalStyles } from '../../../common'
import React from 'react'
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native'
import imgCalendar from '../../../assets/images/calendar.png'
import imgCall from '../../../assets/images/call.png'
import moment from 'moment'
import { CNA } from '../../../model/CNA'
import Rating from '../../../common/components/Rating'

type IProps = {
  cna?: CNA
  type?: string
  onPress?: (cna?: CNA) => void
}

function MobileCnaItem({ cna, onPress, type }: IProps) {
  function getDisplayName(customerName?: string, reminiscentName?: string) {
    if (reminiscentName && reminiscentName.trim() !== '') {
      return reminiscentName.trim()
    }
    if (customerName && customerName.trim() !== '') {
      return customerName.trim()
    }
    return '-'
  }

  return (
    <TouchableOpacity style={styles.container} onPress={() => onPress && onPress(cna)}>
      <View style={styles.infoContainer}>
        <Text style={styles.name}>{getDisplayName(cna?.customerName, cna?.reminiscentName)}</Text>
        <View style={[globalStyles.Flex1, globalStyles.AlignItemsRight]}>
          <Rating ratingValue={cna?.potenialRating ?? 0} enable={false} />
        </View>
      </View>
      <SizedBox height={10} />
      <View style={styles.infoContainer}>
        <View style={styles.phoneContainer}>
          <Image source={imgCall} style={styles.icon} />
          <SizedBox width={13} />
          <Text style={styles.label}>
            {cna?.customerPhone && cna?.customerPhone.trim() !== '' ? cna?.customerPhone : '-'}
          </Text>
        </View>
        <View style={styles.dateContainer}>
          <Image source={imgCalendar} style={styles.icon} />
          <SizedBox width={13} />
          {type === 'review' ? (
            <Text style={styles.label}>{cna?.reviewDate ? moment(cna.reviewDate).format('DD/MM/YYYY hh:mm') : '-'}</Text>
          ) : (
            <Text style={styles.label}>{cna?.createdAt ? moment(cna.createdAt).format('DD/MM/YYYY hh:mm') : '-'}</Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default MobileCnaItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: '#FFFFFF',
    borderWidth: 1.5,
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 1,
    elevation: 1,
  },
  name: {
    color: '#2F7AEB',
    fontSize: 13,
    fontWeight: '600',
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    width: 20,
    height: 20,
  },
  label: {
    color: '#505D6F',
    fontSize: 13,
  },
  phoneContainer: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateContainer: {
    flex: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
