import * as React from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

export type CardProps = {
  title?: string
  titleStyle?: Record<string, unknown>
  child?: JSX.Element
  extra?: JSX.Element
}

export function Card({ title, titleStyle, child, extra }: CardProps) {
  return (
    <View style={[styles.container]}>
      <View style={[styles.titleContainer]}>
        <Text style={[styles.title]}>{title}</Text>
        <View style={[styles.extraContainer]}>{extra}</View>
      </View>
      <View style={[styles.childContainer]}>{child}</View>
    </View>
  )
}

export default Card

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    flexDirection: 'row',
  },
  title: {
    flex: 1,
    paddingLeft: 0,
    color: '#2B2D42',
    fontSize: 24,
    fontWeight: '700',
  },
  extraContainer: {},
  childContainer: {},
})
