import {
  AppBar,
  CircleProgressIndicator,
  Dialog,
  FilledButton,
  OutlinedButton,
  Select,
  SizedBox,
  styles as globalStyles,
  ValidateRequiredError,
} from '../../common'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../store'
import { RootStackScreenProps } from '../../types'
import icArrowBackWhite from '../../assets/images/back.png'
import {
  View,
  StyleSheet,
  TextInput,
  ActivityIndicator,
  Animated,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Platform,
  FlatList,
  TouchableOpacity,
  RefreshControl,
  StatusBar,
} from 'react-native'
import { Image, Text } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { loadCnaListAsync, loadMoreCnaAsync, setCnaData, setLoadingMore } from '../../slices/viewAllCnaSlice'
import MobileCnaItem from '../CNAListScreen/components/MobileCnaItem'
import { ViewMode, CnaStatus } from '../../constants/Enum'
import i18n from 'i18n-js'
import icSearch from '../../assets/images/search.png'
import { numberExist, validateDate } from '../../data/validate'
import { DEFAULT_COMPLETED_DATA } from '../CNAListScreen/components/PlanNeedReview'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import icDropDownLight from '../../assets/images/dropdown-light.png'
import { Calendar } from 'react-date-range'
import * as Locale from 'date-fns/locale'
import icFilterWhite from '../../assets/images/filter.png'
import icCloseWhite from '../../assets/images/close.png'
import { setCnaTodayList } from '../../slices/cnaSlice'
import { APP_BAR_HEIGHT, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants/Constants'
import { wait } from '../../helper/Utils'
import { CommonAppBar } from 'components/CommonAppBar'

const defaultDataSort = { createdAt: 'ASC' }

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
  cnaList: state?.viewAllCna?.cnaList,
  loading: state?.viewAllCna?.loading,
  loadingMore: state?.viewAllCna?.loadingMore,
  isHaveMore: state?.viewAllCna?.isHaveMore,
})

const mapDispatchToProps = {
  loadCnaListAsync,
  loadMoreCnaAsync,
  setCnaTodayList,
  setLoadingMore,
  setCnaData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'ViewAllCNA'> & PropsFromRedux & {}

function CnaFilterCreateTodayScreen({
  route,
  navigation,
  cnaList,
  loading,
  loadingMore,
  isHaveMore,
  setCnaTodayList,
  setCnaData,
  setLoadingMore,
  loadCnaListAsync,
  loadMoreCnaAsync,
}: Props) {
  const isFocused = useIsFocused()
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE)
  const [pageIndex, setPageIndex] = React.useState(DEFAULT_PAGE_INDEX)
  const [keySearch, setKeySearch] = React.useState('')
  const [defaultValue, setDefaultValue] = React.useState([undefined])
  const [dataFilters, setDataFilters] = React.useState<Array<any>>(DEFAULT_COMPLETED_DATA)
  const [dataSorts, setDataSorts] = React.useState<any>(defaultDataSort)
  const [showFilter, setShowFilter] = React.useState(false)
  const [showBlurBg, setShowBlurBg] = React.useState(false)
  const [showDatePicker, setShowDatePicker] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState<string | undefined | null>(moment().toISOString())
  const [datePickerSelectedDate, setDatePickerSelectedDate] = React.useState<Date>()
  const transformAnim = React.useRef(new Animated.Value(-335)).current
  const opacityAnim = React.useRef(new Animated.Value(0)).current
  const [refreshing, setRefreshing] = React.useState(false)

  React.useEffect(() => {
    if (showFilter) {
      Animated.timing(transformAnim, {
        toValue: -1,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      setShowBlurBg(true)
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 400,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
    } else {
      Animated.timing(transformAnim, {
        toValue: -335,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(opacityAnim, {
        toValue: -1,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start(() => {
        setShowBlurBg(false)
      })
    }
  }, [opacityAnim, showFilter, transformAnim])

  React.useEffect(() => {
    return () => {
      setKeySearch('')
    }
  }, [])

  React.useEffect(() => {
    if (isFocused) {
      loadCnaListAsync({ page: pageIndex, limit: pageSize, dataFilters: dataFilters, dataSorts: [dataSorts] })
      navigation.setParams({ ...(route.params as any), page: pageIndex, pageSize: pageSize })
    }
  }, [isFocused])

  function onFilterData() {
    setPageIndex(1)
    let newDataFilters = []
    if (keySearch) {
      if (numberExist.test(keySearch)) {
        newDataFilters.push({
          name: 'reminiscentName',
          value: keySearch,
          type: 'String',
          operator: 'search',
        })
      } else {
        newDataFilters.push({
          name: 'customerName',
          value: keySearch,
          type: 'String',
          operator: 'search',
        })
      }
      setValue('name', keySearch)
    }
    newDataFilters.push(...DEFAULT_COMPLETED_DATA)
    setDataFilters([...newDataFilters])
    loadCnaListAsync({ page: pageIndex, limit: pageSize, dataFilters: newDataFilters, dataSorts: [dataSorts] })
  }

  function handleLoadMore(e: any) {
    if (!loadingMore && isHaveMore && e.distanceFromEnd >= 0) {
      const newPage = pageIndex + 1
      setPageIndex(newPage)
      loadMoreCnaAsync({ page: newPage, limit: pageSize, dataFilters: dataFilters, dataSorts: [dataSorts] })
    }
  }

  function onSubmitFilter(data: any) {
    setPageIndex(1)
    setLoadingMore(true)
    let newDataFilters = []
    if (data?.customerMail) {
      newDataFilters.push({
        name: 'customerEmail',
        value: data?.customerMail,
        type: 'String',
        operator: 'search',
      })
    }
    if (data?.customerPhone) {
      newDataFilters.push({
        name: 'customerPhone',
        value: data?.customerPhone,
        type: 'String',
        operator: 'search',
      })
    }
    if (data?.name) {
      newDataFilters.push({
        name: 'customerName',
        value: data?.name,
        type: 'String',
        operator: 'search',
      })
      setValue('name', data?.name)
    }
    if (data?.status && data.status.length > 0) {
      newDataFilters.push({
        name: 'status',
        value: data?.status,
        type: 'String',
        operator: 'in',
      })
    } else {
      newDataFilters.push({
        name: 'status',
        value: Object.values(CnaStatus),
        type: 'String',
        operator: 'in',
      })
    }
    newDataFilters.push(...DEFAULT_COMPLETED_DATA)
    setDataFilters([...newDataFilters])
    setPageIndex(1)
    loadCnaListAsync({ page: 1, limit: pageSize, dataFilters: newDataFilters, dataSorts: [dataSorts] })
    setShowFilter(false)
  }

  function onResetFilter() {
    reset({
      name: '',
      customerEmail: '',
      customerPhone: '',
      status: undefined,
      createdAt: '',
    })
    setDefaultValue([undefined])
    setLoadingMore(true)
    setKeySearch('')
    setShowFilter(false)
    setPageIndex(1)
    setDataFilters(DEFAULT_COMPLETED_DATA)
    setSelectedDate(moment().toISOString())
    setDatePickerSelectedDate(new Date())
    loadCnaListAsync({ page: 1, limit: pageSize, dataFilters: DEFAULT_COMPLETED_DATA, dataSorts: [dataSorts] })
  }

  function renderFooter() {
    return (
      <View style={styles.footer}>
        {loadingMore && <ActivityIndicator color="#ED1B2E" style={{ marginLeft: 8 }} />}
      </View>
    )
  }

  function renderMobileFilterMenu() {
    return (
      <View
        style={[
          globalStyles.PositionAbsolute,
          {
            top: Platform.OS === 'web' ? 0 : StatusBar.currentHeight,
            bottom: 0,
            right: 0,
            width: showFilter ? '100%' : 0,
          },
        ]}
      >
        {showBlurBg && (
          <Animated.View
            style={[styles.blurBg, { opacity: opacityAnim, top: Platform.OS === 'web' ? 0 : APP_BAR_HEIGHT }]}
          >
            <TouchableOpacity onPress={() => setShowFilter(false)} style={styles.blurBg}>
              <View style={styles.blurBg}></View>
            </TouchableOpacity>
          </Animated.View>
        )}
        <Animated.View
          style={[styles.menuContainer, { right: transformAnim }]}
        >
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[styles.textInputContainer]}>
                <TextInput
                  style={[styles.textInput]}
                  placeholder={i18n.t('VIEW_ALL.SEARCH_HINT')}
                  value={value}
                  onChangeText={(s: string) => {
                    setKeySearch(s)
                    onChange(s)
                  }}
                />
              </View>
            )}
            name="name"
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[styles.textInputContainer]}>
                <TextInput
                  style={[styles.textInput]}
                  placeholder={i18n.t('VIEW_ALL.INPUT_PHONE')}
                  value={value}
                  onChangeText={(s: string) => {
                    onChange(s)
                  }}
                />
              </View>
            )}
            name="customerPhone"
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={[styles.textInputContainer]}>
                <TextInput
                  style={[styles.textInput]}
                  placeholder={i18n.t('VIEW_ALL.INPUT_EMAIL')}
                  value={value}
                  onChangeText={(s: string) => {
                    onChange(s)
                  }}
                />
              </View>
            )}
            name="customerMail"
            defaultValue=""
          />
          <View style={styles.filterDateContainer}>
            <Controller
              control={control}
              rules={{ ...validateDate, required: false }}
              render={({ onChange, onBlur, value }) => (
                <TextInput
                  style={[styles.textInput, globalStyles.Disable, { flex: 1 }]}
                  placeholder={i18n.t('COMMON.CREATED_DATE')}
                  editable={false}
                  selectTextOnFocus={false}
                  value={
                    selectedDate && selectedDate.length >= 10 && moment(selectedDate).isValid()
                      ? moment(selectedDate).format('DD/MM/YYYY')
                      : selectedDate
                      ? selectedDate
                      : ''
                  }
                  onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
                    setSelectedDate((e.target as any).value)
                    onChange((e.target as any).value)
                  }}
                />
              )}
              name="createdAt"
              defaultValue=""
            />
            <TouchableOpacity disabled>
              <Image source={icDropDownLight} style={globalStyles.Icon12} />
            </TouchableOpacity>
          </View>
          {errors.createdAt && <ValidateRequiredError title={i18n.t('VALIDATE.DATE')} style={{ marginLeft: 27 }} />}
          {/* Filter status */}
          <View style={styles.maritalStatusContainer}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <Select
                  selectOption={{
                    options: [
                      { label: i18n.t(`CNA_STATUS.ALL`), value: undefined },
                      { label: i18n.t(`CNA_STATUS.${CnaStatus.COMPLETED}`), value: CnaStatus.COMPLETED },
                      { label: i18n.t(`CNA_STATUS.${CnaStatus.DRAFT}`), value: CnaStatus.DRAFT },
                      {
                        label: i18n.t(`CNA_STATUS.${CnaStatus.NEED_CONTACT_INFO}`),
                        value: CnaStatus.NEED_CONTACT_INFO,
                      },
                    ],
                    style: styles.filterDropdownStatus,
                    dropDownContainerStyle: styles.dropDownContainerStyle,
                    listItemLabelStyle: styles.listItemLabelStyle,
                    labelStyle: styles.labelStyle,
                    placeholderStyle: styles.placeholderStyle,
                    placeholder: i18n.t('COMMON.STATUS'),
                    arrowUpIcon: <Image source={icDropDownLight} style={globalStyles.Icon12} />,
                    arrowDownIcon: <Image source={icDropDownLight} style={globalStyles.Icon12} />,
                    multiple: true,
                    value:  getValues('status') ? getValues('status')?.map((e: string) => (e ? i18n.t(`CNA_STATUS.${e}`) : 'Tất cả')) : [],
                    defaultValue: defaultValue,
                    multiValuesDisplayType: 'horizontal',
                  }}
                  onChange={(value?: Array<string | undefined>) => {
                    if (value && value?.includes(undefined)) {
                      setValue('status', undefined)
                    } else {
                      setValue('status', value)
                    }
                  }}
                />
              )}
              name="status"
              defaultValue=""
            />
          </View>
          <View style={styles.buttons}>
            <OutlinedButton
              title="Thiết lập lại"
              buttonStyle={{ width: 102, paddingHorizontal: 0 }}
              titleStyle={{ fontSize: 14 }}
              onPress={onResetFilter}
            />
            <SizedBox width={20} />
            <FilledButton
              title="Tìm kiếm"
              buttonStyle={{ width: 102, paddingHorizontal: 0 }}
              titleStyle={{ fontSize: 14 }}
              onPress={handleSubmit(onSubmitFilter)}
            />
          </View>
          <SizedBox height={50} />
        </Animated.View>
      </View>
    )
  }

  function onResetFilterData() {
    setCnaData({})
    setPageIndex(1);
  }

  const onRefresh = React.useCallback( async () => {
    setRefreshing(true)
    setPageIndex(1)
    loadCnaListAsync({ page: 1, limit: pageSize, dataFilters: dataFilters, dataSorts: [dataSorts] })
    wait(1000).then(() => setRefreshing(false))
  }, [])

  function renderModals() {
    return (
      <>
        <Dialog visible={showDatePicker} type="default">
          <View style={styles.calendarContainer}>
            <Calendar
              date={datePickerSelectedDate}
              onChange={(date?: Date) => {
                setDatePickerSelectedDate(date)
                setSelectedDate(moment(date).format('DD/MM/YYYY'))
                setValue('createdAt', moment(date).format('DD/MM/YYYY'))
              }}
              color={'#ED1B2E'}
              locale={Locale.vi}
            />
            <FilledButton onPress={() => setShowDatePicker(false)} title={i18n.t('COMMON.DONE')} type="small" />
          </View>
        </Dialog>
      </>
    )
  }

  function renderMobileAppBar() {
    return (
      <AppBar
        leading={
          <TouchableOpacity
            onPress={() => {
              setCnaData({})
              setCnaTodayList(cnaList)
              navigation.canGoBack() ? navigation.goBack() : navigation.replace('MobileHome')
            }}
          >
            <Image source={icArrowBackWhite} style={globalStyles.Icon24} />
          </TouchableOpacity>
        }
        title={<Text style={globalStyles.AppBarTitle}>{`Kế hoạch tạo hôm nay (${cnaList?.totalRecords ?? 0})`}</Text>}
        trailing={
          <TouchableOpacity onPress={() => setShowFilter(!showFilter)} style={[globalStyles.Icon18]}>
            <Image
              source={showBlurBg ? icCloseWhite : icFilterWhite}
              style={{ ...globalStyles.Icon20, height: 18 }}
            />
          </TouchableOpacity>
        }
        containerStyle={globalStyles.AppBarContainer}
      />
    )
  }

  return (
    <SafeAreaView
      style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
      edges={['right', 'left', 'top']}
    >
      {renderMobileAppBar()}
      <View style={[styles.defaultContent]}>
        <View style={[styles.textInputContainer]}>
          <TextInput
            style={[styles.textInput]}
            value={keySearch}
            placeholder={i18n.t('VIEW_ALL.SEARCH_HINT')}
            onChangeText={(s: string) => {
              setKeySearch(s)
              setValue('name', s)
            }}
          />
          <TouchableOpacity onPress={onFilterData}>
          <Image source={icSearch} style={globalStyles.Icon18} />
          </TouchableOpacity>
        </View>
        {loading && !refreshing ? (
          <View style={[globalStyles.mobileNoDataContainer, { flex: 1 }]}>
            <CircleProgressIndicator />
          </View>
        ) : (
          <FlatList
            style={styles.listContainer}
            data={cnaList?.items && cnaList?.items.length > 0 ? cnaList?.items : []}
            onEndReached={(e) => handleLoadMore(e)}
            onEndReachedThreshold={0.1}
            initialNumToRender={cnaList?.totalRecords}
            removeClippedSubviews
            renderItem={(item) => {
              return (
                <MobileCnaItem
                  key={item.index}
                  cna={item.item}
                  onPress={(cna: any) => {
                    onResetFilterData();
                    navigation.navigate('MobileDetailCna', {
                      ...(route.params as any),
                      id: cna._id,
                      mode: ViewMode.EDITABLE,
                    })
                  }}
                />
              )
            }}
            ListFooterComponent={(e: any) => renderFooter()}
            ListEmptyComponent={
              <View style={[globalStyles.mobileNoDataPage]}>
                <Text>{i18n.t('COMMON.NO_DATA')}</Text>
              </View>
            }
            refreshControl={
              Platform.OS === 'web' ? undefined : (
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} style={{ paddingHorizontal: 0 }} />
              )
            }
          />
        )}
      </View>
      {renderMobileFilterMenu()}
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(CnaFilterCreateTodayScreen)

const styles = StyleSheet.create({
  defaultContent: {
    marginTop: 0,
    backgroundColor: '#F7F8FA',
    height: '100%',
    flexDirection: 'column',
    flex: 1,
  },
  boxCard: {
    margin: 14,
  },
  boxConfirm: {
    textAlign: 'center',
    marginBottom: 10,
  },
  boxShare: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
    fontSize: 15,
  },
  id: {
    color: '#ED1B2E',
    fontWeight: '600',
  },
  idHightLight: {
    fontWeight: '600',
  },
  title: {
    color: '#1F1F20',
  },
  line: {
    backgroundColor: '#EDF2F4',
    width: '100%',
    height: 2,
    marginBottom: 20,
  },
  buttonEditContainer: { height: 76, bottom: 0, left: 0, right: 0, flexDirection: 'row' },
  buttonEdit: {
    flex: 1,
    padding: 16,
    backgroundColor: '#FFF',
  },
  listContainer: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
  },
  textInputContainer: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 10,
    paddingHorizontal: 16,
    backgroundColor: '#FAFBFF',
    margin: 16,
  },
  textInput: {
    fontSize: 13,
    paddingVertical: Platform.OS === 'android' ? 4 : 8,
    borderWidth: 0,
    borderColor: '#fff',
    flex: 1,
    marginTop: 0,
  },
  footer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  blurBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
    zIndex: 1001,
  },
  menuContainer: {
    position: 'absolute',
    right: -335,
    top: 56,
    zIndex: 1002,
    bottom: 0,
    width: 331,
    backgroundColor: '#FFF',
    paddingHorizontal: 12,
    paddingTop: Platform.OS === 'ios' ? 30 : 10,
  },
  filterInputNameContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderColor: '#D7DFFF',
    marginTop: 0,
    borderRadius: 0,
    height: 52,
    backgroundColor: '#FFF',
    width: 150,
    paddingLeft: 0,
    fontSize: 14,
  },
  filterInput: {
    fontSize: 14,
    width: '100%',
    paddingRight: 0,
  },
  filterDateContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginHorizontal: 16,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 10,
    paddingHorizontal: 16,
    backgroundColor: '#FAFBFF',
  },
  filterLabel: {
    color: '#A7ACB6',
    fontSize: 14,
    width: 100,
  },
  maritalStatusContainer: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    zIndex: 1,
  },
  itemSelect: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
  filterDropdownStatus: {
    flexDirection: 'row',
    marginTop: 20,
    marginHorizontal: 16,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: '#FAFBFF',
    width: 277,
    height: 36,
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: '#D7DFFF',
    borderWidth: 2,
    width: 277,
    paddingVertical: 0,
    marginHorizontal: 16,
    marginTop: 19,
  },
  listItemLabelStyle: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  labelStyle: {
    fontWeight: 'normal',
    fontSize: 13,
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 15,
    fontWeight: 'normal',
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 0,
  },
  calendarContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
})
