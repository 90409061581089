import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
import SaveLife from '../model/SafeLife'

export interface safeLifeState {
  loading?: boolean
  saveLifeList?: SaveLife
  saveLife?: SaveLife
}

const initialState: safeLifeState = {
  loading: false,
  saveLifeList: undefined,
  saveLife: undefined,
}

export const safeLifeAsync = createAsyncThunk('infomation/safeLife', async (req: any) => {
  const response = {
    data:{
      safeTime: 12,
      moneyMonth: 12,
      safeTimeMonth: 12,
      giveMoney: true
    }
  };
  return response.data
})

export const safeLifeSlice = createSlice({
  name: 'safe-life',
  initialState,
  reducers: {
    loadSafeLife: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.saveLife = action.payload
      state.saveLifeList = {
      safeTime: 12,
      moneyMonth: 12,
      safeTimeMonth: 12,
      giveMoney: true
    }
    },
    updateSafeLife: (state, action: PayloadAction<SaveLife>) => {
      state.saveLife = action.payload
      state.loading = true
    }
  },
})

export const { loadSafeLife, updateSafeLife } = safeLifeSlice.actions

const { reducer } = safeLifeSlice
export default reducer
