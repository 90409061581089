import * as React from 'react'
import { Text, View, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'
import Layout from '../../constants/Layout'

export type OutlinedButtonProps = {
  title?: string
  buttonStyle?: Record<string, unknown>
  titleStyle?: Record<string, unknown>
  prefix?: JSX.Element
  suffix?: JSX.Element
  loading?: boolean
  disabled?: boolean
  onPress?: () => void
}

export function OutlinedButton({
  title,
  buttonStyle,
  titleStyle,
  prefix,
  suffix,
  loading,
  disabled,
  onPress,
}: OutlinedButtonProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (isMobileDevice) {
    return (
      <TouchableOpacity style={[mobileStyle.button, buttonStyle]} disabled={disabled || loading} onPress={onPress}>
        {loading && (
          <View style={mobileStyle.loadingContainer}>
            <ActivityIndicator color="#D62828"/>
          </View>
        )}
        {prefix && <View style={mobileStyle.prefixContainer}>{prefix}</View>}
        <Text style={[mobileStyle.title, titleStyle]}>{title}</Text>
        {suffix && <View style={mobileStyle.suffixContainer}>{suffix}</View>}
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity style={[desktopStyle.button, buttonStyle]} disabled={disabled || loading} onPress={onPress}>
      {loading && (
        <View style={desktopStyle.loadingContainer}>
          <ActivityIndicator color="#D62828"/>
        </View>
      )}
      {prefix && <View style={desktopStyle.prefixContainer}>{prefix}</View>}
      <Text style={[desktopStyle.title, titleStyle]}>{title}</Text>
      {suffix && <View style={desktopStyle.suffixContainer}>{suffix}</View>}
    </TouchableOpacity>
  )
}

export default OutlinedButton

const desktopStyle = StyleSheet.create({
  button: {
    height: 50,
    flexDirection: 'row',
    marginTop: 40,
    paddingHorizontal: 30,
    alignItems: 'center',
    display: 'flex',
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#D62828',
    color: '#D62828',
    justifyContent: 'center',
  },
  title: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  loadingContainer: {
    marginRight: 8
  },
  prefixContainer: {
    marginRight: 8
  },
  suffixContainer: {
    marginLeft: 8
  }
})

const mobileStyle = StyleSheet.create({
  button: {
    height: 41,
    flexDirection: 'row',
    marginTop: 40,
    alignItems: 'center',
    display: 'flex',
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 12,
    borderWidth: 2,
    borderColor: '#D62828',
    color: '#D62828',
    justifyContent: 'center',
    paddingHorizontal: 24,
    paddingVertical: 8,
  },
  title: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
  },
  loadingContainer: {
    marginRight: 8
  },
  prefixContainer: {
    marginRight: 8
  },
  suffixContainer: {
    marginLeft: 8
  }
})
