import { AppBar, Dialog, IconButton, styles as globalStyles } from '../common'
import * as React from 'react'
import { Platform, StyleSheet, Text } from 'react-native'
import icArrowBackWhite from '../assets/images/back.png'
import icHomeWhiteOutline from '../assets/images/home-white.png'
import i18n from 'i18n-js'
import Layout from '../constants/Layout'

export type CommonAppBarProps = {
  title: string
  navigation: any
  onBack?: () => void
  onGoHome?: () => Promise<any> | void | null
}

export function CommonAppBar({ title, navigation, onBack, onGoHome }: CommonAppBarProps) {
  const isMobileDevice = Layout.isMobileDevice
  const [showPopUpConfirmGoHome, setShowPopUpConfirmGoHome] = React.useState(false)

  function handleGoHome() {
    setShowPopUpConfirmGoHome(true)
  }

  function handleGoBack() {
    if (Platform.OS === 'android' || Platform.OS === 'ios' || Platform.OS === 'macos' || Platform.OS === 'windows') {
      navigation.goBack()
    } else {
      onBack && onBack()
    }
  }

  return (
    <>
      <AppBar
        leading={<IconButton imageSource={icArrowBackWhite} onPress={handleGoBack} />}
        title={<Text style={globalStyles.AppBarTitle}>{title}</Text>}
        trailing={<IconButton imageSource={icHomeWhiteOutline} onPress={handleGoHome} iconStyle={globalStyles.Icon22} />}
        containerStyle={globalStyles.AppBarContainer}
      />
      <Dialog
        type="confirm"
        dataDelete={{
          title: i18n.t('COMMON.NOTI'),
          description: i18n.t('MESS.CONFIRM_GO_HOME'),
        }}
        visible={showPopUpConfirmGoHome}
        contentStyle={{ padding: 0, paddingTop: 0 }}
        dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
        okText={i18n.t('COMMON.ACCEPT')}
        cancelText={i18n.t('COMMON.CANCEL')}
        onOk={async () => {
          setShowPopUpConfirmGoHome(false)
          onGoHome && await onGoHome()
          if (isMobileDevice) {
            navigation.replace('MobileHome')
          } else {
            navigation.replace('AfterLogIn')
          }
        }}
        onCancel={() => {
          setShowPopUpConfirmGoHome(false)
        }}
      >
        <Text></Text>
      </Dialog>
    </>
  )
}

export default CommonAppBar

const styles = StyleSheet.create({
  container: {
    width: 32,
    height: 32,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

console.disableYellowBox = true;