import Layout from '../../constants/Layout'
import React, { useState, useEffect } from 'react'
import { Platform, SafeAreaView, StyleSheet, View } from 'react-native'
import { Icon } from 'react-native-elements'
import DropDownPicker from 'react-native-dropdown-picker'
import Colors from '../../common/constants/Colors'

type MultiValuesDisplayType = 'vertical' | 'horizontal'

export const SwitchSelect = StyleSheet.create({
  defaultDropdown: {
    fontWeight: 'bold',
    marginVertical: 10,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    padding: 10,
    borderRadius: 10,
    borderColor: '#EDF2F4',
    borderWidth: 3,
    maxHeight: 300,
  },
  defaultPlaceHolder: {
    color: '#E0E0E0',
    fontWeight: 'bold',
  },
  defaultStyle: {
    backgroundColor: '#FAFBFF',
    flexDirection: 'row',
    fontSize: 20,
    borderColor: '#D7DFFF',
    borderWidth: 3,
    alignItems: 'center',
    borderRadius: 10,
    padding: 15,
  },
  defaultListItem: {
    color: '#1F1F20',
    fontSize: 16,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  defaultSelectedLabelItem: {
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  defaultSelectedItemContainer: {},
  defaultLabel: {
    fontWeight: 'bold',
  },
})

export type SelectItem = {
  options: any
  dropDownContainerStyle?: any
  placeholder?: string
  disableBorderRadius?: boolean
  style?: any
  placeholderStyle?: any
  borderRadius?: number
  labelStyle?: any
  backgroundColor?: string
  listItemLabelStyle?: any
  arrowUpIcon?: JSX.Element
  arrowDownIcon?: JSX.Element
  value?: any | any[]
  multiple?: boolean
  defaultValue?: any | any[]
  multiValuesDisplayType?: MultiValuesDisplayType
  dropDownDirection?: 'DEFAULT' | 'TOP' | 'BOTTOM' | 'AUTO'
  disabled?: boolean
}

export type SelectProps = {
  selectOption: SelectItem
  onChange?: (value?: Array<string | undefined>) => void
}

export function Select({
  selectOption: {
    options,
    listItemLabelStyle,
    labelStyle,
    placeholder,
    placeholderStyle,
    dropDownContainerStyle,
    style,
    disableBorderRadius,
    borderRadius,
    backgroundColor,
    arrowUpIcon,
    arrowDownIcon,
    value,
    multiple,
    defaultValue,
    dropDownDirection,
    multiValuesDisplayType = 'vertical',
    disabled,
    ...props
  },
  onChange,
}: SelectProps) {
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState<any>(defaultValue ?? [])
  const [items, setItems] = useState(options)

  useEffect(() => {
    onChange && onChange(selectedValue)
    setOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  return (
    <SafeAreaView>
      <View style={styles.container}>
        <DropDownPicker
          open={open}
          disabled={disabled}
          value={selectedValue}
          items={items}
          dropDownDirection={dropDownDirection}
          showTickIcon={false}
          ArrowUpIconComponent={({ style }) =>
            arrowUpIcon ?? (
              <Icon
                name="caret-up"
                type="font-awesome"
                color={disabled ? Colors.disabledColor : '#517fa4'}
                tvParallaxProperties={{}}
              />
            )
          }
          ArrowDownIconComponent={() =>
            arrowDownIcon ?? (
              <Icon
                name="caret-down"
                type="font-awesome"
                color={disabled ? Colors.disabledColor : '#517fa4'}
                tvParallaxProperties={{}}
              />
            )
          }
          placeholder={placeholder}
          labelStyle={[
            SwitchSelect.defaultLabel,
            { color: disabled ? Colors.disabledColor : Colors.mainTextColor },
            labelStyle,
          ]}
          placeholderStyle={[SwitchSelect.defaultPlaceHolder, placeholderStyle]}
          style={[SwitchSelect.defaultStyle, style]}
          dropDownContainerStyle={[SwitchSelect.defaultDropdown, dropDownContainerStyle]}
          disableBorderRadius={disableBorderRadius}
          listItemLabelStyle={[SwitchSelect.defaultListItem, listItemLabelStyle]}
          selectedItemContainerStyle={[SwitchSelect.defaultSelectedItemContainer]}
          selectedItemLabelStyle={[listItemLabelStyle, SwitchSelect.defaultSelectedLabelItem]}
          setOpen={setOpen}
          setValue={setSelectedValue}
          setItems={setItems}
          multiple={multiple}
          multipleText={
            value && value.length > 0 && multiple
              ? multiValuesDisplayType === 'vertical'
                ? value?.join(',\n')
                : value?.join(', ')
              : 'Tất cả'
          }
        />
      </View>
    </SafeAreaView>
  )
}

export default Select

const styles = StyleSheet.create({
  container: { minHeight: Layout.isMobileDevice && Platform.OS !== 'web' ? 300 : 0 },
})
