import LoadingIndicator from 'components/LoadingIndicator'
import * as React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { styles as globalStyles } from '../../common/constants/globalStyles'

export type AppBarProps = {
  leading?: JSX.Element
  title?: JSX.Element
  trailing?: JSX.Element
  containerStyle?: Record<string, unknown>
  styleAppBar?: object
}

export function AppBar({ leading, title, trailing, styleAppBar, containerStyle }: AppBarProps) {
  return (
    <View style={globalStyles.DisplayFlexCol}>
      <View style={[styles.container, containerStyle, styleAppBar]}>
        {(leading || trailing) && <View style={styles.leading}>{leading}</View>}
        {title && <View style={styles.title}>{title}</View>}
        {(leading || trailing) && <View style={styles.trailing}>{trailing}</View>}
      </View>
      <LoadingIndicator />
    </View>
  )
}

export default AppBar

const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 56,
    zIndex: 1000,
    backgroundColor: '#D62828',
    alignItems: 'center',
  },
  leading: {
    minWidth: 48,
  },
  title: {
    flex: 1,
  },
  trailing: {
    minWidth: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
