import { Block, styles as globalStyles } from '../../../common'
import { ProductType } from '../../../constants/Enum'
import React, { useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import ChartBoxILP from './ChartBoxILP'

type IProps = {
  customerAge: number
  productTypeSolution?: ProductType
  data?: Array<{ policyYear: number; ape: number; fundValue: number }>
  style?: Record<string, unknown>
  extraInvest?: number
  isLoadingChartData?: boolean
  showInvestInfo?: boolean
  showFundInfo?: boolean
  onConfirm?: (quotation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }) => void
  quotationFundAllocation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }
  onOpenExtraInvestModal?: () => void
}

function SummarySolutionILP({
  customerAge,
  data,
  style,
  extraInvest,
  onConfirm,
  productTypeSolution,
  quotationFundAllocation,
  isLoadingChartData,
  showInvestInfo = true,
  showFundInfo = true,
  onOpenExtraInvestModal,
}: IProps) {

  const [product, setProduct] = useState<ProductType>()

  React.useEffect(() => {
    setProduct(productTypeSolution)
  }, [productTypeSolution])
  
  return (
    <View style={[desktopStyles.container, style]}>
      <Block
        child={
          <View>
            {/* Chart here */}
            <ChartBoxILP
              customerAge={customerAge}
              data={data}
              style={{ marginTop: 0 }}
              extraInvest={extraInvest}
              productTypeChart={product}
              onConfirm={onConfirm}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={quotationFundAllocation}
              showInvestInfo={showInvestInfo}
              showFundInfo={showFundInfo}
              onOpenExtraInvestModal={onOpenExtraInvestModal}
            />
          </View>
        }
      />
    </View>
  )
}

export default SummarySolutionILP

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    paddingVertical: 8,
  },
})
