import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Question from '../model/Question'
import SurveyService from '../services/SurveyService'
import { productTypes } from '../constants/Data'
import { hideLoading, showLoading } from './commonSlice'

export interface AuthenState {
  loading?: boolean
  questionnaireList?: Array<Question>
  productType?: { value: string; text: string }
}

const initialState: AuthenState = {
  loading: false,
  questionnaireList: [],
  productType: productTypes[1],
}

export const getQuestionnaireList = createAsyncThunk('advisor/questionnaire', async (req: any, thunkAPI: any) => {
  thunkAPI.dispatch(showLoading({}))
  const response = await SurveyService.getQuestionnaireList(req)
  thunkAPI.dispatch(hideLoading({}))
  return response.data
})

function updateQuestionnaireListWithAnswer(questionnaireList?: Array<Question>, questionId?: string, answer?: string) {
  let temp = questionnaireList ? [...questionnaireList] : []
  let index = temp.findIndex((e) => e._id === questionId)
  temp[index].userAnswer = answer
  return [...temp]
}

export const advisorSlice = createSlice({
  name: 'advisor',
  initialState,
  reducers: {
    setAnswer: (state, action: PayloadAction<{ questionId?: string; answer?: string }>) => {
      state.questionnaireList = updateQuestionnaireListWithAnswer(
        state.questionnaireList,
        action.payload.questionId,
        action.payload.answer
      )
    },
    setProductType: (state, action: PayloadAction<{ value: string; text: string } | undefined>) => {
      state.productType = action.payload
    },
    updateAnswers: (state, action: PayloadAction<Array<{ question: string; answer: string }> | undefined>) => {
      let selectedQuestions = action.payload
      let temp = state.questionnaireList ? [...state.questionnaireList] : []
      temp = temp.map((e) => {
        return {
          ...e,
          userAnswer: selectedQuestions?.find((k) => k.question === e.question)?.answer,
        }
      })
      state.questionnaireList = [...temp]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionnaireList.pending, (state) => {
        state.loading = true
      })
      .addCase(getQuestionnaireList.fulfilled, (state, action) => {
        state.loading = false
        state.questionnaireList = action.payload
      })
  },
})

export const { setAnswer, setProductType, updateAnswers } = advisorSlice.actions
const { reducer } = advisorSlice

export default reducer
