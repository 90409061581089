import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { genders, needsData, productTypes } from '../constants/Data'
import CNADetail from '../model/CNADetail'
import ApiException from '../model/exception/ApiException'
import ProfileInfo from '../model/ProfileInfo'
import cnaService from '../services/CustomAnalyzeService'
import { updateProfile, updateNeedsCartFromCache } from './needsCartSlice'
import { DataRes } from '../model/networks/DataRes'
import { updateUserProtect, updateUserProtectOriginal } from './userSlice'
import { showLoading, hideLoading, showLoadingWithValue, hideLoadingWithValue } from './commonSlice'
import { updateAnswers } from './advisorSlice'
import { updateNeedsOverall } from './needsOverallSlice'
import { setProductType } from './advisorSlice'
import i18n from 'i18n-js'
import toastService from '../services/toast/ToastService'

export interface CNAState {
  /**
   * @deprecated
   * This field is deprecated, use [loadingSaveDraft] or [loadingSaveContinue] instead
   */
  loading?: boolean
  loadingCnaDetail?: boolean
  currentHandlingCNA?: CNADetail
  agentCnaList?: DataRes<Array<CNADetail>>
  error?: ApiException
  cnaReview?: DataRes<Array<CNADetail>>
  cnaMonthList?: DataRes<Array<CNADetail>>
  cnaTodayList?: DataRes<Array<CNADetail>>
  loadingCnaList?: boolean
  loadingSaveDraft?: boolean
  loadingSaveContinue?: boolean
  loadingMoreMonthList?: boolean
  loadingMoreReviewList?: boolean
  loadingMoreTodayList?: boolean
  loadingDeleteCna?: boolean
  isHaveMoreMonthList?: boolean
  isHaveMoreReviewList?: boolean
  isHaveMoreTodayList?: boolean
}

const initialState: CNAState = {
  loading: false,
  loadingCnaDetail: false,
  error: undefined,
  agentCnaList: {},
  cnaReview: {},
  cnaMonthList: {},
  cnaTodayList: {},
  loadingCnaList: false,
  loadingMoreMonthList: false,
  loadingMoreReviewList: false,
  loadingMoreTodayList: false,
  loadingDeleteCna: false,
  isHaveMoreMonthList: true,
  isHaveMoreReviewList: true,
  isHaveMoreTodayList: true,
}

export const getAgentCnaList = createAsyncThunk('cna/get-all', async (req: any, thunkAPI: any) => {
  try {
    let response = await cnaService.getListCnaOfAgent(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const getCnaReviewList = createAsyncThunk('cna/summary-all', async (req: any, thunkAPI: any) => {
  try {
    let response = await cnaService.getListCnaOfAgent(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const getCnaMonthList = createAsyncThunk('cna/summary-completed', async (req: any, thunkAPI: any) => {
  try {
    let response = await cnaService.getListCnaOfAgent(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const getCnaTodayList = createAsyncThunk('cna/summary-review', async (req: any, thunkAPI: any) => {
  try {
    let response = await cnaService.getListCnaOfAgent(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const getCNADetail = createAsyncThunk('cna/detail', async (req: any, thunkAPI: any) => {
  try {
    thunkAPI.dispatch(showLoadingWithValue({}))
    let response = await cnaService.getDetailCna(req)
    let cnaData = response?.data as CNADetail
    if (!cnaData) {
      return
    }
    // Update local profile data
    thunkAPI.dispatch(
      updateProfile({
        gender: cnaData?.customerGender,
        genderName: genders.find((e) => e.code === cnaData?.customerGender)?.name,
        age: cnaData?.customerAge,
        monthlyIncome: cnaData?.protectIncome?.monthlyIncome,
        monthlyExpense: cnaData?.protectLife?.monthlyFixedExpense,
        maritalStatus: cnaData?.maritalStatus,
      } as ProfileInfo)
    )

    thunkAPI.dispatch(
      updateUserProtect({
        cnaData,
      })
    )

    thunkAPI.dispatch(
      updateUserProtectOriginal({
        cnaData,
      })
    )

    // Update needs cart data
    let patternNeedsData = [...needsData]
    patternNeedsData.forEach((e, i) => {
      let temp = [...e.data]
      temp.forEach((k, j) => {
        if ((cnaData as any)[k.key]) {
          temp[j] = {
            ...temp[j],
            isChecked: (cnaData as any)[k.key]?.isSelect,
            qty: (cnaData as any)[k.key]?.total,
            isTemporaryCheck: (cnaData as any)[k.key]?.isTemporaryCheck,
          }
        }
      })
      patternNeedsData[i] = { ...e, data: temp }
    })
    thunkAPI.dispatch(updateNeedsCartFromCache(patternNeedsData))

    // Update needs overall
    let data: Array<any> = []
    patternNeedsData.forEach((e) => {
      if (e.data) {
        e.data.forEach((e) => {
          if (e.isChecked) {
            data.push({
              ...e,
            })
          }
        })
      }
    })
    thunkAPI.dispatch(updateNeedsOverall([...data]))

    // Update questionnaire
    thunkAPI.dispatch(updateAnswers(cnaData?.questionObjects))

    // Update product type
    thunkAPI.dispatch(setProductType(productTypes.find((e) => e.value === cnaData?.product)))

    thunkAPI.dispatch(hideLoadingWithValue({}))
    return response.data
  } catch (error: any) {
    console.error(error)
    thunkAPI.dispatch(hideLoading({}))
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const deleteCNA = createAsyncThunk('cna/delete', async (req: any, thunkAPI: any) => {
  try {
    await cnaService.deleteCna(req)
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const getCachedCNA = createAsyncThunk('cna/cached-detail', async (req: any, thunkAPI: any) => {
  try {
    thunkAPI.dispatch(getCNADetail(req))
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const saveCNA = createAsyncThunk('cna/save', async (req: any, thunkAPI: any) => {
  try {
    let response
    if (req._id) {
      response = await cnaService.updateCna(req)
      thunkAPI.dispatch(getCNADetail({ id: req._id }))
    } else {
      response = await cnaService.saveNewCna(req)
      thunkAPI.dispatch(getCNADetail({ id: response.data.id }))
    }
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const cnaSlice = createSlice({
  name: 'createSlice',
  initialState,
  reducers: {
    createNewLocalCNA: (state, action: PayloadAction<any>) => {
      state.currentHandlingCNA = {} as CNADetail
    },
    setCnaReview: (state, action) => {
      state.cnaReview = action.payload
      state.isHaveMoreReviewList = true
    },
    setCnaMonthList: (state, action) => {
      state.cnaMonthList = action.payload
      state.isHaveMoreMonthList = true
    },
    setCnaTodayList: (state, action) => {
      state.cnaTodayList = action.payload
      state.isHaveMoreTodayList = true
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCNA.pending, (state, action) => {
        state.loading = true
        if (action.meta?.arg?.isSavingDraft) {
          state.loadingSaveDraft = true
        }
        if (action.meta?.arg?.isSavingContinue) {
          state.loadingSaveContinue = true
        }
      })
      .addCase(saveCNA.fulfilled, (state) => {
        state.loading = false
        state.loadingSaveDraft = false
        state.loadingSaveContinue = false
      })
      .addCase(saveCNA.rejected, (state, action) => {
        state.loading = false
        state.loadingSaveDraft = false
        state.loadingSaveContinue = false
        state.error = JSON.parse(action.error.message && action.error.message !== '' ? action.error.message : '{}')
      })
      .addCase(getAgentCnaList.pending, (state) => {
        state.loadingCnaList = true
      })
      .addCase(getAgentCnaList.fulfilled, (state, action) => {
        state.loadingCnaList = false
        state.agentCnaList = action.payload
      })
      .addCase(getAgentCnaList.rejected, (state) => {
        state.loadingCnaList = false
      })
      .addCase(getCnaReviewList.fulfilled, (state, action) => {
        let newData = state.cnaReview ?? {}
        if (!newData?.items) {
          newData.items = []
        }
        if (action.payload?.items && action.payload?.items?.length > 0) {
          if (action.payload.totalRecords > 9) {
            newData.items = [...newData.items, ...action.payload.items]
          } else {
            newData.items = [...action.payload.items]
          }
        } else {
          state.isHaveMoreReviewList = false
        }
        const uniqueObjects = [...new Map(newData.items.map((item) => [item._id, item])).values()]
        const dataSort = uniqueObjects
        newData.items = dataSort
        state.cnaReview = {
          ...newData,
          totalRecords: action.payload.totalRecords ? action.payload.totalRecords : state.cnaReview?.totalRecords,
        }
        state.loadingMoreReviewList = false
      })
      .addCase(getCnaReviewList.rejected, (state) => {
        state.loadingMoreReviewList = false
      })
      .addCase(getCnaMonthList.pending, (state) => {
        state.loadingMoreMonthList = true
      })
      .addCase(getCnaMonthList.fulfilled, (state, action) => {
        let newData = state.cnaMonthList ?? {}
        if (!newData?.items) {
          newData.items = []
        }
        if (action.payload?.items && action.payload?.items?.length > 0) {
          if (action.payload.totalRecords > 9) {
            newData.items = [...newData.items, ...action.payload.items]
          } else {
            newData.items = [...action.payload.items]
          }
        } else {
          state.isHaveMoreMonthList = false
        }
        const uniqueObjects = [...new Map(newData.items.map((item) => [item._id, item])).values()]
        const dataSort = uniqueObjects
        newData.items = dataSort
        state.cnaMonthList = {
          ...newData,
          totalRecords: action.payload.totalRecords ? action.payload.totalRecords : state.cnaMonthList?.totalRecords,
        }
        state.loadingMoreMonthList = false
      })
      .addCase(getCnaMonthList.rejected, (state) => {
        state.loadingMoreMonthList = false
      })
      .addCase(getCnaTodayList.pending, (state) => {
        state.loadingMoreTodayList = true
      })
      .addCase(getCnaTodayList.fulfilled, (state, action) => {
        let newData = state.cnaTodayList ?? {}
        if (!newData?.items) {
          newData.items = []
        }
        if (action.payload?.items && action.payload?.items?.length > 0) {
          if (action.payload.totalRecords > 9) {
            newData.items = [...newData.items, ...action.payload.items]
          } else {
            newData.items = [...action.payload.items]
          }
        } else {
          state.isHaveMoreTodayList = false
        }

        const uniqueObjects = [...new Map(newData.items.map((item) => [item._id, item])).values()]
        const dataSort = uniqueObjects
        newData.items = dataSort
        state.cnaTodayList = {
          ...newData,
          totalRecords: action.payload.totalRecords ? action.payload.totalRecords : state.cnaTodayList?.totalRecords,
        }
        state.loadingMoreTodayList = false
      })
      .addCase(getCnaTodayList.rejected, (state) => {
        state.loadingMoreTodayList = false
      })
      .addCase(getCNADetail.pending, (state) => {
        state.loadingCnaDetail = true
      })
      .addCase(getCNADetail.fulfilled, (state, action) => {
        state.currentHandlingCNA = action.payload
        state.loadingCnaDetail = false
      })
      .addCase(getCNADetail.rejected, (state) => {})

      // Delete CNA
      .addCase(deleteCNA.pending, (state) => {
        state.loadingDeleteCna = true
      })
      .addCase(deleteCNA.fulfilled, (state) => {
        state.cnaTodayList = {}
        state.cnaReview = {}
        state.cnaMonthList = {}
        state.isHaveMoreTodayList = true
        state.isHaveMoreMonthList = true
        state.isHaveMoreReviewList = true
        state.loadingDeleteCna = false
      })
      .addCase(deleteCNA.rejected, (state) => {
        state.loadingDeleteCna = false
      })
  },
})

export const { createNewLocalCNA, setCnaReview, setCnaMonthList, setCnaTodayList } = cnaSlice.actions

const { reducer } = cnaSlice
export default reducer
