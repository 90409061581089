import { Block, styles as globalStyles, mathHelper, SizedBox, vnNumberFormatter, Spacer } from '../../../common'
import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, FlatList } from 'react-native'
import icWallet from '../../../assets/images/wallet.png'
import icActivity from '../../../assets/images/activity.png'
import icGraph from '../../../assets/images/graph.png'
import { ApeDisplayType } from '../../../constants/Enum'
import i18n from 'i18n-js'
import LinearGradientBackground from '../../../common/components/LinearGradientBackground'
import icCircleDollar from '../../../assets/images/circle-dollar.png'
import { Slider } from 'react-native-elements'
import Colors from '../../../common/constants/Colors'
import UserInfoData from '../../../model/UserInfoData'
import { i18nAgentWording } from '../../../components/AgentWording'

const cardWidth = 200

type IProps = {
  userInfo?: UserInfoData
  needTotal?: number
  productType?: string
  needsData: Array<any>
  style?: Record<string, unknown>
  onOpenFeeModal?: () => void
  sumAssured?: number
  periodPlan?: number
  ape?: number
  showNeedsDetail?: boolean
  hcPlanDetail?: string
}

function SummaryInfo({
  userInfo,
  needTotal,
  productType,
  needsData,
  style,
  onOpenFeeModal,
  sumAssured,
  periodPlan,
  ape,
  showNeedsDetail = true,
  hcPlanDetail,
}: IProps) {
  function countDisplaySumAssure() {
    return mathHelper.divideWithDynamicPrecisionResult(sumAssured!, 1, 3)
  }

  function countApeDisplayValue(apeDisplayType: ApeDisplayType) {
    if (!ape) return 0
    let apeInMillionUnit = mathHelper.divideWithDynamicPrecisionResult(ape, 1000000, 3)
    switch (apeDisplayType) {
      case ApeDisplayType.YEAR:
        return apeInMillionUnit
      case ApeDisplayType.MONTH:
        return mathHelper.divideWithDynamicPrecisionResult(apeInMillionUnit, 12, 3)
      default:
        return 0
    }
  }

  function getApeDisplayUnit(apeDisplayType: ApeDisplayType) {
    switch (apeDisplayType) {
      case ApeDisplayType.YEAR:
        return i18n.t('COMMON.MILLIONS_PER_YEAR')
      case ApeDisplayType.MONTH:
        return i18n.t('COMMON.MILLIONS_PER_MONTH')
      default:
        return
    }
  }

  function getQtyValue(qty?: number) {
    // We need to multiply to 1000000 because qty's unit is billion
    const qtyToCount = (qty ?? 0) * 1000000
    return (
      vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(qtyToCount ?? 0, 1000000, 3)) +
      ' ' +
      i18n.t('COMMON.MILLION')
    )
  }

  function renderNeedItem(
    icon: ImageSourcePropType,
    title: string,
    qty?: number,
    themeColor?: string,
    sliderValue?: number,
    extraInfo?: string
  ) {
    return (
      <View style={desktopStyles.itemContainer}>
        <Image source={icon} style={desktopStyles.itemIcon} />
        <View style={globalStyles.FullWidth}>
          <Text style={[desktopStyles.itemTitle, { height: extraInfo ? undefined : 30 }]}>{title}</Text>
          <Text style={[desktopStyles.itemQty, { color: themeColor ?? '#E0E0E0' }]}>
            {extraInfo ?? getQtyValue(qty)}
          </Text>
          <View style={desktopStyles.sliderContainer}>
            <Slider
              style={{ height: 3.5, marginLeft: 8, marginRight: 40 }}
              minimumValue={0}
              maximumValue={1}
              value={sliderValue}
              minimumTrackTintColor={themeColor}
              maximumTrackTintColor={'#D7DFFF'}
              disabled
              thumbStyle={{ width: 0, height: 0 }}
              trackStyle={{ borderRadius: 9999, height: 3.5 }}
            />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      {showNeedsDetail && (
        <Block
          title="Tổng quan nhu cầu của bạn"
          titleStyle={{ fontSize: 20, marginBottom: 8 }}
          child={
            <View style={desktopStyles.needsItemsContainer}>
              <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
                <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={desktopStyles.highlightTitleContainer}>
                  <Image source={icCircleDollar as ImageSourcePropType} style={desktopStyles.dollarIcon} />
                  <Text style={desktopStyles.highlightTitleLabel}>{'TỔNG NHU CẦU BẢO VỆ'}&#58;</Text>
                  <Text style={desktopStyles.moneyTotal}>&nbsp;&nbsp;{vnNumberFormatter.format(needTotal ?? 0)}</Text>
                  <Text style={desktopStyles.highlightTitleLabel}>&nbsp;{i18n.t('COMMON.MILLION_VND')}</Text>
                </LinearGradientBackground>
              </View>
              <FlatList
                numColumns={4}
                data={needsData}
                style={desktopStyles.needsList}
                contentContainerStyle={{ overflow: 'hidden' }}
                renderItem={({ item }) => (
                  <View key={item.id} style={{ width: '25%' }}>
                    {renderNeedItem(
                      item.iconOutline as ImageSourcePropType,
                      i18nAgentWording({
                        agentOfficeCode: userInfo?.officeCode,
                        wordingCode: `NEEDS_ANALYST.LIST.${item.key}`,
                      }),
                      item.qty,
                      item.themeColor,
                      item.qty / (needTotal ?? 1),
                      item.key === 'hcPlan' ? hcPlanDetail : undefined
                    )}
                  </View>
                )}
              />
            </View>
          }
        />
      )}
      <View style={[globalStyles.DisplayFlexRow]}>
        <Text style={desktopStyles.label}>{'Giải pháp từ Prudential'}</Text>
        <Text style={[globalStyles.RedText, { fontSize: 10, marginTop: 12 }]}>&nbsp;&nbsp;(1)&nbsp;&nbsp;</Text>
        {productType && <Text style={[desktopStyles.productTitle]}>{i18n.t(`PRODUCT_TYPE.${productType}`)}</Text>}
      </View>
      <View style={desktopStyles.needsTotalContainer}>
        <View style={desktopStyles.needsTotalItemsBlockContainer}>
          <Block
            child={
              <View style={desktopStyles.needsTotalItemsContainer}>
                <View style={[desktopStyles.needsSummaryIconContainer, { backgroundColor: '#FFE6E2' }]}>
                  <Image source={icWallet as ImageSourcePropType} style={desktopStyles.needsSummaryIcon} />
                </View>
                <View style={globalStyles.FullWidth}>
                  <Text style={desktopStyles.needsSummaryLabel}>Tổng mệnh giá bảo vệ cho khách hàng</Text>
                  <Text style={desktopStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                    countDisplaySumAssure()
                  )} ${i18n.t('COMMON.MILLION_VND')}`}</Text>
                </View>
              </View>
            }
          />
        </View>
        <View style={desktopStyles.needsTotalItemsBlockContainer}>
          <Block
            child={
              <View style={desktopStyles.needsTotalItemsContainer}>
                <View style={[desktopStyles.needsSummaryIconContainer, { backgroundColor: '#FFF9DD' }]}>
                  <Image source={icActivity as ImageSourcePropType} style={desktopStyles.needsSummaryIcon} />
                </View>
                <View style={globalStyles.FullWidth}>
                  <Text style={desktopStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TERM')}</Text>
                  <Text style={desktopStyles.needsSummaryValue}>{`${periodPlan ?? 0} ${i18n.t('COMMON.YEAR')} (${
                    periodPlan ? periodPlan * 12 : ''
                  } ${i18n.t('COMMON.MONTH')})`}</Text>
                </View>
              </View>
            }
          />
        </View>
        <View style={desktopStyles.needsTotalItemsBlockContainer}>
          <Block
            child={
              <View style={desktopStyles.needsTotalItemsContainer}>
                <View style={[desktopStyles.needsSummaryIconContainer, { backgroundColor: '#E3F8FA' }]}>
                  <Image source={icGraph as ImageSourcePropType} style={desktopStyles.needsSummaryIcon} />
                </View>
                <View style={globalStyles.FullWidth}>
                  <View style={globalStyles.FullWidth}>
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <Text
                        style={[desktopStyles.needsSummaryLabel, { height: 'unset' }]}
                      >{`Tổng tiền tích lũy cho bảo hiểm​`}</Text>
                      <Spacer />
                      <Text style={[globalStyles.RedText, { fontSize: 10 }]}>(2)</Text>
                    </View>
                    <SizedBox height={22} />
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FloatRight, globalStyles.VerticalCenter]}>
                      <Text style={desktopStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                        countApeDisplayValue(ApeDisplayType.YEAR)
                      )} ${getApeDisplayUnit(ApeDisplayType.YEAR)}`}</Text>
                    </View>
                  </View>
                  <SizedBox height={8} />
                  <View style={globalStyles.FullWidth}>
                    {/* <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <Text
                        style={[desktopStyles.needsSummaryLabel, { height: 'unset' }]}
                      >{`Tổng tiền tích lũy cho bảo hiểm theo tháng​:`}</Text>
                    </View> */}
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FloatRight, globalStyles.VerticalCenter]}>
                      <Text style={desktopStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                        countApeDisplayValue(ApeDisplayType.MONTH)
                      )} ${getApeDisplayUnit(ApeDisplayType.MONTH)}`}</Text>
                    </View>
                  </View>
                </View>
              </View>
            }
          />
        </View>
      </View>
    </View>
  )
}

export default SummaryInfo

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'column',
    paddingHorizontal: 16,
    width: '100%',
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 0,
    borderColor: '#FFAFAF',
    borderWidth: 2,
    borderRadius: 12,
    justifyContent: 'center',
  },
  needsList: {
    width: '100%',
    paddingVertical: 0,
    overflow: 'hidden',
  },
  needsTotalItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
    width: '100%',
    minHeight: 115.16,
  },
  totalItemsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  label: {
    color: '#505D6F',
    fontSize: 20,
    fontWeight: '700',
    marginTop: 10,
    marginLeft: 8,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  itemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 4,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemIcon: {
    width: 50,
    height: 50,
  },
  itemTitle: {
    color: '#505D6F',
    fontSize: 12.4374,
    marginLeft: 8.29,
    maxWidth: 133,
    height: 30,
  },
  itemQty: {
    fontSize: 12.4374,
    marginLeft: 8.29,
    marginTop: 4,
    fontWeight: 'bold',
  },
  needsTotalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  needsSummaryIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
    borderRadius: 16.58,
  },
  needsSummaryIcon: {
    width: 33.17,
    height: 33.17,
  },
  needsSummaryLabel: {
    textAlign: 'left',
    fontSize: 13,
    fontWeight: '600',
    color: '#1F1F20',
    marginLeft: 16.58,
    height: 64,
  },
  needsSummaryValue: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  needsTotalItemsBlockContainer: {
    width: '32%',
  },
  extraInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  viewFeeContainer: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 6.33,
    paddingHorizontal: 17.14,
    borderRadius: 19.9,
    flexDirection: 'row',
    marginTop: 8.08,
    alignItems: 'center',
  },
  viewFeeIcon: {
    width: 22.11,
    height: 22.11,
  },
  viewFeeLabel: {
    fontSize: 11.6,
    fontWeight: '600',
    color: '#2F7AEB',
    marginLeft: 8.02,
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 5,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -1,
    marginBottom: 10,
  },
  dollarIcon: {
    width: 40,
    height: 40,
  },
  highlightTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 24,
    fontWeight: '700',
    color: '#FFF',
  },
  sliderContainer: {
    marginTop: 9,
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  productTitle: {
    fontSize: 20,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginTop: 10,
  },
})
