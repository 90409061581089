import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, FlatList } from 'react-native'
import icCircleDollar from '../../../assets/images/circle-dollar.png'
import icCircleDollarRed from '../../../assets/images/circle-dollar-red.png'
import icCircleDollarYellow from '../../../assets/images/circle-dollar-yellow.png'
import { vnNumberFormatter, SizedBox, styles as globalStyles, Line } from '../../../common'
import i18n from 'i18n-js'
import Layout from '../../../constants/Layout'
import Colors from '../../../common/constants/Colors'
import LinearGradientBackground from '../../../common/components/LinearGradientBackground'
import LoadableComponent from '../../../common/components/LoadableComponent'

type IProps = {
  needTotal?: number
  needSubTotal?: number
  apePerMonth?: number
  apePerYear?: number
  apeSubPerMonth?: number
  apeSubPerYear?: number
  loadingSubApe?: boolean
  loadingApe?: boolean
}

function SummaryAPEInfo({
  needTotal,
  needSubTotal,
  apePerMonth,
  apePerYear,
  apeSubPerMonth,
  apeSubPerYear,
  loadingSubApe,
  loadingApe,
}: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  if (isMobileDevice) {
    return (
      <View style={mobileStyles.container}>
        <View style={mobileStyles.summaryContent}>
          {/* Title */}
          <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
            <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={mobileStyles.highlightTitleContainer}>
              <Image source={icCircleDollar as ImageSourcePropType} style={globalStyles.Icon24} />
              <Text style={mobileStyles.highlightTitleLabel}>
                {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUM_PROTECT_VALUE')}:
              </Text>
            </LinearGradientBackground>
          </View>
          <SizedBox height={8} />
          <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
            <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
              <LoadableComponent style={globalStyles.fullWidthHeight} loading={loadingSubApe}>
                <View style={mobileStyles.needTotalContainer}>
                  {/* Sub need total */}
                  <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth, globalStyles.Center]}>
                    <Image source={icCircleDollarRed as ImageSourcePropType} style={globalStyles.Icon24} />
                    <SizedBox height={8} />
                    <View
                      style={[
                        globalStyles.DisplayFlexCol,
                        globalStyles.Flex1,
                        { alignItems: 'center', paddingBottom: 16 },
                      ]}
                    >
                      <Text style={[mobileStyles.needTotalTitleLabel, { color: Colors.mainTextColor }]}>
                        {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUB_NEED_TOTAL')}&#58;
                      </Text>
                      <SizedBox height={20} />
                      <View style={[globalStyles.DisplayFlexRow, { alignItems: 'flex-end' }]}>
                        <Text
                          style={[
                            mobileStyles.moneyTotal,
                            { color: Colors.primaryRed, flex: 1, textAlign: 'right', fontSize: 20 },
                          ]}
                        >
                          &nbsp;{vnNumberFormatter.format(needSubTotal ?? 0)}
                        </Text>
                        <Text style={[mobileStyles.moneyTotal, { color: '#ED1B2E' }]}>
                          &nbsp;{i18n.t('COMMON.MILLION_VND').toLowerCase()}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* Sub ape per year */}
                  <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={20} />
                      <Text style={[globalStyles.Bullet, { color: '#FC573B' }]}>•&nbsp;</Text>
                      <Text style={mobileStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                    </View>
                    <SizedBox height={4} />
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={30} />
                      <Text style={[mobileStyles.feeValue, { color: '#FC573B' }]}>
                        {vnNumberFormatter.format(apeSubPerYear ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                      </Text>
                    </View>
                  </View>
                  <SizedBox height={16} />
                  {/* Sub ape per month */}
                  <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={20} />
                      <Text style={[globalStyles.Bullet, { color: '#FC573B' }]}>•&nbsp;</Text>
                      <Text style={mobileStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                    </View>
                    <SizedBox height={4} />
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={30} />
                      <Text style={[mobileStyles.feeValue, { color: '#FC573B' }]}>
                        {vnNumberFormatter.format(apeSubPerMonth ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                      </Text>
                    </View>
                  </View>
                </View>
              </LoadableComponent>
            </View>
            <Line orientation="vertical" lineStyle="solid" size={230} color="#ECF0FF" lineWidth={2} />
            <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
              <LoadableComponent style={globalStyles.fullWidthHeight} loading={loadingApe}>
                <View style={mobileStyles.needTotalContainer}>
                  {/* Need total */}
                  <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth, globalStyles.Center]}>
                    <Image source={icCircleDollarYellow as ImageSourcePropType} style={globalStyles.Icon24} />
                    <SizedBox height={8} />
                    <View
                      style={[
                        globalStyles.DisplayFlexCol,
                        globalStyles.Flex1,
                        { alignItems: 'center', paddingBottom: 16 },
                      ]}
                    >
                      <Text style={[mobileStyles.needTotalTitleLabel, { color: Colors.mainTextColor }]}>
                        {i18n.t('NEEDS_OVERALL_EDIT_MODE.ACTUAL_NEED_TOTAL')}&#58;
                      </Text>
                      <SizedBox height={20} />
                      <View style={[globalStyles.DisplayFlexRow, { alignItems: 'flex-end' }]}>
                        <Text
                          style={[
                            mobileStyles.moneyTotal,
                            { color: '#F77F00', flex: 1, textAlign: 'right', fontSize: 20 },
                          ]}
                        >
                          &nbsp;{vnNumberFormatter.format(needTotal ?? 0)}
                        </Text>
                        <Text style={[mobileStyles.moneyTotal, { color: Colors.accentTextColor }]}>
                          &nbsp;{i18n.t('COMMON.MILLION_VND').toLowerCase()}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* Ape per year */}
                  <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={20} />
                      <Text style={[globalStyles.Bullet, { color: Colors.accentTextColor }]}>•&nbsp;</Text>
                      <Text style={mobileStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                    </View>
                    <SizedBox height={4} />
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={30} />
                      <Text style={mobileStyles.feeValue}>
                        {vnNumberFormatter.format(apePerYear ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                      </Text>
                    </View>
                  </View>
                  <SizedBox height={16} />
                  {/* Ape per month */}
                  <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={20} />
                      <Text style={[globalStyles.Bullet, { color: Colors.accentTextColor }]}>•&nbsp;</Text>
                      <Text style={mobileStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                    </View>
                    <SizedBox height={4} />
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <SizedBox width={30} />
                      <Text style={mobileStyles.feeValue}>
                        {vnNumberFormatter.format(apePerMonth ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                      </Text>
                    </View>
                  </View>
                </View>
              </LoadableComponent>
            </View>
          </View>
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={tabletStyles.summaryContent}>
        {/* Title */}
        <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
          <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={tabletStyles.highlightTitleContainer}>
            <Image source={icCircleDollar as ImageSourcePropType} style={globalStyles.Icon42} />
            <Text style={tabletStyles.highlightTitleLabel}>{i18n.t('NEEDS_OVERALL_EDIT_MODE.SUM_PROTECT_VALUE')}</Text>
          </LinearGradientBackground>
        </View>
        <SizedBox height={8} />
        <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
          <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
            <LoadableComponent style={globalStyles.fullWidthHeight} loading={loadingSubApe}>
              <View style={tabletStyles.needTotalContainer}>
                {/* Sub need total */}
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
                  <Image source={icCircleDollarRed as ImageSourcePropType} style={globalStyles.Icon42} />
                  <View
                    style={[
                      globalStyles.DisplayFlexRow,
                      globalStyles.Flex1,
                      { alignItems: 'flex-end', paddingBottom: 16 },
                    ]}
                  >
                    <Text style={[tabletStyles.needTotalTitleLabel, { color: Colors.mainTextColor }]}>
                      {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUB_NEED_TOTAL')}&#58;
                    </Text>
                    <Text
                      style={[
                        tabletStyles.moneyTotal,
                        { color: Colors.primaryRed, flex: 1, textAlign: 'right', fontSize: 32, marginBottom: -4 },
                      ]}
                    >
                      &nbsp;{vnNumberFormatter.format(needSubTotal ?? 0)}
                    </Text>
                    <Text style={[tabletStyles.moneyTotal, { color: '#ED1B2E' }]}>
                      &nbsp;{i18n.t('COMMON.MILLION_VND').toLowerCase()}
                    </Text>
                  </View>
                </View>
                <SizedBox height={24} />
                {/* Sub ape per year */}
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                  <Text style={tabletStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                  <Text style={[tabletStyles.feeValue, { color: '#FC573B' }]}>
                    {vnNumberFormatter.format(apeSubPerYear ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                  </Text>
                </View>
                <SizedBox height={16} />
                {/* Sub ape per month */}
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                  <Text style={tabletStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                  <Text style={[tabletStyles.feeValue, { color: '#FC573B' }]}>
                    {vnNumberFormatter.format(apeSubPerMonth ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                  </Text>
                </View>
              </View>
            </LoadableComponent>
          </View>
          <Line orientation="vertical" lineStyle="solid" size={162} color="#ECF0FF" />
          <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
            <LoadableComponent style={globalStyles.fullWidthHeight} loading={loadingApe}>
              <View style={tabletStyles.needTotalContainer}>
                {/* Need total */}
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
                  <Image source={icCircleDollarYellow as ImageSourcePropType} style={globalStyles.Icon42} />
                  <View
                    style={[
                      globalStyles.DisplayFlexRow,
                      globalStyles.Flex1,
                      { alignItems: 'flex-end', paddingBottom: 16 },
                    ]}
                  >
                    <Text style={[tabletStyles.needTotalTitleLabel, { color: Colors.mainTextColor }]}>
                      {i18n.t('NEEDS_OVERALL_EDIT_MODE.ACTUAL_NEED_TOTAL')}&#58;
                    </Text>
                    <Text
                      style={[
                        tabletStyles.moneyTotal,
                        { color: '#F77F00', flex: 1, textAlign: 'right', fontSize: 32, marginBottom: -4 },
                      ]}
                    >
                      &nbsp;{vnNumberFormatter.format(needTotal ?? 0)}
                    </Text>
                    <Text style={[tabletStyles.moneyTotal, { color: Colors.accentTextColor }]}>
                      &nbsp;{i18n.t('COMMON.MILLION_VND').toLowerCase()}
                    </Text>
                  </View>
                </View>
                <SizedBox height={24} />
                {/* Ape per year */}
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                  <Text style={tabletStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                  <Text style={tabletStyles.feeValue}>
                    {vnNumberFormatter.format(apePerYear ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                  </Text>
                </View>
                <SizedBox height={16} />
                {/* Ape per month */}
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                  <Text style={tabletStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                  <Text style={tabletStyles.feeValue}>
                    {vnNumberFormatter.format(apePerMonth ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                  </Text>
                </View>
              </View>
            </LoadableComponent>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={desktopStyles.summaryContent}>
      {/* Title */}
      <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
        <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={desktopStyles.highlightTitleContainer}>
          <Image source={icCircleDollar as ImageSourcePropType} style={globalStyles.Icon42} />
          <Text style={desktopStyles.highlightTitleLabel}>{i18n.t('NEEDS_OVERALL_EDIT_MODE.SUM_PROTECT_VALUE')}</Text>
        </LinearGradientBackground>
      </View>
      <SizedBox height={8} />
      <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
        <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
          <LoadableComponent style={globalStyles.fullWidthHeight} loading={loadingSubApe}>
            <View style={desktopStyles.needTotalContainer}>
              {/* Sub need total */}
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
                <Image source={icCircleDollarRed as ImageSourcePropType} style={globalStyles.Icon42} />
                <View
                  style={[
                    globalStyles.DisplayFlexRow,
                    globalStyles.Flex1,
                    { alignItems: 'flex-end', paddingBottom: 16 },
                  ]}
                >
                  <Text style={[desktopStyles.needTotalTitleLabel, { color: Colors.mainTextColor }]}>
                    {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUB_NEED_TOTAL')}&#58;
                  </Text>
                  <Text
                    style={[
                      desktopStyles.moneyTotal,
                      { color: Colors.primaryRed, flex: 1, textAlign: 'right', fontSize: 32, marginBottom: -4 },
                    ]}
                  >
                    &nbsp;{vnNumberFormatter.format(needSubTotal ?? 0)}
                  </Text>
                  <Text style={[desktopStyles.moneyTotal, { color: '#ED1B2E' }]}>
                    &nbsp;{i18n.t('COMMON.MILLION_VND').toLowerCase()}
                  </Text>
                </View>
              </View>
              <SizedBox height={24} />
              {/* Sub ape per year */}
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                <Text style={desktopStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                <Text style={[desktopStyles.feeValue, { color: '#FC573B' }]}>
                  {vnNumberFormatter.format(apeSubPerYear ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                </Text>
              </View>
              <SizedBox height={16} />
              {/* Sub ape per month */}
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                <Text style={desktopStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                <Text style={[desktopStyles.feeValue, { color: '#FC573B' }]}>
                  {vnNumberFormatter.format(apeSubPerMonth ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                </Text>
              </View>
            </View>
          </LoadableComponent>
        </View>
        <Line orientation="vertical" lineStyle="solid" size={162} color="#ECF0FF" />
        <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
          <LoadableComponent style={globalStyles.fullWidthHeight} loading={loadingApe}>
            <View style={desktopStyles.needTotalContainer}>
              {/* Need total */}
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth, globalStyles.Center]}>
                <Image source={icCircleDollarYellow as ImageSourcePropType} style={globalStyles.Icon42} />
                <View
                  style={[
                    globalStyles.DisplayFlexRow,
                    globalStyles.Flex1,
                    { alignItems: 'flex-end', paddingBottom: 16 },
                  ]}
                >
                  <Text style={[desktopStyles.needTotalTitleLabel, { color: Colors.mainTextColor }]}>
                    {i18n.t('NEEDS_OVERALL_EDIT_MODE.ACTUAL_NEED_TOTAL')}&#58;
                  </Text>
                  <Text
                    style={[
                      desktopStyles.moneyTotal,
                      { color: '#F77F00', flex: 1, textAlign: 'right', fontSize: 32, marginBottom: -4 },
                    ]}
                  >
                    &nbsp;{vnNumberFormatter.format(needTotal ?? 0)}
                  </Text>
                  <Text style={[desktopStyles.moneyTotal, { color: Colors.accentTextColor }]}>
                    &nbsp;{i18n.t('COMMON.MILLION_VND').toLowerCase()}
                  </Text>
                </View>
              </View>
              <SizedBox height={24} />
              {/* Ape per year */}
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                <Text style={desktopStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                <Text style={desktopStyles.feeValue}>
                  {vnNumberFormatter.format(apePerYear ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                </Text>
              </View>
              <SizedBox height={16} />
              {/* Ape per month */}
              <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                <Text style={desktopStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                <Text style={desktopStyles.feeValue}>
                  {vnNumberFormatter.format(apePerMonth ?? 0)} {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                </Text>
              </View>
            </View>
          </LoadableComponent>
        </View>
      </View>
    </View>
  )
}

export default SummaryAPEInfo

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  contentContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 33.17,
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
    paddingBottom: 31,
  },
  summaryContentTitle: {
    fontSize: 18,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 31,
    marginTop: 31,
  },
  summaryContent: {
    flexDirection: 'column',
    margin: 31,
    marginTop: 8,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingLeft: 35,
    paddingRight: 16,
    paddingVertical: 35,
  },
  feeTitle: {
    flex: 1,
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 16,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 15,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  needTotalTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 18,
    fontWeight: '700',
    color: '#FFF',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  contentContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 33.17,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 13,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 13,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
    paddingBottom: 31,
  },
  summaryContentTitle: {
    fontSize: 15,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 31,
    marginTop: 31,
  },
  summaryContent: {
    flexDirection: 'column',
    margin: 31,
    marginTop: 8,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingLeft: 35,
    paddingRight: 16,
    paddingVertical: 35,
  },
  feeTitle: {
    flex: 1,
    fontSize: 13,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 13,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 13,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  needTotalTitleLabel: {
    fontSize: 13,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 13,
    fontWeight: '700',
    color: '#FFF',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFBFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 18,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 8,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  contentContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
    paddingBottom: 31,
  },
  summaryContentTitle: {
    fontSize: 15,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 31,
    marginTop: 31,
  },
  summaryContent: {
    flexDirection: 'column',
    marginTop: 8,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
    width: '100%',
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingVertical: 16,
  },
  feeTitle: {
    flex: 1,
    fontSize: 13,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 13,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  highlightTitleContainer: {
    width: '100%',
    paddingHorizontal: 0,
    paddingVertical: 10,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -2,
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 15,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  needTotalTitleLabel: {
    fontSize: 12,
    color: '#FFF',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 13,
    fontWeight: '700',
    color: '#FFF',
  },
})
