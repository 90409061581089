import imgAccumulateForEducation from './needs/accumulate-for-education.png'
import imgCharity from './needs/charity.png'
import imgInheritances from './needs/inheritances.png'
import imgInvestRaiseAssets from './needs/invest-raise-assets.png'
import imgMedicineCare from './needs/medicine-care.png'
import imgProtectAssets from './needs/protect-assets.png'
import imgProtectDescendantEducation from './needs/protect-descendant-education.png'
import imgProtectFatalDisease from './needs/protect-fatal-disease.png'
import imgProtectIncome from './needs/protect-income.png'
import imgProtectLifeStandard from './needs/protect-life-standard.png'
import imgRetirePlan from './needs/retire-plan.png'

export const IMAGES = {
  imgAccumulateForEducation,
  imgCharity,
  imgInheritances,
  imgInvestRaiseAssets,
  imgMedicineCare,
  imgProtectAssets,
  imgProtectDescendantEducation,
  imgProtectFatalDisease,
  imgProtectIncome,
  imgProtectLifeStandard,
  imgRetirePlan,
}
