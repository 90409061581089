import AsyncStorage from '@react-native-async-storage/async-storage'
import { configEnv, EnvironmentSingleton } from 'config'
import i18n from 'i18n-js'
import toastService from '../services/toast/ToastService'

const { pruquoteRedirectUrl } = configEnv(new EnvironmentSingleton().getEnv())

export const handleSSOPruquote = async (cnaId: string) => {
  try {
    const huutriToken = await AsyncStorage.getItem('huutriToken')
    if (huutriToken) {
      window.open(`${pruquoteRedirectUrl}?ssotoken=${huutriToken}&cnaId=${cnaId}`, '_blank')
      return true
    }
    toastService.error(i18n.t('MESS.SSO_PRUQUOTE_FAILED'))
    return false
  } catch (error) {
    toastService.error(i18n.t('MESS.SSO_PRUQUOTE_FAILED'))
    return false
  }
}