import React, { useState } from 'react'
import { RootState } from '../store'
import AppHeader from './AppHeader'
import { clearCart } from '../slices/needsCartSlice'
import { clearUserInfo } from '../slices/userSlice'
import { connect, ConnectedProps } from 'react-redux'
import { StepIndicator, SizedBox, TextButton, styles as globalStyles } from '../common'
import { View, ImageSourcePropType, Text, Image } from 'react-native'
import { createCNASteps } from '../constants/Data'
import { CnaStatus, ViewMode } from '../constants/Enum'
import SaveDraftButton from './SaveDraftButton'
import i18n from 'i18n-js'
import icBack from '../assets/images/back.png'
import icEditRed from '../assets/images/edit-red.png'
import CNADetail from '../model/CNADetail'
import Needs from '../model/Needs'
import { checkCnaNeedsInputted } from '../helper/CnaHelper'

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
})

const mapDispatchToProps = {
  clearCart,
  clearUserInfo,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type StepAppHeaderProps = PropsFromRedux & {
  route: any
  navigation: any
  onClearData?: () => void
  onSaveDraft?: () => void
  extra?: JSX.Element
  currentPosition?: number
  cnaData?: CNADetail
  needsCartList?: Array<Needs>
}

function StepAppHeader({
  route,
  navigation,
  onSaveDraft,
  currentPosition,
  userInfo,
  cnaData,
  needsCartList,
}: StepAppHeaderProps) {
  /**
   * Get step indicator background color
   */
  function getStepColor(index: number, currentStep: number) {
    if (index < currentStep) return '#FFFFFF'
    if (index === currentStep) return '#ED1B2E'
    else return '#BDBDBD'
  }

  function onSwitchToEditMode() {
    navigation.replace(route.name, { ...(route.params as any), mode: ViewMode.EDITABLE })
  }

  function onReturnToCnaList() {
    navigation.navigate('CNAList', { 'detail_id': cnaData?._id })
  }

  function onSwitchToStep(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        navigation.replace('NeedsCart', { ...(route.params as any) })
        break
      case 1:
        if (cnaData?.status) {
          if (cnaData?.status === CnaStatus.DRAFT || !checkCnaNeedsInputted(needsCartList)) {
            navigation.replace('CustomerNeed', { ...(route.params as any) })
            break
          } else {
            navigation.replace('NeedsOverall', { ...(route.params as any) })
            break
          }
        } else {
          break
        }
      case 2:
        if (cnaData?.status && checkCnaNeedsInputted(needsCartList)) {
          navigation.replace('Solution', { ...(route.params as any) })
          break
        } else {
          break
        }
      default:
        navigation.replace('NeedsCart', { ...(route.params as any) })
        break
    }
  }

  return (
    <AppHeader
      navigation={navigation}
      agentCode={userInfo?.sub}
      extra={
        <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
          <View style={[globalStyles.Expanded, globalStyles.Center]}>
            <StepIndicator
              stepCount={3}
              currentPosition={currentPosition ?? 0}
              renderStepIndicator={(params: { position: number }) => (
                <View>
                <Text style={{ color: getStepColor(params.position, currentPosition ?? 0), fontWeight: 'bold' }}>
                  {createCNASteps[params.position]}
                </Text>
                </View>
              )}
              onPress={onSwitchToStep}
            />
          </View>
          {(route.params as any)?.mode !== ViewMode.READ_ONLY ? (
            <SaveDraftButton onPress={onSaveDraft} />
          ) : (
            <>
              <SizedBox width={20} />
              <TextButton
                title={i18n.t('COMMON.EDIT')}
                prefix={<Image source={icEditRed as ImageSourcePropType} style={globalStyles.Icon18} />}
                onPress={onSwitchToEditMode}
              />
              <SizedBox width={20} />
              <TextButton
                title={i18n.t('COMMON.RETURN')}
                prefix={
                  <Image source={icBack as ImageSourcePropType} style={[globalStyles.Icon18, { marginBottom: 4 }]} />
                }
                onPress={onReturnToCnaList}
              />
              <SizedBox width={20} />
            </>
          )}
        </View>
      }
    />
  )
}

export default connector(StepAppHeader)
