import React from 'react'
import { View } from 'react-native'

export type AgentComponentProps = {
  agentOfficeCode?: string
  agentComponentMap: Map<string, JSX.Element>
  renderDefault: (agentOfficeCode?: string) => JSX.Element
}

const AgentComponent = ({ agentOfficeCode, agentComponentMap, renderDefault }: AgentComponentProps) => {
  if (agentOfficeCode && agentComponentMap.get(agentOfficeCode)) {
    return <View>{agentComponentMap.get(agentOfficeCode)}</View>
  }
  return <View>{renderDefault(agentOfficeCode)}</View>
}

export default AgentComponent
