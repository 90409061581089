import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { styles as globalStyles, BlockAlysys, ValidateRequiredError, InputData, SizedBox } from '../common'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { updateUserProtect } from '../slices/userSlice'
import i18n from 'i18n-js'
import { connect, ConnectedProps } from 'react-redux'
import Layout from '../constants/Layout'

const mapStateToProps = (state: RootState) => ({
  charity: state.charity.charity,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  saveCNA,
  updateUserProtect,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SaveCharityScreen({ charity, needsCartList, userProtect, saveCNA, updateUserProtect }: Props) {
  const isMobileDevice = Layout.isMobileDevice

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: charity,
  })

  useEffect(() => {
    if (userProtect?.charity) {
      setValue('subTotal', parseFloat(userProtect?.charity?.subTotal))
    }
  }, [userProtect])

  useEffect(() => {
    if (userProtect) {
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        charity: { ...userProtect?.charity, ...watch() },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }, [getValues('subTotal')])

  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Từ thiện"
          borderColor="#FC573B"
          child={
            <View>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title="Tổng số tiền bạn muốn dùng để làm từ thiện:"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => onChange(value)}
                    error={errors.subTotal && renderErrorMessage(errors.subTotal.message)}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Từ thiện"
        borderColor="#FC573B"
        child={
          <View>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <InputData
                  title="Tổng số tiền bạn muốn dùng để làm từ thiện:"
                  helpText="Triệu đồng"
                  type="column"
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(value)}
                  error={errors.subTotal && renderErrorMessage(errors.subTotal.message)}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <SizedBox width={24} />
    </View>
  )
}

export default connector(SaveCharityScreen)

const mobileStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  boxEduChild: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    width: '100%',
    flex: 1,
    // overflowY: 'scroll',
    paddingBottom: 100,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
