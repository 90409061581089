export const numberOnly = /^\d+(\.\d{1,})?$/

export const numberExist = /\d/

export const phoneRegex = /^\b0\w{9}\b/

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const ddmmyyyyRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/

export const validateRequired = {
  required: true,
}

export const validateAge = {
  required: true,
  min: 18,
  max: 65,
  pattern: numberOnly,
}

export const validateNumber = {
  required: true,
  min: 0,
  pattern: numberOnly,
}

export const validateMoney = {
  required: true,
  min: 0,
  pattern: numberOnly,
}

export const validatePhone = {
  required: true,
  maxLength: 12,
  pattern: phoneRegex,
}

export const validateEmail = {
  required: true,
  pattern: emailRegex,
}

export const validateDate = {
  required: true,
  pattern: ddmmyyyyRegex
}
