import React, { useEffect } from 'react'
import { View, StyleSheet, Text, ImageSourcePropType, Image } from 'react-native'
import {
  styles as globalStyles,
  BlockAlysys,
  InputData,
  Quantity,
  Table,
  Tooltips,
  Dialog,
  ValidateRequiredError,
  BoxFooter,
  Checkbox,
  SizedBox,
} from '../common'
import { useMediaQuery } from 'react-responsive'
import { tabletMediumMediaConfig } from '../constants/Constants'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { connect, ConnectedProps } from 'react-redux'
import { updateUserProtect } from '../slices/userSlice'
import Layout from '../constants/Layout'
import i18n from 'i18n-js'
import icDocument from '../assets/icon/document.png'

const mapStateToProps = (state: RootState) => ({
  eduPlan: state.eduPlan.eduPlan,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  saveCNA,
  updateUserProtect,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SaveEduScreen({ eduPlan, needsCartList, saveCNA, userProtect, updateUserProtect }: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice
  const isTabletSmallDevice =  Layout.isTabletSmallDevice

  const [showPopup, setShowPopup] = React.useState(false)
  // const [showPopup, setShowPopup] = React.useState(false)

  function countTotal() {
    const data = getValues(`childrenDetails`)
      ?.map((x) => {
        return (
          Number(x.schoolFees || 0) * Number(x.schoolYears || 0) +
          Number(x.universityFees || 0) * Number(x.universityYears || 0) +
          Number(x.afterUniFees || 0) * Number(x.afterUniYears || 0)
        )
      })
      .reduce((prev, next) => prev + next)
    setValue('subTotal', parseFloat(data))
    if (data === 0) {
      checkDataAppend()
    }
  }

  const dataAppend = {
    childAge: 0,
    schoolFees: 0,
    schoolYears: 0,
    universityFees: 0,
    universityYears: 0,
    afterUniFees: 0,
    afterUniYears: 0,
    isSchool: false,
    isUniversity: false,
    isAfterUni: false,
  }

  const columnsCustomer = [
    {
      title: `Giáo dục`,
      dataIndex: 'type',
      text: 'center',
      background: '#F7F7F7',
      width: '20%',
    },
    {
      title: '',
      dataIndex: 'name',
      text: 'flex-start',
      width: '35%',
    },
    {
      title: '',
      dataIndex: 'moneyYear',
      text: 'center',
      width: '20%',
    },
    {
      title: '',
      dataIndex: 'money',
      text: 'flex-end',
      width: '25%',
      paddingRight: 16,
    },
  ]

  const dataSourceCustomer = [
    { type: ' ', name: 'Trường Công lập tại VN', moneyYear: 25, money: 'triệu VND/năm' },
    { type: 'Cấp 1/2/3', name: 'Trường Dân lập tại VN', moneyYear: 100, money: 'triệu VND/năm' },
    { type: '  ', name: 'Trường Quốc tế tại VN', moneyYear: 300, money: 'triệu VND/năm', border: 1 },
    { type: ' ', name: 'ĐH Công lập tại VN', moneyYear: 30, money: 'triệu VND/năm' },
    { type: ' ', name: 'ĐH Dân lập tại VN', moneyYear: 80, money: 'triệu VND/năm' },
    { type: 'Đại học', name: 'ĐH Quốc tế tại VN', moneyYear: 300, money: 'triệu VND/năm' },
    { type: ' ', name: 'Du học Singapore/Úc/Nhật', moneyYear: 450, money: 'triệu VND/năm' },
    { type: ' ', name: 'Du học Châu Âu', moneyYear: 800, money: 'triệu VND/năm' },
    { type: ' ', name: 'Du học Mỹ', moneyYear: 800, money: 'triệu VND/năm' },
  ]

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',    
    shouldUnregister: false,
    defaultValues: eduPlan,
  })

  useEffect(() => {
    if (userProtect?.eduPlan?.childrenDetails) {
      reset(userProtect?.eduPlan, { errors: true })
    }
  }, [userProtect])



  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} style={{marginBottom: 10, marginTop: 0}} />
        </View>
      )
    } else {
      return <></>
    }
  }

  
  function renderErrorMessageStyle(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} style={{marginBottom: 10}} />
        </View>
      )
    } else {
      return <></>
    }
  }


  function countTotalChildren() {
    if (userProtect?.eduPlan?.childrenDetails) {
      const data =
        userProtect?.protectEdu?.childrenDetails &&
        userProtect?.protectEdu?.childrenDetails
          .map((x, index) => {
            return (
              (Number(userProtect?.protectEdu?.eduPlanAge || 0) - Number(getValues().childrenDetails[index]?.childAge) || 0) *
              Number(x.tuition || 0) *
              12
            )
          })
          .reduce((prev, next) => prev + next)
      if (data > 0 || isNaN(data) !== true) {
        return data
      } else {
        return 0
      }
    }
  }

  function checkDataAppend() {
    if (userProtect) { 
      const dataAppend = [...Array(Number(watch()?.children)).keys()]
      const dataChild = userProtect?.protectEdu?.childrenDetails || 1
      const dataAdd = dataAppend.map((x, index) => {
        return (
          dataChild[index] || {
            childAge: 0,
            tuition: 0,
          }
        )
      })
      const dataEduPlan =
        (dataAdd &&
          dataAdd.map((x, index) => ({
            ...x,
            childAge: watch()?.childrenDetails &&  Number(watch()?.childrenDetails[index]?.childAge) || 0,
          }))) ||
        1
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        protectEdu:
          userProtect?.protectEdu?.isSelect === true
            ? {
                ...userProtect?.protectEdu,
                children: Number(watch().children),
                subTotal: countTotalChildren(),
                childrenDetails: dataEduPlan ? dataEduPlan : dataAdd,
              }
            : userProtect?.protectEdu,
        eduPlan: {
          ...userProtect?.eduPlan,
          ...JSON.parse(JSON.stringify(watch())),
          isSelect: userProtect?.eduPlan?.isSelect,
        },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }

  useEffect(() => {
    if (getValues('subTotal') > 0 && userProtect) {
      checkDataAppend()
    }
  }, [getValues('subTotal')])

  function getAppendComponent(value: number) {
    let appendComponent = []
    for (let i = 0; i < value; i++) {
      appendComponent.push(renderView(i))
    }
    return appendComponent
  }

  function renderView(i: number) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.boxContent}>
        <View style={mobileStyles.boxEduChild} key={i}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View style={{marginBottom: 10, width: '100%'}}>
                <InputData
                  title={`Con thứ ${switchChild(i)}`}
                  helpText="Tuổi"
allowNagitive
                  type="row"
                  wrapHelpTextContainer={{paddingLeft: 15}}
                  stylesInput={{ marginRight: 0,marginBottom: 0 }}
                  textUnderline
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    checkDataAppend()
                  }}
                />
                {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && 
                   renderErrorMessageStyle(errors?.childrenDetails[i]?.childAge?.message)
                }
              </View>
            )}
            name={`childrenDetails.${i}.childAge`}
             rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
             
          />
          <Text style={mobileStyles.boxEduText}>
            Bạn muốn lập kế hoạch tích lũy cho giáo dục của con ở các bậc học nào?{' '}
          </Text>
          <View style={[globalStyles.DisplayFlexRow]}>
          <Text style={mobileStyles.textInputLabelFooter}>Bao gồm cấp 1, cấp 2, cấp 3. </Text>
        </View>
          <View style={mobileStyles.boxEduChild}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <Checkbox
                    checked={value}
                    containerStyle={{ marginBottom: 15 }}
                    title={
                      <View style={globalStyles.DisplayFlexRow}>
                        <Text style={mobileStyles.textInputLabel}>Phổ thông</Text>
                      </View>
                    }
                    onChange={ (value) => {
                       onChange(value)
                       clearErrors(`childrenDetails.${i}.schoolFees`)
                       clearErrors(`childrenDetails.${i}.schoolYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.schoolFees`, 0)
                        setValue(`childrenDetails.${i}.schoolYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isSchool`}
               rules={{}}
                
            />
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="row"
                    wrapHelpTextContainer={{paddingLeft: 15}}
                    stylesInput={{ marginBottom: 0 }}
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && getValues(`childrenDetails.${i}.isSchool`) === true &&  errors?.childrenDetails[i]?.schoolFees &&(
                    renderErrorMessageStyle(errors?.childrenDetails[i]?.schoolFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.schoolFees`}
              rules={{
                required: {
                  value:  getValues(`childrenDetails.${i}.isSchool`) ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }} 
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="row"
                    wrapHelpTextContainer={{paddingLeft: 15}}
                    stylesInput={{ marginBottom: 0 }}
                    disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && getValues(`childrenDetails.${i}.isSchool`) === true &&  errors?.childrenDetails[i]?.schoolYears && (
                   renderErrorMessageStyle(errors?.childrenDetails[i]?.schoolYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.schoolYears`}
              rules={{
                required: {
                  value:  getValues(`childrenDetails.${i}.isSchool`) ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                },
              }} 
            />
          </View>

          <View style={mobileStyles.boxEduChild}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <Checkbox
                    checked={value}
                    title={'Đại học'}
                    onChange={  (value) => {
                       onChange(value)
                       clearErrors(`childrenDetails.${i}.universityFees`)
                       clearErrors(`childrenDetails.${i}.universityYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.universityFees`, 0)
                        setValue(`childrenDetails.${i}.universityYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isUniversity`}
               rules={{}}
 
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="row"
                    wrapHelpTextContainer={{paddingLeft: 15}}
                    placeholder="Nhập"
                      stylesInput={{ marginBottom: 0 }}
                    disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && getValues(`childrenDetails.${i}.isUniversity`) === true  &&  errors?.childrenDetails[i]?.universityFees && (
                    renderErrorMessageStyle(errors?.childrenDetails[i]?.universityFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.universityFees`}
              rules={{
                required: {
                  value:  getValues(`childrenDetails.${i}.isUniversity`) ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                },
              }}            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="row"
                    wrapHelpTextContainer={{paddingLeft: 15}}
                    placeholder="Nhập"
                    disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    stylesInput={{ marginBottom: 0 }}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && getValues(`childrenDetails.${i}.isUniversity`) === true  &&  errors?.childrenDetails[i]?.universityYears && (
                    renderErrorMessageStyle(errors?.childrenDetails[i]?.universityYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.universityYears`}
              rules={{
                required: {
                  value:  getValues(`childrenDetails.${i}.isUniversity`) ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                },
              }}
            />
          </View>
          <View style={mobileStyles.boxEduChild}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <Checkbox
                    checked={value}
                    title={'Sau đại học'}
                    onChange={  (value) => {
                       onChange(value)
                       clearErrors(`childrenDetails.${i}.afterUniFees`)
                       clearErrors(`childrenDetails.${i}.afterUniYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.afterUniFees`, 0)
                        setValue(`childrenDetails.${i}.afterUniYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isAfterUni`}
               rules={{}}
 
            />
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="row"
                    wrapHelpTextContainer={{paddingLeft: 15}}
                    placeholder="Nhập"
                    stylesInput={{ marginBottom: 0 }}
                    disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && getValues(`childrenDetails.${i}.isAfterUni`) === true && errors?.childrenDetails[i]?.afterUniFees && (
                   renderErrorMessageStyle(errors?.childrenDetails[i]?.afterUniFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.afterUniFees`}
              rules={{
                required: {
                  value:  getValues(`childrenDetails.${i}.isAfterUni`) ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                },
              }}
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 10 }}>
                <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="row"
                    wrapHelpTextContainer={{paddingLeft: 15}}
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    stylesInput={{ marginBottom: 0 }}
                    disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && getValues(`childrenDetails.${i}.isAfterUni`) === true && errors?.childrenDetails[i]?.afterUniYears &&(
                    renderErrorMessageStyle(errors?.childrenDetails[i]?.afterUniYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.afterUniYears`}
              rules={{
                required: {
                  value:  getValues(`childrenDetails.${i}.isAfterUni`) ? true : false,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                },
              }}
            />
          </View>
        </View>
        </View>
      )
    }

    if (isTabletSmallDevice) {
      return (
        <View style={mobileStyles.boxContent}>
        <View style={mobileStyles.boxEduChild} key={i}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View>
                <InputData
                  title={`Con thứ ${switchChild(i)}`}
                  helpText="Tuổi"
allowNagitive
                  type="column"
                  // stylesInput={{ marginBottom: 0 }}
                  textUnderline
                  titleStyle={{marginBottom: 10}}
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    checkDataAppend()
                  }}
                />
                {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && (
                 renderErrorMessage(errors?.childrenDetails[i]?.childAge?.message)
              )}
              </View>
            )}
            name={`childrenDetails.${i}.childAge`}
             rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
          />
          <Text style={mobileStyles.boxEduText}>
            Bạn muốn lập kế hoạch tích lũy cho giáo dục của con ở các bậc học nào?{' '}
          </Text>
          <View style={[globalStyles.DisplayFlexRow, desktopStyles.tooltips]}>
          <Text style={mobileStyles.textInputLabelFooter}>Bao gồm cấp 1, cấp 2, cấp 3. </Text>
        </View>
          <View style={mobileStyles.boxEduChild}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 5 }}>
                  <Checkbox
                    checked={value}
                    containerStyle={{ marginBottom: 15 }}
                    title={'Phổ thông'}
                    onChange={  (value) => {
                       onChange(value)
                       clearErrors(`childrenDetails.${i}.schoolFees`)
                       clearErrors(`childrenDetails.${i}.schoolYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.schoolFees`, 0)
                        setValue(`childrenDetails.${i}.schoolYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isSchool`}
               rules={{}}
 
            />
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%'}}>
                  <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    wrapHelpTextContainer={{width: '100%',maxWidth: 281}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    maxWidthInput={'100%'}
                    titleStyle={{marginBottom: 10}}
                    // stylesInput={{ marginBottom: 0 }}
                    value={value !== 0 ? value : ''}
                    disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                       {errors?.childrenDetails && errors?.childrenDetails[i]?.schoolFees && getValues(`childrenDetails.${i}.isSchool`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.schoolFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.schoolFees`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%' }}>
                  <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="column"
                    disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                    placeholder="Nhập"
                    wrapHelpTextContainer={{width: '100%',maxWidth: 281}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    maxWidthInput={'100%'}
                    titleStyle={{marginBottom: 10}}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                   {errors?.childrenDetails && errors?.childrenDetails[i]?.schoolYears && getValues(`childrenDetails.${i}.isSchool`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.schoolYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.schoolYears`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
            />
          </View>

          <View style={mobileStyles.boxEduChild}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 5 }}>
                  <Checkbox
                    checked={value}
                    title={'Đại học'}
                    containerStyle={{ marginBottom: 15 }}
                    onChange={  (value) => {
                       onChange(value)
                       clearErrors(`childrenDetails.${i}.universityFees`)
                       clearErrors(`childrenDetails.${i}.universityYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.universityFees`, 0)
                        setValue(`childrenDetails.${i}.universityYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isUniversity`}
               rules={{ }}
 
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%' }}>
                  <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    wrapHelpTextContainer={{width: '100%',maxWidth: 281}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    maxWidthInput={'100%'}
                    titleStyle={{marginBottom: 10}}
                    disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
               {errors?.childrenDetails && errors?.childrenDetails[i]?.universityFees && getValues(`childrenDetails.${i}.isUniversity`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.universityFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.universityFees`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%' }}>
                  <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="column"
                    placeholder="Nhập"
                    disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    wrapHelpTextContainer={{width: '100%',maxWidth: 281}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    maxWidthInput={'100%'}
                    titleStyle={{marginBottom: 10}}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.universityYears && getValues(`childrenDetails.${i}.isUniversity`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.universityYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.universityYears`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
            />
          </View>
          <View style={mobileStyles.boxEduChild}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%', marginBottom: 5 }}>
                  <Checkbox
                    checked={value}
                    title={'Sau đại học'}
                    containerStyle={{ marginBottom: 15 }}
                    onChange={  (value) => {
                       onChange(value)
                      clearErrors(`childrenDetails.${i}.afterUniFees`)
                      clearErrors(`childrenDetails.${i}.afterUniYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.afterUniFees`, 0)
                        setValue(`childrenDetails.${i}.afterUniYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isAfterUni`}
               rules={{}}
 
            />
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%' }}>
                  <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="column"
                    placeholder="Nhập"
                    wrapHelpTextContainer={{width: '100%',maxWidth: 281}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    maxWidthInput={'100%'}
                    titleStyle={{marginBottom: 10}}
                    disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                    {errors?.childrenDetails && errors?.childrenDetails[i]?.afterUniFees && getValues(`childrenDetails.${i}.isAfterUni`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.afterUniFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.afterUniFees`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ width: '100%'}}>
                  <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="column"
                    placeholder="Nhập"
                    wrapHelpTextContainer={{width: '100%',maxWidth: 281}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    maxWidthInput={'100%'}
                    titleStyle={{marginBottom: 10}}
                    value={value !== 0 ? value : ''}
                    disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                   {errors?.childrenDetails && errors?.childrenDetails[i]?.afterUniYears && getValues(`childrenDetails.${i}.isAfterUni`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.afterUniYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.afterUniYears`}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
            />
          </View>
        </View>
        </View>
      )
    }

    if (isLaptopDevice) {
      return (
        <View style={desktopStyles.boxEdu} key={i}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <View>
                <InputData
                  title={`Con thứ ${switchChild(i)}`}
                  helpText="Tuổi"
allowNagitive
                  type="row"
                  stylesInput={{ marginRight: 30 }}
                  textUnderline
                  placeholder="Nhập"
                  value={value !== 0 ? value : ''}
                  onBlur={onBlur}
                  onChangeText={(value) => {
                    onChange(value)
                    checkDataAppend()
                  }}
                />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && (
              renderErrorMessage(errors?.childrenDetails[i]?.childAge?.message)
              )}
              </View>
            )}
            name={`childrenDetails.${i}.childAge`}
             rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
          />
  
          <Text style={desktopStyles.boxEduTextTablet}>
            Bạn muốn lập kế hoạch tích lũy cho giáo dục của con ở các bậc học nào?{' '}
          </Text>
          <View style={[globalStyles.DisplayFlexRow, desktopStyles.tooltips]}>
            <Text style={desktopStyles.textInputLabelFooter}>Bao gồm cấp 1, cấp 2, cấp 3. </Text>
          </View>
          <View style={desktopStyles.boxEduChild}>
            <View style={{ width: '20%',paddingTop: 3 }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <Checkbox
                      checked={value}
                      title={'Phổ thông'}
                      onChange={ (value) => {
                         onChange(value)
                         clearErrors(`childrenDetails.${i}.schoolFees`)
                         clearErrors(`childrenDetails.${i}.schoolYears`)
                        if (value === false) {
                          setValue(`childrenDetails.${i}.schoolFees`, 0)
                          setValue(`childrenDetails.${i}.schoolYears`, 0)
                                              }
                        checkDataAppend()
                      }}
                    />
                  </View>
                )}
                name={`childrenDetails.${i}.isSchool`}
                 rules={{}}
 
              />
            </View>
            <View style={{ width: '40%' }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <InputData
                      title="Số tiền/năm"
                      helpText="Triệu đồng"
                      type="row"
                      stylesInput={{ marginRight: 15, width: 'auto',marginBottom: 10 }}
                      placeholder="Nhập"
                      widthInput={'calc(100% - 80px)'}
                      titleStyle={{minWidth:80}}
                      helpTextWrap={{minWidth:80,width:80,paddingHorizontal: 5}}
                      maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                      value={value !== 0 ? value : ''}
                      disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        countTotal()
                      }}
                    />
                   {errors?.childrenDetails && errors?.childrenDetails[i]?.schoolFees && getValues(`childrenDetails.${i}.isSchool`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.schoolFees?.message)
                  )}
                  </View>
                )}
                name={`childrenDetails.${i}.schoolFees`}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
              />
            </View>
            <View style={{ width: '40%' }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <InputData
                      title="Số năm học"
                      helpText="Năm"
allowNagitive
                      type="row"
                      disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                      placeholder="Nhập"
                      titleStyle={{minWidth:80}}
                      stylesInput={{  width: 'auto',marginBottom: 10 }}
                      helpTextWrap={{minWidth:80,width:80,paddingHorizontal: 5}}
                      widthInput={'calc(100% - 80px)'}
                      maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                      value={value !== 0 ? value : ''}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        countTotal()
                      }}
                    />
                    {errors?.childrenDetails && errors?.childrenDetails[i]?.schoolYears && getValues(`childrenDetails.${i}.isSchool`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.schoolYears?.message)
                  )}
                  </View>
                )}
                name={`childrenDetails.${i}.schoolYears`}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
              />
            </View>
          </View>
  
          <View style={desktopStyles.boxEduChild}>
          <View style={{ width: '20%',paddingTop: 3 }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <Checkbox
                      checked={value}
                      title={'Đại học'}
                      onChange={ (value) => {
                         onChange(value)
                         clearErrors(`childrenDetails.${i}.universityFees`)
                         clearErrors(`childrenDetails.${i}.universityYears`)
                        if (value === false) {
                          setValue(`childrenDetails.${i}.universityFees`, 0)
                          setValue(`childrenDetails.${i}.universityYears`, 0)
                        }
                        checkDataAppend()
                      }}
                    />
                  </View>
                )}
                name={`childrenDetails.${i}.isUniversity`}
                 rules={{ }}
 
              />
            </View>
            <View style={{ width: '40%' }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <InputData
                      title="Số tiền/năm"
                      helpText="Triệu đồng"
                      type="row"
                      stylesInput={{ marginRight: 15, width: 'auto',marginBottom: 10 }}
                      placeholder="Nhập"
                      disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                      value={value !== 0 ? value : ''}
                      onBlur={onBlur}
                      titleStyle={{minWidth:80}}
                      helpTextWrap={{minWidth:80,width:80,paddingHorizontal: 5}}
                      widthInput={'calc(100% - 80px)'}
                      maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                      onChangeText={(value) => {
                        onChange(value)
                        countTotal()
                      }}
                    />
                    {errors?.childrenDetails && errors?.childrenDetails[i]?.universityFees && getValues(`childrenDetails.${i}.isUniversity`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.universityFees?.message)
                  )}
                  </View>
                )}
                name={`childrenDetails.${i}.universityFees`}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
              />
            </View>
            <View style={{ width: '40%' }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <InputData
                      title="Số năm học"
                      helpText="Năm"
allowNagitive
                      type="row"
                      placeholder="Nhập"
                      titleStyle={{minWidth:80}}
                      helpTextWrap={{minWidth:80,width:80,paddingHorizontal: 5}}
                      stylesInput={{ width: 'auto',marginBottom: 10 }}
                      widthInput={'calc(100% - 80px)'}
                      maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                      disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                      value={value !== 0 ? value : ''}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        countTotal()
                      }}
                    />
                   {errors?.childrenDetails && errors?.childrenDetails[i]?.universityYears && getValues(`childrenDetails.${i}.isUniversity`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.universityYears?.message)
                  )}
                  </View>
                )}
                name={`childrenDetails.${i}.universityYears`}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
              />
            </View>
          </View>
          <View style={desktopStyles.boxEduChild}>
          <View style={{ width: '20%',paddingTop: 3 }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <Checkbox
                      checked={value}
                      title={'Sau đại học'}
                      onChange={  (value) => {
                         onChange(value)
                         clearErrors(`childrenDetails.${i}.afterUniFees`)
                         clearErrors(`childrenDetails.${i}.afterUniYears`)
                        if (value === false) {
                          setValue(`childrenDetails.${i}.afterUniFees`, 0)
                          setValue(`childrenDetails.${i}.afterUniYears`, 0)
                        }
                        checkDataAppend()
                      }}
                    />
                  </View>
                )}
                name={`childrenDetails.${i}.isAfterUni`}
                 rules={{}}
 
              />
            </View>
            <View style={{ width: '40%' }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <InputData
                      title="Số tiền/năm"
                      helpText="Triệu đồng"
                      type="row"
                      stylesInput={{ marginRight: 15, width: 'auto',marginBottom: 10 }}
                      placeholder="Nhập"
                      titleStyle={{minWidth:80}}
                      helpTextWrap={{minWidth:80,width:80,paddingHorizontal: 5}}
                      widthInput={'calc(100% - 80px)'}
                      maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                      disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                      value={value !== 0 ? value : ''}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        countTotal()
                      }}
                    />
                       {errors?.childrenDetails && errors?.childrenDetails[i]?.afterUniFees && getValues(`childrenDetails.${i}.isAfterUni`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.afterUniFees?.message)
                  )}
                  </View>
                )}
                name={`childrenDetails.${i}.afterUniFees`}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
              />
            </View>
            <View style={{ width: '40%' }}>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <InputData
                      title="Số năm học"
                      helpText="Năm"
allowNagitive
                      type="row"
                      widthInput={'calc(100% - 80px)'}
                      titleStyle={{minWidth:80}}
                      stylesInput={{ width: 'auto',marginBottom: 10 }}
                      helpTextWrap={{minWidth:80,width:80,paddingHorizontal: 5}}
                      maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                      placeholder="Nhập"
                      value={value !== 0 ? value : ''}
                      disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        countTotal()
                      }}
                    />
                      {errors?.childrenDetails && errors?.childrenDetails[i]?.afterUniYears && getValues(`childrenDetails.${i}.isAfterUni`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.afterUniYears?.message)
                  )}
                  </View>
                )}
                name={`childrenDetails.${i}.afterUniYears`}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
              />
            </View>
          </View>
        </View>
      )
    }

    return (
      <View style={desktopStyles.boxEdu} key={i}>
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => (
            <View>
              <InputData
                title={`Con thứ ${switchChild(i)}`}
                helpText="Tuổi"
allowNagitive
                type="row"
                stylesInput={{ marginRight: 30,marginBottom: 10 }}
                textUnderline
                placeholder="Nhập"
                value={value !== 0 ? value : ''}
                onBlur={onBlur}
                onChangeText={(value) => {
                  onChange(value)
                  checkDataAppend()
                }}
              />
              {errors?.childrenDetails && errors?.childrenDetails[i]?.childAge && (
                renderErrorMessage(errors?.childrenDetails[i]?.childAge?.message)
              )}
            </View>
          )}
          name={`childrenDetails.${i}.childAge`}
           rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
        />

        <Text style={desktopStyles.boxEduText}>
          Bạn muốn lập kế hoạch tích lũy cho giáo dục của con ở các bậc học nào?{' '}
        </Text>
        <View style={[globalStyles.DisplayFlexRow, desktopStyles.tooltips]}>
          <Text style={desktopStyles.textInputLabelFooter}>Bao gồm cấp 1, cấp 2, cấp 3. </Text>
        </View>
        <View style={desktopStyles.boxEduChild}>
          <View style={{ width: '20%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <Checkbox
                    checked={value}
                    title={
                      <View style={globalStyles.DisplayFlexRow}>
                        <Text style={desktopStyles.textInputLabel}>Phổ thông</Text>
                      </View>
                    }
                    onChange={  (value) => {
                      onChange(value)
                      clearErrors(`childrenDetails.${i}.schoolFees`)
                      clearErrors(`childrenDetails.${i}.schoolYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.schoolFees`, 0)
                        setValue(`childrenDetails.${i}.schoolYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isSchool`}
               rules={{}}
               
            />

          </View>
          <View style={{ width: '40%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="row"
                    stylesInput={{ marginRight: 30, width: 'auto' }}
                    placeholder="Nhập"
                    widthInput={'calc(100% - 95px)'}
                    helpTextWrap={{minWidth:96}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    value={value !== 0 ? value : ''}
                    disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.schoolFees && getValues(`childrenDetails.${i}.isSchool`) === true &&  (
                     renderErrorMessage(errors?.childrenDetails[i]?.schoolFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.schoolFees`}
               rules={{
                    required: {
                      value: getValues(`childrenDetails.${i}.isSchool`) === true ? true : false,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
            />
          </View>
          <View style={{ width: '40%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="row"
                    disabled={getValues(`childrenDetails.${i}.isSchool`) === true ? false : true}
                    placeholder="Nhập"
                    widthInput={'calc(100% - 95px)'}
                    helpTextWrap={{minWidth:96}}
                    stylesInput={{ width: 'auto'}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.schoolYears  && getValues(`childrenDetails.${i}.isSchool`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.schoolYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.schoolYears`}
               rules={{
                    required: {
                      value:  getValues(`childrenDetails.${i}.isSchool`) ? true : false,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
            />
          </View>
        </View>

        <View style={desktopStyles.boxEduChild}>
          <View style={{ width: '20%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <Checkbox
                    checked={value}
                    title={'Đại học'}
                    onChange={  (value) => {
                       onChange(value)
                       clearErrors(`childrenDetails.${i}.universityFees`)
                       clearErrors(`childrenDetails.${i}.universityYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.universityFees`, 0)
                        setValue(`childrenDetails.${i}.universityYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isUniversity`}
               rules={{ }}
 
            />
          </View>
          <View style={{ width: '40%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="row"
                    stylesInput={{ marginRight: 30, width: 'auto' }}
                    placeholder="Nhập"
                    disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    widthInput={'calc(100% - 95px)'}
                    helpTextWrap={{minWidth:96}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.universityFees && getValues(`childrenDetails.${i}.isUniversity`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.universityFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.universityFees`}
               rules={{
                    required: {
                      value:  getValues(`childrenDetails.${i}.isUniversity`) ? true : false,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
            />
          </View>
          <View style={{ width: '40%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="row"
                    placeholder="Nhập"
                    widthInput={'calc(100% - 95px)'}
                    helpTextWrap={{minWidth:96}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    disabled={getValues(`childrenDetails.${i}.isUniversity`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.universityYears && getValues(`childrenDetails.${i}.isUniversity`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.universityYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.universityYears`}
               rules={{
                    required: {
                      value: getValues(`childrenDetails.${i}.isUniversity`) ? true : false,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
            />
          </View>
        </View>
        <View style={desktopStyles.boxEduChild}>
          <View style={{ width: '20%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <Checkbox
                    checked={value}
                    title={'Sau đại học'}
                    onChange={  (value) => {
                      onChange(value)
                      clearErrors(`childrenDetails.${i}.afterUniFees`)
                      clearErrors(`childrenDetails.${i}.afterUniYears`)
                      if (value === false) {
                        setValue(`childrenDetails.${i}.afterUniFees`, 0)
                        setValue(`childrenDetails.${i}.afterUniYears`, 0)
                      }
                      checkDataAppend()
                    }}
                  />
                </View>
              )}
              name={`childrenDetails.${i}.isAfterUni`}
               rules={{}}
 
            />
          </View>
          <View style={{ width: '40%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <InputData
                    title="Số tiền/năm"
                    helpText="Triệu đồng"
                    type="row"
                    stylesInput={{ marginRight: 30, width: 'auto' }}
                    placeholder="Nhập"
                    widthInput={'calc(100% - 95px)'}
                    helpTextWrap={{minWidth:96}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                    value={value !== 0 ? value : ''}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.afterUniFees && getValues(`childrenDetails.${i}.isAfterUni`) === true && (
                   renderErrorMessage(errors?.childrenDetails[i]?.afterUniFees?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.afterUniFees`}
               rules={{
                    required: {
                      value:  getValues(`childrenDetails.${i}.isAfterUni`) ? true : false,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                    },
                  }}
 
            />
          </View>
          <View style={{ width: '40%' }}>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <InputData
                    title="Số năm học"
                    helpText="Năm"
allowNagitive
                    type="row"
                    widthInput={'calc(100% - 95px)'}
                    helpTextWrap={{minWidth:96}}
                    maxSectionStyle={{ maxWidth: '100%', width: '100%' }}
                    placeholder="Nhập"
                    value={value !== 0 ? value : ''}
                    disabled={getValues(`childrenDetails.${i}.isAfterUni`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      countTotal()
                    }}
                  />
                  {errors?.childrenDetails && errors?.childrenDetails[i]?.afterUniYears && getValues(`childrenDetails.${i}.isAfterUni`) === true && (
                    renderErrorMessage(errors?.childrenDetails[i]?.afterUniYears?.message)
                  )}
                </View>
              )}
              name={`childrenDetails.${i}.afterUniYears`}
               rules={{
                    required: {
                      value: getValues(`childrenDetails.${i}.isAfterUni`) ? true : false,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                    },
                  }}
 
            />
          </View>
        </View>
      </View>
    )
  }

  function switchChild(i: number) {
    switch (i) {
      case 0:
        return 'nhất'
      case 1:
        return 'hai'
      case 2:
        return 'ba'
      case 3:
        return 'bốn'
      case 4:
        return 'năm'
      case 5:
        return 'sáu'
      case 6:
        return 'bảy'
      case 7:
        return 'tám'
      case 8:
        return 'chín'
      case 9:
        return 'mười'
      default:
        return 'nhất'
    }
  }

  function renderEduPlanDialog() {
    return(
    <Dialog
    message='Thông tin kế hoạch'
    type="inform"
    title={
      <View>
      <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
        <Image
         source={icDocument as ImageSourcePropType}
         style={globalStyles.iconDesktop}
       />
          <Text style={[globalStyles.DialogTitle]}>Chi phí tham khảo</Text>
       </View>
       <SizedBox height={20} />
       </View>
     }
     children={
      <Table
        dataSource={dataSourceCustomer}
        columns={columnsCustomer}
        columnWidth={200}
        headerContainerStyle={{width: '100%',paddingRight: 20}}
        type={'edu'}
        titleColumn={{ fontWeight: 'bold' }}
      />
    }
    dialogStyle={{width: '100%', paddingHorizontal: isMobileDevice? 15 : 20, paddingTop: 0 }}
    showCloseIcon
    iconHeader={true}
    onClose={() => setShowPopup(false)}
    visible={showPopup}
    />
    )
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Kế hoạch tích lũy cho giáo dục của con"
          tooltips={<Tooltips title="Xem chi phí tham khảo" onPress={() => setShowPopup(true)} image={true}
           titleWrapper={{marginTop: 0,justifyContent: 'flex-end', marginBottom: 20}} />}
          borderColor="#27AE60"
          child={
            <View>
              <Controller
                control={control}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
                render={({ onChange, value }) => (
                  <>
                    <Quantity
                      title="Gia đình bạn có bao nhiêu con?"
                      min={1}
                      start={value}
                      value={value}
                      onChange={(value) => {
                        if (value < getValues('children')) {
                          onChange(value)
                          setValue('childrenDetails', getValues('childrenDetails').slice(0, value))
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length + 1}`])
                          checkDataAppend()
                        } else {
                          onChange(value)
                          setValue('childrenDetails', [...getValues('childrenDetails'), dataAppend])
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length - 1}`])
                          checkDataAppend()
                        }
                      }}
                      max={10}
                    />
                    {getAppendComponent(value)}
                  </>
                )}
                name="children"
              />
              <Controller
                control={control}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
                render={({ value }) => (
                  <BoxFooter
                    title="Tổng số tiền bạn cần tích lũy để đảm bảo cho kế hoạch giáo dục cho các con:"
                    backgroundColorBox="#DFF3E7"
                    type="column"
                    titleStyle={{ color: '#27AE60' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
        {renderEduPlanDialog()}
      </View>
    )
  }

  if (isLaptopDevice) {
    return (
      <View>
        <BlockAlysys
          title="Kế hoạch tích lũy cho giáo dục của con"
          borderColor="#27AE60"
          tooltips={<Tooltips title="Xem chi phí tham khảo" onPress={() => setShowPopup(true)} image={true}  />}
          child={
            <View>
              <Controller
                control={control}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
                render={({ onChange, value }) => (
                  <>
                    <Quantity
                      title="Gia đình bạn có bao nhiêu con?"
                      min={1}
                      start={value}
                      value={value}
                      onChange={(value) => {
                        if (value < getValues('children')) {
                          onChange(value)
                          setValue('childrenDetails', getValues('childrenDetails').slice(0, value))
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length + 1}`])
                          checkDataAppend()
                        } else {
                          onChange(value)
                          setValue('childrenDetails', [...getValues('childrenDetails'), dataAppend])
                          clearErrors([`childrenDetails.${getValues('childrenDetails').length - 1}`])
                          checkDataAppend()
                        }
                      }}
                      max={10}
                    />
                    {getAppendComponent(value)}
                  </>
                )}
                name="children"
              />
              <Controller
                control={control}
                 rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
 
                render={({ value }) => (
                  <BoxFooter
                    title="Tổng số tiền bạn cần tích lũy để đảm bảo cho kế hoạch giáo dục cho các con:"
                    backgroundColorBox="#DFF3E7"
                    type="column"
                    titleStyle={{ color: '#27AE60' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name="subTotal"
              />
            </View>
          }
        />
        {renderEduPlanDialog()}
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Kế hoạch tích lũy cho giáo dục của con"
        borderColor="#27AE60"
        tooltips={<Tooltips title="Xem chi phí tham khảo" onPress={() => setShowPopup(true)} image={true}  />}
        child={
          <View>
            <Controller
              control={control}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
              render={({ onChange, value }) => (
                <>
             
                  <Quantity
                    title="Gia đình bạn có bao nhiêu con?"
                    min={1}
                    start={value || 1}
                    value={value}
                    onChange={(value) => {
                      if (value < getValues('children')) {
                        onChange(value)
                        setValue('childrenDetails', getValues('childrenDetails').slice(0, value))
                        clearErrors([`childrenDetails.${getValues('childrenDetails').length + 1}`])
                        checkDataAppend()
                      } else {
                        onChange(value)
                        setValue('childrenDetails', [...getValues('childrenDetails'), dataAppend])
                        clearErrors([`childrenDetails.${getValues('childrenDetails').length - 1}`])
                        checkDataAppend()
                      }
                    }}
                    max={10}
                  />
                  {getAppendComponent(userProtect?.eduPlan?.children ? userProtect?.eduPlan?.children : 1)}
                </>
              )}
              name="children"
            />
            <Controller
              control={control}
               rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED_DATA'),
                    },
                    min: {
                      value: 0.001,
                      message: i18n.t('VALIDATE.REQUIRED_DATA_AGE'),
                    },
                  }}
              
              render={({ value }) => (
                <BoxFooter
                  title="Tổng số tiền bạn cần tích lũy để đảm bảo cho kế hoạch giáo dục cho các con:"
                  backgroundColorBox="#DFF3E7"
                  titleStyle={{ color: '#27AE60' }}
                  child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                />
              )}
              name="subTotal"
            />
          </View>
        }
      />
      <SizedBox width={24} />
      {renderEduPlanDialog()}
    </View>
  )
}
export default connector(SaveEduScreen)

const desktopStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    height: '100%',
  },
  heading: {
    marginBottom: 20
  },
  helpAnalysis: {
    flexDirection: 'row',
  },
  individualDataContainer: {
    backgroundColor: '#fff',
    marginRight: 25,
    // overflowY: 'scroll',
    maxHeight: 700,
  },
  boxEdu: {
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 30,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#D7DFFF',
  },
  boxEduChild: {
    flexDirection: 'row',
    maxWidth: 940,
    marginBottom: 10
  },
  textInputLabel: {
    fontSize: 16,
    fontWeight: '500',
    paddingLeft: 10,
    color: '#505D6F',
  },
  textInputLabelFooter: {
    fontSize: 12,
    fontWeight: '500',
    fontStyle: 'italic',
    color: '#505D6F',
  },
  redLabel: {
    fontSize: 16,
    fontWeight: '700',
    color: 'red',
  },
  boxEduText: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 15,
    fontSize: 16,
    maxWidth: 940,
  },
  boxEduTextTablet: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 15,
    fontSize: 13.2667,
  },
  tooltips: {
    marginBottom: 10,
  },
  footerBox: {
    marginVertical: 20,
    position: 'absolute',
    top: 26,
  },
})

const mobileStyles = StyleSheet.create({
  boxContent: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    marginBottom: 15
  },
  boxEduText: {
    fontWeight: '600',
    color: '#373737',
    fontSize: 13,
  },
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  Tooltips:{
    marginBottom: 0
  },
  textInputLabelFooter: {
    marginTop: 10,
    fontSize: 10,
    fontWeight: '500',
    fontStyle: 'italic',
    color: '#505D6F',
  },
  boxEduChild: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  textInputLabel: {
    fontSize: 13,
    fontWeight: '500',
    paddingLeft: 10,
    color: '#2B2D42',
  },
  redLabel: {
    fontSize: 13,
    fontWeight: '700',
    paddingLeft: 10,
    color: 'red',
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    width: '100%',
    flex: 1,
    // overflowY: 'scroll',
    paddingBottom: 100,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
