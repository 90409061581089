import Layout from '../../constants/Layout'
import React, { PureComponent } from 'react'
import { Animated, ScrollView, View, StyleSheet, Platform, PanResponder } from 'react-native'

type IProps = {
  height?: number | string | undefined
  widthCustom?: number | string | undefined
  style?: Record<string, unknown>
  containerStyle?: Record<string, unknown>
  footerComponent?: JSX.Element
  onReachEnd?: () => void
}

export class CustomScrollView extends PureComponent<IProps> {
  constructor(props: any) {
    super(props)
  }
  scrollRef = React.createRef() as any
  delta = new Animated.Value(0)
  pan = new Animated.ValueXY({ x: 0, y: 0 })
  panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderGrant: () => {
      this.pan.setOffset({
        x: (this.pan.x as any)._value,
        y: (this.pan.y as any)._value,
      })
    },
    onPanResponderMove: (evt, gestureState) => {
      let dy = gestureState.dy
      let y = dy * (this.state.visibleHeight / this.state.wholeHeight) * 1.5
      this.scrollRef.current?.scrollBy(0, y)
      this.delta.setValue(dy)
    },
    onPanResponderRelease: () => {
      this.delta.setValue(0)
      this.pan.flattenOffset()
    },
  })

  hideScrollBarTimeOut: any
  state = {
    indicator: new Animated.Value(0),
    wholeHeight: 1,
    visibleHeight: 0,
    opacityAnim: new Animated.Value(0),
    hoveringScrollBar: false,
    scrollY: 0,
  }

  showScrollBar = () => {
    Animated.timing(this.state.opacityAnim, {
      toValue: 1,
      duration: 10,
      useNativeDriver: false,
    } as Animated.TimingAnimationConfig).start(() => {
      this.timeOutHideScrollBar()
    })
  }

  hideScrollBar = () => {
    Animated.timing(this.state.opacityAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    } as Animated.TimingAnimationConfig).start()
  }

  timeOutHideScrollBar = () => {
    this.hideScrollBarTimeOut = setTimeout(() => {
      this.hideScrollBar()
    }, 1500)
  }

  isCloseToBottom = (nativeEvent: any) => {
    const paddingToBottom = 20
    return (
      nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >=
      nativeEvent.contentSize.height - paddingToBottom
    )
  }

  onHoveredScrollBar = () => {
    setTimeout(() => {
      this.setState({ hoveringScrollBar: !this.state.hoveringScrollBar })
    }, 1000)
  }

  render() {
    const indicatorSize =
      this.state.wholeHeight > this.state.visibleHeight
        ? (this.state.visibleHeight * this.state.visibleHeight) / this.state.wholeHeight
        : this.state.visibleHeight

    const difference = this.state.visibleHeight > indicatorSize ? this.state.visibleHeight - indicatorSize : 1
    return (
      <View
        style={[
          {
            maxHeight: 600,
            flexDirection: 'row',
            width: this.props.widthCustom ? this.props.widthCustom : '100%',
            height: this.props.height ? this.props.height : '100%',
            padding: Platform.OS === 'web' && Layout.isMobileDevice ? 0 : 16,
          },
          this.props.containerStyle,
        ]}
      >
        <ScrollView
          ref={this.scrollRef}
          showsVerticalScrollIndicator={false}
          onContentSizeChange={(width, height) => {
            this.setState({ wholeHeight: height })
          }}
          contentContainerStyle={[this.props.style]}
          onLayout={({
            nativeEvent: {
              layout: { x, y, width, height },
            },
          }) => this.setState({ visibleHeight: height })}
          scrollEventThrottle={16}
          onScroll={(e) => {
            clearTimeout(this.hideScrollBarTimeOut)
            this.showScrollBar()
            Animated.event([{ nativeEvent: { contentOffset: { y: this.state.indicator } } }], {
              useNativeDriver: false,
            })(e)
            if (this.isCloseToBottom(e.nativeEvent)) {
              this.props.onReachEnd && this.props.onReachEnd()
            }
          }}
        >
          {this.props.children && this.props.children}
          {this.props.footerComponent && this.props.footerComponent}
        </ScrollView>
        <View
          style={styles.indicatorWrapper}
          onMouseEnter={this.onHoveredScrollBar}
          onMouseLeave={this.onHoveredScrollBar}
        >
          <Animated.View
            style={[
              styles.indicator,
              {
                height: indicatorSize,
                transform: [
                  {
                    translateY: Animated.multiply(
                      this.state.indicator,
                      this.state.visibleHeight / this.state.wholeHeight
                    ).interpolate({
                      inputRange: [0, difference],
                      outputRange: [0, difference],
                      extrapolate: 'clamp',
                    }),
                  },
                ],
                opacity: this.state.hoveringScrollBar ? 1 : this.state.opacityAnim,
              },
            ]}
            {...this.panResponder.panHandlers}
          />
        </View>
      </View>
    )
  }
}

export default CustomScrollView

const styles = StyleSheet.create({
  indicatorWrapper: {
    width: 6,
    height: '100%',
    paddingBottom: 20,
    overflow: 'hidden',
    borderRadius: 10,
  },
  indicator: {
    width: 6,
    backgroundColor: '#C4C4C4',
    borderRadius: 10,
  },
})
