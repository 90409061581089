import React from 'react'
import {
  View,
  StyleSheet,
  ImageBackground,
  ImageSourcePropType,
  Text,
  Image,
  SafeAreaView,
  Linking,
} from 'react-native'
import { useMediaQuery } from 'react-responsive'
import { laptopMediaConfig, mobileMediaConfig } from '../constants/Constants'
import { RootStackScreenProps } from '../types'
import imgJustBg from '../assets/images/just_bg.png'
import imgDecorTag from '../assets/images/decor_tag.png'
import imgBoxes from '../assets/images/boxes.png'
import { NextButton, OutlinedButton, AppText, Spacer, tabletMediaConfig, styles as globalStyles } from '../common'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import AppHeader from '../components/AppHeader'
import { clearCart } from '../slices/needsCartSlice'
import { createNewLocalCNA } from '../slices/cnaSlice'
import { createUserProtect, createUserProtectOriginal } from '../slices/userSlice'
import i18n from 'i18n-js'
import Layout from '../constants/Layout'

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
})

const mapDispatchToProps = {
  clearCart,
  createNewLocalCNA,
  createUserProtect,
  createUserProtectOriginal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'AfterLogIn'> & PropsFromRedux & {}

function LogInSuccessScreen({
  navigation,
  userInfo,
  clearCart,
  createNewLocalCNA,
  createUserProtect,
  createUserProtectOriginal,
}: Props) {
  const isTabletDevice = Layout.isTabletDevice
  const isMobileDevice = Layout.isMobileDevice

  function onCreateNewCNA() {
    clearCart({})
    createNewLocalCNA({})
    createUserProtect({})
    createUserProtectOriginal({})
    navigation.push('NeedsCart')
  }

  function onViewCNAList() {
    createUserProtect({})
    createUserProtectOriginal({})
    navigation.push('CNAList')
  }
  React.useEffect(() => {
    if (isMobileDevice) {
      navigation.replace('MobileHome')
    }
  }, [])

  if (isMobileDevice) {
    return (
      <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
        <AppHeader navigation={navigation} />
        <Image source={imgDecorTag} style={mobileStyles.decorTag} />
        <Image
          source={imgBoxes}
          style={[mobileStyles.boxes, { width: Layout.window.height * 0.9, height: Layout.window.height * 0.9 }]}
        />
        <ImageBackground
          source={imgJustBg as ImageSourcePropType}
          style={[mobileStyles.bgImg, { flexDirection: 'column', alignContent: 'flex-end' }]}
        >
          <View style={[]}>
            <View style={mobileStyles.content}>
              <View style={[mobileStyles.top]}>
                <View style={mobileStyles.logoContainer}>
                  <Text style={mobileStyles.labelSlogan}>{i18n.t('LOGIN_SUCCESS.SLOGAN')}</Text>
                  <AppText title={i18n.t('LOGIN_SUCCESS.SLOGAN_2')} style={mobileStyles.subTitle} />
                </View>
              </View>
              <View style={[mobileStyles.bottom]}>
                <Spacer />
                <View style={[mobileStyles.buttonContainer]}>
                  <NextButton title={i18n.t('LOGIN_SUCCESS.NEW_CNA')} onPress={onCreateNewCNA} />
                  <OutlinedButton
                    title={i18n.t('LOGIN_SUCCESS.VIEW_CNA_LIST')}
                    loading={false}
                    onPress={onViewCNAList}
                  />
                </View>
                <View style={{ flex: 1 }}></View>
              </View>
            </View>
          </View>
          <View style={mobileStyles.copyRightsContainer}>
            <AppText title="(*) " style={mobileStyles.asterisk} />
            <AppText title={i18n.t('LOGIN_SUCCESS.NOTE')} style={mobileStyles.copyRightsText} />
          </View>
        </ImageBackground>
      </SafeAreaView>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={tabletStyles.container}>
        <AppHeader navigation={navigation} agentCode={userInfo?.agentCode} />
        <ImageBackground
          source={imgJustBg as ImageSourcePropType}
          style={[
            tabletStyles.bgImg,
            { flexDirection: 'column', alignContent: 'flex-end', position: 'relative', bottom: 0 },
          ]}
          imageStyle={{
            resizeMode: 'cover',
            alignSelf: 'flex-end',
          }}
        >
          <Image source={imgDecorTag} style={tabletStyles.decorTag} />
          <Image
            source={imgBoxes}
            style={[tabletStyles.boxes, { width: Layout.window.height * 0.9, height: Layout.window.height * 0.9 }]}
          />
          <View style={tabletStyles.displayEnd}>
            <View style={tabletStyles.content}>
              <View style={[tabletStyles.top]}>
                <View style={tabletStyles.logoContainer}>
                  <Text style={tabletStyles.labelSlogan}>{i18n.t('LOGIN_SUCCESS.SLOGAN')}</Text>
                  <AppText title={i18n.t('LOGIN_SUCCESS.SLOGAN_2')} style={tabletStyles.subTitle} />
                </View>
              </View>
              <View style={[tabletStyles.bottom]}>
                <View style={{ flex: 1 }}></View>
                <View style={[tabletStyles.buttonContainer]}>
                  <NextButton title={i18n.t('LOGIN_SUCCESS.NEW_CNA')} onPress={onCreateNewCNA} />
                  <OutlinedButton
                    title={i18n.t('LOGIN_SUCCESS.VIEW_CNA_LIST')}
                    loading={false}
                    onPress={onViewCNAList}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={tabletStyles.copyRightsContainer}>
            <AppText title="(*) " style={tabletStyles.asterisk} />
            <AppText title={i18n.t('LOGIN_SUCCESS.NOTE')} style={tabletStyles.copyRightsText} />
          </View>
        </ImageBackground>
      </SafeAreaView>
    )
  }

  return (
    <View style={desktopStyles.container}>
      <AppHeader navigation={navigation} agentCode={userInfo?.agentCode} />
      <ImageBackground
        source={imgJustBg as ImageSourcePropType}
        style={[desktopStyles.bgImg, { flexDirection: 'column', alignContent: 'flex-end' }]}
      >
        <Image
          source={imgDecorTag}
          style={[
            desktopStyles.decorTag,
            {
              top: Layout.window.height * 0.3,
              left: Layout.window.height * 0.05,
              width: (240 * Layout.window.height) / 1440,
              height: (400 * Layout.window.height) / 1440,
            },
          ]}
        />
        <Image
          source={imgBoxes}
          style={[desktopStyles.boxes, { width: Layout.window.height * 0.9, height: Layout.window.height * 0.9 }]}
        />
        <View style={desktopStyles.displayEnd}>
          <View style={desktopStyles.content}>
            <View style={[desktopStyles.top]}>
              <View style={desktopStyles.logoContainer}>
                <Text style={desktopStyles.labelSlogan}>{i18n.t('LOGIN_SUCCESS.SLOGAN')}</Text>
                <AppText title={i18n.t('LOGIN_SUCCESS.SLOGAN_2')} style={desktopStyles.subTitle} />
              </View>
            </View>
            <View style={[desktopStyles.bottom]}>
              <View style={{ flex: 1 }}></View>
              <View style={[desktopStyles.buttonContainer]}>
                <NextButton
                  title={i18n.t('LOGIN_SUCCESS.NEW_CNA')}
                  onPress={onCreateNewCNA}
                  titleStyle={desktopStyles.navigatingButtonTitle}
                  buttonStyle={desktopStyles.navigatingButton}
                />
                <OutlinedButton
                  title={i18n.t('LOGIN_SUCCESS.VIEW_CNA_LIST')}
                  loading={false}
                  onPress={onViewCNAList}
                  titleStyle={desktopStyles.navigatingButtonTitle}
                  buttonStyle={desktopStyles.navigatingButton}
                />
              </View>
            </View>
          </View>
        </View>
      </ImageBackground>
      <View style={desktopStyles.copyRightsContainer}>
        <AppText title="(*) " style={desktopStyles.asterisk} />
        <AppText title={i18n.t('LOGIN_SUCCESS.NOTE')} style={desktopStyles.copyRightsText} />
      </View>
    </View>
  )
}

export default connector(LogInSuccessScreen)

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
  },
  displayEnd: {
    flexDirection: 'row',
    alignContent: 'flex-end',
    top: 30,
    justifyContent: 'flex-end',
    zIndex: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  bgImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    flex: 1,
    marginTop: 61,
  },
  decorTag: {
    position: 'absolute',
    width: 120,
    height: 200,
    zIndex: 998,
    left: 50,
    top: 250,
  },
  boxes: {
    position: 'absolute',
    width: 600,
    height: 600,
    zIndex: 2,
    left: 0,
    top: 0,
    resizeMode: 'cover',
  },
  top: {
    padding: 40,
    flexDirection: 'column',
    zIndex: 3,
  },
  bottom: {
    paddingRight: 40,
    flexDirection: 'row',
    zIndex: 3,
  },
  buttonContainer: {
    paddingRight: 40,
    flexDirection: 'column',
  },
  logoContainer: {
    padding: 35,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#fff',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor:
      'radial-gradient(148.54% 148.54% at 20.32% 104.31%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%)',
  },
  labelSlogan: {
    fontSize: 35,
    color: '#ED1B2E',
    fontWeight: '700',
  },
  subTitle: {
    fontSize: 20,
    marginTop: 16,
    color: '#545454',
    fontWeight: '600',
  },
  copyRightsContainer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    flexDirection: 'row',
    zIndex: 999,
  },
  asterisk: {
    color: '#ED1B2E',
  },
  copyRightsText: {
    fontSize: 14,
    color: 'grey',
  },
  navigatingButton: {
    width: 316,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigatingButtonTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
  },
  decorTag: {
    position: 'absolute',
    width: 120,
    height: 200,
    zIndex: 998,
    left: 50,
    top: 250,
  },
  boxes: {
    position: 'absolute',
    width: 600,
    height: 600,
    zIndex: 2,
    left: 0,
    top: 0,
    resizeMode: 'cover',
  },
  displayEnd: {
    flexDirection: 'row',
    alignContent: 'flex-end',
    top: 30,
    justifyContent: 'flex-end',
    zIndex: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  bgImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    flex: 1,
    marginTop: 61,
  },
  top: {
    padding: 40,
    flexDirection: 'column',
    zIndex: 3,
  },
  bottom: {
    paddingRight: 40,
    flexDirection: 'row',
    zIndex: 3,
  },
  buttonContainer: {
    paddingRight: 40,
    flexDirection: 'column',
  },
  logoContainer: {
    padding: 35,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#fff',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor:
      'radial-gradient(148.54% 148.54% at 20.32% 104.31%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%)',
  },
  labelSlogan: {
    fontSize: 35,
    color: '#ED1B2E',
    fontWeight: '700',
  },
  subTitle: {
    fontSize: 20,
    marginTop: 16,
    color: '#545454',
    fontWeight: '600',
  },
  copyRightsContainer: {
    position: 'absolute',
    bottom: 10,
    left: 20,
    flexDirection: 'row',
    zIndex: 3,
  },
  asterisk: {
    color: '#ED1B2E',
  },
  copyRightsText: {
    fontSize: 14,
    color: 'grey',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    height: '100%',
  },
  decorTag: {
    position: 'absolute',
    width: 120,
    height: 200,
    zIndex: 998,
    left: 50,
    top: 250,
  },
  boxes: {
    position: 'absolute',
    width: 600,
    height: 600,
    zIndex: 2,
    left: 0,
    top: 50,
    resizeMode: 'cover',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  bgImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    flex: 1,
    overflow: 'scroll',
  },
  top: {
    padding: 40,
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'row',
  },
  buttonContainer: {
    flexDirection: 'column',
  },
  logoContainer: {
    width: '100%',
    padding: 35,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#fff',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor:
      'radial-gradient(148.54% 148.54% at 20.32% 104.31%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%)',
  },
  labelSlogan: {
    fontSize: 20,
    color: '#ED1B2E',
    fontWeight: '700',
  },
  subTitle: {
    fontSize: 16,
    marginTop: 16,
    color: '#545454',
    fontWeight: '600',
  },
  copyRightsContainer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
    flexDirection: 'row',
  },
  asterisk: {
    color: '#ED1B2E',
  },
  copyRightsText: {
    fontSize: 14,
    color: 'grey',
  },
})
