import Layout from '../../constants/Layout'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

export type BlockAlysysProps = {
  title?: string | JSX.Element
  titleStyle?: Record<string, unknown>
  child?: JSX.Element
  tooltips?: JSX.Element
  borderColor? :string
}

export function BlockAlysys({ title, titleStyle, child,borderColor,tooltips }: BlockAlysysProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container]}>
        {
          title &&  <View style={[mobileStyles.titleContainer, titleStyle]}>
          <Text style={[mobileStyles.title, titleStyle]}>{title}</Text>
        </View>
        }
       

        <View style={[mobileStyles.childContainer,borderColor ? {borderColor: borderColor} : null ]}>
        {tooltips}
      
          {child}</View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
       <View style={[tabletStyles.container]}>
      <View style={[tabletStyles.childContainer,borderColor ? {borderColor: borderColor} : null ]}>
       <View  style={[tabletStyles.titleContainer]} >
         <View style={tabletStyles.titleWrap}>
      <Text style={[tabletStyles.title, titleStyle]}>{title}</Text>
      </View>
      {tooltips}
      </View> 
          {child}</View>
    </View>
    )
  }
  

  return (
    <View style={[desktopStyles.container]}>
      <View style={[desktopStyles.childContainer,borderColor ? {borderColor: borderColor} : null ]}>
       <View  style={[desktopStyles.titleContainer]} >
         <View>
      <Text style={[desktopStyles.title, titleStyle]}>{title}</Text>
      </View>
        {tooltips}
      </View> 
          {child}</View>
    </View>
  )
}

export default BlockAlysys

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    flex:1
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    color: '#2B2D42',
    fontSize: 18,
    textTransform: 'uppercase',
    marginBottom: 40,
    fontWeight: '700',
  },
  childContainer: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    borderRadius: 20,
    borderWidth: 1,
    padding: 30
  },
})


const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    flex:1
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  title: {
    color: '#2B2D42',
    fontSize: 14.925,
    textTransform: 'uppercase',
    fontWeight: '700',
  },
  titleWrap: {
    marginBottom: 30,
  },
  containerColumn: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  childContainer: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    borderRadius: 20,
    borderWidth: 1,
    padding: 20
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginTop: 10,
    paddingBottom: 30,
    position: 'relative'
  },
  titleContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10
  },
  title: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: '700',
    marginLeft: 8,
    width: '100%',
    textTransform: 'uppercase',
  },
  childContainer: {
    backgroundColor: '#FFFFFF',
    // height: '100%',
    minHeight: 400,
    borderRadius: 20,
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 30,
    position: 'relative' 
  },

})
