import {
  styles as globalStyles,
  Block,
  SizedBox,
  StepButton,
  NeedsOverallItem,
  Dialog,
  FilledButton,
  mathHelper,
  vnNumberFormatter,
  OutlinedButton,
} from '../common'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { View, StyleSheet, Text, Image, ImageSourcePropType, FlatList, ScrollView } from 'react-native'
import { connect, ConnectedProps } from 'react-redux'
import { resetCnaData } from '../constants/Data'
import { RootState } from '../store'
import { RootStackScreenProps } from '../types'
import i18n from 'i18n-js'
import icCircleDollar from '../assets/images/circle-dollar.png'
import Layout from '../constants/Layout'
import { updateProfile, clearCart, saveNeedsCart, updateSelectedStatus } from '../slices/needsCartSlice'
import { updateNeedsOverall, savePlanPeriodTime } from '../slices/needsOverallSlice'
import { useIsFocused } from '@react-navigation/native'
import { getCachedCNA, saveCNA } from '../slices/cnaSlice'
import toastService from '../services/toast/ToastService'
import icWarning from '../assets/images/warning.png'
import icEditRed from '../assets/images/edit-red.png'
import icInfoRed from '../assets/images/info-red.png'
import StepAppHeader from '../components/StepAppHeader'
import { NeedsCategory, ViewMode, PageNumberMapping, ProductType } from '../constants/Enum'
import { getStatusToSave, CreateCnaPageStep } from '../helper/CnaStatusWorkFlow'
import MobileStepIndicator from '../components/MobileStepIndicator'
import CommonAppBar from '../components/CommonAppBar'
import { SafeAreaView } from 'react-native-safe-area-context'
import LinearGradientBackground from '../common/components/LinearGradientBackground'
import Colors from '../common/constants/Colors'
import Line from '../common/components/Line'
import { getChartData } from '../slices/solutionSlice'
import dataProvider from 'data/dataProvider'
import { i18nAgentWording } from 'components/AgentWording'

const cardWidth = 200

const mapStateToProps = (state: RootState) => ({
  profileInfo: state.needsCart.profileInfo,
  needsOverallList: state.needsOverall.needsOverallList,
  needsCartList: state.needsCart.needsCartList,
  userProtect: state.user.userProtect,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  userProtectOriginal: state.user.userProtectOriginal,
  userInfo: state?.user?.userInfo,
  loadingCnaDetail: state?.cna?.loadingCnaDetail,
  loadingSaveDraft: state?.cna.loadingSaveDraft,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
})

const mapDispatchToProps = {
  updateNeedsOverall,
  savePlanPeriodTime,
  updateProfile,
  getCachedCNA,
  clearCart,
  saveNeedsCart,
  updateSelectedStatus,
  saveCNA,
  getChartData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'NeedsOverall'> & PropsFromRedux & {}

function NeedsOverallScreen({
  route,
  navigation,
  needsOverallList,
  updateNeedsOverall,
  savePlanPeriodTime,
  needsCartList,
  clearCart,
  userProtect,
  getCachedCNA,
  currentHandlingCNA,
  userInfo,
  saveCNA,
  loadingCnaDetail,
  loadingSaveDraft,
  loadingSaveContinue,
  getChartData,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isFocused = useIsFocused()
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()

  const [showCancelWarningModel, setShowCancelWarningModel] = React.useState(false)
  const [totalValue, setTotalValue] = React.useState(0)
  const [enableContinue, setEnableContinue] = React.useState(false)
  const [showContentPopUpUpdateLate, setShowContentPopUpUpdateLate] = React.useState(false)
  const [apeSub, setApeSub] = React.useState<number>()

  useEffect(() => {
    setShowCancelWarningModel(false)
    const id = (route.params as any)?.id
    if (id && isFocused) {
      getCachedCNA({ id })
      initData()
    } else {
      clearCart({})
    }
  }, [isFocused])

  useEffect(() => {
    setValue('needTerm', currentHandlingCNA?.needTerm ? currentHandlingCNA?.needTerm : undefined)
    setEnableContinue(currentHandlingCNA?.needTerm ? true : false)
  }, [currentHandlingCNA])

  useEffect(() => {
    let data: Array<any> = []
    needsCartList.forEach((e) => {
      if (e.data) {
        e.data.forEach((e) => {
          if (e.isTemporaryCheck) {
            data.push({
              ...e,
              qty: userProtect && userProtect[e.key] ? userProtect[e.key].total : 0,
            })
          }
        })
      }
    })
    updateNeedsOverall([...data])
    setTotalValue(countTotalValue(data))
  }, [needsCartList, userProtect])

  useEffect(() => {
    setTotalValue(countTotalValue(needsOverallList))
    handleShowPopUpUpdateLate()
  }, [needsOverallList])

  function checkEditable() {
    return (route.params as any)?.mode !== ViewMode.READ_ONLY
  }

  function handleShowPopUpUpdateLate() {
    let selectedNeeds = needsOverallList.filter((e) => e.isChecked)
    let selectedCategoryCodes = [...new Set(selectedNeeds.map((e) => e.code))]
    if (
      isFocused &&
      selectedCategoryCodes.length === 1 &&
      selectedCategoryCodes[0] === 'MEDICINE' &&
      !loadingCnaDetail
    ) {
      setShowContentPopUpUpdateLate(true)
    }
  }

  function countTotalValue(needsOverallList: Array<any>) {
    let totalValue = 0
    needsOverallList.forEach((k) => {
      if (k.isChecked) {
        totalValue += k.qty ?? 0
      }
    })
    return totalValue
  }

  function getColumnCount() {
    const ratio = Layout.window.width / 2 / cardWidth
    if (ratio < 2) {
      return 1
    }
    return Math.round(ratio)
  }

  async function onSubmit(data: any) {
    let selectedNeeds = needsOverallList.filter((e) => e.isChecked)
    let selectedCategoryCodes = [...new Set(selectedNeeds.map((e) => e.code))]
    if (selectedCategoryCodes.length === 1 && selectedCategoryCodes[0] === NeedsCategory.MEDICINE) {
      setShowContentPopUpUpdateLate(true)
      return
    }
    savePlanPeriodTime(parseInt(data.needTerm))
    onSaveDraft(data, false, true)?.then((e: any) => {
      if (!e.error) {
        if (currentHandlingCNA?.product === ProductType.ILP) {
          navigation.navigate('Advisor', route.params)
        } else {
          navigation.navigate('Solution', route.params)
        }
      } else {
        toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
      }
    })
  }

  function initData() {
    const id = (route.params as any)?.id
    if (id) {
      Promise.all([
        getChartData({
          id: (route.params as any)?.id,
          withoutTopup: true,
          isSub: false,
        }),
      ])
        .then((data: any) => {
          let quotationDataSub = data[0].payload
          let apeSub =
            quotationDataSub.result && quotationDataSub.result?.length > 0 ? quotationDataSub?.result[0]?.ape : 0
          setApeSub(apeSub)
        })
        .catch((err) => toastService.error(i18n.t('MESS.UNKNOWN_ERROR')))
    }
  }

  function onBack() {
    navigation.replace('CustomerNeed', { ...(route.params as any) })
  }

  function getTotalValueInMillions() {
    return totalValue
  }

  function onChangeNeedsItem(item: any, isSelected: boolean) {
    let temp = [...needsOverallList]
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].code === item.code && temp[i].id === item.id) {
        temp[i] = { ...temp[i], isChecked: isSelected, isTemporaryCheck: true }
        break
      }
    }
    updateNeedsOverall([...temp])
  }

  function countApePerMonthValue(ape?: number) {
    if (!ape) return 0
    let apeInMillionUnit = mathHelper.divideWithDynamicPrecisionResult(ape, 1000000, 3)
    return mathHelper.divideWithDynamicPrecisionResult(apeInMillionUnit, 12, 3)
  }

  function countApePerYearValue(ape?: number) {
    if (!ape) return 0
    let apeInMillionUnit = mathHelper.divideWithDynamicPrecisionResult(ape, 1000000, 3)
    return apeInMillionUnit
  }

  function renderMobileAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('NEEDS_CART.NEW')}
        navigation={navigation}
        onBack={onBack}
        onGoHome={() => onSaveDraft({ needTerm: getValues('needTerm') }, true)}
      />
    )
  }

  function onSaveDraft(data: any, isSavingDraft?: boolean, isSavingContinue?: boolean) {
    let apeError = dataProvider.validateAPE(currentHandlingCNA, apeSub)
    if (!isSavingDraft && apeError) {
      toastService.error(
        i18n.t(apeError?.translateMessageCode ?? 'VALIDATE.UPDATE_VALID_APE', {
          value: vnNumberFormatter.format(apeError?.value ?? 0),
        })
      )
      return
    }
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      lastEditStep: !isSavingDraft ? PageNumberMapping.Advisor : PageNumberMapping.NeedsOverall,
      isSavingDraft: isSavingDraft,
      isSavingContinue: isSavingContinue,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.NEEDS_OVERALL,
        currentHandlingCNA?.customerPhone,
        isSavingDraft
      ),
    } as any
    needsCartList.forEach((e) => {
      if (e && e.data && e.data.length) {
        e.data.forEach((f) => {
          if (f) {
            let userSelectValue = needsOverallList.find((e) => e.key === f.key)?.isChecked
            let obj = body[f.key]
            if (userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: userSelectValue.subTotal,
                }
              } else {
                body[f.key] = {
                  isSelect: true,
                  isTemporaryCheck: true,
                }
              }
            } else if (!userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: userSelectValue.subTotal,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: userSelectValue.subTotal,
                }
              }
            } else if (!userSelectValue && !f.isTemporaryCheck) {
              let resetObj = (resetCnaData as any)[f.key]
              if (resetObj) {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                  ...resetObj,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                }
              }
            }
          }
        })
      }
    })
    return saveCNA(body)
  }

  function checkContinueAvailability() {
    let isNeedsExist = false
    for (let e of needsOverallList) {
      if (e?.isChecked === true) {
        isNeedsExist = true
        break
      }
    }
    return enableContinue && isNeedsExist
  }

  function renderModals() {
    return (
      <>
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showCancelWarningModel}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.KEEP_CANCEL')}
          cancelText={i18n.t('COMMON.CONTINUE')}
          onOk={() => {
            setShowCancelWarningModel(false)
            navigation.navigate('MobileHome')
          }}
          onCancel={() => {
            setShowCancelWarningModel(false)
          }}
        >
          <Text></Text>
        </Dialog>
        {/* Warning pop update late  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showContentPopUpUpdateLate}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ maxWidth: 550, margin: 20, borderRadius: 12, paddingTop: 0 }}
          onClose={() => setShowContentPopUpUpdateLate(false)}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
              <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.Flex1]}>
                <Image style={globalStyles.Icon25} source={icWarning} />
                <Text style={globalStyles.DialogTitle}>{i18n.t('COMMON.WARNING')}</Text>
              </View>
            </View>
          }
        >
          <View style={globalStyles.Center}>
            <View style={{ maxWidth: 516, marginTop: 20, paddingHorizontal: 16 }}>
              <Text style={{ fontSize: 15, fontWeight: 'normal', textAlign: 'center', color: '#505D6F' }}>
                {i18n.t('NEEDS_CART.POP_UPDATE_LATE_CONTENT')}
              </Text>
            </View>
          </View>
        </Dialog>
      </>
    )
  }

  const ListSeparator = () => {
    return <SizedBox width={20} height={20} />
  }

  if (isMobileDevice) {
    return (
      <SafeAreaView
        style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
        edges={['right', 'left', 'top']}
      >
        <View style={globalStyles.mobileContent}>
          {renderMobileAppBar()}
          <ScrollView contentContainerStyle={[mobileStyles.contentContainer]}>
            <View style={[globalStyles.Card, mobileStyles.summaryContent]}>
              <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={mobileStyles.highlightTitleContainer}>
                <Image source={icCircleDollar as ImageSourcePropType} style={mobileStyles.dollarIcon} />
                <Text style={mobileStyles.highlightTitleLabel}>
                  {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUM_PROTECT_VALUE')}&#58;
                </Text>
                <Text style={mobileStyles.moneyTotal}>
                  &nbsp;&nbsp;{vnNumberFormatter.format(getTotalValueInMillions())}&nbsp;
                </Text>
                <Text style={[mobileStyles.unit, { color: '#FFF' }]}>{i18n.t('COMMON.MILLION_VND').toLowerCase()}</Text>
              </LinearGradientBackground>
              <SizedBox height={8} />
              <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                <View style={mobileStyles.needTotalContainer}>
                  <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                    <Text style={mobileStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                    <Text style={mobileStyles.feeValue}>
                      {vnNumberFormatter.format(countApePerYearValue(apeSub))}{' '}
                      {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                    </Text>
                  </View>
                  <SizedBox height={16} />
                  <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                    <Text style={mobileStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                    <Text style={mobileStyles.feeValue}>
                      {vnNumberFormatter.format(countApePerMonthValue(apeSub))}{' '}
                      {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                    </Text>
                  </View>
                </View>
                <SizedBox width={8} />
                <Line orientation="horizontal" lineStyle="solid" size="100%" color="#ECF0FF" />
                <View style={mobileStyles.buttonEditContainer}>
                  <Text style={mobileStyles.buttonEditLabel}>
                    {i18n.t('NEEDS_OVERALL.HOT_FIX_PROTECTION_LEVEL_MOBILE')}
                  </Text>
                  <OutlinedButton
                    title={i18n.t('NEEDS_OVERALL.VIEW_AND_EDIT')}
                    prefix={<Image source={icEditRed} style={globalStyles.Icon24} />}
                    buttonStyle={{ marginTop: 20, borderRadius: 16, height: 50 }}
                    titleStyle={{ fontSize: 15 }}
                    onPress={() => navigation.push('NeedsOverallEditMode', route.params)}
                  />
                </View>
              </View>
            </View>
            <SizedBox height={20} />
            <View style={globalStyles.DisplayFlexRow}>
              <View style={mobileStyles.container}>
                <Block
                  title={i18n.t('SUMMARY_FINANCE.INDEX')}
                  showTitle={false}
                  child={
                    <View style={mobileStyles.container}>
                      <View style={mobileStyles.needsItemsContainer}>
                        <FlatList
                          numColumns={getColumnCount()}
                          data={needsOverallList}
                          style={{ paddingVertical: 10 }}
                          showsVerticalScrollIndicator={false}
                          showsHorizontalScrollIndicator={false}
                          disableScrollViewPanResponder={true}
                          renderItem={({ item }) => (
                            <View key={item.id} style={{ width: `${100 / getColumnCount()}%` }}>
                              <NeedsOverallItem
                                item={item}
                                icon={<Image source={item.icon as ImageSourcePropType} style={globalStyles.Icon60} />}
                                qty={item.qty}
                                isChecked={item.isChecked}
                                onCheck={(value: boolean) => onChangeNeedsItem(item, value)}
                                sliderValue={item.qty / totalValue}
                                renderItemTite={(key: string) =>
                                  i18nAgentWording({
                                    agentOfficeCode: userInfo?.officeCode,
                                    wordingCode: `NEEDS_ANALYST.LIST.${key}`,
                                  })
                                }
                              />
                            </View>
                          )}
                        />
                      </View>
                      <View style={mobileStyles.noteContainer}>
                        <Image source={icInfoRed as ImageSourcePropType} style={globalStyles.Icon22} />
                        <SizedBox width={8} />
                        <Text style={mobileStyles.note}>{i18n.t('NEEDS_OVERALL.NOTE')}</Text>
                      </View>
                    </View>
                  }
                />
              </View>
            </View>
            <View style={mobileStyles.continueButtonContainer}>
              <FilledButton
                title={i18n.t('COMMON.CONTINUE')}
                onPress={handleSubmit(onSubmit)}
                buttonStyle={mobileStyles.continueButton}
                titleStyle={{ fontSize: 14 }}
                loading={loadingSaveContinue}
                disabled={!checkContinueAvailability()}
              />
            </View>
          </ScrollView>
        </View>
        <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
          <MobileStepIndicator
            route={route}
            navigation={navigation}
            cnaData={currentHandlingCNA}
            needsCartList={needsCartList}
            currentPosition={1}
            loadingSaveDraft={loadingSaveDraft}
            onSaveDraft={() => onSaveDraft({ needTerm: getValues('needTerm') }, true)}
          />
        </View>
        {renderModals()}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <StepAppHeader
        route={route}
        navigation={navigation}
        currentPosition={1}
        onSaveDraft={() => onSaveDraft({ needTerm: getValues('needTerm') }, true)}
        cnaData={currentHandlingCNA}
        needsCartList={needsCartList}
      />
      <ScrollView
        contentContainerStyle={globalStyles.content}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <Block
          title={i18n.t('SUMMARY_FINANCE.INDEX')}
          child={
            <View style={desktopStyles.content}>
              <View style={desktopStyles.summaryContent}>
                <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
                  <LinearGradientBackground
                    colors={['#FF8686', '#ED1B2E']}
                    style={desktopStyles.highlightTitleContainer}
                  >
                    <Image source={icCircleDollar as ImageSourcePropType} style={desktopStyles.dollarIcon} />
                    <Text style={desktopStyles.highlightTitleLabel}>{i18n.t('SUMMARY_FINANCE.INDEX')}&#58;</Text>
                    <Text style={desktopStyles.moneyTotal}>
                      &nbsp;{vnNumberFormatter.format(getTotalValueInMillions())} {i18n.t('COMMON.MILLION_VND')}
                    </Text>
                  </LinearGradientBackground>
                </View>
                <SizedBox height={8} />
                <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                  <View style={desktopStyles.needTotalContainer}>
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <Text style={desktopStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.ANNUAL_FEE')}</Text>
                      <Text style={desktopStyles.feeValue}>
                        {vnNumberFormatter.format(countApePerYearValue(apeSub))}{' '}
                        {i18n.t('NEEDS_OVERALL.MILLIONS_PER_YEAR')}
                      </Text>
                    </View>
                    <SizedBox height={16} />
                    <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                      <Text style={desktopStyles.feeTitle}>{i18n.t('NEEDS_OVERALL.MONTHLY_FEE')}</Text>
                      <Text style={desktopStyles.feeValue}>
                        {vnNumberFormatter.format(countApePerMonthValue(apeSub))}{' '}
                        {i18n.t('NEEDS_OVERALL.MILLIONS_PER_MONTH')}
                      </Text>
                    </View>
                  </View>
                  <SizedBox width={8} />
                  <Line orientation="vertical" lineStyle="solid" size={125} color="#ECF0FF" />
                  <View style={desktopStyles.buttonEditContainer}>
                    <Text style={desktopStyles.buttonEditLabel}>
                      {i18n.t('NEEDS_OVERALL.HOT_FIX_PROTECTION_LEVEL')}
                    </Text>
                    <OutlinedButton
                      title={i18n.t('NEEDS_OVERALL.VIEW_AND_EDIT')}
                      prefix={<Image source={icEditRed} style={globalStyles.Icon24} />}
                      buttonStyle={{ marginTop: 8, borderRadius: 16 }}
                      titleStyle={{ fontSize: 15 }}
                      onPress={() => navigation.push('NeedsOverallEditMode', route.params)}
                    />
                  </View>
                </View>
              </View>

              <View style={desktopStyles.needsItemsContainer}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  disableScrollViewPanResponder={true}
                  numColumns={getColumnCount()}
                  data={needsOverallList}
                  ItemSeparatorComponent={ListSeparator}
                  renderItem={({ item, index }) => {
                    return (
                      <View
                        key={item.id}
                        style={{
                          width: `${100 / getColumnCount() - 1.5}%`,
                          marginLeft: index % getColumnCount() === 0 ? 0 : 20,
                        }}
                      >
                        <NeedsOverallItem
                          item={item}
                          icon={<Image source={item.icon as ImageSourcePropType} style={globalStyles.Icon60} />}
                          qty={item.qty}
                          isChecked={item.isChecked}
                          onCheck={(value: boolean) => {
                            onChangeNeedsItem(item, value)
                          }}
                          sliderValue={item.qty / totalValue}
                          renderItemTite={(key: string) =>
                            i18nAgentWording({
                              agentOfficeCode: userInfo?.officeCode,
                              wordingCode: `NEEDS_ANALYST.LIST.${key}`,
                            })
                          }
                        />
                      </View>
                    )
                  }}
                />
              </View>
              <View style={desktopStyles.noteContainer}>
                <Image source={icInfoRed as ImageSourcePropType} style={globalStyles.Icon22} />
                <SizedBox width={8} />
                <Text style={desktopStyles.note}>{i18n.t('NEEDS_OVERALL.NOTE')}</Text>
              </View>
            </View>
          }
        />
        <SizedBox height={30} />
        <StepButton
          onBack={onBack}
          onNext={handleSubmit(onSubmit)}
          disabledOnNext={!checkContinueAvailability()}
          loadingOnNext={loadingSaveContinue}
        />
      </ScrollView>
      {/* <EditProfileTab disabled={true} /> */}
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(NeedsOverallScreen)

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FBFBFB',
    flex: 1,
    paddingBottom: 20,
    paddingRight: 50,
    paddingLeft: 33,
    paddingTop: 100,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
  },
  summaryContent: {
    flexDirection: 'column',
    margin: 31,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
    borderTopWidth: 0,
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingHorizontal: 100,
    paddingVertical: 50,
  },
  feeTitle: {
    flex: 1,
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 16,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 15,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  noteContainer: {
    margin: 31,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  note: {
    fontSize: 15,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 15,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dollarIcon: {
    width: 40,
    height: 40,
  },
  highlightTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 24,
    fontWeight: '700',
    color: '#FFF',
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 33,
    alignItems: 'center',
  },
  planInfoContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderRadius: 20,
    padding: 30,
    backgroundColor: '#FAFBFF',
    marginTop: 17,
    alignItems: 'center',
  },
  planTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: '#505D6F',
    marginRight: 13,
  },
  inputPlanContainer: {
    flexDirection: 'row',
  },
  inputPlan: {
    fontSize: 15,
    fontWeight: '600',
    minWidth: 418,
    textAlign: 'right',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#2B2D42',
  },
  inputPlanContainerLeft: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 20,
  },
  inputPlanContainerRight: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 20,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    padding: 0,
    width: '100%',
    paddingTop: 20,
    paddingBottom: 50,
    marginBottom: 0,
    overflow: 'scroll',
  },
  highlightTitleContainer: {
    paddingHorizontal: 16,
    paddingVertical: 9,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: 19,
    width: '100%',
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 12,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 20,
    fontWeight: '700',
    color: '#FFF',
  },
  needsTermContainer: {
    paddingBottom: 20,
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 8,
    alignItems: 'flex-start',
  },
  planInfoContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderRadius: 10,
    padding: 16,
    backgroundColor: '#FAFBFF',
    marginHorizontal: 16,
    marginTop: 17,
    alignItems: 'center',
  },
  planTitle: {
    fontSize: 13,
    fontWeight: '600',
    color: '#505D6F',
    marginRight: 13,
  },
  inputPlanContainer: {
    flexDirection: 'row',
    marginTop: 8,
    width: '100%',
  },
  inputPlan: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'right',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 13,
    color: '#2B2D42',
  },
  inputPlanContainerLeft: {
    flex: 1,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  inputPlanContainerRight: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderLeftWidth: 0,
    paddingVertical: 12,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  stepIndicatorContainer: {
    bottom: 16,
    left: 16,
    right: 16,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  continueButtonContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 100,
  },
  continueButton: {
    width: 100,
    height: 41,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 32,
  },
  summaryContent: {
    flexDirection: 'column',
    borderRadius: 20,
    borderWidth: 0,
    borderColor: Colors.borderColor,
    borderTopWidth: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    padding: 16,
  },
  feeTitle: {
    flex: 1,
    fontSize: 13,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 15,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    width: '100%',
    padding: 16,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  noteContainer: {
    marginHorizontal: 16,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: Colors.textBoxBackground,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  note: {
    fontSize: 13,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
})
