// components/RadioButton.js
import React, { useState,useEffect } from 'react';
import { SafeAreaView, StyleSheet,View,Text } from 'react-native';
import ButtonQuantity from './ButtonQuantity';
import Layout from '../../constants/Layout';

export type QuantityItem = {
  start: number
  value?: number
  title: string
  min: number
  max: number
  onChange?: (count: number) => void
}

export function Quantity({ start,min,max,title,value,onChange}: QuantityItem) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice

    const [counter, setCounter] = useState(Number(value));
    const [incDisabled, setIncDisabled] = useState(false);
    const [decDisabled, setDecDisabled] = useState(true);

    useEffect(() => {
      if (value){
        setCounter(value);
      }
  }, [value])

   const onPressInc = () => {
        if (counter < max) {
            setCounter(counter +1 );
             // setDecDisabled(false);
            return onChange && onChange(counter + 1 )
        } else {
            // setIncDisabled(true);
        }
      }
    
      const  onPressDec = () => {
        if (counter > min) {
            setCounter(counter - 1 );
            // setIncDisabled(false);
            return onChange && onChange(counter - 1)

        } else {
            // setDecDisabled(true)
        }
      }
     

      if (isMobileDevice) {
        return (
          <SafeAreaView style={mobileStyles.boxQuantity}>
          <View><Text style={mobileStyles.titleQuantity}>{title}</Text></View>
        <View style={mobileStyles.buttonContainer}>
          <ButtonQuantity 
            backgroundColor='#fff' 
            underlayColor='#DDDDDD' 
            label={'-'}
            onPress={() => onPressDec()}  
            // disabled={decDisabled} 
          />
          <View style={mobileStyles.countWrap}>
          <Text style={mobileStyles.count}>
            {counter}
          </Text>
          </View>
          <ButtonQuantity 
            backgroundColor='#fff' 
            underlayColor='#DDDDDD' 
            label={'+'}
            onPress={() => onPressInc()}
            // disabled={incDisabled} 
          />
        </View>
        </SafeAreaView>
        )
      }
    
      if (isLaptopDevice) {
        return (
          <SafeAreaView style={tabletStyle.boxQuantity}>
            <Text style={tabletStyle.titleQuantity}>{title}</Text>
          <View style={tabletStyle.buttonContainer}>
            <ButtonQuantity 
              backgroundColor='#fff' 
              underlayColor='#DDDDDD' 
              label={'-'}
              onPress={() => onPressDec()}  
              // disabled={decDisabled} 
            />
            <Text style={tabletStyle.count}>
              {counter}
            </Text>
            <ButtonQuantity 
              backgroundColor='#fff' 
              underlayColor='#DDDDDD' 
              label={'+'}
              onPress={() => onPressInc()}
              disabled={incDisabled} 
            />
          </View>
          </SafeAreaView>
        );
      }

  return (
    <SafeAreaView style={styles.boxQuantity}>
      <Text style={styles.titleQuantity}>{title}</Text>
    <View style={styles.buttonContainer}>
      <ButtonQuantity 
        backgroundColor='#fff' 
        underlayColor='#DDDDDD' 
        label={'-'}
        onPress={() => onPressDec()}  
        // disabled={decDisabled} 
      />
      <Text style={styles.count}>
        {counter}
      </Text>
      <ButtonQuantity 
        backgroundColor='#fff' 
        underlayColor='#DDDDDD' 
        label={'+'}
        onPress={() => onPressInc()}
        disabled={incDisabled} 
      />
    </View>
    </SafeAreaView>
  );
}


const styles = StyleSheet.create({
    heading: {
      marginVertical: 10,
      color: '#5a2961',
      fontSize: 30,
      fontWeight: 'bold',
    },
    boxQuantity: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 30,
    },
    image: {
      marginVertical: 10,
    },
    buttonContainer: {
      flexDirection: 'row',
      borderWidth: 1.5,
      width: 120,
      borderColor: '#D7DFFF',
      borderRadius: 10
    },
    titleQuantity: {
      fontSize: 16,
      color: '#505D6F',
      fontWeight: '600',
      marginRight: 20
    },
    count: {
      width: 50,
      fontWeight: 'bold',
      marginVertical: 5,
      color: '#5a2961',
      fontSize: 20,
      textAlign: 'center', 
    },
    scroll: {
      flex: 1,
      marginTop: 10,
    },
  });

  const tabletStyle = StyleSheet.create({
    heading: {
      marginVertical: 10,
      color: '#5a2961',
      fontSize: 16.5833,
      fontWeight: 'bold',
    },
    boxQuantity: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 30,
    },
    image: {
      marginVertical: 10,
    },
    buttonContainer: {
      flexDirection: 'row',
      borderWidth: 1.5,
      width: 120,
      alignItems:'center',
      borderColor: '#D7DFFF',
      borderRadius: 10
    },
    titleQuantity: {
      fontSize: 13.2667,
      color: '#505D6F',
      fontWeight: '600',
      marginRight: 20
    },
    count: {
      width: 50,
      fontWeight: 'bold',
      marginVertical: 5,
      color: '#5a2961',
      fontSize: 16.5833,
      textAlign: 'center', 
    },
    scroll: {
      flex: 1,
      marginTop: 10,
    },
  });
  

  const mobileStyles = StyleSheet.create({
    heading: {
      marginVertical: 10,
      color: '#373737',
      fontSize: 30,
      fontWeight: 'bold',
    },
    boxQuantity: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    image: {
      marginVertical: 10,
    },
    buttonContainer: {
      flexDirection: 'row',
      borderWidth: 1.5,
      width: 'auto',
      maxWidth: 120,
      borderColor: '#D7DFFF',
      borderRadius: 10
    },
    titleQuantity: {
      fontSize: 13,
      color: '#373737',
      fontWeight: '700',
      marginRight: 20
    },
    countWrap: {
      marginVertical: 7,
      fontSize: 19,
      width: 'auto', 
      minWidth: 30,
    },
    count: {
      color: '#5a2961',
      fontSize: 19,
      fontWeight: "600",
      textAlign: 'center', 
    },
    scroll: {
      flex: 1,
      marginTop: 10,
    },

  })