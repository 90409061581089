import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native'
import IcArrowRightRedCircle from '../../assets/images/circle-chevron-right-red.png'
import { styles as globalStyles } from '../constants/globalStyles'

export type ProvideYourInfoButtonProps = {
  onPress?: () => void
}

export function ProvideYourInfoButton({ onPress }: ProvideYourInfoButtonProps) {
  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.button} onPress={onPress}>
        <Text style={styles.label}>Cung cấp thông tin của bạn</Text>
        <Image
          source={IcArrowRightRedCircle}
          style={globalStyles.Icon20}
        />
      </TouchableOpacity>
    </View>
  )
}

export default ProvideYourInfoButton

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 50,
    marginBottom: 30,
  },
  label: {
    color: '#505D6F',
    fontSize: 14,
    fontWeight: '600',
    marginRight: 8,
  },
  button: {
    backgroundColor: '#FFF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    borderRadius: 25,
  },
})
