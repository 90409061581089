import * as React from 'react'
import { StyleSheet, Image, ImageSourcePropType } from 'react-native'
import { CheckBox } from 'react-native-elements'
import imgUntick from '../../assets/images/untick-square.png'
import { styles as globalStyles } from '../constants/globalStyles'
import imgTick from '../../assets/images/tick-square.png'
import Layout from '../../constants/Layout'

export type CheckboxProps = {
  checked?: boolean
  title?: string | JSX.Element
  containerStyle?: object
  onChange?: (value: boolean) => void
}

export function Checkbox({ checked, containerStyle, title, onChange }: CheckboxProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice

  if (isMobileDevice) {
    return (
      <CheckBox
        checked={checked}
        title={title}
        textStyle={mobileStyles.checkTitle}
        containerStyle={[mobileStyles.checkList, containerStyle]}
        onPress={() => onChange?.(!checked)}
        checkedIcon={<Image source={imgTick as ImageSourcePropType} style={globalStyles.Icon20} />}
        uncheckedIcon={<Image source={imgUntick as ImageSourcePropType} style={globalStyles.Icon20} />}
        size={20}
      />
    )
  }

  if (isLaptopDevice) {
    return (
      <CheckBox
        checked={checked}
        title={title}
        textStyle={tabletStyles.checkTitle}
        containerStyle={[tabletStyles.checkList, containerStyle]}
        onPress={() => onChange?.(!checked)}
        checkedIcon={<Image source={imgTick as ImageSourcePropType} style={globalStyles.Icon18} />}
        uncheckedIcon={<Image source={imgUntick as ImageSourcePropType} style={globalStyles.Icon18} />}
        size={20}
      />
    )
  }

  return (
    <CheckBox
      checked={checked}
      title={title}
      textStyle={styles.checkTitle}
      containerStyle={[styles.checkList, containerStyle]}
      onPress={() => onChange?.(!checked)}
      checkedIcon={<Image source={imgTick as ImageSourcePropType} style={globalStyles.Icon25} />}
      uncheckedIcon={<Image source={imgUntick as ImageSourcePropType} style={globalStyles.Icon25} />}
      size={20}
    />
  )
}

export default Checkbox

const styles = StyleSheet.create({
  checkList: {
    minWidth: 140,
    maxWidth: 200,
    borderWidth: 0,
    backgroundColor: 'transparent',
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    marginVertical: 6,
  },
  checkTitle: {
    fontSize: 16,
    color: '#373737',
    fontWeight: '500',
  },
})

const tabletStyles = StyleSheet.create({
  checkList: {
    minWidth: 140,
    maxWidth: 200,
    borderWidth: 0,
    backgroundColor: 'transparent',
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
  },
  checkTitle: {
    fontSize: 13.2667,
    color: '#373737',
    fontWeight: '500',
  },
})

const mobileStyles = StyleSheet.create({
  checkList: {
    minWidth: 140,
    borderWidth: 0,
    backgroundColor: 'transparent',
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    marginVertical: 20,
  },
  checkTitle: {
    fontSize: 13,
    color: '#373737',
    fontWeight: '500',
  },
})
