import React, { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { styles as globalStyles, BlockAlysys, InputData, Checkbox, BoxFooter, SizedBox, ValidateRequiredError } from '../common'
import { updateUserProtect } from '../slices/userSlice'

import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { updateProtectAssetSlice } from '../slices/protectAssetSlice'
import { connect, ConnectedProps } from 'react-redux'
import Layout from '../constants/Layout'
import i18n from 'i18n-js'
import AgentWording from '../components/AgentWording'
import Colors from '../common/constants/Colors'


const mapStateToProps = (state: RootState) => ({
  protectAsset: state.protectAsset.protectAsset,
  profileInfo: state.needsCart.profileInfo,
  needsCartList: state.needsCart.needsCartList,
  userProtect: state.user.userProtect,
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {
  saveCNA,
  updateProtectAssetSlice,
  updateUserProtect,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SafeAssetScreen({
  userInfo,
  userProtect,
  protectAsset,
  updateUserProtect,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice

  function countTotal() {
    if (userProtect) {
      const array = [
        Number(getValues(`saving.total`) || 0),
        Number(getValues(`otherAssets.total`) || 0),
        Number(getValues(`collateral.total`) || 0),
      ]
      setValue('subTotal', array.reduce(reducer))
      const dataWatch = JSON.parse(JSON.stringify(watch()))
      const defaultData = {
        isTemporaryCheck: true,
        subTotal: dataWatch.subTotal,
        collateral: {
          isCheck: dataWatch.collateral.isCheck || false,
          total: dataWatch.collateral.total || 0,
        },
        otherAssets: {
          isCheck: dataWatch.otherAssets.isCheck || false,
          total: dataWatch.otherAssets.total || 0,
        },
        saving: {
          isCheck: dataWatch.saving.isCheck || false,
          total: dataWatch.saving.total || 0,
        },
      }
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        protectAsset: { ...userProtect?.protectAsset, ...defaultData },
      }
      updateUserProtect({
        cnaData,
      })
    }
  }

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: protectAsset,
  })

  useEffect(() => {
    if (userProtect?.protectAsset) {
      // reset(userProtect?.protectAsset, { errors: false , isDirty: true})
      setValue("collateral",userProtect?.protectAsset?.collateral );
      setValue("otherAssets",userProtect?.protectAsset?.otherAssets );
      setValue("saving",userProtect?.protectAsset?.saving );
    }
  }, [userProtect])

  const reducer = (previousValue: any, currentValue: any) => Number(previousValue) + Number(currentValue)


  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error}  style={{marginBottom: 10, marginTop: 0}}/>
        </View>
      )
    } else {
      return <></>
    }
  }

  function renderErrorMessageMobile(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error}  style={{marginBottom: 0, marginTop: 10}}/>
        </View>
      )
    } else {
      return <></>
    }
  }


  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Bảo vệ tài sản"
          borderColor="#BE63F9"
          child={
            <View>
              <Text style={mobileStyles.safeTitle}>
                Bạn đang có tài sản thế chấp, sổ tiết kiệm hay tài sản nào cần được bảo vệ không?
              </Text>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <Checkbox
                      checked={value}
                      title={'Tài sản thế chấp'}
                      onChange={(value) => {
                        onChange(value)
                        if (value === false) {
                          setValue(`collateral.total`, 0)
                          clearErrors(`collateral.total`)
                        }
                        countTotal()
                      }}
                    />
                  </View>
                )}
                name={`collateral.isCheck`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />

              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View style={{ paddingLeft: 35 }}>
                    <InputData
                      title="Giá trị"
                      helpText="Triệu đồng"
                      type="row"
                      placeholder="Nhập"
                      titleStyle={{ width: '15%', marginBottom: 0 }}
                      stylesInput={{ marginBottom: 0 }}
                      wrapHelpTextContainer={{ width: '85%', paddingLeft: 10 }}
                      value={value !== 0 ? value : ''}
                      disabled={watch('collateral.isCheck') === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        setValue(`collateral.total`, value)
                        countTotal()
                      }}
                    />

                    {watch('collateral.isCheck') === true && errors.collateral?.total &&  renderErrorMessageMobile(errors?.collateral.total.message)}
                  </View>
                )}
                name={`collateral.total`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />

              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <Checkbox
                      checked={value}
                      title={'Sổ tiết kiệm'}
                      onChange={(value) => {
                        onChange(value)
                        if (value === false) {
                          setValue(`saving.total`, 0)
                          clearErrors(`saving.total`)
                        }
                        countTotal()
                      }}
                    />
                  </View>
                )}
                name={`saving.isCheck`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />
              <Controller
                control={control}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={{ paddingLeft: 35 }}>
                    <InputData
                      title="Giá trị"
                      helpText="Triệu đồng"
                      type="row"
                      titleStyle={{ width: '15%', marginBottom: 0 }}
                      stylesInput={{ marginBottom: 0 }}
                      wrapHelpTextContainer={{ width: '85%', paddingLeft: 10 }}
                      value={value !== 0 ? value : ''}
                      disabled={watch(`saving.isCheck`) === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        setValue(`saving.total`, value)
                        countTotal()
                      }}
                      placeholder="Nhập"
                    />
                    {getValues('saving.isCheck') === true && errors.saving?.total &&  renderErrorMessageMobile(errors?.saving.total.message)}
                  </View>
                )}
                name={`saving.total`}
              />
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View>
                    <Checkbox
                      checked={value}
                      title={
                        <AgentWording
                          agentOfficeCode={userInfo?.officeCode}
                          wordingCode='NEEDS_ANALYST.PROTECT_ASSETS.OTHERS'
                          style={mobileStyles.checkboxTitle}
                        />
                      }
                      containerStyle={{maxWidth: 300}}
                      onChange={(value) => {
                        onChange(value)
                        if (value === false) {
                          setValue(`otherAssets.total`, 0)
                          clearErrors(`otherAssets.total`)
                        }
                        countTotal()
                      }}
                    />
                  </View>
                )}
                name={`otherAssets.isCheck`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />
              <Controller
                control={control}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={{ paddingLeft: 35, marginBottom: 20 }}>
                    <InputData
                      title="Giá trị"
                      helpText="Triệu đồng"
                      type="row"
                      titleStyle={{ width: '15%', marginBottom: 0 }}
                      stylesInput={{ marginBottom: 0 }}
                      wrapHelpTextContainer={{ width: '85%', paddingLeft: 10 }}
                      value={value !== 0 ? value : ''}
                      disabled={watch(`otherAssets.isCheck`) === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        setValue(`otherAssets.total`, value)
                        countTotal()
                      }}
                      placeholder="Nhập"
                    />
                    {getValues('otherAssets.isCheck') === true && errors.otherAssets?.total &&  renderErrorMessageMobile(errors?.otherAssets.total.message) }
                  </View>
                )}
                name={`otherAssets.total`}
              />

              <Controller
                control={control}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name={`subTotal`}
              />
            </View>
          }
        />
      </View>
    )
  }

  if (isLaptopDevice) {
    return (
      <View style={{ flex: 1, position: 'relative' }}>
        <BlockAlysys
          title="Bảo vệ tài sản"
          borderColor="#BE63F9"
          child={
            <View>
              <Text style={tabletStyles.safeTitle}>
                Bạn đang có tài sản thế chấp, sổ tiết kiệm hay tài sản nào cần được bảo vệ không?
              </Text>
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View style={{ marginBottom: 10 }}>
                    <Checkbox
                      checked={value}
                      title={'Tài sản thế chấp'}
                      containerStyle={{maxWidth: 300}}
                      onChange={(value) => {
                        onChange(value)
                        if (value === false) {
                          setValue(`collateral.total`, 0)
                          clearErrors(`collateral.total`)
                          countTotal()
                        }
                        countTotal()
                      }}
                    />
                  </View>
                )}
                name={`collateral.isCheck`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />

              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View style={{ paddingLeft: 35, marginBottom: 0 }}>
                    <InputData
                      title="Giá trị"
                      helpText="Triệu đồng"
                      type="row"
                      placeholder="Nhập"
                      titleStyle={{ minWidth: 'auto' }}
                      widthInput={90}
                      stylesInput={{ width: 'auto', marginBottom: 10 }}
                      value={value !== 0 ? value : ''}
                      disabled={watch('collateral.isCheck') === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        setValue(`collateral.total`, value)
                        countTotal()
                      }}
                    />
                    {getValues('collateral.isCheck') === true && errors.collateral?.total && renderErrorMessage(errors?.collateral.total.message)}
                  </View>
                )}
                name={`collateral.total`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />

              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View style={{ marginBottom: 10 }}>
                    <Checkbox
                      checked={value}
                      title={'Sổ tiết kiệm'}
                      onChange={(value) => {
                        onChange(value)
                        if (value === false) {
                          setValue(`saving.total`, 0)
                          clearErrors(`saving.total`)
                        }
                        countTotal()
                      }}
                    />
                  </View>
                )}
                name={`saving.isCheck`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />
              <Controller
                control={control}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={{ paddingLeft: 35, marginBottom: 0 }}>
                    <InputData
                      title="Giá trị"
                      helpText="Triệu đồng"
                      type="row"
                      titleStyle={{ minWidth: 'auto' }}
                      widthInput={90}
                      value={value !== 0 ? value : ''}
                      stylesInput={{ width: 'auto', marginBottom: 10 }}
                      disabled={watch(`saving.isCheck`) === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        setValue(`saving.total`, value)
                        countTotal()
                      }}
                      placeholder="Nhập"
                    />
                    {watch('saving.isCheck') === true && errors.saving?.total &&  renderErrorMessage(errors?.saving.total.message)}
                  </View>
                )}
                name={`saving.total`}
              />
              <Controller
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <View style={{ marginBottom: 10 }}>
                    <Checkbox
                      checked={value}
                      title={
                        <AgentWording
                          agentOfficeCode={userInfo?.officeCode}
                          wordingCode='NEEDS_ANALYST.PROTECT_ASSETS.OTHERS'
                          style={tabletStyles.checkboxTitle}
                        />
                      }
                      containerStyle={{maxWidth: 300}}
                      onChange={(value) => {
                        onChange(value)
                        if (value === false) {
                          setValue(`otherAssets.total`, 0)
                          clearErrors(`otherAssets.total`)
                        }
                        countTotal()
                      }}
                    />
                  </View>
                )}
                name={`otherAssets.isCheck`}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
              />
              <Controller
                control={control}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={{ paddingLeft: 35, marginBottom: 0 }}>
                    <InputData
                      title="Giá trị"
                      helpText="Triệu đồng"
                      type="row"
                      titleStyle={{ minWidth: 'auto' }}
                      value={value !== 0 ? value : ''}
                      widthInput={90}
                      stylesInput={{ width: 'auto', marginBottom: 10 }}
                      disabled={watch(`otherAssets.isCheck`) === true ? false : true}
                      onBlur={onBlur}
                      onChangeText={(value) => {
                        onChange(value)
                        setValue(`otherAssets.total`, value)
                        countTotal()
                      }}
                      placeholder="Nhập"
                    />
                    {watch('otherAssets.isCheck') === true && errors.otherAssets?.total &&  renderErrorMessage(errors?.otherAssets.total.message)}
                  </View>
                )}
                name={`otherAssets.total`}
              />

              <Controller
                control={control}
                   rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
                defaultValue=""
                render={({ value }) => (
                  <BoxFooter
                    title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                    backgroundColorBox="#F5E6FE"
                    titleStyle={{ color: '#BE63F9' }}
                    child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                  />
                )}
                name={`subTotal`}
              />
            </View>
          }
        />
        <SizedBox width={24} />
      </View>
    )
  }
  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Bảo vệ tài sản"
        borderColor="#BE63F9"
        child={
          <View>
            <Text style={desktopStyles.safeTitle}>
              Bạn đang có tài sản thế chấp, sổ tiết kiệm hay tài sản nào cần được bảo vệ không?
            </Text>
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ marginBottom: 10 }}>
                  <Checkbox
                    checked={value}
                    title={'Tài sản thế chấp'}
                    onChange={(value) => {
                      onChange(value)
                      if (value === false) {
                        setValue(`collateral.total`, 0)
                        clearErrors(`collateral.total`)
                      }
                      countTotal()
                    }}
                  />
                </View>
              )}
              name={`collateral.isCheck`}
                 rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ paddingLeft: 35 }}>
                  <InputData
                    title="Giá trị"
                    helpText="Triệu đồng"
                    type="row"
                    placeholder="Nhập"
                    titleStyle={{ minWidth: 'auto' }}
                    value={value !== 0 ? value : ''}
                    disabled={watch('collateral.isCheck') === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      setValue(`collateral.total`, value)
                      countTotal()
                    }}
                  />
                  {watch('collateral.isCheck') === true && errors.collateral?.total && 
                  renderErrorMessage(errors?.collateral.total.message)
                  }
                </View>
              )}
              name={`collateral.total`}
              rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
            />

            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ marginBottom: 10 }}>
                  <Checkbox
                    checked={value}
                    title={'Sổ tiết kiệm'}
                    onChange={(value) => {
                      onChange(value)
                      if (value === false) {
                        setValue(`saving.total`, 0)
                        clearErrors(`saving.total`)
                      }
                      countTotal()
                    }}
                  />
                </View>
              )}
              name={`saving.isCheck`}
                 rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
              }}
              defaultValue=""
            />
            <Controller
              control={control}
                 rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <View style={{ paddingLeft: 35 }}>
                  <InputData
                    title="Giá trị"
                    helpText="Triệu đồng"
                    type="row"
                    titleStyle={{ minWidth: 'auto' }}
                    value={value !== 0 ? value : ''}
                    disabled={watch(`saving.isCheck`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      setValue(`saving.total`, value)
                      countTotal()
                    }}
                    placeholder="Nhập"
                  />
                  {watch('saving.isCheck') === true && errors.saving?.total && renderErrorMessage(errors?.saving.total.message) }
                </View>
              )}
              name={`saving.total`}
            />
            <Controller
              control={control}
              render={({ onChange, onBlur, value }) => (
                <View style={{ marginBottom: 10 }}>
                  <Checkbox
                    checked={value}
                    title={
                      <AgentWording
                        agentOfficeCode={userInfo?.officeCode}
                        wordingCode='NEEDS_ANALYST.PROTECT_ASSETS.OTHERS'
                        style={desktopStyles.checkboxTitle}
                      />
                    }
                    containerStyle={{maxWidth: 300}}
                    onChange={(value) => {
                      onChange(value)
                      if (value === false) {
                        setValue(`otherAssets.total`, 0)
                        clearErrors(`otherAssets.total`)
                      }
                      countTotal()
                    }}
                  />
                </View>
              )}
              name={`otherAssets.isCheck`}
                 rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                }
              }}
              defaultValue=""
            />
            <Controller
              control={control}
                 rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <View style={{ paddingLeft: 35 }}>
                  <InputData
                    title="Giá trị"
                    helpText="Triệu đồng"
                    type="row"
                    titleStyle={{ minWidth: 'auto' }}
                    value={value !== 0 ? value : ''}
                    stylesInput={{marginBottom: 0}}
                    disabled={watch(`otherAssets.isCheck`) === true ? false : true}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                      onChange(value)
                      setValue(`otherAssets.total`, value)
                      countTotal()
                    }}
                    placeholder="Nhập"
                  />
                  {watch('otherAssets.isCheck') === true && errors.otherAssets?.total &&  renderErrorMessage(errors?.otherAssets.total.message) }
                </View>
              )}
              name={`otherAssets.total`}
            />

            <Controller
              control={control}
                 rules={{
                required: {
                  value: true,
                  message: i18n.t('VALIDATE.REQUIRED_DATA'),
                },
                min: {
                  value: 0.001,
                  message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                },
              }}
              defaultValue=""
              render={({ value }) => (
                <BoxFooter
                  title="SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:"
                  backgroundColorBox="#F5E6FE"
                  titleStyle={{ color: '#BE63F9' }}
                  child={<Text>{isNaN(value as any) === false && value && value > 0 ? value : 0}</Text>}
                />
              )}
              name={`subTotal`}
            />
          </View>
        }
      />
      <SizedBox width={24} />
    </View>
  )
}

export default connector(SafeAssetScreen)

const desktopStyles = StyleSheet.create({
  safeTitle: {
    fontSize: 16,
    marginBottom: 30,
    fontWeight: '600',
  },
  checkboxTitle: {
    fontSize: 16,
    color: Colors.mainTextColor,
    fontWeight: '500',
    marginHorizontal: 10,
  },
})

const tabletStyles = StyleSheet.create({
  safeTitle: {
    fontSize: 13.2667,
    marginBottom: 30,
    fontWeight: '600',
  },
  checkboxTitle: {
    fontSize: 13,
    color: Colors.mainTextColor,
    fontWeight: '500',
    marginHorizontal: 10,
  },
})

const mobileStyles = StyleSheet.create({
  safeTitle: {
    fontSize: 13,
    marginBottom: 15,
    fontWeight: '600',
  },
  checkboxTitle: {
    fontSize: 13,
    color: Colors.mainTextColor,
    fontWeight: '500',
    marginHorizontal: 10,
  },
})
