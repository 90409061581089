import React, { Component } from 'react'
import { isHoverEnabled } from './HoverState'

type IProps = {
  children: any
  onHoverIn: (e: any) => void
  onHoverOut: (e: any) => void
}

type IState = {
  isHovered: boolean
  showHover: boolean
}

export class Hoverable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isHovered: false, showHover: true }
    this._handleMouseEnter = this._handleMouseEnter.bind(this)
    this._handleMouseLeave = this._handleMouseLeave.bind(this)
    this._handleGrant = this._handleGrant.bind(this)
    this._handleRelease = this._handleRelease.bind(this)
  }

  _handleMouseEnter(e: any) {
    if (isHoverEnabled() && !this.state.isHovered) {
      const { onHoverIn } = this.props
      if (onHoverIn) onHoverIn(e)
      this.setState((state) => ({ ...state, isHovered: true }))
    }
  }

  _handleMouseLeave(e: any) {
    if (this.state.isHovered) {
      const { onHoverOut } = this.props
      if (onHoverOut) onHoverOut(e)
      this.setState((state) => ({ ...state, isHovered: false }))
    }
  }

  _handleGrant() {
    this.setState((state) => ({ ...state, showHover: false }))
  }

  _handleRelease() {
    this.setState((state) => ({ ...state, showHover: true }))
  }

  render() {
    const { children} = this.props
    const child = typeof children === 'function' ? children(this.state.showHover && this.state.isHovered) : children

    return React.cloneElement(React.Children.only(child), {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
      // Prevent hover showing while responder
      onResponderGrant: this._handleGrant,
      onResponderRelease: this._handleRelease,
      // If child is Touchable
      onPressIn: this._handleGrant,
      onPressOut: this._handleRelease,
    })
  }
}

export default Hoverable
