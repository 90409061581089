import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, Dimensions, TouchableOpacity } from 'react-native'
import icOrangeHeart from '../../../assets/images/orange-heart.png'

import {
  vnNumberFormatter,
  SizedBox,
  largeTabletMediaConfig,
  tabletSmallMediaConfig,
  styles as globalStyles,
  InputChart,
  FilledButton,
} from '../../../common'
import ColumnChart from '../../Charts/ColumnChart'
import i18n from 'i18n-js'
import { useMediaQuery } from 'react-responsive'
import icRate from '../../../assets/images/rate.png'
import icCirce from '../../../assets/images/circle.png'
import imgArrowRightCircleRedLight from '../../../assets/images/arrow-right-circle-red-light.png'
import navService from '../../../services/navigation/NavService'
import Layout from '../../../constants/Layout'

type IProps = {
  customerAge: number
  data?: Array<{ policyYear: number; ape: number; fundValue: number }>
  dataTopUp?: Array<{ policyYear: number; ape: number; fundValue: number }>

  style?: Record<string, unknown>
  quotationFundAllocation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }
  productTypeChart?: string
  extraInvest?: number
  isLoadingChartData?: boolean
  editable?: boolean
  onConfirm: (quotation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }) => void
  onOpenExtraInvestModal: () => void
  onOpenProfitModal: () => void
}

function ChartBox({
  data,
  customerAge,
  dataTopUp,
  style,
  quotationFundAllocation,
  productTypeChart,
  extraInvest,
  isLoadingChartData,
  editable,
  onConfirm,
  onOpenExtraInvestModal,
  onOpenProfitModal,
}: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = useMediaQuery(largeTabletMediaConfig)
  const isTabletSmallDevice = useMediaQuery(tabletSmallMediaConfig)

  const patternChartData = [
    {
      seriesName: 'Số tiền tham gia',
      data: new Array<{ x: string; y: number }>(),
      color: '#FF9900',
    },
    {
      seriesName: 'Số tiền nhận về',
      data: new Array<{ x: string; y: number }>(),
      color: '#26C6DA',
    },
  ]

  const patternChartDataTopUp = [
    {
      seriesName: 'Số tiền tham gia',
      data: new Array<{ x: string; y: number }>(),
      color: '#FF9900',
    },
    {
      seriesName: 'Số tiền nhận về',
      data: new Array<{ x: string; y: number }>(),
      color: '#26C6DA',
    },
  ]

  const [chartData, setChartData] = React.useState<
    Array<{
      seriesName: any
      data: any
      color: any
    }>
  >(patternChartData)

  const [dataChartInvest, setDataChartInvest] = React.useState<
    Array<{
      seriesName: any
      data: any
      color: any
    }>
  >(patternChartDataTopUp)

  const [quotationData, setQuotationData] = React.useState<{
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }>()

  const [total, setTotal] = React.useState<number>(0)

  React.useEffect(() => {
    setQuotationData(quotationFundAllocation)
  }, [quotationFundAllocation])

  React.useEffect(() => {
    let total = 0
    total += quotationData?.vietNamEquity
    total += quotationData?.growth
    total += quotationData?.balanced
    total += quotationData?.stable
    total += quotationData?.vietNamBond
    total += quotationData?.preserved
    setTotal(total)
  }, [quotationData])

  React.useEffect(() => {
    if (data) {
      let temp = chartData
      temp[0].data = []
      temp[1].data = []
      temp[0].color = extraInvest ? '#FFCF86' : '#FF9900'
      temp[1].color = extraInvest ? '#83EBF9' : '#26C6DA'
      for (let i = 0; i < data.length; i++) {
        if (i === 0 || (i + 1) % 5 === 0) {
          temp[0].data.push({
            x: `${customerAge + data[i]?.policyYear} tuổi`,
            y: data[i]?.ape,
          })
          temp[1].data.push({
            x: `${customerAge + data[i]?.policyYear} tuổi`,
            y: data[i]?.fundValue,
          })
        }
      }
      setChartData([...temp])
    }
    if (dataTopUp) {
      let temp = dataChartInvest
      temp[0].data = []
      temp[1].data = []
      for (let i = 0; i < dataTopUp.length; i++) {
        if (i === 0 || (i + 1) % 5 === 0) {
          temp[0].data.push({
            x: `${customerAge + dataTopUp[i]?.policyYear} tuổi`,
            y: dataTopUp[i]?.ape,
          })
          temp[1].data.push({
            x: `${customerAge + dataTopUp[i]?.policyYear} tuổi`,
            y: dataTopUp[i]?.fundValue,
          })
        }
      }
      setDataChartInvest([...temp])
      // setChartData([...temp])
    }
  }, [customerAge, data, dataTopUp])

  function onPress() {
    onConfirm(quotationData ?? quotationFundAllocation)
  }

  function renderTotal() {
    if (!total) {
      return 100
    } else if (100 - total < 100 && 100 - total > 0) {
      return 100 - total
    } else return 0
  }

  function onOpenPopupChart() {
    let idData = window.location.search.split('id=')[1]
    if (idData.includes('mode') || idData.includes('popup')) {
      idData = idData.substring(0, idData.indexOf('&'))
      navService.push('ChartPage', { ...{ id: idData, mode: 'editable' } })
    } else {
      navService.push('ChartPage', { ...{ id: idData } })
    }
  }

  function updateQuotation(key: string, value: string) {
    setQuotationData({
      ...quotationData,
      [key]: value ? parseFloat(value) : null,
    })
  }

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container, style]}>
        <View style={mobileStyles.titleContainer}>
          <Image source={icOrangeHeart as ImageSourcePropType} style={mobileStyles.iconTitle} />
          <Text style={mobileStyles.title}>Giá trị tài khoản nhận về khi cuộc sống bình yên</Text>
        </View>
        <View style={mobileStyles.infoContainer}>
          <View style={mobileStyles.infoContainerHead}>
            <Text style={mobileStyles.heading}>
              <Text>Để gia tăng giá trị tài khoản </Text>
              <View>
                <Text style={{ fontSize: 10, position: 'relative', color: 'red', top: -3 }}>(3)</Text>
              </View>
              <Text>, Khách hàng có thể thực hiện </Text>
              <Text style={mobileStyles.titleHightlight} onPress={onOpenExtraInvestModal}>
                {productTypeChart === 'ILP' ? 'Đầu tư thêm' : 'Tích lũy thêm'}
              </Text>
            </Text>
            <TouchableOpacity onPress={onOpenExtraInvestModal} disabled={!editable} style={mobileStyles.iconArrow}>
              <Image source={imgArrowRightCircleRedLight} style={globalStyles.Icon20} />
            </TouchableOpacity>
          </View>

          {extraInvest && extraInvest !== 0 ? (
            <View style={mobileStyles.investBoxWrapper}>
              <View style={mobileStyles.investBox}>
                <Text>
                  Giá trị {productTypeChart === 'ILP' ? 'Đầu tư' : 'Tích lũy'}{' '}
                  <Text style={mobileStyles.investNumber}>{`${vnNumberFormatter.format(extraInvest ?? 0)} ${i18n.t(
                    'COMMON.MILLION_VND'
                  )}`}</Text>
                </Text>
              </View>
            </View>
          ) : null}
        </View>
        {productTypeChart === 'ILP' && (
          <View style={mobileStyles.DisplayFlexRow}>
            <View style={mobileStyles.tooltipsCharts}>
              <Image source={icRate as ImageSourcePropType} style={mobileStyles.IconChart} />
              <Text style={mobileStyles.tooltips}>Tỷ lệ đầu tư vào mỗi Quỹ PruLink</Text>
              <Text style={[globalStyles.NoteText, { position: 'relative', top: -5, left: -10 }]}>(4)</Text>
            </View>
            <View style={mobileStyles.tooltipsCharts}>
              <Text style={mobileStyles.tooltipsCustom}>
                Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                <TouchableOpacity onPress={onOpenProfitModal} disabled={!editable}>
                  <Text style={globalStyles.CommonSubTitleNote}>(Xem thêm)</Text>
                </TouchableOpacity>
                <View>
                  <Text style={[globalStyles.NoteText, { position: 'relative', top: -5, left: -3 }]}> (5)</Text>
                </View>
              </Text>
            </View>
            <View style={mobileStyles.tooltipsCharts}>
              <Image source={icCirce as ImageSourcePropType} style={mobileStyles.IconChart} />
              <Text style={mobileStyles.tooltips}>
              Mức lãi suất các quỹ PruLink dùng để minh họa "Số tiền nhận về               
                <View>
                  <Text style={[globalStyles.NoteText, { position: 'relative', top: -5, left: 0 }]}>(6)<Text style={[{color: '#1F1F20' }]}>"</Text></Text>
        
                </View>
              </Text>
            </View>
          </View>
        )}
        <View style={{ width: '100%', marginTop: 10 }}>
          {productTypeChart === 'ILP' && (
            <>
              <View style={mobileStyles.infoContainerChart}>
                <InputChart
                  title="Cổ phiếu VN"
                  quotationKey="vietNamEquity"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  onChange={updateQuotation}
                  bottomNumber={9.0}
                  value={quotationData?.vietNamEquity}
                />
                <InputChart
                  title="Tăng trưởng"
                  quotationKey="growth"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.7}
                  onChange={updateQuotation}
                  value={quotationData?.growth}
                />
                <InputChart
                  title="Cân bằng"
                  quotationKey="balanced"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.5}
                  onChange={updateQuotation}
                  value={quotationData?.balanced}
                />
              </View>
              <View style={mobileStyles.infoContainerChart}>
                <InputChart
                  title="Bền vững"
                  quotationKey="stable"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.3}
                  onChange={updateQuotation}
                  value={quotationData?.stable}
                />
                <InputChart
                  title="Trái phiếu VN"
                  quotationKey="vietNamBond"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.0}
                  onChange={updateQuotation}
                  value={quotationData?.vietNamBond}
                />
                <InputChart
                  title="Bảo toàn"
                  quotationKey="preserved"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={7.0}
                  onChange={updateQuotation}
                  value={quotationData?.preserved}
                />
              </View>
              <View style={mobileStyles.submitContent}>
                <View>
                  <Text>
                    Giá trị còn lại: <Text style={desktopStyles.totalOtherPercent}>{renderTotal()}%</Text>
                  </Text>
                </View>
                <FilledButton
                  title={'Xác nhận'}
                  onPress={onPress}
                  buttonStyle={desktopStyles.buttonConfirm}
                  titleStyle={{ fontSize: 15 }}
                />
              </View>
            </>
          )}
          <View style={mobileStyles.mobileHelpText}>
            <Text style={{ fontSize: 13 }}>Đơn vị tính: Triệu đồng</Text>
          </View>
        </View>
        <View style={{ width: '100%'}}>
          <TouchableOpacity onPress={onOpenPopupChart} style={{ width: '100%', zIndex: 999, height: '100%', position: 'relative' }}>
            <ColumnChart
              width={'100%'}
              height={250}
              extraInvest={extraInvest ? true : false}
              chartInvesment={extraInvest ? dataChartInvest : patternChartDataTopUp}
              data={chartData}
              maxColumnWidth={30}
              defaultColumnWidthInvest={extraInvest ? 28 : 20}
              productType={productTypeChart === 'ILP' ? '(6)' : '(4)'}
              defaultColumnWidth={extraInvest ? 20 : 28}
              loading={isLoadingChartData}
            />
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  if (isTabletSmallDevice && Dimensions.get('window').height > Dimensions.get('window').width) {
    return (
      <View style={[tabletStyles.container, style]}>
        <View style={tabletStyles.titleContainer}>
          <Image source={icOrangeHeart as ImageSourcePropType} style={mobileStyles.iconTitle} />
          <Text style={mobileStyles.title}>Giá trị tài khoản nhận về khi cuộc sống bình yên</Text>
        </View>
        <View style={mobileStyles.infoContainer}>
          <View style={mobileStyles.infoContainerHead}>
            <Text style={mobileStyles.heading}>
              Để gia tăng giá trị tài khoản{' '}
              <Text style={[globalStyles.RedText, { fontSize: 12, bottom: 9, left: -3, position: 'relative' }]}>
                (3)
              </Text>
              , Khách hàng có thể thực hiện
              <TouchableOpacity onPress={onOpenExtraInvestModal} disabled={!editable}>
                <Text style={mobileStyles.titleHightlight}>
                  {productTypeChart === 'ILP' ? 'Đầu tư thêm' : 'Tích lũy thêm'}
                </Text>
              </TouchableOpacity>
            </Text>
          </View>

          {extraInvest && extraInvest !== 0 ? (
            <View style={mobileStyles.investBoxWrapper}>
              <View style={mobileStyles.investBox}>
                <Text>
                  Giá trị {productTypeChart === 'ILP' ? 'Đầu tư' : 'Tích lũy'}{' '}
                  <Text style={mobileStyles.investNumber}>{`${vnNumberFormatter.format(extraInvest ?? 0)} ${i18n.t(
                    'COMMON.MILLION_VND'
                  )}`}</Text>
                </Text>
              </View>
            </View>
          ) : null}
        </View>
        {productTypeChart === 'ILP' && (
          <View style={mobileStyles.DisplayFlexRow}>
            <View style={mobileStyles.tooltipsCharts}>
              <Image source={icRate as ImageSourcePropType} style={mobileStyles.IconChart} />
              <Text style={mobileStyles.tooltips}>Tỷ lệ đầu tư vào mỗi Quỹ PruLink</Text>
              <Text style={[globalStyles.NoteText, { position: 'relative', top: -5, left: -10 }]}>(4)</Text>
            </View>
            <View style={mobileStyles.tooltipsCharts}>
              <Image source={icCirce as ImageSourcePropType} style={mobileStyles.IconChart} />
              <Text style={mobileStyles.tooltips}>
                Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                <TouchableOpacity onPress={onOpenProfitModal} disabled={!editable}>
                  <Text style={globalStyles.CommonSubTitleNote}>(Xem thêm)</Text>
                </TouchableOpacity>
                <View>
                  <Text style={[globalStyles.NoteText, { position: 'relative', top: -5, left: 3 }]}> (5)</Text>
                </View>
              </Text>
            </View>
          </View>
        )}
        <View>
          {productTypeChart === 'ILP' && (
            <>
              {' '}
              <View style={mobileStyles.infoContainerChart}>
                <InputChart
                  title="Cổ phiếu VN"
                  quotationKey="vietNamEquity"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  onChange={updateQuotation}
                  bottomNumber={9.0}
                  value={quotationData?.vietNamEquity}
                />
                <InputChart
                  title="Tăng trưởng"
                  quotationKey="growth"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.7}
                  onChange={updateQuotation}
                  value={quotationData?.growth}
                />
                <InputChart
                  title="Cân bằng"
                  quotationKey="balanced"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.5}
                  onChange={updateQuotation}
                  value={quotationData?.balanced}
                />
              </View>
              <View style={mobileStyles.infoContainerChart}>
                <InputChart
                  title="Bền vững"
                  quotationKey="stable"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.3}
                  onChange={updateQuotation}
                  value={quotationData?.stable}
                />
                <InputChart
                  title="Trái phiếu VN"
                  quotationKey="vietNamBond"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={8.0}
                  onChange={updateQuotation}
                  value={quotationData?.vietNamBond}
                />
                <InputChart
                  title="Bảo toàn"
                  quotationKey="preserved"
                  type="input"
                  style={mobileStyles.infoContainerBox}
                  bottomNumber={7.0}
                  onChange={updateQuotation}
                  value={quotationData?.preserved}
                />
              </View>
              <View style={desktopStyles.submitContent}>
                <View>
                  <Text style={desktopStyles.totalOther}>
                    Giá trị còn lại: <Text style={desktopStyles.totalOtherPercent}>{renderTotal()}%</Text>
                  </Text>
                </View>
                <FilledButton
                  title={'Xác nhận'}
                  onPress={onPress}
                  buttonStyle={desktopStyles.buttonConfirm}
                  titleStyle={{ fontSize: 15 }}
                />
              </View>
            </>
          )}
        </View>
        <ColumnChart
          width={'100%'}
          height={250}
          extraInvest={extraInvest ? true : false}
          data={chartData}
          maxColumnWidth={70}
          defaultColumnWidthInvest={extraInvest ? 64 : 40}
          chartInvesment={extraInvest ? dataChartInvest : patternChartDataTopUp}
          productType={productTypeChart === 'ILP' ? '(6)' : '(4)'}
          defaultColumnWidth={extraInvest ? 40 : 64}
          loading={isLoadingChartData}
        />
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={[tabletStyles.container, style]}>
        <View style={tabletStyles.titleContainer}>
          <Image source={icOrangeHeart as ImageSourcePropType} style={tabletStyles.iconTitle} />
          <Text style={tabletStyles.title}>Giá trị tài khoản nhận về khi cuộc sống bình yên</Text>
        </View>
        <View style={tabletStyles.infoContainer}>
          <View style={tabletStyles.headWraper}>
            <Text style={tabletStyles.headingTablet}>
              Để gia tăng giá trị tài khoản{' '}
              <Text style={[globalStyles.RedText, { fontSize: 12, bottom: 9, left: -3, position: 'relative' }]}>
                (3)
              </Text>
              , Khách hàng có thể thực hiện
              <TouchableOpacity onPress={onOpenExtraInvestModal} disabled={!editable}>
                <Text style={tabletStyles.titleHightlightTablet}>
                  {productTypeChart === 'ILP' ? 'Đầu tư thêm' : 'Tích lũy thêm'}
                </Text>
              </TouchableOpacity>
              {extraInvest && extraInvest !== 0 ? (
                <View style={tabletStyles.investBox}>
                  <Text>
                    Giá trị {productTypeChart === 'ILP' ? 'Đầu tư' : 'Tích lũy'}{' '}
                    <Text style={tabletStyles.investNumber}>{`${vnNumberFormatter.format(extraInvest ?? 0)} ${i18n.t(
                      'COMMON.MILLION_VND'
                    )}`}</Text>
                  </Text>
                </View>
              ) : null}
            </Text>
          </View>
        </View>
        <SizedBox height={20} />
        {productTypeChart === 'ILP' && (
          <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
            <View style={[globalStyles.DisplayFlexRow, globalStyles.VerticalCenter]}>
              <Image source={icRate as ImageSourcePropType} style={mobileStyles.IconChart} />
              <SizedBox width={8} />
              <Text style={globalStyles.CommonSubTitleTablet}>Tỷ lệ đầu tư vào mỗi Quỹ PruLink</Text>
              <Text style={[globalStyles.RedText, { fontSize: 12 }]}> (4)</Text>
            </View>
            <View
              style={[
                globalStyles.Flex1,
                globalStyles.DisplayFlexCol,
                globalStyles.VerticalCenter,
                globalStyles.FloatRight,
              ]}
            >
              <View
                style={[
                  globalStyles.Flex1,
                  globalStyles.DisplayFlexRow,
                  globalStyles.VerticalCenter,
                  globalStyles.FloatRight,
                  globalStyles.marginBottom10,
                ]}
              >
                <SizedBox width={8} />
                <Text style={globalStyles.CommonSubTitleTablet}>
                  Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                  <TouchableOpacity onPress={onOpenProfitModal} disabled={!editable}>
                    <Text style={globalStyles.CommonSubTitleNote}>(Xem thêm)</Text>
                  </TouchableOpacity>
                  <Text style={[globalStyles.RedText, { fontSize: 12, top: -4, left: -1 ,position: 'relative', fontWeight: '400' }]}> (5)</Text>
                </Text>
              </View>
              <View
                style={[
                  globalStyles.Flex1,
                  globalStyles.DisplayFlexRow,
                  globalStyles.VerticalCenter,
                  globalStyles.FloatRight,
                  globalStyles.marginBottom10,
                ]}
              >
                <Image source={icCirce as ImageSourcePropType} style={mobileStyles.IconChart} />
                <SizedBox width={8} />
                <Text style={globalStyles.CommonSubTitleTablet}>
                  Mức lãi suất các quỹ PruLink dùng để minh họa "Số tiền nhận về
                </Text>
                <Text style={[globalStyles.RedText, { fontSize: 12, marginBottom: 8 }]}> (6)</Text>
                <Text style={globalStyles.CommonSubTitleTablet}>"</Text>
              </View>
            </View>
          </View>
        )}
        {productTypeChart === 'ILP' && (
          <>
            {' '}
            <View style={desktopStyles.infoContainerChart}>
              <InputChart
                title="Cổ phiếu VN"
                quotationKey="vietNamEquity"
                type="input"
                style={desktopStyles.infoContainerBox}
                onChange={updateQuotation}
                bottomNumber={9.0}
                value={quotationData?.vietNamEquity}
              />
              <InputChart
                title="Tăng trưởng"
                quotationKey="growth"
                type="input"
                style={desktopStyles.infoContainerBox}
                bottomNumber={8.7}
                onChange={updateQuotation}
                value={quotationData?.growth}
              />
              <InputChart
                title="Cân bằng"
                quotationKey="balanced"
                type="input"
                style={desktopStyles.infoContainerBox}
                bottomNumber={8.5}
                onChange={updateQuotation}
                value={quotationData?.balanced}
              />
              <InputChart
                title="Bền vững"
                quotationKey="stable"
                type="input"
                style={desktopStyles.infoContainerBox}
                bottomNumber={8.3}
                onChange={updateQuotation}
                value={quotationData?.stable}
              />
              <InputChart
                title="Trái phiếu VN"
                quotationKey="vietNamBond"
                type="input"
                style={desktopStyles.infoContainerBox}
                bottomNumber={8.0}
                onChange={updateQuotation}
                value={quotationData?.vietNamBond}
              />
              <InputChart
                title="Bảo toàn"
                quotationKey="preserved"
                type="input"
                style={desktopStyles.infoContainerBox}
                bottomNumber={7.0}
                onChange={updateQuotation}
                value={quotationData?.preserved}
              />
            </View>
            <View style={desktopStyles.submitContent}>
              <View>
                <Text style={desktopStyles.totalOther}>
                  Giá trị còn lại: <Text style={desktopStyles.totalOtherPercent}>{renderTotal()}%</Text>
                </Text>
              </View>
              <FilledButton
                title={'Xác nhận'}
                onPress={onPress}
                buttonStyle={desktopStyles.buttonConfirm}
                titleStyle={{ fontSize: 15 }}
              />
            </View>
          </>
        )}

        <ColumnChart
          width={'100%'}
          height={400}
          maxColumnWidth={108}
          data={chartData}
          productType={productTypeChart === 'ILP' ? '(6)' : '(4)'}
          defaultColumnWidthInvest={extraInvest ? 104 : 80}
          defaultColumnWidth={extraInvest ? 80 : 104}
          loading={isLoadingChartData}
          extraInvest={extraInvest ? true : false}
          chartInvesment={extraInvest ? dataChartInvest : patternChartDataTopUp}
        />
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Image source={icOrangeHeart as ImageSourcePropType} style={desktopStyles.iconTitle} />
        <Text style={desktopStyles.title}>Giá trị tài khoản nhận về khi cuộc sống bình yên</Text>
      </View>
      <View style={desktopStyles.infoContainer}>
        <View style={desktopStyles.headWraper}>
          <Text style={desktopStyles.heading}>
            Để gia tăng giá trị tài khoản{' '}
            <Text style={[globalStyles.RedText, { fontSize: 12, bottom: 9, left: -3, position: 'relative' }]}>(3)</Text>
            , Khách hàng có thể thực hiện
            <TouchableOpacity onPress={onOpenExtraInvestModal} disabled={!editable}>
              <Text style={desktopStyles.titleHightlight}>
                {productTypeChart === 'ILP' ? 'Đầu tư thêm' : 'Tích lũy thêm'}
              </Text>
            </TouchableOpacity>
            {extraInvest && extraInvest !== 0 ? (
              <View style={desktopStyles.investBox}>
                <Text>
                  Giá trị {productTypeChart === 'ILP' ? 'Đầu tư' : 'Tích lũy'}{' '}
                  <Text style={desktopStyles.investNumber}>{`${vnNumberFormatter.format(extraInvest ?? 0)} ${i18n.t(
                    'COMMON.MILLION_VND'
                  )}`}</Text>
                </Text>
              </View>
            ) : null}
          </Text>
        </View>
      </View>
      <SizedBox height={20} />
      {productTypeChart === 'ILP' && (
        <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
          <View style={[globalStyles.DisplayFlexRow, globalStyles.VerticalCenter]}>
            <Image source={icRate as ImageSourcePropType} style={mobileStyles.IconChart} />
            <SizedBox width={8} />
            <Text style={globalStyles.CommonSubTitle}>Tỷ lệ đầu tư vào mỗi Quỹ PruLink</Text>
            <Text style={[globalStyles.RedText, { fontSize: 12 }]}> (4)</Text>
          </View>
          <View
            style={[
              globalStyles.Flex1,
              globalStyles.DisplayFlexCol,
              globalStyles.VerticalCenter,
              globalStyles.FloatRight,
            ]}
          >
            <View
              style={[
                globalStyles.Flex1,
                globalStyles.DisplayFlexRow,
                globalStyles.VerticalCenter,
                globalStyles.FloatRight,
                globalStyles.marginBottom10,
              ]}
            >
              <SizedBox width={8} />
              <Text style={globalStyles.CommonSubTitle}>
                Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                <TouchableOpacity onPress={onOpenProfitModal} disabled={!editable}>
                  <Text style={globalStyles.CommonSubTitleNote}>(Xem thêm)</Text>
                </TouchableOpacity>
              </Text>
              <Text style={[globalStyles.RedText, { fontSize: 12 }]}> (5)</Text>
            </View>
            <View
              style={[
                globalStyles.Flex1,
                globalStyles.DisplayFlexRow,
                globalStyles.VerticalCenter,
                globalStyles.FloatRight,
                globalStyles.marginBottom10,
              ]}
            >
              <Image source={icCirce as ImageSourcePropType} style={globalStyles.Icon20} />
              <SizedBox width={8} />
              <Text style={globalStyles.CommonSubTitle}>
                Mức lãi suất các quỹ PruLink dùng để minh họa "Số tiền nhận về
              </Text>
              <Text style={[globalStyles.RedText, { fontSize: 12, marginBottom: 8 }]}> (6)</Text>
              <Text style={globalStyles.CommonSubTitle}>"</Text>
            </View>
          </View>
        </View>
      )}
      {productTypeChart === 'ILP' && (
        <>
          {' '}
          <View style={desktopStyles.infoContainerChart}>
            <InputChart
              title="Cổ phiếu VN"
              quotationKey="vietNamEquity"
              type="input"
              style={desktopStyles.infoContainerBox}
              onChange={updateQuotation}
              bottomNumber={9.0}
              value={quotationData?.vietNamEquity}
            />
            <InputChart
              title="Tăng trưởng"
              quotationKey="growth"
              type="input"
              style={desktopStyles.infoContainerBox}
              bottomNumber={8.7}
              onChange={updateQuotation}
              value={quotationData?.growth}
            />
            <InputChart
              title="Cân bằng"
              quotationKey="balanced"
              type="input"
              style={desktopStyles.infoContainerBox}
              bottomNumber={8.5}
              onChange={updateQuotation}
              value={quotationData?.balanced}
            />
            <InputChart
              title="Bền vững"
              quotationKey="stable"
              type="input"
              style={desktopStyles.infoContainerBox}
              bottomNumber={8.3}
              onChange={updateQuotation}
              value={quotationData?.stable}
            />
            <InputChart
              title="Trái phiếu VN"
              quotationKey="vietNamBond"
              type="input"
              style={desktopStyles.infoContainerBox}
              bottomNumber={8.0}
              onChange={updateQuotation}
              value={quotationData?.vietNamBond}
            />
            <InputChart
              title="Bảo toàn"
              quotationKey="preserved"
              type="input"
              style={desktopStyles.infoContainerBox}
              bottomNumber={7.0}
              onChange={updateQuotation}
              value={quotationData?.preserved}
            />
          </View>
          <View style={desktopStyles.submitContent}>
            <View>
              <Text style={desktopStyles.totalOther}>
                Giá trị còn lại: <Text style={desktopStyles.totalOtherPercent}>{renderTotal()}%</Text>
              </Text>
            </View>
            <FilledButton
              title={'Xác nhận'}
              onPress={onPress}
              buttonStyle={desktopStyles.buttonConfirm}
              titleStyle={{ fontSize: 15 }}
            />
          </View>
        </>
      )}
      <ColumnChart
        width={'100%'}
        height={400}
        maxColumnWidth={108}
        data={chartData}
        productType={productTypeChart === 'ILP' ? '(6)' : '(4)'}
        defaultColumnWidthInvest={extraInvest ? 104 : 80}
        defaultColumnWidth={extraInvest ? 80 : 104}
        loading={isLoadingChartData}
        extraInvest={extraInvest ? true : false}
        chartInvesment={extraInvest ? dataChartInvest : patternChartDataTopUp}
      />
    </View>
  )
}

export default ChartBox

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 18.14,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 20,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  titleHightlight: {
    color: '#ED1B2E',
    fontSize: 20,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    marginHorizontal: 10,
    marginRight: 20,
  },
  heading: {
    color: '#505D6F',
    fontSize: 20,
  },
  totalOther: {
    color: '#505D6F',
    fontWeight: '700',
  },
  totalOtherPercent: {
    color: '#FD907E',
  },
  headingTablet: {
    color: '#505D6F',
    fontSize: 16.5833,
  },
  titleHightlightTablet: {
    color: '#ED1B2E',
    fontSize: 16.5833,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    marginHorizontal: 10,
    marginRight: 20,
  },
  investBox: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    borderRadius: 30,
    fontSize: 18,
    color: '#505D6F',
    backgroundColor: '#fff',
  },
  submitContent: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  investNumber: {
    fontSize: 20,
    paddingVertical: 10,
    fontWeight: '600',
    color: '#1F1F20',
  },
  headWraper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 28.19,
    paddingHorizontal: 31.51,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 24.5,
  },
  infoContainerChart: {
    marginVertical: 30,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  infoContainerBox: {
    width: '16%',
  },
  infoContainerBoxTotal: {
    width: '16%',
    backgroundColor: '#fff',
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 100,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    flex: 1,
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  buttonConfirm: {
    marginTop: 0,
    paddingVertical: 0,
    paddingHorizontal: 21,
    height: 41,
    borderRadius: 10,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 18.14,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 16.5,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  titleHightlight: {
    color: '#ED1B2E',
    fontSize: 20,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    marginHorizontal: 10,
    marginRight: 20,
  },
  heading: {
    color: '#505D6F',
    fontSize: 20,
  },
  headingTablet: {
    color: '#505D6F',
    fontSize: 16.5833,
  },
  titleHightlightTablet: {
    color: '#ED1B2E',
    fontSize: 16.5833,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    marginHorizontal: 10,
    marginRight: 20,
  },
  investBox: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    borderRadius: 30,
    fontSize: 18,
    color: '#505D6F',
    backgroundColor: '#fff',
  },
  investNumber: {
    fontSize: 20,
    paddingVertical: 10,
    fontWeight: '600',
    color: '#1F1F20',
  },
  headWraper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 28.19,
    paddingHorizontal: 31.51,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 24.5,
  },
  infoContainerChart: {
    marginVertical: 30,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  infoContainerBox: {
    width: '16%',
  },
  infoContainerBoxTotal: {
    width: '16%',
    backgroundColor: '#fff',
  },
  totalOther: {
    color: '#505D6F',
    fontSize: 12,
    fontWeight: '700',
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 100,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    flex: 1,
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  buttonConfirm: {
    marginTop: 0,
    paddingVertical: 0,
    paddingHorizontal: 21,
    height: 41,
    borderRadius: 10,
  },
})

const mobileStyles = StyleSheet.create({
  infoContainerChart: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  mobileHelpText: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 20,
  },
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoContainerHead: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  heading: {
    color: '#505D6F',
    fontSize: 14,
    width: '90%',
    maxWidth: '90%',
    lineHeight: 20,
  },
  submitContent: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  iconArrow: {
    width: '5%',
    alignItems: 'center',
  },
  infoContainerBox: {
    width: '32%',
    padding: 10,
  },
  infoContainerBoxTotal: {
    width: '32%',
    padding: 10,
    backgroundColor: '#fff',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  titleHightlight: {
    color: '#ED1B2E',
    fontSize: 14,
    marginLeft: 3,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#FAFBFF',
    borderRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 14,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 8,
  },
  infoContainerTabet: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    borderRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 14,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 8,
  },
  infoItemContainer: {
    paddingVertical: 9,
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    flex: 1,
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  tooltipsCharts: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
  tooltips: {
    fontSize: 13,
    fontWeight: '600',
    paddingHorizontal: 10,
    flexWrap: 'nowrap',
  },
  tooltipsCustom: {
    fontSize: 13,
    fontWeight: '600',
    paddingLeft: 26,
    flexWrap: 'nowrap',
  },
  IconChart: {
    height: 22,
    width: 16,
    resizeMode: 'contain',
  },
  DisplayFlexRow: {
    flexDirection: 'column',
    marginVertical: 10,
  },
  investBoxWrapper: {
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  investBoxWrapperTablet: {
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  investBox: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    borderRadius: 30,
    fontSize: 13,
    color: '#505D6F',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  investBoxTablet: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    borderRadius: 30,
    fontSize: 13,
    color: '#505D6F',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  investNumber: {
    fontSize: 14,
    paddingVertical: 10,
    fontWeight: 'bold',
    color: '#1F1F20',
  },
})
