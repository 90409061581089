import React, { useEffect, memo } from 'react'
import {
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  Image,
  ImageSourcePropType,
} from 'react-native'
import {
  styles as globalStyles,
  SideTabView,
  FilledButton,
  CustomScrollView,
  Dialog,
  SizedBox,
  TabBarView,
  StepButton,
  ValidateRequiredError,
  vnNumberFormatter,
} from '../common'
import { dataScreen, productTypes } from '../constants/Data'
import { saveCNA } from '../slices/cnaSlice'
import EditProfileTab from '../components/EditProfileTab'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { updateProfile, clearCart } from '../slices/needsCartSlice'
import { getCNADetail } from '../slices/cnaSlice'
import { RootStackScreenProps } from '../types'
import AnalysisScreen from './AnalysisScreen'
import SafeLifeScreen from './SafeLifeScreen'
import InvestAssetScreen from './InvestAssetScreen'
import SafeAssetScreen from './SafeAssetScreen'
import SafeEduScreen from './SafeEduScreen'
import SaveCancerScreen from './SaveCancerScreen'
import SaveCharityScreen from './SaveCharityScreen'
import SaveEduScreen from './SaveEduScreen'
import SaveHealthScreen from './SaveHealthScreen'
import SaveInheritScreen from './SaveInheritScreen'
import SaveRetireScreen from './SaveRetireScreen'
import { updateUserProtect, updateUserProtectOriginal } from '../slices/userSlice'
import { ViewMode } from '../constants/Enum'
import StepAppHeader from '../components/StepAppHeader'
import { useIsFocused } from '@react-navigation/native'
import { CreateCnaPageStep, getStatusToSave } from '../helper/CnaStatusWorkFlow'
import i18n from 'i18n-js'
import MobileStepIndicator from '../components/MobileStepIndicator'
import CommonAppBar from '../components/CommonAppBar'
import Layout from '../constants/Layout'
import ConfirmSavePopUp from '../components/ConfirmSavePopUp'
import _ from 'lodash'
import { MIN_NEED_TERM } from 'constants/Constants'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Colors from '../common/constants/Colors'
import imgCalendar2 from '../assets/images/calendar-2.png'
import { DataScreenType } from 'model/DataScreenType'
import { RadioListTitle } from '../common/components/RadioListTitle'
import LinearGradientBackground from '../common/components/LinearGradientBackground'
import icCircleDollar from '../assets/images/circle-dollar.png'
import dataService from '../services/DataService'
import { i18nAgentWording } from '../components/AgentWording'

const mapStateToProps = (state: RootState) => ({
  needsCartList: state.needsCart.needsCartList,
  profileInfo: state.needsCart.profileInfo,
  userInfo: state?.user?.userInfo,
  userProtect: state.user.userProtect,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
  userProtectOriginal: state.user.userProtectOriginal,
  loadingSaveDraft: state?.cna.loadingCnaDetail,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
})

const mapDispatchToProps = {
  updateProfile,
  getCNADetail,
  saveCNA,
  clearCart,
  updateUserProtect,
  updateUserProtectOriginal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'CustomerNeed'> &
  PropsFromRedux & {
    onClearData?: () => void
    children?: React.ReactNode
    screenActive?: string
    agentCode?: string
    dataUpdateScreen: (value: any) => void
  }

function CustomerNeedScreen({
  route,
  saveCNA,
  userInfo,
  navigation,
  userProtect,
  getCNADetail,
  needsCartList,
  loadingSaveDraft,
  currentHandlingCNA,
  userProtectOriginal,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  const [dataScreens, setDataScreen] = React.useState<DataScreenType[] | undefined>(dataScreen)
  const [screen, setScreen] = React.useState(undefined)
  const [showCancelWarningModel, setShowCancelWarningModel] = React.useState(false)
  const [showConfirmLeavingAlert, setShowConfirmLeavingAlert] = React.useState(false)
  const [showExpectationPopUp, setShowExpectationPopUp] = React.useState(false)
  const [confirm, setConfirm] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const isFocused = useIsFocused()
  const [productType, setProductType] = React.useState<{ value: string; text: string } | undefined>()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  })

  useEffect(() => {
    const params = {
      id: (route.params as any)?.id,
    }
    if ((route.params as any)?.id && isFocused) {
      getCNADetail(params)
    }
  }, [isFocused])

  useEffect(() => {
    setProductType(productTypes.find((e) => e.value === currentHandlingCNA?.product))
    setValue('product', currentHandlingCNA?.product ? currentHandlingCNA?.product : undefined)
    setValue('needTerm', currentHandlingCNA?.needTerm ? currentHandlingCNA?.needTerm : undefined)
  }, [currentHandlingCNA])

  useEffect(() => {
    if (userProtect) {
      if (checkTotal() === true) {
        setConfirm(false)
      } else {
        setConfirm(true)
      }
    }
  }, [userProtect])

  function checkDataHaveBeenSaved(object1: any, object2: any) {
    const keys1 = Object.keys(object1)

    for (let key of keys1) {
      if (object1[key]?.subTotal !== object2[key]?.subTotal) {
        return false
      }
    }
    return true
  }

  function onBack() {
    navigation.replace('NeedsCart', { ...(route.params as any) })
  }

  function renderErrorMessage(error?: string, style?: any) {
    if (error) {
      return (
        <View style={globalStyles.FloatRight}>
          <ValidateRequiredError title={error} style={style} />
        </View>
      )
    } else {
      return <></>
    }
  }

  async function checkDiff(object1: any, object2: any) {
    const keys1 = Object.keys(object1)
    const objectKey = { ...object1 }

    for (let key of keys1) {
      if (object1[key]?.subTotal !== object2[key]?.subTotal || (object1[key]?.total === 0 && object1[key]?.subTotal)) {
        objectKey[key] =
          key !== 'hcPlan'
            ? {
                ...objectKey[key],
                isSelect: true,
                subTotal: objectKey[key]?.subTotal,
                total: objectKey[key]?.subTotal,
              }
            : {
                ...objectKey[key],
                hcPlanDetail: objectKey[key]?.hcPlanDetailSub,
                isSelect: true,
                subTotal: objectKey[key]?.subTotal,
                total: objectKey[key]?.subTotal,
              }
      }
    }

    let cnaData = {
      ...objectKey,
      lastEditStep:
        objectKey?.lastEditStep && objectKey?.lastEditStep > 5 && checkDataHaveBeenSaved(currentHandlingCNA, objectKey)
          ? object2?.lastEditStep
          : 5,
      id: objectKey?._id,
      product: productType?.value,
      needTerm: parseInt(getValues('needTerm')),
    }

    // If user changed product type, need to reset topup, quotation, ape value
    if (productType?.value !== currentHandlingCNA?.product) {
      cnaData = {
        ...cnaData,
        topUp: 0,
        quotations: null,
        ape: 0,
        apeSub: 0,
      }
    }

    return cnaData
  }

  function onSubmitExpectationValue() {
    setShowExpectationPopUp(false)
    onSubmit()
  }

  async function onSubmit() {
    setLoading(true)
    const cnaData = await checkDiff(userProtect, userProtectOriginal)
    await saveCNA(cnaData)
    const id = (route.params as any)?.id
    if (!id) {
      navigation.navigate('NeedsCart', route.params)
      setTimeout(() => {
        navigation.replace('NeedsOverall', route.params)
      }, 100)
    } else {
      navigation.setParams({ ...(route.params as any) })
      navigation.replace('NeedsOverall', { ...(route.params as any) })
    }
  }

  function onGoToNeedOverallScreen() {}

  function onUpdateProduct(key?: string) {
    let product = productTypes.find((e) => e.value === key)
    setProductType(product)
    setValue('product', product?.value)
  }

  function getTotalValueInMillions() {
    return dataService.countNeedTotalSub(userProtect)
  }

  function checkTotal() {
    let checkVadilateEdu = true
    userProtect?.eduPlan?.childrenDetails &&
      userProtect?.eduPlan?.childrenDetails.every((x: any) => {
        checkVadilateEdu = x.isSchool ? x.schoolFees !== 0 && x.schoolYears !== 0 : true
        if (!checkVadilateEdu) {
          return
        }
        checkVadilateEdu = x.isUniversity ? x.universityFees !== 0 && x.universityYears !== 0 : true
        if (!checkVadilateEdu) {
          return
        }
        checkVadilateEdu = x.isAfterUni ? x.afterUniFees !== 0 && x.afterUniYears !== 0 : true
        if (!checkVadilateEdu) {
          return
        }
        return true
      })

    const checkProtectEdu =
      userProtect?.protectEdu?.childrenDetails &&
      userProtect?.protectEdu?.childrenDetails?.some(function (x: any) {
        if (Number(x.childAge) > 0 && Number(x.childAge) < Number(userProtect?.protectEdu?.eduPlanAge)) {
          return true
        } else {
          return false
        }
      })
    const checkSaveEdu =
      userProtect?.eduPlan?.childrenDetails && userProtect?.eduPlan?.childrenDetails?.some((x: any) => x.childAge === 0)
    if (Number(userProtect?.hcFatalDisease?.subTotal) < 100 && userProtect?.hcFatalDisease?.isTemporaryCheck === true) {
      return false
    }
    if (
      (Number(userProtect?.protectLife?.subTotal) <= 0 && userProtect?.protectLife?.isTemporaryCheck === true) ||
      (!userProtect?.protectLife?.month && userProtect?.protectLife?.isTemporaryCheck === true) ||
      (userProtect?.protectLife?.isSubsidy === true && !userProtect?.protectLife?.monthlySubsidy) ||
      (userProtect?.protectLife?.monthlyFixedExpense &&
        userProtect?.protectIncome?.monthlyIncome &&
        Number(userProtect?.protectIncome?.monthlyIncome) < Number(userProtect?.protectLife?.monthlyFixedExpense)) ||
      Number(userProtect?.protectLife?.monthlyFixedExpense) > 10000 ||
      (userProtect?.protectLife?.isSubsidy === true && !userProtect?.protectLife?.subsidyMonth)
    ) {
      return false
    }
    if (
      (Number(userProtect?.protectIncome?.subTotal) <= 0 && userProtect?.protectIncome?.isTemporaryCheck === true) ||
      (userProtect?.protectLife?.monthlyFixedExpense &&
        userProtect?.protectIncome?.monthlyIncome &&
        Number(userProtect?.protectIncome?.monthlyIncome) < Number(userProtect?.protectLife?.monthlyFixedExpense)) ||
      Number(userProtect?.protectIncome?.monthlyIncome) > 10000
    ) {
      return false
    }

    if (Number(userProtect?.hcPlan?.subTotal) <= 0 && userProtect?.hcPlan?.isTemporaryCheck === true) {
      return false
    }
    if (
      (Number(userProtect?.retirement?.subTotal) <= 0 && userProtect?.retirement?.isTemporaryCheck === true) ||
      (Number(userProtect?.retirement?.countDown) <= 0 && userProtect?.retirement?.isTemporaryCheck === true) ||
      (Number(userProtect?.retirement?.countDown) > 100 && userProtect?.retirement?.isTemporaryCheck === true)
    ) {
      return false
    }
    if (
      (((Number(userProtect?.investment?.subTotal) <= 0 && userProtect?.investment?.isTemporaryCheck === true) ||
        !userProtect?.investment?.year) &&
        userProtect?.investment?.isTemporaryCheck === true) ||
      (userProtect?.investment?.isPrepare === true &&
        Number(userProtect?.investment?.saveMoney) >= Number(userProtect?.investment?.money) &&
        userProtect?.investment?.isTemporaryCheck === true) ||
      (userProtect?.investment?.isPrepare === true &&
        Number(userProtect?.investment?.saveMoney) === 0 &&
        userProtect?.investment?.isTemporaryCheck === true)
    ) {
      return false
    }
    if (Number(userProtect?.inherit?.subTotal) <= 0 && userProtect?.inherit?.isTemporaryCheck === true) {
      return false
    }
    if (Number(userProtect?.charity?.subTotal) <= 0 && userProtect?.charity?.isTemporaryCheck === true) {
      return false
    }
    if (
      (Number(userProtect?.protectEdu?.subTotal) <= 0 && userProtect?.protectEdu?.isTemporaryCheck === true) ||
      checkProtectEdu === false ||
      (userProtect?.protectEdu?.childrenDetails &&
        userProtect?.protectEdu?.childrenDetails.some((x: any) => Number(x.tuition || 0) === 0))
    ) {
      return false
    }
    if (
      (Number(userProtect?.protectAsset?.subTotal) <= 0 && userProtect?.protectAsset?.isTemporaryCheck === true) ||
      (userProtect?.protectAsset?.collateral?.isCheck === true && !userProtect?.protectAsset?.collateral?.total) ||
      (userProtect?.protectAsset?.saving?.isCheck === true && !userProtect?.protectAsset?.saving?.total) ||
      (userProtect?.protectAsset?.otherAssets?.isCheck === true && !userProtect?.protectAsset?.otherAssets?.total)
    ) {
      return false
    }
    if (
      (checkSaveEdu === true && userProtect?.eduPlan?.isSelect === true) ||
      (checkSaveEdu === null && userProtect?.eduPlan?.isSelect === true) ||
      (!checkVadilateEdu && userProtect?.eduPlan?.isSelect === true) ||
      (Number(userProtect?.eduPlan?.subTotal) <= 0 && userProtect?.eduPlan?.isTemporaryCheck === true)
    ) {
      return false
    }

    return true
  }

  useEffect(() => {
    if (needsCartList.length > 1 && needsCartList) {
      const dataScreen = needsCartList
        ?.map((x) => x.data)
        ?.reduce((prev?: any[], next?: any[]) =>
          prev?.concat(next).filter((y: any) => y.isChecked || y.isTemporaryCheck)
        )
        ?.filter((x) => x.isChecked || x.isTemporaryCheck)
      setDataScreen(dataScreen)
      if (dataScreen && dataScreen.length > 0 && dataScreen[0]?.screen) {
        setScreen(dataScreen[0].screen)
      } else {
        navigation.replace('NeedsCart', { ...(route.params as any) })
      }
    }
  }, [needsCartList])

  function checkEditable() {
    return (route.params as any)?.mode !== ViewMode.READ_ONLY
  }

  function getDataScreen(value: any) {
    setScreen(value)
  }

  async function onSaveDraft(isSavingDraft?: boolean, isSavingContinue?: boolean) {
    // const dataCna = await checkDiff(userProtect, userProtectOriginal)
    let cnaData = {
      ...userProtect,
      isSavingDraft: isSavingDraft,
      isSavingContinue: isSavingContinue,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.ANALYZE_NEEDS,
        currentHandlingCNA?.customerPhone,
        isSavingDraft
      ),
    } as any
    return saveCNA(cnaData)
  }

  function renderMobileAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('NEEDS_CART.NEW')}
        navigation={navigation}
        onBack={onBack}
        onGoHome={() => onSaveDraft(true)}
      />
    )
  }

  function renderLoading() {
    return (
      <View style={[desktopStyles.loading]}>
        <ActivityIndicator color="#ED1B2E" style={[desktopStyles.loading]} size="large" />
      </View>
    )
  }

  function renderModals() {
    return (
      <>
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showCancelWarningModel}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.KEEP_CANCEL')}
          cancelText={i18n.t('COMMON.CONTINUE')}
          onOk={() => {
            setShowCancelWarningModel(false)
            navigation.navigate('NeedsCart')
          }}
          onCancel={() => {
            setShowCancelWarningModel(false)
          }}
        ></Dialog>
        {/* Selected product type and need term pop up */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showExpectationPopUp}
          contentStyle={{}}
          dialogStyle={{}}
          onClose={() => setShowExpectationPopUp(false)}
        >
          <EditProtectExpectation />
        </Dialog>
      </>
    )
  }
  const EditProtectExpectation = () => {
    if (isMobileDevice) {
      return (
        <View style={[mobileStyles.needsDataContainerBlock]}>
          <View style={[mobileStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
            <View style={mobileStyles.inputInfoContainer}>
              <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={mobileStyles.highlightTitleContainer}>
                  <Image source={icCircleDollar as ImageSourcePropType} style={mobileStyles.dollarIcon} />
                  <Text style={mobileStyles.highlightTitleLabel}>{i18n.t('CUSTOMER_NEED.YOUR_NEED_TOTAL')}&#58;</Text>
                  <Text style={mobileStyles.moneyTotal}>
                    &nbsp;&nbsp;{vnNumberFormatter.format(getTotalValueInMillions())}&nbsp;
                  </Text>
                  <Text style={[mobileStyles.unit, {color: '#FFFFFF'}]}>
                    {i18n.t('COMMON.MILLION_VND_MOBILE')}
                  </Text>
                </LinearGradientBackground>
                <SizedBox height={8} />
                <Text style={mobileStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INVESTMENT_EXPECTATION')}</Text>
                <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.AlignItemsRight]}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: i18n.t('VALIDATE.REQUIRED'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <RadioListTitle
                        title=""
                        data={productTypes}
                        direction="column"
                        defaultValue={productType?.value}
                        onChange={(key: string) => onUpdateProduct(key)}
                        containerStyle={{
                          width: '100%',
                          marginTop: 0,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                        radioItemContainerStyle={{
                          minWidth: 'none',
                          maxWidth: 'none',
                          width: 'fit-content',
                          marginTop: 16,
                          justifyContent: 'flex-start',
                          marginRight: 16,
                        }}
                        error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                      />
                    )}
                    name="product"
                    defaultValue=""
                  />
                </View>
              </View>
              <SizedBox height={20} />
              <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                <Text style={mobileStyles.inputBlockTitle}>
                  {i18nAgentWording({agentOfficeCode: userInfo?.officeCode, wordingCode: 'NEEDS_CART.NEED_TERM'})}
                </Text>
                <SizedBox height={16} />
                <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                  <View style={mobileStyles.inputBlockContainer}>
                    <View style={mobileStyles.inputBlockContainerLeft}>
                      <Image source={imgCalendar2} style={globalStyles.Icon26} />
                      <Controller
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: i18n.t('VALIDATE.REQUIRED'),
                          },
                          min: {
                            value: MIN_NEED_TERM,
                            message: i18n.t('VALIDATE.MIN_PLAN_TERM'),
                          },
                        }}
                        render={({ onChange, onBlur, value }) => (
                          <NumberFormat
                            placeholder={i18n.t('NEEDS_CART.NEED_TERM_HINT')}
                            style={{
                              fontSize: 13,
                              minWidth: '300px',
                              marginLeft: 8,
                              borderWidth: 0,
                              borderColor: '#fff',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            value={value}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={3}
                            type="text"
                            prefix={''}
                            onValueChange={(values: any) => {
                              const { value } = values
                              onChange(value)
                              setValue('needTerm', value)
                            }}
                            renderText={(value) => (
                              <TextInput
                                underlineColorAndroid="transparent"
                                placeholderTextColor="#E0E0E0"
                                onChangeText={(value) => {
                                  onChange(value)
                                  setValue('needTerm', value)
                                }}
                                value={value}
                                keyboardType="numeric"
                              />
                            )}
                          />
                        )}
                        name="needTerm"
                        defaultValue=""
                      />
                    </View>
                    <View style={mobileStyles.inputBlockContainerRight}>
                      <Text style={mobileStyles.unit}>{i18n.t('COMMON.YEAR')}</Text>
                    </View>
                  </View>
                  {renderErrorMessage(errors?.needTerm?.message)}
                </View>
              </View>
              <View style={[globalStyles.FullWidth, globalStyles.Center]}>
                <FilledButton
                  type="small"
                  title={i18n.t('COMMON.ACCEPT').toUpperCase()}
                  onPress={handleSubmit(onSubmitExpectationValue)}
                />
              </View>
            </View>
          </View>
        </View>
      )
    }

    if (isTabletDevice) {
      return (
        <View style={[tabletStyles.needsDataContainerBlock]}>
          <View style={[tabletStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
            <View style={tabletStyles.inputInfoContainer}>
              <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={tabletStyles.highlightTitleContainer}>
                  <Image source={icCircleDollar as ImageSourcePropType} style={tabletStyles.dollarIcon} />
                  <Text style={tabletStyles.highlightTitleLabel}>{i18n.t('CUSTOMER_NEED.YOUR_NEED_TOTAL')}&#58;</Text>
                  <Text style={tabletStyles.moneyTotal}>
                    &nbsp;&nbsp;{vnNumberFormatter.format(getTotalValueInMillions())}&nbsp;
                  </Text>
                  <Text style={[tabletStyles.unit, { color: '#FFF' }]}>
                    {i18n.t('COMMON.MILLION_VND')}
                  </Text>
                </LinearGradientBackground>
                <SizedBox height={40} />
                <Text style={tabletStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INVESTMENT_EXPECTATION')}</Text>
                <SizedBox height={8} />
                <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.AlignItemsRight]}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: i18n.t('VALIDATE.REQUIRED'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <RadioListTitle
                        title=""
                        data={productTypes}
                        direction="row"
                        defaultValue={productType?.value}
                        onChange={(key: string) => onUpdateProduct(key)}
                        containerStyle={{
                          width: '100%',
                          marginTop: 0,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          marginBottom: 0,
                        }}
                        radioItemContainerStyle={{
                          minWidth: 'none',
                          maxWidth: 'none',
                          width: 'fit-content',
                          marginVertical: 8,
                          justifyContent: 'flex-start',
                          marginRight: 16,
                        }}
                        error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                      />
                    )}
                    name="product"
                    defaultValue=""
                  />
                </View>
              </View>
              <SizedBox height={20} />
              <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                <Text style={tabletStyles.inputBlockTitle}>
                  {i18nAgentWording({agentOfficeCode: userInfo?.officeCode, wordingCode: 'NEEDS_CART.NEED_TERM'})}
                </Text>
                <SizedBox height={16} />
                <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                  <View style={tabletStyles.inputBlockContainer}>
                    <View style={tabletStyles.inputBlockContainerLeft}>
                      <Image source={imgCalendar2} style={globalStyles.Icon26} />
                      <Controller
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: i18n.t('VALIDATE.REQUIRED'),
                          },
                          min: {
                            value: MIN_NEED_TERM,
                            message: i18n.t('VALIDATE.MIN_PLAN_TERM'),
                          },
                        }}
                        render={({ onChange, onBlur, value }) => (
                          <NumberFormat
                            placeholder={i18n.t('NEEDS_CART.NEED_TERM_HINT')}
                            style={{
                              fontSize: 13,
                              minWidth: '300px',
                              marginLeft: 8,
                              borderWidth: 0,
                              borderColor: '#fff',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            value={value}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={3}
                            type="text"
                            prefix={''}
                            onValueChange={(values: any) => {
                              const { value } = values
                              onChange(value)
                              setValue('needTerm', value)
                            }}
                            renderText={(value) => (
                              <TextInput
                                underlineColorAndroid="transparent"
                                placeholderTextColor="#E0E0E0"
                                onChangeText={(value) => {
                                  onChange(value)
                                  setValue('needTerm', value)
                                }}
                                value={value}
                                keyboardType="numeric"
                              />
                            )}
                          />
                        )}
                        name="needTerm"
                        defaultValue=""
                      />
                    </View>
                    <View style={mobileStyles.inputBlockContainerRight}>
                      <Text style={mobileStyles.unit}>{i18n.t('COMMON.YEAR')}</Text>
                    </View>
                  </View>
                  {renderErrorMessage(errors?.needTerm?.message)}
                </View>
              </View>
              <View style={[globalStyles.FullWidth, globalStyles.Center]}>
                <FilledButton
                  type="small"
                  title={i18n.t('COMMON.ACCEPT').toUpperCase()}
                  onPress={handleSubmit(onSubmitExpectationValue)}
                />
              </View>
            </View>
          </View>
        </View>
      )
    }
    return (
      <View style={[desktopStyles.needsDataContainerBlock]}>
        <View style={[desktopStyles.needsDataContainerBlockContent, globalStyles.DisplayFlexRow]}>
          <View style={desktopStyles.inputInfoContainer}>
            <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
              <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={desktopStyles.highlightTitleContainer}>
                <Image source={icCircleDollar as ImageSourcePropType} style={desktopStyles.dollarIcon} />
                <Text style={desktopStyles.highlightTitleLabel}>{i18n.t('CUSTOMER_NEED.YOUR_NEED_TOTAL')}&#58;</Text>
                <Text style={desktopStyles.moneyTotal}>
                  &nbsp;&nbsp;{vnNumberFormatter.format(getTotalValueInMillions())}&nbsp;
                </Text>
                <Text style={[desktopStyles.unit, { color: '#FFFFFF' }]}>
                  {i18n.t('COMMON.MILLION_VND')}
                </Text>
              </LinearGradientBackground>
              <SizedBox height={40} />
              <Text style={desktopStyles.inputBlockTitle}>{i18n.t('NEEDS_CART.INVESTMENT_EXPECTATION')}</Text>
              <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.AlignItemsRight]}>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.t('VALIDATE.REQUIRED'),
                    },
                  }}
                  render={({}) => (
                    <RadioListTitle
                      data={productTypes}
                      direction="row"
                      defaultValue={productType?.value}
                      onChange={(key: string) => onUpdateProduct(key)}
                      containerStyle={{
                        width: '100%',
                        marginTop: 0,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginBottom: 0,
                      }}
                      radioItemContainerStyle={{
                        minWidth: 'none',
                        maxWidth: 'none',
                        width: 'fit-content',
                        marginTop: 16,
                        justifyContent: 'flex-start',
                        marginRight: 16,
                      }}
                      error={!productType ? renderErrorMessage(errors?.product?.message) : <></>}
                    />
                  )}
                  name="product"
                  defaultValue=""
                />
              </View>
            </View>
            <SizedBox height={40} />
            <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
              <Text style={desktopStyles.inputBlockTitle}>
                {i18nAgentWording({agentOfficeCode: userInfo?.officeCode, wordingCode: 'NEEDS_CART.NEED_TERM'})}
              </Text>
              <SizedBox height={16} />
              <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
                <View style={desktopStyles.inputBlockContainer}>
                  <View style={desktopStyles.inputBlockContainerLeft}>
                    <Image source={imgCalendar2} style={globalStyles.Icon26} />
                    <Controller
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: i18n.t('VALIDATE.REQUIRED'),
                        },
                        min: {
                          value: MIN_NEED_TERM,
                          message: i18n.t('VALIDATE.MIN_PLAN_TERM'),
                        },
                      }}
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          placeholder={i18n.t('NEEDS_CART.NEED_TERM_HINT')}
                          style={{
                            fontSize: 18,
                            minWidth: '300px',
                            marginLeft: 8,
                            borderWidth: 0,
                            borderColor: '#fff',
                            backgroundColor: 'transparent',
                            width: '100%',
                          }}
                          value={value}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={3}
                          type="text"
                          prefix={''}
                          onValueChange={(values: any) => {
                            const { value } = values
                            onChange(value)
                            setValue('needTerm', value)
                          }}
                          renderText={(value) => (
                            <TextInput
                              underlineColorAndroid="transparent"
                              placeholderTextColor="#E0E0E0"
                              onChangeText={(value) => {
                                onChange(value)
                                setValue('needTerm', value)
                              }}
                              value={value}
                              keyboardType="numeric"
                            />
                          )}
                        />
                      )}
                      name="needTerm"
                      defaultValue=""
                    />
                  </View>
                  <View style={desktopStyles.inputBlockContainerRight}>
                    <Text style={desktopStyles.unit}>{i18n.t('COMMON.YEAR')}</Text>
                  </View>
                </View>
                {renderErrorMessage(errors?.needTerm?.message)}
              </View>
            </View>
            <View style={[globalStyles.FullWidth, globalStyles.Center]}>
              <FilledButton
                type="small"
                title={i18n.t('COMMON.ACCEPT').toUpperCase()}
                onPress={handleSubmit(onSubmitExpectationValue)}
              />
            </View>
          </View>
        </View>
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <KeyboardAvoidingView style={{ flex: 1 }} enabled behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <SafeAreaView style={[globalStyles.mobileContainerSafeArea]} edges={['right', 'left', 'top']}>
          <View style={[globalStyles.mobileContent]}>
            {renderMobileAppBar()}
            <ScrollView contentContainerStyle={mobileStyles.mobileContent}>
              {
                <View style={[mobileStyles.titleContainer]}>
                  <Text style={[mobileStyles.title]}>Phân tích nhu cầu</Text>
                </View>
              }
              {dataScreens && dataScreens.length > 0 && screen && (
                <TabBarView
                  data={dataScreens}
                  dataActive={userProtect}
                  postion={dataScreens.findIndex((x) => x.screen === screen)}
                  userInfo={userInfo}
                  onPressPrev={() => {
                    const position = dataScreens.findIndex((x) => x.screen === screen)
                    getDataScreen(dataScreens[position - 1]?.screen)
                  }}
                  onPressNext={() => {
                    const position = dataScreens.findIndex((x) => x.screen === screen)
                    getDataScreen(dataScreens[position + 1]?.screen || dataScreens[position]?.screen)
                  }}
                />
              )}
              <View pointerEvents={checkEditable() ? 'auto' : 'none'} style={{ height: '100%', flex: 1 }}>
                {screen === 'Analysis' && (
                  <AnalysisScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SafeLife' && (
                  <SafeLifeScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'InvestAsset' && (
                  <InvestAssetScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveCancer' && (
                  <SaveCancerScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveCharity' && (
                  <SaveCharityScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveEdu' && (
                  <SaveEduScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveHealth' && (
                  <SaveHealthScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveInherit' && (
                  <SaveInheritScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveRetire' && (
                  <SaveRetireScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SafeAsset' && (
                  <SafeAssetScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SafeEdu' && (
                  <SafeEduScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
              </View>
              <SizedBox width={24} />
              <View style={mobileStyles.continueButton}>
                <FilledButton
                  title={i18n.t('COMMON.CONTINUE')}
                  loading={loading}
                  onPress={() => setShowExpectationPopUp(true)}
                  disabled={confirm || loadingSaveDraft}
                  buttonStyle={{
                    width: 100,
                    height: 41,
                    paddingHorizontal: 16,
                    paddingVertical: 10,
                    borderRadius: 8,
                    marginTop: 0,
                  }}
                  titleStyle={{ fontSize: 14 }}
                />
              </View>
            </ScrollView>
          </View>

          <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
            <MobileStepIndicator
              route={route}
              navigation={navigation}
              cnaData={currentHandlingCNA}
              needsCartList={needsCartList}
              currentPosition={1}
              loadingSaveDraft={loadingSaveDraft}
              onSaveDraft={() => onSaveDraft(true)}
            />
          </View>
          {renderModals()}
        </SafeAreaView>
      </KeyboardAvoidingView>
    )
  }

  return (
    <SafeAreaView
      style={[globalStyles.container, globalStyles.PositionRelative]}
      pointerEvents={loadingSaveDraft === false ? 'auto' : 'none'}
    >
      <StepAppHeader
        route={route}
        navigation={navigation}
        currentPosition={1}
        onSaveDraft={() => onSaveDraft(true)}
        cnaData={currentHandlingCNA}
        needsCartList={needsCartList}
      />
      <ScrollView
        contentContainerStyle={globalStyles.content}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {!screen ? (
          renderLoading()
        ) : (
          <View style={{ paddingRight: 40, paddingLeft: 25 }}>
            <Text style={desktopStyles.heading}>Phân tích nhu cầu của bạn</Text>
            <View style={[globalStyles.DisplayFlexRow, desktopStyles.PaddingBottom]}>
              <CustomScrollView
                style={desktopStyles.individualDataContainer}
                containerStyle={{ padding: 0, paddingRight: 15 }}
                widthCustom={'auto'}
              >
                {dataScreens && dataScreens.length > 0 && screen && (
                  <SideTabView
                    screen={screen}
                    userInfo={userInfo}
                    dataActive={userProtect || []}
                    data={dataScreens ? dataScreens : dataScreen}
                    onPress={(item) => getDataScreen(item.screen)}
                  />
                )}
              </CustomScrollView>
              <View style={desktopStyles.needsDataContainer} pointerEvents={checkEditable() ? 'auto' : 'none'}>
                {screen === 'Analysis' && (
                  <AnalysisScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SafeLife' && (
                  <SafeLifeScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'InvestAsset' && (
                  <InvestAssetScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveCancer' && (
                  <SaveCancerScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveCharity' && (
                  <SaveCharityScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveEdu' && (
                  <SaveEduScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveHealth' && (
                  <SaveHealthScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveInherit' && (
                  <SaveInheritScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SaveRetire' && (
                  <SaveRetireScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SafeAsset' && (
                  <SafeAssetScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
                {screen === 'SafeEdu' && (
                  <SafeEduScreen dataBack={() => navigation.push('NeedsCart', { ...(route.params as any) })} />
                )}
              </View>
            </View>
            <StepButton
              disabledOnNext={confirm || loadingSaveDraft}
              styleButton={{ width: '100%', paddingBottom: 30 }}
              loadingOnNext={loading}
              onBack={onBack}
              onNext={() => setShowExpectationPopUp(true)}
            />
          </View>
        )}
      </ScrollView>
      <EditProfileTab disabled={true} />
      <ConfirmSavePopUp
        isShow={showConfirmLeavingAlert}
        onOk={() => {
          onSaveDraft(true).then((_) => {
            setShowConfirmLeavingAlert(false)
            navigation.navigate('NeedsCart', { ...(route.params as any) })
          })
        }}
        onCancel={() => {
          setShowConfirmLeavingAlert(false)
        }}
        onClose={() => setShowConfirmLeavingAlert(false)}
      />
      {renderModals()}
    </SafeAreaView>
  )
}
export default memo(connector(CustomerNeedScreen))

const desktopStyles = StyleSheet.create({
  needsDataContainerBlock: {
    flex: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    width: '100%',
  },
  needsDataContainerBlockContent: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 16,
    paddingHorizontal: 30,
    width: '100%',
  },
  inputInfoContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 18,
    fontWeight: '700',
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    paddingVertical: 14,
    paddingHorizontal: 16,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontSize: 18,
    fontWeight: '600',
    color: Colors.mainTextColor,
  },
  Container: {
    height: '100%',
  },
  PaddingBottom: {
    paddingBottom: 30,
  },
  heading: {
    color: '#505D6F',
    fontSize: 24,
    marginBottom: 20,
    fontWeight: 'bold',
  },
  needsDataContainer: {
    flex: 1,
    height: '100%',
  },
  helpAnalysis: {
    flexDirection: 'row',
  },
  loading: {
    height: '100%',
  },
  individualDataContainer: {
    backgroundColor: 'transparent',
    width: 'auto',
    padding: 0,
    maxHeight: 700,
  },
  highlightTitleContainer: {
    width: '100%',
    paddingHorizontal: 50,
    paddingVertical: 15,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dollarIcon: {
    width: 40,
    height: 40,
  },
  highlightTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 24,
    fontWeight: '700',
    color: '#FFF',
  },
})

const tabletStyles = StyleSheet.create({
  needsDataContainerBlock: {
    flex: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    width: '100%',
    marginTop: 10,
  },
  needsDataContainerBlockContent: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 16,
    paddingHorizontal: 30,
    width: '100%',
  },
  inputInfoContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 13,
    fontWeight: '700',
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    paddingVertical: 14,
    paddingHorizontal: 16,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontSize: 13,
    fontWeight: '600',
    color: Colors.mainTextColor,
  },
  Container: {
    height: '100%',
  },
  PaddingBottom: {
    paddingBottom: 30,
  },
  heading: {
    color: '#505D6F',
    fontSize: 24,
    marginBottom: 20,
    fontWeight: 'bold',
  },
  needsDataContainer: {
    flex: 1,
    height: '100%',
  },
  helpAnalysis: {
    flexDirection: 'row',
  },
  loading: {
    height: '100%',
  },
  individualDataContainer: {
    backgroundColor: 'transparent',
    width: 'auto',
    padding: 0,
    maxHeight: 700,
  },
  highlightTitleContainer: {
    width: '100%',
    paddingHorizontal: 50,
    paddingVertical: 15,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dollarIcon: {
    width: 40,
    height: 40,
  },
  highlightTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 24,
    fontWeight: '700',
    color: '#FFF',
  },
})

const mobileStyles = StyleSheet.create({
  needsDataContainerBlock: {
    flex: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    width: '100%',
    marginTop: 10,
  },
  needsDataContainerBlockContent: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 16,
    paddingHorizontal: 30,
    width: '100%',
  },
  inputInfoContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 13,
    fontWeight: '700',
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    paddingVertical: 14,
    paddingHorizontal: 16,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontSize: 13,
    fontWeight: '600',
    color: Colors.mainTextColor,
    textAlign: 'right',
  },
  mobileContainer: {
    backgroundColor: '#F7F8FA',
    height: '100%',
    width: '100%',
    flex: 1,
    overflow: 'scroll',
  },
  mobileContent: {
    paddingBottom: 30,
    paddingHorizontal: 16,
    paddingTop: 20,
  },
  titleContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },
  title: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: '700',
    marginLeft: 8,
    width: '100%',
    textTransform: 'uppercase',
  },
  stepIndicatorContainer: {
    bottom: 16,
    left: 16,
    right: 16,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },

  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    paddingTop: 0,
    width: '100%',
    flex: 1,
    paddingBottom: 100,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 180,
  },
  highlightTitleContainer: {
    paddingHorizontal: 16,
    paddingVertical: 9,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 19,
    width: '100%',
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 12,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 26,
    fontWeight: '700',
    color: '#FFF',
  },
})
