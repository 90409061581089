export const API_PATH = {
  USER: {
    SIGN_IN: `/auth/token`,
    INFO: `/cnas/me`
  },
  CNA: {
    GET_ALL: `/cnas/cna-detail/get-all`,
    DETAIL: `/cnas/cna-detail/get`,
    CREATE: `/cnas/cna-detail/create`,
    SUMMARY: `/cnas/cna-detail/summary`,
    UPDATE: `/cnas/cna-detail/update`,
    QUESTIONS: `/cnas/question/get-all`,
    DELETE: `/cnas/cna-detail/delete`,
    GET_CHART_DATA: `/cnas/cna-detail/quotation`,
    GET_TEMP_APE_DATA: `/cnas/cna-detail/draw-quotation`,
    PRINT_PDF: `/cnas/cna-detail/export-summary`,
    SEND_MAIL_PDF: `/cnas/cna-detail/send-mail/pdf`,
  },
  INTERNAL_CNA: {
    HOST: `cna-process`,
    GET_DETAIL: `/cna-detail/{agentCode}/get`,
    GET_CHART_DATA: `/cna-detail/quotation`,
    GET_AGENT_INFO: `/agent/{agentCode}/info`,
  }
}

export const LOCAL_PATH = {}

export const NON_AUTH_PATHS = [
  'Splash',
  'GenPDF',
  'GenPDFForEmbedIntoMail',
]
