/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Splash: '',
      MobileHome: {
        path: 'mobile-home',
        screens: {
          Home: 'index',
          List: 'cna-list',
        },
      },
      Modal: 'modal',
      AfterLogIn: 'login-success',
      Analysis: 'analysis',
      SafeLife: 'safe-life',
      SafeEdu: 'safe-edu',
      SafeAsset: 'safe-asset',
      SaveHealth: 'save-health',
      SaveRetire: 'save-retire',
      SaveCancer: 'save-cancer',
      SaveEdu: 'save-edu',
      SaveCharity: 'save-charity',
      SaveInherit: 'save-inherit',
      InvestAsset: 'invest-asset',
      NeedsCart: 'needs-cart',
      CustomerNeed : 'customer-need',
      ChartPage: 'chart-page',
      NeedsOverall: 'needs-overall',
      NeedsOverallEditMode: 'needs-overall-edit-mode',
      LogIn: 'login',
      Advisor: 'advisor',
      Solution: 'solution',
      SolutionDetail: 'solution-detail',
      SaveForm: 'save-form',
      CNAList: 'cna-list',
      GenPDF: 'generate-pdf',
      GenPDFForEmbedIntoMail: 'generate-pdf-for-embed-into-mail',
      MobileDetailCna: 'mobile-detail-cna',
      ViewAllCNA: 'view-all-cna',
      CnaFilterReviewDate: 'filter-need-review-cna',
      CnaFilterMonth: 'filter-need-review-cna-month',
      CnaFilterCreateToday: 'filter-create-today-cna',
      NotFound: '*',
    },
  },
};

export default linking;
