import * as React from 'react'
import {
  StyleSheet,
  Image,
  TextInput,
  ImageSourcePropType,
  TouchableOpacity,
  ScrollView,
  Platform,
  Linking,
  ActivityIndicator,
} from 'react-native'
import { connect, ConnectedProps } from 'react-redux'
import {
  AppBar,
  CircleProgressIndicator,
  Dialog,
  FilledButton,
  SizedBox,
  ExpandableList,
  ToggleRadioButton,
  styles as globalStyles,
  ValidateRequiredError,
} from '../common'
import { Text, View } from '../components/Themed'
import { RootState } from '../store'
import { RootStackScreenProps } from '../types'
import { Controller, useForm } from 'react-hook-form'
import imgFamilyProtect from '../assets/images/family-protect.png'
import imgPruLogo from '../assets/images/pru-logo.png'
import i18n from 'i18n-js'
import { useWindowDimensions } from 'react-native'
import RenderHtml from 'react-native-render-html'
import icCalendar2 from '../assets/images/calendar.png'
import icSendRed from '../assets/images/send-red.png'
import icSendDisabled from '../assets/images/send-disabled.png'
import icHomeWhite from '../assets/images/home-white.png'
import icFirecracker from '../assets/images/firecracker.png'
import icChain from '../assets/images/chain.png'
import { ddmmyyyyRegex, emailRegex, phoneRegex } from '../data/validate'
import { saveCNA, getCachedCNA } from '../slices/cnaSlice'
import { useIsFocused } from '@react-navigation/native'
import AppHeader from '../components/AppHeader'
import toastService from '../services/toast/ToastService'
import moment from 'moment'
import { createUserProtect, createUserProtectOriginal } from '../slices/userSlice'
import Calendar from '../common/components/Calendar'
import * as Locale from 'date-fns/locale'
import { AgentOffice, CnaStatus } from '../constants/Enum'
import { sendMailPdf } from '../slices/saveFormSlice'
import { isEditMode, isReadOnlyMode } from '../helper/RouteHelper'
import { setCnaReview, setCnaMonthList, setCnaTodayList } from '../slices/cnaSlice'
import { SafeAreaView } from 'react-native-safe-area-context'
import Layout from '../constants/Layout'
import LinearGradientBackground from '../common/components/LinearGradientBackground'
import AgentComponent from 'components/AgentComponent'
import pruquoteBackWhiteIc from '../assets/images/pruquote-back-white.png'
import { handleSSOPruquote } from 'helper/SsoPruquoteHelper'

const mapStateToProps = (state: RootState) => ({
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
  userInfo: state?.user?.userInfo,
})

const mapDispatchToProps = {
  getCachedCNA,
  saveCNA,
  sendMailPdf,
  createUserProtect,
  setCnaReview,
  setCnaMonthList,
  setCnaTodayList,
  createUserProtectOriginal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'SaveForm'> & PropsFromRedux & {}

function SaveFormScreen({
  route,
  saveCNA,
  userInfo,
  navigation,
  sendMailPdf,
  getCachedCNA,
  setCnaReview,
  setCnaMonthList,
  setCnaTodayList,
  createUserProtect,
  currentHandlingCNA,
  loadingSaveContinue,
  createUserProtectOriginal,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletSmallDevice = Layout.isTabletSmallDevice

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })
  const { width } = useWindowDimensions()
  const isFocused = useIsFocused()
  const expandableRef = React.useRef()
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)
  const [showDatePicker, setShowDatePicker] = React.useState(false)
  const [heightExpand, setHeightExpand] = React.useState(0)
  const [datePickerSelectedDate, setDatePickerSelectedDate] = React.useState<Date>()
  const [showConfirmShareDialog, setShowConfirmShareDialog] = React.useState(false)
  const [showSharePDFSuccessDialog, setShowSharePDFSuccessDialog] = React.useState(false)
  const [showSharePDFFailDialog, setShowSharePDFFailDialog] = React.useState(false)
  const [showLoadingSendEmailDialog, setShowLoadingSendEmailDialog] = React.useState(false)
  const [enableContinue, setEnableContinue] = React.useState(false)

  const policyConfirmNoti = `
  <div style="margin-top: 8px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">Nếu bạn chọn “Đồng ý” nghĩa là bạn đã hiểu các Chính sách bảo mật thông tin của Prudential thể hiện tại <a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/" target="_blank" style="color:#2F7AEB">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a> và cho phép chúng tôi thu thập, xử lý thông tin của bạn.</span></div>
`

  const content = `
  <div style="margin-top: 0px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify">Công cụ này sẽ thu thập, xử lý và lưu trữ lại các thông tin cá nhân bao gồm cả thông tin định danh (nếu có) do Khách hàng tiềm năng/Khách hàng cung cấp phục vụ mục đích (i) thực hiện khảo sát nhu cầu bảo hiểm, (ii) gửi Bảng kết quả Phân tích nhu cầu tài chính từ Công cụ này cho Khách hàng, Cụ thể, TVV có trách nhiệm phải: (iii) nâng </span></div>
`

  const contentFull = `
  <div style="margin-top: 0px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify">Công cụ này sẽ thu thập, xử lý và lưu trữ lại các thông tin cá nhân bao gồm cả thông tin định danh (nếu có) do Khách hàng tiềm năng/Khách hàng cung cấp phục vụ mục đích (i) thực hiện khảo sát nhu cầu bảo hiểm, (ii) gửi Bảng kết quả Phân tích nhu cầu tài chính từ Công cụ này cho Khách hàng, (iii) nâng cao chất lượng dịch vụ cũng như nghiên cứu và thiết kế những sản phẩm bảo hiểm phù hợp hơn với nhu cầu của Khách hàng. Do đó, khi Tư vấn viên - sau đây gọi tắt là TVV - cung cấp cho Prudential thông tin cá nhân về (các) Khách hàng tiềm năng/Khách hàng, TVV cần được chỉ định và ủy quyền bởi (các) Khách hàng tiềm năng/Khách hàng này, theo quy định của pháp luật, để hành động thay cho họ. Cụ thể, TVV có trách nhiệm phải:</span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">1. Giải thích rõ cho (các) Khách hàng tiềm năng/Khách hàng về loại thông tin cá nhân mà Prudential sẽ được thu thập/xử lý/lưu trữ và mục đích của việc thu thập/ xử lý /lưu trữ đó.</span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">2. Nhận được sự đồng ý của (các) Khách hàng tiềm năng/Khách hàng trước khi thu thập thông tin cá nhân của họ cũng như trước khi gửi Bảng kết quả Phân tích nhu cầu tài chính từ Công cụ này đến địa chỉ email hoặc ứng dụng mà (các) Khách hàng tiềm năng/Khách hàng này sử dụng. </span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">Ngoài ra, TVV cũng cần tìm hiểu đồng thời hướng dẫn (các) Khách hàng tiềm năng/Khách hàng tìm hiểu về Chính sách bảo mật thông tin của Prudential để họ hiểu cách thức Prudential thu thập, xử lý và chuyển giao thông tin của họ tại đường dẫn: <span><a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a></span></span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">Bằng việc nhấn vào nút xác nhận dưới đây, TVV đã hiểu, đồng ý và cam kết thực hiện đầy đủ những nội dung quy định nêu trên. </span></div>
`

  const contentHide = `
  <div style="margin-top: 0px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">cao chất lượng dịch vụ cũng như nghiên cứu và thiết kế những sản phẩm bảo hiểm phù hợp hơn với nhu cầu của Khách hàng. Do đó, khi Tư vấn viên - sau đây gọi tắt là TVV - cung cấp cho Prudential thông tin cá nhân về (các) Khách hàng tiềm năng/Khách hàng, TVV cần được chỉ định và ủy quyền bởi (các) Khách hàng tiềm năng/Khách hàng này, theo quy định của pháp luật, để hành động thay cho họ.</span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">1.	Giải thích rõ cho (các) Khách hàng tiềm năng/Khách hàng về loại thông tin cá nhân mà Prudential sẽ được thu thập/xử lý/lưu trữ và mục đích của việc thu thập/ xử lý /lưu trữ đó.</span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">2.	Nhận được sự đồng ý của (các) Khách hàng tiềm năng/Khách hàng trước khi thu thập thông tin cá nhân của họ cũng như trước khi gửi Bảng kết quả Phân tích nhu cầu tài chính từ Công cụ này đến địa chỉ email hoặc ứng dụng mà (các) Khách hàng tiềm năng/Khách hàng này sử dụng. </span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify ">Ngoài ra, TVV cũng cần tìm hiểu đồng thời hướng dẫn (các) Khách hàng tiềm năng/Khách hàng tìm hiểu về Chính sách bảo mật thông tin của Prudential để họ hiểu cách thức Prudential thu thập, xử lý và chuyển giao thông tin của họ tại đường dẫn: <span><a href="https://www.prudential.com.vn/vi/chinh-sach-bao-mat/">https://www.prudential.com.vn/vi/chinh-sach-bao-mat/</a></span></span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify">Bằng việc nhấn vào nút xác nhận dưới đây, TVV đã hiểu, đồng ý và cam kết thực hiện đầy đủ những nội dung quy định nêu trên. </span></div>
`

  const contentBottom = `
  <div style="margin-top: 0px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify"><span style="color: #ED1B2E;font-weight: bold">Prudential</span> sẽ lưu trữ thông tin Khách hàng vừa cung cấp với mục đích (i) thực hiện khảo sát nhu cầu bảo hiểm, (ii) gửi Bảng kết quả Phân tích sơ bộ nhu cầu tài chính từ Công cụ này cho Khách hàng, (iii) nâng cao chất lượng dịch vụ cũng như nghiên cứu và thiết kế những sản phẩm bảo hiểm phù hợp hơn với nhu cầu của Khách hàng</span></div>
  <div style="margin-top: 16px; "><span style="color: #505D6F; font-size: 13px; font-weight: normal;text-align: justify">Chúng tôi tôn trọng sự quan tâm của bạn về <span style="color: #000;font-weight: bold">quyền riêng tư</span>, thông tin của bạn được <span style="color: #000;font-weight: bold">bảo mật tuyệt đối</span> và không dùng cho mục đích khác.</span></div>
`

  React.useEffect(() => {
    const id = (route.params as any)?.id
    if (id && isFocused) {
      getCachedCNA({ id })
    }
  }, [isFocused])

  React.useEffect(() => {
    if (currentHandlingCNA) {
      setValue('customerName', currentHandlingCNA?.customerName)
      setValue('reminiscentName', currentHandlingCNA?.reminiscentName)
      setValue('customerPhone', currentHandlingCNA?.customerPhone)
      setValue('customerEmail', currentHandlingCNA?.customerEmail)
      setValue('isCustomerConfirmed', currentHandlingCNA?.isCustomerConfirmed)
      setValue('isAgentConfirmed', currentHandlingCNA?.isAgentConfirmed)
      setValue(
        'reviewDate',
        currentHandlingCNA?.reviewDate ? moment(currentHandlingCNA?.reviewDate).format('DD/MM/YYYY') : undefined
      )
    }
  }, [currentHandlingCNA])

  function onSubmit(data: any) {
    if (isReadOnlyMode(route)) {
      if (isMobileDevice) {
        navigation.replace('MobileHome')
      } else {
        navigation.navigate('CNAList')
      }
      return
    }
    let reviewDate: string
    if (!data.customerName && !data.reminiscentName) {
      toastService.error('Vui lòng nhập tên khách hàng hoặc tên gợi nhớ.')
      return
    }
    if (data.reviewDate) {
      reviewDate = moment(data.reviewDate, 'DD/MM/YYYY').add(7, 'hours').toISOString()
    } else {
      reviewDate = moment().add(6, 'months').add(7, 'hours').toISOString()
    }
    let status: string
    if (data.customerPhone) {
      status = CnaStatus.COMPLETED
    } else {
      status = CnaStatus.NEED_CONTACT_INFO
    }
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      customerName: data.customerName,
      reminiscentName: data.reminiscentName,
      customerPhone: data.customerPhone,
      customerEmail: data.customerEmail,
      reviewDate: reviewDate,
      isAgentConfirmed: data.isAgentConfirmed,
      isCustomerConfirmed: data.isCustomerConfirmed,
      isSavingContinue: true,
      status: status,
    } as any
    saveCNA(body)
      .unwrap()
      .then((e: any) => {
        setTimeout(() => {
          if (!e.error) {
            createUserProtect({})
            createUserProtectOriginal({})
            setShowSuccessModal(true)
          } else {
            toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
          }
        }, 2000)
      })
  }

  function onSendMailPdf() {
    setShowConfirmShareDialog(false)
    setShowLoadingSendEmailDialog(true)
    sendMailPdf({ id: currentHandlingCNA?._id })
      .then((data: any) => {
        setShowLoadingSendEmailDialog(false)
        if (data.error) {
          // ** We have updated the logic of showing sending fail dialog here,
          // in all cases, sending PDF file successfully or fail always show success dialog
          // setShowSharePDFFailDialog(true)
          setShowSharePDFSuccessDialog(true)
        } else {
          setShowSharePDFSuccessDialog(true)
        }
      })
      .catch((err) => console.log(err))
  }

  function onShowPopUpSelectDate() {
    setShowDatePicker(true)
  }

  function renderErrorMessage(error?: string, style?: any) {
    if (error) {
      return renderErrorElement(
        <View style={globalStyles.FloatRight}>
          <ValidateRequiredError title={error} style={style} />
        </View>
      )
    } else {
      return <></>
    }
  }

  function updateRecord() {
    setCnaReview({})
    setCnaMonthList({})
    setCnaTodayList({})
  }

  function renderModals() {
    return (
      <>
        {/* Date picker dialog */}
        <Calendar
          open={showDatePicker}
          containerStyles={desktopStyles.calendarContainer}
          date={datePickerSelectedDate}
          color={'#ED1B2E'}
          locale={Locale.vi}
          onChange={(date?: Date) => {
            setShowDatePicker(false)
            setDatePickerSelectedDate(date)
            setValue('reviewDate', moment(date).format('DD/MM/YYYY'))
          }}
          onCancel={() => setShowDatePicker(false)}
          disabledPastDates={true}
        />
        {/* Success dialog */}
        <Dialog
          type="default"
          visible={showSuccessModal}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522 }}
          onRemove={() => setShowSuccessModal(false)}
        >
          <View style={[globalStyles.BackgroundTransparent, { paddingVertical: 50 }]}>
            <View style={[globalStyles.BackgroundTransparent, globalStyles.DisplayFlexRow, globalStyles.Center]}>
              <TouchableOpacity
                style={[
                  globalStyles.DisplayFlexRow,
                  globalStyles.Center,
                  getValues('customerEmail') ? globalStyles.OutlineStyle : globalStyles.OutlineDisabledStyle,
                  {
                    width: isMobileDevice ? 140 : 190,
                    height: 50,
                    paddingHorizontal: 8,
                  },
                ]}
                disabled={!getValues('customerEmail')}
                onPress={() => {
                  setShowSuccessModal(false)
                  setShowConfirmShareDialog(true)
                }}
              >
                <Image
                  source={(getValues('customerEmail') ? icSendRed : icSendDisabled) as ImageSourcePropType}
                  style={globalStyles.Icon20}
                />
                <SizedBox width={isMobileDevice ? 8 : 14} />
                <Text
                  style={[
                    getValues('customerEmail') ? globalStyles.AccentText : globalStyles.DisabledText,
                    { fontSize: isMobileDevice ? 13 : 16 },
                  ]}
                >
                  {i18n.t('SAVE_FORM.SHARE_PDF')}
                </Text>
              </TouchableOpacity>
              <SizedBox width={24} />
              <TouchableOpacity
                style={[
                  globalStyles.DisplayFlexRow,
                  globalStyles.Center,
                  globalStyles.FilledStyle,
                  { width: isMobileDevice ? 140 : 190, height: 50, paddingHorizontal: 8 },
                ]}
                onPress={() => {
                  updateRecord()
                  setShowSuccessModal(false)
                  navigation.reset({
                    index: 0,
                    routes: [{ name: isMobileDevice ? 'MobileHome' : 'AfterLogIn' }],
                  })
                }}
              >
                <Image source={icHomeWhite as ImageSourcePropType} style={globalStyles.Icon20} />
                <SizedBox width={isMobileDevice ? 8 : 14} />
                <Text style={[globalStyles.WhiteText, { fontSize: isMobileDevice ? 13 : 16 }]}>
                  {i18n.t('SAVE_FORM.BACK_TO_HOME')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Dialog>
        {/* Confirm share pdf dialog */}
        <Dialog
          type="delete"
          visible={showConfirmShareDialog}
          showCloseIcon
          dataDelete={{
            title: i18n.t('SAVE_FORM.SHARE_PDF'),
            description: i18n.t('SAVE_FORM.CONFIRM_SHARE_PDF'),
          }}
          okText={i18n.t('COMMON.YES')}
          cancelText={i18n.t('COMMON.NO')}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522 }}
          onRemove={() => setShowConfirmShareDialog(false)}
          onOk={onSendMailPdf}
          onCancel={() => setShowConfirmShareDialog(false)}
        />
        {/* Loading send mail pdf */}
        <Dialog
          type="default"
          visible={showLoadingSendEmailDialog}
          showCloseIcon={false}
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
        >
          <View
            style={[
              globalStyles.BackgroundTransparent,
              globalStyles.DisplayFlexCol,
              globalStyles.Center,
              { padding: 20 },
            ]}
          >
            <Text style={[globalStyles.TextCommon]}>{`${i18n.t('SAVE_FORM.SENDING_PDF_TO_EMAIL')} ${getValues(
              'customerEmail'
            )}`}</Text>
            <SizedBox height={15} />
            <CircleProgressIndicator />
          </View>
        </Dialog>
        {/* Share pdf successfully dialog */}
        <Dialog
          type="default"
          visible={showSharePDFSuccessDialog}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
          onRemove={() => {
            setShowSharePDFFailDialog(false)
            setTimeout(() => {
              navigation.reset({
                index: 0,
                routes: [{ name: isMobileDevice ? 'MobileHome' : 'AfterLogIn' }],
              })
            }, 500)
          }}
        >
          <View
            style={[
              globalStyles.BackgroundTransparent,
              globalStyles.DisplayFlexCol,
              globalStyles.Center,
              { padding: 20 },
            ]}
          >
            <Image source={icFirecracker as ImageSourcePropType} style={{ width: 72, height: 72 }} />
            <SizedBox height={15} />
            <Text style={[globalStyles.TextCommon]}>{i18n.t('SAVE_FORM.SHARE_SUCCESSFUL')}</Text>
          </View>
        </Dialog>
        {/* Share pdf fail dialog */}
        <Dialog
          type="default"
          visible={showSharePDFFailDialog}
          showCloseIcon
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
          onRemove={() => {
            setShowSharePDFFailDialog(false)
          }}
        >
          <View style={[globalStyles.Background, globalStyles.DisplayFlexCol, globalStyles.Center, { padding: 20 }]}>
            <Image source={icChain as ImageSourcePropType} style={{ width: 72, height: 72 }} />
            <SizedBox height={15} />
            <Text style={[globalStyles.TextCommon, { textAlign: 'center' }]}>{i18n.t('SAVE_FORM.SHARE_FAIL')}</Text>
          </View>
        </Dialog>
      </>
    )
  }

  function renderErrorElement(child: JSX.Element) {
    if (isMobileDevice) {
      return <View style={[mobileStyles.formItemContainer]}>{child}</View>
    }
    return (
      <View style={[desktopStyles.formItemContainer, { marginLeft: 120 }]}>
        <Text style={desktopStyles.formItemLabel}></Text>
        {child}
      </View>
    )
  }

  function renderMobileAppBar() {
    return (
      <AppBar
        title={<Text style={globalStyles.AppBarTitle}>{i18n.t('SAVE_FORM.INDEX')}</Text>}
        containerStyle={globalStyles.AppBarContainer}
      />
    )
  }

  function renderFormItem(
    label: string,
    placeholder: string,
    name: string,
    validateRules?: any,
    suffix?: JSX.Element,
    disabled = false
  ) {
    if (isMobileDevice) {
      return (
        <>
          <Controller
            control={control}
            rules={validateRules}
            render={({ onChange, onBlur, value }) => (
              <View style={mobileStyles.formItemContainer}>
                <Text style={mobileStyles.formItemLabel}>{label}</Text>
                <View style={mobileStyles.formItemInputContainer}>
                  <TextInput
                    style={mobileStyles.formItemInput}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    editable={!disabled && isEditMode(route)}
                  />
                  {suffix}
                </View>
              </View>
            )}
            name={name}
            defaultValue=""
          />
        </>
      )
    }
    return (
      <>
        <Controller
          control={control}
          rules={validateRules}
          render={({ onChange, onBlur, value }) => (
            <View style={desktopStyles.formItemContainer}>
              <Text style={desktopStyles.formItemLabel}>{label}</Text>
              <View style={desktopStyles.formItemInputContainer}>
                <TextInput
                  style={desktopStyles.formItemInput}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  editable={!disabled && isEditMode(route)}
                />
                {suffix}
              </View>
            </View>
          )}
          name={`${name}`}
          defaultValue=""
        />
      </>
    )
  }

  function renderSsoPruquoteButton(width?: number, fontSize?: number, buttonStyle?: any) {
    return (
      <FilledButton
        title="PRUQUOTE"
        buttonStyle={buttonStyle || { marginTop: 26, borderRadius: '36px', paddingLeft: 50, width: width }}
        titleStyle={{ fontSize: fontSize || 24 }}
        suffix={
          <Image
            source={pruquoteBackWhiteIc as ImageSourcePropType}
            style={[desktopStyles.nextIcon, { marginLeft: 20 }]}
          />
        }
        onPress={() => currentHandlingCNA?._id && handleSSOPruquote?.(currentHandlingCNA?._id)}
      />
    )
  }

  if (isMobileDevice) {
    return (
      <SafeAreaView
        style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
        edges={['right', 'left', 'top']}
      >
        <View style={globalStyles.mobileContent}>
          {renderMobileAppBar()}
          <LinearGradientBackground colors={['#ffecec', '#fffcfc', '#fffcfc', '#ffecec']} style={globalStyles.Flex1}>
            <ScrollView contentContainerStyle={mobileStyles.container}>
              <View style={mobileStyles.decorImageContainer}>
                <Image source={imgFamilyProtect} style={mobileStyles.decorImage} />
              </View>
              <View style={[mobileStyles.formContainer]}>
                <Text style={mobileStyles.formTitle}>{i18n.t('SAVE_FORM.ENTER_FORM_ENTICEMENT')}</Text>
                <View style={mobileStyles.inputFormContainer}>
                  {renderFormItem(i18n.t('SAVE_FORM.NAME'), i18n.t('SAVE_FORM.NAME_HINT'), 'customerName', null)}
                  {renderErrorMessage(errors?.customerName?.message)}
                  {renderFormItem(
                    i18n.t('SAVE_FORM.REMINISCENT_NAME'),
                    i18n.t('SAVE_FORM.REMINISCENT_NAME_HINT'),
                    'reminiscentName'
                  )}
                  {renderErrorMessage(errors?.reminiscentName?.message)}
                  <AgentComponent
                    agentOfficeCode={userInfo?.officeCode}
                    agentComponentMap={new Map([[AgentOffice.VIB, <View></View>]])}
                    renderDefault={(agentOfficeCode?: string) => (
                      <View>
                        {renderFormItem(i18n.t('SAVE_FORM.PHONE'), i18n.t('SAVE_FORM.PHONE_HINT'), 'customerPhone', {
                          maxLength: {
                            value: 12,
                            message: i18n.t('VALIDATE.PHONE'),
                          },
                          pattern: {
                            value: phoneRegex,
                            message: i18n.t('VALIDATE.PHONE'),
                          },
                        })}
                        {renderErrorMessage(errors?.customerPhone?.message)}
                      </View>
                    )}
                  />
                  {renderFormItem(i18n.t('SAVE_FORM.EMAIL'), i18n.t('SAVE_FORM.EMAIL_HINT'), 'customerEmail', {
                    pattern: {
                      value: emailRegex,
                      message: i18n.t('VALIDATE.EMAIL'),
                    },
                  })}
                  {renderErrorMessage(errors?.customerEmail?.message)}
                  {renderFormItem(
                    i18n.t('SAVE_FORM.REVIEW_DATE'),
                    'dd/mm/yyyy',
                    'reviewDate',
                    {
                      pattern: {
                        value: ddmmyyyyRegex,
                        message: i18n.t('VALIDATE.DATE'),
                      },
                    },
                    <TouchableOpacity onPress={onShowPopUpSelectDate} disabled={isReadOnlyMode(route)}>
                      <Image source={icCalendar2} style={globalStyles.Icon20} />
                    </TouchableOpacity>,
                    true
                  )}
                  {renderErrorMessage(errors?.reviewDate?.message)}
                </View>
                <SizedBox height={10} />
                <View style={desktopStyles.noticeContainer}>
                  <Text style={mobileStyles.radioTitle}>Chính sách bảo mật cho khách hàng</Text>
                  <SizedBox height={10} />
                  <RenderHtml
                    html={policyConfirmNoti}
                    onLinkPress={(evt, href) => {
                      Linking.openURL(href)
                    }}
                  />
                </View>
                <View style={[globalStyles.Width100, desktopStyles.noticeContainer]}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: i18n.t('VALIDATE.PHONE'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <ToggleRadioButton
                        title=""
                        data={[
                          {
                            value: true,
                            text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                          },
                        ]}
                        direction="column"
                        value={value}
                        defaultValue={value}
                        wrapperStyle={{ marginBottom: 10 }}
                        containerStyle={{ alignItems: 'flex-start', marginTop: 0 }}
                        onChange={(value: any) => {
                          onChange(value)
                          setEnableContinue(value === true)
                        }}
                        titleStyle={{ fontWeight: '400' }}
                        error={renderErrorMessage(errors?.isCustomerConfirmed?.message)}
                        radioItemContainerStyle={{ maxWidth: 'none', marginTop: 0 }}
                      />
                    )}
                    name="isCustomerConfirmed"
                    defaultValue=""
                  />
                </View>

                <View style={desktopStyles.noticeContainer}>
                  <Text style={mobileStyles.radioTitle}>Chính sách bảo mật cho Tư vấn viên</Text>
                  <ExpandableList
                    ref={expandableRef}
                    displayExpandIcon={false}
                    header={
                      <View>
                        <RenderHtml
                          html={content}
                          onLinkPress={(evt, href) => {
                            Linking.openURL(href)
                          }}
                        />
                      </View>
                    }
                    expandable={
                      <View
                        onLayout={({
                          nativeEvent: {
                            layout: { x, y, width, height },
                          },
                        }) => {
                          setHeightExpand(height + 20)
                        }}
                      >
                        <RenderHtml
                          html={contentFull}
                          onLinkPress={(evt, href) => {
                            Linking.openURL(href)
                          }}
                        />
                      </View>
                    }
                    hideExpand={true}
                    headerStyle={{ alignItems: 'flex-start', maxHeight: 65, overflow: 'hidden' }}
                    containerStyle={{ padding: 0, marginTop: 0 }}
                    styleExpandIndicator={{ justifyContent: 'flex-start', paddingBottom: 0 }}
                    expandLabel={`${i18n.t('COMMON.READ_MORE')}`}
                    height={heightExpand}
                    collapseLabel={i18n.t('COMMON.COLLAPSE')}
                  />
                </View>
                <View style={[globalStyles.Width100]}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: i18n.t('VALIDATE.PHONE'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <ToggleRadioButton
                        title=""
                        data={[
                          {
                            value: true,
                            text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                          },
                        ]}
                        direction="column"
                        value={value}
                        defaultValue={value}
                        titleStyle={{ fontWeight: '400' }}
                        wrapperStyle={{ marginBottom: 10 }}
                        containerStyle={{ alignItems: 'flex-start', paddingBottom: 0, marginTop: 0 }}
                        onChange={(value: any) => {
                          onChange(value)
                          setEnableContinue(value === true)
                        }}
                        error={renderErrorMessage(errors?.isAgentConfirmed?.message)}
                        radioItemContainerStyle={{ maxWidth: 'none', marginTop: 0 }}
                      />
                    )}
                    name="isAgentConfirmed"
                    defaultValue=""
                  />
                </View>
                <View style={mobileStyles.confirmButtonContainer}>
                  <FilledButton
                    title={i18n.t('COMMON.CONFIRM')}
                    buttonStyle={{ width: 146, height: 50, paddingHorizontal: 0, marginTop: 20 }}
                    titleStyle={{ fontSize: 15 }}
                    disabled={
                      Object.keys(errors).length > 0 || !watch('isCustomerConfirmed') || !watch('isAgentConfirmed')
                    }
                    onPress={handleSubmit(onSubmit)}
                    loading={loadingSaveContinue}
                  />
                </View>
                <View style={mobileStyles.noteBottom}>
                  <RenderHtml
                    html={contentBottom}
                    onLinkPress={(evt, href) => {
                      Linking.openURL(href)
                    }}
                  />
                </View>
                <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.BackgroundTransparent]}>
                  <Image source={imgPruLogo} style={{ width: 97, height: 58 }} />
                </View>
                <View style={[globalStyles.FullWidth, globalStyles.Center, { backgroundColor: '#00000000' }]}>
                  {renderSsoPruquoteButton(204, 20, {
                     marginTop: 26,
                     width: 204,
                     paddingHorizontal: 0,
                     paddingVertical: 25,
                     borderRadius: 36,
                  })}
                </View>
                {/* <View style={[globalStyles.FullWidth, globalStyles.Center, { backgroundColor: '#00000000' }]}>
                <FilledButton
                  title="PRUDAILY"
                  buttonStyle={{
                    marginTop: 26,
                    width: 204,
                    paddingHorizontal: 0,
                    paddingVertical: 30,
                    borderRadius: 36,
                  }}
                  titleStyle={{ fontSize: 20 }}
                  suffix={<Image source={icArrowRightWhite as ImageSourcePropType} style={mobileStyles.nextIcon} />}
                />
              </View> */}
              </View>
            </ScrollView>
          </LinearGradientBackground>
        </View>
        {renderModals()}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <AppHeader
        navigation={navigation}
        extra={
          <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
            <View style={globalStyles.Expanded}></View>
          </View>
        }
      />
      <LinearGradientBackground
        colors={['#ffecec', '#fffcfc', '#fffcfc', '#ffecec']}
        style={[desktopStyles.container, globalStyles.content]}
      >
        <View style={[desktopStyles.formContainerWrap]}>
          <View style={[desktopStyles.formContainer]}>
            <Text style={desktopStyles.formTitle}>{i18n.t('SAVE_FORM.ENTER_FORM_ENTICEMENT')}</Text>
            <View style={desktopStyles.inputFormContainer}>
              {renderFormItem(i18n.t('SAVE_FORM.NAME'), i18n.t('SAVE_FORM.NAME_HINT'), 'customerName', null)}
              {renderErrorMessage(errors?.customerName?.message)}
              {renderFormItem(
                i18n.t('SAVE_FORM.REMINISCENT_NAME'),
                i18n.t('SAVE_FORM.REMINISCENT_NAME_HINT'),
                'reminiscentName'
              )}
              {renderErrorMessage(errors?.reminiscentName?.message)}
              <AgentComponent
                agentOfficeCode={userInfo?.officeCode}
                agentComponentMap={new Map([[AgentOffice.VIB, <View></View>]])}
                renderDefault={(agentOfficeCode?: string) => (
                  <View>
                    {renderFormItem(i18n.t('SAVE_FORM.PHONE'), i18n.t('SAVE_FORM.PHONE_HINT'), 'customerPhone', {
                      maxLength: {
                        value: 12,
                        message: i18n.t('VALIDATE.PHONE'),
                      },
                      pattern: {
                        value: phoneRegex,
                        message: i18n.t('VALIDATE.PHONE'),
                      },
                    })}
                    {renderErrorMessage(errors?.customerPhone?.message)}
                  </View>
                )}
              />
              {renderFormItem(i18n.t('SAVE_FORM.EMAIL'), i18n.t('SAVE_FORM.EMAIL_HINT'), 'customerEmail', {
                pattern: {
                  value: emailRegex,
                  message: i18n.t('VALIDATE.EMAIL'),
                },
              })}
              {renderErrorMessage(errors?.customerEmail?.message)}
              {renderFormItem(
                i18n.t('SAVE_FORM.REVIEW_DATE'),
                'dd/mm/yyyy',
                'reviewDate',
                {
                  pattern: {
                    value: ddmmyyyyRegex,
                    message: i18n.t('VALIDATE.DATE'),
                  },
                },
                <TouchableOpacity onPress={onShowPopUpSelectDate} disabled={isReadOnlyMode(route)}>
                  <Image source={icCalendar2} style={globalStyles.Icon20} />
                </TouchableOpacity>,
                true
              )}
              {renderErrorMessage(errors?.reviewDate?.message)}
              <SizedBox width={isMobileDevice ? 8 : 14} />
              <View style={desktopStyles.noticeContainer}>
                <Text style={desktopStyles.radioTitle}>Chính sách bảo mật cho khách hàng</Text>
                <RenderHtml
                  html={policyConfirmNoti}
                  onLinkPress={(evt, href) => {
                    Linking.openURL(href)
                  }}
                />
              </View>
              <View style={[globalStyles.Width100, desktopStyles.noticeContainer]}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ onChange, onBlur, value }) => (
                    <ToggleRadioButton
                      title=""
                      data={[
                        {
                          value: true,
                          text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                        },
                      ]}
                      direction="column"
                      value={value}
                      titleStyle={{ fontWeight: '400' }}
                      defaultValue={value}
                      containerStyle={{ alignItems: 'flex-start' }}
                      onChange={(value: any) => {
                        onChange(value)
                        setEnableContinue(value === true)
                      }}
                      error={renderErrorMessage(errors?.isCustomerConfirmed?.message)}
                      radioItemContainerStyle={{ maxWidth: 'none', marginTop: 0 }}
                    />
                  )}
                  name="isCustomerConfirmed"
                  defaultValue=""
                />
              </View>

              <View style={desktopStyles.noticeContainer}>
                <Text style={desktopStyles.radioTitle}>Chính sách bảo mật cho Tư vấn viên</Text>
                <ExpandableList
                  ref={expandableRef}
                  displayExpandIcon={false}
                  header={
                    <View style={desktopStyles.noticeContainer}>
                      <RenderHtml
                        html={content}
                        onLinkPress={(evt, href) => {
                          Linking.openURL(href)
                        }}
                      />
                    </View>
                  }
                  expandable={
                    <View
                      style={desktopStyles.noticeContainer}
                      onLayout={({
                        nativeEvent: {
                          layout: { x, y, width, height },
                        },
                      }) => {
                        setHeightExpand(height + 20)
                      }}
                    >
                      <RenderHtml
                        html={contentFull}
                        onLinkPress={(evt, href) => {
                          Linking.openURL(href)
                        }}
                      />
                    </View>
                  }
                  hideExpand={true}
                  headerStyle={{ alignItems: 'flex-start', maxHeight: 60, overflow: 'hidden' }}
                  containerStyle={{ padding: 0 }}
                  styleExpandIndicator={{ justifyContent: 'flex-start', paddingBottom: 0, marginTop: 20, top: -10 }}
                  expandLabel={`${i18n.t('COMMON.READ_MORE')}`}
                  height={heightExpand}
                  collapseLabel={i18n.t('COMMON.COLLAPSE')}
                />
              </View>
              <View style={[globalStyles.Width100, desktopStyles.noticeContainer]}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ onChange, onBlur, value }) => (
                    <ToggleRadioButton
                      title=""
                      data={[
                        {
                          value: true,
                          text: i18n.t('COMMON.UNDERSTAND_AND_ACCEPT'),
                        },
                      ]}
                      direction="column"
                      value={value}
                      defaultValue={value}
                      titleStyle={{ fontWeight: '400' }}
                      containerStyle={{ alignItems: 'flex-start' }}
                      onChange={(value: any) => {
                        onChange(value)
                        setEnableContinue(value === true)
                      }}
                      error={renderErrorMessage(errors?.isAgentConfirmed?.message)}
                      radioItemContainerStyle={{ maxWidth: 'none', marginTop: 0 }}
                    />
                  )}
                  name="isAgentConfirmed"
                  defaultValue=""
                />
              </View>
            </View>
            <View style={desktopStyles.confirmButtonContainer}>
              <FilledButton
                title={i18n.t('COMMON.CONFIRM')}
                buttonStyle={{ width: 146, height: 50, paddingHorizontal: 0, marginTop: 20 }}
                titleStyle={{ fontSize: 15 }}
                disabled={Object.keys(errors).length > 0 || !watch('isCustomerConfirmed') || !watch('isAgentConfirmed')}
                onPress={handleSubmit(onSubmit)}
                loading={loadingSaveContinue}
              />
            </View>
          </View>
          <View style={desktopStyles.noteBottom}>
            <RenderHtml
              html={contentBottom}
              onLinkPress={(evt, href) => {
                Linking.openURL(href)
              }}
            />
          </View>
          <View style={desktopStyles.ssoPruquoteButtonTablet}>
            {isTabletSmallDevice ? renderSsoPruquoteButton(250) : <></>}
          </View>
        </View>
        <View style={desktopStyles.decorImageContainer}>
          <Image source={imgFamilyProtect} style={desktopStyles.decorImage} />
          {!isTabletSmallDevice ? renderSsoPruquoteButton() : <></>}
        </View>
      </LinearGradientBackground>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(SaveFormScreen)

const desktopStyles = StyleSheet.create({
  calendarContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 81,
    paddingRight: 0,
    paddingTop: 92,
    overflow: 'scroll',
    paddingBottom: 30,
  },
  radioTitle: {
    fontWeight: 'bold',
    color: '#505D6F',
    fontSize: 15,
  },
  noticeContainer: {
    backgroundColor: 'transparent',
    marginTop: 15,
  },
  backgroundTransparent: {
    backgroundColor: 'transparent',
  },
  noteBottom: {
    backgroundColor: 'transparent',
    paddingHorizontal: 28,
    paddingVertical: 29,
    paddingRight: 107,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  formContainerWrap: {
    maxWidth: 659,
    backgroundColor: 'transparent',
  },
  formContainer: {
    paddingHorizontal: 28,
    paddingVertical: 29,
    backgroundColor: '#ffffff99',
    height: 'fit-content',
    borderWidth: 4,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 24,
  },
  decorImage: {
    maxWidth: 527,
    height: 515,
    width: '100%',
    resizeMode: 'contain',
  },
  formTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#505D6F',
    marginBottom: 18,
  },
  formItemContainer: {
    flexDirection: 'row',
    marginTop: 24,
    backgroundColor: '#00000000',
    alignItems: 'center',
  },
  formItemLabel: {
    fontSize: 16,
    color: '#2B2D42',
    width: 120,
    textAlign: 'right',
    marginRight: 19,
  },
  formItemInputContainer: {
    backgroundColor: '#FAFBFF',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderRadius: 16,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 17,
  },
  formItemInput: {
    paddingHorizontal: 17,
    paddingVertical: 12,
    borderWidth: 0,
    borderColor: '#fff',
    flex: 1,
  },
  decorImageContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#00000000',
    alignItems: 'center',
  },
  confirmButtonContainer: {
    flexDirection: 'row',
    backgroundColor: '#00000000',
    justifyContent: 'center',
  },
  policyContainer: {
    backgroundColor: '#00000000',
    marginTop: 40,
    textAlign: 'justify',
    // whiteSpace: 'initial',
  },
  nextIcon: {
    width: 20,
    height: 25,
    resizeMode: 'contain',
  },
  inputFormContainer: {
    paddingRight: 80,
    backgroundColor: '#00000000',
  },
  ssoPruquoteButtonTablet: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 30,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    paddingVertical: 0,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    padding: 0,
    paddingBottom: 0,
    marginBottom: 0,
    overflow: 'scroll',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  formContainer: {
    borderWidth: 2,
    borderColor: '#FFE6E2',
    borderStyle: 'solid',
    borderRadius: 12,
    paddingHorizontal: 24,
    paddingVertical: 24,
    marginTop: 30,
    width: '100%',
    backgroundColor: '#00000000',
  },
  decorImage: {
    width: 229,
    height: 224,
    marginTop: 26,
  },
  noteBottom: {
    backgroundColor: 'transparent',
    marginBottom: 30,
  },
  radioTitle: {
    fontWeight: 'bold',
    color: '#505D6F',
    fontSize: 14,
  },
  formTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#505D6F',
    marginBottom: 18,
  },
  formItemContainer: {
    flexDirection: 'column',
    marginTop: 24,
    backgroundColor: '#00000000',
    alignItems: 'flex-start',
  },
  formItemLabel: {
    fontSize: 15,
    color: '#2B2D42',
    width: '100%',
    textAlign: 'left',
  },
  formItemInputContainer: {
    backgroundColor: '#FAFBFF',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderRadius: 12,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 17,
    width: '100%',
    marginTop: 8,
  },
  formItemInput: {
    paddingHorizontal: 17,
    paddingVertical: Platform.OS === 'android' ? 6 : 12,
    borderWidth: 0,
    borderColor: '#fff',
    flex: 1,
    fontSize: 15,
  },
  decorImageContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#00000000',
    alignItems: 'center',
  },
  confirmButtonContainer: {
    flexDirection: 'row',
    backgroundColor: '#00000000',
    justifyContent: 'center',
    marginBottom: 30,
  },
  policyContainer: {
    backgroundColor: '#00000000',
    marginTop: 40,
    textAlign: 'justify',
    // whiteSpace: 'initial',
  },
  nextIcon: {
    width: 20,
    height: 25,
    resizeMode: 'contain',
  },
  inputFormContainer: {
    paddingRight: 0,
    backgroundColor: '#00000000',
  },
})
