import * as React from 'react'
import { View } from 'react-native'

const STEP_WIDTH = 5
const LINE_WIDTH = 1

export type LineProps = {
  lineStyle?: 'solid' | 'dashed' | 'dotted'
  color?: any
  orientation?: 'vertical' | 'horizontal'
  size?: number | string
  lineWidth?: number
  containerStyle?: Record<string, unknown>
}

export function Line({ lineStyle, color, orientation = 'horizontal', size, lineWidth = LINE_WIDTH, containerStyle }: LineProps) {
  const [steps, setSteps] = React.useState<JSX.Element[]>([])

  const Step = () => {
    if (lineStyle === 'dashed') {
      return (
        <>
          <View
            style={{
              width: orientation === 'horizontal' ? 3 : lineWidth,
              backgroundColor: color,
              height: orientation === 'horizontal' ? lineWidth : 3,
            }}
          />
          <View
            style={{
              width: orientation === 'horizontal' ? 2 : lineWidth,
              backgroundColor: 'white',
              height: orientation === 'horizontal' ? lineWidth : 2,
            }}
          />
        </>
      )
    } else if (lineStyle === 'dotted') {
      return (
        <>
          <View
            style={{
              width: orientation === 'horizontal' ? 3 : lineWidth,
              backgroundColor: color,
              height: orientation === 'horizontal' ? lineWidth : 3,
            }}
          />
          <View
            style={{
              width: orientation === 'horizontal' ? 2 : lineWidth,
              backgroundColor: 'white',
              height: orientation === 'horizontal' ? lineWidth : 2,
            }}
          />
        </>
      )
    } else {
      return (
        <>
          <View
            style={{
              width: orientation === 'horizontal' ? 3 : lineWidth,
              backgroundColor: color,
              height: orientation === 'horizontal' ? lineWidth : 3,
            }}
          />
          <View
            style={{
              width: orientation === 'horizontal' ? 2 : lineWidth,
              backgroundColor: color,
              height: orientation === 'horizontal' ? lineWidth : 2,
            }}
          />
        </>
      )
    }
  }

  if (orientation === 'vertical') {
    return (
      <View
        style={[{
          height: size,
          flexDirection: 'column',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          backgroundColor: lineStyle === 'solid' ? color : null,
        }, containerStyle]}
        onLayout={(event) => {
          var { height } = event.nativeEvent.layout
          let count = Math.ceil(height / STEP_WIDTH)
          let steps = Array(count)
            .fill(0)
            .map((e, i) => {
              return <Step key={i} />
            })
          setSteps(steps)
        }}
      >
        {steps}
      </View>
    )
  } else {
    return (
      <View
        style={[{
          width: size,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          backgroundColor: lineStyle === 'solid' ? color : null,
        }, containerStyle]}
        onLayout={(event) => {
          var { width } = event.nativeEvent.layout
          let count = Math.ceil(width / STEP_WIDTH)
          let steps = Array(count)
            .fill(0)
            .map((e, i) => {
              return <Step key={i} />
            })
          setSteps(steps)
        }}
      >
        {steps}
      </View>
    )
  }
}

export default Line
