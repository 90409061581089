
import React, { useEffect } from 'react';
import { View, StyleSheet, Text,TouchableOpacity,Image,ImageSourcePropType } from 'react-native'
import { RootStackScreenProps } from '../types'
import {
  styles as globalStyles,
  AppBar,
} from '../common'
import icArrowBackWhite from '../assets/images/icon-dropdown.png'
import { saveCNA } from '../slices/cnaSlice'
import SummarySolutionPopUp from './SolutionScreen/components/SummarySolutionPopUp'

import {
  defaultQuotationFundAllocation,
} from '../constants/Data'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { updateUserProtect } from '../slices/userSlice'
import { useIsFocused } from '@react-navigation/native'
import { getCNADetail } from '../slices/cnaSlice'
import { getChartData } from '../slices/solutionSlice'
import toastService from '../services/toast/ToastService'
import i18n from 'i18n-js'
import {  ViewMode } from '../constants/Enum'
import Layout from '../constants/Layout';


const mapStateToProps = (state: RootState) => ({
  data: state?.user?.userProtect?.quotations,
  chartData: state?.solution.chartData,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
})

const mapDispatchToProps = {
   saveCNA, updateUserProtect,getCNADetail,getChartData
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>


type Props = RootStackScreenProps<'ChartPage'> &
  PropsFromRedux & {}


function ChartPageScreen({saveCNA,data,route,getCNADetail,getChartData,chartData,currentHandlingCNA,navigation }: Props) {

  const isMobileDevice = Layout.isMobileDevice

  const isFocused = useIsFocused()
 

  useEffect(() => {
    if (isFocused) {
      onInitData()
    }
  }, [isFocused])

  function onUpdateQuotations(body: any) {
    saveCNA(body)
      .unwrap()
      .then((data) => {
        const id = (route.params as any)?.id
        if (id && id !== 'new') {
          // getCachedCNA({ id })
        }
      })
  }
  

  async function onInitData() {
    const id = (route.params as any)?.id
    if (id) {
      Promise.all([
        getCNADetail({ id }),
      await  getChartData({ id: (route.params as any)?.id, withoutTopup: false }),
      await  getChartData({ id: (route.params as any)?.id, withoutTopup: true }),
      ])
        .then((data: any) => {
          let cnaData = data[0].payload
          let cnaDataTopUp =  data[2].payload?.result.map( (x: any) => {
            return {
              ...x,
              ape:  cnaData?.topUp ? Number(x.ape) + cnaData?.topUp * 1000000 :  Number(x.ape)
            }
          }
          );
          let body = {
            ...cnaData,
            id: cnaData?._id,
            ape:
              data[1] && data[1].payload?.result && data[1].payload?.result?.length > 0
                ? data[1].payload?.result[0]?.ape
                : 0,
            quotations:  data[1].payload?.result,
            quotationWithoutTopups:
              data[0].payload?.topUp !== 0 && data[0].payload?.topUp ? cnaDataTopUp : null,
          } as any
          // onUpdateQuotations(body)
        })
        .catch((err) => toastService.error(i18n.t('MESS.UNKNOWN_ERROR')))
    }
  }



function renderMobileAppBar() {
  return (
    <AppBar
    styleAppBar={{backgroundColor:'#fff'}}
    leading={
      <TouchableOpacity
      onPress={() => {
        navigation.push('Solution', { ...(route.params as any), id: currentHandlingCNA?._id, popup: 'chart'})
      }}
      >
        <Image source={icArrowBackWhite as ImageSourcePropType} style={[globalStyles.Icon24,{transform: [{ rotate: '90deg' }]} ]} />
      </TouchableOpacity>
    }
    title={<Text style={mobileStyles.titlePopUp}>Cổ phiếu Việt Nam</Text>}
  />
  )
}




  if (isMobileDevice) {
    return (
           <View style={{overflow:'hidden'}}>
             <View style={{width: '100vh',height: '100vh',transform: [{ rotateZ: '270deg' }]}}>
             {renderMobileAppBar()}
             <View  style={{marginTop:50}}>
             <SummarySolutionPopUp
              customerAge={currentHandlingCNA?.customerAge ?? 0}
              data={chartData}
              dataTopUp={currentHandlingCNA?.quotationWithoutTopups}
              productTypeSolution={currentHandlingCNA?.product}
              extraInvest={currentHandlingCNA?.topUp}
              isLoadingChartData={false}
              editable={(route.params as any).mode !== ViewMode.READ_ONLY}
              quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
              // onOpenExtraInvestModal={onOpenExtraInvestModal}
            />
               {/* {
                 chartData && 
                 <ColumnChart
                 width={'100%'}
                 height={150}
                 data={chartData as any}
                 defaultColumnWidth={80}
                 wrapperStyle={{ padding: 20, paddingTop: 0 }}
               />
               } */}
            
      </View>
        </View>
          </View>
        
    )
  }


  return (
         <View  style={{flex: 1,position: 'relative'}}>
            
      </View>
  )
}
export default connector(ChartPageScreen)

const mobileStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  titlePopUp: {
    fontSize: 15,
    fontWeight: '600',
    color: '#505D6F'
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginTop: 56,
    width: '100%',
  },
  individualDataContainer: {
    paddingTop: 0,
    width: '100%',
    flex: 1,
    // overflowY: 'scroll',
    paddingBottom: 100,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
