import Layout from '../../constants/Layout'
import React, { useState, useEffect } from 'react'
import { SafeAreaView, StyleSheet, View, Text, TouchableOpacity } from 'react-native'

export type ToggleRadioButtonItem = {
  data: any
  title: string
  styleRadio?: Record<string, unknown>
  value?: any
  error?: React.ReactNode
  defaultValue?: any
  onChange?: (value: any) => void
  direction?: 'column' | 'row'
  containerStyle?: Record<string, unknown>
  wrapperStyle?: Record<string, unknown>
  radioItemContainerStyle?: Record<string, unknown>
  titleStyle?: Record<string, unknown>
}

export function ToggleRadioButton({
  data,
  title,
  error,
  defaultValue,
  onChange,
  value,
  styleRadio,
  direction,
  wrapperStyle,
  titleStyle,
  containerStyle,
  radioItemContainerStyle,
}: ToggleRadioButtonItem) {
  const [titleRadio, setTitleRadio] = useState(defaultValue)
  const isMobileDevice = Layout.isMobileDevice

  useEffect(() => {
    if (defaultValue !== undefined) {
      setTitleRadio(defaultValue)
    }
  }, [defaultValue])

  if (isMobileDevice) {
    return (
      <SafeAreaView style={[mobileStyles.wrapper,wrapperStyle]}>
        <Text style={mobileStyles.radioTitle}>{title}</Text>
        <View style={[mobileStyles.wrapperContent, { flexDirection: direction ?? 'row' }, containerStyle]}>
          {data.map((res: any, i: number) => {
            return (
              <View key={res.value} style={[mobileStyles.container, { marginTop: i > 0 ? 22 : 0 }]}>
                <TouchableOpacity
                  style={titleRadio === res.value ? mobileStyles.radioCircleSelect : mobileStyles.radioCircle}
                  onPress={() => {
                    if (value !== res.value) {
                      setTitleRadio(res.value)
                      onChange && onChange(res.value)
                    } else {
                      setTitleRadio(undefined)
                      onChange && onChange(undefined)
                    }
                  }}
                >
                  {titleRadio === res.value && <View style={mobileStyles.selectedRb} />}
                </TouchableOpacity>
                <Text style={[titleRadio === res.value ? mobileStyles.radioTextSelect : mobileStyles.radioText, titleStyle]}>
                  {res.text}
                </Text>
              </View>
            )
          })}
        </View>
        {error && error}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[styles.wrapper, styleRadio]}>
      <Text style={styles.radioTitle}>{title}</Text>
      <View
        style={[styles.wrapperContent, { flexDirection: direction ?? 'row' }, containerStyle, radioItemContainerStyle]}
      >
        {data.map((res: any, i: number) => {
          return (
            <View
              key={res.value}
              style={[
                styles.container,
                radioItemContainerStyle ? radioItemContainerStyle : {},
                { marginTop: i > 0 ? 22 : 0 },
              ]}
            >
              <TouchableOpacity
                style={titleRadio === res.value ? styles.radioCircleSelect : styles.radioCircle}
                onPress={() => {
                  if (value !== res.value) {
                    setTitleRadio(res.value)
                    onChange && onChange(res.value)
                  } else {
                    setTitleRadio(undefined)
                    onChange && onChange(undefined)
                  }
                }}
              >
                {titleRadio === res.value && <View style={styles.selectedRb} />}
              </TouchableOpacity>
              <Text style={styles.radioText}>{res.text}</Text>
            </View>
          )
        })}
        <View></View>
      </View>
      {error && error}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 30,
    width: '100%',
  },
  wrapperContent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
  },
  container: {
    maxWidth: 200,
    minWidth: 160,
    flexDirection: 'row',
    marginTop: 8,
    width: '100%',
  },
  radioTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#505D6F',
    fontSize: 15,
  },
  radioText: {
    paddingLeft: 15,
    fontSize: 15,
    color: '#373737',
    fontWeight: '400',
    flex: 1,
  },
  radioTextSelect: {
    paddingLeft: 15,
    fontSize: 15,
    color: '#373737',
    fontWeight: '700',
    flex: 1,
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelect: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ED1B2E',
  },
  selectedRb: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: '#ED1B2E',
  },
})

const mobileStyles = StyleSheet.create({
  wrapper: {
    marginBottom: 30,
  },
  wrapperContent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
    width: '100%',
  },
  container: {
    minWidth: '50%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  radioTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#505D6F',
    fontSize: 13,
  },
  radioText: {
    paddingLeft: 15,
    fontSize: 13,
    color: '#373737',
    fontWeight: '400',
  },
  radioTextSelect: {
    paddingLeft: 15,
    fontSize: 13,
    color: '#373737',
    fontWeight: '700',
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelect: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ED1B2E',
  },
  selectedRb: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: '#ED1B2E',
  },
})
