import * as React from 'react'
import { StyleSheet, Text, View, Image, ImageSourcePropType, TextInput, Animated } from 'react-native'
import { Slider } from 'react-native-elements'
import i18n from 'i18n-js'
import { Checkbox, Select, SizedBox, Spacer, styles as globalStyles } from '../../../common'
import Layout from '../../../constants/Layout'
import Colors from '../../../common/constants/Colors'
import NumberFormat from 'react-number-format'
import { MIN_FATAL_DECEASE_VALUE } from 'constants/Constants'
import { HealthCarePlan } from '../../../model/HealthCarePlan'

export type NeedsOverallItemProps = {
  item?: any
  icon?: JSX.Element
  subQty?: number
  qty?: number
  isChecked?: boolean
  sliderValue?: number
  sliderValueSub?: number
  selectedHcPlanSub?: HealthCarePlan
  selectedHcPlan?: HealthCarePlan
  hcPlanData?: HealthCarePlan[]
  onChangedHcPlan?: (plan: any) => void
  onChangeTotalValue?: (needsCategoryCode: string, needsKey: string, totalValue: number, isSelected: boolean) => void
  onChangeSubTotalValue?: (
    needsCategoryCode: string,
    needsKey: string,
    subTotalValue: number,
    isSelected: boolean
  ) => void
  onSelected?: (needsCategoryCode: string, needsKey: string, isSelected: boolean) => void
  renderItemTite?: (key: string) => string
}

export function NeedsOverallEditItem({
  item,
  subQty,
  qty,
  isChecked,
  sliderValue,
  sliderValueSub,
  selectedHcPlan,
  hcPlanData,
  selectedHcPlanSub,
  onChangedHcPlan,
  onChangeTotalValue,
  onChangeSubTotalValue,
  onSelected,
  renderItemTite,
}: NeedsOverallItemProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  let sliderAnimate = new Animated.Value(0)
  let sliderSubAnimate = new Animated.Value(0)

  const [sliderAnimateValue, setSliderAnimateValue] = React.useState(0)
  const [sliderSubAnimateValue, setSliderSubAnimateValue] = React.useState(0)
  const [totalValue, setTotalValue] = React.useState<number | undefined>(0)

  React.useEffect(() => {
    setTotalValue(qty)
  }, [qty])

  sliderAnimate.addListener(({ value }) => {
    setSliderAnimateValue(value)
  })
  sliderSubAnimate.addListener(({ value }) => {
    setSliderSubAnimateValue(value)
  })

  React.useEffect(() => {
    if ((sliderValue ?? 0) <= 1 && sliderValue !== (sliderAnimate as any)._value) {
      sliderAnimate.stopAnimation()
      sliderAnimate.setValue(sliderAnimateValue)
      setTimeout(() => {
        Animated.timing(sliderAnimate, {
          useNativeDriver: true,
          toValue: sliderValue ?? 0,
          duration: 300,
        }).start()
      }, 300)
    }
  }, [sliderValue])

  React.useEffect(() => {
    if ((sliderValueSub ?? 0) <= 1 && sliderValueSub !== sliderSubAnimateValue) {
      sliderSubAnimate.stopAnimation()
      sliderSubAnimate.setValue(sliderSubAnimateValue)
      setTimeout(() => {
        Animated.timing(sliderSubAnimate, {
          useNativeDriver: true,
          toValue: sliderValueSub ?? 0,
          duration: 500,
        }).start()
      }, 300)
    }
  }, [sliderValueSub])

  const Input = ({
    key,
    value,
    onChange,
    disabled,
    objColor,
    error,
    displayError,
    subValue1,
  }: {
    key?: string
    value?: any
    onChange?: (value: number) => void
    disabled?: boolean
    objColor?: any
    error?: string
    displayError?: boolean
    subValue1?: any
  }) => {
    if (key === 'hcPlan') {
      if (isMobileDevice) {
        return (
          <View style={[globalStyles.DisplayFlexCol]}>
            <Select
              selectOption={{
                options: hcPlanData?.map((e, i) => {
                  return {
                    label: e.label,
                    value: e.key,
                  }
                }),
                disabled: disabled,
                style: mobileStyles.filterDropdownHcPlan,
                dropDownContainerStyle: mobileStyles.dropDownContainerStyle,
                listItemLabelStyle: mobileStyles.listItemLabelStyle,
                labelStyle: {
                  fontSize: 11,
                  fontWeight: 'normal',
                  color: disabled ? Colors.disabledColor : objColor,
                },
                placeholderStyle: mobileStyles.placeholderStyle,
                dropDownDirection: 'TOP',
                defaultValue: subValue1 ? subValue1.key : undefined,
              }}
              onChange={(value?: any) => {
                if (value !== selectedHcPlan?.key && !disabled) {
                  onChangedHcPlan && onChangedHcPlan(value)
                }
              }}
            />
            {error && (
              <Text
                style={[
                  globalStyles.FloatLeft,
                  globalStyles.ErrorText,
                  { maxWidth: 150, opacity: displayError ? 1 : 0 },
                ]}
              >
                {error}
              </Text>
            )}
          </View>
        )
      }
      return (
        <View style={[globalStyles.DisplayFlexCol]}>
          <View style={desktopStyles.inputBlockContainer}>
            <View style={desktopStyles.inputBlockContainerFull}>
              <Select
                selectOption={{
                  options: hcPlanData?.map((e, i) => {
                    return {
                      label: e.label,
                      value: e.key,
                    }
                  }),
                  disabled: disabled,
                  style: desktopStyles.filterDropdownHcPlan,
                  dropDownContainerStyle: desktopStyles.dropDownContainerStyle,
                  listItemLabelStyle: desktopStyles.listItemLabelStyle,
                  labelStyle: {
                    ...desktopStyles.labelStyle,
                    fontWeight: 'normal',
                    color: disabled ? Colors.disabledColor : objColor,
                  },
                  placeholderStyle: desktopStyles.placeholderStyle,
                  placeholder: i18n.t('NEEDS_OVERALL_EDIT_MODE.INVEST_EXPECTATION'),
                  dropDownDirection: 'TOP',
                  defaultValue: subValue1 ? subValue1.key : undefined,
                }}
                onChange={(value?: any) => {
                  if (value !== selectedHcPlan?.key && !disabled) {
                    onChangedHcPlan && onChangedHcPlan(value)
                  }
                }}
              />
            </View>
          </View>
          {error && <Text style={[globalStyles.FloatLeft, globalStyles.ErrorText, { maxWidth: 200 }]}>{error}</Text>}
        </View>
      )
    }
    if (isMobileDevice) {
      return (
        <View style={[globalStyles.DisplayFlexCol]}>
          <View style={mobileStyles.inputBlockContainer}>
            <View style={mobileStyles.inputBlockContainerLeft}>
              <NumberFormat
                disabled={disabled}
                placeholder={i18n.t('COMMON.INPUT')}
                style={{
                  width: 62,
                  fontSize: 13,
                  fontWeight: 600,
                  borderWidth: 0,
                  borderColor: '#fff',
                  backgroundColor: 'transparent',
                  textAlign: 'end',
                  color: disabled ? null : objColor,
                }}
                value={value ? value : undefined}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={3}
                isNumericString={true}
                type="text"
                prefix={''}
                onValueChange={(values: any, sourceInfo: any) => {
                  if (!!!disabled) {
                    const { value } = values
                    if (value && value !== '') {
                      setTotalValue(parseFloat(value))
                    } else {
                      setTotalValue(0)
                    }
                  }
                }}
                onBlur={() => {
                  onChange && onChange(totalValue ?? 0)
                }}
                renderText={(value) => (
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholderTextColor="#E0E0E0"
                    editable={!disabled}
                    onChangeText={(value) => {
                      if (!!!disabled) {
                        if (value && value !== '') {
                          setTotalValue(parseFloat(value))
                        } else {
                          setTotalValue(0)
                        }
                      }
                    }}
                    value={value ? value : undefined}
                    keyboardType="numeric"
                  />
                )}
              />
            </View>
            <View style={mobileStyles.inputBlockContainerRight}>
              <Text style={[mobileStyles.unit, { color: disabled ? Colors.disableTextColor : Colors.mainTextColor }]}>
                {i18n.t('COMMON.MILLION_VND_MOBILE')}
              </Text>
            </View>
          </View>
          {error && (
            <Text
              style={[globalStyles.FloatLeft, globalStyles.ErrorText, { maxWidth: 150, opacity: displayError ? 1 : 0 }]}
            >
              {error}
            </Text>
          )}
        </View>
      )
    }
    return (
      <View style={[globalStyles.DisplayFlexCol]}>
        <View style={desktopStyles.inputBlockContainer}>
          <View style={desktopStyles.inputBlockContainerLeft}>
            <NumberFormat
              disabled={disabled}
              placeholder={i18n.t('COMMON.INPUT')}
              style={{
                width: 80,
                fontSize: 15,
                fontWeight: 500,
                borderWidth: 0,
                borderColor: '#fff',
                backgroundColor: 'transparent',
                textAlign: 'end',
                color: disabled ? (isTabletDevice ? undefined : Colors.disableTextColor) : objColor,
              }}
              value={value ? value : undefined}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={3}
              isNumericString={true}
              type="text"
              prefix={''}
              onValueChange={(values: any, sourceInfo: any) => {
                if (!!!disabled) {
                  const { value } = values
                  if (value && value !== '') {
                    setTotalValue(parseFloat(value))
                  } else {
                    setTotalValue(0)
                  }
                }
              }}
              onBlur={() => {
                onChange && onChange(totalValue ?? 0)
              }}
              renderText={(value) => (
                <TextInput
                  underlineColorAndroid="transparent"
                  placeholderTextColor="#E0E0E0"
                  editable={!disabled}
                  onChangeText={(value) => {
                    if (!!!disabled) {
                      if (value && value !== '') {
                        setTotalValue(parseFloat(value))
                      } else {
                        setTotalValue(0)
                      }
                    }
                  }}
                  value={value ? value : undefined}
                  keyboardType="numeric"
                />
              )}
            />
          </View>
          <View style={desktopStyles.inputBlockContainerRight}>
            <Text style={[desktopStyles.unit, { color: disabled ? Colors.disableTextColor : Colors.mainTextColor }]}>
              {i18n.t('COMMON.MILLION_VND')}
            </Text>
          </View>
        </View>
        {error && <Text style={[globalStyles.FloatLeft, globalStyles.ErrorText, { maxWidth: 200 }]}>{error}</Text>}
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={[globalStyles.Card, mobileStyles.container, { paddingHorizontal: 8 }]}>
        <View style={mobileStyles.infoContainer}>
          <View style={[globalStyles.DisplayFlexRow, globalStyles.AlignItemsCenter]}>
            {Input({
              key: item.key,
              value: subQty,
              onChange: (value: number) =>
                onChangeSubTotalValue && onChangeSubTotalValue(item.code, item.key, value, isChecked ?? false),
              disabled: true,
              objColor: item.themeColor,
              subValue1: selectedHcPlanSub,
              error: isChecked
                ? item.key === 'hcFatalDisease'
                  ? (totalValue ?? 0) < MIN_FATAL_DECEASE_VALUE
                    ? i18n.t('VALIDATE.FATAL_DECEASE_MIN')
                    : undefined
                  : (totalValue ?? 0) <= 0
                  ? i18n.t('VALIDATE.MONEY')
                  : undefined
                : undefined,
              displayError: false,
            })}
            <Spacer />
            {Input({
              key: item.key,
              value: totalValue,
              onChange: (value: number) =>
                onChangeTotalValue && onChangeTotalValue(item.code, item.key, value, isChecked ?? false),
              disabled: !item.isChecked,
              objColor: item.themeColor,
              subValue1: selectedHcPlan,
              error: isChecked
                ? item.key === 'hcFatalDisease'
                  ? (totalValue ?? 0) < MIN_FATAL_DECEASE_VALUE
                    ? i18n.t('VALIDATE.FATAL_DECEASE_MIN')
                    : undefined
                  : (totalValue ?? 0) <= 0
                  ? i18n.t('VALIDATE.MONEY')
                  : undefined
                : undefined,
              displayError: true,
            })}
            <SizedBox width={8} />
            <Checkbox
              checked={isChecked}
              onChange={(value: boolean) => onSelected && onSelected(item.code, item.key, value)}
              containerStyle={mobileStyles.checkboxContainer}
            />
          </View>
          <SizedBox height={8} />
          <View style={mobileStyles.sliderContentContainer}>
            <View style={[mobileStyles.sliderContainer]}>
              <Slider
                animateTransitions
                animationType="spring"
                animationConfig={{ delay: 1000 } as Animated.SpringAnimationConfig}
                style={{ width: '100%', height: 10, maxWidth: 180, transform: 'rotateZ(180deg)' } as any}
                minimumValue={0}
                maximumValue={1}
                value={isChecked ? sliderValueSub : 0}
                minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
                maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
                disabled
                thumbStyle={{ width: 0, height: 0 }}
                trackStyle={{ borderTopRightRadius: 9999, borderBottomRightRadius: 9999, height: 12 }}
              />
            </View>
            <View style={mobileStyles.iconContainer}>
              <Image
                source={isChecked ? item.icon : (item.disabledIcon as ImageSourcePropType)}
                style={globalStyles.Icon40}
              />
            </View>
            <View style={mobileStyles.sliderContainer}>
              <Slider
                style={{ width: '100%', height: 10, maxWidth: 180 }}
                minimumValue={0}
                maximumValue={1}
                value={isChecked ? sliderValue : 0}
                minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
                maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
                disabled
                thumbStyle={{ width: 0, height: 0 }}
                trackStyle={{ borderTopRightRadius: 9999, borderBottomRightRadius: 9999, height: 12 }}
              />
            </View>
          </View>
          <SizedBox width={16} />
        </View>
        <SizedBox height={8} />
        <View style={mobileStyles.titleContainer}>
          <Text style={[mobileStyles.title, { color: isChecked ? '#70777E' : '#E0E0E0' }]} numberOfLines={2}>
            {renderItemTite ? renderItemTite(item.key) : item.title}
          </Text>
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={tabletStyles.container}>
        <View style={tabletStyles.infoContainer}>
          {Input({
            key: item.key,
            value: subQty,
            onChange: (value: number) =>
              onChangeSubTotalValue && onChangeSubTotalValue(item.code, item.key, value, isChecked ?? false),
            disabled: true,
            objColor: item.themeColor,
            subValue1: selectedHcPlanSub,
          })}
          <SizedBox width={30} />
          <View style={tabletStyles.sliderContentContainer}>
            <View
              style={[
                tabletStyles.sliderContainer,
                {
                  justifyContent: 'flex-end',
                } as any,
              ]}
            >
              <SizedBox width={97} />
              <Slider
                animateTransitions
                animationType="spring"
                animationConfig={{ delay: 1000 } as Animated.SpringAnimationConfig}
                style={{ width: '100%', height: 10, maxWidth: 180, transform: 'rotateZ(180deg)' } as any}
                minimumValue={0}
                maximumValue={1}
                value={isChecked ? sliderValueSub : 0}
                minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
                maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
                disabled
                thumbStyle={{ width: 0, height: 0 }}
                trackStyle={{ borderTopRightRadius: 9999, borderBottomRightRadius: 9999, height: 10 }}
              />
            </View>
            <View style={tabletStyles.iconContainer}>
              <Image
                source={isChecked ? item.icon : (item.disabledIcon as ImageSourcePropType)}
                style={globalStyles.Icon60}
              />
            </View>
            <View style={tabletStyles.sliderContainer}>
              <Slider
                style={{ width: '100%', height: 10, maxWidth: 180 }}
                minimumValue={0}
                maximumValue={1}
                value={isChecked ? sliderValue : 0}
                minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
                maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
                disabled
                thumbStyle={{ width: 0, height: 0 }}
                trackStyle={{ borderTopRightRadius: 9999, borderBottomRightRadius: 9999, height: 10 }}
              />
            </View>
          </View>
          <SizedBox width={16} />
          <Checkbox
            checked={isChecked}
            onChange={(value: boolean) => onSelected && onSelected(item.code, item.key, value)}
            containerStyle={tabletStyles.checkboxContainer}
          />
          <SizedBox width={16} />
          {Input({
            key: item.key,
            value: totalValue,
            onChange: (value: number) =>
              onChangeTotalValue && onChangeTotalValue(item.code, item.key, value, isChecked ?? false),
            disabled: !item.isChecked,
            objColor: item.themeColor,
            subValue1: selectedHcPlan,
            error: isChecked
              ? item.key === 'hcFatalDisease'
                ? (totalValue ?? 0) < MIN_FATAL_DECEASE_VALUE
                  ? i18n.t('VALIDATE.FATAL_DECEASE_MIN')
                  : undefined
                : (totalValue ?? 0) <= 0
                ? i18n.t('VALIDATE.MONEY')
                : undefined
              : undefined,
            displayError: true,
          })}
        </View>
        <SizedBox height={8} />
        <View style={tabletStyles.titleContainer}>
          <Text style={[tabletStyles.title, { color: isChecked ? '#70777E' : '#E0E0E0' }]} numberOfLines={2}>
            {renderItemTite ? renderItemTite(item.key) : item.title}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <View style={desktopStyles.container}>
      <View style={desktopStyles.infoContainer}>
        {Input({
          key: item.key,
          value: subQty,
          onChange: (value: number) =>
            onChangeSubTotalValue && onChangeSubTotalValue(item.code, item.key, value, isChecked ?? false),
          disabled: true,
          objColor: item.themeColor,
          subValue1: selectedHcPlanSub,
        })}
        <SizedBox width={30} />
        <View style={desktopStyles.sliderContentContainer}>
          <View
            style={[
              desktopStyles.sliderContainer,
              {
                justifyContent: 'flex-end',
              } as any,
            ]}
          >
            <SizedBox width={97} />
            <Slider
              style={{ width: '100%', height: 20, maxWidth: 200, transform: 'rotateZ(180deg)' } as any}
              minimumValue={0}
              maximumValue={1}
              value={isChecked ? sliderSubAnimateValue : 0}
              minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
              maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
              disabled
              thumbStyle={{ width: 0, height: 0 }}
              trackStyle={{ borderTopRightRadius: 9999, borderBottomRightRadius: 9999, height: 15 }}
            />
          </View>
          <View style={desktopStyles.iconContainer}>
            <Image
              source={isChecked ? item.icon : (item.disabledIcon as ImageSourcePropType)}
              style={globalStyles.Icon80}
            />
          </View>
          <View style={desktopStyles.sliderContainer}>
            <Slider
              style={{ width: '100%', height: 20, maxWidth: 200 }}
              minimumValue={0}
              maximumValue={1}
              value={isChecked ? sliderAnimateValue : 0}
              minimumTrackTintColor={isChecked ? item.themeColor : '#E0E0E0'}
              maximumTrackTintColor={isChecked ? '#D7DFFF' : '#E0E0E0'}
              disabled
              thumbStyle={{ width: 0, height: 0 }}
              trackStyle={{ borderTopRightRadius: 9999, borderBottomRightRadius: 9999, height: 15 }}
            />
          </View>
        </View>
        <Checkbox
          checked={isChecked}
          onChange={(value: boolean) => onSelected && onSelected(item.code, item.key, value)}
          containerStyle={desktopStyles.checkboxContainer}
        />
        <SizedBox width={16} />
        {Input({
          key: item.key,
          value: totalValue,
          onChange: (value: number) =>
            onChangeTotalValue && onChangeTotalValue(item.code, item.key, value, isChecked ?? false),
          disabled: !item.isChecked,
          objColor: item.themeColor,
          subValue1: selectedHcPlan,
          error: isChecked
            ? item.key === 'hcFatalDisease'
              ? (totalValue ?? 0) < MIN_FATAL_DECEASE_VALUE
                ? i18n.t('VALIDATE.FATAL_DECEASE_MIN')
                : undefined
              : (totalValue ?? 0) <= 0
              ? i18n.t('VALIDATE.MONEY')
              : undefined
            : undefined,
          displayError: true,
        })}
      </View>
      <SizedBox height={8} />
      <View style={desktopStyles.titleContainer}>
        <Text style={[desktopStyles.title, { color: isChecked ? '#70777E' : '#E0E0E0' }]} numberOfLines={2}>
          {renderItemTite ? renderItemTite(item.key) : item.title}
        </Text>
      </View>
    </View>
  )
}

export default NeedsOverallEditItem

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    borderRadius: 20,
    borderColor: Colors.borderColor,
    borderWidth: 1,
    padding: 16,
  },
  iconContainer: {
    marginRight: 0,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 9999,
  },
  titleContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    paddingRight: 24,
  },
  checkbox: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
  },
  title: {
    fontSize: 14,
    height: 38,
  },
  sliderContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  sliderContainer: {
    marginTop: 0,
    flex: 1,
    flexDirection: 'row',
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  checkboxContainer: {
    minWidth: 0,
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 18,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 10,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerFull: {
    borderRadius: 10,
    flex: 1,
    flexDirection: 'row',
    width: 202.34,
    height: 50,
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 14,
    color: Colors.mainTextColor,
  },
  filterDropdownHcPlan: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: '#FFF',
    width: 202.34,
    height: 50,
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: Colors.borderColor,
    borderWidth: 1.5,
    width: '100%',
    paddingVertical: 0,
  },
  listItemLabelStyle: {
    fontSize: 14,
    fontWeight: 'normal',
    paddingVertical: 10,
  },
  labelStyle: {
    fontWeight: '500',
    fontSize: 14,
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 15,
    fontWeight: 'normal',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    borderRadius: 20,
    borderColor: Colors.borderColor,
    borderWidth: 1,
    padding: 16,
  },
  iconContainer: {
    marginRight: 0,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    paddingRight: 24,
  },
  checkbox: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
  },
  title: {
    fontSize: 14,
    height: 38,
  },
  sliderContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  sliderContainer: {
    marginTop: 0,
    flex: 1,
    flexDirection: 'row',
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  checkboxContainer: {
    minWidth: 0,
    marginBottom: 5,
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 18,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 10,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 14,
    color: Colors.mainTextColor,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    marginVertical: 8,
    padding: 16,
  },
  checkboxContainer: {
    marginRight: 0,
    marginVertical: 0,
    minWidth: 0,
  },
  iconContainer: {
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
  },
  title: {
    fontSize: 14,
  },
  sliderContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  sliderContainer: {
    flex: 1,
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  slider: {
    width: '90%',
    height: 20,
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 18,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 4,
    flexDirection: 'row',
  },
  inputBlockContainerFull: {
    borderRadius: 10,
    flex: 1,
    flexDirection: 'row',
    width: 120,
    height: 50,
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 6,
    paddingHorizontal: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 13,
    color: Colors.mainTextColor,
  },
  filterDropdownHcPlan: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: 8,
    backgroundColor: '#FFF',
    width: 132,
    height: 50,
    fontSize: 12,
  },
  dropDownContainerStyle: {
    marginVertical: 0,
    borderColor: Colors.borderColor,
    borderWidth: 1.5,
    width: 132,
    paddingVertical: 0,
    fontSize: 12,
  },
  listItemLabelStyle: {
    fontSize: 11,
    fontWeight: 'normal',
    paddingVertical: 10,
  },
  labelStyle: {
    fontWeight: '500',
    fontSize: 12,
  },
  placeholderStyle: {
    color: '#A7ACB6',
    fontSize: 12,
    fontWeight: 'normal',
  },
})
