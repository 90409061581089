import React from 'react'
import { StyleSheet, View, Text, Image, TouchableOpacity, TextInput, ImageSourcePropType, Linking } from 'react-native'
import { FilledButton, SizedBox, styles as globalStyles } from '../../../common'
import i18n from 'i18n-js'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { validateNumber } from '../../../data/validate'
import RenderHtml from 'react-native-render-html'
import icClose from '../../../assets/images/close-grey.png'

import Layout from '../../../constants/Layout'

type IProps = {
  extraInvest?: number
  maxApe?: any
  productTypeSolution?: string
  onClose: (extraInvestValue?: number) => void
}

function ExtraInvestDialog({ extraInvest, onClose, productTypeSolution, maxApe }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const [enableContinue, setEnableContinue] = React.useState(false)
  const [maxTopUp, setMaxTopUp] = React.useState(100)
  const [minTopUp, setMinTopUp] = React.useState(0)
  const [type, setType] = React.useState(10)
  const [minValue, setMinValue] = React.useState('')
  React.useEffect(() => {
    setValue('extraInvestValue', extraInvest)
  }, [extraInvest])

  React.useEffect(() => {
    if (maxApe && maxApe[0]?.ape) {
      if (productTypeSolution === 'ILP') {
        setMaxTopUp((maxApe[0]?.ape * 10) / 1000000)
        setType(10)
        setMinValue('tối thiểu là 2 triệu đồng và')
        setMinTopUp(2)
      } else {
        setMaxTopUp((maxApe[0]?.ape * 5) / 1000000)
        setType(5)
      }
    }
  }, [maxApe])

  function updateStateContinueButton(e: any) {
    if (e) {
      setEnableContinue(true)
    } else {
      setEnableContinue(false)
    }
  }

  function onSubmit(data: any) {
    onClose(parseFloat(data.extraInvestValue))
  }

  function renderInputMoneyError(error?: any) {
    if (!error) {
      return null
    }
    if (error.type === 'required') {
      return i18n.t('VALIDATE.REQUIRED')
    }
    if (error.type === 'min' || error.type === 'max' || error.type === 'pattern') {
      return `${productTypeSolution === 'ILP'? 'Khoản đầu tư thêm tối thiểu là 2 triệu đồng và không vượt quá 10 lần phí bảo hiểm quỹ năm của Năm hợp đồng đầu tiên' : 
      'Khoản tích luỹ thêm không vượt quá 5 lần phí bảo hiểm quỹ của Năm hợp đồng đầu tiên. Vui lòng nhập lại'}
      `
    }
    return null
  }


  

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  if (isMobileDevice) {
    return (
      <>
        {productTypeSolution === 'ILP' ? (
          <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', paddingHorizontal: 30 }]}>
            <View style={globalStyles.Center}>
              <View style={[{ marginTop: 30, width: '100%' }]}>
                <Text style={mobileStyles.extraInvestLabel}>{i18n.t('SOLUTION.INVEST_VALUE')}</Text>
                <SizedBox height={10} />
                <View style={mobileStyles.inputPlanContainer}>
                  <View style={mobileStyles.inputPlanContainerLeft}>
                    <Controller
                      control={control}
                      rules={{  min: 2, max: (maxApe[0]?.ape * 10) / 1000000 }}
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          placeholder={i18n.t('COMMON.INPUT')}
                          style={{
                            fontSize: 15,
                            fontWeight: 600,
                            textAlign: 'right',
                            borderWidth: 0,
                            borderColor: '#fff',
                            backgroundColor: 'transparent',
                          }}
                          value={value ? value : ''}
                          allowNegative={false}
                          thousandSeparator="."
                          isNumericString={true}
                          decimalSeparator=","
                          decimalScale={3}
                          prefix={''}
                          onValueChange={(values: any, sourceInfo: any) => {
                            const { formattedValue, value } = values
                            const { event, source } = sourceInfo
                            onChange(value)
                            setValue('extraInvestValue', value)
                            updateStateContinueButton(value)
                          }}
                          renderText={(value) => (
                            <TextInput
                              underlineColorAndroid="transparent"
                              style={[mobileStyles.mobileInput, { textAlign: 'right' }]}
                              onChangeText={(value) => {
                                onChange(value)
                                setValue('extraInvestValue', value)
                                updateStateContinueButton(value)
                              }}
                              value={value}
                              keyboardType="numeric"
                            />
                          )}
                        />
                      )}
                      name="extraInvestValue"
                    />
                  </View>
                  <View style={mobileStyles.inputPlanContainerRight}>
                    <Text style={mobileStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                  </View>
                </View>
                {errors.extraInvestValue && (
                <Text style={globalStyles.errorMessage}>{renderInputMoneyError(errors.extraInvestValue)}</Text>
                )}
              </View>
              <View>
                <SizedBox height={15} />
                <Text style={mobileStyles.highlightTitle}>Lưu ý:</Text>
                <View style={mobileStyles.noticeContainer}>
                  <RenderHtml
                    html={`
                    <div style="margin-top: 8px;"></div><span style="font-size: 15px">- Bảng minh họa dòng tiền được tính giả định Top-up (Tích lũy / Đầu tư thêm) vào <span ><span style="font-weight:700">năm đầu tiên</span>, và <span style="font-weight:700">ngay sau 21 ngày</span> cân nhắc.</span></span></div>
                    <div style="margin-top: 8px;"><span font-size: 15px;">- Khách hàng có thể thực hiện Topup (Tích lũy / Đầu tư thêm) vào các năm tiếp theo trong suốt thời hạn hợp đồng bảo hiểm theo quy định của Quy tắc, điều khoản sản phẩm tương ứng.</span></div>
                    <div style="margin-top: 8px;"><span font-size: 15px;">- Đối với sản phẩm ILP, số tiền Top-up (Đầu tư thêm) tối thiểu là 2 triệu đồng/lần.</span></div>
                  `}
                    onLinkPress={(evt, href) => {
                      Linking.openURL(href)
                    }}
                  />
                </View>
              </View>
              <SizedBox height={22} />
              <FilledButton
                title={i18n.t('COMMON.CONFIRM')}
                onPress={handleSubmit(onSubmit)}
                buttonStyle={globalStyles.CommonButton}
                titleStyle={{ fontSize: 14 }}
                disabled={errors.extraInvestValue}
              />
              <SizedBox height={20} />
            </View>
          </View>
        ) : (
          <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', paddingHorizontal: 30 }]}>
            <View style={globalStyles.Center}>
              <View style={[{ marginTop: 30, width: '100%' }]}>
              <Text style={desktopStyles.extraInvestLabel}>{i18n.t('SOLUTION.INVEST_VALUE_ULP')}</Text>
                <SizedBox height={10} />
                <View style={mobileStyles.inputPlanContainer}>
                  <View style={mobileStyles.inputPlanContainerLeft}>
                    <Controller
                      control={control}
                      rules={{  min: 0, max: (maxApe[0]?.ape * 5) / 1000000 }}
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          placeholder={i18n.t('COMMON.INPUT')}
                          style={{
                            fontSize: 15,
                            fontWeight: 600,
                            textAlign: 'right',
                            borderWidth: 0,
                            borderColor: '#fff',
                            backgroundColor: 'transparent',
                          }}
                          value={value ? value : ''}
                          allowNegative={false}
                          thousandSeparator="."
                          isNumericString={true}
                          decimalSeparator=","
                          decimalScale={3}
                          prefix={''}
                          onValueChange={(values: any, _: any) => {
                            const { value } = values
                            onChange(value)
                            setValue('extraInvestValue', value)
                            updateStateContinueButton(value)
                          }}
                          renderText={(value) => (
                            <TextInput
                              underlineColorAndroid="transparent"
                              style={[mobileStyles.mobileInput, { textAlign: 'right' }]}
                              onChangeText={(value) => {
                                onChange(value)
                                setValue('extraInvestValue', value)
                                updateStateContinueButton(value)
                              }}
                              value={value}
                              keyboardType="numeric"
                            />
                          )}
                        />
                      )}
                      name="extraInvestValue"
                    />
                  </View>
                  <View style={mobileStyles.inputPlanContainerRight}>
                    <Text style={mobileStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                  </View>
                </View>
                {errors.extraInvestValue && (
                <Text style={globalStyles.errorMessage}>{renderInputMoneyError(errors.extraInvestValue)}</Text>
                )}
              </View>
              <View>
                <SizedBox height={15} />
                <Text style={mobileStyles.highlightTitle}>Lưu ý:</Text>
                <View style={mobileStyles.noticeContainer}>
                <RenderHtml
                  html={`
                  <div style="margin-top: 8px;"></div><span style="font-size: 15px">- Bảng minh họa dòng tiền được tính giả định Top-up (Tích lũy / Đầu tư thêm) vào <span><span style="font-weight:700">năm đầu tiên</span>, và <span style="font-weight:700">ngay sau 21 ngày</span> cân nhắc.</span></span></div>
                  <div style="margin-top: 8px;"><span style="font-size: 15px;">- Khách hàng có thể thực hiện Topup (Tích lũy / Đầu tư thêm) vào các năm tiếp theo trong suốt thời hạn hợp đồng bảo hiểm theo quy định của Quy tắc, điều khoản sản phẩm tương ứng.</span></div>`}
                  onLinkPress={(evt, href) => {
                    Linking.openURL(href)
                  }}
                  />
                </View>
              </View>
              <SizedBox height={22} />
              <FilledButton
                title={i18n.t('COMMON.CONFIRM')}
                onPress={handleSubmit(onSubmit)}
                buttonStyle={globalStyles.CommonButton}
                titleStyle={{ fontSize: 14 }}
                disabled={errors.extraInvestValue}
              />
              <SizedBox height={20} />
            </View>
          </View>
        )}
      </>
    )
  }

  return (
    <>
      {productTypeSolution === 'ILP' ? (
        <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', paddingHorizontal: 30 }]}>
          <View style={globalStyles.Center}>
            <View style={[globalStyles.FullWidth, { marginTop: 30 }]}>
              <Text style={desktopStyles.extraInvestLabel}>{i18n.t('SOLUTION.INVEST_VALUE')}</Text>
              <SizedBox height={10} />
              <View style={desktopStyles.inputPlanContainer}>
                <View style={desktopStyles.inputPlanContainerLeft}>
                  <Controller
                    control={control}
                    rules={{  min: 2, max: (maxApe[0]?.ape * 10) / 1000000 }}
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        placeholder={i18n.t('COMMON.INPUT')}
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          textAlign: 'right',
                          borderWidth: 0,
                          borderColor: '#fff',
                          backgroundColor: 'transparent',
                        }}
                        value={value ? value : ''}
                        allowNegative={false}
                        thousandSeparator="."
                        isNumericString={true}
                        decimalSeparator=","
                        decimalScale={3}
                        prefix={''}
                        onValueChange={(values: any, sourceInfo: any) => {
                          const { formattedValue, value } = values
                          const { event, source } = sourceInfo
                          onChange(value)
                          setValue('extraInvestValue', value)
                          updateStateContinueButton(value)
                        }}
                      />
                    )}
                    name="extraInvestValue"
                  />
                </View>
                <View style={desktopStyles.inputPlanContainerRight}>
                  <Text style={desktopStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                </View>
              </View>
              {errors.extraInvestValue && (
                <Text style={globalStyles.errorMessage}>{renderInputMoneyError(errors.extraInvestValue)}</Text>
              )}
            </View>
            <View>
              <SizedBox height={30} />
              <Text style={desktopStyles.highlightTitle}>Lưu ý:</Text>
              <View style={desktopStyles.noticeContainer}>
                <RenderHtml
                  html={`
                  <div style="margin-top: 8px;"></div><span style="font-size: 15px">- Bảng minh họa dòng tiền được tính giả định Top-up (Tích lũy / Đầu tư thêm) vào <span ><span style="font-weight:700">năm đầu tiên</span>, và <span style="font-weight:700">ngay sau 21 ngày</span> cân nhắc.</span></span></div>
                  <div style="margin-top: 8px;"><span style="font-size: 15px;">- Khách hàng có thể thực hiện Topup (Tích lũy / Đầu tư thêm) vào các năm tiếp theo trong suốt thời hạn hợp đồng bảo hiểm theo quy định của Quy tắc, điều khoản sản phẩm tương ứng.</span></div>
                  <div style="margin-top: 8px;"><span style="font-size: 15px;">- Đối với sản phẩm ILP, số tiền Top-up (Đầu tư thêm) tối thiểu là 2 triệu đồng/lần.</span></div>
                `}
                  onLinkPress={(evt, href) => {
                    Linking.openURL(href)
                  }}
                />
              </View>
            </View>
            <SizedBox height={22} />
            <FilledButton
              title={i18n.t('COMMON.CONFIRM')}
              onPress={handleSubmit(onSubmit)}
              buttonStyle={globalStyles.CommonButton}
              titleStyle={{ fontSize: 14 }}
              disabled={errors.extraInvestValue}
            />
            <SizedBox height={20} />
          </View>
        </View>
      ) : (
        <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', paddingHorizontal: 30 }]}>
          <View style={globalStyles.Center}>
            <View style={[globalStyles.FullWidth, { marginTop: 30 }]}>
            <Text style={desktopStyles.extraInvestLabel}>{i18n.t('SOLUTION.INVEST_VALUE_ULP')}</Text>
              <SizedBox height={10} />
              <View style={desktopStyles.inputPlanContainer}>
                <View style={desktopStyles.inputPlanContainerLeft}>
                  <Controller
                    control={control}
                    rules={{  min: 0, max: (maxApe[0]?.ape * 5) / 1000000 }}
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        placeholder={i18n.t('COMMON.INPUT')}
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          textAlign: 'right',
                          borderWidth: 0,
                          borderColor: '#fff',
                          backgroundColor: 'transparent',
                        }}
                        value={value ? value : ''}
                        allowNegative={false}
                        thousandSeparator="."
                        isNumericString={true}
                        decimalSeparator=","
                        decimalScale={3}
                        prefix={''}
                        onValueChange={(values: any, sourceInfo: any) => {
                          const { formattedValue, value } = values
                          const { event, source } = sourceInfo
                          onChange(value)
                          setValue('extraInvestValue', value)
                          updateStateContinueButton(value)
                        }}
                      />
                    )}
                    name="extraInvestValue"
                  />
                </View>
                <View style={desktopStyles.inputPlanContainerRight}>
                  <Text style={desktopStyles.unit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                </View>
              </View>
              {errors.extraInvestValue && (
                <Text style={globalStyles.errorMessage}>{renderInputMoneyError(errors.extraInvestValue)}</Text>
              )}
            </View>
            <View>
              <SizedBox height={30} />
              <Text style={desktopStyles.highlightTitle}>Lưu ý:</Text>
              <View style={desktopStyles.noticeContainer}>
                <RenderHtml
                  html={`
                  <div style="margin-top: 8px;"></div><span style="font-size: 15px">- Bảng minh họa dòng tiền được tính giả định Top-up (Tích lũy / Đầu tư thêm) vào <span><span style="font-weight:700">năm đầu tiên</span>, và <span style="font-weight:700">ngay sau 21 ngày</span> cân nhắc.</span></span></div>
                  <div style="margin-top: 8px;"><span style="font-size: 15px;">- Khách hàng có thể thực hiện Topup (Tích lũy / Đầu tư thêm) vào các năm tiếp theo trong suốt thời hạn hợp đồng bảo hiểm theo quy định của Quy tắc, điều khoản sản phẩm tương ứng.</span></div>`}
                  onLinkPress={(evt, href) => {
                    Linking.openURL(href)
                  }}
                />
              </View>
            </View>
            <SizedBox height={22} />
            <FilledButton
              title={i18n.t('COMMON.CONFIRM')}
              onPress={handleSubmit(onSubmit)}
              buttonStyle={globalStyles.CommonButton}
              titleStyle={{ fontSize: 14 }}
              disabled={errors.extraInvestValue}
            />
            <SizedBox height={20} />
          </View>
        </View>
      )}
    </>
  )
}

export default ExtraInvestDialog

const desktopStyles = StyleSheet.create({
  inputPlanContainer: {
    flexDirection: 'row',
  },
  inputPlan: {
    fontSize: 15,
    fontWeight: '600',
    minWidth: 418,
    textAlign: 'right',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#2B2D42',
  },
  inputPlanContainerLeft: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 20,
    flex: 1,
  },
  inputPlanContainerRight: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  highlightTitle: {
    color: '#ED1B2E',
    fontSize: 15,
    fontWeight: 'bold',
  },
  noticeContainer: {
    backgroundColor: '#00000000',
    textAlign: 'justify',
    // whiteSpace: 'initial',
  },
  extraInvestLabel: {
    color: '#505D6F',
    fontSize: 15,
    fontWeight: '600',
  },
})

const mobileStyles = StyleSheet.create({
  inputPlanContainer: {
    flexDirection: 'row',
  },
  inputPlan: {
    fontSize: 15,
    fontWeight: '600',
    minWidth: 418,
    textAlign: 'right',
  },
  mobileInput: {
    fontSize: 13,
    fontWeight: '600',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 0,
    borderColor: '#fff',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 13,
    color: '#2B2D42',
  },
  inputPlanContainerLeft: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingHorizontal: 20,
    paddingTop: 0,
    height: 46,
    justifyContent: 'center',
    flex: 1,
  },
  inputPlanContainerRight: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    paddingTop: 0,
    paddingHorizontal: 20,
    height: 46,
    flexDirection: 'row',
    alignItems: 'center',
  },
  highlightTitle: {
    color: '#ED1B2E',
    fontSize: 15,
    fontWeight: 'bold',
  },
  noticeContainer: {
    backgroundColor: '#00000000',
    textAlign: 'justify',
    // whiteSpace: 'initial',
  },
  extraInvestLabel: {
    color: '#505D6F',
    fontSize: 15,
    fontWeight: '600',
  },
})
