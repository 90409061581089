import React, { useEffect } from 'react'
import { View, StyleSheet, Text, ImageSourcePropType, Image} from 'react-native'
import {
  styles as globalStyles,
  BlockAlysys,
  Dialog,
  Table,
  Tooltips,
  Options,
  ValidateRequiredError,
  SizedBox,
} from '../common'
import { Controller, useForm } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { updateUserProtect } from '../slices/userSlice'
import { connect, ConnectedProps } from 'react-redux'
import Layout from '../constants/Layout'
import { dataSourceHealthCarePlan, dataSourceHealthCarePlanMaternity } from '../constants/Data'
import dataProvider from '../data/dataProvider'
import icDocument from '../assets/icon/document.png'
import BeneficiaryTable from '../components/BeneficiaryTable'
import AgentComponent from '../components/AgentComponent'
import { AgentOffice } from '../constants/Enum'
import { i18nAgentWording } from '../components/AgentWording'

const mapStateToProps = (state: RootState) => ({
  hcPlan: state.hcPlan.hcPlan,
  userProtect: state.user.userProtect,
  needsCartList: state.needsCart.needsCartList,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {
  saveCNA,
  updateUserProtect,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  dataBack: () => void
}

function SaveHealthScreen({
  hcPlan,
  userProtect,
  userInfo,
  currentHandlingCNA,
  updateUserProtect,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice

  const [showPopup, setShowPopup] = React.useState(false)
  const [isMaternity, setIsMaternity] = React.useState(false)

  useEffect(() => {
    if (userProtect?.hcPlan?.hcPlanDetailSub) {
      setValue('answer', userProtect?.hcPlan?.hcPlanDetailSub?.answer)
    }
  }, [userProtect])

  useEffect(() => {
    if (dataProvider.isHospitalDataMaternity(currentHandlingCNA?.customerAge, currentHandlingCNA?.customerGender)) {
      setIsMaternity(true)
    }
  }, [])

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: hcPlan,
  })

  useEffect(() => {
    if (userProtect && getValues('answer')){
      const filter: any = !isMaternity
        ? dataSourceHealthCarePlan.find((x) => x.answer === getValues('answer'))
        : dataSourceHealthCarePlanMaternity.find((x) => x.answer === getValues('answer'));

      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
      
        hcPlan: {
          ...userProtect?.hcPlan,
          hcPlanDetailSub: filter,
          subTotal: dataProvider.countTotalHealthCarePlanData({
            boarding: filter?.boarding,
            dentistry: filter?.dentistry,
            outPatient: filter?.outPatient,
            maternity: filter?.maternity,
            age: currentHandlingCNA?.customerAge,
            gender: currentHandlingCNA?.customerGender,
          }),
        },
      }
      updateUserProtect({
        cnaData,
      })
  }
  }, [getValues('answer')])


  function renderErrorMessage(error?: string) {
    if (error) {
      return (
        <View style={globalStyles.FloatLeft}>
          <ValidateRequiredError title={error} />
        </View>
      )
    } else {
      return <></>
    }
  }

  const columnsMaternity = [
    {
      title: 'Bệnh viện',
      dataIndex: 'answer',
      width: '40%',
    },
    {
      title: 'Nội trú',
      dataIndex: 'boarding',
      text: 'center',
      width: '15%',
    },
    {
      title: 'Ngoại trú',
      dataIndex: 'outPatient',
      text: 'center',
      width: '15%',
    },
    {
      title: 'Nha khoa',
      dataIndex: 'dentistry',
      text: 'center',
      width: '15%',
    },
    {
      title: 'Thai sản',
      dataIndex: 'maternity',
      text: 'center',
      width: '15%',
    },
  ]

  const columns= [
    {
      title: 'Bệnh viện',
      dataIndex: 'answer',
      width: '40%',
    },
    {
      title: 'Nội trú',
      dataIndex: 'boarding',
      text: 'center',
      width: '20%',
    },
    {
      title: 'Ngoại trú',
      dataIndex: 'outPatient',
      text: 'center',
      width: '20%',
    },
    {
      title: 'Nha khoa',
      dataIndex: 'dentistry',
      text: 'center',
      width: '20%',
    },
  ]

  const option = ['Chương trình chăm sóc cơ bản', 'Chương trình chăm sóc nâng cao', 'Chương trình chăm sóc toàn diện', 'Chương trình chăm sóc hoàn hảo']

  const RenderModals = () => {
    return (
      <AgentComponent
        agentOfficeCode={userInfo?.officeCode}
        agentComponentMap={new Map([
          [
            AgentOffice.VIB,
            <Dialog
              type="inform"
              showCloseIcon
              visible={showPopup}
              contentStyle={{ padding: 0 }}
              dialogStyle={{ padding: 0, margin: 20, maxWidth: 1300, width: '99%', paddingTop: 0 }}
              title={
                <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
                  <Text style={[globalStyles.DialogTitle, globalStyles.Flex1, globalStyles.CommonMarginHorizontal16]}>
                    QUYỀN LỢI CHI TIẾT CÁC GÓI CHĂM SÓC Y TẾ
                  </Text>
                </View>
              }
              onClose={() => setShowPopup(false)}
            >
              <BeneficiaryTable />
            </Dialog>],
          ]
        )}
        renderDefault={(_agentOfficeCode) => {
          return (
            <Dialog
              title={
                <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
                  <Image
                  source={icDocument as ImageSourcePropType}
                  style={globalStyles.iconDesktop}
                />
                    <Text style={[globalStyles.DialogTitle,desktopStyle.heading]}>Chi phí tham khảo</Text>
                </View>
              }
              message='Thông tin kế hoạch'
              children={
                <View style={[globalStyles.DisplayFlexCol]}>
                  <View>
                    <Text style={desktopStyle.hintText}>
                      Sau khi tham khảo từ nhiều nguồn trên internet về chi phí khám chữa bệnh tại các bệnh viện công, tư nhân, quốc tế và nước ngoài, khách hàng có thể tham khảo các 
                      chương trình chăm sóc Y tế phù hợp với các hạn mức như sau:
                    </Text>
                  </View>
                  <SizedBox height={10} />
                  <Text style={{
                      flex: 1,
                      width: '100%',
                      textAlign: 'right',
                      paddingBottom: 8,
                  }}>Đơn vị tính: Triệu đồng</Text>
                  <Table
                    dataSource={isMaternity ? dataSourceHealthCarePlanMaternity :  dataSourceHealthCarePlan}
                    columns={isMaternity ? columnsMaternity : columns }
                    columnWidth={200}
                    headerContainerStyle={{width: '100%',}}
                  />
                </View>
              }
              dialogStyle={{ minWidth: 522, paddingTop: 0 }}
              contentStyle={{ paddingHorizontal: isMobileDevice? 15 : 20, paddingTop: 0, }}
              type='inform'
              showCloseIcon
              onClose={() => setShowPopup(false)}
              visible={showPopup}
            />
          )
        }}
      />
    )
  }

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          tooltips={
            <Tooltips
              title={i18nAgentWording({
                agentOfficeCode: userInfo?.officeCode,
                wordingCode: 'NEEDS_ANALYST.HEALTH_CARE_PLAN.REFERENCE_COST',
              })}
              onPress={() => setShowPopup(true)}
              styles={{ position: 'absolute', right: 20, bottom: 25 }}
              image={true}
            />
          }
          borderColor="#26C6DA"
          child={
            <View>
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ onChange, value }) => (
                  <Options
                  options={option}
                  value={value}
                  onChange={(value) => {
                    onChange(value)
                 
                  }}
                  error={errors.answer && renderErrorMessage(errors.answer.message)}
                  title="Bạn muốn chọn chương trình chăm sóc sức khỏe nào?"
                />
                )}
                name="answer"
              />
            </View>
          }
        />
        <SizedBox width={24} />
        <RenderModals />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <BlockAlysys
        title="Chăm sóc Y Tế"
        tooltips={
          <Tooltips
            title={i18nAgentWording({
              agentOfficeCode: userInfo?.officeCode,
              wordingCode: 'NEEDS_ANALYST.HEALTH_CARE_PLAN.REFERENCE_COST',
            })}
            onPress={() => setShowPopup(true)}
            image={true}
          />
        }
        borderColor="#26C6DA"
        child={
          <View style={{marginTop: 10}}>
            <Controller
              control={control}
               rules={{ required: true }}
              defaultValue=""
              render={({ onChange, value }) => (
                <Options
                  options={option}
                  value={value}
                  onChange={(value) => {
                    onChange(value)
                  }}
                  error={errors.answer && renderErrorMessage(errors.answer.message)}
                  title="Bạn muốn chọn chương trình chăm sóc sức khỏe nào?"
                />
              )}
              name="answer"
            />
          </View>
        }
      />
      <SizedBox width={24} />
      <RenderModals />
    </View>
  )
}
export default connector(SaveHealthScreen)

const desktopStyle = StyleSheet.create({
  heading: {
    marginTop: 0,
  },
  hintText: {
    fontSize: 12,
    fontStyle: 'italic',
    marginTop: 10
  },
})
