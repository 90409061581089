import * as React from 'react'
import { Text } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'
import Layout from '../../constants/Layout';

export type ValidateRequiredErrorProps = {
  title?: string
  style?: Record<string, unknown>
  onPress?: () => void
}

export function ValidateRequiredError({ title, style, onPress }: ValidateRequiredErrorProps) {
  const isMobileDevice = Layout.isMobileDevice
  if (isMobileDevice) {
    return <Text style={[globalStyles.ErrorTextMobile, style]}>{title}</Text>
  }
  return <Text style={[globalStyles.ErrorText, style]}>{title}</Text>
}

export default ValidateRequiredError
