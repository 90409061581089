import * as React from 'react'
import { SafeAreaView, View, StyleSheet } from 'react-native'
import SwitchSelector from "react-native-switch-selector";
import { styles } from '../constants/globalStyles';

export const SwitchSelect = StyleSheet.create({
  switchSelected: {
    fontWeight: 'bold',
    color: '#1F1F20',
  },
})

export type SwitchItem = {
  options: any
  initial: number
  activeColor?: string
  value?: number
  selectedColor? :string
  textColor?: string
  buttonColor?: string
  borderRadius?: number
  backgroundColor?: string
  textStyle?: any
  selectedTextStyle?: any
}

export type SwitchlProps = {
  switchOption: SwitchItem
  onPress?: (value:any) => void
}

export function Switch({ switchOption: { options, selectedColor,initial, textColor, buttonColor, borderRadius,value, backgroundColor, textStyle, selectedTextStyle, ...props }, onPress }: SwitchlProps) {
  return (
    <SafeAreaView>
      <View>
        <SwitchSelector
          options={options}
          initial={initial}
          selectedColor={selectedColor}
          textColor={textColor}
          value={value}
          selectedTextStyle={{ ...{
            fontWeight: 'bold',
            color: '#1F1F20',
          }, ...(selectedTextStyle ?? {})}}
          buttonColor={buttonColor}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          onPress={(value) => onPress && onPress(value) }
          textStyle={textStyle}
          {...props}
        />
      </View>
    </SafeAreaView>
  )
}

export default Switch