// components/RadioButton.js
import Layout from '../../constants/Layout';
import React, { useState,useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, SafeAreaView } from 'react-native';
export type OptionsItem = {
    options: string[]
    title: string
    value?: string
    error?: React.ReactNode
    onChange: (option: any) => void
}

export function Options({ options, title, error,value,onChange }: OptionsItem) {

    const [activeOption, setActiveOption] = useState(value);
    const isMobileDevice = Layout.isMobileDevice;
    const isLaptopDevice = Layout.isTabletDevice

    const updateActiveOption = (option: any) => {
        setActiveOption(option)
    }

    useEffect(() => {
        if (value){
            setActiveOption(value);
        }
    }, [value])

    const replaceIndex = (index: number) => {
        switch (index) {
            case 0:
                return 'A'
            case 1:
                return 'B'
            case 2:
                return 'C'
            case 3:
                return 'D'
            default:
                return 'A'
        }
    };

    if (isMobileDevice) {
        return (
            <SafeAreaView >
            <Text style={mobilesStyles.titleOptions} >{title}</Text>
            <View
                style={mobilesStyles.container}
            >
                {options.map((option: string, index: number) => (
                    <TouchableOpacity
                        onPress={() => {
                            onChange(option);
                            updateActiveOption(option);
                        }}
                        key={index}
                        style={[mobilesStyles.boxOptions, { backgroundColor: activeOption === option ? '#E3F8FA' : '#F5F5F5' }]}
                    >
                        <View>
                        <Text
                            style={[mobilesStyles.boxText,{ color: activeOption === option ? '#26C6DA' : 'black' }]}
                        >
                            <Text style={[mobilesStyles.textBold]}>{replaceIndex(index)}. </Text>
                            <Text>{option}</Text>
                        </Text>
                        </View>
                    </TouchableOpacity>
                ))}
               {error && error }

            </View>
        </SafeAreaView>
        )
      }

     if (isLaptopDevice){
        return (
            <SafeAreaView >
                <Text style={styles.titleOptionsTablet} >{title}</Text>
                <View
                    style={styles.container}
                >
                    {options.map((option: string, index: number) => (
                        <TouchableOpacity
                            onPress={() => {
                                onChange(option);
                                updateActiveOption(option);
                            }}
                            key={index}
                            style={[styles.boxOptions, { backgroundColor: activeOption === option ? '#E3F8FA' : '#F5F5F5' }]}
                        >
                            <Text
                                style={[styles.boxTextTablet, { color: activeOption === option ? '#26C6DA' : 'black' }]}
                            >
                                <Text style={styles.textBoldTablet}>{replaceIndex(index)}. </Text>{option}
                            </Text>
                        </TouchableOpacity>
                    ))}
                     {error && error }
                </View>
            </SafeAreaView>
        );
     } 
    return (
        <SafeAreaView >
            <Text style={styles.titleOptions} >{title}</Text>
            <View
                style={styles.container}
            >
                {options.map((option: string, index: number) => (
                    <TouchableOpacity
                        onPress={() => {
                            onChange(option);
                            updateActiveOption(option);
                        }}
                        key={index}
                        style={[styles.boxOptions, { backgroundColor: activeOption === option ? '#E3F8FA' : '#F5F5F5' }]}
                    >
                        <Text
                            style={[styles.boxText, { color: activeOption === option ? '#26C6DA' : 'black' }]}
                        >
                            <Text style={styles.textBold}>{replaceIndex(index)}. </Text>{option}
                        </Text>
                    </TouchableOpacity>
                ))}
                 {error && error }
            </View>
        </SafeAreaView>
    );
}




const styles = StyleSheet.create({
    container: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: 660,
    },
    titleOptions: {
        fontWeight: 'bold',
        marginBottom: 15,
        fontSize: 16,
        color: '#505D6F'
    },
    titleOptionsTablet: {
        fontWeight: 'bold',
        marginBottom: 15,
        fontSize: 13.2667,
        color: '#505D6F'
    },
    boxOptions: {
        justifyContent: 'center',
        minWidth: 150,
        borderRadius: 8,
        width: 310,
        marginRight: 20,
        marginBottom: 15,
        alignItems: 'center',
    },
    boxText: {
        alignItems: 'center',
        width: '100%',
        height: 50,
        display: 'flex',
        fontSize: 16,
        paddingHorizontal: 15,
    },
    boxTextTablet: {
        alignItems: 'center',
        width: '100%',
        height: 50,
        display: 'flex',
        fontSize: 13.2667,
        paddingHorizontal: 15,
    },
    textBold: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    textBoldTablet: {
        fontWeight: 'bold',
        fontSize: 13.2667,
    },
});

const mobilesStyles = StyleSheet.create({
    container: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: 640,
    },
    titleOptions: {
        fontWeight: 'bold',
        marginBottom: 20,
        fontSize: 13,
        color: '#505D6F'
    },
    boxOptions: {
        justifyContent: 'center',
        minWidth: 150,
        borderRadius: 8,
        width: '100%',
        marginBottom: 15,
        alignItems: 'flex-start',
    },
    boxText: {
        alignItems: 'center',
        width: '100%',
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 15,
    },
    textBold: {
        fontWeight: 'bold'
    },
});