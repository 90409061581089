// components/RadioButton.js
import Layout from '../../constants/Layout';
import React, { useState,useEffect } from 'react';
import { SafeAreaView, StyleSheet,View,Text ,TouchableOpacity} from 'react-native';

export type RadioButtonItem = {
  data: any
  title: string
  value?: any
  error?: React.ReactNode
  dafaultValue?:any
  onChange?: (value: any) => void,
  direction?: 'column' | 'row'
  containerStyle?: Record<string, unknown>
  margin?: boolean
  radioItemContainerStyle?: Record<string, unknown>
}

export function RadioButton({ data,title,error,dafaultValue,margin,onChange, direction, containerStyle, radioItemContainerStyle}: RadioButtonItem) {
    const [titleRadio, setTitleRadio] = useState( dafaultValue);
    const isMobileDevice = Layout.isMobileDevice;
    const isTabletDevice = Layout.isTabletDevice


    useEffect(() => {
        if (dafaultValue !== undefined){
            setTitleRadio(dafaultValue);
        }
    }, [dafaultValue])

    if (isMobileDevice) {
        return (
            <View style={mobileStyles.wrapper}>
            <Text style={mobileStyles.radioTitle}>{title}</Text>
            <View style={[mobileStyles.wraperContent, {flexDirection: direction ? direction : 'row'}, containerStyle]}>
            {data.map((res:any, i: number) => {
                return (
                    <View key={res.value} style={[mobileStyles.container, { marginTop: i > 0 ? margin ? 0 : 22 : 0 }]}>
                        <TouchableOpacity
                            style={titleRadio === res.value ? mobileStyles.radioCircleSelect : mobileStyles.radioCircle}
                            onPress={() => {
                                setTitleRadio(res.value);
                                onChange && onChange(res.value)
                            }}>
                              {titleRadio === res.value && <View style={mobileStyles.selectedRb} />}
                        </TouchableOpacity>
                        <Text style={titleRadio === res.value ?  mobileStyles.radioTextSelect : mobileStyles.radioText}>{res.text}</Text>
                    </View>
                );
            })}
                 

        </View>
        {error && error }
        </View>
        )
      }

      if (isTabletDevice) {
        return (
            <SafeAreaView style={tabletStyle.wrapper}>
            <Text style={tabletStyle.radioTitle}>{title}</Text>
            <View style={[tabletStyle.wraperContent, {flexDirection: direction ?? 'row'}, containerStyle, radioItemContainerStyle]}>
            {data.map((res:any, i: number) => {
                return (
                    <View key={res.value} style={[tabletStyle.container, radioItemContainerStyle ? radioItemContainerStyle : {}, { marginTop: i > 0 ? margin ? 0 : 22 : 0 }]}>
                        <TouchableOpacity
                            style={titleRadio === res.value ? tabletStyle.radioCircleSelect : tabletStyle.radioCircle}
                            onPress={() => {
                                setTitleRadio(res.value);
                                onChange && onChange(res.value)
                            }}>
                              {titleRadio === res.value && <View style={tabletStyle.selectedRb} />}
                        </TouchableOpacity>
                        <Text style={titleRadio === res.value ?  tabletStyle.radioTextSelect : tabletStyle.radioText}>{res.text}</Text>
                    </View>
                );
            })}
            <View>
            </View>
        </View>
        {error && error }
        
        </SafeAreaView>
          );
      }
  return (
    <SafeAreaView style={styles.wrapper}>
    <Text style={styles.radioTitle}>{title}</Text>
    <View style={[styles.wraperContent, {flexDirection: direction ?? 'row'}, containerStyle, radioItemContainerStyle]}>
    {data.map((res:any, i: number) => {
        return (
            <View key={res.value} style={[styles.container, radioItemContainerStyle ? radioItemContainerStyle : {}, { marginTop: i > 0 ? margin ? 0 : 22 : 0 }]}>
                <TouchableOpacity
                    style={titleRadio === res.value ? styles.radioCircleSelect : styles.radioCircle}
                    onPress={() => {
                        setTitleRadio(res.value);
                        onChange && onChange(res.value)
                    }}>
                      {titleRadio === res.value && <View style={styles.selectedRb} />}
                </TouchableOpacity>
                <Text style={titleRadio === res.value ?  styles.radioTextSelect : styles.radioText}>{res.text}</Text>
            </View>
        );
    })}
    <View>
    </View>
</View>
{error && error }

</SafeAreaView>
  );
}


const styles = StyleSheet.create({
    wrapper: {
      marginBottom: 30,
      width: '100%',
    },
    wraperContent: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
    },
	container: {
        maxWidth: 200,
        minWidth:160,
        flexDirection: 'row',
        marginTop: 8,
        width: '100%',
	},
    radioTitle: {
        fontWeight: '600',
        marginBottom: 10,
        color:'#505D6F',
        fontSize: 16
    },
    radioText: {
        paddingLeft: 15,
        fontSize: 16,
        color: '#373737',
        fontWeight: '400',
        flex: 1,
    },
    radioTextSelect: {
        paddingLeft: 15,
        fontSize: 16,
        color: '#373737',
        fontWeight: '600',
        flex: 1,
    },
	radioCircle: {
		height: 20,
		width: 20,
		borderRadius: 100,
        backgroundColor:'#F2F2F2',
		alignItems: 'center',
		justifyContent: 'center',
	},
    radioCircleSelect: {
        height: 20,
		width: 20,
		borderRadius: 100,
        backgroundColor:'#F2F2F2',
		alignItems: 'center',
		justifyContent: 'center',
        borderWidth: 1,
        borderColor: '#ED1B2E'
    },
	selectedRb: {
		width: 10,
		height: 10,
		borderRadius: 50,
		backgroundColor: '#ED1B2E',
    },
});

const tabletStyle = StyleSheet.create({
    wrapper: {
      marginBottom: 30,
      width: '100%',
    },
    wraperContent: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
    },
	container: {
        maxWidth: 200,
        minWidth:160,
        flexDirection: 'row',
        marginTop: 8,
        width: '100%',
	},
    radioTitle: {
        fontWeight: '600',
        color:'#505D6F',
        fontSize: 13.2667
    },
    radioText: {
        paddingLeft: 15,
        fontSize: 13.2667,
        color: '#373737',
        fontWeight: '400',
        flex: 1,
    },
    radioTextSelect: {
        paddingLeft: 15,
        fontSize: 13.2667,
        color: '#373737',
        fontWeight: '600',
        flex: 1,
    },
	radioCircle: {
		height: 20,
		width: 20,
		borderRadius: 100,
        backgroundColor:'#F2F2F2',
		alignItems: 'center',
		justifyContent: 'center',
	},
    radioCircleSelect: {
        height: 20,
		width: 20,
		borderRadius: 100,
        backgroundColor:'#F2F2F2',
		alignItems: 'center',
		justifyContent: 'center',
        borderWidth: 1,
        borderColor: '#ED1B2E'
    },
	selectedRb: {
		width: 10,
		height: 10,
		borderRadius: 50,
		backgroundColor: '#ED1B2E',
    },
});

const mobileStyles = StyleSheet.create({
    wrapper: {
        marginBottom: 30
      },
    wraperContent: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
        width: '100%',
    },
	container: {
        minWidth:'50%',
        alignItems: 'center',
        flexDirection: 'row',
	},
    radioTitle: {
        fontWeight: '600',
        marginBottom: 10,
        color:'#505D6F',
        fontSize: 12
    },
    radioText: {
        paddingLeft: 15,
        fontSize: 13,
        color: '#373737',
        fontWeight: '400'
    },
    radioTextSelect: {
        paddingLeft: 15,
        fontSize: 13,
        color: '#373737',
        fontWeight: '600'
    },
	radioCircle: {
		height: 20,
		width: 20,
		borderRadius: 100,
        backgroundColor:'#F2F2F2',
		alignItems: 'center',
		justifyContent: 'center',
	},
    radioCircleSelect: {
        height: 20,
		width: 20,
		borderRadius: 100,
        backgroundColor:'#F2F2F2',
		alignItems: 'center',
		justifyContent: 'center',
        borderWidth: 1,
        borderColor: '#ED1B2E'
    },
	selectedRb: {
		width: 10,
		height: 10,
		borderRadius: 50,
		backgroundColor: '#ED1B2E',
    },
});