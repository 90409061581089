import React from 'react'
import { StyleSheet, View, Text, FlatList, useWindowDimensions, Linking } from 'react-native'
import { SizedBox, TableWithTitle } from '../../../common'
import { ProductType } from '../../../constants/Enum'
import {
  tableFeeColumns,
  tableFeeDataSourceILP,
  tableInterestRateColumns,
  tableInterestRateDataSource,
  tableFeeDataSourceULP,
  dataExclusiveTermsPrintPdf,
  tableFundManageFeeColumns,
  tableFundManageFeeDataSource,
} from '../../../constants/Data'
import RenderHtml from 'react-native-render-html'

type IProps = {
  productType?: string
  dataImportantNote?: Array<{ title: string; content: string }>
}

function Fees({ productType, dataImportantNote }: IProps) {
  const { width } = useWindowDimensions()

  function renderRowData(rowData: { title: string; content: Array<string> }) {
    return (
      <View style={desktopStyles.rowDataContentContainer}>
        <Text style={desktopStyles.rowDataContentLabel}>&#8226;{rowData.title}</Text>
        <FlatList
          data={rowData.content}
          renderItem={(item) => {
            return (
              <View style={desktopStyles.rowDataContentItem}>
                <Text style={[desktopStyles.rowDataContentItemTitle]}>&nbsp;&nbsp;&nbsp;&nbsp;{`-`}</Text>
                <Text style={[desktopStyles.rowDataContentItemContent]}>
                  <RenderHtml html={item.item} onLinkPress={(evt, href) => { Linking.openURL(href) }} />
                </Text>
              </View>
            )
          }}
        />
      </View>
    )
  }

  function renderImportantNoteDataItem(rowData: { title: string; content: string }) {
    return (
      <View style={desktopStyles.rowDataContentContainer}>
        <View style={desktopStyles.rowDataContentItem}>
          <Text style={[desktopStyles.rowDataContentItemTitle]}>&#8226;</Text>
          <Text style={[desktopStyles.rowDataContentItemContent]}>
            <RenderHtml html={rowData.content} onLinkPress={(evt, href) => { Linking.openURL(href) }} />
          </Text>
        </View>
      </View>
    )
  }

  if (productType === ProductType.ILP) {
    return (
      <View style={desktopStyles.container}>
        <View style={desktopStyles.contentContainer}>
          <Text style={desktopStyles.pageTitle}>A. Các loại phí</Text>
          <SizedBox height={10} />
          <Text style={desktopStyles.title}>1. Phí bảo hiểm rủi ro</Text>
          <Text style={desktopStyles.content}>
            Được khấu trừ hàng tháng và được xác định dựa trên Số tiền bảo hiểm, Thời hạn bảo hiểm, độ tuổi, giới tính,
            nghề nghiệp và tình trạng sức khỏe của Người được bảo hiểm.
          </Text>
          <Text style={desktopStyles.title}>2. Phí ban đầu</Text>
          <Text style={desktopStyles.content}>
            Khấu trừ từ phí bảo hiểm cơ bản và phí bảo hiểm đóng thêm trước khi được phân bổ vào Giá trị tài khoản cơ
            bản và Giá trị tài khoản đầu tư thêm tương ứng.
          </Text>
          <View style={desktopStyles.tableContainer}>
            <TableWithTitle
              columns={tableFeeColumns as any}
              dataSource={tableFeeDataSourceILP}
              tableHead={[]}
              tableTitle={[]}
              tableData={[
                [85, 75, 10, 5, 0],
                [85, 75, 10, 5, 0],
                [85, 75, 10, 5, 0],
              ]}
              containerStyle={{
                borderRadius: 20,
                backgroundColor: '#FAFBFF',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#D7DFFF',
                paddingBottom: 10,
              }}
              headerContainerStyle={{
                backgroundColor: '#FAFBFF',
              }}
              bodyContainerStyle={{
                backgroundColor: '#FAFBFF',
              }}
              rowStyle={{
                borderBottomWidth: 0,
              }}
              headerStyle={{
                color: '#505D6F',
                fontSize: 14,
                fontWeight: 'normal',
              }}
              isHaveTitle={true}
              titleStyle={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#505D6F',
              }}
              bodyStyle={{
                fontSize: 14,
                fontWeight: '600',
                color: '#505D6F',
                paddingVertical: 10,
              }}
            />
          </View>
          <Text style={desktopStyles.title}>3. Phí quản lý quỹ</Text>
          <Text style={desktopStyles.content}>
            Được khấu trừ khi tính Giá trị tài sản thuần của mỗi Quỹ liên kết đơn vị.
          </Text>
          <View style={desktopStyles.tableContainer}>
            <TableWithTitle
              columns={tableFundManageFeeColumns as any}
              dataSource={tableFundManageFeeDataSource}
              tableHead={[]}
              tableTitle={[]}
              tableData={[]}
              containerStyle={{
                borderRadius: 20,
                backgroundColor: '#FAFBFF',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#D7DFFF',
                paddingBottom: 10,
              }}
              headerContainerStyle={{
                backgroundColor: '#FAFBFF',
              }}
              bodyContainerStyle={{
                backgroundColor: '#FAFBFF',
              }}
              rowStyle={{
                borderBottomWidth: 0,
              }}
              headerStyle={{
                color: '#505D6F',
                fontSize: 14,
                fontWeight: 'normal',
              }}
              isHaveTitle={true}
              titleStyle={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#505D6F',
              }}
              bodyStyle={{
                fontSize: 14,
                fontWeight: '600',
                color: '#505D6F',
                paddingVertical: 10,
              }}
            />
          </View>
          <Text style={desktopStyles.title}>4. Phí quản lý hợp đồng</Text>
          <Text style={desktopStyles.content}>40.000 đồng / tháng</Text>
          <SizedBox height={20} />
          <Text style={desktopStyles.pageTitle}>B. Các điều khoản loại trừ</Text>
          <SizedBox height={12} />
          <Text style={[desktopStyles.contentTitle, { marginLeft: 8 }]}>
            (Lưu ý: tham khảo điều khoản loại trừ của các sản phẩm bổ trợ (nếu có) trong Quy tắc, điều khoản sản phẩm
            bảo hiểm tương ứng)
          </Text>
          <SizedBox height={8} />
          <FlatList
            data={dataExclusiveTermsPrintPdf}
            renderItem={(item) => {
              return renderRowData(item.item)
            }}
            style={{ marginLeft: 8 }}
          />
          {/* Important notes */}
          <Text style={desktopStyles.pageTitle}>C. Lưu ý quan trọng </Text>
          <FlatList
            data={dataImportantNote}
            renderItem={(item) => {
              return renderImportantNoteDataItem(item.item)
            }}
          />
        </View>
      </View>
    )
  } else {
    return (
      <View style={desktopStyles.container}>
        <View style={desktopStyles.contentContainer}>
          <Text style={desktopStyles.pageTitle}>A. Các loại phí</Text>
          <SizedBox height={10} />
          <Text style={desktopStyles.title}>1. Phí bảo hiểm rủi ro</Text>
          <Text style={desktopStyles.content}>
            Được khấu trừ hàng tháng và được xác định dựa trên Số tiền bảo hiểm, độ tuổi, giới tính, nghề nghiệp và tình
            trạng sức khỏe của Người được bảo hiểm.
          </Text>
          <Text style={desktopStyles.title}>2. Phí ban đầu</Text>
          <Text style={desktopStyles.content}>
            Khấu trừ từ phí bảo hiểm cơ bản và phí bảo hiểm Tích lũy thêm trước khi được phân bổ vào Giá trị tài khoản
            cơ bản và Giá trị tài khoản tích lũy thêm tương ứng.
          </Text>
          <View style={desktopStyles.tableContainer}>
            <TableWithTitle
              columns={(tableFeeColumns as any).map((e: any, i: number) => {
                return {
                  ...e,
                  renderCell:
                    i > 0
                      ? (value: any, record: any) => <Text style={{ marginLeft: 14, paddingLeft: 10 }}>{value}</Text>
                      : null,
                }
              })}
              dataSource={tableFeeDataSourceULP}
              tableHead={[]}
              tableTitle={[]}
              tableData={[
                [85, 75, 10, 5, 0],
                [85, 75, 10, 5, 0],
                [85, 75, 10, 5, 0],
              ]}
              containerStyle={{
                borderRadius: 24,
                backgroundColor: '#FAFBFF',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#D7DFFF',
              }}
              headerContainerStyle={{
                backgroundColor: '#FAFBFF',
                paddingLeft: 10,
              }}
              bodyContainerStyle={{
                backgroundColor: '#FAFBFF',
              }}
              rowStyle={{
                borderBottomWidth: 0,
              }}
              headerStyle={{
                color: '#505D6F',
                fontSize: 14,
                fontWeight: 'normal',
              }}
              isHaveTitle={true}
              titleStyle={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#505D6F',
                paddingLeft: 10,
              }}
              bodyStyle={{
                fontSize: 14,
                fontWeight: '600',
                color: '#505D6F',
                paddingVertical: 10,
              }}
            />
          </View>
          <Text style={desktopStyles.title}>3. Lãi suất cam kết:</Text>
          <Text style={desktopStyles.content}>
            Lãi suất đầu tư sau khi trừ phí quản lý quỹ luôn được đảm bảo ở mức tối thiểu như sau:
          </Text>
          <View style={desktopStyles.tableContainer}>
            <TableWithTitle
              columns={(tableInterestRateColumns as any).map((e: any, i: number) => {
                return {
                  ...e,
                  renderCell:
                    i > 0
                      ? (value: any, record: any) => <Text style={{ marginLeft: 14, paddingLeft: 20 }}>{value}</Text>
                      : null,
                }
              })}
              dataSource={tableInterestRateDataSource}
              tableHead={[]}
              tableTitle={[]}
              tableData={[]}
              containerStyle={{
                borderRadius: 24,
                backgroundColor: '#FAFBFF',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#D7DFFF',
              }}
              headerContainerStyle={{
                backgroundColor: '#FAFBFF',
                paddingLeft: 10,
              }}
              bodyContainerStyle={{
                backgroundColor: '#FAFBFF',
              }}
              rowStyle={{
                borderBottomWidth: 0,
              }}
              headerStyle={{
                color: '#505D6F',
                fontSize: 14,
                fontWeight: 'normal',
              }}
              isHaveTitle={true}
              titleStyle={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#505D6F',
                paddingLeft: 10,
              }}
              bodyStyle={{
                fontSize: 14,
                fontWeight: '600',
                color: '#505D6F',
                paddingVertical: 10,
              }}
            />
          </View>
          <Text style={desktopStyles.title}>4. Phí quản lý hợp đồng</Text>
          <Text style={desktopStyles.content}>40.000 đồng / tháng</Text>
          <SizedBox height={10} />
          <Text style={desktopStyles.pageTitle}>B. Các điều khoản loại trừ</Text>
          <SizedBox height={12} />
          <Text style={[desktopStyles.contentTitle, { marginLeft: 8 }]}>
            (Lưu ý: tham khảo điều khoản loại trừ của các sản phẩm bổ trợ (nếu có) trong Quy tắc, điều khoản sản phẩm
            bảo hiểm tương ứng)
          </Text>
          <SizedBox height={8} />
          <FlatList
            data={dataExclusiveTermsPrintPdf}
            renderItem={(item) => {
              return renderRowData(item.item)
            }}
            style={{ marginLeft: 8 }}
          />
          {/* Important notes */}
          <Text style={desktopStyles.pageTitle}>C. Lưu ý quan trọng </Text>
          <FlatList
            data={dataImportantNote}
            renderItem={(item) => {
              return renderImportantNoteDataItem(item.item)
            }}
          />
        </View>
      </View>
    )
  }
}

export default Fees

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    flexDirection: 'column',
    flex: 1,
  },
  pageTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    color: '#000',
    marginTop: 5,
    marginBottom: 8,
  },
  content: {
    fontSize: 15,
    fontWeight: 'normal',
    color: '#000',
    marginBottom: 8,
  },
  tableContainer: {
    marginTop: 10,
    marginBottom: 20,
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: 40,
    paddingVertical: 30,
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentTitle: {
    color: '#585c6c',
  },
  rowDataContentContainer: {
    marginTop: 10,
  },
  rowDataContentLabel: {
    fontWeight: '600',
    fontSize: 13,
    textAlign: 'left',
    marginBottom: 16,
  },
  rowDataContentItem: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  rowDataContentItemTitle: {
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '600',
    marginRight: 4,
    textAlign: 'left',
  },
  rowDataContentItemContent: {
    flex: 1,
    fontSize: 13,
    color: '#585c6c',
    fontWeight: '400',
    marginLeft: 4,
    textAlign: 'left',
  },
})
