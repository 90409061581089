import Layout from '../constants/Layout'

const STANDARD_SCREEN_WIDTH = 400
const STANDARD_SCREEN_HEIGHT = 580

export function FontSizeValue(
  standardFontSize: number,
  standardScreenWidth = STANDARD_SCREEN_WIDTH,
  standardScreenHeight = STANDARD_SCREEN_HEIGHT
) {
  let ratio = Layout.window.width / standardScreenWidth
  if (ratio > 1.5) {
    ratio = 1.5
  }
  return standardFontSize * ratio
}
