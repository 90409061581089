import { Block } from '../../../common'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import ChartBox from './ChartBox'

type IProps = {
  customerAge: number
  productTypeSolution?: string
  data?: Array<{ policyYear: number; ape: number; fundValue: number }>
  dataTopUp?: Array<{ policyYear: number; ape: number; fundValue: number }>
  style?: Record<string, unknown>
  extraInvest?: number
  isLoadingChartData?: boolean
  editable?: boolean
  onConfirm: (quotation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }) => void
  quotationFundAllocation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }
  onOpenExtraInvestModal: () => void
  onOpenProfitModal: () => void
}

function SummarySolution({
  customerAge,
  data,
  style,
  extraInvest,
  dataTopUp,
  editable,
  onConfirm,
  productTypeSolution,
  quotationFundAllocation,
  isLoadingChartData,
  onOpenExtraInvestModal,
  onOpenProfitModal
}: IProps) {
  return (
    <View style={[desktopStyles.container, style]} pointerEvents={editable ? 'auto' : 'none'}>
      <Block
        showTitle={false}
        child={
          <View>
            {/* Chart here */}
            <ChartBox
              customerAge={customerAge}
              data={data}
              dataTopUp={dataTopUp}
              style={{ marginTop: 0 }}
              extraInvest={extraInvest}
              productTypeChart={productTypeSolution}
              editable={editable}
              onConfirm={onConfirm}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={quotationFundAllocation}
              onOpenExtraInvestModal={onOpenExtraInvestModal}
              onOpenProfitModal={onOpenProfitModal}
            />
          </View>
        }
      />
    </View>
  )
}

export default SummarySolution

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
})
