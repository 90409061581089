import React from 'react'
import { StyleSheet, View } from 'react-native'
import SummaryHealthCareProgram from './SummayHealthCareProgram'
import SummaryMainBenefit from './SummaryMainBenefit'
import CNADetail from '../../../model/CNADetail'
import { mathHelper, SizedBox } from '../../../common'

type IProps = {
  cna?: CNADetail
  hospitalData?: any
}

function SummaryBenefitILP({ cna, hospitalData }: IProps) {
  return (
    <View style={[desktopStyles.container]}>
      <SummaryMainBenefit
        data={[
          {
            title: 'Số tiền bảo hiểm quyền lợi Tử vong / TTTB&VV',
            value: cna?.sumAssured,
            show: true,
            showPlaceholder: true,
          },
          {
            title: 'Tử vong do tai nạn, cộng thêm',
            value: mathHelper.getExtraProfitFatalByAccident(cna),
            show: true,
            showPlaceholder: true,
          },
          {
            title: 'Bệnh hiểm nghèo',
            value: mathHelper.getExtraProfitByFatalDisease(cna),
            show: cna?.hcFatalDisease?.isSelect,
            showPlaceholder: true,
          },
          { title: 'Miễn thu phí bệnh hiểm nghèo', value: 0, show: true },
          { title: 'Kết quả đầu tư của Quỹ', value: 0, show: true, isRequired: true },
        ]}
        style={{ marginTop: 8, flex: 1 }}
      />
      <SizedBox width={8} />
      <SummaryHealthCareProgram
        data={[
          { title: 'Nội trú', value: cna?.hcPlan?.isSelect ? hospitalData?.boarding : 0 },
          { title: 'Ngoại trú', value: cna?.hcPlan?.isSelect ? hospitalData?.outPatient : 0 },
          { title: 'Nha khoa', value: cna?.hcPlan?.isSelect ? hospitalData?.dentistry : 0 },
          { title: 'Thai sản', value: cna?.hcPlan?.isSelect ? hospitalData?.maternity : 0 },
        ]}
        style={{ marginTop: 8, flex: 1 }}
      />
    </View>
  )
}

export default SummaryBenefitILP

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
})
