import { CnaStatus } from '../constants/Enum'

export enum CreateCnaPageStep {
  NEEDS_CART = 3,
  ANALYZE_NEEDS = 4,
  NEEDS_OVERALL = 5,
  ADVISOR = 6,
  SOLUTION = 7,
}
export function getStatusToSave(
  currentStatus?: CnaStatus,
  pageStep?: CreateCnaPageStep,
  requiredCustomerInfo?: any,
  isSavingAsDraft?: boolean
) {
  if (!currentStatus) {
    return CnaStatus.DRAFT
  }
  if (isSavingAsDraft) {
    switch (currentStatus) {
      case CnaStatus.DRAFT:
        if (pageStep === CreateCnaPageStep.SOLUTION) {
          return CnaStatus.NEED_CONTACT_INFO
        } else {
          return CnaStatus.DRAFT
        }
      case CnaStatus.NEED_CONTACT_INFO:
        return CnaStatus.NEED_CONTACT_INFO
      case CnaStatus.COMPLETED:
        if (requiredCustomerInfo) {
          return CnaStatus.COMPLETED
        } else {
          return CnaStatus.NEED_CONTACT_INFO
        }
      default:
        return CnaStatus.DRAFT
    }
  } else {
    switch (currentStatus) {
      case CnaStatus.DRAFT:
        if (pageStep === CreateCnaPageStep.ADVISOR || pageStep === CreateCnaPageStep.SOLUTION) {
          return CnaStatus.NEED_CONTACT_INFO
        } else {
          return CnaStatus.DRAFT
        }
      case CnaStatus.NEED_CONTACT_INFO:
      case CnaStatus.COMPLETED:
        if (requiredCustomerInfo) {
          return CnaStatus.COMPLETED
        } else {
          return CnaStatus.NEED_CONTACT_INFO
        }
      default:
        return CnaStatus.DRAFT
    }
  }
}
