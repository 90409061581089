import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image } from 'react-native'
import moment from 'moment'
import icUsers from '../../../assets/images/2-user.png'
import icProfile from '../../../assets/images/profile.png'
import { SizedBox } from '../../../common'
import i18n from 'i18n-js'
import Layout from '../../../constants/Layout'
import Colors from '../../../common/constants/Colors'
import imgAvatar from '../../../assets/images/avatar.png'
import img2Heart from '../../../assets/images/2-heart.png'

type IProps = {
  gender?: string
  age?: number
  name?: string
  maritalStatus?: string
  style?: Record<string, unknown>
}

function UserInfo({ gender, age, name, maritalStatus, style }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  function renderInfoItem(icon?: ImageSourcePropType, title?: string, value?: string) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.infoItemContainer}>
          <Image source={icon as ImageSourcePropType} style={mobileStyles.infoItemIcon} />
          <Text style={mobileStyles.infoItemTitle}>{title}</Text>
          <Text style={mobileStyles.infoItemValue}>{value}</Text>
        </View>
      )
    }
    if (isTabletDevice) {
      return (
        <View style={tabletStyles.infoItemContainer}>
          <Image source={icon as ImageSourcePropType} style={tabletStyles.infoItemIcon} />
          <Text style={tabletStyles.infoItemTitle}>{title}</Text>
          <Text style={tabletStyles.infoItemValue}>{value}</Text>
        </View>
      )
    }
    return (
      <View style={desktopStyles.infoItemContainer}>
        <Image source={icon as ImageSourcePropType} style={desktopStyles.infoItemIcon} />
        <Text style={desktopStyles.infoItemTitle}>{title}</Text>
        <Text style={desktopStyles.infoItemValue}>{value}</Text>
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={mobileStyles.container}>
        <SizedBox height={10} />
        <View style={[mobileStyles.contentContainer, style]}>
          <View style={mobileStyles.infoContainer}>
            <View style={mobileStyles.avatarContainer}>
              <Image source={imgAvatar as ImageSourcePropType} style={mobileStyles.avatar} />
              <Text style={mobileStyles.name}>{name}</Text>
            </View>
            {renderInfoItem(
              icUsers as ImageSourcePropType,
              i18n.t('COMMON.GENDER'),
              gender ? i18n.t(`GENDER.${gender}`) : ''
            )}
            {renderInfoItem(icProfile as ImageSourcePropType, i18n.t('COMMON.AGE'), `${age ?? ''}`)}
            {renderInfoItem(
              img2Heart as ImageSourcePropType,
              i18n.t('COMMON.MARITAL_STATUS'),
              maritalStatus ? i18n.t(`MARITAL_STATUS.${maritalStatus}`) : ''
            )}
          </View>
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={tabletStyles.container}>
        <Text style={tabletStyles.title}>{i18n.t('SOLUTION.CUSTOMER_INFO')}</Text>
        <SizedBox height={10} />
        <View style={[tabletStyles.contentContainer, style]}>
          <View style={tabletStyles.avatarContainer}>
            <Image source={imgAvatar as ImageSourcePropType} style={tabletStyles.avatar} />
            <Text style={tabletStyles.name}>{name}</Text>
          </View>
          <View style={tabletStyles.infoContainer}>
            {renderInfoItem(
              icUsers as ImageSourcePropType,
              i18n.t('COMMON.GENDER'),
              gender ? i18n.t(`GENDER.${gender}`) : ''
            )}
            {renderInfoItem(icProfile as ImageSourcePropType, i18n.t('COMMON.AGE'), `${age ?? ''}`)}
            {renderInfoItem(
              img2Heart as ImageSourcePropType,
              i18n.t('COMMON.MARITAL_STATUS'),
              maritalStatus ? i18n.t(`MARITAL_STATUS.${maritalStatus}`) : ''
            )}
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={desktopStyles.container}>
      <Text style={desktopStyles.title}>{i18n.t('SOLUTION.CUSTOMER_INFO')}</Text>
      <SizedBox height={16} />
      <View style={[desktopStyles.contentContainer, style]}>
        <View style={desktopStyles.avatarContainer}>
          <Image source={imgAvatar as ImageSourcePropType} style={desktopStyles.avatar} />
          <Text style={desktopStyles.name}>{name}</Text>
        </View>
        <View style={desktopStyles.infoContainer}>
          {renderInfoItem(
            icUsers as ImageSourcePropType,
            i18n.t('COMMON.GENDER'),
            gender ? i18n.t(`GENDER.${gender}`) : ''
          )}
          {renderInfoItem(icProfile as ImageSourcePropType, i18n.t('COMMON.AGE'), `${age ?? ''}`)}
          {renderInfoItem(
            img2Heart as ImageSourcePropType,
            i18n.t('COMMON.MARITAL_STATUS'),
            maritalStatus ? i18n.t(`MARITAL_STATUS.${maritalStatus}`) : ''
          )}
        </View>
      </View>
    </View>
  )
}

export default UserInfo

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    paddingLeft: 64,
  },
  avatar: {
    width: 38,
    height: 38,
  },
  contentContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  name: {
    fontSize: 18,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
    textTransform: 'uppercase',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 33.17,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    paddingLeft: 40,
  },
  avatar: {
    width: 38,
    height: 38,
  },
  contentContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  name: {
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
    textTransform: 'uppercase',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFBFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  avatarContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 18,
    marginRight: 8,
  },
  avatar: {
    width: 38,
    height: 38,
  },
  contentContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
    textTransform: 'uppercase',
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderRadius: 41.5,
    paddingBottom: 16,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 18,
    marginRight: 8,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 8,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
})
