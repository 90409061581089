import { ProfileInfoTab } from '../common'
import * as React from 'react'
import {
  StyleSheet,
  Text,
  View,
  Image,
  ImageSourcePropType,
  TouchableOpacity,
  Animated,
} from 'react-native'
import icLeftCircle from '../assets/images/circle-chevron-left-grey.png'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../store'
import { updateProfile } from '../slices/needsCartSlice'
import { saveCNA } from '../slices/cnaSlice'

const mapStateToProps = (state: RootState) => ({
  customerProfile: state?.needsCart.profileInfo,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
})

const mapDispatchToProps = {
  updateProfile,
  saveCNA,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type EditProfileTabProps = PropsFromRedux & {
  disabled?: boolean
}

export function EditProfileTab({ updateProfile, customerProfile, saveCNA, currentHandlingCNA ,disabled}: EditProfileTabProps) {
  const [showProfileTab, setShowProfileTab] = React.useState(false)
  const [showBlurBg, setShowBlurBg] = React.useState(false)
  const transformAnim = React.useRef(new Animated.Value(-335)).current
  const opacityAnim = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    if (showProfileTab) {
      Animated.timing(transformAnim, {
        toValue: -1,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      setShowBlurBg(true)
      Animated.timing(opacityAnim, {
        toValue: 0.2,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
    } else {
      Animated.timing(transformAnim, {
        toValue: -335,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(opacityAnim, {
        toValue: -1,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start(() => {
        setShowBlurBg(false)
      })
    }
  }, [opacityAnim, showProfileTab, transformAnim])

  function onUpdate(profileData: any) {
    updateProfile(profileData)
    setShowProfileTab(false)
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      customerGender: profileData.gender,
      customerAge: profileData.age ? parseInt(profileData.age) : 0,
      maritalStatus: profileData.maritalStatus,
    } as any
    body.protectIncome = {
      ...body.protectIncome,
      monthlyIncome: profileData.monthlyIncome,
    }
    body.protectLife = {
      ...body.protectLife,
      monthlyFixedExpense: profileData.monthlyExpense,
    }
    saveCNA(body)
  }

  return (
    <>
      <TouchableOpacity onPress={() => setShowProfileTab(true)} style={styles.touchContainer} activeOpacity={1}>
        <View style={styles.container}>
          <Image source={icLeftCircle as ImageSourcePropType} style={styles.icon} />
          <Text style={styles.label}>Thông tin của bạn</Text>
        </View>
      </TouchableOpacity>
      {showBlurBg && (
        <Animated.View style={[styles.blurBg, { opacity: opacityAnim }]}>
          <TouchableOpacity onPress={() => setShowProfileTab(false)} style={styles.blurBg}>
            <View style={styles.blurBg}></View>
          </TouchableOpacity>
        </Animated.View>
      )}
      <Animated.View style={[styles.profileTabContainer, { right: transformAnim }]}>
        <ProfileInfoTab onUpdateProfile={onUpdate}  disabled={disabled} profileInfo={customerProfile} />
      </Animated.View>
    </>
  )
}

export default connector(EditProfileTab)

const styles = StyleSheet.create({
  touchContainer: {
    transform: [{ rotateZ: '-90deg' }],
    width: 310,
    position: 'absolute',
    right: -140,
    top: '34%',
  },
  container: {
    width: 310,
    height: 40,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: '#D62828',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  label: {
    flex: 1,
    textAlign: 'center',
    color: '#FFF',
    fontSize: 16,
    fontWeight: '700',
  },
  icon: {
    width: 24,
    height: 24,
    transform: [{ rotateZ: '90deg' }],
  },
  profileTabContainer: {
    position: 'absolute',
    right: -335,
    top: 61,
    zIndex: 1001,
    bottom: 0,
  },
  blurBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'grey',
    opacity: 0.2,
    zIndex: 1001,
  },
})
