import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'


export interface ProtectIncome {
  isTemporaryCheck?: boolean 
  // isSelect?: boolean
  monthlyIncome?: number
  month?: number
  subTotal?: number
}


export interface protectIncomState {
  loading?: boolean
  protectIncome?:  ProtectIncome
}

const initialState: protectIncomState = {
  loading: false,
  protectIncome: {
    isTemporaryCheck: true,
    monthlyIncome: 0,
    month: 0,
    subTotal: 0,
    },
}

export const updateProtectIncomeSlice = createAsyncThunk('users/updateProtectIncomeSlice', async (data) => {
  return data
});


export const protectIncomeSlice = createSlice({
  name: 'protectIncome',
  initialState,
  reducers: {
    loadProtectIncome: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.protectIncome = action.payload
    },
    updateProtectIncome: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.protectIncome = action.payload.cnaData
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProtectIncomeSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadProtectIncome, updateProtectIncome } = protectIncomeSlice.actions

const { reducer } = protectIncomeSlice
export default reducer
