import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  isTabletDevice: width < 1300,
  isTabletSmallDevice: width < 1024,
  isMobileDevice: width < 768,
  isSmallDevice: width < 375,
};
