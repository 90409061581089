import CNADetail from 'model/CNADetail'

export const mathHelper = {
  divideWithDynamicPrecisionResult,
  getExtraProfitFatalByAccident,
  getExtraProfitByFatalDisease,
  countProtectIncomeTotal,
  countProtectLifeTotal,
  countRetirementAnnualTotal,
} as const

/**
 * Return a / b with dynamic precision
 * @param a
 * @param b
 */
function divideWithDynamicPrecisionResult(a: number, b: number, fixed = 1, round = true) {
  let strRegex = `^-?\\\d+(?:\\\.\\\d{0,${fixed}})?`
  let regex = new RegExp(strRegex)
  const result = a / b
  if (result - Math.floor(result) !== 0) {
    if (round) {
      return parseFloat(result.toFixed(fixed))
    } else {
      if (result?.toString()?.match(regex) &&
        result?.toString()?.match(regex) !== null &&
        result?.toString()?.match(regex)!.length > 0) {
          return parseFloat(result?.toString()?.match(regex)![0])
      } else {
        return result
      }
    }
  } else {
    return Math.ceil(result)
  }
}

function getExtraProfitFatalByAccident(cna?: CNADetail): number {
  const sumAssured = cna?.sumAssured
  if (!sumAssured) {
    return 0
  }
  const doubleSumAssured = 2 * sumAssured
  const halfSumAssured = sumAssured / 2
  if (cna?.product === 'ILP') {
    const min = 100
    const max = Math.min(5000, doubleSumAssured)
    if (halfSumAssured < min) {
      return min
    }
    if (halfSumAssured > max) {
      return max
    }
    return halfSumAssured
  }
  if (cna?.product === 'ULP') {
    const min = 100
    const max = doubleSumAssured
    if (halfSumAssured < min) {
      return min
    }
    if (halfSumAssured > max) {
      return max
    }
    return halfSumAssured
  }
  return 0
}

function getExtraProfitByFatalDisease(cna?: CNADetail): number {
  const sumAssured = cna?.sumAssured ?? 0
  const fatalDiseaseProfit = cna?.hcFatalDisease?.total ?? 0
  const min = 100
  let max = sumAssured
  if (max <= min) {
    max = min
  }
  if (fatalDiseaseProfit < min) {
    return min
  }
  if (fatalDiseaseProfit > max) {
    return max
  }
  return fatalDiseaseProfit
}

function countProtectIncomeTotal(monthlyIncome: number, months: number) {
  if (!monthlyIncome || !months) {
    return 0
  }
  return monthlyIncome * months
}

function countProtectLifeTotal(monthlyFixedExpense?: number, months?: number, monthlySubsidy?: number, subsidyMonth?: number) {
  if (!monthlyFixedExpense || !months || !monthlySubsidy || !subsidyMonth) {
    return 0
  }
  return monthlyFixedExpense * months + monthlySubsidy * subsidyMonth
}

function countRetirementAnnualTotal(duration?: number, spendingPerMonth?: number) {
  if (!duration || !spendingPerMonth) {
    return 0
  }
  return duration * spendingPerMonth * 12
}
