import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class investment {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
    money? : number
    year? : number
    isPrepare? : boolean 
    saveMoney? : number
  }
  

export interface investmentState {
  loading?: boolean
  investment?: investment
}

const initialState: investmentState = {
  loading: false,
  investment:  {
    isTemporaryCheck: true,
    // isSelect: true,
    subTotal: 0,
  },
}

export const updateInvestmentSlice = createAsyncThunk('users/updateInvestmentSlice', async (data) => {
  return data
});



export const investmentSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {
    loadInvestmentState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.investment = action.payload
    },
    updateInvestmentState: (state, action: PayloadAction<investment>) => {
      state.investment = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateInvestmentSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadInvestmentState, updateInvestmentState } = investmentSlice.actions

const { reducer } = investmentSlice
export default reducer
