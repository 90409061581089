import { FilledButton } from '../../../common'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ImageSourcePropType,
  Image,
} from 'react-native'
import { ButtonGroup } from 'react-native-elements'
import icPagingPrev from '../../../assets/images/paging-prev.png'
import icPagingNext from '../../../assets/images/paging-next.png'
import { styles as globalStyles } from '../../../common'
import NumberFormat from 'react-number-format'
import i18n from 'i18n-js'

const MAX_PAGE_DISPLAY = 3

export type PaginationProps = {
  total: number
  pageSize: number
  pageIndex: number
  onPageChanged: (pageIndex: number) => void
}

export function Pagination({ total, pageSize, pageIndex, onPageChanged }: PaginationProps) {
  const [pages, setPages] = useState(0)
  const [buttons, setButtons] = useState<Array<string>>()
  const [showGoToPage, setShowGoToPage] = useState(false)
  const [pageToGoInputted, setPageToGoInputted] = useState(pageIndex)

  useEffect(() => {
    setPages(Math.ceil(total / pageSize))
  }, [total, pageSize])

  useEffect(() => {
    let newButtons: Array<string> = []
    for (let i = 0; i < pages; i++) {
      newButtons.push(`${i + 1}`)
    }
    if (newButtons.length > MAX_PAGE_DISPLAY) {
      if (pageIndex <= MAX_PAGE_DISPLAY) {
        newButtons = newButtons.slice(0, 3)
        newButtons.push('...')
        newButtons.push(`${pages}`)
        setButtons([...newButtons])
      } else if (pageIndex > MAX_PAGE_DISPLAY && pageIndex < pages - MAX_PAGE_DISPLAY) {
        newButtons = []
        newButtons.push('1')
        newButtons.push('...')
        newButtons.push(`${pageIndex - 1}`)
        newButtons.push(`${pageIndex}`)
        newButtons.push(`${pageIndex + 1}`)
        newButtons.push('...')
        newButtons.push(`${pages}`)
        setButtons([...newButtons])
      } else {
        newButtons = []
        newButtons.push('1')
        newButtons.push('...')
        for (let i = pageIndex - 1; i < pages; i++) {
          newButtons.push(`${i + 1}`)
        }
        setButtons([...newButtons])
      }
    } else {
      setButtons([...newButtons])
    }
  }, [pages, pageIndex])

  useEffect(() => {})

  const onPrev = () => {
    if (pageIndex === 1) {
      return
    }
    onPageChanged(pageIndex - 1)
  }
  const onNext = () => {
    if (pageIndex === pages) {
      return
    }
    onPageChanged(pageIndex + 1)
  }

  return (
    <SafeAreaView>
      <View style={styles.pagination}>
        <Text style={styles.total}>{`Tổng cộng ${pages} trang`}</Text>
        <TouchableOpacity onPress={() => onPrev()} style={styles.button}>
          <Image source={icPagingPrev as ImageSourcePropType} style={globalStyles.Icon12} />
        </TouchableOpacity>
        <ButtonGroup
          onPress={(index: number) => {
            if (buttons && buttons[index] === '...') {
              setShowGoToPage(!showGoToPage)
            } else {
              onPageChanged(parseInt(buttons && buttons[index] ? buttons[index] : '1'))
              setShowGoToPage(false)
            }
          }}
          selectedIndex={buttons?.findIndex(e => e === `${pageIndex}`)}
          buttons={buttons}
          buttonStyle={styles.button}
          containerStyle={styles.container}
          buttonContainerStyle={styles.buttonContainer}
          textStyle={styles.btnText}
          selectedButtonStyle={styles.selectedButton}
          selectedTextStyle={styles.selectedText}
        />
        <TouchableOpacity onPress={() => onNext()} style={styles.button}>
          <Image source={icPagingNext as ImageSourcePropType} style={globalStyles.Icon12} />
        </TouchableOpacity>
        <View style={[styles.menuContainer, { display: showGoToPage ? 'flex' : 'none' }]}>
          <Text style={styles.menuLabel}>Đi tới trang</Text>
          <View style={styles.inputPageContainer}>
            <NumberFormat
              placeholder={i18n.t('COMMON.INPUT')}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 10,
                paddingBottom: 10,
                width: 100,
                borderWidth: 1,
                borderRightWidth: 0,
                borderColor: '#D7DFFF',
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              value={pageToGoInputted}
              thousandSeparator={true}
              prefix={''}
              onValueChange={(values, sourceInfo) => {
                const { formattedValue, value } = values
                const { event, source } = sourceInfo
                setPageToGoInputted(parseInt(value))
              }}
            />
            <FilledButton
              title="Đi"
              buttonStyle={styles.buttonGoToPage}
              type="small"
              onPress={() => {
                onPageChanged(pageToGoInputted)
                setShowGoToPage(false)
              }}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

export default Pagination

const styles = StyleSheet.create({
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginVertical: 20,
    position: 'relative',
    backgroundColor: '#00000000',
  },
  container: {
    borderColor: 'transparent',
    alignItems: 'center',
    marginHorizontal: 0,
    marginVertical: 0,
    backgroundColor: '#00000000',
  },
  button: {
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderRadius: 6.6,
    borderStyle: 'solid',
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    width: 32,
    height: 32,
    marginHorizontal: 4,
    color: '#D9D9D9',
    borderRightColor: 'transparent',
  },
  total: {
    color: 'rgba(0, 0, 0, 0.85)',
    marginRight: 12,
    fontSize: 13,
  },
  btnText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: 13,
  },
  selectedButton: {
    borderColor: '#ED1B2E',
    backgroundColor: 'transparent',
  },
  selectedText: {
    color: '#ED1B2E',
  },
  icon: {
    marginHorizontal: 4,
    width: 32,
    height: 32,
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderRadius: 8,
    borderStyle: 'solid',
    lineHeight: 32,
    justifyContent: 'center',
  },
  picker: {
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderRadius: 8,
    borderStyle: 'solid',
    width: 100,
    height: 32,
    fontSize: 13,
    marginLeft: 4,
    paddingHorizontal: 5,
    textAlign: 'center',
  },
  blurBg: {
    backgroundColor: 'transparent',
    flex: 1,
  },
  menuContainer: {
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: '#fff',
    top: 45,
    right: 0,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    zIndex: 1000,
    padding: 8,
  },
  inputGoToPage: {
    width: 100,
    borderWidth: 1,
    borderRightWidth: 0,
    borderColor: '#D7DFFF',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingHorizontal: 16,
  },
  buttonGoToPage: {
    marginTop: 0,
    padding: 4,
    width: 20,
    shadowColor: '#00000000',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  inputPageContainer: {
    flexDirection: 'row',
    marginTop: 4,
  },
  menuLabel: {
    color: '#738399',
  },
})
