import React, { Component } from 'react'
import { GoogleReCaptcha } from './GoogleReCaptcha'

type Props = {
  googleSiteKey: string
  verifyCallback: (recaptchaToken: any) => void
  show?: boolean
  onError?: () => void
  onCancel?: () => void
}

export class GoogleReCaptchaComponent extends Component<Props> {
  captchaRef: any
  constructor(props: any) {
    super(props)
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
    this.loadReCaptcha = this.loadReCaptcha.bind(this)
  }

  loadReCaptcha() {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = 'https://www.google.com/recaptcha/api.js'
    document.body.appendChild(script)
  }

  componentDidMount() {
    this.loadReCaptcha()
    if (this.captchaRef) {
      this.captchaRef.reset()
      this.captchaRef.execute()
    }
  }

  onLoadRecaptcha() {
    if (this.captchaRef) {
      this.captchaRef.reset()
      this.captchaRef.execute()
    }
  }

  verifyCallback(recaptchaToken: any) {
    this.props.verifyCallback && this.props.verifyCallback(recaptchaToken)
  }

  render() {
    return (
      <div>
        <GoogleReCaptcha
          ref={(el) => {
            this.captchaRef = el
          }}
          size="invisible"
          render="explicit"
          siteKey={this.props.googleSiteKey}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.props.verifyCallback}
          expiredCallback={this.onLoadRecaptcha}
        />
      </div>
    )
  }
}

export default GoogleReCaptchaComponent
