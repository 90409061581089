import Layout from '../../constants/Layout';
import * as React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { Badge } from 'react-native-elements'
import { ThemeProvider } from 'react-native-elements';

const theme = {
    colors: {
        primary: 'transparent',
        error: '#FFE6E2',
        success: '#DFF3E7',
        warning: '#E0E0E0',
    },
    text: {
        primary: '#000',
        error: '#FC573B',
        success: '#27AE60',
        warning: '#505D6F',
    },
    dots: {
        primary: '#ccc',
        error: '#FC573B',
        success: '#27AE60',
        warning: '#505D6F',
    }
}

export type BadgeItem = {
    status: "primary" | "success" | "warning" | "error"
    value: string
    transparent?: boolean
    badgeStyle?: any
}



export function BadgeComponent({ status, value, transparent, badgeStyle }: BadgeItem) {
    const isMobileDevice = Layout.isMobileDevice
    const isTabletDevice = Layout.isMobileDevice

    if (isMobileDevice || isTabletDevice) {
        return (
            <ThemeProvider theme={theme} >
                <Badge value={
                <View style={BadgeStyle.defaultWrapText}>
                    <View style={{ backgroundColor: theme?.dots?.[status], width: 7, height: 7, marginRight: 9, borderRadius: 3.5 }}></View>
                 <Text style={{ color: theme?.text?.[status], fontSize: 13 }}>{value} </Text>
                 </View>
                 }
                    badgeStyle={[badgeStyle, BadgeStyle.defaultBadge, transparent ? BadgeStyle.transparent : null]}
                    status={status} />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme} >
            <Badge value={
            <View style={BadgeStyle.defaultWrapText}>
                <View style={{ backgroundColor: theme?.dots?.[status], width: 7, height: 7, marginRight: 9, borderRadius: 3.5 }}></View>
             <Text style={{ color: theme?.text?.[status]}}>{value} </Text>
             </View>
             }
                badgeStyle={[badgeStyle, BadgeStyle.defaultBadge, transparent ? BadgeStyle.transparent : null]}
                status={status} />
        </ThemeProvider>
    );
}

export default BadgeComponent

const BadgeStyle = StyleSheet.create({
    defaultBadge: {
        padding: 15,
        borderRadius: 30
    },
    defaultWrapText: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    transparent: {
        backgroundColor: 'transparent',
        padding: 0
    }
})