/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react'
import { ActivityIndicator } from 'react-native'

export type CircleProgressIndicatorProps = {
  size?: "small" | "large"
}

export function CircleProgressIndicator({ size }: CircleProgressIndicatorProps) {
  return <ActivityIndicator size={size ?? "large"} color="#ED1B2E" />
}

export default CircleProgressIndicator
