import appEnv from './app.env'

export interface IEnvConfig {
  host: string
  ocpKey: string
  subscriptionKey: string
  apim: string
  ingress: string
  googleSiteKey: string
  reCaptchaBaseUrl: string
  pruquoteRedirectUrl: string
}

export const ENV_BUILD_APP = {
  PRD: 'PRD',
  UAT: 'UAT',
  DEV: 'DEV',
}

export function configEnv(initEnv?: string): IEnvConfig {
  const envName = initEnv || process.env.ENV || process.env.REACT_APP_ENV || 'DEV'
  const envNameApp = (ENV_BUILD_APP as any)[envName] || 'DEV'
  const currentConfig = (appEnv as any)[envNameApp]
  return currentConfig
}

let environmentSingletonInstance: EnvironmentSingleton
export class EnvironmentSingleton {
  env: string | undefined
  constructor() {
    if (!environmentSingletonInstance) {
      environmentSingletonInstance = this
    }
    return environmentSingletonInstance
  }

  getEnv() {
    return this.env
  }

  setEnv(env: string) {
    this.env = env
  }
}
