export function parseJSON(input?: string) {
  if (!input) {
    return null
  }
  try {
    return JSON.parse(input)
  } catch (err) {
    return null
  }
}
 