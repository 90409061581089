import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import UserService from '../services/UserService'
import ApiException from '../model/exception/ApiException'
import UserInfoData from '../model/UserInfoData'
import { parseJSON } from '../helper/JSONHelper'
import UserInfo from '../model/UserInfo'
import { setUserInfo } from './authenSlice'

export interface UserState {
  loading?: boolean
  userInfo?: UserInfoData
  error?: ApiException | null
  userProtect?: any
  userProtectOriginal?: any
}

const initialState: UserState = {
  loading: false,
  userInfo: undefined,
  error: undefined,
  userProtect: undefined,
  userProtectOriginal: undefined,
}

export const getUserInfo = createAsyncThunk('user/info', async (req: any, thunkAPI: any) => {
  try {
    const response = await UserService.getUserInfo()
    thunkAPI.dispatch(setUserInfo(response.data))
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const updateUserProtectOriginal = createAsyncThunk('users/updateUserProtectOriginal', async (data: any) => {
  return data
})
export const updateUserProtect = createAsyncThunk('users/updateUserProtect', async (data: any) => {
  return data
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserInfo: (state) => {
      state.userInfo = undefined
      state.userProtect = undefined
    },
    createUserProtectOriginal: (state, action: PayloadAction<any>) => {
      state.userProtectOriginal = {} as any
    },
    createUserProtect: (state, action: PayloadAction<any>) => {
      state.userProtect = {} as any
    },
    setUser: (state, action: PayloadAction<any>) => {
      let userInfo = {
        ...action.payload,
        name: action.payload?.name,
        agentCode: action.payload?.sub,
      } as UserInfo
      state.userInfo = userInfo
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false
        let userInfo = {
          ...action.payload,
          name: action.payload?.name,
          agentCode: action.payload?.sub,
        } as UserInfo
        state.userInfo = userInfo
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false
        state.error = parseJSON(action?.error?.message)
      })
      .addCase(updateUserProtect.pending, (state) => {
        state.loading = true
      })
      .addCase(updateUserProtect.fulfilled, (state, action: any) => {
        state.loading = false
        state.userProtect = action.payload.cnaData
      })
      .addCase(updateUserProtect.rejected, (state, action) => {
        state.loading = false
        state.error = parseJSON(action?.error?.message)
      })
      .addCase(updateUserProtectOriginal.pending, (state) => {
        state.loading = true
      })
      .addCase(updateUserProtectOriginal.fulfilled, (state, action: any) => {
        state.loading = false
        state.userProtectOriginal = action.payload.cnaData
      })
      .addCase(updateUserProtectOriginal.rejected, (state, action) => {
        state.loading = false
        state.error = parseJSON(action?.error?.message)
      })
  },
})

export const { clearUserInfo, createUserProtect, createUserProtectOriginal, setUser } = userSlice.actions

const { reducer } = userSlice
export default reducer
