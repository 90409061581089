import * as React from 'react'
import { StyleSheet, Text, Image, View, ImageSourcePropType } from 'react-native'
import { styles as globalStyles } from '../../../common'
import icRate from '../../../assets/images/rate.png'
import icChart from '../../../assets/images/chart.png'
import icCirce from '../../../assets/images/circle.png'

export type InputChartProps = {
  title: string
  quotationKey: string
  value?: number
  onChange: (key: string, value: any) => void
  style?: Record<string, unknown>
  labelStyle?: Record<string, unknown>
  bottomNumber?: number
  type?: 'text' | 'input' | string | undefined
}

export function InputChart({
  title,
  style,
  labelStyle,
  onChange,
  bottomNumber,
  type,
  quotationKey,
  value,
}: InputChartProps) {
  return (
    <View style={[styles.button, style, value && value > 0 ? styles.highlight : null]}>
      <Text style={[styles.label, labelStyle]}>{title}</Text>
      <View style={[styles.border, type === 'text' ? styles.textSelect : null]}>
        {value && value > 0 ? (
          <Image source={icRate as ImageSourcePropType} style={styles.IconChart} />
        ) : (
          <Image source={icChart as ImageSourcePropType} style={styles.IconChart} />
        )}
        <Text style={[styles.textUpdate, { color: value ? '#FF9900' : '#505D6F' }]}>{`${value}%`}</Text>
      </View>
      <View style={styles.bottomBox}>
        <Image source={icCirce as ImageSourcePropType} style={globalStyles.Icon14} />
        <Text style={styles.textBottom}>{bottomNumber ? bottomNumber : '0'}%</Text>
      </View>
    </View>
  )
}

export default InputChart

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#FAFBFF',
    padding: 10,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    height: 110,
  },
  highlight: {
    backgroundColor: '#FFFFFF',
    shadowColor: 'grey',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 8,
    borderWidth: 0,
  },
  IconChart: {
    height: 34,
    width: 24,
  },
  label: {
    fontWeight: '600',
    fontSize: 10,
    color: '#505D6F',
  },
  Input: {
    width: 'calc(100% - 15px)',
  },
  border: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderBottomWidth: 2,
    borderBottomColor: '#D7DFFF',
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  textSelect: {
    alignItems: 'center',
  },
  bottomBox: {
    marginTop: 4,
    paddingRight: 10,
    paddingLeft: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textBottom: {
    fontSize: 12,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  textUpdate: {
    fontSize: 15,
    fontWeight: '700',
    color: '#FF9900',
    textAlign: 'center',
    width: '100%',
  },
})
