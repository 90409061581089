import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import ColumnChart from '../../Charts/ColumnChart'
import { styles as globalStyles } from '../../../common'
import { ProductType } from '../../../constants/Enum'
import { Dimensions } from 'react-native'
import Layout from '../../../constants/Layout'

type IProps = {
  customerAge: number
  data?: Array<{ policyYear: number; ape: number; fundValue: number }>
  dataTopUp?: Array<{ policyYear: number; ape: number; fundValue: number }>
  style?: Record<string, unknown>
  quotationFundAllocation?: {
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }
  productTypeChart?: string
  extraInvest?: number
  isLoadingChartData?: boolean
  editable?: boolean
}

function ChartBoxPopup({
  data,
  dataTopUp,
  extraInvest,
  customerAge,
  style,
  isLoadingChartData,
  quotationFundAllocation,
  productTypeChart,
}: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const windowWidth = Dimensions.get('window').width

  const patternChartData = [
    {
      seriesName: 'Số tiền tham gia',
      data: new Array<{ x: string; y: number }>(),
      color: '#FF9900',
    },
    {
      seriesName: 'Số tiền nhận về',
      data: new Array<{ x: string; y: number }>(),
      color:  '#26C6DA',
    },
  ]

  const patternChartDataTopUp = [
    {
      seriesName: 'Số tiền tham gia',
      data: new Array<{ x: string; y: number }>(),
      color: '#FF9900',
    },
    {
      seriesName: 'Số tiền nhận về',
      data: new Array<{ x: string; y: number }>(),
      color: '#26C6DA',
    },
  ]

  const [chartData, setChartData] = React.useState<
    Array<{
      seriesName: any
      data: any
      color: any
    }>
  >(patternChartData)


  const [dataChartInvest, setDataChartInvest] = React.useState<
    Array<{
      seriesName: any
      data: any
      color: any
    }>
  >(patternChartDataTopUp)

  
  const [quotationData, setQuotationData] = React.useState<{
    vietNamEquity?: number
    growth?: number
    balanced?: number
    stable?: number
    vietNamBond?: number
    preserved?: number
  }>()

  React.useEffect(() => {
    setQuotationData(quotationFundAllocation)
  }, [quotationFundAllocation])

  React.useEffect(() => {
    setChartData(patternChartData)
  }, [productTypeChart])

  React.useEffect(() => {
    if (data) {
      let temp = chartData
      temp[0].data = []
      temp[1].data = []
      temp[0].color = extraInvest ? '#FFCF86' : '#FF9900'
      temp[1].color = extraInvest ? '#83EBF9' : '#26C6DA'
      for (let i = 0; i < data.length; i++) {
        if (i === 0 || (i + 1) % 5 === 0) {
          temp[0].data.push({
            x: `${customerAge + data[i]?.policyYear} tuổi`,
            y: data[i]?.ape,
          })
          temp[1].data.push({
            x: `${customerAge + data[i]?.policyYear} tuổi`,
            y: data[i]?.fundValue,
          })
        }
      }
      setChartData([...temp])
    }
    if (dataTopUp) {
      let temp = dataChartInvest
      temp[0].data = []
      temp[1].data = []
      for (let i = 0; i < dataTopUp.length; i++) {
        if (i === 0 || (i + 1) % 5 === 0) {
          temp[0].data.push({
            x: `${customerAge + dataTopUp[i]?.policyYear} tuổi`,
            y: dataTopUp[i]?.ape,
          })
          temp[1].data.push({
            x: `${customerAge + dataTopUp[i]?.policyYear} tuổi`,
            y: dataTopUp[i]?.fundValue,
          })
        }
      }
      setDataChartInvest([...temp])
      // setChartData([...temp])
    }
  }, [customerAge, data, dataTopUp])

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.container, style]}>
    <ColumnChart width={'100%'} maxColumnWidth={64} data={chartData} 
 productType={productTypeChart === 'ILP' ? '(6)' : '(4)'} 
 defaultColumnWidthInvest={extraInvest? 64 : 40 } 
 defaultColumnWidth={extraInvest? 40 : 64 }
 height={extraInvest ? windowWidth - 350 : windowWidth - 280}  
 loading={isLoadingChartData} 
 popUpStyle={true}
 extraInvest={extraInvest ? true : false} 
 chartInvesment={extraInvest ? dataChartInvest: patternChartDataTopUp}  />
      </View>
    )
  }
  return (
    <View style={[desktopStyles.container, style]}>
      {/* <ColumnChart
        width={'100%'}
        defaultColumnWidth={80}
        height={windowWidth - 280}
        data={chartData}
        popUpStyle={true}
      /> */}

<ColumnChart width={'100%'} maxColumnWidth={108} data={chartData} 
 productType={productTypeChart === 'ILP' ? '(6)' : '(4)'} 
 defaultColumnWidthInvest={extraInvest? 104 : 80 } 
 defaultColumnWidth={extraInvest? 80 : 104 }
 height={extraInvest ? windowWidth - 280 : windowWidth}  
 loading={isLoadingChartData} 
 popUpStyle={true}
 extraInvest={extraInvest ? true : false} 
 chartInvesment={extraInvest ? dataChartInvest: patternChartDataTopUp}  />

    </View>
  )
}

export default ChartBoxPopup

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 18.14,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 14,
    paddingVertical: 16,
  },
})
