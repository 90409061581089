import {
  Block,
  styles as globalStyles,
  mathHelper,
  SizedBox,
  ExpandableList,
  vnNumberFormatter,
  Spacer,
} from '../../../common'
import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, FlatList, TouchableOpacity } from 'react-native'
import icWallet from '../../../assets/images/wallet.png'
import icActivity from '../../../assets/images/activity.png'
import icGraph from '../../../assets/images/graph.png'
import icDiscount from '../../../assets/images/discount.png'
import Layout from '../../../constants/Layout'
import i18n from 'i18n-js'
import { ApeDisplayType } from '../../../constants/Enum'
import LinearGradientBackground from '../../../common/components/LinearGradientBackground'
import icCircleDollar from '../../../assets/images/circle-dollar.png'
import { Slider } from 'react-native-elements'
import imgArrowRightCircleRedLight from '../../../assets/images/arrow-right-circle-red-light.png'
import { i18nAgentWording } from '../../../components/AgentWording'
import UserInfoData from '../../../model/UserInfoData'

const cardWidth = 200

type IProps = {
  userInfo?: UserInfoData
  needsData: Array<any>
  productType?: string
  protectValueTotal?: number
  style?: Record<string, unknown>
  sumAssured?: number
  periodPlan?: number
  ape?: number
  hcPlanDetail?: string
  onOpenFeeModal?: () => void
  onViewSolutionDetail?: () => void
}

function SummaryInfo({
  userInfo,
  needsData,
  style,
  productType,
  sumAssured,
  periodPlan,
  ape,
  protectValueTotal,
  hcPlanDetail,
  onOpenFeeModal,
  onViewSolutionDetail,
}: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  const expandableRef = React.useRef()

  const [apeDisplayType, setApeDisplayType] = React.useState<ApeDisplayType>(ApeDisplayType.YEAR)

  function getColumnCount() {
    const ratio = Layout.window.width / 2 / cardWidth
    if (ratio < 2) {
      return 1
    }
    return Math.round(ratio)
  }

  function countDisplaySumAssure() {
    return mathHelper.divideWithDynamicPrecisionResult(sumAssured!, 1, 3)
  }

  function countApeDisplayValue(apeDisplayType: ApeDisplayType) {
    if (!ape) return 0
    let apeInMillionUnit = mathHelper.divideWithDynamicPrecisionResult(ape, 1000000, 3)
    switch (apeDisplayType) {
      case ApeDisplayType.YEAR:
        return apeInMillionUnit
      case ApeDisplayType.MONTH:
        return mathHelper.divideWithDynamicPrecisionResult(apeInMillionUnit, 12, 3)
      default:
        return 0
    }
  }

  function getApeDisplayUnit(apeDisplayType: ApeDisplayType) {
    switch (apeDisplayType) {
      case ApeDisplayType.YEAR:
        return i18n.t('COMMON.MILLIONS_PER_YEAR')
      case ApeDisplayType.MONTH:
        return i18n.t('COMMON.MILLIONS_PER_MONTH')
      default:
        return
    }
  }

  function onSwitchApeDisplayType() {
    switch (apeDisplayType) {
      case ApeDisplayType.YEAR:
        setApeDisplayType(ApeDisplayType.MONTH)
        break
      case ApeDisplayType.MONTH:
        setApeDisplayType(ApeDisplayType.YEAR)
        break
      default:
        break
    }
  }

  function getQtyValue(qty: number) {
    // We need to multiply to 1000000 because qty's unit is billion
    const qtyToCount = (qty ?? 0) * 1000000
    return (
      vnNumberFormatter.format(mathHelper.divideWithDynamicPrecisionResult(qtyToCount ?? 0, 1000000, 3)) +
      ' ' +
      i18n.t('COMMON.MILLION')
    )
  }

  function renderNeedItem(
    icon: ImageSourcePropType,
    title: string,
    qty: number,
    sliderValue: number,
    themeColor: any,
    extraInfo?: string
  ) {
    if (isMobileDevice) {
      return (
        <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth]}>
          <View style={mobileStyles.itemContainer}>
            <Image source={icon} style={mobileStyles.itemIcon} />
            <View style={mobileStyles.itemContentContainer}>
              <Text style={[mobileStyles.itemTitle]}>{title}</Text>
              {extraInfo && <Text style={[mobileStyles.sliderLabel, { color: themeColor }]}>{extraInfo}</Text>}
              {!!!extraInfo && (
                <Text style={[mobileStyles.sliderLabel, { color: themeColor }]}>{getQtyValue(qty)}</Text>
              )}
            </View>
          </View>
          <SizedBox height={16} />
          <View style={mobileStyles.sliderContainer}>
            <Slider
              style={{ width: '100%', height: 6 }}
              minimumValue={0}
              maximumValue={1}
              value={sliderValue}
              minimumTrackTintColor={themeColor}
              maximumTrackTintColor={'#D7DFFF'}
              disabled
              thumbStyle={{ width: 0, height: 0 }}
              trackStyle={{ borderRadius: 9999, height: 6 }}
            />
          </View>
          <SizedBox height={20} />
        </View>
      )
    }
    return (
      <View style={desktopStyles.itemContainer}>
        <Image source={icon} style={desktopStyles.itemIcon} />
        <View style={desktopStyles.itemContentContainer}>
          <Text style={[desktopStyles.itemTitle]}>{title}</Text>
          {extraInfo && <Text style={[desktopStyles.sliderLabel, { color: themeColor }]}>{extraInfo}</Text>}
          {!!!extraInfo && <Text style={[desktopStyles.sliderLabel, { color: themeColor }]}>{getQtyValue(qty)}</Text>}
          <SizedBox height={8} />
          <Slider
            style={{ width: '90%', height: 8 }}
            minimumValue={0}
            maximumValue={1}
            value={sliderValue}
            minimumTrackTintColor={themeColor}
            maximumTrackTintColor={'#D7DFFF'}
            disabled
            thumbStyle={{ width: 0, height: 0 }}
            trackStyle={{ borderRadius: 9999, height: 8 }}
          />
        </View>
      </View>
    )
  }

  if (isMobileDevice) {
    const maxNeedsDisplay = 4
    return (
      <View style={[mobileStyles.container, style]}>
        <Text style={mobileStyles.blockTitle}>{i18n.t('COMMON.PROTECT_NEED')}</Text>
        <View style={mobileStyles.needsItemsContainer}>
          <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={mobileStyles.highlightTitleContainer}>
            <Image source={icCircleDollar as ImageSourcePropType} style={mobileStyles.dollarIcon} />
            <Text style={mobileStyles.highlightTitleLabel}>
              {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUM_PROTECT_VALUE')}&#58;
            </Text>
            <Text style={mobileStyles.moneyTotal}>
              &nbsp;&nbsp;{vnNumberFormatter.format(protectValueTotal ?? 0)}&nbsp;
            </Text>
            <Text style={[mobileStyles.unit, { color: '#FFF' }]}>{i18n.t('COMMON.MILLION_VND').toLowerCase()}</Text>
          </LinearGradientBackground>
          <ExpandableList
            ref={expandableRef}
            displayExpandIcon={false}
            header={
              <View>
                {(needsData && needsData.length > 0 && needsData.length > maxNeedsDisplay
                  ? needsData.slice(0, maxNeedsDisplay)
                  : needsData
                ).map((item, index) => {
                  return (
                    <View key={item.id} style={{ width: `${100 / getColumnCount()}%` }}>
                      {renderNeedItem(
                        item.icon as ImageSourcePropType,
                        i18nAgentWording({
                          agentOfficeCode: userInfo?.officeCode,
                          wordingCode: `NEEDS_ANALYST.LIST.${item.key}`,
                        }),
                        item.qty,
                        (item.qty ?? 0) / (protectValueTotal ?? 1),
                        item.themeColor,
                        item.key === 'hcPlan' ? hcPlanDetail : undefined
                      )}
                    </View>
                  )
                })}
              </View>
            }
            expandable={
              needsData && needsData.length > 0 && needsData.length > maxNeedsDisplay ? (
                <View>
                  {(needsData && needsData.length > 0 && needsData.length > maxNeedsDisplay
                    ? needsData.slice(maxNeedsDisplay, needsData.length)
                    : null
                  )?.map((item, index) => {
                    return (
                      <View key={item.id} style={{ width: `${100 / getColumnCount()}%` }}>
                        {renderNeedItem(
                          item.icon as ImageSourcePropType,
                          i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`,
                          }),
                          item.qty,
                          (item.qty ?? 0) / (protectValueTotal ?? 1),
                          item.themeColor,
                          item.key === 'hcPlan' ? hcPlanDetail : undefined
                        )}
                      </View>
                    )
                  })}
                </View>
              ) : null
            }
            expandLabel={
              needsData && needsData.length > 0 && needsData.length > maxNeedsDisplay
                ? `${needsData.length - maxNeedsDisplay}${i18n.t('SOLUTION.SEE_MORE_NEEDS')}`
                : ''
            }
            height={
              needsData && needsData.length > 0 && needsData.length > maxNeedsDisplay
                ? (needsData.length - maxNeedsDisplay) * 88
                : 0
            }
            collapseLabel={i18n.t('COMMON.COLLAPSE')}
          />
        </View>
        <TouchableOpacity style={mobileStyles.navigateMobileChart} onPress={onViewSolutionDetail}>
          <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1]}>
            <View style={[globalStyles.DisplayFlexRow]}>
              <Text style={mobileStyles.navigateMobileChartLabel}>{i18n.t('SOLUTION.INDEX')}</Text>
              <Text style={[globalStyles.RedText, { fontSize: isMobileDevice ? 10 : 12 }]}> (1)</Text>
            </View>
            {productType && <Text style={[mobileStyles.productTitle]}>{i18n.t(`PRODUCT_TYPE.${productType}`)}</Text>}
          </View>
          <Image source={imgArrowRightCircleRedLight} style={globalStyles.Icon25} />
        </TouchableOpacity>
        <View style={mobileStyles.needsTotalContainer}>
          <View style={mobileStyles.needsTotalItemsBlockContainer}>
            <Block
              child={
                <View style={mobileStyles.needsTotalItemsContainer}>
                  <Text style={mobileStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TOTAL')}</Text>
                  <View style={mobileStyles.needsTotalItemsInfo}>
                    <View style={[mobileStyles.needsSummaryIconContainer, { backgroundColor: '#FFE6E2' }]}>
                      <Image source={icWallet} style={mobileStyles.needsSummaryIcon} />
                    </View>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.AlignItemsRight]}>
                      <Text style={mobileStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                        countDisplaySumAssure()
                      )}`}</Text>
                      <Text style={mobileStyles.needsSummaryValueUnit}>{i18n.t('COMMON.MILLION_VND')}</Text>
                    </View>
                  </View>
                </View>
              }
              childContainerStyle={{ padding: 10 }}
            />
          </View>
          <View style={mobileStyles.needsTotalItemsBlockContainer}>
            <Block
              child={
                <View style={mobileStyles.needsTotalItemsContainer}>
                  <Text style={mobileStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TERM')}</Text>
                  <View style={mobileStyles.needsTotalItemsInfo}>
                    <View style={[mobileStyles.needsSummaryIconContainer, { backgroundColor: '#FFF9DD' }]}>
                      <Image source={icActivity} style={mobileStyles.needsSummaryIcon} />
                    </View>
                    <View style={[globalStyles.DisplayFlexCol, globalStyles.AlignItemsRight]}>
                      <Text style={mobileStyles.needsSummaryValue}>{`${periodPlan ?? 0} ${i18n.t(
                        'COMMON.YEAR'
                      )}`}</Text>
                      <Text style={mobileStyles.needsSummaryValueUnit}>{`(${periodPlan ? periodPlan * 12 : 0} ${i18n.t(
                        'COMMON.MONTH'
                      )})`}</Text>
                    </View>
                  </View>
                </View>
              }
            />
          </View>
          <View style={mobileStyles.needsTotalItemsBlockContainer}>
            <Block
              child={
                <View style={mobileStyles.needsTotalItemsContainer}>
                  <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                    <Text style={mobileStyles.needsSummaryLabel}>{`${i18n.t('SOLUTION.SUM_ASSURE')} `}</Text>
                    <Text style={[globalStyles.RedText, { fontSize: 10, marginTop: -3 }]}>
                      (2)
                    </Text>
                  </View>
                  <View style={mobileStyles.needsTotalItemsInfo}>
                    <View style={[mobileStyles.needsSummaryIconContainer, { backgroundColor: '#FFF9DD' }]}>
                      <Image source={icActivity} style={mobileStyles.needsSummaryIcon} />
                    </View>
                    <TouchableOpacity
                      onPress={onSwitchApeDisplayType}
                      style={[
                        globalStyles.DisplayFlexRow,
                        globalStyles.FloatRight,
                        globalStyles.AlignItemsRight,
                        globalStyles.Flex1,
                      ]}
                    >
                      <View
                        style={[globalStyles.DisplayFlexCol, globalStyles.FloatRight, globalStyles.AlignItemsRight]}
                      >
                        <Text style={mobileStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                          countApeDisplayValue(apeDisplayType)
                        )}`}</Text>
                        <Text style={mobileStyles.needsSummaryValueUnit}>{` ${getApeDisplayUnit(
                          apeDisplayType
                        )}`}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              }
            />
          </View>
        </View>
        <View style={mobileStyles.extraInfoContainer}>
          <TouchableOpacity style={mobileStyles.viewFeeContainer} onPress={onOpenFeeModal}>
            <Image source={icDiscount} style={mobileStyles.viewFeeIcon} />
            <Text style={mobileStyles.viewFeeLabel}>{i18n.t('SOLUTION.FEES')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={[tabletStyles.container, style]}>
        <Block
          title={i18n.t('COMMON.PROTECT_NEED')}
          child={
            <View>
              <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
                <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={desktopStyles.highlightTitleContainer}>
                  <Image source={icCircleDollar as ImageSourcePropType} style={desktopStyles.dollarIcon} />
                  <Text style={desktopStyles.highlightTitleLabel}>{'TỔNG NHU CẦU BẢO VỆ'}&#58;</Text>
                  <Text style={desktopStyles.moneyTotal}>
                    &nbsp;{vnNumberFormatter.format(protectValueTotal ?? 0)} {i18n.t('COMMON.MILLION_VND')}
                  </Text>
                </LinearGradientBackground>
              </View>
              <View style={tabletStyles.needsItemsContainer}>
                <FlatList
                  numColumns={getColumnCount()}
                  data={needsData}
                  style={tabletStyles.listItemsContainer}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({ item }) => {
                    return (
                      <View key={item.id} style={{ width: `${100 / getColumnCount()}%` }}>
                        {renderNeedItem(
                          item.icon as ImageSourcePropType,
                          i18nAgentWording({
                            agentOfficeCode: userInfo?.officeCode,
                            wordingCode: `NEEDS_ANALYST.LIST.${item.key}`,
                          }),
                          item.qty,
                          (item.qty ?? 0) / (protectValueTotal ?? 1),
                          item.themeColor,
                          item.key === 'hcPlan' ? hcPlanDetail : undefined
                        )}
                      </View>
                    )
                  }}
                />
              </View>
            </View>
          }
        />
        <SizedBox height={30} />
        <Block
          title={i18n.t('SOLUTION.INDEX')}
          showTitle={true}
          titleSuffix={
            <View style={[globalStyles.DisplayFlexRow]}>
              <Text style={[globalStyles.RedText, { fontSize: isMobileDevice ? 10 : 12 }]}> (1)</Text>
              {productType && <Text style={[tabletStyles.productTitle]}>{i18n.t(`PRODUCT_TYPE.${productType}`)}</Text>}
            </View>
          }
          withChildBackground={false}
          child={
            <View style={tabletStyles.needsTotalContainer}>
              <View style={tabletStyles.needsTotalItemsBlockContainer}>
                <Block
                  showTitle={false}
                  child={
                    <View style={tabletStyles.needsTotalItemsContainer}>
                      <View style={[tabletStyles.needsSummaryIconContainer, { backgroundColor: '#FFE6E2' }]}>
                        <Image source={icWallet as ImageSourcePropType} style={tabletStyles.needsSummaryIcon} />
                      </View>
                      <View style={[globalStyles.FullWidth, globalStyles.FullHeight, globalStyles.OverFlowHidden]}>
                        <Text style={tabletStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TOTAL')}</Text>
                        <View
                          style={[
                            globalStyles.DisplayFlexRow,
                            globalStyles.FloatRight,
                            globalStyles.AlignItemsRight,
                            globalStyles.Flex1,
                          ]}
                        >
                          <Text style={tabletStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                            countDisplaySumAssure()
                          )}`}</Text>
                          <Text style={tabletStyles.needsSummaryValueUnit}>{` ${i18n
                            .t('COMMON.MILLION_VND')
                            .toLowerCase()}`}</Text>
                        </View>
                      </View>
                    </View>
                  }
                />
              </View>
              <View style={tabletStyles.needsTotalItemsBlockContainer}>
                <Block
                  showTitle={false}
                  child={
                    <View style={tabletStyles.needsTotalItemsContainer}>
                      <View style={[tabletStyles.needsSummaryIconContainer, { backgroundColor: '#FFF9DD' }]}>
                        <Image source={icActivity as ImageSourcePropType} style={tabletStyles.needsSummaryIcon} />
                      </View>
                      <View style={[globalStyles.FullWidth, globalStyles.FullHeight, globalStyles.OverFlowHidden]}>
                        <Text style={tabletStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TERM')}</Text>
                        <View
                          style={[
                            globalStyles.DisplayFlexRow,
                            globalStyles.FloatRight,
                            globalStyles.AlignItemsRight,
                            globalStyles.Flex1,
                          ]}
                        >
                          <Text style={tabletStyles.needsSummaryValue}>{`${periodPlan ?? 0}`}</Text>
                          <Text style={tabletStyles.needsSummaryValueUnit}>{` năm (${
                            periodPlan ? periodPlan * 12 : ''
                          } tháng)`}</Text>
                        </View>
                      </View>
                    </View>
                  }
                />
              </View>
              <View style={tabletStyles.needsTotalItemsBlockContainer}>
                <Block
                  showTitle={false}
                  child={
                    <View style={tabletStyles.needsTotalItemsContainer}>
                      <View style={[tabletStyles.needsSummaryIconContainer, { backgroundColor: '#E3F8FA' }]}>
                        <Image source={icGraph as ImageSourcePropType} style={tabletStyles.needsSummaryIcon} />
                      </View>
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.FullWidth, globalStyles.FullHeight, globalStyles.OverFlowHidden]}>
                        <View style={[globalStyles.FullWidth]}>
                          <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                            <Text
                              style={[tabletStyles.needsSummaryLabel, { height: 'unset' }]}
                            >{`Tổng tiền tích lũy cho bảo hiểm`}</Text>
                            <Spacer />
                            <Text style={[globalStyles.RedText, { fontSize: 10 }]}>(2)</Text>
                          </View>
                        </View>
                        <View style={[globalStyles.DisplayFlexCol, globalStyles.Flex1, globalStyles.FloatRight]}>
                          <View
                            style={[globalStyles.DisplayFlexRow, globalStyles.FloatRight, { alignItems: 'flex-end' }]}
                          >
                            <Text style={tabletStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                              countApeDisplayValue(ApeDisplayType.YEAR)
                            )} `}</Text>
                            <Text style={tabletStyles.needsSummaryValueUnit}>{`${getApeDisplayUnit(
                              ApeDisplayType.YEAR
                            )}`}</Text>
                          </View>
                          <SizedBox height={6} />
                          <View
                            style={[globalStyles.DisplayFlexRow, globalStyles.FloatRight, { alignItems: 'flex-end' }]}
                          >
                            <Text style={tabletStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                              countApeDisplayValue(ApeDisplayType.MONTH)
                            )} `}</Text>
                            <Text style={tabletStyles.needsSummaryValueUnit}>{`${getApeDisplayUnit(
                              ApeDisplayType.MONTH
                            )}`}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  }
                />
              </View>
            </View>
          }
        />
        <View style={tabletStyles.extraInfoContainer}>
          <TouchableOpacity style={tabletStyles.viewFeeContainer} onPress={onOpenFeeModal}>
            <Image source={icDiscount as ImageSourcePropType} style={tabletStyles.viewFeeIcon} />
            <Text style={tabletStyles.viewFeeLabel}>{i18n.t('SOLUTION.FEES')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <Block
        title={i18n.t('COMMON.PROTECT_NEED')}
        child={
          <View>
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
              <LinearGradientBackground colors={['#FF8686', '#ED1B2E']} style={desktopStyles.highlightTitleContainer}>
                <Image source={icCircleDollar as ImageSourcePropType} style={desktopStyles.dollarIcon} />
                <Text style={desktopStyles.highlightTitleLabel}>{'TỔNG NHU CẦU BẢO VỆ'}&#58;</Text>
                <Text style={desktopStyles.moneyTotal}>
                  &nbsp;{vnNumberFormatter.format(protectValueTotal ?? 0)} {i18n.t('COMMON.MILLION_VND')}
                </Text>
              </LinearGradientBackground>
            </View>
            <View style={desktopStyles.needsItemsContainer}>
              <FlatList
                numColumns={getColumnCount()}
                data={needsData}
                style={desktopStyles.listItemsContainer}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                renderItem={({ item }) => {
                  return (
                    <View key={item.id} style={{ width: `${100 / getColumnCount()}%` }}>
                      {renderNeedItem(
                        item.icon as ImageSourcePropType,
                        i18nAgentWording({
                          agentOfficeCode: userInfo?.officeCode,
                          wordingCode: `NEEDS_ANALYST.LIST.${item.key}`,
                        }),
                        item.qty,
                        (item.qty ?? 0) / (protectValueTotal ?? 1),
                        item.themeColor,
                        item.key === 'hcPlan' ? hcPlanDetail : undefined
                      )}
                    </View>
                  )
                }}
              />
            </View>
          </View>
        }
      />
      <SizedBox height={30} />
      <Block
        title={i18n.t('SOLUTION.INDEX')}
        showTitle={true}
        titleSuffix={
          <View style={[globalStyles.DisplayFlexRow]}>
            <Text style={[globalStyles.RedText, { fontSize: isMobileDevice ? 10 : 12 }]}> (1)</Text>
            {productType && <Text style={[desktopStyles.productTitle]}>{i18n.t(`PRODUCT_TYPE.${productType}`)}</Text>}
          </View>
        }
        withChildBackground={false}
        child={
          <View style={desktopStyles.needsTotalContainer}>
            <Block
              containerStyle={desktopStyles.needsTotalItemsBlockContainer}
              childContainerStyle={globalStyles.Height100}
              child={
                <View style={desktopStyles.needsTotalItemsContainer}>
                  <View style={[desktopStyles.needsSummaryIconContainer, { backgroundColor: '#FFE6E2' }]}>
                    <Image source={icWallet} style={desktopStyles.needsSummaryIcon} />
                  </View>
                  <View style={[globalStyles.FullWidth, globalStyles.FullHeight, globalStyles.OverFlowHidden]}>
                    <Text style={desktopStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TOTAL')}</Text>
                    <View
                      style={[
                        globalStyles.DisplayFlexRow,
                        globalStyles.FloatRight,
                        globalStyles.AlignItemsRight,
                        globalStyles.Flex1,
                      ]}
                    >
                      <Text style={desktopStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                        countDisplaySumAssure()
                      )}`}</Text>
                      <Text style={desktopStyles.needsSummaryValueUnit}>{` ${i18n.t('COMMON.MILLION_VND')}`}</Text>
                    </View>
                  </View>
                </View>
              }
            />
            <Block
              containerStyle={desktopStyles.needsTotalItemsBlockContainer}
              childContainerStyle={globalStyles.Height100}
              child={
                <View style={desktopStyles.needsTotalItemsContainer}>
                  <View style={[desktopStyles.needsSummaryIconContainer, { backgroundColor: '#FFF9DD' }]}>
                    <Image source={icActivity} style={desktopStyles.needsSummaryIcon} />
                  </View>
                  <View style={[globalStyles.FullWidth, globalStyles.FullHeight, globalStyles.OverFlowHidden]}>
                    <Text style={tabletStyles.needsSummaryLabel}>{i18n.t('SOLUTION.NEED_TERM')}</Text>
                    <View
                      style={[
                        globalStyles.DisplayFlexRow,
                        globalStyles.FloatRight,
                        globalStyles.AlignItemsRight,
                        globalStyles.Flex1,
                      ]}
                    >
                      <Text style={desktopStyles.needsSummaryValue}>{`${periodPlan ?? 0}`}</Text>
                      <Text style={desktopStyles.needsSummaryValueUnit}>{` năm (${
                        periodPlan ? periodPlan * 12 : ''
                      } tháng)`}</Text>
                    </View>
                  </View>
                </View>
              }
            />
            <Block
              containerStyle={desktopStyles.needsTotalItemsBlockContainer}
              childContainerStyle={globalStyles.Height100}
              child={
                <View style={desktopStyles.needsTotalItemsContainer}>
                  <View style={[desktopStyles.needsSummaryIconContainer, { backgroundColor: '#E3F8FA' }]}>
                    <Image source={icGraph as ImageSourcePropType} style={desktopStyles.needsSummaryIcon} />
                  </View>
                  <View style={globalStyles.FullWidth}>
                    <View style={globalStyles.FullWidth}>
                      <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
                        <Text
                          style={[desktopStyles.needsSummaryLabel, { height: 'unset' }]}
                        >{`Tổng tiền tích lũy cho bảo hiểm`}</Text>
                        <Spacer />
                        <Text style={[globalStyles.RedText, { fontSize: 10 }]}>(2)</Text>
                      </View>
                      <View style={[globalStyles.DisplayFlexRow, globalStyles.FloatRight, { alignItems: 'flex-end' }]}>
                        <Text style={desktopStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                          countApeDisplayValue(ApeDisplayType.YEAR)
                        )} `}</Text>
                        <Text style={desktopStyles.needsSummaryValueUnit}>{`${getApeDisplayUnit(
                          ApeDisplayType.YEAR
                        )}`}</Text>
                      </View>
                    </View>
                    <View style={globalStyles.FullWidth}>
                      <View style={[globalStyles.DisplayFlexRow, globalStyles.FloatRight, { alignItems: 'flex-end' }]}>
                        <Text style={desktopStyles.needsSummaryValue}>{`${vnNumberFormatter.format(
                          countApeDisplayValue(ApeDisplayType.MONTH)
                        )} `}</Text>
                        <Text style={desktopStyles.needsSummaryValueUnit}>{`${getApeDisplayUnit(
                          ApeDisplayType.MONTH
                        )}`}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              }
            />
          </View>
        }
      />
      <View style={desktopStyles.extraInfoContainer}>
        <TouchableOpacity style={desktopStyles.viewFeeContainer} onPress={onOpenFeeModal}>
          <Image source={icDiscount as ImageSourcePropType} style={desktopStyles.viewFeeIcon} />
          <Text style={desktopStyles.viewFeeLabel}>{i18n.t('SOLUTION.FEES')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default SummaryInfo

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
  needsTotalItemsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
    width: '100%',
  },
  totalItemsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
  productTitle: {
    fontSize: 24,
    color: '#505D6F',
    fontWeight: 'bold',
    marginLeft: 8,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  itemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
  },
  itemContentContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 8.29,
    flex: 1,
  },
  itemIcon: {
    width: 50,
    height: 50,
  },
  itemTitle: {
    color: '#000000',
    fontSize: 15,
    height: 38,
  },
  needsTotalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  needsSummaryIconContainer: {
    padding: 14,
    borderRadius: 16.58,
  },
  needsSummaryIcon: {
    width: 33.17,
    height: 33.17,
    resizeMode: 'contain',
  },
  needsSummaryLabel: {
    textAlign: 'left',
    fontSize: 14.925,
    fontWeight: '600',
    color: '#1F1F20',
    marginLeft: 16.58,
    height: 45,
  },
  needsSummaryValue: {
    textAlign: 'right',
    fontSize: 24.875,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  needsTotalItemsBlockContainer: {
    width: '32%',
    paddingBottom: 16,
  },
  extraInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  viewFeeContainer: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 6.33,
    paddingHorizontal: 17.14,
    borderRadius: 19.9,
    flexDirection: 'row',
    marginTop: 8.08,
    alignItems: 'center',
  },
  viewFeeIcon: {
    width: 22.11,
    height: 22.11,
  },
  viewFeeLabel: {
    fontSize: 11.6,
    fontWeight: '600',
    color: '#2F7AEB',
    marginLeft: 8.02,
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 15,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dollarIcon: {
    width: 40,
    height: 40,
  },
  highlightTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 24,
    fontWeight: '700',
    color: '#FFF',
    textTransform: 'lowercase',
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  listItemsContainer: {
    paddingVertical: 10,
    overflow: 'hidden',
  },
  needsSummaryValueUnit: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#505D6F',
    marginBottom: 3,
    textTransform: 'lowercase'
  },
  childContainer: {
    paddingHorizontal: 16,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
  needsTotalItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
    width: '100%',
    minHeight: 130.16,
  },
  totalItemsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
  productTitle: {
    fontSize: 18,
    color: '#505D6F',
    fontWeight: 'bold',
    marginLeft: 8,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  itemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemIcon: {
    width: 50,
    height: 50,
  },
  itemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 8.29,
  },
  needsTotalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  needsSummaryIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
    borderRadius: 16.58,
  },
  needsSummaryIcon: {
    width: 33.17,
    height: 33.17,
    resizeMode: 'contain',
  },
  needsSummaryLabel: {
    textAlign: 'left',
    fontSize: 14.925,
    fontWeight: '600',
    color: '#1F1F20',
    marginLeft: 16.58,
    height: 45,
  },
  needsSummaryValue: {
    textAlign: 'right',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  needsSummaryValueUnit: {
    textAlign: 'right',
    fontSize: 13,
    fontWeight: 'bold',
    color: '#505D6F',
  },
  needsTotalItemsBlockContainer: {
    width: '32%',
  },
  extraInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  viewFeeContainer: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 6.33,
    paddingHorizontal: 17.14,
    borderRadius: 19.9,
    flexDirection: 'row',
    marginTop: 8.08,
    alignItems: 'center',
  },
  viewFeeIcon: {
    width: 22.11,
    height: 22.11,
  },
  viewFeeLabel: {
    fontSize: 11.6,
    fontWeight: '600',
    color: '#2F7AEB',
    marginLeft: 8.02,
  },
  listItemsContainer: {
    paddingVertical: 10,
    overflow: 'hidden',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  needsItemsContainer: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
  },
  needsTotalItemsContainer: {
    height: 120,
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: '#FFF',
  },
  needsTotalItemsInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    flex: 1,
  },
  totalItemsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    alignItems: 'flex-start',
    padding: 16.58,
  },
  blockTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#505D6F',
    marginBottom: 8,
  },
  productTitle: {
    fontSize: 15,
    color: '#505D6F',
    fontWeight: 'bold',
    marginTop: 8,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  itemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingHorizontal: 28,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemIcon: {
    width: 40,
    height: 40,
  },
  itemTitle: {
    color: '#505D6F',
    fontSize: 13,
    height: 28,
  },
  sliderContainer: {
    paddingHorizontal: 28,
    width: '100%',
  },
  needsTotalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  needsSummaryIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    width: 25,
    height: 25,
  },
  needsSummaryIcon: {
    width: 15,
    height: 15,
    resizeMode: 'contain',
  },
  needsSummaryLabel: {
    textAlign: 'left',
    fontSize: 12,
    color: '#1F1F20',
    flex: 1,
  },
  needsSummaryValue: {
    textAlign: 'right',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#505D6F',
    flex: 1,
  },
  needsSummaryValueUnit: {
    textAlign: 'right',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#505D6F',
    marginBottom: 3,
  },
  needsTotalItemsBlockContainer: {
    width: '32%',
  },
  extraInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  viewFeeContainer: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 6.33,
    paddingHorizontal: 17.14,
    borderRadius: 19.9,
    flexDirection: 'row',
    marginTop: 8.08,
    alignItems: 'center',
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
  },
  viewFeeIcon: {
    width: 22.11,
    height: 22.11,
  },
  viewFeeLabel: {
    fontSize: 11.6,
    fontWeight: '600',
    color: '#2F7AEB',
    marginLeft: 8.02,
  },
  itemContentContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 8.29,
    flex: 1,
  },
  sliderLabel: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  highlightTitleContainer: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: 19,
    width: '100%',
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 12,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 20,
    fontWeight: '700',
    color: '#FFF',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 13,
    color: '#2B2D42',
  },
  navigateMobileChart: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    marginTop: 16,
    borderRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  navigateMobileChartLabel: {
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    flex: 1,
    color: '#505D6F',
  },
})
