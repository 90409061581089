import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import solutionService from '../services/SolutionService'

export interface SolutionState {
  loading?: boolean
  loadingChartData?: boolean
  chartData?: Array<{ policyYear: number; ape: number, fundValue: number }>
}

const initialState: SolutionState = {
  loading: false,
  loadingChartData: false,
}

export const getChartData = createAsyncThunk('solution/get-chart-data', async (req: any, thunkAPI: any) => {
  try {
    let response = await solutionService.getChartData(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const solutionSlice = createSlice({
  name: 'solution',
  initialState,
  reducers: {
    updateSolution: (state, action: PayloadAction<Array<any>>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChartData.pending, (state) => {
        state.loadingChartData = true
      })
      .addCase(getChartData.fulfilled, (state, action) => {
        state.chartData = action.payload?.result
        state.loadingChartData = false
      })
      .addCase(getChartData.rejected, (state) => {
        state.loadingChartData = false
      })
  },
})

export const { updateSolution } = solutionSlice.actions

const { reducer } = solutionSlice
export default reducer
