import * as React from 'react'
import { ImageSourcePropType, StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import icCircleCheck from '../assets/images/circle-check.png'
import icEditRed from '../assets/images/edit-red.png'
import { createCNASteps } from '../constants/Data'
import i18n from 'i18n-js'
import CNADetail from '../model/CNADetail'
import Needs from '../model/Needs'
import { CnaStatus, ViewMode } from '../constants/Enum'
import { checkCnaNeedsInputted } from '../helper/CnaHelper'
import { CircleProgressIndicator, SizedBox, StepIndicator, styles as globalStyles, } from '../common'

export type MobileStepIndicatorProps = {
  route: any
  navigation: any
  currentPosition?: number
  stepCount?: number
  direction?: 'horizontal' | 'vertical'
  labels?: string[]
  loadingSaveDraft?: boolean
  cnaData?: CNADetail
  needsCartList?: Array<Needs>
  disableSaveDraft?: boolean
  onSaveDraft?: () => void
}

export function MobileStepIndicator({
  route,
  navigation,
  currentPosition = 0,
  stepCount = 3,
  direction = 'horizontal',
  labels = [i18n.t('COMMON.NEEDS'), i18n.t('COMMON.ANALYSIS'), i18n.t('COMMON.SOLUTION')],
  onSaveDraft,
  loadingSaveDraft,
  disableSaveDraft,
  cnaData,
  needsCartList,
}: MobileStepIndicatorProps) {
  /**
   * Get step indicator background color
   */
  function getStepColor(index: number, currentStep: number) {
    if (index < currentStep) return '#FFFFFF'
    if (index === currentStep) return '#ED1B2E'
    else return '#BDBDBD'
  }

  /**
   * Get step label color
   */
  function getStepLabelColor(index: number, currentStep: number) {
    if (index < currentStep) return '#505D6F'
    else return '#ED1B2E'
  }

  function onSwitchToStep(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        navigation.replace('NeedsCart', { ...(route.params as any) })
        break
      case 1:
        if (cnaData?.status) {
          if (cnaData?.status === CnaStatus.DRAFT || !checkCnaNeedsInputted(needsCartList)) {
            navigation.replace('CustomerNeed', { ...(route.params as any) })
            break
          } else {
            navigation.replace('NeedsOverall', { ...(route.params as any) })
            break
          }
        } else {
          break
        }
      case 2:
        if (cnaData?.status && checkCnaNeedsInputted(needsCartList)) {
          navigation.replace('Solution', { ...(route.params as any) })
          break
        } else {
          break
        }
      default:
        navigation.replace('NeedsCart', { ...(route.params as any) })
        break
    }
  }

  return (
    <View style={styles.container}>
      <View style={globalStyles.Flex1}>
        <StepIndicator
          stepCount={stepCount}
          currentPosition={currentPosition}
          direction={direction}
          onPress={onSwitchToStep}
          renderStepIndicator={(params: { position: number }) => (
            <Text style={[styles.stepIndicator, { color: getStepColor(params.position, currentPosition) }]}>
              {params.position + 1}
            </Text>
          )}
          labels={labels}
          renderLabel={(params: { position: number }) => (
            <Text style={[styles.labelIndicator, { color: getStepLabelColor(currentPosition, params.position) }]}>
              {createCNASteps[params.position]}
            </Text>
          )}
          customStyles={{
            stepIndicatorFinishedColor: '#ED1B2E',
            stepIndicatorLabelFinishedColor: '#FFF',
          }}
        />
      </View>
      <TouchableOpacity
        style={styles.saveDraftButtonContainer}
        onPress={() => {
          if (route.params?.mode === ViewMode.READ_ONLY) {
            navigation.replace(route.name, { ...(route.params as any), mode: ViewMode.EDITABLE })
          } else {
            onSaveDraft && onSaveDraft()
          }
        }}
        disabled={disableSaveDraft}
      >
        {route.params?.mode === ViewMode.READ_ONLY ? (
          <Image source={icEditRed as ImageSourcePropType} style={globalStyles.Icon25} />
        ) : loadingSaveDraft ? (
          <CircleProgressIndicator size="small" />
        ) : (
          <Image source={icCircleCheck} style={globalStyles.Icon25} />
        )}
        <SizedBox height={12} />
        <Text style={{ fontSize: 11, color: '#ED1B2E' }}>
          {route.params?.mode === ViewMode.READ_ONLY ? i18n.t('COMMON.EDIT') : i18n.t('COMMON.SAVE_DRAFT')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default MobileStepIndicator

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
    paddingHorizontal: 0,
    paddingVertical: 7,
  },
  saveDraftButtonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 24,
    alignItems: 'center',
    paddingTop: 2,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
})
