import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Expandable from './Expandable'
import Layout from '../../constants/Layout'
import Colors from '../constants/Colors'

export type ExpandableSelectProps<T> = {
  data: Array<T>
  currentSelectIndex?: number
  onSelect?: (value: T, index: number) => void
  renderItem?: (value: T) => JSX.Element
  height?: number
}

export function ExpandableSelect<T>({
  data,
  currentSelectIndex,
  onSelect,
  renderItem,
  height,
}: ExpandableSelectProps<T>) {
  const isMobileDevice = Layout.isMobileDevice

  const expandableRef = React.useRef()

  function onSelectItem(e: T, i: number) {
    onSelect && onSelect(e, i)
    ;(expandableRef.current as any).toggleExpandableView()
  }

  if (isMobileDevice) {
    return (
      <Expandable
        ref={expandableRef}
        header={
          <View>
            {typeof currentSelectIndex === 'number' && currentSelectIndex >= 0 ? (
              renderItem ? (
                renderItem(data[currentSelectIndex])
              ) : (
                <Text style={mobileStyles.item}>{data[currentSelectIndex]}</Text>
              )
            ) : (
              <Text style={mobileStyles.hint}>Chọn</Text>
            )}
          </View>
        }
        headerStyle={mobileStyles.header}
        height={height}
        expandable={
          <View style={mobileStyles.expandable}>
            {data.map((e: T, i: number) => (
              <TouchableOpacity onPress={() => onSelectItem(e, i)} key={i}>
                <View style={mobileStyles.itemContainer}>
                  {renderItem ? renderItem(e) : <Text style={mobileStyles.item}>{e}</Text>}
                </View>
              </TouchableOpacity>
            ))}
          </View>
        }
      />
    )
  }

  return (
    <Expandable
      ref={expandableRef}
      header={
        <View>
          {typeof currentSelectIndex === 'number' && currentSelectIndex >= 0 ? (
            renderItem ? (
              renderItem(data[currentSelectIndex])
            ) : (
              <Text style={styles.item}>{data[currentSelectIndex]}</Text>
            )
          ) : (
            <Text style={styles.hint}>Chọn</Text>
          )}
        </View>
      }
      headerStyle={styles.header}
      height={height}
      expandable={
        <View style={styles.expandable}>
          {data.map((e: T, i: number) => (
            <TouchableOpacity onPress={() => onSelectItem(e, i)} key={i}>
              <View style={styles.itemContainer}>
                {renderItem ? renderItem(e) : <Text style={styles.item}>{e}</Text>}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      }
    />
  )
}

export default ExpandableSelect

const styles = StyleSheet.create({
  header: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    padding: 17,
    borderRadius: 16,
    borderColor: Colors.borderColor,
    borderWidth: 3,
    marginBottom: 10,
  },
  hint: {
    fontWeight: '600',
    color: '#E0E0E0',
    fontSize: 16,
  },
  selectedItem: {
    fontSize: 16,
    fontWeight: '600',
  },
  itemContainer: {
    width: '100%',
    height: 44,
    alignItems: 'flex-start',
  },
  item: {
    fontSize: 16,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
  expandable: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#FAFBFF',
    padding: 17,
    paddingBottom: 0,
    borderRadius: 16,
    borderColor: '#EDF2F4',
    borderWidth: 3,
    display: 'flex',
    flexDirection: 'column',
  },
})

const mobileStyles = StyleSheet.create({
  header: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 10,
    borderColor: '#D7DFFF',
    borderWidth: 1,
    marginBottom: 10,
  },
  hint: {
    fontWeight: '600',
    color: '#E0E0E0',
    fontSize: 13,
  },
  selectedItem: {
    fontSize: 13,
    fontWeight: '600',
  },
  itemContainer: {
    width: '100%',
    height: 44,
    alignItems: 'flex-start',
  },
  item: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
    color: '#000',
  },
  expandable: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#FAFBFF',
    padding: 17,
    paddingBottom: 0,
    borderRadius: 16,
    borderColor: '#EDF2F4',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
  },
})
