import * as React from 'react'
import { StyleSheet, Text } from 'react-native'

export type AppTextProps = {
  title?: string
  style?: Record<string, unknown>
  onPress?: () => void
}

export function AppText({ title, style, onPress }: AppTextProps) {
  return <Text style={[styles.text, style]}>{title}</Text>
}

export default AppText

const styles = StyleSheet.create({
  text: {
    // fontFamily: 'Open Sans',
  },
})
