import Layout from '../../constants/Layout'
import React, { useState, useEffect } from 'react'
import { SafeAreaView, StyleSheet, View, Text, TouchableOpacity } from 'react-native'

export type RadioListTitleItem = {
  data: any
  title?: string
  value?: any
  error?: React.ReactNode
  defaultValue?: any
  direction?: 'column' | 'row'
  containerStyle?: Record<string, unknown>
  margin?: boolean
  radioItemContainerStyle?: Record<string, unknown>
  onChange?: (value: any) => void
}

export function RadioListTitle({
  data,
  title,
  error,
  defaultValue,
  margin,
  onChange,
  direction,
  containerStyle,
  radioItemContainerStyle,
}: RadioListTitleItem) {
  const [titleRadio, setTitleRadio] = useState(defaultValue)
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  useEffect(() => {
    if (defaultValue !== undefined) {
      setTitleRadio(defaultValue)
    }
  }, [defaultValue])

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.wrapper, containerStyle]}>
        {title && <Text style={mobileStyles.radioTitle}>{title}</Text>}
        <View style={[mobileStyles.wrapperContent, { flexDirection: direction ? direction : 'row' }, containerStyle]}>
          {data.map((res: any, i: number) => {
            return (
              <View key={res.value} style={[mobileStyles.container, radioItemContainerStyle ? radioItemContainerStyle : {}]}>
                <TouchableOpacity
                  style={titleRadio === res.value ? mobileStyles.radioCircleSelect : mobileStyles.radioCircle}
                  onPress={() => {
                    setTitleRadio(res.value)
                    onChange && onChange(res.value)
                  }}
                >
                  {titleRadio === res.value && <View style={mobileStyles.selectedRb} />}
                </TouchableOpacity>
                <Text style={titleRadio === res.value ? mobileStyles.radioTextSelect : mobileStyles.radioText}>
                  {res.text}
                </Text>
              </View>
            )
          })}
        </View>
        {error && error}
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={[tabletStyle.wrapper, containerStyle]}>
        {title && <Text style={tabletStyle.radioTitle}>{title}</Text>}
        <View
          style={[
            tabletStyle.wrapperContent,
            { flexDirection: direction ?? 'row' },
            containerStyle,
            radioItemContainerStyle,
          ]}
        >
          {data.map((res: any, i: number) => {
            return (
              <View
                key={res.value}
                style={[tabletStyle.container, radioItemContainerStyle ? radioItemContainerStyle : {}]}
              >
                <TouchableOpacity
                  style={titleRadio === res.value ? tabletStyle.radioCircleSelect : tabletStyle.radioCircle}
                  onPress={() => {
                    setTitleRadio(res.value)
                    onChange && onChange(res.value)
                  }}
                >
                  {titleRadio === res.value && <View style={tabletStyle.selectedRb} />}
                </TouchableOpacity>
                <Text style={titleRadio === res.value ? tabletStyle.radioTextSelect : tabletStyle.radioText}>
                  {res.text}
                </Text>
              </View>
            )
          })}
          <View></View>
        </View>
        {error && error}
      </SafeAreaView>
    )
  }
  return (
    <SafeAreaView style={[styles.wrapper, containerStyle]}>
      {title != undefined && <Text style={styles.radioTitle}>{title}</Text>}
      <View style={[styles.wrapperContent, { flexDirection: direction ?? 'row' }, containerStyle]}>
        {data.map((res: any, i: number) => {
          return (
            <View
              key={res.value}
              style={[
                styles.container,
                { marginLeft: i % 2 === 1 && direction === 'row' ? 30 : 0 },
                radioItemContainerStyle ? radioItemContainerStyle : {},
              ]}
            >
              <TouchableOpacity
                style={titleRadio === res.value ? styles.radioCircleSelect : styles.radioCircle}
                onPress={() => {
                  setTitleRadio(res.value)
                  onChange && onChange(res.value)
                }}
              >
                {titleRadio === res.value && <View style={styles.selectedRb} />}
              </TouchableOpacity>
              <Text style={titleRadio === res.value ? styles.radioTextSelect : styles.radioText}>{res.text}</Text>
            </View>
          )
        })}
        <View></View>
      </View>
      <View>{error && error}</View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  wrapperContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    maxWidth: 200,
    minWidth: 160,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  radioTitle: {
    fontWeight: '600',
    marginBottom: 10,
    color: '#505D6F',
    fontSize: 16,
  },
  radioText: {
    paddingLeft: 15,
    fontSize: 16,
    color: '#373737',
    fontWeight: '400',
  },
  radioTextSelect: {
    paddingLeft: 15,
    fontSize: 16,
    color: '#373737',
    fontWeight: '500',
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelect: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ED1B2E',
  },
  selectedRb: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: '#ED1B2E',
  },
})

const tabletStyle = StyleSheet.create({
  wrapper: {
    marginBottom: 30,
    width: '100%',
  },
  wrapperContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    maxWidth: 200,
    minWidth: 160,
    flexDirection: 'row',
    width: '100%',
  },
  radioTitle: {
    fontWeight: '600',
    color: '#505D6F',
    fontSize: 13.2667,
  },
  radioText: {
    paddingLeft: 15,
    fontSize: 13.2667,
    color: '#373737',
    fontWeight: '400',
    flex: 1,
  },
  radioTextSelect: {
    paddingLeft: 15,
    fontSize: 13.2667,
    color: '#373737',
    fontWeight: '500',
    flex: 1,
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelect: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ED1B2E',
  },
  selectedRb: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: '#ED1B2E',
  },
})

const mobileStyles = StyleSheet.create({
  wrapper: {
    marginBottom: 0,
  },
  wrapperContent: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    minWidth: '50%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  radioTitle: {
    fontWeight: '600',
    marginBottom: 10,
    color: '#505D6F',
    fontSize: 12,
  },
  radioText: {
    paddingLeft: 15,
    fontSize: 13,
    color: '#373737',
    fontWeight: '400',
  },
  radioTextSelect: {
    paddingLeft: 15,
    fontSize: 13,
    color: '#373737',
    fontWeight: '500',
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelect: {
    height: 20,
    width: 20,
    borderRadius: 100,
    backgroundColor: '#F2F2F2',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ED1B2E',
  },
  selectedRb: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: '#ED1B2E',
  },
})
