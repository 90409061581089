import { SizedBox, tabletMediaConfig, TextButton } from '../../../common'
import { Switch as SwitchSelect, SwitchItem } from '../../../common/components/SwitchSelector'
import React, { useState } from 'react'
import { StyleSheet, View, Text, TouchableOpacity, ImageSourcePropType, Image, ActivityIndicator } from 'react-native'
import { useMediaQuery } from 'react-responsive'
import { styles as globalStyles, BadgeComponent, CustomScrollView } from '../../../common'
import moment from 'moment'
import { connect, ConnectedProps } from 'react-redux'
import { loadSummaryAsync } from '../../../slices/cnaListSlice'
import { getCnaReviewList, deleteCNA, getCnaMonthList, getCnaTodayList } from '../../../slices/cnaSlice'
import i18n from 'i18n-js'
import { CnaStatus, ViewMode } from '../../../constants/Enum'
import {  setCnaData } from '../../../slices/viewAllCnaSlice'
import icCall from '../../../assets/images/call.png'
import icCalendar from '../../../assets/images/calendar.png'
import imgNoDocument from '../../../assets/images/no-document.png'
import { RootState } from '../../../store'
import { clearCart } from '../../../slices/needsCartSlice'
import MobileCnaItem from './MobileCnaItem'
import { useIsFocused } from '@react-navigation/core'
import Layout from '../../../constants/Layout'

const DEFAULT_PAGE_SIZE = 1000
const DEFAULT_PAGE_INDEX = 1

export const DEFAULT_DRAFT_DATA = [
  {
    name: 'reviewDate',
    value: moment().startOf('day').add(7, 'hours'),
    type: 'Date',
    operator: 'eq',
  },
]

export const DEFAULT_DRAFT_DATA_30DAY = [
  {
    name: 'reviewDate',
    value: moment().startOf('day').add(7, 'hours'),
    type: 'Date',
    operator: 'gte',
  },
  {
    name: 'reviewDate',
    value: moment().add(30, 'days').toISOString(),
    type: 'Date',
    operator: 'lte',
  },
]

export const DEFAULT_COMPLETED_DATA = [
  {
    name: 'createdAt',
    value: moment().startOf('day').add(7, 'hours'),
    type: 'Date',
    operator: 'eq',
  },
]

const DEFAULT_DATA_SORT = [
  {
    createdAt: 'ASC',
  },
]

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
  cnaReview: state?.cna?.cnaReview,
  cnaMonthList: state?.cna?.cnaMonthList,
  cnaTodayList: state?.cna?.cnaTodayList,
  isHaveMoreReviewList: state?.cna?.isHaveMoreReviewList,
  isHaveMoreMonthList: state?.cna?.isHaveMoreMonthList,
  isHaveMoreTodayList: state?.cna?.isHaveMoreTodayList,
  loadingMoreReviewList: state?.cna?.loadingMoreReviewList,
  loadingMoreMonthList: state?.cna?.loadingMoreMonthList,
  loadingMoreTodayList: state?.cna?.loadingMoreTodayList,
})

const mapDispatchToProps = {
  clearCart,
  getCnaReviewList,
  deleteCNA,
  loadSummaryAsync,
  getCnaTodayList,
  getCnaMonthList,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type PlanReviewProps = PropsFromRedux & {
  navigation: any
  route?: any
  updateRecord?: any
  onViewDetail?: (params: any) => void
}

function PlanNeedReview({
  getCnaReviewList,
  route,
  onViewDetail,
  cnaReview,
  navigation,
  updateRecord,
  getCnaMonthList,
  getCnaTodayList,
  loadSummaryAsync,
  cnaMonthList,
  cnaTodayList,
  loadingMoreMonthList,
  loadingMoreReviewList,
  loadingMoreTodayList,
  isHaveMoreMonthList,
  isHaveMoreReviewList,
  isHaveMoreTodayList,
}: PlanReviewProps) {
  const isFocused = useIsFocused()

  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = useMediaQuery(tabletMediaConfig)
  const [showDetailPlan, setShowDetailPlan] = React.useState(false)
  const [dataSorts, setDataSorts] = React.useState<any>({})
  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE)
  const [pageIndex, setPageIndex] = React.useState(DEFAULT_PAGE_INDEX)
  const [pageTodayIndex, setPageTodayIndex] = React.useState(DEFAULT_PAGE_INDEX)
  const [pageMonthIndex, setPageMonthIndex] = React.useState(DEFAULT_PAGE_INDEX)
  const [dataFilters, setDataFilters] = React.useState<Array<any>>(DEFAULT_DRAFT_DATA)
  const [dataFilterDay, setDataFiltersDay] = React.useState<Array<any>>(DEFAULT_DRAFT_DATA_30DAY)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSort, setDataSort] = useState(DEFAULT_DATA_SORT)

  const [dataFiltersCompleted, setDataFiltersCompleted] = React.useState<Array<any>>(DEFAULT_COMPLETED_DATA)
  const [currentViewDetailPlan, setCurrentViewDetailPlan] = React.useState<any>({})
  const [defaultSelect, setDefaultSelect] = React.useState<any>(0)
  const [selectedDelete, setSelectedDelete] = React.useState('')
  const [showWarningDeletePlan, setShowWarningDeletePlan] = React.useState(false)

  function convertCNAStatusToBadgeStatus(cnaStatus: string) {
    switch (cnaStatus) {
      case CnaStatus.DRAFT:
      case CnaStatus.DRAFT_INDEX:
        return 'warning'
      case CnaStatus.COMPLETED:
      case CnaStatus.COMPLETED_INDEX:
        return 'success'
      case CnaStatus.NEED_CONTACT_INFO:
      case CnaStatus.NEED_CONTACT_INFO_INDEX:
        return 'error'
      default:
        return 'primary'
    }
  }

  React.useEffect(() => {
    if (isFocused) {
      initData()
    }
  }, [isFocused])

  React.useEffect( () => {
    if ( updateRecord !== '') {
       setPageTodayIndex(1)
       setPageMonthIndex(1)
       setPageIndex(1)
       setSelectedDelete(updateRecord)
    }
  }, [updateRecord])

  React.useEffect( () => {
    if ( selectedDelete !== '') {
      initData();
    }
  }, [selectedDelete])

  async function initData() {
    setIsLoading(true)
    setCnaData({})
    await loadSummaryAsync()
    await getCnaTodayList({ page: pageTodayIndex, limit: 10, dataFilters: dataFiltersCompleted, dataSorts: dataSort })
    await getCnaReviewList({ page: pageIndex, limit: 10, dataFilters: dataFilters, dataSorts: dataSort })
    await getCnaMonthList({ page: pageMonthIndex, limit: 10, dataFilters: dataFilterDay, dataSorts: dataSort })
    setIsLoading(false)
  }

  function handleLoadMoreTodayCna(e: any) {
    if (!loadingMoreTodayList && isHaveMoreTodayList && e.distanceFromEnd >= 0) {
      const newPage = pageTodayIndex + 1
      setPageTodayIndex(newPage)
      getCnaTodayList({ page: newPage, limit: 10, dataFilters: dataFiltersCompleted, dataSorts: dataSort })
    }
  }

  function handleLoadMoreMonthCna(e: any) {
    if (!loadingMoreMonthList && isHaveMoreMonthList && e.distanceFromEnd >= 0) {
      const newPage = pageMonthIndex + 1
      setPageMonthIndex(newPage)
      getCnaMonthList({ page: newPage, limit: 10, dataFilters: dataFilterDay, dataSorts: dataSort })
    }
  }

  function handleLoadMoreReviewCna(e: any) {
    if (!loadingMoreReviewList && isHaveMoreReviewList && e.distanceFromEnd >= 0) {
      const newPage = pageIndex + 1
      setPageIndex(newPage)
      getCnaReviewList({ page: newPage, limit: 10, dataFilters: dataFilters, dataSorts: dataSort })
    }
  }


  function renderLoadMore(loadingMore?: boolean) {
    return loadingMore ? (
      <View style={desktopStyles.footer}>
        {loadingMore && <ActivityIndicator color="#ED1B2E" style={{ marginLeft: 8 }} />}
      </View>
    ) : (
      <View></View>
    )
  }

  function renderNoData(isInitData?: boolean) {
    return (
      <View style={desktopStyles.noDataContainer}>
        <Text>{isInitData ? `Đang tải...` : `Không tìm thấy dữ liệu`}</Text>
      </View>
    )
  }

  function renderNeedsReviewTodayCnaItem(cna?: any, index?: number) {
    if (isTabletDevice) {
      return (
        <View key={index}>
          <TouchableOpacity
            style={tabletStyles.cnaItemContainer}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <Text style={tabletStyles.cnaItemCusName}>{cna.reminiscentName ? cna.reminiscentName : '-'}</Text>
            <SizedBox width={8} />
            <View style={globalStyles.Separator} />
            <SizedBox width={8} />
           <Image source={icCall as ImageSourcePropType} style={[globalStyles.Icon16]} /> 
            <Text
              style={tabletStyles.cnaItemPhone}
            >
              {`${cna?.customerPhone ? cna?.customerPhone : '-'}`}
            </Text>
            <SizedBox width={17} />
            <Image source={icCalendar as ImageSourcePropType} style={[globalStyles.Icon16]} />
            <Text style={tabletStyles.cnaItemDate}>
              {cna?.reviewDate ? moment(cna?.reviewDate).format('DD/MM/YYYY') : ''}
            </Text>
          </TouchableOpacity>
        </View>
      )
    }

    return (
      <View key={index}>
        <View style={desktopStyles.cnaItemContainer}>
          <TouchableOpacity
            style={[globalStyles.FloatLeft, globalStyles.ColumnWidth]}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <Text style={desktopStyles.cnaItemCusName}>{cna.reminiscentName ? cna.reminiscentName : '-'}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[globalStyles.ColumnWidth]}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          ></TouchableOpacity>
          <TouchableOpacity
            style={[
              globalStyles.DisplayFlexRow,
              globalStyles.Center,
              desktopStyles.CellPhone,
              globalStyles.ColumnWidth,
            ]}
          >
            {cna?.customerPhone && (
              <>
                {' '}
                <Image source={icCall as ImageSourcePropType} style={[globalStyles.Icon20, desktopStyles.icon]} />
                <Text
                  style={{
                    color: '#505D6F',
                    fontSize: 16,
                    textAlign: 'center',
                  }}
                >
                  {cna?.customerPhone}
                </Text>
              </>
            )}
          </TouchableOpacity>
          <TouchableOpacity style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.ColumnWidth]}>
            <>
              <Image source={icCalendar as ImageSourcePropType} style={[globalStyles.Icon20, desktopStyles.icon]} />
              <Text
                style={{
                  color: '#505D6F',
                  fontSize: 16,
                  textAlign: 'center',
                }}
              >
                {cna?.reviewDate ? moment(cna?.reviewDate).format('DD/MM/YYYY') : ''}
              </Text>
            </>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  function renderNeedsReviewThisMonthCnaItem(cna?: any, index?: number) {
    if (isTabletDevice) {
      return (
        <View key={index}>
          <TouchableOpacity
            style={tabletStyles.cnaItemContainer}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <Text style={tabletStyles.cnaItemCusName}>{cna.reminiscentName ? cna.reminiscentName : '-'}</Text>
            <SizedBox width={8} />
            <View style={globalStyles.Separator} />
            <SizedBox width={8} />
            <Image source={icCall as ImageSourcePropType} style={[globalStyles.Icon16]} /> 
            <Text
              style={tabletStyles.cnaItemPhone}
            >
              {`${cna?.customerPhone ? cna?.customerPhone : '-'}`}
            </Text>
            <SizedBox width={17} />
            <Image source={icCalendar as ImageSourcePropType} style={[globalStyles.Icon16]} />
            <Text style={tabletStyles.cnaItemDate}>
              {cna?.reviewDate ? moment(cna?.reviewDate).format('DD/MM/YYYY') : ''}
            </Text>
          </TouchableOpacity>
        </View>
      )
    }

    return (
      <View key={index}>
        <View style={desktopStyles.cnaItemContainer}>
          <TouchableOpacity
            style={[globalStyles.FloatLeft, globalStyles.ColumnWidth]}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <Text style={desktopStyles.cnaItemCusName}>{cna.reminiscentName ? cna.reminiscentName : '-'}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[globalStyles.ColumnWidth]}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          ></TouchableOpacity>
          <TouchableOpacity
            style={[
              globalStyles.DisplayFlexRow,
              globalStyles.Center,
              desktopStyles.CellPhone,
              globalStyles.ColumnWidth,
            ]}
          >
            {cna?.customerPhone ? (
              <>
                {' '}
                <Image source={icCall as ImageSourcePropType} style={[globalStyles.Icon20, desktopStyles.icon]} />
                <Text
                  style={{
                    color: '#505D6F',
                    fontSize: 16,
                    textAlign: 'center',
                  }}
                >
                  {cna?.customerPhone}
                </Text>
              </>
            ) : (
              <Text>-</Text>
            )}
          </TouchableOpacity>
          <TouchableOpacity style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.ColumnWidth]}>
            <>
              <Image source={icCalendar as ImageSourcePropType} style={[globalStyles.Icon20, desktopStyles.icon]} />
              <Text
                style={{
                  color: '#505D6F',
                  fontSize: 16,
                  textAlign: 'center',
                }}
              >
                {cna?.reviewDate ? moment(cna?.reviewDate).format('DD/MM/YYYY') : ''}
              </Text>
            </>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  function renderCreatedAtTodayCnaItem(cna?: any, index?: number) {
    if (isTabletDevice) {
      return (
        <View key={index}>
          <TouchableOpacity
            style={tabletStyles.cnaItemContainer}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <Text style={tabletStyles.cnaItemCusName}>{cna.reminiscentName ? cna.reminiscentName : '-'}</Text>
            <View style={[globalStyles.ColumnWidth]}>
              <BadgeComponent
                value={i18n.t(`CNA_STATUS.${cna?.status}`)}
                transparent
                status={convertCNAStatusToBadgeStatus(`${cna?.status}`)}
                badgeStyle={{ width: 80 }}
              />
            </View>
            <SizedBox width={8} />
            <View style={globalStyles.Separator} />
            <SizedBox width={8} />
            <Image source={icCall as ImageSourcePropType} style={[globalStyles.Icon16]} />
            <Text
              style={tabletStyles.cnaItemPhone}
            >
              {`${cna?.customerPhone ? cna?.customerPhone : '-'}`}
            </Text>
            <SizedBox width={17} />
            <Image source={icCalendar as ImageSourcePropType} style={[globalStyles.Icon16]} />
            <Text style={tabletStyles.cnaItemDate}>
              {cna?.createdAt ? moment(cna?.createdAt).format('DD/MM/YYYY') : ''}
            </Text>
          </TouchableOpacity>
        </View>
      )
    }

    return (
      <View key={index}>
        <View style={desktopStyles.cnaItemContainer}>
          <TouchableOpacity
            style={[globalStyles.FloatLeft, globalStyles.ColumnWidth]}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <Text style={desktopStyles.cnaItemCusName}>{cna.reminiscentName ? cna.reminiscentName : '-'}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[globalStyles.ColumnWidth]}
            onPress={() => {
              onViewDetail && onViewDetail(cna)
            }}
          >
            <BadgeComponent
              value={i18n.t(`CNA_STATUS.${cna?.status}`)}
              transparent
              status={convertCNAStatusToBadgeStatus(`${cna?.status}`)}
              badgeStyle={{ width: 112 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              globalStyles.DisplayFlexRow,
              globalStyles.Center,
              desktopStyles.CellPhone,
              globalStyles.ColumnWidth,
            ]}
          >
            {cna?.customerPhone ? (
              <>
                {' '}
                <Image source={icCall as ImageSourcePropType} style={[globalStyles.Icon20, desktopStyles.icon]} />
                <Text
                  style={{
                    color: '#505D6F',
                    fontSize: 16,
                    textAlign: 'center',
                  }}
                >
                  {cna?.customerPhone}
                </Text>
              </>
            ) : (
              <Text>-</Text>
            )}
          </TouchableOpacity>
          <TouchableOpacity style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.ColumnWidth]}>
            <>
              <Image source={icCalendar as ImageSourcePropType} style={[globalStyles.Icon20, desktopStyles.icon]} />
              <Text style={desktopStyles.cnaItemDate}>
                {cna?.createdAt ? moment(cna?.createdAt).format('DD/MM/YYYY') : ''}
              </Text>
            </>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  function renderNoDocument() {
    return (
      <View style={[globalStyles.mobileNoDataContainer]}>
        <Image source={imgNoDocument} style={{ width: 60, height: 60, resizeMode: 'contain' }} />
        <SizedBox height={8} />
        <Text style={{ color: 'grey' }}>{i18n.t('COMMON.NO_DATA')}</Text>
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={mobileStyles.highlightPlanContainer}>
        <SizedBox height={20} />
        <View style={mobileStyles.highlightPlanArea}>
          <View style={mobileStyles.highlightPlanAreaHeaderContainer}>
            <View style={mobileStyles.highlightPlanAreaHeaderContainer}>
              <Text style={mobileStyles.highlightPlanAreaTitle}>{`Kế hoạch tới hạn xem lại (Hôm nay) ${
                  cnaReview && cnaReview?.items && cnaReview?.items?.length > 0
                    ? `(${cnaReview?.totalRecords ?? 0})`
                    : ''
                }`}</Text>
              <View style={mobileStyles.highlightPlanAreaSwitch}>
                <TextButton
                  title={i18n.t('COMMON.VIEW_ALL')}
                  buttonStyle={{ justifyContent: 'flex-end', paddingRight: 0 }}
                  labelStyle={{ fontSize: 13 }}
                  onPress={() => navigation.push('CnaFilterReviewDate')}
                />
              </View>
            </View>
          </View>
          <SizedBox height={8} />
          {cnaReview?.items && cnaReview?.items.length > 0 ? (
            cnaReview.items.length > 3 ? (
              cnaReview.items.slice(0, 3).map((e, i) => {
                return (
                  <MobileCnaItem
                    key={i}
                    cna={e}
                    type="review"
                    onPress={(cna: any) => {
                      navigation.push('MobileDetailCna', {
                        id: cna._id,
                        mode: ViewMode.EDITABLE,
                      })
                    }}
                  />
                )
              })
            ) : (
              cnaReview.items.map((e, i) => {
                return (
                  <MobileCnaItem
                    key={i}
                    cna={e}
                    type="review"
                    onPress={(cna: any) => {
                      navigation.navigate('MobileDetailCna', {
                        id: cna._id,
                        mode: ViewMode.EDITABLE,
                      })
                    }}
                  />
                )
              })
            )
          ) : (renderNoDocument())}
        </View>
        <SizedBox height={20} />
        <View style={mobileStyles.highlightPlanArea}>
          <View style={mobileStyles.highlightPlanAreaHeaderContainer}>
            <View style={mobileStyles.highlightPlanAreaHeaderContainer}>
              <Text style={mobileStyles.highlightPlanAreaTitle}>
              {`Kế hoạch tới hạn xem lại (30 ngày) ${
                  cnaMonthList && cnaMonthList?.items && cnaMonthList?.items?.length > 0
                    ? `(${cnaMonthList?.totalRecords ?? 0})`
                    : ''
                }`}
                </Text>
              <View style={mobileStyles.highlightPlanAreaSwitch}>
                <TextButton
                  title={i18n.t('COMMON.VIEW_ALL')}
                  buttonStyle={{ justifyContent: 'flex-end', paddingRight: 0 }}
                  labelStyle={{ fontSize: 13 }}
                  onPress={() => navigation.navigate('CnaFilterMonth')}
                />
              </View>
            </View>
          </View>
          <SizedBox height={8} />
          {cnaMonthList?.items && cnaMonthList?.items.length > 0 ? (
            cnaMonthList.items.length > 3 ? (
              cnaMonthList.items.slice(0, 3).map((e, i) => {
                return (
                  <MobileCnaItem
                    key={i}
                    cna={e}
                    type="review"
                    onPress={(cna: any) => {
                      navigation.navigate('MobileDetailCna', {
                        id: cna._id,
                        mode: ViewMode.EDITABLE,
                      })
                    }}
                  />
                )
              })
            ) : (
              cnaMonthList.items.map((e, i) => {
                return (
                  <MobileCnaItem
                    key={i}
                    cna={e}
                    type="review"
                    onPress={(cna: any) => {
                      navigation.push('MobileDetailCna', {
                        id: cna._id,
                        mode: ViewMode.EDITABLE,
                      })
                    }}
                  />
                )
              })
            )
          ) : (renderNoDocument())}
        </View>
        <SizedBox height={20} />
        <View style={mobileStyles.highlightPlanArea}>
          <View style={mobileStyles.highlightPlanAreaHeaderContainer}>
            <View style={mobileStyles.highlightPlanAreaHeaderContainer}>
              <Text style={mobileStyles.highlightPlanAreaTitle}>
                {`Tạo hôm nay ${
                   cnaTodayList?.items && cnaTodayList?.items.length > 0 ? `(${cnaTodayList?.totalRecords ?? 0})` : ''
                }`}
              </Text>
              <View style={mobileStyles.highlightPlanAreaSwitch}>
                <TextButton
                  title={i18n.t('COMMON.VIEW_ALL')}
                  buttonStyle={{ justifyContent: 'flex-end', paddingRight: 0 }}
                  labelStyle={{ fontSize: 13 }}
                  onPress={() => navigation.push('CnaFilterCreateToday')}
                />
              </View>
            </View>
          </View>
          <SizedBox height={8} />
          {cnaTodayList?.items && cnaTodayList?.items.length > 0 ? (
            cnaTodayList.items.length > 3 ? (
              cnaTodayList.items.slice(0, 3).map((e, i) => {
                return (
                  <MobileCnaItem
                    key={i}
                    cna={e}
                    onPress={(cna: any) => {
                      navigation.push('MobileDetailCna', {
                        id: cna._id,
                        mode: ViewMode.EDITABLE,
                      })
                    }}
                  />
                )
              })
            ) : (
              cnaTodayList.items.map((e, i) => {
                return (
                  <MobileCnaItem
                    key={i}
                    cna={e}
                    onPress={(cna: any) => {
                      navigation.push('MobileDetailCna', {
                        id: cna._id,
                        mode: ViewMode.EDITABLE,
                      })
                    }}
                  />
                )
              })
            )
          ) : (renderNoDocument())}
        </View>
      </View>
    )
  }

  if (isTabletDevice) {
    return (
      <View style={desktopStyles.highlightPlanContainer}>
        <View style={desktopStyles.highlightPlanArea}>
          <View style={desktopStyles.highlightPlanAreaHeaderContainer}>
            <Text style={tabletStyle.highlightPlanAreaTitle}>Kế hoạch tới hạn xem lại</Text>
            <View style={desktopStyles.highlightPlanAreaSwitch}>
              <SwitchSelect
                switchOption={
                  {
                    options: [
                      {
                        label: `Trong hôm nay ${
                          cnaReview && cnaReview.items?.length ? `(${cnaReview.totalRecords ?? 0})` : ''
                        }`,
                        value: 0,
                      },
                      {
                        label: `Trong 30 ngày ${
                          cnaMonthList && cnaMonthList.items?.length ? `(${cnaMonthList.totalRecords ?? 0})` : ''
                        }`,
                        value: 1,
                      },
                    ],
                    initial: defaultSelect,
                    backgroundColor: '#F7F8FA',
                    selectedColor: '#EDF2F4',
                    buttonColor: '#EDF2F4',
                    onPress: (value: any) => {
                      setDefaultSelect(value)
                    },
                    textStyle: { fontSize: 13 },
                    selectedTextStyle: { fontSize: 13 },
                  } as SwitchItem
                }
              />
            </View>
          </View>

          {defaultSelect === 0 ? (
            <View
              style={{
                backgroundColor: cnaReview?.items && cnaReview?.items.length > 0 ? 'transparent' : '#fff',
                height: 180,
                flexDirection: 'row',
                alignItems: cnaReview?.items && cnaReview.items.length > 0 ? 'flex-start' : 'center',
                justifyContent: 'center',
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
              }}
            >
              <CustomScrollView
                height={180}
                containerStyle={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 16 }}
                footerComponent={renderLoadMore(loadingMoreReviewList)}
                onReachEnd={() => handleLoadMoreReviewCna({ distanceFromEnd: 1 })}
              >
                {cnaReview?.items && cnaReview?.items.length > 0 ? (
                  <View style={{ paddingHorizontal: 16 }}>
                    {cnaReview?.items?.map((e: any, i: any) => {
                      return renderNeedsReviewTodayCnaItem(e, i)
                    })}
                  </View>
                ) : (
                  renderNoData(isLoading)
                )}
              </CustomScrollView>
            </View>
          ) : (
            <View
              style={{
                backgroundColor: cnaMonthList?.items && cnaMonthList?.items.length > 0 ? 'transparent' : '#fff',
                height: 180,
                flexDirection: 'row',
                alignItems: cnaMonthList?.items && cnaMonthList?.items.length > 0 ? 'flex-start' : 'center',
                justifyContent: 'center',
              }}
            >
              <CustomScrollView
                height={180}
                containerStyle={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 16 }}
                footerComponent={renderLoadMore(loadingMoreMonthList)}
                onReachEnd={() => handleLoadMoreMonthCna({ distanceFromEnd: 1 })}
              >
                {cnaMonthList?.items && cnaMonthList?.items.length > 0 ? (
                  <View style={{ paddingHorizontal: 16 }}>
                    {cnaMonthList?.items?.map((e: any, i: any) => {
                      return renderNeedsReviewThisMonthCnaItem(e, i)
                    })}
                  </View>
                ) : (
                  renderNoData(isLoading)
                )}
              </CustomScrollView>
            </View>
          )}
        </View>
        <SizedBox width={20} />
        <View style={desktopStyles.highlightPlanArea}>
          <View style={desktopStyles.highlightPlanAreaHeaderContainer}>
            <Text style={tabletStyle.highlightPlanAreaTitle}>
              {`Tạo hôm nay ${
                cnaTodayList?.items && cnaTodayList?.items.length > 0 ? `(${cnaTodayList?.totalRecords ?? 0})` : ''
              }`}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: cnaTodayList?.items && cnaTodayList?.items.length > 0 ? 'transparent' : '#fff',
              height: 180,
              flexDirection: 'row',
              alignItems: cnaTodayList?.items && cnaTodayList?.items.length > 0 ? 'flex-start' : 'center',
              justifyContent: 'center',
            }}
          >
            <CustomScrollView
              height={180}
              containerStyle={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 16 }}
              footerComponent={renderLoadMore(loadingMoreTodayList)}
              onReachEnd={() => handleLoadMoreTodayCna({ distanceFromEnd: 1 })}
            >
              {cnaTodayList?.items && cnaTodayList?.items.length > 0 ? (
                <View style={{ paddingHorizontal: 16 }}>
                  {cnaTodayList?.items?.map((e: any, i: any) => {
                    return renderCreatedAtTodayCnaItem(e, i)
                  })}
                </View>
              ) : (
                renderNoData(isLoading)
              )}
            </CustomScrollView>
          </View>
        </View>
      </View>
    )
  }
  return (
    <View style={desktopStyles.highlightPlanContainer}>
      <View style={desktopStyles.highlightPlanArea}>
        <View style={desktopStyles.highlightPlanAreaHeaderContainer}>
          <Text style={desktopStyles.highlightPlanAreaTitle}>Kế hoạch tới hạn xem lại</Text>
          <View style={desktopStyles.highlightPlanAreaSwitch}>
            <SwitchSelect
              switchOption={
                {
                  options: [
                    {
                      label: `Trong hôm nay ${
                        cnaReview && cnaReview.items?.length ? `(${cnaReview.totalRecords ?? 0})` : ''
                      }`,
                      value: 0,
                    },
                    {
                      label: `Trong 30 ngày ${
                        cnaMonthList && cnaMonthList.items?.length ? `(${cnaMonthList.totalRecords ?? 0})` : ''
                      }`,
                      value: 1,
                    },
                  ],
                  initial: defaultSelect,
                  backgroundColor: '#F7F8FA',
                  selectedColor: '#EDF2F4',
                  buttonColor: '#EDF2F4',
                  onPress: (value: any) => {
                    setDefaultSelect(value)
                  },
                } as SwitchItem
              }
            />
          </View>
        </View>

        {defaultSelect === 0 ? (
          <View
            style={{
              backgroundColor: cnaReview?.items && cnaReview?.items.length > 0 ? 'transparent' : '#fff',
              height: 180,
              flexDirection: 'row',
              alignItems: cnaReview?.items && cnaReview.items.length > 0 ? 'flex-start' : 'center',
              justifyContent: 'center',
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            }}
          >
            <CustomScrollView
              height={180}
              containerStyle={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 16 }}
              footerComponent={renderLoadMore(loadingMoreReviewList)}
              onReachEnd={() => handleLoadMoreReviewCna({ distanceFromEnd: 1 })}
            >
              {cnaReview?.items && cnaReview?.items.length > 0 ? (
                <View style={{ paddingHorizontal: 20 }}>
                  {cnaReview?.items?.map((e: any, i: any) => {
                    return (
                      <View key={i}>
                        <View
                          style={{
                            backgroundColor: '#fff',
                            flexDirection: 'row',
                            width: '100%',
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                            marginBottom: 10,
                            minHeight: 50,
                            alignItems: 'center',
                            borderRadius: 12,
                          }}
                        >
                          <TouchableOpacity
                            style={[globalStyles.FloatLeft, globalStyles.ColumnWidth]}
                            onPress={() => {
                              onViewDetail && onViewDetail(e)
                            }}
                          >
                            <Text
                              style={{
                                color: '#2F7AEB',
                                fontSize: 16,
                                fontWeight: '600',
                                textAlign: 'left',
                                width: '100%',
                              }}
                            >
                              {e.reminiscentName ? e.reminiscentName : '-'}
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[globalStyles.ColumnWidth]}
                            onPress={() => {
                              onViewDetail && onViewDetail(e)
                            }}
                          ></TouchableOpacity>
                          <TouchableOpacity
                            style={[
                              globalStyles.DisplayFlexRow,
                              globalStyles.Center,
                              desktopStyles.CellPhone,
                              globalStyles.ColumnWidth,
                            ]}
                          >
                            {e?.customerPhone ? (
                              <>
                                {' '}
                                <Image
                                  source={icCall as ImageSourcePropType}
                                  style={[globalStyles.Icon20, desktopStyles.icon]}
                                />
                                <Text
                                  style={{
                                    color: '#505D6F',
                                    fontSize: 16,
                                    textAlign: 'center',
                                  }}
                                >
                                  {e?.customerPhone}
                                </Text>
                              </>
                            ):
                            <Text>-</Text>
                          }
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.ColumnWidth]}
                          >
                            <>
                              <Image
                                source={icCalendar as ImageSourcePropType}
                                style={[globalStyles.Icon20, desktopStyles.icon]}
                              />
                              <Text
                                style={{
                                  color: '#505D6F',
                                  fontSize: 16,
                                  textAlign: 'center',
                                }}
                              >
                                {e?.reviewDate ? moment(e?.reviewDate).format('DD/MM/YYYY') : ''}
                              </Text>
                            </>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )
                  })}
                </View>
              ) : (
                renderNoData(isLoading)
              )}
            </CustomScrollView>
          </View>
        ) : (
          <View
            style={{
              backgroundColor: cnaMonthList?.items && cnaMonthList?.items.length > 0 ? 'transparent' : '#fff',
              height: 180,
              flexDirection: 'row',
              alignItems: cnaMonthList?.items && cnaMonthList?.items.length > 0 ? 'flex-start' : 'center',
              justifyContent: 'center',
            }}
          >
            <CustomScrollView
              height={180}
              containerStyle={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 16 }}
              footerComponent={renderLoadMore(loadingMoreMonthList)}
              onReachEnd={() => handleLoadMoreMonthCna({ distanceFromEnd: 1 })}
            >
              {cnaMonthList?.items && cnaMonthList?.items.length > 0 ? (
                <View style={{ paddingHorizontal: 20 }}>
                  {cnaMonthList?.items?.map((e: any, i: any) => {
                    return (
                      <View key={i}>
                        <View
                          style={{
                            backgroundColor: '#fff',
                            flexDirection: 'row',
                            width: '100%',
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                            marginBottom: 10,
                            minHeight: 50,
                            alignItems: 'center',
                            borderRadius: 12,
                          }}
                        >
                          <TouchableOpacity
                            style={[globalStyles.FloatLeft, globalStyles.ColumnWidth]}
                            onPress={() => {
                              onViewDetail && onViewDetail(e)
                            }}
                          >
                            <Text
                              style={{
                                color: '#2F7AEB',
                                fontSize: 16,
                                fontWeight: '600',
                                textAlign: 'left',
                                width: '100%',
                              }}
                            >
                              {e.reminiscentName ? e.reminiscentName : '-'}
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[globalStyles.ColumnWidth]}
                            onPress={() => {
                              onViewDetail && onViewDetail(e)
                            }}
                          ></TouchableOpacity>
                          <TouchableOpacity
                            style={[
                              globalStyles.DisplayFlexRow,
                              globalStyles.Center,
                              desktopStyles.CellPhone,
                              globalStyles.ColumnWidth,
                            ]}
                          >
                            {e?.customerPhone ? (
                              <>
                                {' '}
                                <Image
                                  source={icCall as ImageSourcePropType}
                                  style={[globalStyles.Icon20, desktopStyles.icon]}
                                />
                                <Text
                                  style={{
                                    color: '#505D6F',
                                    fontSize: 16,
                                    textAlign: 'center',
                                  }}
                                >
                                  {e?.customerPhone}
                                </Text>
                              </>
                            ) : (
                              <Text>-</Text>
                            )}
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.ColumnWidth]}
                          >
                            <>
                              <Image
                                source={icCalendar as ImageSourcePropType}
                                style={[globalStyles.Icon20, desktopStyles.icon]}
                              />
                              <Text
                                style={{
                                  color: '#505D6F',
                                  fontSize: 16,
                                  textAlign: 'center',
                                }}
                              >
                                {e?.reviewDate ? moment(e?.reviewDate).format('DD/MM/YYYY') : ''}
                              </Text>
                            </>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )
                  })}
                </View>
              ) : (
                renderNoData(isLoading)
              )}
            </CustomScrollView>
          </View>
        )}
      </View>
      <SizedBox width={20} />
      <View style={desktopStyles.highlightPlanArea}>
        <View style={desktopStyles.highlightPlanAreaHeaderContainer}>
          <Text style={desktopStyles.highlightPlanAreaTitle}>
            {`Tạo hôm nay ${
              cnaTodayList?.items && cnaTodayList?.items.length > 0 ? `(${cnaTodayList?.totalRecords ?? 0})` : ''
            }`}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: cnaTodayList?.items && cnaTodayList?.items.length > 0 ? 'transparent' : '#fff',
            height: 180,
            flexDirection: 'row',
            alignItems: cnaTodayList?.items && cnaTodayList?.items.length > 0 ? 'flex-start' : 'center',
            justifyContent: 'center',
          }}
        >
          <CustomScrollView
            height={180}
            containerStyle={{ paddingHorizontal: 0, paddingTop: 0, paddingBottom: 16 }}
            footerComponent={renderLoadMore(loadingMoreTodayList)}
            onReachEnd={() => handleLoadMoreTodayCna({ distanceFromEnd: 1 })}
          >
            {cnaTodayList?.items && cnaTodayList?.items.length > 0 ? (
              <View style={{ paddingHorizontal: 20 }}>
                {cnaTodayList?.items?.map((e: any, i: any) => {
                  return (
                    <View key={i}>
                      <View
                        style={{
                          backgroundColor: '#fff',
                          flexDirection: 'row',
                          width: '100%',
                          paddingHorizontal: 16,
                          paddingVertical: 5,
                          marginBottom: 10,
                          minHeight: 50,
                          alignItems: 'center',
                          borderRadius: 12,
                        }}
                      >
                        <TouchableOpacity
                          style={[globalStyles.FloatLeft, globalStyles.ColumnWidth]}
                          onPress={() => {
                            onViewDetail && onViewDetail(e)
                          }}
                        >
                          <Text
                            style={{
                              color: '#2F7AEB',
                              fontSize: 16,
                              fontWeight: '600',
                              textAlign: 'left',
                              width: '100%',
                            }}
                          >
                            {e.reminiscentName ? e.reminiscentName : '-'}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[globalStyles.ColumnWidth]}
                          onPress={() => {
                            onViewDetail && onViewDetail(e)
                          }}
                        >
                          <BadgeComponent
                            value={i18n.t(`CNA_STATUS.${e?.status}`)}
                            transparent
                            status={convertCNAStatusToBadgeStatus(`${e?.status}`)}
                            badgeStyle={{ width: 112 }}
                          />
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            globalStyles.DisplayFlexRow,
                            globalStyles.Center,
                            desktopStyles.CellPhone,
                            globalStyles.ColumnWidth,
                          ]}
                        >
                          {e?.customerPhone ? (
                            <>
                              {' '}
                              <Image
                                source={icCall as ImageSourcePropType}
                                style={[globalStyles.Icon20, desktopStyles.icon]}
                              />
                              <Text
                                style={{
                                  color: '#505D6F',
                                  fontSize: 16,
                                  textAlign: 'center',
                                }}
                              >
                                {e?.customerPhone}
                              </Text>
                            </>
                          ) : (
                            <Text>-</Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.ColumnWidth]}
                        >
                          <>
                            <Image
                              source={icCalendar as ImageSourcePropType}
                              style={[globalStyles.Icon20, desktopStyles.icon]}
                            />
                            <Text
                              style={{
                                color: '#505D6F',
                                fontSize: 16,
                                textAlign: 'center',
                              }}
                            >
                              {e?.createdAt ? moment(e?.createdAt).format('DD/MM/YYYY') : ''}
                            </Text>
                          </>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )
                })}
              </View>
            ) : (
              renderNoData(isLoading)
            )}
          </CustomScrollView>
        </View>
      </View>
    </View>
  )
}

export default connector(PlanNeedReview)

const desktopStyles = StyleSheet.create({
  itemImage: {
    width: '100%',
    height: 200,
  },
  highlightPlanContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
  },
  highlightPlanArea: {
    paddingTop: 20,
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    flex: 1,
  },
  highlightPlanTablet: {
    flexDirection: 'column',
    width: '100%',
    marginTop: 30,
  },
  highlightPlanAreaTablet: {
    padding: 20,
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    width: '100%',
    paddingBottom: 0,
  },
  highlightPlanAreaHeaderContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    height: 40,
    paddingHorizontal: 20,
  },
  highlightPlanAreaSwitch: {
    flex: 1,
    marginLeft: 36,
    maxWidth: 400,
  },
  highlightPlanAreaTitle: {
    color: '#505D6F',
    fontSize: 18,
    fontWeight: 'bold',
  },
  CellPhone: {
    height: '100%',
    width: '100%',
    borderLeftColor: '#D7DFFF',
    borderLeftWidth: 3,
  },
  icon: {
    marginRight: 10,
  },
  footer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  noDataContainer: {
    width: '100%',
    height: 160,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cnaItemContainer: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginBottom: 10,
    minHeight: 50,
    alignItems: 'center',
    borderRadius: 12,
  },
  cnaItemCusName: {
    color: '#2F7AEB',
    fontSize: 12,
    fontWeight: '600',
    textAlign: 'left',
    width: '100%',
  },
  cnaItemDate: {
    fontSize: 12,
    marginLeft: 8,
    color: '#505D6F',
  },
  cnaItemPhone: {
    fontSize: 12,
    marginLeft: 8,
    color: '#505D6F',
  },
})

const tabletStyles = StyleSheet.create({
  itemImage: {
    width: '100%',
    height: 200,
  },
  highlightPlanContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
  },
  highlightPlanArea: {
    paddingTop: 20,
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    flex: 1,
  },
  highlightPlanTablet: {
    flexDirection: 'column',
    width: '100%',
    marginTop: 30,
  },
  highlightPlanAreaTablet: {
    padding: 20,
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    borderRadius: 12,
    width: '100%',
    paddingBottom: 0,
  },
  highlightPlanAreaHeaderContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    height: 40,
    paddingHorizontal: 20,
  },
  highlightPlanAreaSwitch: {
    flex: 1,
    marginLeft: 36,
    maxWidth: 400,
  },
  highlightPlanAreaTitle: {
    color: '#505D6F',
    fontSize: 18,
    fontWeight: 'bold',
  },
  CellPhone: {
    height: '100%',
    width: '100%',
    borderLeftColor: '#D7DFFF',
    borderLeftWidth: 3,
  },
  icon: {
    marginRight: 10,
  },
  footer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  noDataContainer: {
    width: '100%',
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cnaItemContainer: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 10,
    minHeight: 40,
    alignItems: 'center',
    borderRadius: 12,
  },
  cnaItemCusName: {
    color: '#2F7AEB',
    fontSize: 13,
    fontWeight: '600',
    textAlign: 'left',
    width: '100%',
  },
  cnaItemDate: {
    fontSize: 13,
    marginLeft: 8,
    color: '#505D6F',
    width: 80,
    maxWidth: 80,
  },
  cnaItemPhone: {
    fontSize: 13,
    marginLeft: 8,
    color: '#505D6F',
    minWidth: 80,
    maxWidth: 80,
  },
})

const mobileStyles = StyleSheet.create({
  highlightPlanContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  highlightPlanArea: {},
  highlightPlanAreaHeaderContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  highlightPlanAreaSwitch: {
    flex: 1,
    marginLeft: 36,
    maxWidth: 400,
  },
  highlightPlanAreaTitle: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: 'bold',
  },
  CellPhone: {
    height: '100%',
    width: '100%',
    borderLeftColor: '#D7DFFF',
    borderLeftWidth: 3,
  },
  icon: {
    marginRight: 10,
  },
})

const tabletStyle = StyleSheet.create({
  highlightPlanAreaTitle: {
    color: '#505D6F',
    fontSize: 14,
    fontWeight: 'bold',
  },
})
