import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { CustomScrollView, Line, SizedBox } from '../../../common'
import { tableProfit } from '../../../constants/Data'
import Layout from '../../../constants/Layout'
import RenderHtml from 'react-native-render-html'

type IProps = {}

function ProfitTable({}: IProps) {
  const isMobileDevice = Layout.isMobileDevice

  function renderBeneficiaryGroup(
    item: {
      groupTitle: string
      content: {
        title: string
        contentA: string
      }[]
    },
    option?: {
      renderA?: boolean
    }
  ) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.groupItemContainer}>
          <View style={[mobileStyles.groupItemContentContainer]}>
            {item.content.map((e) => {
              return (
                <View style={[mobileStyles.itemContentContainer]}>
                  <View style={mobileStyles.titleContainer}>
                    <View style={mobileStyles.itemRowTitleContainer}>
                      <RenderHtml html={e.title} />
                    </View>
                  </View>
                  <View style={mobileStyles.contentContainer}>
                    <View style={mobileStyles.contentRowContainer}>
                      <View style={mobileStyles.contentItemContainer}>
                        <RenderHtml html={e.contentA} />
                      </View>
                    </View>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      )
    }

    return (
      <View style={desktopStyles.groupItemContainer}>
        {/* Group content */}
        <View style={[desktopStyles.groupItemContentContainer]}>
          {item.content.map((e) => {
            return (
              <View style={[desktopStyles.itemContentContainer]}>
                <View style={desktopStyles.titleContainer}>
                  <View style={desktopStyles.itemRowTitleContainer}>
                    <RenderHtml html={e.title} />
                  </View>
                </View>
                <View style={desktopStyles.contentContainer}>
                  <View style={desktopStyles.contentRowContainer}>
                    <View style={desktopStyles.contentItemContainer}>
                      <RenderHtml html={e.contentA} />
                    </View>
                  </View>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={{ maxHeight: 320, paddingHorizontal: 0 }}>
        <SizedBox height={20} />
        <View style={{ paddingLeft: 10, paddingRight: 10 }}>
          <View style={[desktopStyles.itemContentContainer]}>
            <View style={desktopStyles.contentContainer}>
              <View style={[desktopStyles.contentRowContainer, { paddingVertical: 0 }]}>
                <View style={[desktopStyles.contentItemContainer, { backgroundColor: '#ECF0FF', paddingVertical: 4 }]}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: '#505D6F',
                    }}
                  >
                    Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <CustomScrollView
          style={{ paddingLeft: 10, paddingRight: 5, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
          <View>
            <>
              {tableProfit.map((item: any, index: number) => {
                return renderBeneficiaryGroup(item)
              })}
            </>
          </View>
          <Text style={desktopStyles.hintText}>
            <Text style={desktopStyles.hintTextBold}>Nguồn:</Text> Báo cáo thường niên Quỹ liên kết đơn vị năm 2021
          </Text>
        </CustomScrollView>
        <SizedBox height={10} />
      </View>
    )
  }

  return (
    <View style={{ maxHeight: 320, paddingHorizontal: 0 }}>
      <SizedBox height={20} />
      <View style={{ paddingLeft: 10, paddingRight: 10 }}>
        <View style={[desktopStyles.itemContentContainer]}>
          <View style={desktopStyles.titleContainer}>
            <View style={desktopStyles.itemRowTitleContainer}>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#505D6F',
                }}
              >
                Quỹ đầu tư
              </Text>
            </View>
          </View>
          <View style={desktopStyles.contentContainer}>
            <View style={[desktopStyles.contentRowContainer, { paddingVertical: 0 }]}>
              <View
                style={[
                  desktopStyles.contentItemContainer,
                  { backgroundColor: '#ECF0FF', paddingVertical: 4, marginLeft: 1 },
                ]}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#505D6F',
                  }}
                >
                  Tỷ suất lợi nhuận bình quân thực tế của các quỹ Prulink giai đoạn 5 năm (2017 – 2021)
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Line lineStyle="solid" orientation="horizontal" />
      <CustomScrollView
        style={{ paddingLeft: 10, paddingRight: 5, paddingTop: 0 }}
        containerStyle={{ padding: 0, height: '100%', flex: 1 }}
      >
        <View>
          <>
            {tableProfit.map((item: any, index: number) => {
              return renderBeneficiaryGroup(item)
            })}
          </>
        </View>
        <Text style={desktopStyles.hintText}>
          <Text style={desktopStyles.hintTextBold}>Nguồn:</Text> Báo cáo thường niên Quỹ liên kết đơn vị năm 2021
        </Text>
      </CustomScrollView>
      <SizedBox height={10} />
    </View>
  )
}

export default ProfitTable

const desktopStyles = StyleSheet.create({
  container: {
    maxWidth: 784,
    maxHeight: 600,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
  },
  groupItemContainer: {},
  groupItemTitleContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    borderColor: '#ECF0FF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
  },
  hintText: {
    fontSize: 12,
    fontStyle: 'italic',
    marginTop: 10,
  },
  hintTextBold: {
    fontWeight: '700',
  },
  titleContainer: {
    flex: 25,
  },
  itemRowTitleContainer: {
    backgroundColor: '#ECF0FF',
    borderWidth: 1,
    borderColor: '#ECF0FF',
    paddingHorizontal: 10,
    textAlign: 'center',
    paddingVertical: 6,
    height: '100%',
  },
  contentContainer: {
    flex: 75,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  contentItemContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#373737',
    fontFamily: 'Noto Sans',
    paddingHorizontal: 16,
  },
  groupTitleContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  groupItemContentContainer: {},
  contentRowContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingVertical: 8,
    alignItems: 'stretch',
  },
  itemContentContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: '#E7E7E7',
    display: 'flex',
  },
})

const mobileStyles = StyleSheet.create({
  listTitleContainer: {
    backgroundColor: '#FAFBFF',
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  listTitleHeaderContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#373737',
    fontFamily: 'Noto Sans',
    marginLeft: 1,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  container: {
    maxWidth: 784,
    maxHeight: 600,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
  },
  groupItemContainer: {},
  groupItemTitleContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    borderColor: '#ECF0FF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
  },
  titleContainer: {
    flex: 40,
  },
  itemRowTitleContainer: {
    backgroundColor: '#ECF0FF',
    borderWidth: 1,
    borderColor: '#ECF0FF',
    paddingHorizontal: 10,
    paddingVertical: 6,
    height: '100%',
  },
  contentContainer: {
    flex: 60,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  contentItemContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#373737',
    fontFamily: 'Noto Sans',
    marginLeft: 1,
  },
  groupTitleContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  groupItemContentContainer: {},
  contentRowContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingVertical: 8,
    alignItems: 'stretch',
  },
  itemContentContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: '#E7E7E7',
    display: 'flex',
  },
})
