import * as React from 'react'
import { ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import GenderSwitch from './GenderSwitch'
import { useForm, Controller } from 'react-hook-form'
import ValidateRequiredError from './ValidateRequiredError'
import { styles as globalStyles } from '../constants/globalStyles'
import FilledButton from './FilledButton'
import ExpandableSelect from './ExpandableSelect'
import { validateAge, validateMoney, validateRequired } from '../data/validate'
import i18n from 'i18n-js'
import NumberFormat from 'react-number-format'
import Layout from '../../constants/Layout'
import { martialStatusOptions } from '../../constants/Data'

export type ProfileInfoTabProps = {
  profileInfo?: any
  disabled?: boolean
  onUpdateProfile: (data: any) => void
}

export function ProfileInfoTab({ profileInfo, onUpdateProfile, disabled}: ProfileInfoTabProps) {
  const isMobileDevice = Layout.isMobileDevice

  const [gender, setGender] = React.useState(profileInfo?.gender)
  const [maritalStatusIndexSelected, setMaritalStatusIndexSelected] = React.useState<number>()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: profileInfo })

  React.useEffect(() => {
    setGender(profileInfo?.gender)
    setValue('age', profileInfo?.age)
    setValue('maritalStatus', profileInfo?.maritalStatus)
    setValue('monthlyIncome', profileInfo?.monthlyIncome)
    setValue('monthlyExpense', profileInfo?.monthlyExpense)
    setMaritalStatusIndexSelected(martialStatusOptions.findIndex((e) => e.value === profileInfo?.maritalStatus))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo])

  function onSubmit(data: any) {
    onUpdateProfile(data)
  }

  React.useEffect(() => {
    if (profileInfo) {
      reset(profileInfo);
    }
  },[profileInfo])

  function renderInputCodeError(error?: any) {
    if (!error) {
      return null
    }
    if (error.type === 'required') {
      return <ValidateRequiredError title="Thông tin này là bắt buộc" />
    }
    return null
  }

  function renderInputAgeError(error?: any) {
    if (!error) {
      return null
    }
    if (error.type === 'required') {
      return <ValidateRequiredError title={i18n.t('VALIDATE.REQUIRED')} />
    }
    if (error.type === 'min' || error.type === 'max' || error.type === 'pattern') {
      return <ValidateRequiredError title={i18n.t('VALIDATE.AGE')} />
    }
    return null
  }

  function renderInputMoneyError(error?: any) {
    if (!error) {
      return null
    }
    if (error.type === 'required') {
      return <ValidateRequiredError title={i18n.t('VALIDATE.REQUIRED')} />
    }
    if (error.type === 'min' || error.type === 'max' || error.type === 'pattern') {
      return <ValidateRequiredError title={i18n.t('VALIDATE.MONEY')} />
    }
    return null
  }

  if (isMobileDevice) {
    return (
      <>
        <View style={mobileStyles.headerContainer}>
          <Text style={mobileStyles.headerLabel}>Thông tin của bạn</Text>
        </View>
        <View style={mobileStyles.container}>
          {/* Gender */}
          <View style={mobileStyles.genderContainer}>
            <Text style={mobileStyles.formItemLabel}>Bạn là?</Text>
            <GenderSwitch value={gender} onChange={(value: string) => setGender(value)} />
          </View>
          {/* Age */}
          <View style={mobileStyles.formItemContainer}>
            <View style={globalStyles.DisplayFlexRow}>
              <Text style={mobileStyles.textInputLabel}>Tuổi</Text>
              <Text style={globalStyles.RedText}>&nbsp;*</Text>
            </View>
            {/* For option select from select box */}
            {/* <ExpandableSelect<Array<number>>
              data={ages}
              onSelect={(value: Array<number>, index: number) => {
                setAgeIndexSelected(index)
              }}
              currentSelectIndex={ageIndexSelected}
              renderItem={(item: Array<number>) => {
                if (item[0] && item[1])
                  return <Text style={mobileStyles.itemSelect}>{`${item[0] ?? 0} - ${item[1] ?? 0}`}</Text>
                else if (!item[0] && item[1]) return <Text style={mobileStyles.itemSelect}>{`< ${item[1] ?? 0}`}</Text>
                else if (item[0] && !item[1]) return <Text style={mobileStyles.itemSelect}>{`> ${item[0] ?? 0}`}</Text>
                else return <Text style={mobileStyles.itemSelect}>{`${item[1] ?? 0}`}</Text>
              }}
            /> */}
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={styles.textInputLabel}>{i18n.t('NEEDS_CART.AGE')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={styles.textInputContainer}>
                    <TextInput
                      style={styles.textInput}
                      placeholder="Nhập"
                      selectionColor="#fff"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      onChange={onChange}
                      value={value}
                    />
                  </View>
                </View>
              )}
              name="age"
              defaultValue=""
            />
            {renderInputAgeError(errors?.age)}
          </View>
          {/* Monthly income */}
          <View style={mobileStyles.formItemContainer}>
            <Controller
              control={control}
              rules={validateMoney}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={mobileStyles.textInputLabel}>{i18n.t('NEEDS_CART.MONTHLY_INCOME')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={mobileStyles.textInputContainer}>
                    <TextInput
                      style={mobileStyles.textInput}
                      placeholder="Nhập"
                      selectionColor="#fff"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                </View>
              )}
              name="monthlyIncome"
              defaultValue=""
            />
            {renderInputMoneyError(errors?.monthlyIncome)}
            {/* <ExpandableSelect<Array<number>>
              data={monthlyIncomes}
              currentSelectIndex={monthlyIncomeIndexSelected}
              onSelect={(value: Array<number>, index: number) => {
                setMonthlyIncomeIndexSelected(index)
              }}
              renderItem={(item: Array<number>) => {
                if (item[0] && item[1])
                  return (
                    <Text style={mobileStyles.itemSelect}>{`${vnCurrencyFormatter.format(
                      item[0] ?? 0
                    )} - ${vnCurrencyFormatter.format(item[1] ?? 0)}`}</Text>
                  )
                else if (!item[0] && item[1])
                  return <Text style={mobileStyles.itemSelect}>{`< ${vnCurrencyFormatter.format(item[1] ?? 0)}`}</Text>
                else if (item[0] && !item[1])
                  return <Text style={mobileStyles.itemSelect}>{`> ${vnCurrencyFormatter.format(item[0] ?? 0)}`}</Text>
                else return <Text style={mobileStyles.itemSelect}>{`${vnCurrencyFormatter.format(item[1] ?? 0)}`}</Text>
              }}
            /> */}
          </View>
          {/* Monthly expense */}
          <View style={mobileStyles.formItemContainer}>
            <Controller
              control={control}
              rules={validateMoney}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={styles.textInputLabel}>{i18n.t('NEEDS_CART.MONTHLY_EXPENSES')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={styles.textInputContainer}>
                    <TextInput
                      style={styles.textInput}
                      placeholder="Nhập"
                      selectionColor="#fff"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                </View>
              )}
              name="monthlyExpense"
              defaultValue=""
            />
            {renderInputMoneyError(errors?.monthlyExpense)}
            {/* <ExpandableSelect<Array<number>>
              data={monthlyExpenses}
              onSelect={(value: Array<number>, index: number) => {
                setMonthlyExpenseIndexSelected(index)
              }}
              currentSelectIndex={monthlyExpenseIndexSelected}
              renderItem={(item: Array<number>) => {
                if (item[0] && item[1])
                  return (
                    <Text style={mobileStyles.itemSelect}>{`${vnCurrencyFormatter.format(
                      item[0] ?? 0
                    )} - ${vnCurrencyFormatter.format(item[1] ?? 0)}`}</Text>
                  )
                else if (!item[0] && item[1])
                  return <Text style={mobileStyles.itemSelect}>{`< ${vnCurrencyFormatter.format(item[1] ?? 0)}`}</Text>
                else if (item[0] && !item[1])
                  return <Text style={mobileStyles.itemSelect}>{`> ${vnCurrencyFormatter.format(item[0] ?? 0)}`}</Text>
                else return <Text style={mobileStyles.itemSelect}>{`${vnCurrencyFormatter.format(item[1] ?? 0)}`}</Text>
              }}
            /> */}
            {renderInputMoneyError(errors?.code)}
          </View>
          {/* Marital status */}
          <View style={mobileStyles.maritalStatusContainer}>
            <Text style={mobileStyles.textInputLabel}>{i18n.t('NEEDS_CART.MARITAL_STATUS')}</Text>
            <ExpandableSelect<any>
              data={martialStatusOptions}
              onSelect={(value: any, index: number) => {
                setMaritalStatusIndexSelected(index)
              }}
              currentSelectIndex={maritalStatusIndexSelected}
              renderItem={(item: any) => {
                return <Text style={mobileStyles.itemSelect}>{item?.label}</Text>
              }}
            />
          </View>
        </View>
        <View style={mobileStyles.continueButton}>
          <FilledButton
            title="Tiếp tục"
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{ width: 100, height: 41, paddingHorizontal: 16, paddingVertical: 10, borderRadius: 8 }}
            titleStyle={{ fontSize: 14 }}
          />
        </View>
      </>
    )
  }

  return (
    <View style={[styles.container]}>
      <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerLabel}>Thông tin của bạn</Text>
        </View>
        <View style={[globalStyles.CommonPadding12, { paddingBottom: 0 }]} pointerEvents={disabled ? 'none' : 'auto'}>
          {/* Gender */}
          <View style={styles.genderContainer}>
            <Text style={styles.formItemLabel}>Bạn là?</Text>
            <GenderSwitch value={gender} onChange={(value: string) => setGender(value)} />
          </View>
          {/* Age */}
          <View style={styles.formItemContainer}>
            <Controller
              control={control}
              rules={validateAge}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={styles.textInputLabel}>Tuổi</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={styles.textInputContainer}>
                    <NumberFormat
                    placeholder={i18n.t('COMMON.INPUT')}
                    style={{
                      fontSize: 16,
                      flex: 1,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                      paddingBottom: 16,
                      borderWidth: 0,
                      borderColor: '#fff',
                      borderRadius: 20,
                      backgroundColor: '#FAFBFF',
                    }}
                    value={value}
                    thousandSeparator={true}
                    decimalScale={3}
                    prefix={''}
                    onValueChange={(values, sourceInfo) => {
                      const { formattedValue, value } = values
                      const { event, source } = sourceInfo
                      onChange(value)
                      setValue('age', value)
                    }}
                    renderText={value => (
                      <TextInput
                        underlineColorAndroid="transparent"
                        style={globalStyles.DesktopTextInput}
                        placeholder={i18n.t('COMMON.INPUT')}
                        onChangeText={(value) => {
                          onChange(value)
                          setValue('age', value)
                        }}
                        value={value}
                        keyboardType="numeric"
                      />
                    )}
                  />
                  </View>
                </View>
              )}
              name="age"
              defaultValue=""
            />
            {renderInputAgeError(errors?.age)}
          </View>
          {/* Monthly income */}
          <View style={styles.formItemContainer}>
            <Controller
              control={control}
              rules={validateMoney}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={styles.textInputLabel}>{i18n.t('NEEDS_CART.MONTHLY_INCOME')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={styles.textInputContainer}>
                  <NumberFormat
                    placeholder={i18n.t('COMMON.INPUT')}
                    style={{
                      fontSize: 16,
                      flex: 1,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                      paddingBottom: 16,
                      borderWidth: 0,
                      borderColor: '#fff',
                      borderRadius: 20,
                      backgroundColor: '#FAFBFF',
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={3}
                    value={value}
                    prefix={''}
                    onValueChange={(values, sourceInfo) => {
                      const { formattedValue, value } = values
                      const { event, source } = sourceInfo
                      onChange(value)
                      setValue('monthlyIncome', value)
                    }}
                    renderText={value => (
                      <TextInput
                        underlineColorAndroid="transparent"
                        style={globalStyles.DesktopTextInput}
                        placeholder={i18n.t('COMMON.INPUT')}
                        onChangeText={(value) => {
                          onChange(value)
                          setValue('monthlyIncome', value)
                        }}
                        value={value}
                        keyboardType="numeric"
                      />
                    )}
                  />
                  </View>
                </View>
              )}
              name="monthlyIncome"
              defaultValue=""
            />
            {renderInputMoneyError(errors?.monthlyIncome)}
          </View>
          {/* Monthly expense */}
          <View style={styles.formItemContainer}>
            <Controller
              control={control}
              rules={validateMoney}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={styles.textInputLabel}>{i18n.t('NEEDS_CART.MONTHLY_EXPENSES')}</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <View style={styles.textInputContainer}>
                    <NumberFormat
                      placeholder={i18n.t('COMMON.INPUT')}
                      style={{
                        fontSize: 16,
                        flex: 1,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 16,
                        paddingBottom: 16,
                        borderWidth: 0,
                        borderColor: '#fff',
                        borderRadius: 20,
                        backgroundColor: '#FAFBFF',
                      }}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={3}
                      value={value}
                      prefix={''}
                      onValueChange={(values, sourceInfo) => {
                        const { formattedValue, value } = values
                        const { event, source } = sourceInfo
                        onChange(value)
                        setValue('monthlyExpense', value)
                      }}
                      renderText={value => (
                        <TextInput
                          underlineColorAndroid="transparent"
                          style={globalStyles.DesktopTextInput}
                          placeholder={i18n.t('COMMON.INPUT')}
                          onChangeText={(value) => {
                            onChange(value)
                            setValue('monthlyExpense', value)
                          }}
                          value={value}
                          keyboardType="numeric"
                        />
                      )}
                    />
                  </View>
                </View>
              )}
              name="monthlyExpense"
              defaultValue=""
            />
            {renderInputMoneyError(errors?.monthlyExpense)}
          </View>
          {/* Marital status */}
          <View style={styles.maritalStatusContainer}>
            <Controller
              control={control}
              rules={validateRequired}
              render={({ onChange, onBlur, value }) => (
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <Text style={styles.textInputLabel}>Tình trạng hôn nhân</Text>
                    <Text style={globalStyles.RedText}>&nbsp;*</Text>
                  </View>
                  <ExpandableSelect<any>
                    data={martialStatusOptions}
                    onSelect={(value: any, index: number) => {
                      setMaritalStatusIndexSelected(index)
                      onChange(value.value)
                    }}
                    currentSelectIndex={maritalStatusIndexSelected}
                    renderItem={(item: any) => {
                      return <Text style={styles.itemSelect}>{item?.label}</Text>
                    }}
                  />
                </View>
              )}
              name="maritalStatus"
              defaultValue=""
            />
            {renderInputCodeError(errors?.maritalStatus)}
          </View>
          {!disabled &&
          <View style={mobileStyles.continueButton}>
          <FilledButton title="Cập nhật" onPress={handleSubmit(onSubmit)} titleStyle={{ fontSize: 14 }} />
        </View>
          }
        
        </View>
      </ScrollView>
    </View>
  )
}

export default ProfileInfoTab

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 8,
    width: 335,
    height: '100%',
    maxHeight: 692,
  },
  headerContainer: {
    backgroundColor: '#ED1B2E',
    borderRadius: 12,
    padding: 16,
  },
  headerLabel: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '700',
  },
  genderContainer: {
    marginTop: 12,
  },
  formItemContainer: {
    padding: 0,
    marginTop: 20,
  },
  formItemLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    backgroundColor: '#FAFBFF',
    borderRadius: 16,
  },
  textInputLabel: {
    fontSize: 15,
    fontWeight: '700',
    marginBottom: 8,
  },
  textInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderWidth: 0,
    borderColor: '#fff',
  },
  maritalStatusContainer: {
    paddingTop: 20,
    paddingHorizontal: 0,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
  itemSelect: {
    fontSize: 15,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  headerContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 16,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },
  headerLabel: {
    fontSize: 13,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  genderContainer: {
    marginTop: 12,
  },
  formItemContainer: {
    paddingVertical: 12,
    marginTop: 0,
  },
  formItemLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    backgroundColor: '#FAFBFF',
    borderRadius: 8,
  },
  textInputLabel: {
    fontSize: 13,
    fontWeight: '700',
    marginBottom: 8,
  },
  textInput: {
    fontSize: 13,
    fontWeight: '600',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 0,
    borderColor: '#fff',
  },
  maritalStatusContainer: {
    paddingVertical: 20,
    paddingHorizontal: 0,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
  itemSelect: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
})
