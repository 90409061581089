import React, { useEffect } from 'react'
import { View, StyleSheet, Image, Text } from 'react-native'
import { RootStackScreenProps } from '../../types'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'
import SummaryInfo from './components/SummaryInfo'
import SummaryNeeds from './components/SummaryNeeds'
import {
  dataImportantNotesILPPrintPdf,
  dataImportantNotesULPPrintPdf,
  dataNotesILPGenPdf,
  dataNotesULPGenPdf,
  defaultQuotationFundAllocation,
  genders,
  needsData,
} from '../../constants/Data'
import { updateNeedsOverall } from '../../slices/needsOverallSlice'
import Fees from './components/Fees'
import imgGenPdfBg from '../../assets/images/gen-pdf-bg.png'
import { getInternalCNADetail } from '../../slices/genPdfSlice'
import ImportantNotes from './components/ImportantNotes'
import { Spacer } from '../../common'
import { ProductType } from '../../constants/Enum'
import SummarySolutionILP from './components/SummarySolutionILP'
import SummarySolutionULP from './components/SummarySolutionULP'
import SummaryBenefitILP from './components/SummaryBenefitILP'
import SummaryBenefitULP from './components/SummaryBenefitULP'
import ReferenceBenefit from './components/ReferenenceBenefit'
import dataProvider from '../../data/dataProvider'
import { HealthCarePlan } from '../../model/HealthCarePlan'

const mapStateToProps = (state: RootState) => ({
  error: state?.genPdf.error,
  loading: state.advisor.loading,
  userInfo: state?.genPdf.userInfo,
  isLoadingChartData: state?.genPdf.loadingChartData,
  currentHandlingCNA: state?.genPdf.currentHandlingCNA,
  needsOverallList: state.needsOverall.needsOverallList,
})

const mapDispatchToProps = {
  updateNeedsOverall,
  getInternalCNADetail,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'Solution'> & PropsFromRedux & {}

function GenPDFScreen({
  route,
  error,
  loading,
  userInfo,
  navigation,
  needsOverallList,
  updateNeedsOverall,
  currentHandlingCNA,
  getInternalCNADetail,
  isLoadingChartData,
}: Props) {
  const [selectedHcPlan, setSelectedHcPlan] = React.useState<HealthCarePlan>()

  useEffect(() => {
    let data: Array<any> = []
    needsData.forEach((e) => data.push(...e.data))
    updateNeedsOverall(data)
    onInitData()
  }, [])

  React.useEffect(() => {
    let hospitalTypeDataList = dataProvider.getHospitalDataBasedOnAgeAndGender(
      currentHandlingCNA?.customerAge,
      currentHandlingCNA?.customerGender
    )
    setSelectedHcPlan(hospitalTypeDataList.find((x) => x.answer === currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer))
  }, [currentHandlingCNA])

  function onInitData() {
    const id = (route.params as any)?.id
    const agentCode = (route.params as any)?.agentCode
    if (id) {
      Promise.all([getInternalCNADetail({ id, agentCode })])
    }
  }

  function renderPageNumber(pageNumber: string) {
    return (
      <View style={desktopStyles.pageNumberContainer}>
        <Text style={desktopStyles.pageNumber}>{pageNumber}</Text>
      </View>
    )
  }

  function getCustomerName() {
    if (currentHandlingCNA?.customerName && currentHandlingCNA?.customerName !== '') {
      return currentHandlingCNA?.customerName
    }
    return currentHandlingCNA?.reminiscentName
  }

  if (currentHandlingCNA?.product === ProductType.ILP) {
    return (
      <SafeAreaView style={desktopStyles.container} key="ILP-PDF">
        <View style={desktopStyles.a4Page}>
          <Image source={imgGenPdfBg} style={[desktopStyles.headerImage, { width: 1200, height: 232 }]} />
          <View style={desktopStyles.contentContainer}>
            <SummaryInfo
              gender={genders.find((e) => e.code === currentHandlingCNA?.customerGender)?.name}
              age={currentHandlingCNA?.customerAge}
              createdDate={currentHandlingCNA?.createdAt}
              name={getCustomerName()}
            />
            <SummaryNeeds
              userInfo={userInfo}
              needTotal={currentHandlingCNA?.needTotal}
              productType={currentHandlingCNA?.product}
              needsData={needsOverallList}
              style={{ marginTop: 8 }}
              sumAssured={currentHandlingCNA?.sumAssured}
              ape={currentHandlingCNA?.ape}
              periodPlan={currentHandlingCNA?.needTerm}
              hcPlanDetail={selectedHcPlan?.label}
            />
            <SummaryBenefitILP cna={currentHandlingCNA} hospitalData={selectedHcPlan} />
            <ReferenceBenefit />
            <SummarySolutionILP
              customerAge={currentHandlingCNA?.customerAge ?? 0}
              style={{ marginTop: 8 }}
              data={
                currentHandlingCNA?.quotationWithoutTopups
                  ? currentHandlingCNA?.quotationWithoutTopups
                  : currentHandlingCNA?.quotations
              }
              productTypeSolution={ProductType.ILP}
              extraInvest={currentHandlingCNA?.topUp}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
            />
            <Spacer />
            {renderPageNumber('1/3')}
          </View>
        </View>
        <View style={desktopStyles.a4Page}>
          <View style={desktopStyles.contentContainer}>
            <Fees productType={ProductType.ILP} dataImportantNote={dataImportantNotesILPPrintPdf} />
            {renderPageNumber('2/3')}
          </View>
        </View>
        <View style={desktopStyles.a4Page}>
          <View style={desktopStyles.contentContainer}>
            <ImportantNotes dataNote={dataNotesILPGenPdf} />
            {renderPageNumber('3/3')}
          </View>
        </View>
      </SafeAreaView>
    )
  } else {
    return (
      <SafeAreaView style={desktopStyles.container} key="ULP-PDF">
        <View style={desktopStyles.a4Page}>
          <Image source={imgGenPdfBg} style={[desktopStyles.headerImage, { width: 1200, height: 232 }]} />
          <View style={desktopStyles.contentContainer}>
            <SummaryInfo
              gender={genders.find((e) => e.code === currentHandlingCNA?.customerGender)?.name}
              age={currentHandlingCNA?.customerAge}
              createdDate={currentHandlingCNA?.createdAt}
              name={getCustomerName()}
            />
            <SummaryNeeds
              userInfo={userInfo}
              needTotal={currentHandlingCNA?.needTotal}
              productType={currentHandlingCNA?.product}
              needsData={needsOverallList}
              style={{ marginTop: 8 }}
              sumAssured={currentHandlingCNA?.sumAssured}
              ape={currentHandlingCNA?.ape}
              periodPlan={currentHandlingCNA?.needTerm}
              hcPlanDetail={selectedHcPlan?.label}
            />
            <SummaryBenefitULP cna={currentHandlingCNA} hospitalData={selectedHcPlan} />
            <ReferenceBenefit />
            <SummarySolutionULP
              customerAge={currentHandlingCNA?.customerAge ?? 0}
              style={{ marginTop: 8 }}
              data={
                currentHandlingCNA?.quotationWithoutTopups
                  ? currentHandlingCNA?.quotationWithoutTopups
                  : currentHandlingCNA?.quotations
              }
              productTypeSolution={ProductType.ULP}
              extraInvest={currentHandlingCNA?.topUp}
              isLoadingChartData={isLoadingChartData}
              quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
            />
            <Spacer />
            {renderPageNumber('1/3')}
          </View>
        </View>
        <View style={desktopStyles.a4Page}>
          <View style={desktopStyles.contentContainer}>
            <Fees productType={ProductType.ULP} dataImportantNote={dataImportantNotesULPPrintPdf} />
            {renderPageNumber('2/3')}
          </View>
        </View>
        <View style={desktopStyles.a4Page}>
          <View style={desktopStyles.contentContainer}>
            <ImportantNotes dataNote={dataNotesULPGenPdf} />
            {renderPageNumber('3/3')}
          </View>
        </View>
      </SafeAreaView>
    )
  }
}

export default connector(GenPDFScreen)

const desktopStyles = StyleSheet.create({
  a4Page: {
    width: 1200,
    height: 1680,
    backgroundColor: '#F7F8FA',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    backgroundColor: '#F7F8FA',
    maxWidth: 1200,
    maxHeight: 5091,
  },
  contentContainer: {
    paddingLeft: 16,
    paddingRight: 28,
    paddingVertical: 16.58,
    backgroundColor: '#F7F8FA',
    flex: 1,
    flexDirection: 'column',
  },
  headerImage: {
    resizeMode: 'contain',
  },
  pageNumberContainer: {
    marginTop: 8,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pageNumber: {
    color: '#ED1B2E',
    fontSize: 13,
    fontWeight: '600',
  },
})
