import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { CircleProgressIndicator, CustomScrollView, SizedBox } from '../../../common'
import { tableBeneficiary, beneficiaryNotes, beneficiaryNotesOthers, beneficiaryNotesTerm } from '../../../constants/Data'
import Layout from '../../../constants/Layout'
import RenderHtml from 'react-native-render-html'

const isMobileDevice = Layout.isMobileDevice

type IProps = {
  answerType?: any
  answer?: string
}

function FeeHealthCare({ answerType, answer }: IProps) {
  let healcarePlanData = tableBeneficiary.filter((x) => x.type === answerType)

  function renderBenefitTitle(
    item?: {
      groupTitle?: string
      type?: string
      titleBenefit?: string
      helpText?: string
      content?: {
        title?: string
        contentA?: string
        contentB?: string
        contentC?: string
        contentD?: string
      }[]
    },
    answer?: any
  ) {
    if (isMobileDevice) {
      return (
        <View
          style={[
            item?.titleBenefit === 'CÁC GIỚI HẠN PHỤ' ? mobileStyles.heading : mobileStyles.headingTitle,
            { backgroundColor: item?.titleBenefit === 'CÁC GIỚI HẠN PHỤ' ? '#FAFBFF' : switchBackground(answer) },
          ]}
        >
          <Text style={[mobileStyles.headingText, { color: switchColorText(answer) }]}><RenderHtml html={switchAnswerType(item?.titleBenefit)} tagsStyles={{ span: { fontSize:13 }}} /></Text>
          {item?.helpText && (
            <View style={mobileStyles.helpText}>
              <RenderHtml html={item?.helpText} tagsStyles={{ span: { fontSize: 11}}} />
            </View>
          )}
        </View>
      )
    }
    return (
      <View
        style={[
          item?.titleBenefit === 'CÁC GIỚI HẠN PHỤ' ? desktopStyles.heading : desktopStyles.headingTitle,
          { backgroundColor: item?.titleBenefit === 'CÁC GIỚI HẠN PHỤ' ? '#FAFBFF' : switchBackground(answer) },
        ]}
      >
        <Text style={[desktopStyles.headingText, { color: switchColorText(answer) }]}><RenderHtml html={switchAnswerType(item?.titleBenefit)} /> </Text>
        {item?.helpText && (
          <View style={desktopStyles.helpText}>
            <RenderHtml html={item?.helpText} />
          </View>
        )}
      </View>
    )
  }

  function renderBeneficiaryGroup(
    item?: {
      groupTitle?: string
      type?: string
      titleBenefit?: string
      helpText?: string
      content?: {
        title?: string
        contentA?: string
        contentB?: string
        contentC?: string
        contentD?: string
      }[]
    },
    answer?: any,
    showTitle?: boolean
  ) {
    if (isMobileDevice) {
      return (
        <View style={mobileStyles.groupItemContainer}>
          {/* Group title */}
          {showTitle && item?.content?.every((x: any) => x[answer] !== '') && renderBenefitTitle(item, answer)}

          {/* Group content */}
          <View style={[mobileStyles.groupItemContentContainer]}>
            {item?.content &&
              item?.content.map((e: any) => {
                return (
                  <View>
                    {e[answer] && (
                      <>
                        <View style={[mobileStyles.itemContentContainer]}>
                          <View style={mobileStyles.titleContainer}>
                            <View style={mobileStyles.itemRowTitleContainer}>
                              <RenderHtml html={e.title} tagsStyles={{ span: { fontSize: 12 }}} />
                            </View>
                          </View>
                          <View style={mobileStyles.contentContainer}>
                            <View style={mobileStyles.contentRowContainer}>
                              <View style={mobileStyles.contentItemContainer}>
                                <RenderHtml html={'<span>' + e[answer] + '</span>'} tagsStyles={{ span: { fontSize: 12 }}} />
                              </View>
                            </View>
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                )
              })}
          </View>
        </View>
      )
    }

    return (
      <View style={desktopStyles.groupItemContainer}>
        {/* Group title */}
        {showTitle && item?.content?.every((x: any) => x[answer] !== '') && renderBenefitTitle(item, answer)}

        {/* Group content */}
        <View style={[desktopStyles.groupItemContentContainer]}>
          {item?.content &&
            item?.content.map((e: any) => {
              return (
                <View>
                  {e[answer] && (
                    <>
                      <View style={[desktopStyles.itemContentContainer]}>
                        <View style={desktopStyles.titleContainer}>
                          <View style={desktopStyles.itemRowTitleContainer}>
                            <RenderHtml html={e.title} />
                          </View>
                        </View>
                        <View style={desktopStyles.contentContainer}>
                          <View style={desktopStyles.contentRowContainer}>
                            <View style={desktopStyles.contentItemContainer}>
                              <RenderHtml html={e[answer]} />
                            </View>
                          </View>
                        </View>
                      </View>
                    </>
                  )}
                </View>
              )
            })}
        </View>
      </View>
    )
  }

  function switchAnswer(answer: string) {
    switch (answer) {
      case 'Chương trình chăm sóc cơ bản':
        return 'contentA'
      case 'Chương trình chăm sóc nâng cao':
        return 'contentB'
      case 'Chương trình chăm sóc toàn diện':
        return 'contentC'
      case 'Chương trình chăm sóc hoàn hảo':
        return 'contentD'
      default:
        return 'contentA'
    }
  }

  function switchBackground(answer: string) {
    switch (answer) {
      case 'contentA':
        return '#F5E6FE'
      case 'contentB':
        return '#FFF9DD'
      case 'contentC':
        return '#E3F8FA'
      case 'contentD':
        return '#DFF3E7'
      case 'Chương trình chăm sóc cơ bản':
        return '#F5E6FE'
      case 'Chương trình chăm sóc nâng cao':
        return '#FFF9DD'
      case 'Chương trình chăm sóc toàn diện':
        return '#E3F8FA'
      case 'Chương trình chăm sóc hoàn hảo':
        return '#DFF3E7'
      default:
        return '#F5E6FE'
    }
  }

  function switchColorText(answer: string) {
    switch (answer) {
      case 'contentA':
        return '#BE63F9'
      case 'contentB':
        return '#FF9900'
      case 'contentC':
        return '#26C6DA'
      case 'contentD':
        return '#27AE60'
      case 'Chương trình chăm sóc cơ bản':
        return '#BE63F9'
      case 'Chương trình chăm sóc nâng cao':
        return '#FF9900'
      case 'Chương trình chăm sóc toàn diện':
        return '#26C6DA'
      case 'Chương trình chăm sóc hoàn hảo':
        return '#27AE60'
      default:
        return '#BE63F9'
    }
  }

  if (answerType) {
    return (
      <View style={{ maxHeight: 550 }}>
        <SizedBox height={20} />
        {renderBenefitTitle(healcarePlanData[0], answer)}
        <CustomScrollView
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
          style={{ paddingLeft: 30, paddingRight: 25, paddingTop: 0 }}
        >
          {healcarePlanData.map((item: any, index: number) => {
            return <View key={index}>{renderBeneficiaryGroup(item, answer && switchAnswer(answer), index > 0)}</View>
          })}
          {answerType === 'Nội trú' || answerType === 'Ngoại trú' ? (
            answer && switchAnswer(answer) === 'contentD' && answerType !== 'Ngoại trú' ? (
              <RenderHtml html={beneficiaryNotes} tagsStyles={{ span: { fontSize: isMobileDevice && 12 }}} />
            ) : (
              <RenderHtml html={beneficiaryNotesOthers} tagsStyles={{ span: { fontSize: isMobileDevice && 12 }}} />
            )
          ) : null}
           <RenderHtml html={beneficiaryNotesTerm} tagsStyles={{ span: { fontSize: isMobileDevice && 12 }}}  />
        </CustomScrollView>
        <SizedBox height={10} />
      </View>
    )
  } else {
    return (
      <View>
        <CircleProgressIndicator />
      </View>
    )
  }
}

export default FeeHealthCare

export function switchAnswerType(answerType: string) {
  switch (answerType) {
    case 'QUYỀN LỢI ĐIỀU TRỊ NỘI TRÚ':
      return '<div><span style="font-weight: bold">Công ty sẽ chi trả các Chi phí y tế theo các Quyền lợi bảo hiểm theo các Giới hạn phụ được quy định tại Quy tắc, Điều khoản.</br><span style="font-weight: 700; line-height: 30px">QUYỀN LỢI ĐIỀU TRỊ NỘI TRÚ</span></span></div>'
    case 'QUYỀN LỢI ĐIỀU TRỊ NGOẠI TRÚ':
      return '<div style="display:flex;justify-content:center;width:100%;"><span style="font-weight: bold;">Quyền lợi Bổ sung tùy chọn</span></br><span style="font-weight: 600;">Công ty sẽ chi trả các Chi phí y tế theo các Quyền lợi bảo hiểm theo các Giới hạn phụ được quy định tại Quy tắc, Điều khoản.</br><span style="font-weight: 700;">QUYỀN LỢI ĐIỀU TRỊ NGOẠI TRÚ</span></span></div>'
    case 'QUYỀN LỢI CHĂM SÓC NHA KHOA':
      return '<div style="display:flex;justify-content:center;width:100%;"><span style="font-weight: bold;">Quyền lợi Bổ sung tùy chọn</span></br><span style="font-weight: 600;">Công ty sẽ chi trả các Chi phí y tế theo các Quyền lợi bảo hiểm theo các Giới hạn phụ được quy định tại Quy tắc, Điều khoản.</br><span style="font-weight: 700;">QUYỀN LỢI CHĂM SÓC NHA KHOA</span></span></div>'
    case 'QUYỀN LỢI CHĂM SÓC THAI SẢN':
      return '<div style="display:flex;justify-content:center;width:100%;"><span style="font-weight: bold;">Quyền lợi Bổ sung tùy chọn</span></br><span style="font-weight: 600;">Công ty sẽ chi trả các Chi phí y tế theo các Quyền lợi bảo hiểm theo các Giới hạn phụ được quy định tại Quy tắc, Điều khoản.</br><span style="font-weight: 700;">QUYỀN LỢI CHĂM SÓC THAI SẢN</span></span></div>'
    default:
      return answerType
  }
}

const desktopStyles = StyleSheet.create({
  container: {
    maxWidth: 784,
    maxHeight: 600,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    // marginHorizontal: 30,
    marginBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingTitle: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 30,
    marginHorizontal: 30,
    marginBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  subHeading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
    width: '100%'
  },
  headingText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FC573B',
    textAlign: 'center',
    maxWidth: '80%',
    margin: 'auto'
  },

  listContent: {
    marginVertical: 20,
    width: '100%',
  },
  listPathoLogicalGroupSmall: {
    fontSize: 14,
    textAlign: 'center',
    color: '#2B2D42',
  },
  helpText: {
    marginTop: 10,
    maxWidth: '90%',
    margin: 'auto',
  },
  contentHelpText: {
    fontSize: 14,
    color: '#2B2D42',
  },
  listPathoLogicalItem: {
    padding: 15,
    flexDirection: 'row',

    justifyContent: 'space-between',
  },
  listPathoLogicalGroup: {
    fontWeight: '700',
    fontSize: 16,
    color: '#373737',
  },
  listPathoLogicalText: {
    fontWeight: '700',
    fontSize: 16,
    paddingVertical: 13,
    color: '#373737',
  },
  groupItemContainer: {},
  groupItemTitleContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  titleContainer: {
    flex: 50,
  },
  itemRowTitleContainer: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    height: '100%',
  },
  contentContainer: {
    flex: 50,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  contentItemContainer: {
    flex: 1,
    textAlign: 'right',
    color: '#373737',
    fontFamily: 'Noto Sans',
    paddingHorizontal: 16,
  },
  groupTitleContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  groupItemContentContainer: {},
  contentRowContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingVertical: 8,
  },
  itemContentContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    // textAlignVertical: 'justify', // This will not work on mobile
    borderBottomWidth: 1,
    borderColor: '#E7E7E7',
    display: 'flex',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    maxWidth: 784,
    maxHeight: 600,
    overflow: 'hidden',
    padding: 20,
  },
  heading: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 10,
    marginVertical: 15,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingTitle: {
    backgroundColor: '#FFE6E2',
    paddingVertical: 10,
    marginHorizontal: 30,
    marginVertical: 15,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  headingText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#FC573B',
    maxWidth: '80%',
    margin: 'auto',
    textAlign: 'center',
  },

  listContent: {
    marginVertical: 20,
    width: '100%',
  },
  listPathoLogicalGroupSmall: {
    fontSize: 12,
    textAlign: 'center',
    color: '#2B2D42',
  },
  helpText: {
    marginTop: 10,
    maxWidth: '90%',
    lineHeight: 30,
    fontSize: 13,
    margin: 'auto',
  },
  contentHelpText: {
    fontSize: 12,
    color: '#2B2D42',
  },
  listPathoLogicalItem: {
    padding: 15,
    flexDirection: 'row',

    justifyContent: 'space-between',
  },
  listPathoLogicalGroup: {
    fontWeight: '700',
    fontSize: 13,
    color: '#373737',
  },
  listPathoLogicalText: {
    fontWeight: '700',
    fontSize: 13,
    paddingVertical: 13,
    color: '#373737',
  },
  groupItemContainer: {},
  groupItemTitleContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  titleContainer: {
    flex: 50,
  },
  itemRowTitleContainer: {
    paddingVertical: 6,
    height: '100%',
  },
  contentContainer: {
    flex: 50,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  contentItemContainer: {
    flex: 1,
    textAlign: 'right',
    color: '#373737',
    fontFamily: 'Noto Sans',
    paddingHorizontal: 16,
  },
  groupTitleContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  groupItemContentContainer: {},
  contentRowContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingVertical: 8,
  },
  itemContentContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottomWidth: 1,
    borderColor: '#E7E7E7',
    display: 'flex',
  },
})
