export const largeTabletMediaConfig = {
  maxDeviceWidth: 1300,
  query: '(max-device-width: 1300px)',
}

export const tabletMediaConfig = {
  maxDeviceWidth: 1194,
  query: '(max-device-width: 1194px)',
}

export const tabletSmallMediaConfig = {
  maxDeviceWidth: 992,
  query: '(max-device-width: 992px)',
}

export const mobileMediaConfig = {
  maxDeviceWidth: 768,
  query: '(max-device-width: 768px)',
}
