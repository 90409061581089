import CNADetail from 'model/CNADetail'

class DataService {
  countNeedTotalSub(cna?: CNADetail) {
    if (!cna) {
      return 0
    }
    let total = 0
    // Protect needs
    if (cna.protectIncome?.subTotal) {
        total += cna.protectIncome?.subTotal
    }
    if (cna.protectLife?.subTotal) {
        total += cna.protectLife?.subTotal
    }
    if (cna.protectAsset?.subTotal) {
        total += cna.protectAsset?.subTotal
    }
    if (cna.protectEdu?.subTotal) {
        total += cna.protectEdu?.subTotal
    }

    // Education needs
    if (cna.eduPlan?.subTotal) {
        total += cna.eduPlan?.subTotal
    }

    // Health care needs
    if (cna.hcPlan?.subTotal) {
        total += cna.hcPlan?.subTotal
    }
    if (cna.hcFatalDisease?.subTotal) {
        total += cna.hcFatalDisease?.subTotal
    }

    //  Retirement
    if (cna.retirement?.subTotal) {
        total += cna.retirement?.subTotal
    }

    // Other needs
    if (cna.investment?.subTotal) {
        total += cna.investment?.subTotal
    }
    if (cna.inherit?.subTotal) {
        total += cna.inherit?.subTotal
    }
    if (cna.charity?.subTotal) {
        total += cna.charity?.subTotal
    }
    return total
  }

  countNeedTotal(cna?: CNADetail) {
    if (!cna) {
      return 0
    }
    let total = 0
    // Protect needs
    if (cna.protectIncome?.total) {
        total += cna.protectIncome?.total
    }
    if (cna.protectLife?.total) {
        total += cna.protectLife?.total
    }
    if (cna.protectAsset?.total) {
        total += cna.protectAsset?.total
    }
    if (cna.protectEdu?.total) {
        total += cna.protectEdu?.total
    }

    // Education needs
    if (cna.eduPlan?.total) {
        total += cna.eduPlan?.total
    }

    // Health care needs
    if (cna.hcPlan?.total) {
        total += cna.hcPlan?.total
    }
    if (cna.hcFatalDisease?.total) {
        total += cna.hcFatalDisease?.total
    }

    //  Retirement
    if (cna.retirement?.total) {
        total += cna.retirement?.total
    }

    // Other needs
    if (cna.investment?.total) {
        total += cna.investment?.total
    }
    if (cna.inherit?.total) {
        total += cna.inherit?.total
    }
    if (cna.charity?.total) {
        total += cna.charity?.total
    }
    return total
  }
}

export default new DataService()
