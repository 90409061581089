import { API_PATH } from '../constants/Path'
import Question from '../model/Question'
import { authApiService } from './ApiService'

class SurveyService {
  async getQuestionnaireList(data: any) {
    return await authApiService.get(API_PATH.CNA.QUESTIONS, data)
  }
}

export default new SurveyService()
