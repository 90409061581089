import React from 'react'
import { View, StyleSheet, Text, LogBox, ScrollView } from 'react-native'
import { RootStackScreenProps } from '../../types'
import { styles as globalStyles, SizedBox, FilledButton, mathHelper, Dialog } from '../../common'
import i18n from 'i18n-js'
import { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'
import { dataNotesILP, dataNotesULP, defaultQuotationFundAllocation } from '../../constants/Data'
import { updateNeedsOverall } from '../../slices/needsOverallSlice'
import SummarySolution from './components/SummarySolution'
import SummaryMainBenefit from './components/SummaryMainBenefit'
import SummaryHealthCareProgram from './components/SummayHealthCareProgram'
import SummaryNotes from './components/SummaryNotes'
import { ProductType, ViewMode } from '../../constants/Enum'
import { saveCNA } from '../../slices/cnaSlice'
import { HealthCarePlan } from '../../model/HealthCarePlan'
import FeeHealthCare from './components/FeeHealthCare'
import dataProvider from '../../data/dataProvider'
import ListFatalDisease from './components/ListFatalDisease'
import Layout from '../../constants/Layout'
import ProfitTable from './components/ProfitTable'

const mapStateToProps = (state: RootState) => ({
  loading: state.advisor.loading,
  needsOverallList: state.needsOverall.needsOverallList,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  loadingSaveDraft: state?.cna.loadingSaveDraft,
  profileData: state.needsCart.profileInfo,
})

const mapDispatchToProps = {
  updateNeedsOverall,
  saveCNA,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'Solution'> &
  PropsFromRedux & {
    onFinish: () => void
    isLoadingChartData?: boolean
    chartData: any
    onUpdateQuotation: (data: any) => void
    onOpenExtraInvestModal: () => void
    onShowBeneficiary?: () => void
    onShowPopupBenefit?: (title: string) => void
  }

function MobileSolutionDetailScreen({
  route,
  currentHandlingCNA,
  isLoadingChartData,
  onFinish,
  onUpdateQuotation,
  onOpenExtraInvestModal,
  onShowBeneficiary,
  onShowPopupBenefit,
}: Props) {
  const [selectedHcPlan, setSelectedHcPlan] = React.useState<HealthCarePlan>()
  const [showBeneficiaryModal, setShowBeneficiaryModal] = React.useState(false)
  const [showAnswer, setShowAnswer] = React.useState<string | undefined>('Nội trú')
  const [showHealthCare, setShowHealthCare] = React.useState(false)
  const [showFatalDisease, setShowFatalDisease] = React.useState<string | undefined>('Bệnh hiểm nghèo')
  const [showFatalDiseasePopUp, setShowFatalDiseasePopUp] = React.useState(false)
  const [showProfitModal, setShowProfitModal] = React.useState(false)
  const isMobileDevice = Layout.isMobileDevice

  React.useEffect(() => {
    let hospitalTypeDataList = dataProvider.getHospitalDataBasedOnAgeAndGender(
      currentHandlingCNA?.customerAge,
      currentHandlingCNA?.customerGender
    )
    setSelectedHcPlan(hospitalTypeDataList.find((x) => x.answer === currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer))
  }, [currentHandlingCNA])

  React.useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
    setShowHealthCare(false)
  }, [])

  function renderModels() {
    return (
      <>
        {/* Change product type model  */}

        <Dialog
          type="inform"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          showCloseIcon
          visible={showHealthCare}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ borderRadius: 12, padding: 0, maxHeight: 550 }}
          onClose={() => setShowHealthCare(false)}
        >
          <FeeHealthCare
            answerType={showAnswer}
            answer={currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer}
          />
        </Dialog>
        {/* Fatal disease modal  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showFatalDiseasePopUp}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ borderRadius: 12, padding: 0 }}
          onClose={() => setShowFatalDiseasePopUp(false)}
          title={
            !isMobileDevice ?
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1, globalStyles.fontSize18]}>
                {showFatalDisease}
              </Text>
            </View> : 
             <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
             <Text style={[globalStyles.DialogTitleMobile, globalStyles.Flex1]}>
               {showFatalDisease}
             </Text>
           </View>

          }
        >
          <ListFatalDisease
            productType={currentHandlingCNA?.product as ProductType}
            except={showFatalDisease === 'Bệnh hiểm nghèo' ? true : false}
          />
        </Dialog>
        <Dialog
          type="inform"
          showCloseIcon
          visible={showProfitModal}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ paddingHorizontal: 16, margin: 20, paddingTop: 10 }}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.FullWidth]}>
              <Text style={[globalStyles.DialogTitle, globalStyles.Flex1]}>
              </Text>
            </View>
          }
          onClose={() => setShowProfitModal(false)}
        >
          <ProfitTable />
        </Dialog>
      </>
    )
  }

  function setPopupBenefit(title?: string) {
    setShowHealthCare(true)
    setShowAnswer(title)
  }

  function setPopupFatalDisease(title?: string) {
    setShowFatalDiseasePopUp(true)
    setShowFatalDisease(title)
  }

  if (isMobileDevice) {
    return (
      <View style={[globalStyles.mobileContainerSafeArea, { paddingHorizontal: 0 }]}>
        <View style={globalStyles.mobileContainerSafeArea}>
          <ScrollView contentContainerStyle={mobileStyles.contentContainer}>
            <SummarySolution
              customerAge={currentHandlingCNA?.customerAge ?? 0}
              data={currentHandlingCNA?.quotations}
              productTypeSolution={currentHandlingCNA?.product}
              dataTopUp={currentHandlingCNA?.quotationWithoutTopups}
              extraInvest={currentHandlingCNA?.topUp}
              isLoadingChartData={isLoadingChartData}
              editable={(route.params as any).mode !== ViewMode.READ_ONLY}
              onConfirm={onUpdateQuotation}
              quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
              onOpenExtraInvestModal={onOpenExtraInvestModal}
              onOpenProfitModal={() => setShowProfitModal(true)}
            />
            <SummaryMainBenefit
              data={[
                {
                  title: 'Số tiền bảo hiểm quyền lợi Tử vong / TTTB&VV',
                  value: currentHandlingCNA?.sumAssured,
                  show: true,
                  showPlaceholder: true,
                },
                {
                  title: 'Tử vong do tai nạn, cộng thêm',
                  value: mathHelper.getExtraProfitFatalByAccident(currentHandlingCNA),
                  show: true,
                  showPlaceholder: true,
                },
                {
                  title: 'Bệnh hiểm nghèo',
                  value: currentHandlingCNA?.hcFatalDisease?.isSelect
                    ? mathHelper.getExtraProfitByFatalDisease(currentHandlingCNA)
                    : 0,
                  show: true,
                  showReference: currentHandlingCNA?.hcFatalDisease?.isSelect ? true : false,
                },
                {
                  title: 'Miễn thu phí bệnh hiểm nghèo',
                  value: currentHandlingCNA?.hcFatalDisease?.isSelect ? 0 : 0,
                  show: true,
                  showReference: true,
                },
              ]}
              onShowPopupFatalDisease={setPopupFatalDisease}
              style={{ marginTop: 16 }}
            />
            <SummaryHealthCareProgram
              data={[
                {
                  title: 'Nội trú',
                  value:
                    currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.boarding ? selectedHcPlan?.boarding : 0,
                  answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
                },
                {
                  title: 'Ngoại trú',
                  value:
                    currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.outPatient ? selectedHcPlan?.outPatient : 0,
                  answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
                },
                {
                  title: 'Nha khoa',
                  value:
                    currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.dentistry ? selectedHcPlan?.dentistry : 0,
                  answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
                },
                {
                  title: 'Thai sản',
                  value:
                    currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.maternity ? selectedHcPlan?.maternity : 0,
                  answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
                },
              ]}
              onShowPopupBenefit={onShowPopupBenefit}
              onShowBeneficiary={onShowBeneficiary}
              style={{ marginTop: 16 }}
            />
            <SummaryNotes
              data={currentHandlingCNA?.product === ProductType.ILP ? dataNotesILP : dataNotesULP}
              style={{ marginTop: 16 }}
            />
            <View style={mobileStyles.continueButton}>
              <FilledButton
                title={i18n.t('COMMON.FINISH')}
                buttonStyle={mobileStyles.continueButtonStyle}
                onPress={() => {
                  onFinish()
                }}
              />
            </View>
            <SizedBox height={150} />
          </ScrollView>
          {renderModels()}
        </View>
      </View>
    )
  }

  return (
    <View style={[globalStyles.mobileContainerSafeArea, { paddingHorizontal: 0 }]}>
      <View style={globalStyles.mobileContainerSafeArea}>
        <ScrollView contentContainerStyle={mobileStyles.contentContainer}>
          <SummarySolution
            customerAge={currentHandlingCNA?.customerAge ?? 0}
            data={currentHandlingCNA?.quotations}
            productTypeSolution={currentHandlingCNA?.product}
            dataTopUp={currentHandlingCNA?.quotationWithoutTopups}
            extraInvest={currentHandlingCNA?.topUp}
            isLoadingChartData={isLoadingChartData}
            editable={(route.params as any).mode !== ViewMode.READ_ONLY}
            onConfirm={onUpdateQuotation}
            quotationFundAllocation={currentHandlingCNA?.quotationFundAllocation ?? defaultQuotationFundAllocation}
            onOpenExtraInvestModal={onOpenExtraInvestModal}
            onOpenProfitModal={() => setShowProfitModal(true)}
          />
          <SummaryMainBenefit
            data={[
              {
                title: 'Số tiền bảo hiểm quyền lợi Tử vong / TTTB&VV',
                value: currentHandlingCNA?.sumAssured,
                show: true,
                showPlaceholder: true,
              },
              {
                title: 'Tử vong do tai nạn, cộng thêm',
                value: mathHelper.getExtraProfitFatalByAccident(currentHandlingCNA),
                show: true,
                showPlaceholder: true,
              },
              {
                title: 'Bệnh hiểm nghèo',
                value: currentHandlingCNA?.hcFatalDisease?.isSelect
                  ? mathHelper.getExtraProfitByFatalDisease(currentHandlingCNA)
                  : 0,
                show: true,
                showReference: currentHandlingCNA?.hcFatalDisease?.isSelect ? true : false,
              },
              {
                title: 'Miễn thu phí bệnh hiểm nghèo',
                value: currentHandlingCNA?.hcFatalDisease?.isSelect ? 0 : 0,
                show: true,
                showReference: true,
              },
            ]}
            onShowPopupFatalDisease={setPopupFatalDisease}
            style={{ marginTop: 16 }}
          />
          <SummaryHealthCareProgram
            data={[
              {
                title: 'Nội trú',
                value: currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.boarding ? selectedHcPlan?.boarding : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
              {
                title: 'Ngoại trú',
                value:
                  currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.outPatient ? selectedHcPlan?.outPatient : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
              {
                title: 'Nha khoa',
                value:
                  currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.dentistry ? selectedHcPlan?.dentistry : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
              {
                title: 'Thai sản',
                value:
                  currentHandlingCNA?.hcPlan?.isSelect && selectedHcPlan?.maternity ? selectedHcPlan?.maternity : 0,
                answer: currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer,
              },
            ]}
            style={{ marginTop: 16 }}
            onShowPopupBenefit={onShowPopupBenefit}
            onShowBeneficiary={onShowBeneficiary}
          />
          <SummaryNotes
            data={currentHandlingCNA?.product === ProductType.ILP ? dataNotesILP : dataNotesULP}
            style={{ marginTop: 16 }}
          />
          <View style={mobileStyles.continueButton}>
            <FilledButton
              title={i18n.t('COMMON.FINISH')}
              onPress={() => {
                onFinish()
              }}
            />
          </View>
          <SizedBox height={100} />
        </ScrollView>
      </View>
    </View>
  )
}

export default connector(MobileSolutionDetailScreen)

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F7F8FA',
  },
  contentContainer: {
    paddingHorizontal: 33.17,
    paddingVertical: 16.58,
  },
  fatalDeceaseContainer: {
    flexDirection: 'column',
  },
  fatalDeceaseTooltipsTitle: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    textDecorationColor: '#ED1B2E',
    textDecorationLine: 'underline',
  },
  fatalDeceaseTooltipsContent: {
    color: '#505D6F',
    fontSize: 15,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    padding: 0,
    paddingTop: 56,
    backgroundColor: '#F7F8FA',
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    paddingVertical: 0,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    paddingTop: 20,
    paddingBottom: 0,
    marginBottom: 0,
    overflow: 'scroll',
  },
  stepIndicatorContainer: {
    bottom: 44,
    left: 32,
    right: 32,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  headerContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    color: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
    marginTop: 20,
    flexDirection: 'row',
  },
  headerLabel: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  navigateMobileChart: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    marginTop: 24,
    borderRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  navigateMobileChartLabel: {
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    flex: 1,
    color: '#505D6F',
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  continueButtonStyle: {
    marginTop: 13,
  },
})
