import * as React from 'react'
import TextButton from './TextButton'

export type SaveDraftButtonProps = {
  onPress?: () => void
  buttonStyle?: Record<string, unknown>
  labelStyle?: Record<string, unknown>
  loading?: boolean
}

/**
 * @deprecated
 * Use save draft button from apps/cna-app/components/SaveDraftButton instead
 */
export function SaveDraftButton({ buttonStyle, labelStyle, onPress, loading }: SaveDraftButtonProps) {
  return (
    <TextButton
      title="Lưu nháp"
      onPress={onPress}
      labelStyle={labelStyle}
      buttonStyle={{ paddingHorizontal: 35, ...buttonStyle }}
      loading={loading}
    />
  )
}

export default SaveDraftButton
