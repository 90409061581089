import React from 'react'
import {
  Modal,
  View,
  Text,
  Image,
  TouchableOpacity,
  ImageSourcePropType,
  TouchableWithoutFeedback,
  StyleSheet,
  ScrollView,
  TextInput as ReactTextInput,
} from 'react-native'
import Button from './Buttons'
import { Icon } from 'react-native-elements'
import icDocument from '../../assets/icon/document.png'
import imgClose from '../../assets/images/close-grey.png'
import imgDelete from '../../assets/images/delete-red.png'
import imgSend from '../../assets/images/send-red.png'
import imgSendDisabled from '../../assets/images/send-disabled.png'
import imgEye from '../../assets/images/eye-red.png'
import imgEyeDisabled from '../../assets/images/eye-disabled.png'
import pruquoteBackIcon from '../../assets/images/pruquote-back.png'
import imgEdit from '../../assets/images/edit-white.png'
import { styles as globalStyles } from '../constants/globalStyles'
import Badge from './Badge'
import TextInput from './TextInput'
import { CnaStatus } from '../../constants/Enum'
import i18n from 'i18n-js'
import { CustomScrollView } from './CustomScrollView'
import { FilledButton, OutlinedButton, vnNumberFormatter } from '../../common'
import 'intl'
import 'intl/locale-data/jsonp/vi'
import Layout from '../../constants/Layout'
import Rating from './Rating'
import Colors from '../constants/Colors'

export type DialogItem = {
  message?: string
  animationType?: any
  visible: boolean
  data?: any
  iconHeader?: boolean
  dataDelete?: any
  valueShare?: string
  children?: React.ReactNode
  onTouchOutside?: any
  messageStyle?: any
  contentStyle?: any
  titleStyle?: any
  titleContainerStyle?: Record<string, unknown>
  title?: string | JSX.Element
  type: 'share' | 'details' | 'delete' | 'confirm' | 'inform' | 'default' | string | undefined
  overlayStyle?: any
  footerButton?: any
  dialogStyle?: any
  helpTitle?: string
  dialogBackgroundColor?: string
  dialogBorderRadius?: number
  showCloseIcon?: boolean
  okText?: string
  cancelText?: string
  disablePreview?: boolean
  disableShare?: boolean
  loadingDeleteCna?: boolean
  onRemove?: () => void
  onClose?: () => void
  onOk?: () => void
  onCancel?: () => void
  onRequestClose?: () => void
  onShow?: () => void
  onOrientationChange?: () => void
  onEditCna?: (item: any) => void
  onDeleteCna?: (item: any) => void
  onShareCna?: (item: any) => void
  onReviewCna?: (item: any) => void
  onUpdateAttributes?: (attrs: any) => void
  onSSOPruquote?: (cnaId: string) => Promise<boolean>
}

export function Dialog({
  message,
  title,
  data,
  titleStyle,
  titleContainerStyle,
  valueShare,
  dataDelete,
  visible,
  iconHeader,
  type,
  dialogStyle,
  showCloseIcon,
  okText,
  cancelText,
  helpTitle,
  onRemove,
  onClose,
  onOk,
  onCancel,
  onShow,
  onRequestClose,
  onOrientationChange,
  overlayStyle,
  messageStyle,
  children,
  contentStyle,
  onTouchOutside,
  footerButton,
  animationType,
  disablePreview,
  disableShare,
  loadingDeleteCna,
  onEditCna,
  onDeleteCna,
  onShareCna,
  onReviewCna,
  onUpdateAttributes,
  onSSOPruquote,
}: DialogItem) {
  const isMobileDevice = Layout.isMobileDevice
  const [potentialRating, setPotentialRating] = React.useState<number>()
  const [notes, setNotes] = React.useState<string>('')

  React.useEffect(() => {
    if (data) {
      setNotes(data?.notes)
      setPotentialRating(data?.potentialRating)
    }
  }, [data])

  function onChangedRateValue(ratingValue?: number) {
    setPotentialRating(ratingValue)
  }

  function onUpdateDetailPopUp() {
    let attrsObj = {
      id: data._id,
      potenialRating: potentialRating !== 0 ? potentialRating : null,
      notes: notes || null,
    }
    onUpdateAttributes && onUpdateAttributes(attrsObj)
  }

  const renderTitle = () => {
    return (
      <Text style={[styles.defaultTitle, titleStyle]}>
        {iconHeader ? <Image source={icDocument as ImageSourcePropType} style={styles.icon} /> : null}
        {title} {helpTitle && <View style={styles.helpTextTitle}>{helpTitle}</View>}
      </Text>
    )
  }

  const renderMessage = () => {
    if (message) return <Text style={[styles.defaultMessage, messageStyle]}>{message}</Text>
    return null
  }

  const renderContent = () => {
    switch (type) {
      case 'details':
        return (
          <View
            style={{
              height: '100%',
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <CustomScrollView
                style={{ ...styles.defaultContent, ...contentStyle }}
                containerStyle={{ padding: 0, maxHeight: 490 }}
              >
                <View style={BoxCardStyles.BoxCard}>
                  <View style={BoxCardStyles.Item}>
                    <Text>Tên gợi nhớ</Text>
                    <Text style={[BoxCardStyles.Title, BoxCardStyles.IdHightLight]}>{data.nick_name}</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Họ và tên</Text>
                    <Text style={BoxCardStyles.Title}>{data.name}</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Tuổi</Text>
                    <Text style={BoxCardStyles.Title}>{data.age}</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Số điện thoại</Text>
                    <Text style={BoxCardStyles.Title}>{data.phone}</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Email</Text>
                    <Text style={BoxCardStyles.Title}>{data.email}</Text>
                  </View>
                  <View style={BoxCardStyles.Line}></View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Mức độ tiềm năng</Text>
                    <Rating ratingValue={data.potentialRating} onChanged={onChangedRateValue} />
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Mã kế hoạch</Text>
                    <Text style={[BoxCardStyles.Title, BoxCardStyles.IdHightLight]}>{data.id_plan}</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Ngày tạo</Text>
                    <Text style={BoxCardStyles.Title}>{data.date_create}</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Tổng nhu cầu</Text>
                    <Text style={[BoxCardStyles.Title, BoxCardStyles.Id]}>{data.total} triệu</Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Trạng thái</Text>
                    <Text style={BoxCardStyles.Title}>
                      <Badge
                        value={i18n.t(`CNA_STATUS.${data.status}`)}
                        status={switchStatus(`${data?.status}`)}
                        transparent={true}
                      />
                    </Text>
                  </View>
                  <View style={BoxCardStyles.Line}></View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Thu nhập hàng tháng</Text>
                    <Text style={BoxCardStyles.Title}>
                      {vnNumberFormatter.format(data.monthly_income ? data.monthly_income * 1000000 : 0)} VND
                    </Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Chi phí cố định hàng tháng</Text>
                    <Text style={BoxCardStyles.Title}>
                      {vnNumberFormatter.format(data.fixed_money ? data.fixed_money * 1000000 : 0)} VND
                    </Text>
                  </View>
                  <View style={BoxCardStyles.Item}>
                    <Text>Tình trạng hôn nhân</Text>
                    <Text style={BoxCardStyles.Title}>{data.marital_status}</Text>
                  </View>
                  <View style={{ width: '100%' }}>
                    <View style={[globalStyles.FullWidth]}>
                      <ReactTextInput
                        multiline
                        numberOfLines={5}
                        style={styles.inputComment}
                        value={notes}
                        placeholder="Ghi chú"
                        placeholderTextColor="#E0E0E0"
                        onChangeText={(s: string) => setNotes(s)}
                      />
                    </View>
                  </View>
                </View>
              </CustomScrollView>
            </View>
            <View style={BoxCardStyles.Action}>
              <Button
                button={{
                  title: 'Xóa',
                  containerStyle: {
                    alignItems: 'center',
                  },
                  titleStyle: {
                    color: '#ED1B2E',
                    fontSize: 16,
                    marginLeft: 8,
                    fontWeight: '500',
                  },
                  prefix: <Image source={imgDelete as ImageSourcePropType} style={globalStyles.Icon20} />,
                  buttonStyle: {
                    backgroundColor: 'transparent',
                    shadowColor: 'transparent',
                    // minWidth: '146px',
                    width: 'auto',
                    alignItems: 'center',
                    borderWidth: 1,
                    marginRight: 12,
                    borderColor: '#ED1B2E',
                    borderRadius: 16,
                  },
                }}
                btnClick={() => {
                  onDeleteCna && onDeleteCna(data)
                }}
              />
              <Button
                button={{
                  title: 'Chia sẻ',
                  titleStyle: {
                    color: '#ED1B2E',
                    fontSize: 16,
                    marginLeft: 8,
                    fontWeight: '500',
                  },
                  prefix: (
                    <Image
                      source={(disableShare ? imgSendDisabled : imgSend) as ImageSourcePropType}
                      style={globalStyles.Icon20}
                    />
                  ),
                  containerStyle: {
                    alignItems: 'center',
                  },
                  buttonStyle: {
                    backgroundColor: 'transparent',
                    shadowColor: 'transparent',
                    alignItems: 'center',
                    // minWidth: '146px',
                    width: 'auto',
                    borderRadius: 16,
                    borderWidth: 1,
                    marginRight: 12,
                    borderColor: '#ED1B2E',
                  },
                  disabled: disableShare,
                }}
                btnClick={() => {
                  onShareCna && onShareCna(data)
                }}
              />
              <Button
                button={{
                  title: 'Xem giải pháp',
                  titleStyle: {
                    color: '#ED1B2E',
                    fontSize: 16,
                    marginLeft: 8,
                    fontWeight: '500',
                  },
                  prefix: (
                    <Image
                      source={(disablePreview ? imgEyeDisabled : imgEye) as ImageSourcePropType}
                      style={[globalStyles.Icon20]}
                    />
                  ),
                  containerStyle: {
                    alignItems: 'center',
                  },
                  buttonStyle: {
                    backgroundColor: 'transparent',
                    shadowColor: 'transparent',
                    // minWidth: '146px',
                    width: 'auto',
                    alignItems: 'center',
                    borderWidth: 1,
                    marginRight: 12,
                    borderColor: '#ED1B2E',
                    borderRadius: 16,
                  },
                  disabled: disablePreview,
                }}
                btnClick={() => {
                  onReviewCna && onReviewCna(data)
                }}
              />
              <Button
                button={{
                  title: 'PRUQUOTE',
                  titleStyle: {
                    color: '#ED1B2E',
                    fontSize: 16,
                    fontWeight: '500',
                  },
                  containerStyle: {
                    alignItems: 'center',
                  },
                  prefix: (
                    <Image
                      source={pruquoteBackIcon as ImageSourcePropType}
                      style={[globalStyles.Icon20, { marginLeft: 8 }]}
                    />
                  ),
                  iconRight: true,
                  buttonStyle: {
                    backgroundColor: 'transparent',
                    shadowColor: 'transparent',
                    // minWidth: '146px',
                    width: 'auto',
                    alignItems: 'center',
                    borderWidth: 1,
                    marginRight: 12,
                    borderColor: '#ED1B2E',
                    borderRadius: 16,
                  },
                  disabled: disablePreview,
                }}
                btnClick={() => onSSOPruquote?.(data?._id)}
              />
              <Button
                button={{
                  title: 'Chỉnh sửa',
                  titleStyle: {
                    color: '#fff',
                    marginLeft: 8,
                    fontSize: 16,
                    fontWeight: '500',
                  },
                  prefix: <Image source={imgEdit as ImageSourcePropType} style={globalStyles.Icon20} />,
                  containerStyle: {
                    alignItems: 'center',
                  },
                  buttonStyle: {
                    alignItems: 'center',
                    // minWidth: '146px',
                    width: 'auto',
                    borderRadius: 16,
                    backgroundColor: '#ED1B2E',
                    shadowColor: 'transparent',
                  },
                }}
                btnClick={() => {
                  onEditCna && onEditCna(data)
                }}
              />
            </View>
          </View>
        )
      case 'delete':
        return (
          <View style={[styles.deleteDialogContent, contentStyle]}>
            <View style={BoxCardStyles.BoxCard}>
              <View style={BoxCardStyles.BoxConfirm}>
                <Text style={BoxCardStyles.defaultConfirm}>{dataDelete.title}</Text>
                <Text style={BoxCardStyles.defaultConfirmDelete}>{dataDelete.description}</Text>
              </View>
              <View style={BoxCardStyles.ActionDelete}>
                <OutlinedButton
                  title={okText ?? 'Xóa'}
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    shadowColor: 'transparent',
                    minWidth: isMobileDevice ? 100 : 146,
                    borderRadius: 16,
                    width: 'auto',
                    borderWidth: 1,
                    marginRight: 12,
                    borderColor: '#ED1B2E',
                    marginTop: 0,
                    flex: 1,
                  }}
                  loading={loadingDeleteCna}
                  onPress={() => onOk && onOk()}
                />
                <FilledButton
                  title={cancelText ?? 'Quay lại'}
                  buttonStyle={{
                    marginLeft: 12,
                    borderRadius: 16,
                    minWidth: isMobileDevice ? 100 : 146,
                    width: 'auto',
                    backgroundColor: '#ED1B2E',
                    shadowColor: 'transparent',
                    marginTop: 0,
                    flex: 1,
                  }}
                  onPress={() => onRemove && onRemove()}
                />
              </View>
            </View>
          </View>
        )

      case 'share':
        return (
          <View style={[styles.defaultContent, contentStyle]}>
            <View style={BoxCardStyles.BoxCard}>
              <View style={BoxCardStyles.BoxShare}>
                <Icon
                  reverse
                  name="share-alt"
                  size={20}
                  containerStyle={BoxCardStyles.IconStyle}
                  type="font-awesome"
                  color="#E3F8FA"
                  tvParallaxProperties={undefined}
                />
                <TextInput
                  placeholder="Nhập"
                  placeholderTextColor="#505D6F"
                  value={valueShare}
                  defaultInputContainer={{
                    backgroundColor: '#FAFBFF',
                    borderRadius: 56,
                    paddingLeft: 15,
                    paddingHorizontal: 15,
                  }}
                />
              </View>
              <Text style={BoxCardStyles.defaultConfirmDelete}>
                People in Prudential Corporation Asia with the link can view
              </Text>

              <View style={BoxCardStyles.ActionDelete}>
                <TouchableOpacity>
                  <Button
                    button={{
                      title: 'Sao chép link',
                      titleStyle: {
                        color: '#fff',
                        fontSize: 16,
                        marginLeft: 8,
                        fontWeight: '500',
                      },
                      icon: {
                        iconName: 'copy',
                        iconSize: 18,
                        iconColor: '#fff',
                      },
                      containerStyle: {
                        alignItems: 'center',
                      },
                      buttonStyle: {
                        alignItems: 'center',
                        minWidth: '146px',
                        borderRadius: 16,
                        backgroundColor: '#ED1B2E',
                        shadowColor: 'transparent',
                      },
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )
      case 'confirm':
        return (
          <View style={[styles.defaultContent, contentStyle]}>
            <View style={BoxCardStyles.BoxCard}>
              <View style={BoxCardStyles.BoxConfirm}>
                <View style={mobileStyles.titleContainer}>
                  <View style={[mobileStyles.line, mobileStyles.lineLeft]} />
                  <Text style={[mobileStyles.defaultTitle, titleStyle]}>{dataDelete.title}</Text>
                  <View style={[mobileStyles.line, mobileStyles.lineRight]} />
                </View>
                <Text style={mobileStyles.defaultDescriptionStyle}>{dataDelete.description}</Text>
              </View>
              <View style={BoxCardStyles.ActionDelete}>
                <Button
                  button={{
                    title: okText ?? 'Đồng ý',
                    titleStyle: {
                      color: '#fff',
                      fontSize: 14,
                      fontWeight: '500',
                    },
                    buttonStyle: {
                      marginRight: 12,
                      borderRadius: 12,
                      minWidth: 100,
                      width: 'auto',
                      backgroundColor: '#ED1B2E',
                      shadowColor: 'transparent',
                    },
                  }}
                  btnClick={onOk}
                />
                <Button
                  button={{
                    title: cancelText ?? 'Hủy',
                    titleStyle: {
                      color: '#ED1B2E',
                      fontSize: 14,
                      fontWeight: '500',
                    },
                    buttonStyle: {
                      backgroundColor: 'transparent',
                      shadowColor: 'transparent',
                      minWidth: 100,
                      borderRadius: 12,
                      width: 'auto',
                      borderWidth: 1,
                      marginLeft: 12,
                      borderColor: '#ED1B2E',
                    },
                  }}
                  btnClick={onCancel}
                />
              </View>
            </View>
          </View>
        )
      case 'inform':
        return (
          <View
            style={[
              {
                width: '100%',
                height: '100%',
              },
              contentStyle,
            ]}
          >
            {children}
          </View>
        )
      case 'default':
        return (
          <View
            style={[
              {
                width: '100%',
              },
              contentStyle,
            ]}
          >
            {children}
          </View>
        )
      default:
        return renderMessage()
    }
  }

  const switchStatus = (cnaStatus: string) => {
    switch (cnaStatus) {
      case CnaStatus.DRAFT:
      case CnaStatus.DRAFT_INDEX:
        return 'warning'
      case CnaStatus.COMPLETED:
      case CnaStatus.COMPLETED_INDEX:
        return 'success'
      case CnaStatus.NEED_CONTACT_INFO:
      case CnaStatus.NEED_CONTACT_INFO_INDEX:
        return 'error'
      default:
        return 'primary'
    }
  }

  const _renderOutsideTouchable = (onTouch: () => void) => {
    const view = <View style={styles.defaultClickOutSide} />

    if (!onTouch) return view

    return (
      <TouchableWithoutFeedback onPress={onTouch} style={styles.defaultClickOutSide}>
        {view}
      </TouchableWithoutFeedback>
    )
  }

  const dialogActions = () => {
    if (footerButton)
      return (
        <View
          style={{
            padding: 20,
          }}
        >
          {React.Children.map(footerButton, (child) =>
            React.isValidElement(child) ? React.cloneElement(child) : child
          )}
        </View>
      )
    return null
  }

  switch (type) {
    case 'inform':
      return (
        <Modal
          animationType={animationType ?? 'fade'}
          transparent={true}
          visible={visible}
          onRequestClose={onRequestClose}
          onShow={onShow}
          onOrientationChange={onOrientationChange}
          onDismiss={onRequestClose}
        >
          <View style={styles.defaultContainer}>
            <View style={[styles.defaultBackgroundOverLay, overlayStyle]}>
              {_renderOutsideTouchable(onTouchOutside)}
              <View style={[styles.defaultDialog, dialogStyle]}>
                {showCloseIcon && (
                  <TouchableOpacity
                    onPress={() => {
                      onClose && onClose()
                    }}
                    style={styles.defaultIcon}
                  >
                    <Image source={imgClose as ImageSourcePropType} style={globalStyles.Icon15} />
                  </TouchableOpacity>
                )}
                {title ? (
                  <View style={[styles.defaultTitleContainer, titleContainerStyle]}>
                    {title}
                    {helpTitle && <View style={styles.helpTextTitle}>{helpTitle}</View>}
                  </View>
                ) : (
                  <View>
                    {iconHeader ? <Image source={icDocument as ImageSourcePropType} style={styles.icon} /> : null}
                    {helpTitle && <View style={styles.helpTextTitle}>{helpTitle}</View>}
                  </View>
                )}
                {renderContent()}
                {dialogActions()}
              </View>

              {_renderOutsideTouchable(onTouchOutside)}
            </View>
          </View>
        </Modal>
      )
    default:
      return (
        <Modal
          animationType={animationType ?? 'fade'}
          transparent={true}
          visible={visible}
          onRequestClose={onRequestClose}
          onShow={onShow}
          onOrientationChange={onOrientationChange}
          onDismiss={onRequestClose}
        >
          <ScrollView bounces={false} contentContainerStyle={styles.defaultContainer}>
            <View style={[styles.defaultBackgroundOverLay, overlayStyle]}>
              {_renderOutsideTouchable(onTouchOutside)}
              <View style={[styles.defaultDialog, dialogStyle]}>
                {showCloseIcon && (
                  <TouchableOpacity
                    onPress={() => {
                      onRemove && onRemove()
                      if (type === 'details') {
                        onUpdateDetailPopUp()
                      }
                    }}
                    style={styles.defaultIcon}
                  >
                    <Image source={imgClose as ImageSourcePropType} style={globalStyles.Icon15} />
                  </TouchableOpacity>
                )}
                {title && renderTitle()}
                {renderContent()}
                {dialogActions()}
              </View>

              {_renderOutsideTouchable(onTouchOutside)}
            </View>
          </ScrollView>
        </Modal>
      )
  }
}

export default Dialog

const styles = StyleSheet.create({
  defaultContainer: {
    flex: 1,
  },
  defaultIcon: {
    position: 'absolute',
    right: 16,
    top: 16,
    zIndex: 1000,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  defaultTitleContainer: {
    paddingTop: 14,
  },
  defaultTitle: {
    color: '#505D6F',
    fontSize: 18,
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  helpTextTitle: {
    position: 'absolute',
    right: 25,
  },
  defaultMessage: {
    textAlign: 'center',
    color: '#00000089',
    fontSize: 18,
    marginBottom: 20,
  },
  defaultContent: {
    width: '100%',
    padding: 24,
    paddingTop: 20,
  },
  deleteDialogContent: {
    width: '100%',
    padding: 0,
  },
  defaultClickOutSide: {
    backgroundColor: 'transparent',
  },
  defaultBackgroundOverLay: {
    flex: 1,
    backgroundColor: '#0000001F',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 24,
  },
  defaultDialog: {
    position: 'relative',
    backgroundColor: '#ffffff',
    shadowOpacity: 0.24,
    borderRadius: 12,
    maxWidth: 700,
    paddingVertical: 20,
    paddingHorizontal: 0,
    width: '100%',
  },
  inputComment: {
    backgroundColor: '#FAFBFF',
    borderWidth: 1,
    borderColor: Colors.borderColor,
    borderRadius: 10,
    textAlignVertical: 'top',
    marginBottom: 0,
    padding: 16,
  },
})

const mobileStyles = StyleSheet.create({
  defaultContainer: {
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
  },
  line: {
    flex: 1,
    height: 2,
    width: '100%',
    borderBottomWidth: 2,
    borderBottomColor: '#EDF2F4',
    marginBottom: 4,
  },
  lineLeft: {
    marginHorizontal: 20,
  },
  lineRight: {
    marginHorizontal: 20,
  },
  defaultIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 1000,
  },
  defaultTitle: {
    color: '#505D6F',
    fontSize: 15,
    fontWeight: '700',
    textAlign: 'center',
  },
  defaultMessage: {
    textAlign: 'center',
    color: '#00000089',
    fontSize: 13,
    fontWeight: '600',
    marginBottom: 20,
  },
  defaultContent: {
    width: '100%',
    padding: 24,
    paddingTop: 20,
  },
  defaultClickOutSide: {
    backgroundColor: 'transparent',
  },
  defaultBackgroundOverLay: {
    flex: 1,
    backgroundColor: '#0000001F',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 24,
  },
  defaultDialog: {
    position: 'relative',
    backgroundColor: '#ffffff',
    shadowOpacity: 0.24,
    borderRadius: 12,
    paddingVertical: 20,
    paddingHorizontal: 15,
    // elevation: 4,
    // shadowOffset: {
    //     height: 4,
    //     width: 2
    // }
  },
  defaultDescriptionStyle: {
    textAlign: 'center',
    color: '#505D6F',
    fontSize: 13,
    fontWeight: '600',
    marginTop: 16,
  },
})

const BoxCardStyles = StyleSheet.create({
  BoxCard: {
    margin: 14,
  },
  BoxConfirm: {
    textAlign: 'center',
    marginBottom: 10,
  },
  BoxShare: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Item: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
    fontSize: 15,
  },
  Id: {
    color: '#ED1B2E',
    fontWeight: '600',
  },
  IdHightLight: {
    fontWeight: '600',
  },
  Title: {
    color: '#1F1F20',
  },
  Line: {
    backgroundColor: '#D7DFFF',
    width: '100%',
    height: 2,
    marginBottom: 20,
  },
  Action: {
    justifyContent: 'center',
    flexDirection: 'row',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  ActionDelete: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#FFFFFF',
  },
  IconStyle: {
    margin: 0,
    marginLeft: 10,
    marginBottom: 15,
  },
  buttonDelete: {
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
    minWidth: '146px',
    borderWidth: 1,
    marginRight: 12,
    borderColor: '#ED1B2E',
  },
  defaultConfirm: {
    fontSize: 18,
    color: '#505D6F',
    marginBottom: 10,
    fontWeight: 'bold',
  },
  defaultConfirmDelete: {
    fontSize: 15,
    textAlign: 'center',
    marginTop: 10,
    color: '#505D6F',
  },
})
