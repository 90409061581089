// components/Panel.js
import * as React from 'react'
import { ImageSourcePropType, StyleSheet, Image, View, Text } from 'react-native'
import { Card, ListItem } from 'react-native-elements'
import Layout from '../../constants/Layout'

export type PanelItem = {
  image: ImageSourcePropType
  title: string
  subtitle: string
}

export type PanelProps = {
  panel: PanelItem
}

export const PanelStyle = StyleSheet.create({
  defaultCard: {
    width: '100%',
    padding: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    borderWidth: 0,
    shadowOpacity: 0.05,
    shadowRadius: 20,
    borderRadius: 12,
    margin: 0,
    marginRight: 20,
    backgroundColor: '#FFF',
  },
  defaultListItem: {
    alignItems: 'stretch',
    borderRadius: 12,
  },
  defaultItemContent: {
    justifyContent: 'space-between',
  },
  defaultItemTitle: {
    color: '#2B2D42',
    fontSize: 15,
  },
  defaultSubTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1F1F20',
  },
  defaultImage: {
    width: 70,
    height: 70,
  },
})

export function Panel({ panel: { image, title, subtitle } }: PanelProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (isMobileDevice) {
    return (
      <View style={[PanelStyle.defaultCard, { padding: 16, height: 115 }]}>
        <View style={[PanelStyle.defaultListItem, { flexDirection: 'row', alignItems: 'center', flex: 1 }]}>
          <Image source={image} style={[PanelStyle.defaultImage, { width: 40, height: 40, marginRight: 8 }]} />
          <Text style={PanelStyle.defaultItemContent}>
            <Text style={[PanelStyle.defaultSubTitle, { fontWeight: '600' }]}>{subtitle}</Text>
          </Text>
        </View>
        <Text style={[PanelStyle.defaultItemTitle, { height: 36, marginTop: 8 }]}>{title}</Text>
      </View>
    )
  }

  return (
    <View style={PanelStyle.defaultCard}>
      <ListItem
        containerStyle={PanelStyle.defaultListItem}
        hasTVPreferredFocus={undefined}
        tvParallaxProperties={undefined}
      >
        <Image source={image} style={PanelStyle.defaultImage} />
        <ListItem.Content style={PanelStyle.defaultItemContent}>
          <ListItem.Title style={PanelStyle.defaultItemTitle}>{title}</ListItem.Title>
          <ListItem.Subtitle style={PanelStyle.defaultSubTitle}>{subtitle}</ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>
    </View>
  )
}

export default Panel
