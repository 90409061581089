import React, { useState } from 'react'
import { ImageSourcePropType, StyleSheet, View, Image, TouchableOpacity, Modal } from 'react-native'
import pruVerLogo from '../assets/images/pru-vertical-logo.png'
import pruHozLogo from '../assets/images/pru-horizontal-logo.png'
import icArrowDown from '../assets/images/arrow-down-filled-circle.png'
import icLogout from '../assets/icon/logout.png'
import { AppText, Line } from '../common'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { clearCart } from '../slices/needsCartSlice'
import { clearUserInfo } from '../slices/userSlice'
import { createNewLocalCNA, setCnaReview, setCnaMonthList, setCnaTodayList } from '../slices/cnaSlice'
import Layout from '../constants/Layout'
import i18n from 'i18n-js'
import { clearAgentData, getAgentData } from '../helper/CnaAgentHelper'
import { styles as globalStyles } from '../common/constants/globalStyles'
import Colors from 'common/constants/Colors'

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
})

const mapDispatchToProps = {
  clearCart,
  clearUserInfo,
  createNewLocalCNA,
  setCnaReview,
  setCnaMonthList,
  setCnaTodayList,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type AppHeaderProps = PropsFromRedux & {
  navigation: any
  onClearData?: () => void
  extra?: JSX.Element
  agentCode?: string
}

function AppHeader({
  navigation,
  extra,
  clearCart,
  userInfo,
  clearUserInfo,
  createNewLocalCNA,
  setCnaReview,
  setCnaMonthList,
  setCnaTodayList,
}: AppHeaderProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  const [showMenu, setShowMenu] = useState<boolean>(false)
  function onToggleMenu() {
    setShowMenu(!showMenu)
  }

  function onGoHome() {
    createNewLocalCNA({})
    setCnaReview({})
    setCnaMonthList({})
    setCnaTodayList({})
    if (isMobileDevice) {
      navigation.popToTop()
      navigation.navigate('MobileHome')
    } else {
      navigation.popToTop()
      navigation.navigate('AfterLogIn')
    }
  }

  async function onLogOut() {
    setShowMenu(false)
    clearCart({})
    setCnaReview({}), createNewLocalCNA({})
    setCnaMonthList({}), setCnaTodayList({}), clearUserInfo()
    await clearUserData()
    navigation.replace('LogIn')
  }

  async function clearUserData() {
    try {
      return clearAgentData()
    } catch (e) {
      console.error(e)
    }
  }

  const renderLogo = (agentOfficeCode?: string) => {
    const agData = getAgentData(agentOfficeCode)
    let icons = []
    if (agData.icon) {
      icons.push(agData.icon)
    }
    icons.push(pruVerLogo)
    if (icons.length === 1) {
      return (
        <TouchableOpacity onPress={onGoHome} activeOpacity={1}>
          <Image source={pruHozLogo as ImageSourcePropType} style={mobileStyles.logoHorizontal} />
        </TouchableOpacity>
      )
    }
    return (
      <TouchableOpacity onPress={onGoHome} activeOpacity={1} style={globalStyles.DisplayFlexRow}>
        {icons.map((e, i) => (
          <View key={i} style={globalStyles.DisplayFlexRow}>
            {i % 2 === 1 ? (
              <Line
                lineStyle="solid"
                orientation="vertical"
                size={38}
                lineWidth={1}
                color={Colors.lineColor}
                containerStyle={globalStyles.CommonMarginHorizontal12}
              />
            ) : null}
            <Image source={e as ImageSourcePropType} style={mobileStyles.logoVertical} />
          </View>
        ))}
      </TouchableOpacity>
    )
  }

  if (isMobileDevice) {
    return (
      <>
        <View style={mobileStyles.container}>
          {renderLogo(userInfo?.officeCode)}
          <View style={mobileStyles.navbar}>
            <View style={mobileStyles.userInfo}>
              <AppText style={mobileStyles.userId} title={`ID: ${userInfo?.agentCode ?? ''}`} />
              <TouchableOpacity onPress={onToggleMenu}>
                <Image source={icArrowDown as ImageSourcePropType} style={mobileStyles.icon} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <Modal transparent visible={showMenu} animationType="fade" onRequestClose={() => setShowMenu(false)}>
          <TouchableOpacity activeOpacity={1} onPress={() => setShowMenu(false)} style={mobileStyles.blurBg}>
            <TouchableOpacity style={mobileStyles.menuContainer} onPress={onLogOut}>
              <Image source={icLogout as ImageSourcePropType} style={mobileStyles.icon} />
              <AppText style={mobileStyles.signOutText} title={i18n.t('COMMON.LOG_OUT')} />
            </TouchableOpacity>
          </TouchableOpacity>
        </Modal>
      </>
    )
  }

  if (isTabletDevice) {
    return (
      <>
        <View style={tabletStyles.container}>
          {renderLogo(userInfo?.officeCode)}
          <View style={tabletStyles.extraContainer}>{extra}</View>
          <View style={tabletStyles.navbar}>
            <View style={tabletStyles.userInfo}>
              <AppText style={tabletStyles.userId} title={`ID: ${userInfo?.agentCode ?? ''}`} />
              <TouchableOpacity onPress={onToggleMenu}>
                <Image source={icArrowDown as ImageSourcePropType} style={tabletStyles.icon} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <Modal transparent visible={showMenu} animationType="fade" onRequestClose={() => setShowMenu(false)}>
          <TouchableOpacity activeOpacity={1} onPress={() => setShowMenu(false)} style={tabletStyles.blurBg}>
            <TouchableOpacity style={tabletStyles.menuContainer} onPress={onLogOut}>
              <Image source={icLogout as ImageSourcePropType} style={tabletStyles.icon} />
              <AppText style={tabletStyles.signOutText} title={i18n.t('COMMON.LOG_OUT')} />
            </TouchableOpacity>
          </TouchableOpacity>
        </Modal>
      </>
    )
  }

  return (
    <>
      <View style={desktopStyles.container}>
        {renderLogo(userInfo?.officeCode)}
        <View style={desktopStyles.extraContainer}>{extra}</View>
        <View style={desktopStyles.navbar}>
          <View style={desktopStyles.userInfo}>
            <AppText style={desktopStyles.userId} title={`ID: ${userInfo?.agentCode ?? ''}`} />
            <TouchableOpacity onPress={onToggleMenu}>
              <Image source={icArrowDown as ImageSourcePropType} style={desktopStyles.icon} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Modal transparent visible={showMenu} animationType="fade" onRequestClose={() => setShowMenu(false)}>
        <TouchableOpacity activeOpacity={1} onPress={() => setShowMenu(false)} style={desktopStyles.blurBg}>
          <TouchableOpacity style={desktopStyles.menuContainer} onPress={onLogOut}>
            <Image source={icLogout as ImageSourcePropType} style={desktopStyles.icon} />
            <AppText style={desktopStyles.signOutText} title={i18n.t('COMMON.LOG_OUT')} />
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>
    </>
  )
}

export default connector(AppHeader)

const desktopStyles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 46,
    paddingVertical: 10,
    flexDirection: 'row',
    position: 'absolute',
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  logo: {
    width: 192,
    height: 38,
  },
  extraContainer: {
    flex: 1,
  },
  navbar: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  userInfo: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 8,
    flexDirection: 'row',
    backgroundColor: '#EDF2F4',
    borderRadius: 50,
    alignItems: 'center',
  },
  userId: {
    marginRight: 8,
    fontSize: 15,
    fontWeight: '400',
  },
  icon: {
    width: 25,
    height: 25,
  },
  menuContainer: {
    position: 'absolute',
    flexDirection: 'row',
    backgroundColor: '#fff',
    top: 65,
    right: 46,
    width: 200,
    height: 60,
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
    alignItems: 'center',
    paddingHorizontal: 16,
    zIndex: 1000,
  },
  signOutText: {
    color: '#ED1B2E',
    marginLeft: 8,
    fontSize: 15,
    fontWeight: '700',
  },
  blurBg: {
    backgroundColor: 'transparent',
    flex: 1,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: 'row',
    position: 'absolute',
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  logo: {
    width: 192,
    height: 38,
  },
  extraContainer: {
    flex: 1,
  },
  navbar: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  userInfo: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 8,
    flexDirection: 'row',
    backgroundColor: '#EDF2F4',
    borderRadius: 50,
    alignItems: 'center',
  },
  userId: {
    marginRight: 8,
    fontSize: 15,
    fontWeight: '400',
  },
  icon: {
    width: 25,
    height: 25,
  },
  menuContainer: {
    position: 'absolute',
    flexDirection: 'row',
    backgroundColor: '#fff',
    top: 65,
    right: 46,
    width: 200,
    height: 60,
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
    alignItems: 'center',
    paddingHorizontal: 16,
    zIndex: 1000,
  },
  signOutText: {
    color: '#ED1B2E',
    marginLeft: 8,
    fontSize: 15,
    fontWeight: '700',
  },
  blurBg: {
    backgroundColor: 'transparent',
    flex: 1,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: 'row',
    position: 'relative',
  },
  logoVertical: {
    width: 66,
    height: 31,
    resizeMode: 'contain',
  },
  logoHorizontal: {
    width: 192,
    height: 38,
    resizeMode: 'contain',
  },
  navbar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  userInfo: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 8,
    flexDirection: 'row',
    backgroundColor: '#EDF2F4',
    borderRadius: 50,
    alignItems: 'center',
  },
  userId: {
    marginRight: 8,
    fontSize: 15,
    fontWeight: '400',
  },
  icon: {
    width: 25,
    height: 25,
  },
  menuContainer: {
    position: 'absolute',
    flexDirection: 'row',
    backgroundColor: '#fff',
    top: 65,
    right: 46,
    width: 200,
    height: 60,
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
    alignItems: 'center',
    paddingHorizontal: 16,
    zIndex: 1000,
  },
  signOutText: {
    color: '#ED1B2E',
    marginLeft: 8,
    fontSize: 15,
    fontWeight: '700',
  },
  blurBg: {
    backgroundColor: 'transparent',
    flex: 1,
  },
})
