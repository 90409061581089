import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image } from 'react-native'
import { styles as globalStyles } from '../../../common'
import i18n from 'i18n-js'
import Layout from '../../../constants/Layout'
import Colors from '../../../common/constants/Colors'
import NeedsOverallEditItem from './NeedsOverallEditItem'
import { HealthCarePlan } from '../../../model/HealthCarePlan'
import { i18nAgentWording } from '../../../components/AgentWording'
import UserInfoData from '../../../model/UserInfoData'

type IProps = {
  userInfo?: UserInfoData
  needsOverallList?: any
  needTotal?: number
  needSubTotal?: number
  selectedHcPlanSub?: HealthCarePlan
  selectedHcPlan?: HealthCarePlan
  hcPlanData?: HealthCarePlan[]
  onChangedHcPlan?: (plan: any) => void
  onUpdateTotalValue?: (needsCategoryCode: string, needsKey: string, totalValue: number, isSelected: boolean) => void
  onUpdateSubTotalValue?: (
    needsCategoryCode: string,
    needsKey: string,
    subtotalValue: number,
    isSelected: boolean
  ) => void
  onSelected?: (needsCategoryCode: string, needsKey: string, isSelected: boolean) => void
}

const NeedsInfo = React.memo(
  ({
    userInfo,
    needsOverallList,
    needTotal,
    needSubTotal,
    selectedHcPlanSub,
    selectedHcPlan,
    hcPlanData,
    onChangedHcPlan,
    onUpdateTotalValue,
    onUpdateSubTotalValue,
    onSelected,
  }: IProps) => {
    const isMobileDevice = Layout.isMobileDevice
    const isTabletDevice = Layout.isTabletDevice

    if (isMobileDevice) {
      return (
        <View style={mobileStyles.container}>
          <View style={mobileStyles.needsItemsContainer}>
            <View style={mobileStyles.needsItemsTitleContainer}>
              <Text style={[mobileStyles.needsItemsTitle, { color: Colors.primaryColor }]}>
                {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUB_NEEDS').toUpperCase()}
              </Text>
              <Text style={[mobileStyles.needsItemsTitle, { color: Colors.accentTextColor }]}>
                {i18n.t('NEEDS_OVERALL_EDIT_MODE.ADJUSTED_NEEDS_MOBILE').toUpperCase()}
              </Text>
            </View>
            {needsOverallList?.map((item: any, index: number) => {
              return (
                <View
                  key={item.id}
                  style={{
                    width: '100%',
                  }}
                >
                  <NeedsOverallEditItem
                    item={item}
                    icon={<Image source={item.icon as ImageSourcePropType} style={globalStyles.Icon60} />}
                    subQty={item.subQty}
                    qty={item.qty}
                    isChecked={item.isChecked}
                    sliderValue={item.qty / (needTotal ?? 0)}
                    sliderValueSub={item.subQty / (needSubTotal ?? 0)}
                    selectedHcPlan={selectedHcPlan}
                    selectedHcPlanSub={selectedHcPlanSub}
                    hcPlanData={hcPlanData}
                    onChangedHcPlan={onChangedHcPlan}
                    onChangeTotalValue={onUpdateTotalValue}
                    onChangeSubTotalValue={onUpdateSubTotalValue}
                    onSelected={onSelected}
                    renderItemTite={(key: string) =>
                      i18nAgentWording({
                        agentOfficeCode: userInfo?.officeCode,
                        wordingCode: `NEEDS_ANALYST.LIST.${key}`,
                      })
                    }
                  />
                </View>
              )
            })}
          </View>
        </View>
      )
    }

    if (isTabletDevice) {
      return (
        <View style={tabletStyles.needsItemsContainer}>
          <View style={tabletStyles.needsItemsTitleContainer}>
            <Text style={[tabletStyles.needsItemsTitle, { color: Colors.primaryColor }]}>
              {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUB_NEEDS').toUpperCase()}
            </Text>
            <Text style={[tabletStyles.needsItemsTitle, { color: Colors.accentTextColor }]}>
              {i18n.t('NEEDS_OVERALL_EDIT_MODE.ADJUSTED_NEEDS').toUpperCase()}
            </Text>
          </View>
          {needsOverallList?.map((item: any, index: number) => {
            return (
              <View
                key={item.id}
                style={{
                  width: '100%',
                  zIndex: needsOverallList.length + index,
                  elevation: needsOverallList.length + index,
                  marginBottom: 20,
                }}
              >
                <NeedsOverallEditItem
                  item={item}
                  icon={<Image source={item.icon as ImageSourcePropType} style={globalStyles.Icon60} />}
                  subQty={item.subQty}
                  qty={item.qty}
                  isChecked={item.isChecked}
                  sliderValue={item.qty / (needTotal ?? 0)}
                  sliderValueSub={item.subQty / (needSubTotal ?? 0)}
                  selectedHcPlan={selectedHcPlan}
                  selectedHcPlanSub={selectedHcPlanSub}
                  hcPlanData={hcPlanData}
                  onChangedHcPlan={onChangedHcPlan}
                  onChangeTotalValue={onUpdateTotalValue}
                  onChangeSubTotalValue={onUpdateSubTotalValue}
                  onSelected={onSelected}
                  renderItemTite={(key: string) =>
                    i18nAgentWording({
                      agentOfficeCode: userInfo?.officeCode,
                      wordingCode: `NEEDS_ANALYST.LIST.${key}`,
                    })
                  }
                />
              </View>
            )
          })}
        </View>
      )
    }

    return (
      <View style={desktopStyles.needsItemsContainer}>
        <View style={desktopStyles.needsItemsTitleContainer}>
          <Text style={[desktopStyles.needsItemsTitle, { color: Colors.primaryColor }]}>
            {i18n.t('NEEDS_OVERALL_EDIT_MODE.SUB_NEEDS').toUpperCase()}
          </Text>
          <Text style={[desktopStyles.needsItemsTitle, { color: Colors.accentTextColor }]}>
            {i18n.t('NEEDS_OVERALL_EDIT_MODE.ADJUSTED_NEEDS').toUpperCase()}
          </Text>
        </View>
        {needsOverallList?.map((item: any, index: number) => {
          return (
            <View
              key={item.id}
              style={{
                width: '100%',
                zIndex: needsOverallList.length + index,
                elevation: needsOverallList.length + index,
                marginBottom: 20,
              }}
            >
              <NeedsOverallEditItem
                item={item}
                icon={<Image source={item.icon as ImageSourcePropType} style={globalStyles.Icon60} />}
                subQty={item.subQty}
                qty={item.qty}
                isChecked={item.isChecked}
                sliderValue={item.qty / (needTotal ?? 0)}
                sliderValueSub={item.subQty / (needSubTotal ?? 0)}
                selectedHcPlan={selectedHcPlan}
                selectedHcPlanSub={selectedHcPlanSub}
                hcPlanData={hcPlanData}
                onChangedHcPlan={onChangedHcPlan}
                onChangeTotalValue={onUpdateTotalValue}
                onChangeSubTotalValue={onUpdateSubTotalValue}
                onSelected={onSelected}
                renderItemTite={(key: string) =>
                  i18nAgentWording({
                    agentOfficeCode: userInfo?.officeCode,
                    wordingCode: `NEEDS_ANALYST.LIST.${key}`,
                  })
                }
              />
            </View>
          )
        })}
      </View>
    )
  }
)

export default NeedsInfo

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  contentContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 33.17,
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
    paddingBottom: 31,
  },
  summaryContentTitle: {
    fontSize: 18,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 31,
    marginTop: 31,
  },
  summaryContent: {
    flexDirection: 'column',
    margin: 31,
    marginTop: 8,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingLeft: 35,
    paddingRight: 16,
    paddingVertical: 35,
  },
  feeTitle: {
    flex: 1,
    fontSize: 16,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 16,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 15,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  needTotalTitleLabel: {
    fontSize: 16,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 18,
    fontWeight: '700',
    color: '#FFF',
  },
  needsItemsContainer: {
    flexDirection: 'column',
    paddingHorizontal: 31,
  },
  needsItemsTitleContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 31,
    paddingBottom: 29,
    justifyContent: 'space-between',
  },
  needsItemsTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  contentContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 16,
    marginRight: 0,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingVertical: 16.58,
    paddingHorizontal: 34,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 33.17,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 13,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 13,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
    paddingBottom: 31,
  },
  summaryContentTitle: {
    fontSize: 15,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 31,
    marginTop: 31,
  },
  summaryContent: {
    flexDirection: 'column',
    margin: 31,
    marginTop: 8,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingLeft: 35,
    paddingRight: 16,
    paddingVertical: 35,
  },
  feeTitle: {
    flex: 1,
    fontSize: 13,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 13,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 13,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  needTotalTitleLabel: {
    fontSize: 13,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 13,
    fontWeight: '700',
    color: '#FFF',
  },
  needsItemsContainer: {
    flexDirection: 'column',
    paddingHorizontal: 31,
  },
  needsItemsTitleContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 31,
    paddingBottom: 29,
    justifyContent: 'space-between',
  },
  needsItemsTitle: {
    fontSize: 13,
    fontWeight: 'bold',
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFBFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 9,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 18,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 8,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
  contentContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 13.23,
    flexDirection: 'column',
  },
  content: {
    paddingTop: 0,
    flexDirection: 'column',
    paddingBottom: 31,
  },
  summaryContentTitle: {
    fontSize: 15,
    color: Colors.titleTextColor,
    fontWeight: 'bold',
    marginLeft: 31,
    marginTop: 31,
  },
  summaryContent: {
    flexDirection: 'column',
    marginTop: 8,
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.borderColor,
    backgroundColor: '#FAFBFF',
  },
  needTotalContainer: {
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 16,
  },
  feeTitle: {
    flex: 1,
    fontSize: 13,
    color: Colors.titleTextColor,
    fontWeight: '500',
  },
  feeValue: {
    fontSize: 13,
    color: Colors.accentTextColor,
    fontWeight: '700',
  },
  buttonEditContainer: {
    paddingHorizontal: 45,
    paddingVertical: 8,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonEditLabel: {
    color: Colors.titleTextColor,
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  highlightTitleContainer: {
    paddingHorizontal: 50,
    paddingVertical: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
  },
  dollarIcon: {
    width: 24,
    height: 24,
  },
  highlightTitleLabel: {
    fontSize: 13,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  needTotalTitleLabel: {
    fontSize: 13,
    color: '#FFF',
    fontWeight: '700',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  moneyTotal: {
    fontSize: 13,
    fontWeight: '700',
    color: '#FFF',
  },
  needsItemsContainer: {
    flexDirection: 'column',
    paddingHorizontal: 0,
    width: '100%',
  },
  needsItemsTitleContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 0,
    paddingBottom: 0,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  needsItemsTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
  },
})
