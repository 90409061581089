/* eslint-disable react-native/no-inline-styles */
import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  Animated,
  TouchableOpacity,
  ViewStyle,
  TextStyle,
  Image,
  ImageSourcePropType,
} from 'react-native'
import { useMediaQuery } from 'react-responsive'
import icStep from '../../assets/icon/arrow-step.png'
import icStepDisable from '../../assets/icon/arrow-step-disable.png'
import Line from './Line'
import Layout from '../../constants/Layout'

const STEP_STATUS = {
  CURRENT: 'current',
  FINISHED: 'finished',
  UNFINISHED: 'unfinished',
}

interface DefaultStepIndicatorStyles {
  stepIndicatorSize: number
  currentStepIndicatorSize: number
  separatorStrokeWidth: number
  separatorStrokeUnfinishedWidth: number
  separatorStrokeFinishedWidth: number
  currentStepStrokeWidth: number
  stepStrokeWidth: number
  stepStrokeCurrentColor: string
  stepStrokeFinishedColor: string
  stepStrokeUnFinishedColor: string
  separatorFinishedColor: string
  separatorUnFinishedColor: string
  stepIndicatorFinishedColor: string
  stepIndicatorUnFinishedColor: string
  stepIndicatorCurrentColor: string
  stepIndicatorLabelFontSize: number
  currentStepIndicatorLabelFontSize: number
  stepIndicatorLabelCurrentColor: string
  stepIndicatorLabelFinishedColor: string
  stepIndicatorLabelUnFinishedColor: string
  labelColor: string
  labelSize: number
  labelAlign: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline' | undefined
  currentStepLabelColor: string
  labelFontFamily?: string
}

const defaultStyles: DefaultStepIndicatorStyles = {
  stepIndicatorSize: 30,
  currentStepIndicatorSize: 40,
  separatorStrokeWidth: 2,
  separatorStrokeUnfinishedWidth: 0,
  separatorStrokeFinishedWidth: 0,
  currentStepStrokeWidth: 2,
  stepStrokeWidth: 0,
  stepStrokeCurrentColor: '#ED1B2E',
  stepStrokeFinishedColor: '#ED1B2E',
  stepStrokeUnFinishedColor: '#ED1B2E',
  separatorFinishedColor: '#ED1B2E',
  separatorUnFinishedColor: '#ED1B2E',
  stepIndicatorFinishedColor: 'black',
  stepIndicatorUnFinishedColor: '#EDF2F4',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 12,
  currentStepIndicatorLabelFontSize: 12,
  stepIndicatorLabelCurrentColor: 'red',
  stepIndicatorLabelFinishedColor: '#505D6F',
  stepIndicatorLabelUnFinishedColor: '#505D6F',
  labelColor: '#000000',
  labelSize: 13,
  labelAlign: 'center',
  currentStepLabelColor: '#4aae4f',
}

interface StepIndicatorStyles {
  currentStepIndicatorSize?: number
  separatorStrokeWidth?: number
  separatorStrokeUnfinishedWidth?: number
  separatorStrokeFinishedWidth?: number
  stepStrokeWidth?: number
  currentStepStrokeWidth?: number
  stepStrokeCurrentColor?: string
  stepStrokeFinishedColor?: string
  stepStrokeUnFinishedColor?: string
  separatorFinishedColor?: string
  separatorUnFinishedColor?: string
  stepIndicatorFinishedColor?: string
  stepIndicatorUnFinishedColor?: string
  stepIndicatorCurrentColor?: string
  stepIndicatorLabelFontSize?: number
  currentStepIndicatorLabelFontSize?: number
  stepIndicatorLabelCurrentColor?: string
  stepIndicatorLabelFinishedColor?: string
  stepIndicatorLabelUnFinishedColor?: string
  labelColor?: string
  currentStepLabelColor?: string
  labelSize?: number
  labelAlign?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline' | undefined
  labelFontFamily?: string
}

export interface StepIndicatorProps {
  currentPosition?: number
  stepCount?: number
  direction?: 'horizontal' | 'vertical'
  customStyles?: StepIndicatorStyles
  labels?: string[]
  onPress?: (step: number) => void

  renderStepIndicator?: (args: { position: number; stepStatus: string }) => React.ReactNode

  renderLabel?: (args: {
    position: number
    stepStatus: string
    label: string
    currentPosition: number
  }) => React.ReactNode
}

export const StepIndicator = ({
  currentPosition = 3,
  stepCount = 5,
  direction = 'horizontal',
  customStyles: customStylesFromProps = defaultStyles,
  labels = [],
  onPress,
  renderStepIndicator: renderCustomStepIndicator,
  renderLabel,
}: StepIndicatorProps) => {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const [width, setWidth] = React.useState<number>(0)
  const [height, setHeight] = React.useState<number>(0)
  const [progressBarSize, setProgressBarSize] = React.useState<number>()
  const [customStyles, setCustomStyles] = React.useState<DefaultStepIndicatorStyles>({
    ...defaultStyles,
    ...customStylesFromProps,
  })

  const progressAnim = React.useRef(new Animated.Value(0)).current
  const sizeAnim = React.useRef(new Animated.Value(customStyles.stepIndicatorSize)).current
  const staleSizeAnim = React.useRef(new Animated.Value(customStyles.stepIndicatorSize)).current
  const borderRadiusAnim = React.useRef(new Animated.Value(customStyles.stepIndicatorSize / 2)).current

  const stepPressed = (position: number) => {
    if (onPress) {
      onPress(position)
    }
  }

  const effectCustomStyles = () => {
    setCustomStyles({ ...customStyles, ...customStylesFromProps })
  }
  React.useEffect(effectCustomStyles, [customStylesFromProps])

  const effectCurrentPosition = () => {
    onCurrentPositionChanged(currentPosition)
  }
  React.useEffect(effectCurrentPosition, [currentPosition, progressBarSize])

  const renderProgressBarBackground = () => {
    let progressBarBackgroundStyle: ViewStyle = {
      position: 'absolute',
    };
    if (direction === 'vertical') {
      progressBarBackgroundStyle = {
        ...progressBarBackgroundStyle,
        left: (width - customStyles.separatorStrokeWidth) / 2,
        top: height / (2 * stepCount),
        bottom: height / (2 * stepCount),
        width:
          customStyles.separatorStrokeUnfinishedWidth === 0
            ? customStyles.separatorStrokeWidth
            : customStyles.separatorStrokeUnfinishedWidth,
      };
    } else {
      progressBarBackgroundStyle = {
        ...progressBarBackgroundStyle,
        top: (height - customStyles.separatorStrokeWidth) / 2,
        left: width / (2 * stepCount),
        right: width / (2 * stepCount),
        height:
          customStyles.separatorStrokeUnfinishedWidth === 0
            ? customStyles.separatorStrokeWidth
            : customStyles.separatorStrokeUnfinishedWidth,
      };
    }
    return (
      <View
        onLayout={(event) => {
          if (direction === 'vertical') {
            setProgressBarSize(event.nativeEvent.layout.height);
          } else {
            setProgressBarSize(event.nativeEvent.layout.width);
          }
        }}
        style={progressBarBackgroundStyle}
      >
        <Line size="100%" color={getStepLineStyleColor(getStepStatus(currentPosition + 1))} lineStyle={getStepLineStyle(getStepStatus(currentPosition + 1))} />
      </View>
    );
  };
  const renderProgressBar = (position : any) => {
    if (position === stepCount - 1) {
      return <></>
    }
    let progressBarStyle: any = {
        position: 'absolute',
    };
    if (direction === 'vertical') {
      progressBarStyle = {
        ...progressBarStyle,
        left: (width - customStyles.separatorStrokeWidth) / 2 ,
        top: height / (2 * stepCount),
        bottom: height / (2 * stepCount),
        width:
          customStyles.separatorStrokeFinishedWidth === 0
            ? customStyles.separatorStrokeWidth
            : customStyles.separatorStrokeFinishedWidth,
        height: progressAnim,
        maxWidth: Layout.window.width / stepCount
      };
    } else {
      progressBarStyle = {
        ...progressBarStyle,
        top: 11,
        left: 0,
        right: width / (2 * stepCount),
        height:
          customStyles.separatorStrokeFinishedWidth === 0
            ? customStyles.separatorStrokeWidth
            : customStyles.separatorStrokeFinishedWidth, 
        width: position < 3 ? progressAnim : 0,
        maxWidth: Layout.window.width / stepCount
      };
    }
    return <Animated.View style={progressBarStyle}>
      <Line size="100%" color={getStepLineStyleColor(getStepStatus(position + 1))} lineStyle={getStepLineStyle(getStepStatus(position + 1))} />
    </Animated.View>;
  };

  const renderStepIndicator = () => {
    let steps = []
    for (let position = 0; position < stepCount; position++) {
      steps.push(
        <TouchableOpacity key={position} onPress={() => stepPressed(position)}>
          <View
            style={[
              styles.stepContainer,
              direction === 'vertical' ? { flexDirection: 'column' } : { flexDirection: 'row' },
            ]}
          >
            {renderStep(position)}
          </View>
        </TouchableOpacity>
      )
    }
    return (
      <View
        onLayout={(event) => {
          setWidth(event.nativeEvent.layout.width)
          setHeight(event.nativeEvent.layout.height)
        }}
      >
        <View
          style={[
            styles.stepIndicatorContainer,
            direction === 'vertical'
              ? {
                  flexDirection: 'column',
                  width: customStyles.currentStepIndicatorSize,
                }
              : {
                  flexDirection: 'row',
                  height: customStyles.currentStepIndicatorSize,
                },
          ]}
        >
          {steps}
        </View>
      </View>
    )
  }

  const renderStepLabels = () => {
    if (!labels || labels.length === 0) {
      return
    }
    var labelViews = labels.map((label, index) => {
      const selectedStepLabelStyle =
        index === currentPosition ? { color: customStyles.currentStepLabelColor } : { color: customStyles.labelColor }
      return (
        <TouchableOpacity style={styles.stepLabelItem} key={index} onPress={() => stepPressed(index)}>
          <View style={styles.stepLabelItem}>
            {renderLabel ? (
              renderLabel({
                position: index,
                stepStatus: getStepStatus(index),
                label,
                currentPosition,
              })
            ) : (
              <Text
                style={[
                  styles.stepLabel,
                  selectedStepLabelStyle,
                  {
                    fontSize: customStyles.labelSize,
                    fontFamily: customStyles.labelFontFamily,
                  },
                ]}
              >
                {label}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      )
    })

    return (
      <View
        style={[
          styles.stepLabelsContainer,
          direction === 'vertical'
            ? { flexDirection: 'column', paddingHorizontal: 4 }
            : { flexDirection: 'row', paddingVertical: 4 },
          { alignItems: customStyles.labelAlign },
        ]}
      >
        {labelViews}
      </View>
    )
  }

  const renderStep = (position: number) => {
    let stepStyle = {}
    let indicatorLabelStyle: TextStyle = {}

    if (isMobileDevice) {
      switch (getStepStatus(position)) {
        case STEP_STATUS.CURRENT: {
          stepStyle = {
            backgroundColor: customStyles.stepIndicatorCurrentColor,
            borderWidth: 1,
            borderColor: customStyles.stepStrokeCurrentColor,
            borderRadius: 50,
            width: 24,
            height: 24,
            overflow: 'hidden',
          }
          indicatorLabelStyle = {
            overflow: 'hidden',
            fontSize: customStyles.currentStepIndicatorLabelFontSize,
            color: customStyles.stepIndicatorLabelCurrentColor,
          }

          break
        }
        case STEP_STATUS.FINISHED: {
          stepStyle = {
            backgroundColor: customStyles.stepIndicatorFinishedColor,
            borderWidth: 1,
            borderColor: customStyles.stepStrokeFinishedColor,
            borderRadius: 50,
            width: 24,
            height: 24,
            overflow: 'hidden',
          }
          indicatorLabelStyle = {
            overflow: 'hidden',
            fontSize: customStyles.stepIndicatorLabelFontSize,
            color: customStyles.stepIndicatorLabelFinishedColor,
          }
          break
        }

        case STEP_STATUS.UNFINISHED: {
          stepStyle = {
            backgroundColor: customStyles.stepIndicatorUnFinishedColor,
            borderWidth: customStyles.stepStrokeWidth,
            borderColor: customStyles.stepStrokeUnFinishedColor,
            borderRadius: 50,
            width: 24,
            height: 24,
            overflow: 'hidden',
          }
          indicatorLabelStyle = {
            overflow: 'hidden',
            fontSize: 11,
            fontWeight: '400',
            color: '#BDBDBD',
          }
          break
        }
        default:
      }
      return (
        <View>
          <Animated.View key={'step-indicator'} style={[styles.step, stepStyle]}>
            {renderCustomStepIndicator ? (
              renderCustomStepIndicator({
                position,
                stepStatus: getStepStatus(position),
              })
            ) : (
              <Text style={indicatorLabelStyle}>{`${position + 1}`}</Text>
            )}
          </Animated.View>
          {position !== 0 && getStepStatus(position) === STEP_STATUS.UNFINISHED ? (
            <Image
              style={{
                height: 11,
                position: 'absolute',
                right: 0,
                flex: 1,
                top: 7,
                minWidth: 55,
                resizeMode: 'contain',
              }}
              source={icStepDisable as ImageSourcePropType}
            />
          ) : (
            position !== 0 && (
              <Image
                style={{
                  height: 11,
                  position: 'absolute',
                  right: 0,
                  flex: 1,
                  top: 7,
                  minWidth: 55,
                  resizeMode: 'contain',
                }}
                source={icStep as ImageSourcePropType}
              />
            )
          )}
          {renderProgressBar(position)}
        </View>
      )
    }

    if (isTabletDevice) {
      switch (getStepStatus(position)) {
        case STEP_STATUS.CURRENT: {
          stepStyle = {
            backgroundColor: customStyles.stepIndicatorCurrentColor,
            borderWidth: customStyles.currentStepStrokeWidth,
            borderColor: customStyles.stepStrokeCurrentColor,
            borderRadius: 20,
            paddingHorizontal: 20,
            paddingVertical: 10,
            overflow: 'hidden',
          }
          indicatorLabelStyle = {
            overflow: 'hidden',
            fontSize: 13,
            color: customStyles.stepIndicatorLabelCurrentColor,
          }

          break
        }
        case STEP_STATUS.FINISHED: {
          stepStyle = {
            backgroundColor: '#ED1B2E',
            borderWidth: customStyles.stepStrokeWidth,
            borderColor: customStyles.stepStrokeFinishedColor,
            borderRadius: 20,
            paddingHorizontal: 20,
            paddingVertical: 10,
            overflow: 'hidden',
          }
          indicatorLabelStyle = {
            overflow: 'hidden',
            fontSize: 13,
            color: customStyles.stepIndicatorLabelFinishedColor,
          }
          break
        }

        case STEP_STATUS.UNFINISHED: {
          stepStyle = {
            backgroundColor: customStyles.stepIndicatorUnFinishedColor,
            borderWidth: customStyles.stepStrokeWidth,
            borderColor: customStyles.stepStrokeUnFinishedColor,
            borderRadius: 20,
            paddingHorizontal: 20,
            paddingVertical: 10,
            overflow: 'hidden',
          }
          indicatorLabelStyle = {
            overflow: 'hidden',
            fontSize: 13,
            color: customStyles.stepIndicatorLabelUnFinishedColor,
          }
          break
        }
        default:
      }

      return (
        <Animated.View key={'step-indicator'} style={[styles.step, stepStyle]}>
          {renderCustomStepIndicator ? (
            renderCustomStepIndicator({
              position,
              stepStatus: getStepStatus(position),
            })
          ) : (
            <Text style={[styles.defaultIndicatorLabel, indicatorLabelStyle]}>{`${position + 1}`}</Text>
          )}
        </Animated.View>
      )
    }

    switch (getStepStatus(position)) {
      case STEP_STATUS.CURRENT: {
        stepStyle = {
          backgroundColor: customStyles.stepIndicatorCurrentColor,
          borderWidth: customStyles.currentStepStrokeWidth,
          borderColor: customStyles.stepStrokeCurrentColor,
          borderRadius: 20,
          paddingHorizontal: 20,
          paddingVertical: 10,
          overflow: 'hidden',
        }
        indicatorLabelStyle = {
          overflow: 'hidden',
          fontSize: customStyles.currentStepIndicatorLabelFontSize,
          color: customStyles.stepIndicatorLabelCurrentColor,
        }

        break
      }
      case STEP_STATUS.FINISHED: {
        stepStyle = {
          backgroundColor: '#ED1B2E',
          borderWidth: customStyles.stepStrokeWidth,
          borderColor: customStyles.stepStrokeFinishedColor,
          borderRadius: 20,
          paddingHorizontal: 20,
          paddingVertical: 10,
          overflow: 'hidden',
        }
        indicatorLabelStyle = {
          overflow: 'hidden',
          fontSize: customStyles.stepIndicatorLabelFontSize,
          color: customStyles.stepIndicatorLabelFinishedColor,
        }
        break
      }

      case STEP_STATUS.UNFINISHED: {
        stepStyle = {
          backgroundColor: customStyles.stepIndicatorUnFinishedColor,
          borderWidth: customStyles.stepStrokeWidth,
          borderColor: customStyles.stepStrokeUnFinishedColor,
          borderRadius: 20,
          paddingHorizontal: 20,
          paddingVertical: 10,
          overflow: 'hidden',
        }
        indicatorLabelStyle = {
          overflow: 'hidden',
          fontSize: customStyles.stepIndicatorLabelFontSize,
          color: customStyles.stepIndicatorLabelUnFinishedColor,
        }
        break
      }
      default:
    }

    return (
      <Animated.View key={'step-indicator'} style={[styles.step, stepStyle]}>
        {renderCustomStepIndicator ? (
          renderCustomStepIndicator({
            position,
            stepStatus: getStepStatus(position),
          })
        ) : (
          <Text style={[styles.defaultIndicatorLabel, indicatorLabelStyle]}>{`${position + 1}`}</Text>
        )}
      </Animated.View>
    )
  }

  const getStepStatus = (stepPosition: number) => {
    if (stepPosition === currentPosition) {
      return STEP_STATUS.CURRENT
    } else if (stepPosition < currentPosition) {
      return STEP_STATUS.FINISHED
    } else {
      return STEP_STATUS.UNFINISHED
    }
  }

  const getStepLineStyle = (stepStatus: string) => {
    switch (stepStatus) {
      case STEP_STATUS.CURRENT:
        return 'dashed'
      case STEP_STATUS.FINISHED:
        return 'solid'
      case STEP_STATUS.UNFINISHED:
        return 'dashed'
      default:
        return 'dashed'
    }
  }
  const getStepLineStyleColor = (stepStatus: string) => {
    switch (stepStatus) {
      case STEP_STATUS.CURRENT:
        return 'red'
      case STEP_STATUS.FINISHED:
        return 'red'
      case STEP_STATUS.UNFINISHED:
      default:
        return '#E0E0E0'
    }
  }
  const onCurrentPositionChanged = (position: number) => {
    if (position > stepCount - 1) {
      position = stepCount - 1
    }
    const animateToPosition = (progressBarSize ?? 0) / (stepCount - 1)
    sizeAnim.setValue(customStyles.stepIndicatorSize)
    staleSizeAnim.setValue(customStyles.stepIndicatorSize)
    borderRadiusAnim.setValue(customStyles.stepIndicatorSize / 2)
    Animated.sequence([
      Animated.timing(progressAnim, {
        toValue: isNaN(animateToPosition) ? 0 : animateToPosition,
        duration: 200,
        useNativeDriver: false,
      }),
      Animated.parallel([
        Animated.timing(sizeAnim, {
          toValue: customStyles.currentStepIndicatorSize,
          duration: 100,
          useNativeDriver: false,
        }),
        Animated.timing(borderRadiusAnim, {
          toValue: customStyles.currentStepIndicatorSize / 2,
          duration: 100,
          useNativeDriver: false,
        }),
      ]),
    ]).start()
  }

  return (
    <View
      style={[
        styles.container,
        direction === 'vertical' ? { flexDirection: 'row', flex: 1 } : { flexDirection: 'column' },
      ]}
    >
      {width !== 0 && (
        <React.Fragment>
          {renderProgressBarBackground()}
        </React.Fragment>
      )}
      {renderStepIndicator()}
      {labels && renderStepLabels()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(1,0,0,0)',
    width: '100%',
    maxWidth: 506,
  },
  stepIndicatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(1,0,0,0)',
  },
  stepLabelsContainer: {
    justifyContent: 'space-around',
  },
  step: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  stepContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepLabel: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: '500',
  },
  stepLabelItem: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultIndicatorLabel: {
    color: '#ED1B2E',
  },
})

export default StepIndicator
