import { IObjectPromise, utils } from '../common'
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios, { AxiosInstance } from 'axios'
import { configEnv, EnvironmentSingleton } from '../config'
import { HttpCode, KNOWN_ERROR_CODE } from '../constants/HttpCode'
import { navigationRef } from './navigation/NavService'
import ToastService from './toast/ToastService'
import authenService from './AuthenService'
import moment from 'moment'
import { API_PATH } from '../constants/Path'
import i18n from 'i18n-js'
import { openHomePage, openAuthenAsync } from '../helper/RouteHelper'
import { parseJSON } from '../helper/JSONHelper'
import { clearAgentData } from '../helper/CnaAgentHelper'

const { host, subscriptionKey, apim, ingress } = configEnv(new EnvironmentSingleton().getEnv())

const TIME_OUT = 60000

export const defaultQueryStringsObj = {
  'subscription-key': subscriptionKey,
}

class ApiService {
  private axiosInstance: AxiosInstance
  private interceptors?: IObjectPromise

  constructor(config: { baseUrl: string; timeout: number; interceptors?: IObjectPromise }) {
    this.interceptors = config.interceptors
    this.axiosInstance = axios.create({
      baseURL: config.baseUrl,
      timeout: config.timeout,
      headers: { 'Content-Type': 'application/json' },
    })
  }

  async get<T = any>(endPoint: string, params: any = {}): Promise<T> {
    params = {
      ...params,
      ...defaultQueryStringsObj,
    }
    const headers = await utils.promiseAllObjects(this.interceptors)
    const paramUrls = new URLSearchParams(params).toString()
    let finalUrl = endPoint
    if (paramUrls) {
      finalUrl = finalUrl + '?' + paramUrls
    }
    try {
      return await this.axiosInstance.get(finalUrl, { headers })
    } catch (error: any) {
      return this.handleHttpError(error, { method: 'GET', finalUrl: finalUrl, body: {}, headers: { headers } })
    }
  }

  async post<T = any>(endPoint: string, body: any = {}, params: any = {}): Promise<T | undefined> {
    params = {
      ...params,
      ...defaultQueryStringsObj,
    }
    const headers = await utils.promiseAllObjects(this.interceptors)
    const paramUrls = new URLSearchParams(params).toString()
    let finalUrl = endPoint
    if (paramUrls) {
      finalUrl = finalUrl + '?' + paramUrls
    }
    try {
      return await this.axiosInstance.post(finalUrl, body, { headers })
    } catch (error: any) {
      return this.handleHttpError(error, { method: 'POST', finalUrl: finalUrl, body: body, headers: { headers } })
    }
  }

  async put<T = any>(endPoint: string, body: any = {}, params: any = {}): Promise<T> {
    params = {
      ...params,
      ...defaultQueryStringsObj,
    }
    const headers = await utils.promiseAllObjects(this.interceptors)
    const paramUrls = new URLSearchParams(params).toString()
    let finalUrl = endPoint
    if (paramUrls) {
      finalUrl = finalUrl + '?' + paramUrls
    }
    try {
      return await this.axiosInstance.put(finalUrl, body, { headers })
    } catch (error: any) {
      return this.handleHttpError(error, { method: 'PUT', finalUrl: finalUrl, body: body, headers: { headers } })
    }
  }

  async onReLogin() {
    ToastService.error(i18n.t('LOGIN.AUTHEN_EXPIRED'))
    await clearAgentData()
    setTimeout(() => {
      openAuthenAsync((navigationRef as any).current)
    }, 3000)
  }

  async onUnauthorizedAccess() {
    ToastService.error(i18n.t('MESS.UNAUTHORIZED'))
    setTimeout(() => {
      openHomePage((navigationRef as any).current)
    }, 3000)
  }

  async onUnknowError() {
    ToastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
  }

  async handleHttpError(error: any, recallApiData: any): Promise<any> {
    let errorMessage = parseJSON(error.message ?? '{}')
    let statusCode: number | undefined
    if (errorMessage) {
      statusCode = errorMessage.statusCode
    }
    if (
      (error?.response?.status === HttpCode.UNAUTHORIZED || statusCode === HttpCode.UNAUTHORIZED) &&
      !recallApiData?.finalUrl?.includes(API_PATH.USER.SIGN_IN) &&
      !recallApiData?.finalUrl?.includes(API_PATH.INTERNAL_CNA.HOST)
    ) {
      this.onUnauthorizedAccess()
    } else if (
      (error?.response?.status === HttpCode.FORBIDDEN || statusCode === HttpCode.FORBIDDEN) &&
      !recallApiData?.finalUrl?.includes(API_PATH.USER.SIGN_IN) &&
      !recallApiData?.finalUrl?.includes(API_PATH.INTERNAL_CNA.HOST)
    ) {
      let refreshToken = await AsyncStorage.getItem('refreshToken')
      if (refreshToken) {
        try {
          let newTokenRes = await authenService.refreshToken(refreshToken)
          if (newTokenRes) {
            await AsyncStorage.setItem('accessToken', newTokenRes?.data?.accessToken)
            await AsyncStorage.setItem('refreshToken', newTokenRes?.data?.refreshToken)
            await AsyncStorage.setItem('expiresIn', `${newTokenRes?.data?.expiresIn}`)
            await AsyncStorage.setItem('refreshToken', newTokenRes?.data?.refreshToken)
            await AsyncStorage.setItem('tokenType', newTokenRes?.data?.refreshToken)
            await AsyncStorage.setItem('latestSuccessLogInTime', moment().toISOString())
            let newHeaders = { ...recallApiData.headers, Authorization: `Bearer ${newTokenRes?.data?.accessToken}` }
            switch (recallApiData.method) {
              case 'GET':
                return await this.axiosInstance.get(recallApiData.finalUrl, {
                  headers: newHeaders,
                })
              case 'POST':
                return await this.axiosInstance.post(recallApiData.finalUrl, recallApiData.body, {
                  headers: newHeaders,
                })
              case 'PUT':
                return await this.axiosInstance.put(recallApiData.finalUrl, recallApiData.body, {
                  headers: newHeaders,
                })
            }
          } else {
            this.onReLogin()
          }
        } catch (error: any) {
          this.onReLogin()
        }
      } else {
        this.onReLogin()
      }
    } else {
      if (!KNOWN_ERROR_CODE.includes(error?.response?.status)) {
        this.onUnknowError()
      } else {
        throw error
      }
    }
  }
}

export const nonAuthApiService = new ApiService({
  baseUrl: host + apim,
  timeout: TIME_OUT,
  interceptors: {},
})

export const authApiService = new ApiService({
  baseUrl: host + apim,
  timeout: TIME_OUT,
  interceptors: {
    Authorization: async () => {
      let token = await AsyncStorage.getItem('accessToken')
      return `Bearer ${token}`
    },
  },
})

export const internalApiService = new ApiService({
  baseUrl: ingress,
  timeout: TIME_OUT,
  interceptors: {},
})
