import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class childrenDetail {
    childAge?: number
    isSchool?: boolean
    schoolFees?: number
    schoolYears?: number
    isUniversity?: boolean
    universityFees?: number
    universityYears?: number
    isAfterUni?: boolean
    afterUniFees?: number
    afterUniYears?: number
  }


export class eduPlan {
    isTemporaryCheck?: boolean 
    isSelect?: boolean
    subTotal?: number
    children?: number
    childrenDetails?: Array<childrenDetail>
}
  

export interface eduPlanState {
  loading?: boolean
  eduPlan?: eduPlan
}

const initialState: eduPlanState = {
  loading: false,
  eduPlan: {
    isTemporaryCheck: true,
    isSelect: undefined,
    children: 1,
    subTotal: 0,
    childrenDetails: [
        {
            isSchool: false,
            isUniversity: false,
            isAfterUni: false,
            childAge: 0,
            schoolFees: 0,
            schoolYears: 0,
            universityFees: 0,
            universityYears: 0,
            afterUniFees: 0,
            afterUniYears: 0
        }
    ]
  },
}

export const updateEduPlanSlice = createAsyncThunk('users/updateEduPlanSlice', async (data) => {
  return data
});



export const eduPlanSlice = createSlice({
  name: 'eduPlan',
  initialState,
  reducers: {
    loadEduPlanState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.eduPlan = action.payload
    },
    updateEduPlanState: (state, action: PayloadAction<eduPlan>) => {
      state.eduPlan = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateEduPlanSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadEduPlanState, updateEduPlanState } = eduPlanSlice.actions

const { reducer } = eduPlanSlice
export default reducer
