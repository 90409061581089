import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class hcFatalDisease {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
  }
  

export interface hcFatalDiseaseState {
  loading?: boolean
  hcFatalDisease?: hcFatalDisease
}

const initialState: hcFatalDiseaseState = {
  loading: false,
  hcFatalDisease: {
    isTemporaryCheck: true,
    // isSelect: true,
    subTotal: 0
  },
}

export const updateHcFatalDiseaseSlice = createAsyncThunk('users/updateHcFatalDiseaseSlice', async (data) => {
  return data
});



export const hcFatalDiseaseSlice = createSlice({
  name: 'hcFatalDisease',
  initialState,
  reducers: {
    loadHcFatalDiseaseState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.hcFatalDisease = action.payload
    },
    updateHcFatalDiseaseState: (state, action: PayloadAction<hcFatalDisease>) => {
      state.hcFatalDisease = action.payload
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateHcFatalDiseaseSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadHcFatalDiseaseState, updateHcFatalDiseaseState } = hcFatalDiseaseSlice.actions

const { reducer } = hcFatalDiseaseSlice
export default reducer
