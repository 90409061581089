import * as React from 'react'
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native'
import icStar from '../../assets/images/star.png'
import icStarHighlight from '../../assets/images/star-highlight.png'
import { styles as globalStyles } from '../constants/globalStyles'

export type AppTextProps = {
  ratingValue: number
  containerStyle?: Record<string, unknown>
  enable?: boolean
  onChanged?: (ratingValue: number) => void
}

export function Rating({ ratingValue, containerStyle, enable = true, onChanged }: AppTextProps) {
  const [selectedRatingValue, setSelectedRatingValue] = React.useState<number>(ratingValue ?? 0)
  const [hoverRatingValue, setHoverRatingValue] = React.useState<number | null>()

  function getRatingValue() {
    if (hoverRatingValue) {
        return hoverRatingValue
    }
    return selectedRatingValue
  }

  function onRated(value: number) {
    setSelectedRatingValue(value)
    onChanged && onChanged(value)
  }

  return (
    <View style={[styles.container]} pointerEvents={enable ? "auto" : "none"}>
      <View style={{ flexDirection: 'row' }}>
        {[1, 2, 3, 4, 5].map((e, i) => {
          return (
            <TouchableOpacity
              style={{ marginLeft: i > 0 ? 10 : 0 }}
              key={i}
              onMouseEnter={() => {
                setHoverRatingValue(i + 1)
              }}
              onMouseLeave={() => {
                setHoverRatingValue(null)
              }}
              onPress={() => onRated(i + 1)}
              onFocus={() => setSelectedRatingValue(i + 1)}
            >
              <Image source={e <= getRatingValue() ? icStarHighlight : icStar} style={globalStyles.Icon15} />
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
}

export default Rating

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
})
