import * as React from 'react'
import { View } from 'react-native'

export type SizedBoxProps = {
  width?: number;
  height?: number;
}

export function SizedBox({ width, height }: SizedBoxProps) {
  return <View style={{ width: width, height: height }}/>
}

export default SizedBox
