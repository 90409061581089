import { createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'

export interface CommonState {
  showBlurLayer: boolean
  loading: boolean
}

const initialState: CommonState = {
  showBlurLayer: false,
  loading: false,
}

export const showLoadingWithValue = createAsyncThunk('common/show-loading', async (req: any, thunkAPI: any) => {
  const { updateLoadingValue } = commonSlice.actions
  setTimeout(() => {
    thunkAPI.dispatch(updateLoadingValue(req))
  }, 500);
})

export const hideLoadingWithValue = createAsyncThunk('common/show-loading', async (req: any, thunkAPI: any) => {
  const { hideLoading } = commonSlice.actions
  setTimeout(() => {
    thunkAPI.dispatch(hideLoading(req))
  }, 500);
})

export const commonSlice = createSlice({
  name: 'Common',
  initialState,
  reducers: {
    showBlurLayer: (state, action: PayloadAction<any>) => {
      state.showBlurLayer = true
    },
    hideBlurLayer: (state, action: PayloadAction<any>) => {
      state.showBlurLayer = false
    },
    showLoading: (state, action: PayloadAction<any>) => {
      if (!state.loading) {
        state.loading = true
      }
    },
    hideLoading: (state, action: PayloadAction<any>) => {
      state.loading = false
    },
    updateLoadingValue: (state, action: PayloadAction<any>) => {
      if (!state.loading) {
        state.loading = true
      }
    },
  },
  extraReducers: (builder) => {},
})

export const { showBlurLayer, hideBlurLayer, showLoading, hideLoading } = commonSlice.actions

const { reducer } = commonSlice
export default reducer
