import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { CircleProgressIndicator, CustomScrollView, SizedBox, TableWithTitle, vnNumberFormatter } from '../../../common'
import { ProductType } from '../../../constants/Enum'
import {
  tableFeeColumns,
  tableFeeDataSourceILP,
  tableFundManageFeeColumns,
  tableFundManageFeeDataSource,
  contractManageFee,
  tableFeeDataSourceULP,
  tableInterestRateColumns,
  tableInterestRateDataSource,
} from '../../../constants/Data'
import Layout from '../../../constants/Layout'

type IProps = {
  productType?: ProductType
}

function FeeDialog({ productType }: IProps) {
  const isMobileDevice = Layout.isMobileDevice

  if (productType === ProductType.ILP) {
    return (
      <View style={{ maxHeight: 550 }}>
        <SizedBox height={20} />
        <CustomScrollView
          style={{ paddingLeft: isMobileDevice ? 20 : 30, paddingRight: isMobileDevice ? 10 : 25, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
          <View>
            <Text style={desktopStyles.title}>1. Phí bảo hiểm rủi ro</Text>
            <Text style={desktopStyles.content}>
              Được khấu trừ hàng tháng và được xác định dựa trên Số tiền bảo hiểm, độ tuổi, giới tính, nghề nghiệp và
              tình trạng sức khỏe của Người được bảo hiểm.
            </Text>
            <SizedBox height={30} />
            <Text style={desktopStyles.title}>2. Phí ban đầu</Text>
            <Text style={desktopStyles.content}>
              Khấu trừ từ phí bảo hiểm cơ bản và phí bảo hiểm đóng thêm trước khi được phân bổ vào Giá trị tài khoản cơ
              bản và Giá trị tài khoản đầu tư thêm tương ứng.
            </Text>
            <View style={desktopStyles.tableContainer}>
              <TableWithTitle
                columns={tableFeeColumns as any}
                dataSource={tableFeeDataSourceILP}
                tableHead={['Năm hợp đồng', 'Năm 1', 'Năm 2', 'Năm 3', 'Năm 4', 'Năm 5+']}
                tableTitle={[
                  'Phí bảo hiểm cơ bản đóng cho năm hợp đồng',
                  'Phí sản phẩm bổ trợ nhóm 1',
                  'Phí bảo hiểm đóng thêm',
                ]}
                tableData={[
                  [85, 75, 10, 5, 0],
                  [85, 75, 10, 5, 0],
                  [85, 75, 10, 5, 0],
                ]}
                containerStyle={{
                  borderRadius: 20,
                  backgroundColor: '#FAFBFF',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#D7DFFF',
                  paddingBottom: 0,
                }}
                headerContainerStyle={{
                  backgroundColor: '#FAFBFF',
                }}
                bodyContainerStyle={{
                  backgroundColor: '#FAFBFF',
                }}
                rowStyle={{
                  borderBottomWidth: 0,
                }}
                headerStyle={{
                  color: '#505D6F',
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: 'normal',
                }}
                isHaveTitle={true}
                titleStyle={{
                  fontWeight: 'normal',
                  fontSize: isMobileDevice ? 10 : 14,
                  color: '#505D6F',
                }}
                bodyStyle={{
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: '600',
                  color: '#505D6F',
                  paddingVertical: 10,
                }}
              />
            </View>
            <Text style={desktopStyles.title}>3. Phí quản lý quỹ</Text>
            <Text style={desktopStyles.content}>
              Được khấu trừ khi tính Giá trị tài sản thuần của mỗi Quỹ liên kết đơn vị.
            </Text>
            <View style={desktopStyles.tableContainer}>
              <TableWithTitle
                columns={tableFundManageFeeColumns as any}
                dataSource={tableFundManageFeeDataSource}
                tableHead={[]}
                tableTitle={[]}
                tableData={[]}
                containerStyle={{
                  borderRadius: 20,
                  backgroundColor: '#FAFBFF',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#D7DFFF',
                  paddingBottom: isMobileDevice ? 0 : 10,
                }}
                headerContainerStyle={{
                  backgroundColor: '#FAFBFF',
                }}
                bodyContainerStyle={{
                  backgroundColor: '#FAFBFF',
                }}
                rowStyle={{
                  borderBottomWidth: 0,
                }}
                headerStyle={{
                  color: '#505D6F',
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: 'normal',
                }}
                isHaveTitle={true}
                titleStyle={{
                  fontWeight: 'normal',
                  fontSize: isMobileDevice ? 10 : 14,
                  color: '#505D6F',
                }}
                bodyStyle={{
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: '600',
                  color: '#505D6F',
                  paddingVertical: 10,
                  borderRadius: 20,
                }}
              />
            </View>
            <Text style={desktopStyles.title}>4. Phí quản lý hợp đồng</Text>
            <Text style={desktopStyles.content}>{`${vnNumberFormatter.format(contractManageFee)} đồng / tháng`}</Text>
          </View>
        </CustomScrollView>
        <SizedBox height={20} />
      </View>
    )
  } else if (productType === ProductType.ULP) {
    return (
      <View style={{ maxHeight: 550 }}>
        <SizedBox height={20} />
        <CustomScrollView
          style={{ paddingLeft: isMobileDevice ? 20 : 30, paddingRight: isMobileDevice ? 10 : 25, paddingTop: 0 }}
          containerStyle={{ padding: 0, height: '100%', flex: 1 }}
        >
          <View>
            <Text style={desktopStyles.title}>1. Phí bảo hiểm rủi ro</Text>
            <Text style={desktopStyles.content}>
              Được khấu trừ hàng tháng và được xác định dựa trên Số tiền bảo hiểm, độ tuổi, giới tính, nghề nghiệp và
              tình trạng sức khỏe của Người được bảo hiểm.
            </Text>
            <SizedBox height={30} />
            <Text style={desktopStyles.title}>2. Phí ban đầu</Text>
            <Text style={desktopStyles.content}>
              Khấu trừ từ phí bảo hiểm cơ bản và phí bảo hiểm đóng thêm trước khi được phân bổ vào Giá trị tài khoản cơ
              bản và Giá trị tài khoản đầu tư thêm tương ứng.
            </Text>
            <View style={desktopStyles.tableContainer}>
              <TableWithTitle
                columns={tableFeeColumns as any}
                dataSource={tableFeeDataSourceULP}
                tableHead={['Năm hợp đồng', 'Năm 1', 'Năm 2', 'Năm 3', 'Năm 4', 'Năm 5+']}
                tableTitle={[
                  'Phí bảo hiểm cơ bản đóng cho năm hợp đồng',
                  'Phí sản phẩm bổ trợ nhóm 1',
                  'Phí bảo hiểm đóng thêm',
                ]}
                tableData={[
                  [85, 75, 10, 5, 0],
                  [85, 75, 10, 5, 0],
                  [85, 75, 10, 5, 0],
                ]}
                containerStyle={{
                  borderRadius: 20,
                  backgroundColor: '#FAFBFF',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#D7DFFF',
                  paddingBottom: 0,
                }}
                headerContainerStyle={{
                  backgroundColor: '#FAFBFF',
                }}
                bodyContainerStyle={{
                  backgroundColor: '#FAFBFF',
                  borderRadius: 20,
                }}
                rowStyle={{
                  borderBottomWidth: 0,
                }}
                headerStyle={{
                  color: '#505D6F',
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: 'normal',
                }}
                isHaveTitle={true}
                titleStyle={{
                  fontWeight: 'normal',
                  fontSize: isMobileDevice ? 10 : 14,
                  color: '#505D6F',
                }}
                bodyStyle={{
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: '600',
                  color: '#505D6F',
                  paddingVertical: 10,
                  borderRadius: 20,
                }}
              />
            </View>
            <Text style={desktopStyles.title}>3. Lãi suất cam kết</Text>
            <Text style={desktopStyles.content}>
              Lãi suất đầu tư sau khi trừ phí quản lý quỹ luôn được đảm bảo ở mức tối thiểu như sau:
            </Text>
            <View style={desktopStyles.tableContainer}>
              <TableWithTitle
                columns={tableInterestRateColumns as any}
                dataSource={tableInterestRateDataSource}
                tableHead={[]}
                tableTitle={[]}
                tableData={[]}
                containerStyle={{
                  borderRadius: 20,
                  backgroundColor: '#FAFBFF',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#D7DFFF',
                  paddingBottom: 0,
                }}
                headerContainerStyle={{
                  backgroundColor: '#FAFBFF',
                }}
                bodyContainerStyle={{
                  backgroundColor: '#FAFBFF',
                  borderRadius: 20,
                }}
                rowStyle={{
                  borderBottomWidth: 0,
                }}
                headerStyle={{
                  color: '#505D6F',
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: 'normal',
                }}
                isHaveTitle={true}
                titleStyle={{
                  fontWeight: 'normal',
                  fontSize: isMobileDevice ? 10 : 14,
                  color: '#505D6F',
                }}
                bodyStyle={{
                  fontSize: isMobileDevice ? 10 : 14,
                  fontWeight: '600',
                  color: '#505D6F',
                  paddingVertical: 10,
                }}
              />
            </View>
            <Text style={desktopStyles.title}>4. Phí quản lý hợp đồng</Text>
            <Text style={desktopStyles.content}>{`${vnNumberFormatter.format(contractManageFee)} đồng / tháng`}</Text>
          </View>
        </CustomScrollView>
        <SizedBox height={20} />
      </View>
    )
  } else {
    return (
      <View>
        <CircleProgressIndicator />
      </View>
    )
  }
}

export default FeeDialog

const desktopStyles = StyleSheet.create({
  container: {
    maxWidth: 784,
    maxHeight: 600,
    overflow: 'hidden',
    padding: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    color: '#000',
    marginBottom: 13,
  },
  content: {
    fontSize: 15,
    fontWeight: 'normal',
    color: '#000',
    textAlign: 'justify',
  },
  tableContainer: {
    marginVertical: 20,
    width: '100%',
  },
})
