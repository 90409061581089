import {  createSlice, PayloadAction ,createAsyncThunk} from '@reduxjs/toolkit'
// import ProtectIncome from '../model/protectIncome'

export class protectLife {
    isTemporaryCheck?: boolean 
    // isSelect?: boolean
    subTotal?: number
    monthlyFixedExpense? : number
    month?: number
    isSubsidy? : boolean
    monthlySubsidy?: number
    subsidyMonth? : number
  }
  

export interface protectLifeState {
  loading?: boolean
  protectLife?: protectLife
}

const initialState: protectLifeState = {
  loading: false,
  protectLife:{
    isTemporaryCheck: true,
    // isSelect: true,
    isSubsidy: undefined,
    subTotal: 0,
  },
}

export const updateProtectLifeSlice = createAsyncThunk('users/updateProtectLifeSlice', async (data) => {
  return data
});


export const protectLifeSlice = createSlice({
  name: 'protectLife',
  initialState,
  reducers: {
    loadProtectLifeState: (state, action: PayloadAction<any>) => {
      state.loading = true
      state.protectLife = action.payload
    },
    updateProtectLifeState: (state, action: PayloadAction<protectLife>) => {
      state.loading = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProtectLifeSlice.fulfilled, (state, action) => {
        state.loading = false
      })
  },
})

export const { loadProtectLifeState, updateProtectLifeState } = protectLifeSlice.actions

const { reducer } = protectLifeSlice
export default reducer
