import {
  styles as globalStyles,
  Block,
  SizedBox,
  StepButton,
  Dialog,
  mathHelper,
  vnNumberFormatter,
  Expandable,
} from '../../common'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { View, StyleSheet, Text, Image, ScrollView } from 'react-native'
import { connect, ConnectedProps } from 'react-redux'
import { defaultQuotationFundAllocation, resetCnaData } from '../../constants/Data'
import { RootState } from '../../store'
import { RootStackScreenProps } from '../../types'
import i18n from 'i18n-js'
import Layout from '../../constants/Layout'
import { updateProfile, clearCart, saveNeedsCart, updateSelectedStatus } from '../../slices/needsCartSlice'
import { updateNeedsOverall, savePlanPeriodTime } from '../../slices/needsOverallSlice'
import { useIsFocused } from '@react-navigation/native'
import { getCachedCNA, saveCNA } from '../../slices/cnaSlice'
import toastService from '../../services/toast/ToastService'
import icWarning from '../../assets/images/warning.png'
import StepAppHeader from '../../components/StepAppHeader'
import { ViewMode, PageNumberMapping, NeedsCategory, ProductType } from '../../constants/Enum'
import { getStatusToSave, CreateCnaPageStep } from '../../helper/CnaStatusWorkFlow'
import MobileStepIndicator from '../../components/MobileStepIndicator'
import CommonAppBar from '../../components/CommonAppBar'
import { SafeAreaView } from 'react-native-safe-area-context'
import UserInfo from './components/UserInfo'
import UserFinanceInfo from './components/UserFinanceInfo'
import NeedsInfo from './components/NeedsInfo'
import ToastService from '../../services/toast/ToastService'
import { getChartData } from '../../slices/solutionSlice'
import solutionService from '../../services/SolutionService'
import { MIN_FATAL_DECEASE_VALUE, MIN_NEED_TERM } from '../../constants/Constants'
import ConfirmSavePopUp from '../../components/ConfirmSavePopUp'
import SummaryAPEInfo from './components/SummaryApeInfo'
import { HealthCarePlan } from '../../model/HealthCarePlan'
import dataProvider from '../../data/dataProvider'
import _, { isNumber } from 'lodash'
import moment from 'moment'
import imgArrowRightCircleRed from '../../assets/images/arrow-right-circle-red.png'
import DelayAppearComponent from '../../common/components/DelayAppearComponent'
import { showLoading, hideLoading } from '../../slices/commonSlice'

const mapStateToProps = (state: RootState) => ({
  profileInfo: state.needsCart.profileInfo,
  needsOverallList: state.needsOverall.needsOverallList,
  needsCartList: state.needsCart.needsCartList,
  userProtect: state.user.userProtect,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  userProtectOriginal: state.user.userProtectOriginal,
  userInfo: state?.user?.userInfo,
  loadingCnaDetail: state?.cna?.loadingCnaDetail,
  loadingSaveDraft: state?.cna.loadingSaveDraft,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
})

const mapDispatchToProps = {
  updateNeedsOverall,
  savePlanPeriodTime,
  updateProfile,
  getCachedCNA,
  clearCart,
  saveNeedsCart,
  updateSelectedStatus,
  saveCNA,
  getChartData,
  showLoading,
  hideLoading,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'NeedsOverall'> & PropsFromRedux & {}

function NeedsOverallEditModeScreen({
  route,
  navigation,
  needsOverallList,
  updateNeedsOverall,
  needsCartList,
  clearCart,
  userProtect,
  userInfo,
  getCachedCNA,
  currentHandlingCNA,
  saveCNA,
  loadingCnaDetail,
  loadingSaveDraft,
  loadingSaveContinue,
  getChartData,
  showLoading,
  hideLoading,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice
  const isFocused = useIsFocused()

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onSubmit', shouldUnregister: false })

  const [showCancelWarningModel, setShowCancelWarningModel] = React.useState(false)
  const [subTotalValue, setSubTotalValue] = React.useState(0)
  const [totalValue, setTotalValue] = React.useState(0)
  const [enableContinue, setEnableContinue] = React.useState(false)
  const [showContentPopUpUpdateLate, setShowContentPopUpUpdateLate] = React.useState(false)
  const [, updateState] = React.useState<any>()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [ape, setApe] = React.useState<number>()
  const [apeSub, setApeSub] = React.useState<number>()
  const [showConfirmLeavingAlert, setShowConfirmLeavingAlert] = React.useState(false)
  const [selectedHcPlan, setSelectedHcPlan] = React.useState<HealthCarePlan>()
  const [hcPlanData, setHcPlanData] = React.useState<HealthCarePlan[]>()
  const [loadingSubApe, setLoadingSubApe] = React.useState<boolean>()
  const [loadingApe, setLoadingApe] = React.useState<boolean>()

  useEffect(() => {
    setShowCancelWarningModel(false)
    const id = (route.params as any)?.id
    if (id && isFocused) {
      getCachedCNA({ id })
      initApeData()
    } else {
      clearCart({})
    }
  }, [isFocused])

  useEffect(() => {
    let hospitalTypeDataList = dataProvider.getHospitalDataBasedOnAgeAndGender(
      currentHandlingCNA?.customerAge,
      currentHandlingCNA?.customerGender
    )
    setHcPlanData(hospitalTypeDataList)
    setSelectedHcPlan(hospitalTypeDataList.find((x) => x.answer === currentHandlingCNA?.hcPlan?.hcPlanDetail?.answer))
    setValue('needTerm', currentHandlingCNA?.needTerm ? currentHandlingCNA?.needTerm : 0)
    setValue('product', currentHandlingCNA?.product ? currentHandlingCNA?.product : undefined)
    setValue(
      'monthlyIncome',
      currentHandlingCNA?.protectIncome?.monthlyIncome ? currentHandlingCNA?.protectIncome?.monthlyIncome : undefined
    )
    setValue(
      'monthlyExpense',
      currentHandlingCNA?.protectLife?.monthlyFixedExpense
        ? currentHandlingCNA?.protectLife?.monthlyFixedExpense
        : undefined
    )
    setEnableContinue(currentHandlingCNA?.needTerm ? true : false)
    forceUpdate()
  }, [currentHandlingCNA])

  useEffect(() => {
    let data: Array<any> = []
    needsCartList.forEach((e) => {
      if (e.data) {
        e.data.forEach((e) => {
          if (e.isTemporaryCheck) {
            data.push({
              ...e,
              subQty: userProtect && userProtect[e.key] ? userProtect[e.key].subTotal : 0,
              qty: userProtect && userProtect[e.key] ? userProtect[e.key].total : 0,
            })
          }
        })
      }
    })
    updateNeedsOverall([...data])
    setTotalValue(countTotalValue(data))
    setSubTotalValue(countSubTotalValue(data))
  }, [needsCartList, userProtect])

  useEffect(() => {
    let data: Array<any> = []
    needsCartList.forEach((e) => {
      if (e.data) {
        e.data.forEach((e) => {
          if (e.isTemporaryCheck) {
            if (e.key === 'hcPlan') {
              data.push({
                ...e,
                subQty: userProtect && userProtect[e.key] ? userProtect[e.key].subTotal : 0,
                qty: dataProvider.countTotalHealthCarePlanData({
                  boarding: selectedHcPlan?.boarding,
                  dentistry: selectedHcPlan?.dentistry,
                  outPatient: selectedHcPlan?.outPatient,
                  maternity: selectedHcPlan?.maternity,
                  age: currentHandlingCNA?.customerAge,
                  gender: currentHandlingCNA?.customerGender,
                }),
              })
            } else {
              let need = needsOverallList.find((k) => e.key === k.key)
              data.push({
                ...need,
                subQty: userProtect && userProtect[e.key] ? userProtect[e.key].subTotal : 0,
                qty: need?.qty,
              })
            }
          }
        })
      }
    })
    updateNeedsOverall([...data])
    setTotalValue(countTotalValue(data))
    setSubTotalValue(countSubTotalValue(data))
    reCountApe([...data])
  }, [selectedHcPlan])

  useEffect(() => {
    setTotalValue(countTotalValue(needsOverallList))
    setSubTotalValue(countSubTotalValue(needsOverallList))
    handleShowPopUpUpdateLate()
    updateStateContinueButton()
  }, [needsOverallList])

  function initApeData() {
    const id = (route.params as any)?.id
    if (id) {
      setLoadingApe(true)
      setLoadingSubApe(true)
      Promise.all([
        getChartData({
          id: (route.params as any)?.id,
          withoutTopup: true,
          isSub: true,
        }),
        getChartData({
          id: (route.params as any)?.id,
          withoutTopup: true,
          isSub: false,
        }),
      ])
        .then((data: any) => {
          let quotationDataSub = data[0].payload
          let quotationData = data[1].payload
          let apeSub =
            quotationDataSub.result && quotationDataSub.result?.length > 0 ? quotationDataSub?.result[0]?.ape : 0
          let ape = quotationData.result && quotationData.result?.length > 0 ? quotationData?.result[0].ape : 0
          setApeSub(apeSub)
          setApe(ape)
          setLoadingApe(false)
          setLoadingSubApe(false)
        })
        .catch((err) => toastService.error(i18n.t('MESS.UNKNOWN_ERROR')))
    }
  }

  function recountAllApeData() {
    async function reCountApe(needsOverallList: any[]) {
      const body = getBodyCountApe(needsOverallList)
      return solutionService.getTempApeData(body)
    }
    async function reCountApeSub(needsOverallList: any[]) {
      const body = getBodyCountApeSub(needsOverallList)
      return solutionService.getTempApeData(body)
    }

    setLoadingApe(true)
    setLoadingSubApe(true)
    Promise.all([reCountApeSub(needsOverallList), reCountApe(needsOverallList)])
      .then((data: any) => {
        let quotationDataSub = data[0].data
        let quotationData = data[1].data
        let apeSub =
          quotationDataSub.result && quotationDataSub.result?.length > 0 ? quotationDataSub?.result[0]?.ape : 0
        let ape = quotationData.result && quotationData.result?.length > 0 ? quotationData?.result[0].ape : 0
        setApeSub(apeSub)
        setApe(ape)
        setLoadingApe(false)
        setLoadingSubApe(false)
      })
      .catch((err) => toastService.error(i18n.t('MESS.UNKNOWN_ERROR')))
  }

  async function reCountApe(needsOverallList: any[]) {
    if (currentHandlingCNA?._id) {
      const body = getBodyCountApe(needsOverallList)
      try {
        setLoadingApe(true)
        showLoading({})
        let quotationData = await solutionService.getTempApeData(body)
        let ape =
          quotationData.data && quotationData.data.result && quotationData.data.result.length > 0
            ? quotationData.data.result[0]?.ape
            : 0
        setApe(ape)
        setLoadingApe(false)
        if (!loadingSubApe) {
          hideLoading({})
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function reCountApeSub(needsOverallList: any[]) {
    if (currentHandlingCNA?._id) {
      const body = getBodyCountApeSub(needsOverallList)
      try {
        showLoading({})
        setLoadingSubApe(true)
        let quotationData = await solutionService.getTempApeData(body)
        let ape =
          quotationData.data && quotationData.data.result && quotationData.data.result.length > 0
            ? quotationData.data.result[0]?.ape
            : 0
        setApeSub(ape)
        setLoadingSubApe(false)
        if (!loadingApe) {
          hideLoading({})
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  function getBodyCountApe(needsOverallList: any[]) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      needTerm: getValues('needTerm'),
      product: getValues('product'),
      needTotal: countTotalValue(needsOverallList),
      hcPlan: {
        ...currentHandlingCNA?.hcPlan,
        hcPlanDetail: selectedHcPlan,
        total: dataProvider.countTotalHealthCarePlanData({
          boarding: selectedHcPlan?.boarding,
          dentistry: selectedHcPlan?.dentistry,
          outPatient: selectedHcPlan?.outPatient,
          maternity: selectedHcPlan?.maternity,
          age: currentHandlingCNA?.customerAge,
          gender: currentHandlingCNA?.customerGender,
        }),
      },
    } as any
    needsCartList.forEach((e) => {
      if (e && e.data && e.data.length) {
        e.data.forEach((f) => {
          if (f) {
            let correspondingNeedsOverall = needsOverallList.find((e) => e.key === f.key)
            let userSelectValue = correspondingNeedsOverall?.isChecked
            let total = correspondingNeedsOverall?.qty
            let obj = body[f.key]
            if (userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: total,
                }
              } else {
                body[f.key] = {
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: total,
                }
              }
            } else if (!userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: total,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: total,
                }
              }
            } else if (!userSelectValue && !f.isTemporaryCheck) {
              let resetObj = (resetCnaData as any)[f.key]
              if (resetObj) {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                  total: total,
                  ...resetObj,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                  total: total,
                }
              }
            }
          }
        })
      }
    })
    return body
  }

  function getBodyCountApeSub(needsOverallList: any[]) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      needTerm: getValues('needTerm'),
      product: getValues('product'),
      needSubTotal: countSubTotalValue(needsOverallList),
      needTotal: countTotalValue(needsOverallList),
      hcPlan: {
        ...currentHandlingCNA?.hcPlan,
        hcPlanDetail: selectedHcPlan,
        total: dataProvider.countTotalHealthCarePlanData({
          boarding: selectedHcPlan?.boarding,
          dentistry: selectedHcPlan?.dentistry,
          outPatient: selectedHcPlan?.outPatient,
          maternity: selectedHcPlan?.maternity,
          age: currentHandlingCNA?.customerAge,
          gender: currentHandlingCNA?.customerGender,
        }),
      },
    } as any
    needsCartList.forEach((e) => {
      if (e && e.data && e.data.length) {
        e.data.forEach((f) => {
          if (f) {
            let correspondingNeedsOverall = needsOverallList.find((e) => e.key === f.key)
            let userSelectValue = correspondingNeedsOverall?.isChecked
            let subTotal = correspondingNeedsOverall?.subQty
            let obj = body[f.key]
            if (userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: subTotal,
                }
              } else {
                body[f.key] = {
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: subTotal,
                }
              }
            } else if (!userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: subTotal,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: subTotal,
                }
              }
            } else if (!userSelectValue && !f.isTemporaryCheck) {
              let resetObj = (resetCnaData as any)[f.key]
              if (resetObj) {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                  total: subTotal,
                  ...resetObj,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: false,
                  total: subTotal,
                }
              }
            }
          }
        })
      }
    })
    return body
  }

  function onChangedHcPlan(plan: any) {
    let selectedHcPlan = hcPlanData?.find((e) => e.key === plan)
    setSelectedHcPlan(selectedHcPlan)
  }

  function checkEditable() {
    return (route.params as any)?.mode !== ViewMode.READ_ONLY
  }

  function countApePerMonthValue(ape?: number) {
    if (!ape) return 0
    let apeInMillionUnit = mathHelper.divideWithDynamicPrecisionResult(ape, 1000000, 3, false)
    return mathHelper.divideWithDynamicPrecisionResult(apeInMillionUnit, 12, 3)
  }

  function countApePerYearValue(ape?: number) {
    if (!ape) return 0
    let apeInMillionUnit = mathHelper.divideWithDynamicPrecisionResult(ape, 1000000, 3, false)
    return apeInMillionUnit
  }

  function handleShowPopUpUpdateLate() {
    let selectedNeeds = needsOverallList.filter((e) => e.isChecked)
    let selectedCategoryCodes = [...new Set(selectedNeeds.map((e) => e.code))]
    if (
      isFocused &&
      selectedCategoryCodes.length === 1 &&
      selectedCategoryCodes[0] === 'MEDICINE' &&
      !loadingCnaDetail
    ) {
      setShowContentPopUpUpdateLate(true)
    }
  }

  function countTotalValue(needsOverallList: Array<any>) {
    let totalValue = 0
    needsOverallList.forEach((k) => {
      if (k.isChecked) {
        let qty = k.qty
        if (qty && isNumber(qty)) {
          totalValue += qty ?? 0
        }
      }
    })
    return totalValue
  }

  function countSubTotalValue(needsOverallList: Array<any>) {
    let totalValue = 0
    needsOverallList.forEach((k) => {
      if (k.isChecked) {
        let qty = k.subQty
        if (qty && isNumber(qty)) {
          totalValue += qty ?? 0
        }
      }
    })
    return totalValue
  }

  function renderMobileAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('NEEDS_CART.NEW')}
        navigation={navigation}
        onBack={onBack}
        onGoHome={() => setShowCancelWarningModel(true)}
      />
    )
  }

  function onBack() {
    if (checkDataHaveBeenSaved()) {
      navigation.replace('NeedsOverall', route.params)
    } else {
      setShowConfirmLeavingAlert(true)
    }
  }

  function onSaveDraft(data: any, isSavingDraft?: boolean, isSavingContinue?: boolean) {
    if (!data.needTerm || parseInt(data.needTerm) <= 0) {
      ToastService.error(i18n.t('VALIDATE.NEEDS_TERM'))
      return null
    }
    if (!data.needTerm || parseInt(data.needTerm) < MIN_NEED_TERM) {
      ToastService.error(i18n.t('NEEDS_OVERALL_EDIT_MODE.MIN_PLAN_TERM'))
      return null
    }
    if (needsOverallList.find((e) => e.isChecked && e.qty <= 0)) {
      ToastService.error(i18n.t('VALIDATE.MONEY'))
      return null
    }
    if (needsOverallList.find((e) => e.key === 'hcFatalDisease' && e.qty < MIN_FATAL_DECEASE_VALUE)) {
      ToastService.error(i18n.t('VALIDATE.FATAL_DECEASE_MIN'))
      return null
    }
    let apeError = dataProvider.validateAPE(currentHandlingCNA, ape)
    if (apeError) {
      toastService.error(
        i18n.t(apeError?.translateMessageCode ?? 'VALIDATE.UPDATE_VALID_APE', {
          value: vnNumberFormatter.format(apeError?.value ?? 0),
        })
      )
      return
    }
    let body = getDisplayingCNAData(data, isSavingDraft, isSavingContinue)
    return saveCNA(body)
  }

  function checkDataHaveBeenSaved() {
    if (!currentHandlingCNA?._id) {
      return false
    }
    let displayingCNAData = getDisplayingCNAData(getValues(), false, false)
    let initData = { ...currentHandlingCNA }
    delete initData.agentCode
    delete initData.lastEditStep
    delete displayingCNAData.isSavingDraft
    delete displayingCNAData.isSavingContinue
    delete displayingCNAData.id
    delete displayingCNAData.lastEditStep
    delete displayingCNAData.agentCode
    if (_.isEqual(initData, displayingCNAData)) {
      return true
    }
    return false
  }

  function getDisplayingCNAData(data: any, isSavingDraft?: boolean, isSavingContinue?: boolean) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      needTerm: parseInt(data.needTerm),
      lastEditStep: !isSavingDraft
        ? data.product === ProductType.ILP
          ? PageNumberMapping.Advisor
          : PageNumberMapping.Solution
        : PageNumberMapping.NeedsOverall,
      isSavingDraft: isSavingDraft,
      isSavingContinue: isSavingContinue,
      product: data.product,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.NEEDS_OVERALL,
        currentHandlingCNA?.customerPhone,
        isSavingDraft
      ),
    } as any
    if (data.product !== currentHandlingCNA?.product) {
      body = {
        ...body,
        topUp: 0,
        solutionDate: moment().toISOString(),
        quotationFundAllocation: defaultQuotationFundAllocation,
      }
    }
    if (selectedHcPlan) {
      let hcPlan = {
        isTemporaryCheck: true,
        isChecked: true,
        hcPlanDetail: selectedHcPlan,
        subTotal: dataProvider.countTotalHealthCarePlanData({
          boarding: selectedHcPlan?.boarding,
          dentistry: selectedHcPlan?.dentistry,
          outPatient: selectedHcPlan?.outPatient,
          maternity: selectedHcPlan?.maternity,
          age: currentHandlingCNA?.customerAge,
          gender: currentHandlingCNA?.customerGender,
        }),
      }
      if (currentHandlingCNA?.hcPlan?.hcPlanDetailSub) {
        hcPlan = {
          ...hcPlan,
          hcPlanDetailSub: currentHandlingCNA?.hcPlan?.hcPlanDetailSub,
        } as any
      }
      body = {
        ...body,
        hcPlan: hcPlan,
      }
    }
    if (data.product !== currentHandlingCNA?.product) {
      body = {
        ...body,
        topUp: 0,
        quotations: null,
        ape: 0,
        apeSub: 0,
      }
    }
    needsCartList.forEach((e) => {
      if (e && e.data && e.data.length) {
        e.data.forEach((f) => {
          if (f) {
            let correspondingNeedsOverall = needsOverallList.find((e) => e.key === f.key)
            let userSelectValue = correspondingNeedsOverall?.isChecked
            let total = correspondingNeedsOverall?.qty
            let obj = body[f.key]
            if (userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: total,
                }
              } else {
                body[f.key] = {
                  isSelect: true,
                  isTemporaryCheck: true,
                  total: total,
                }
              }
            } else if (!userSelectValue && f.isTemporaryCheck) {
              if (obj) {
                body[f.key] = {
                  ...obj,
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: total,
                }
              } else {
                body[f.key] = {
                  isSelect: false,
                  isTemporaryCheck: true,
                  total: total,
                }
              }
            }
          }
        })
      }
    })
    return body
  }

  function updateStateContinueButton() {
    let isNeedsExist = false
    for (let e of needsOverallList) {
      if (e?.isChecked === true) {
        isNeedsExist = true
        break
      }
    }
    if (isNeedsExist) {
      setEnableContinue(true)
    } else {
      setEnableContinue(false)
    }
  }

  function onGoBack() {
    navigation.replace('NeedsOverall', route.params)
  }

  function onSelected(needsCategoryCode: string, needsKey: string, isSelected: boolean) {
    let temp = [...needsOverallList]
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].code === needsCategoryCode && temp[i].key === needsKey) {
        temp[i] = { ...temp[i], isChecked: isSelected, isTemporaryCheck: true }
        break
      }
    }
    updateNeedsOverall([...temp])
    reCountApe([...temp])
    reCountApeSub([...temp])
  }

  function onConfirm(data: any) {
    let selectedNeeds = needsOverallList.filter((e) => e.isChecked)
    let selectedCategoryCodes = [...new Set(selectedNeeds.map((e) => e.code))]
    if (selectedCategoryCodes.length === 1 && selectedCategoryCodes[0] === NeedsCategory.MEDICINE) {
      setShowContentPopUpUpdateLate(true)
      return
    }
    onSaveDraft(getValues(), false, true)?.then((e: any) => {
      if (!e.error) {
        if (getValues('product') === ProductType.ILP) {
          navigation.navigate('Advisor', route.params)
        } else {
          navigation.navigate('Solution', route.params)
        }
      } else {
        toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
      }
    })
  }

  function onUpdateTotalValue(needsCategoryCode: string, needsKey: string, totalValue: number, isSelected: boolean) {
    let temp = [...needsOverallList]
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].code === needsCategoryCode && temp[i].key === needsKey) {
        temp[i] = { ...temp[i], qty: totalValue, isChecked: isSelected, isTemporaryCheck: true }
        break
      }
    }
    updateNeedsOverall([...temp])
    reCountApe([...temp])
  }

  function onUpdateSubTotalValue(
    needsCategoryCode: string,
    needsKey: string,
    subtotalValue: number,
    isSelected: boolean
  ) {
    let temp = [...needsOverallList]
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].code === needsCategoryCode && temp[i].key === needsKey) {
        temp[i] = { ...temp[i], subQty: subtotalValue, isChecked: isSelected, isTemporaryCheck: true }
        break
      }
    }
    updateNeedsOverall([...temp])
    reCountApeSub([...temp])
  }

  function onChangeProductType(value?: number | string) {
    if (value !== getValues('product') && typeof value === 'string') {
      setValue('product', value)
      recountAllApeData()
    }
  }

  function renderModals() {
    return (
      <>
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showCancelWarningModel}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.KEEP_CANCEL')}
          cancelText={i18n.t('COMMON.CONTINUE')}
          onOk={() => {
            onSaveDraft(getValues(), true)
              ?.unwrap()
              .then((_) => {
                setShowConfirmLeavingAlert(false)
                navigation.replace('NeedsOverall', route.params)
              })
          }}
          onCancel={() => {
            setShowConfirmLeavingAlert(false)
            navigation.replace('NeedsOverall', route.params)
          }}
        >
          <Text></Text>
        </Dialog>
        {/* Warning pop update late  */}
        <Dialog
          type="inform"
          showCloseIcon
          visible={showContentPopUpUpdateLate}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ maxWidth: 550, margin: 20, borderRadius: 12, paddingTop: 0 }}
          onClose={() => setShowContentPopUpUpdateLate(false)}
          title={
            <View style={[globalStyles.DisplayFlexRow, globalStyles.FullWidth]}>
              <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.Flex1]}>
                <Image style={globalStyles.Icon25} source={icWarning} />
                <Text style={globalStyles.DialogTitle}>{i18n.t('COMMON.WARNING')}</Text>
              </View>
            </View>
          }
        >
          <View style={globalStyles.Center}>
            <View style={{ maxWidth: 516, marginTop: 20, paddingHorizontal: 16 }}>
              <Text style={{ fontSize: 15, fontWeight: 'normal', textAlign: 'center', color: '#505D6F' }}>
                {i18n.t('NEEDS_CART.POP_UPDATE_LATE_CONTENT')}
              </Text>
            </View>
          </View>
        </Dialog>
        {/* Confirm leaving */}
        <ConfirmSavePopUp
          isShow={showConfirmLeavingAlert}
          onOk={() => {
            onSaveDraft(getValues())
              ?.unwrap()
              .then((_) => {
                setShowConfirmLeavingAlert(false)
                navigation.replace('NeedsOverall', route.params)
              })
          }}
          onCancel={() => {
            setShowConfirmLeavingAlert(false)
            navigation.replace('NeedsOverall', route.params)
          }}
          onClose={() => setShowConfirmLeavingAlert(false)}
        />
      </>
    )
  }

  const ExpandableListTitle = ({
    title,
    children,
    containerStyle,
  }: {
    title: string
    children: JSX.Element
    containerStyle: Record<string, unknown>
  }) => {
    return (
      <Expandable
        header={<Text style={globalStyles.MobileTitle}>{title}</Text>}
        expandable={children}
        expandIcon={imgArrowRightCircleRed}
        expandIconStyle={{ width: 20, height: 20 }}
        containerStyle={containerStyle}
      />
    )
  }

  if (isMobileDevice) {
    return (
      <SafeAreaView
        style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
        edges={['right', 'left', 'top']}
      >
        <View style={globalStyles.mobileContent}>
          {renderMobileAppBar()}
          <ScrollView style={globalStyles.contentMobile}>
            <ExpandableListTitle
              title={i18n.t('SOLUTION.CUSTOMER_INFO').toUpperCase()}
              children={
                <UserInfo
                  name={currentHandlingCNA?.reminiscentName}
                  maritalStatus={currentHandlingCNA?.maritalStatus}
                  gender={currentHandlingCNA?.customerGender}
                  age={currentHandlingCNA?.customerAge}
                />
              }
              containerStyle={{
                ...globalStyles.Card,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#D7DFFF',
                paddingVertical: 13,
                paddingHorizontal: 20,
                borderRadius: 10,
                backgroundColor: '#FAFBFF',
              }}
            />
            <SizedBox height={20} />
            <ExpandableListTitle
              title={i18n.t('NEEDS_OVERALL_EDIT_MODE.NEEDS_INFO').toUpperCase()}
              children={
                <UserFinanceInfo
                  needTerm={getValues('needTerm')}
                  productType={getValues('product')}
                  monthlyIncome={getValues('monthlyIncome')}
                  monthlyExpense={getValues('monthlyExpense')}
                  formControl={control}
                  formRegister={register}
                  errors={errors}
                  onChangeNeedTerm={(value?: number | string) => setValue('needTerm', value)}
                  onChangeProductType={onChangeProductType}
                />
              }
              containerStyle={{
                ...globalStyles.Card,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#FFF',
                paddingVertical: 13,
                paddingHorizontal: 20,
                borderRadius: 10,
                backgroundColor: '#FFF',
              }}
            />
            <SizedBox height={20} />
            <SummaryAPEInfo
              needTotal={totalValue}
              needSubTotal={subTotalValue}
              apePerMonth={countApePerMonthValue(ape)}
              apePerYear={countApePerYearValue(ape)}
              apeSubPerMonth={countApePerMonthValue(apeSub)}
              apeSubPerYear={countApePerYearValue(apeSub)}
              loadingApe={loadingApe}
              loadingSubApe={loadingSubApe}
            />
            <SizedBox height={20} />
            <DelayAppearComponent triggerAnimation={needsOverallList?.length > 0}>
              <NeedsInfo
                userInfo={userInfo}
                needsOverallList={needsOverallList}
                needTotal={totalValue}
                needSubTotal={subTotalValue}
                onChangedHcPlan={onChangedHcPlan}
                onUpdateTotalValue={onUpdateTotalValue}
                onUpdateSubTotalValue={onUpdateSubTotalValue}
                selectedHcPlan={selectedHcPlan}
                selectedHcPlanSub={currentHandlingCNA?.hcPlan?.hcPlanDetailSub}
                hcPlanData={hcPlanData}
                onSelected={onSelected}
              />
            </DelayAppearComponent>
            <SizedBox height={20} />
            <StepButton
              onBack={onGoBack}
              onNext={handleSubmit(onConfirm)}
              loadingOnNext={loadingSaveContinue}
              disabledOnNext={!!!enableContinue || loadingApe || loadingSubApe}
              nextText={i18n.t('NEEDS_OVERALL_EDIT_MODE.VIEW_SOLUTION')}
              buttonWidth={150}
            />
            <SizedBox height={130} />
          </ScrollView>
        </View>
        <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
          <MobileStepIndicator
            route={route}
            navigation={navigation}
            cnaData={currentHandlingCNA}
            needsCartList={needsCartList}
            currentPosition={2}
            loadingSaveDraft={loadingSaveDraft}
            onSaveDraft={() => onSaveDraft(getValues(), true)}
          />
        </View>
        {renderModals()}
      </SafeAreaView>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
        <StepAppHeader
          route={route}
          navigation={navigation}
          currentPosition={1}
          onSaveDraft={() => onSaveDraft(getValues(), true)}
          cnaData={currentHandlingCNA}
          needsCartList={needsCartList}
        />
        <ScrollView style={globalStyles.content}>
          <UserInfo
            name={currentHandlingCNA?.reminiscentName}
            maritalStatus={currentHandlingCNA?.maritalStatus}
            gender={currentHandlingCNA?.customerGender}
            age={currentHandlingCNA?.customerAge}
          />
          <SizedBox height={20} />
          <UserFinanceInfo
            needTerm={getValues('needTerm')}
            productType={getValues('product')}
            monthlyIncome={getValues('monthlyIncome')}
            monthlyExpense={getValues('monthlyExpense')}
            formControl={control}
            formRegister={register}
            errors={errors}
            onChangeNeedTerm={(value?: number | string) => setValue('needTerm', value)}
            onChangeProductType={onChangeProductType}
          />
          <SizedBox height={20} />
          <Block
            title={i18n.t('NEEDS_OVERALL_EDIT_MODE.ADJUST_NEEDS')}
            child={
              <View style={tabletStyles.content}>
                <SummaryAPEInfo
                  needTotal={totalValue}
                  needSubTotal={subTotalValue}
                  apePerMonth={countApePerMonthValue(ape)}
                  apePerYear={countApePerYearValue(ape)}
                  apeSubPerMonth={countApePerMonthValue(apeSub)}
                  apeSubPerYear={countApePerYearValue(apeSub)}
                  loadingApe={loadingApe}
                  loadingSubApe={loadingSubApe}
                />
                <NeedsInfo
                  userInfo={userInfo}
                  needsOverallList={needsOverallList}
                  needTotal={totalValue}
                  needSubTotal={subTotalValue}
                  onChangedHcPlan={onChangedHcPlan}
                  onUpdateTotalValue={onUpdateTotalValue}
                  onUpdateSubTotalValue={onUpdateSubTotalValue}
                  selectedHcPlan={selectedHcPlan}
                  selectedHcPlanSub={currentHandlingCNA?.hcPlan?.hcPlanDetailSub}
                  hcPlanData={hcPlanData}
                  onSelected={onSelected}
                />
              </View>
            }
          />
          <SizedBox height={20} />
          <StepButton
            onBack={onBack}
            onNext={handleSubmit(onConfirm)}
            disabledOnNext={!!!enableContinue || loadingApe || loadingSubApe}
            nextText={i18n.t('NEEDS_OVERALL_EDIT_MODE.VIEW_SOLUTION')}
            buttonWidth={150}
            loadingOnNext={loadingSaveContinue}
          />
        </ScrollView>
        {renderModals()}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <StepAppHeader
        route={route}
        navigation={navigation}
        currentPosition={1}
        onSaveDraft={() => onSaveDraft(getValues(), true)}
        cnaData={currentHandlingCNA}
        needsCartList={needsCartList}
      />
      <ScrollView style={globalStyles.content}>
        <UserInfo
          name={currentHandlingCNA?.reminiscentName}
          maritalStatus={currentHandlingCNA?.maritalStatus}
          gender={currentHandlingCNA?.customerGender}
          age={currentHandlingCNA?.customerAge}
        />
        <SizedBox height={40} />
        <UserFinanceInfo
          needTerm={getValues('needTerm')}
          productType={getValues('product')}
          monthlyIncome={getValues('monthlyIncome')}
          monthlyExpense={getValues('monthlyExpense')}
          formControl={control}
          formRegister={register}
          errors={errors}
          onChangeNeedTerm={(value?: number | string) => setValue('needTerm', value)}
          onChangeProductType={onChangeProductType}
        />
        <SizedBox height={40} />
        <Block
          title={i18n.t('NEEDS_OVERALL_EDIT_MODE.ADJUST_NEEDS')}
          child={
            <View style={desktopStyles.content}>
              <SummaryAPEInfo
                needTotal={totalValue}
                needSubTotal={subTotalValue}
                apePerMonth={countApePerMonthValue(ape)}
                apePerYear={countApePerYearValue(ape)}
                apeSubPerMonth={countApePerMonthValue(apeSub)}
                apeSubPerYear={countApePerYearValue(apeSub)}
                loadingApe={loadingApe}
                loadingSubApe={loadingSubApe}
              />
              <NeedsInfo
                userInfo={userInfo}
                needsOverallList={needsOverallList}
                needTotal={totalValue}
                needSubTotal={subTotalValue}
                onChangedHcPlan={onChangedHcPlan}
                onUpdateTotalValue={onUpdateTotalValue}
                onUpdateSubTotalValue={onUpdateSubTotalValue}
                selectedHcPlan={selectedHcPlan}
                selectedHcPlanSub={currentHandlingCNA?.hcPlan?.hcPlanDetailSub}
                hcPlanData={hcPlanData}
                onSelected={onSelected}
              />
            </View>
          }
        />

        <SizedBox height={40} />
        <StepButton
          onBack={onBack}
          disabledOnNext={!!!enableContinue || loadingApe || loadingSubApe}
          onNext={handleSubmit(onConfirm)}
          nextText={i18n.t('NEEDS_OVERALL_EDIT_MODE.VIEW_SOLUTION')}
          buttonWidth={150}
          loadingOnNext={loadingSaveContinue}
        />
      </ScrollView>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(NeedsOverallEditModeScreen)

const desktopStyles = StyleSheet.create({
  content: {
    paddingTop: 31,
    flexDirection: 'column',
    paddingBottom: 31,
  },
})

const tabletStyles = StyleSheet.create({
  content: {
    paddingTop: 31,
    flexDirection: 'column',
    paddingBottom: 31,
  },
})

const mobileStyles = StyleSheet.create({
  stepIndicatorContainer: {
    bottom: 16,
    left: 16,
    right: 16,
  },
})
