import * as React from 'react'
import { StyleSheet, TouchableOpacity, Image, ImageSourcePropType } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'

export type IconButtonProps = {
  imageSource?: any
  iconStyle?: Record<string, unknown>
  onPress?: () => void
}

export function IconButton({ imageSource, iconStyle, onPress }: IconButtonProps) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <Image source={imageSource} style={iconStyle ?? globalStyles.Icon25} />
    </TouchableOpacity>
  )
}

export default IconButton

const styles = StyleSheet.create({
  container: {
    width: 32,
    height: 32,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
