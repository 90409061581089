import { FilledButton, styles as globalStyles } from '../common'
import { connect, ConnectedProps } from 'react-redux'
import * as React from 'react'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { DEFAULT_CUSTOMER_GENDER } from '../constants/Data'
import icCheckWhite from '../assets/images/check-white.png'
import { Image } from 'react-native'

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.user?.userInfo,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  customerProfile: state?.needsCart.profileInfo,
  loadingSaveCNA: state?.cna.loadingSaveDraft,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  saveCNA,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export type SaveDraftButtonProps = PropsFromRedux & {
  onPress?: () => void
  buttonStyle?: Record<string, unknown>
  labelStyle?: Record<string, unknown>
}

function SaveDraftButton({
  buttonStyle,
  labelStyle,
  onPress,
  currentHandlingCNA,
  loadingSaveCNA,
  customerProfile,
  userInfo,
  needsCartList,
  saveCNA,
}: SaveDraftButtonProps) {
  function onSaveDraft() {
    try {
      let body = {
        ...currentHandlingCNA,
        id: currentHandlingCNA?._id,
        agentCode: userInfo?.sub,
        customerGender: customerProfile?.gender ?? DEFAULT_CUSTOMER_GENDER,
        customerAge: customerProfile?.age,
        maritalStatus: customerProfile?.maritalStatus,
      } as any
      needsCartList.forEach((e) => {
        if (e && e.data && e.data.length) {
          e.data.forEach((f) => {
            if (f && f.isChecked) {
              body[f.key] = {
                isSelect: true,
              }
            }
          })
        }
      })
      saveCNA(body)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <FilledButton
      title="Lưu nháp"
      onPress={() => (onPress ? onPress() : onSaveDraft())}
      prefix={<Image source={icCheckWhite} style={globalStyles.Icon9} />}
      buttonStyle={{
        width: 134,
        marginTop: 0,
        height: 41,
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginRight: 8,
        ...buttonStyle,
      }}
      loading={loadingSaveCNA}
    />
  )
}

export default connector(SaveDraftButton)
