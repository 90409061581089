import Layout from '../../../constants/Layout'
import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image, FlatList, TouchableOpacity } from 'react-native'
import icBlueHeart from '../../../assets/images/blue-heart.png'
import icCircleCheck from '../../../assets/images/circle-check.png'
import icDocument from '../../../assets/images/column-graph.png'
import icInfoRed from '../../../assets/images/info-red.png'
import { Spacer, vnCurrencyUnitFormatter, styles as globalStyles, SizedBox } from '../../../common'
import { tableBeneficiary } from '../../../constants/Data'

const cardWidth = 300

type IProps = {
  data?: Array<{ title: string; value?: number; answer?: any }>
  style?: Record<string, unknown>
  onShowBeneficiary?: () => void
  onShowPopupBenefit?: (title: string) => void
}

function SummaryHealthCareProgram({ data, style, onShowBeneficiary, onShowPopupBenefit }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice


  
  function getColumnCount() {
    const ratio = Layout.window.width / 2 / cardWidth
    if (ratio < 2) {
      return 1
    }
    return Math.round(ratio)
  }

  function switchAnswer(answer: string) {
    switch (answer) {
      case 'Chương trình chăm sóc cơ bản':
        return 'contentA'
      case 'Chương trình chăm sóc nâng cao':
        return 'contentB'
      case 'Chương trình chăm sóc toàn diện':
        return 'contentC'
      case 'Chương trình chăm sóc hoàn hảo':
        return 'contentD'
      default:
        return 'contentA'
    }
  }

  function renderItem(title: string, answer: string, value?: number) {
    const dataCheck = tableBeneficiary.filter((x) => x.type === title).map((y) => y?.content) as any
    const mergeArray = [...new Set([].concat(...dataCheck))]
    const checkArray = mergeArray.every((x) => x[switchAnswer(answer)] === '')

    if (isMobileDevice) {
      return (
        <View style={mobileStyles.infoItemContainer}>
          <Image style={mobileStyles.infoItemIcon} source={icCircleCheck as ImageSourcePropType} />
          <Text style={mobileStyles.infoItemTitle}>{title}</Text>
          <Text style={mobileStyles.infoItemValue}>{value ? vnCurrencyUnitFormatter(value * 1000000) : '-'}</Text>
          {!checkArray && answer && (value ?? 0) > 0 && (
            <>
              <SizedBox width={4} />
              <Image source={icInfoRed as ImageSourcePropType} style={mobileStyles.infoIcon} />
              <SizedBox width={4} />
              <TouchableOpacity onPress={() => onShowPopupBenefit && onShowPopupBenefit(title)}>
                <Text style={[globalStyles.ErrorText, { fontSize: 10, marginTop: 0 }]}>(Tham khảo)</Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      )
    }

    return (
      <View style={desktopStyles.infoItemContainer}>
        <Image style={desktopStyles.infoItemIcon} source={icCircleCheck as ImageSourcePropType} />
        <Text style={desktopStyles.infoItemTitle}>{title}</Text>
        <Text style={desktopStyles.infoItemValue}>{value ? vnCurrencyUnitFormatter(value * 1000000) : '-'}</Text>
        {!checkArray && answer && (value ?? 0) > 0 && (
          <>
            <SizedBox width={4} />
            <Image source={icInfoRed as ImageSourcePropType} style={desktopStyles.infoIcon} />
            <SizedBox width={4} />
            <TouchableOpacity onPress={() => onShowPopupBenefit && onShowPopupBenefit(title)}>
              <Text style={[globalStyles.ErrorText, { fontSize: 10, marginTop: 0 }]}>(Tham khảo)</Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    )
  }

  if (isMobileDevice) {
    return (
      <View style={[mobileStyles.containerWrap,style]}>
      <View style={[mobileStyles.container]}>
        <View style={desktopStyles.titleContainer}>
          <Image source={icBlueHeart as ImageSourcePropType} style={mobileStyles.iconTitle} />
          <Text style={mobileStyles.title}>Thẻ sức khỏe chăm sóc Khách hàng và gia đình</Text>
        </View>
        <View style={mobileStyles.infoContainer}>
          <FlatList
            data={data}
            renderItem={(item) => {
              return (
                <View key={item.index} style={{ width: `${100 / getColumnCount()}%` }}>
                {renderItem(item.item.title, item.item.answer, item.item.value)}
                </View>
              )
            }}
             numColumns={getColumnCount()}
          />
        </View>
      </View>
      <Spacer />
          <TouchableOpacity style={mobileStyles.viewFeeContainer} onPress={onShowBeneficiary}>
            <Image source={icDocument as ImageSourcePropType} style={desktopStyles.viewFeeIcon} />
            <Text style={desktopStyles.viewFeeLabel}>Tham khảo chi tiết các gói Chăm sóc y tế</Text>
          </TouchableOpacity>
          </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <View style={desktopStyles.titleContainer}>
        <Image source={icBlueHeart as ImageSourcePropType} style={desktopStyles.iconTitle} />
        <Text style={desktopStyles.title}>Thẻ sức khỏe chăm sóc Khách hàng và gia đình</Text>
        <Spacer />
        <TouchableOpacity style={desktopStyles.viewFeeContainer} onPress={onShowBeneficiary}>
          <Image source={icDocument as ImageSourcePropType} style={desktopStyles.viewFeeIcon} />
          <Text style={desktopStyles.viewFeeLabel}>Tham khảo chi tiết các gói Chăm sóc y tế</Text>
        </TouchableOpacity>
      </View>
      <View style={desktopStyles.infoContainer}>
        <FlatList
          data={data}
          renderItem={(item) => {
            return (
              <View key={item.index} style={{ width: `${100 / 2}%` }}>
              {renderItem(item.item.title, item.item.answer, item.item.value)}
              </View>
            )
          }}
           numColumns={2}
        />
      </View>
    </View>
  )
}

export default SummaryHealthCareProgram

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 12,
    borderRadius: 12,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 20,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 28.19,
    paddingHorizontal: 31.51,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 8,
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 50,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
    flex: 1,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  infoIcon: {
    width: 10,
    height: 10,
    resizeMode: 'contain',
  },
  viewFeeContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  viewFeeIcon: {
    width: 18,
    height: 20,
  },
  viewFeeLabel: {
    color: '#2F7AEB',
    fontStyle: 'italic',
    fontSize: 12,
    marginLeft: 12,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 21.7,
    paddingVertical: 12,
    borderRadius: 12,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 20,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 28.19,
    paddingHorizontal: 31.51,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 24.5,
  },
  infoItemContainer: {
    paddingVertical: 9,
    paddingRight: 50,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 15,
    marginLeft: 14,
    flex: 1,
  },
  infoItemValue: {
    fontSize: 15,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
  },
  infoIcon: {
    width: 10,
    height: 10,
    resizeMode: 'contain',
  },
  viewFeeContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  viewFeeIcon: {
    width: 18,
    height: 20,
  },
  viewFeeLabel: {
    color: '#2F7AEB',
    fontStyle: 'italic',
    fontSize: 12,
    marginLeft: 12,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
    borderRadius: 12,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconTitle: {
    width: 41.46,
    height: 41.45,
  },
  title: {
    fontSize: 13.23,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 44.78,
  },
  containerWrap: {
    backgroundColor: '#fff',
    borderRadius: 13.23,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderRadius: 19.9,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderWidth: 0.829167,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    width: '100%',
    marginTop: 8,
    flexWrap: 'wrap',
  },
  infoItemContainer: {
    paddingVertical: 9,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  infoItemIcon: {
    width: 18.24,
    height: 18.24,
    resizeMode: 'contain',
  },
  infoItemTitle: {
    flex: 1,
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
    textAlign: 'right',
    minWidth: 90,
  },
  infoIcon: {
    width: 10,
    height: 10,
    resizeMode: 'contain',
  },
  viewFeeContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 15,
    paddingRight: 20
  },
  viewFeeIcon: {
    width: 18,
    height: 20,
  },
  viewFeeLabel: {
    color: '#2F7AEB',
    fontStyle: 'italic',
    fontSize: 12,
    marginLeft: 12,
  },
})
