import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { FilledButton, RadioButton, SizedBox, styles as globalStyles } from '../../../common'
import i18n from 'i18n-js'
import Layout from '../../../constants/Layout'

type IProps = {
  loading?: boolean
  onClose: (isUserSatisfied?: boolean) => void
}

function ReviewDialog({ loading, onClose }: IProps) {
  const isMobileDevice = Layout.isMobileDevice
  const [isUserSatisfied, setIsUserSatisfied] = React.useState()
  return (
    <View style={[{ maxWidth: 784, maxHeight: 600, overflow: 'hidden', padding: isMobileDevice ? 0 : 0 }]}>
      <Text style={[globalStyles.DialogTitle]}>{i18n.t('SOLUTION.SATISFACTION_CONFIRM')}</Text>
      <View style={globalStyles.Center}>
        <View
          style={[
            { paddingRight: isMobileDevice ? 20 : 40, paddingLeft: isMobileDevice ? 20 : 70, paddingTop: 0 },
          ]}
        >
          <View style={[{ paddingHorizontal: 20 }]}>
            <RadioButton
              title=""
              data={[
                {
                  value: false,
                  text: i18n.t('SOLUTION.NOT_SATISFIED'),
                },
                {
                  value: true,
                  text: i18n.t('SOLUTION.SATISFIED'),
                },
              ]}
              direction={'column'}
              containerStyle={{ alignItems: 'flex-start' }}
              onChange={(value: any) => setIsUserSatisfied(value)}
              radioItemContainerStyle={{ maxWidth: 'none' }}
            />
          </View>
          <SizedBox height={20} />
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <FilledButton
          title={i18n.t('COMMON.CONFIRM')}
          onPress={() => {
            onClose(isUserSatisfied)
          }}
          buttonStyle={styles.button}
          titleStyle={{ fontSize: 14 }}
          disabled={typeof isUserSatisfied !== 'boolean'}
          loading={loading}
        />
      </View>
      <SizedBox height={20} />
    </View>
  )
}

export default ReviewDialog

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    width: 120,
    height: 41,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 0,
  },
})
