import { HttpCode } from "../constants/HttpCode"

const exceptionCode = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: "FORBIDDEN",
  BAD_REQUEST: "BAD_REQUEST",
  SERVER_ERROR: "SERVER_ERROR",
}

export function parseHttpCode(code?: number) {
  switch (code) {
    case HttpCode.BAD_REQUEST:
      return exceptionCode.BAD_REQUEST
    case HttpCode.FORBIDDEN:
      return exceptionCode.FORBIDDEN
    case HttpCode.UNAUTHORIZED:
      return exceptionCode.UNAUTHORIZED
    case HttpCode.SERVER_ERROR:
    default:
      return exceptionCode.SERVER_ERROR
  }
}

export default exceptionCode
