import * as React from 'react'
import FilledButton from './FilledButton'
import { Image, ImageSourcePropType, StyleSheet } from 'react-native'
import icArrowRight from '../../assets/icon/arrow-right.png'

export type NextButtonProps = {
  title?: string
  buttonStyle?: Record<string, unknown>
  titleStyle?: Record<string, unknown>
  onPress?: () => void
}

export function NextButton({ title, titleStyle, buttonStyle, onPress }: NextButtonProps) {
  return (
    <FilledButton
      title={title}
      onPress={onPress}
      suffix={<Image source={icArrowRight as ImageSourcePropType} style={styles.icon} />}
      titleStyle={titleStyle}
      buttonStyle={buttonStyle}
    />
  )
}

export default NextButton

const styles = StyleSheet.create({
  icon: {
    width: 30,
    height: 30,
  },
})
