import React, {useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Image , ImageSourcePropType} from 'react-native'
import imgInvestRaiseAssets from '../../assets/images/needs/invest-raise-assets.png'
import imgAccumulateForEducation from '../../assets/images/needs/accumulate-for-education.png'
import imgCharity from '../../assets/images/needs/charity.png'
import imgInheritances from '../../assets/images/needs/inheritances.png'
import imgMedicineCare from '../../assets/images/needs/medicine-care.png'
import imgProtectAssets from '../../assets/images/needs/protect-assets.png'
import imgProtectDescendantEducation from '../../assets/images/needs/protect-descendant-education.png'
import imgProtectFatalDisease from '../../assets/images/needs/protect-fatal-disease.png'
import imgProtectIncome from '../../assets/images/needs/protect-income.png'
import imgProtectLifeStandard from '../../assets/images/needs/protect-life-standard.png'
import imgRetirePlan from '../../assets/images/needs/retire-plan.png'
import imgArrowLeft from '../../assets/images/arrow-left.png'
import imgArrowRight from '../../assets/images/arrow-right.png'
import { CheckBox } from 'react-native-elements'
import AgentWording from '../../components/AgentWording';
import UserInfoData from 'model/UserInfoData';


export type TabBarViewProps = {
  title?: string
  data?: any
  postion: number
  containerStyle?: object
  dataActive?: any
  style?: Record<string, unknown>
  userInfo?: UserInfoData
  onPressPrev?: () => void
  onPressNext?: () => void
}

export function TabBarView({ userInfo, data, onPressNext,onPressPrev, postion,containerStyle,dataActive }: TabBarViewProps) {
  
  function switchCase(i: string) { 
    if (dataActive?._id) {
    let checkVadilateEdu = true
    dataActive?.eduPlan?.childrenDetails &&
    dataActive?.eduPlan?.childrenDetails.every((x:any) => {
        checkVadilateEdu = x.isSchool ? x.schoolFees !== 0 && x.schoolYears !== 0 : true
        if (!checkVadilateEdu) {
          return 
        }
        checkVadilateEdu = x.isUniversity ? x.universityFees !== 0 && x.universityYears !== 0 : true
        if (!checkVadilateEdu) {
          return 
        }
        checkVadilateEdu = x.isAfterUni ? x.afterUniFees !== 0 && x.afterUniYears !== 0 : true
        if (!checkVadilateEdu) {
          return 
        }
        return true
      })
      const checkSaveEdu =
      dataActive?.eduPlan?.childrenDetails && dataActive?.eduPlan?.childrenDetails?.some((x: any) => x.childAge === 0)

      const checkProtectEdu =  dataActive?.protectEdu?.childrenDetails  &&
      dataActive?.protectEdu?.childrenDetails?.every(function (x: any) {
        if (Number(x.childAge) > 0 &&  Number(x.childAge) <= Number(dataActive?.protectEdu?.eduPlanAge) &&  Number(x.tuition !== 0)) {
          return true;
        }
        return false
      }) 
    switch (i) { 
      case 'Bảo vệ thu nhập':
        case 'Bảo vệ thu nhập':
          if (Number(dataActive?.protectIncome?.subTotal) <= 0 && dataActive?.protectIncome?.isTemporaryCheck === true || Number(dataActive?.protectIncome?.monthlyIncome) > 10000 ||  dataActive?.protectLife?.monthlyFixedExpense && dataActive?.protectIncome?.monthlyIncome && Number(dataActive?.protectIncome?.monthlyIncome) < Number(dataActive?.protectLife?.monthlyFixedExpense)  ) {
            return 'deactive'
          } else {
            return 'active'
          }
        case 'Bảo vệ tiêu chuẩn cuộc sống':
          if (Number(dataActive?.protectLife?.subTotal) <= 0 && dataActive?.protectLife?.isTemporaryCheck === true || 
          !dataActive?.protectLife?.month && dataActive?.protectLife?.isTemporaryCheck === true || 
          dataActive?.protectLife?.isSubsidy === true && !dataActive?.protectLife?.monthlySubsidy ||   dataActive?.protectLife?.monthlyFixedExpense && dataActive?.protectIncome?.monthlyIncome && Number(dataActive?.protectIncome?.monthlyIncome) < Number(dataActive?.protectLife?.monthlyFixedExpense) || Number(dataActive?.protectLife?.monthlyFixedExpense) > 10000 ||
          dataActive?.protectLife?.isSubsidy === true && !dataActive?.protectLife?.subsidyMonth ) {
            return 'deactive'
          } else {
            return 'active'
          }
      case 'Bảo vệ tài sản':
        if (  (Number(dataActive?.protectAsset?.subTotal) <= 0  && dataActive?.protectAsset?.isTemporaryCheck === true) ||
        (dataActive?.protectAsset?.collateral?.isCheck === true && !dataActive?.protectAsset?.collateral?.total) ||
     (dataActive?.protectAsset?.saving?.isCheck === true && !dataActive?.protectAsset?.saving?.total) ||
     (dataActive?.protectAsset?.otherAssets?.isCheck === true && !dataActive?.protectAsset?.otherAssets?.total)) {
          return 'deactive'
        } else {
          return 'active'
        }
        case 'Bảo vệ kế hoạch giáo dục cho con':
          if ((Number(dataActive?.protectEdu?.subTotal) <= 0 && dataActive?.protectEdu?.isTemporaryCheck === true) ||
          checkProtectEdu === false || dataActive?.protectEdu?.childrenDetails && dataActive?.protectEdu?.childrenDetails.some((x: any) =>  Number(x.tuition || 0) === 0)) {
            return 'deactive'
          } else {
            return 'active'
          }
      case 'Bảo vệ trước bệnh hiểm nghèo':
        if (Number(dataActive?.hcFatalDisease?.subTotal) > 99 && dataActive?.hcFatalDisease?.isTemporaryCheck === true) {
          return 'active'
        } else {
          return 'deactive'
        }
      case 'Chăm sóc Y tế':
        if (Number(dataActive?.hcPlan?.subTotal) > 0 && dataActive?.hcPlan?.isTemporaryCheck === true ) {
          return 'active'
        } else {
          return 'deactive'
        }
      case 'Tích luỹ cho kế hoạch giáo dục':
        if ( (checkSaveEdu === true && dataActive?.eduPlan?.isSelect === true) ||
        (checkSaveEdu === null && dataActive?.eduPlan?.isSelect === true) ||
        (!checkVadilateEdu && dataActive?.eduPlan?.isSelect === true) || 
        Number(dataActive?.eduPlan?.subTotal) <= 0  && dataActive?.eduPlan?.isTemporaryCheck === true ) {
          return 'deactive'
        } else {
          return 'active'
        }
      case 'Chuẩn bị cho tuổi hưu an nhàn':
        if ((Number(dataActive?.retirement?.subTotal) <= 0 && dataActive?.retirement?.isTemporaryCheck === true) ||
        (Number(dataActive?.retirement?.countDown) <= 0 && dataActive?.retirement?.isTemporaryCheck === true) ||
        (Number(dataActive?.retirement?.countDown) > 100 && dataActive?.retirement?.isTemporaryCheck === true)) {
          return 'deactive'
        } else {
          return 'active'
        }
      case 'Đầu tư gia tăng tài sản':
        if ( (Number(dataActive?.investment?.subTotal) <= 0 &&
        dataActive?.investment?.isTemporaryCheck === true  ||
        !dataActive?.investment?.year) &&  dataActive?.investment?.isTemporaryCheck === true  || 
      (dataActive?.investment?.isPrepare === true &&
        Number(dataActive?.investment?.saveMoney) >= Number(dataActive?.investment?.money) &&
        dataActive?.investment?.isTemporaryCheck === true) ||
      (dataActive?.investment?.isPrepare === true &&
        Number(dataActive?.investment?.saveMoney) === 0 &&
        dataActive?.investment?.isTemporaryCheck === true)) {
          return 'deactive'
        } else {
          return 'active'
        }
      case 'Để lại thừa kế':
        if (Number(dataActive?.inherit?.subTotal) > 0 && dataActive?.inherit?.isTemporaryCheck === true) {
          return 'active'
        } else {
          return 'deactive'
        }
      case 'Từ thiện':
        if (Number(dataActive?.charity?.subTotal) > 0 && dataActive?.charity?.isTemporaryCheck === true) {
          return 'active'
        } else {
          return 'deactive'
        }

      default:
        return 'active'
    }
  } else {
    return 'active'
  }
  }

  const [index, setIndex] = React.useState(postion)
  const [routes,setRoutes] = React.useState(data)

  useEffect(() => {
   setIndex(postion)
  }, [postion])


  useEffect(() => {
    if (data){
      setRoutes(data)
    }
  }, [data])

  function renderContent(needKey: string) {
    switch (needKey) {
      case 'protectIncome':
      case 'protectLife':
      case 'protectEdu':
      case 'protectAsset':
      case 'eduPlan':
      case 'hcFatalDisease':
      case 'hcPlan':
      case 'retirement':
      case 'investment':
      case 'inherit':
      case 'charity':
        return (
          <AgentWording
            agentOfficeCode={userInfo?.officeCode}
            wordingCode={`NEEDS_ANALYST.LIST.${needKey}`}
            style={styles.titleList}
          />
        )
      default:
        return ''
    }
  }


  function renderBorder(type: string) {
    switch (type) {
      case 'PROTECT':
        return '#BE63F9'
      case 'EDUCATION':
        return '#26C6DA'
      case 'MEDICINE':
        return '#26C6DA'
      case 'OTHER':
        return '#FF9900'
      case 'RETIREMENT':
        return '#FC573B'
      default:
        return 'red'
    }
  }


  function renderContentImage(type: string) {
    switch (type) {
      case 'Bảo vệ thu nhập':
        return (
          <Image
            style={styles.tinyImage}
            source={
             imgProtectIncome as ImageSourcePropType
            }
          />
        )
      case 'Bảo vệ tiêu chuẩn cuộc sống':
        return (
          <Image
            style={styles.tinyImage}
            source={
              imgProtectLifeStandard as ImageSourcePropType
            }
          />
        )
      case 'Bảo vệ kế hoạch giáo dục cho con':
        return (
          <Image
            style={styles.tinyImage}
            source={
               imgProtectDescendantEducation as ImageSourcePropType
            }
          />
        )
      case 'Bảo vệ tài sản':
        return (
          <Image
            style={styles.tinyImage}
            source={
              imgProtectAssets as ImageSourcePropType
            }
          />
        )
      case 'Tích luỹ cho kế hoạch giáo dục':
        return (
          <Image
            style={styles.tinyImage}
            source={
              imgAccumulateForEducation as ImageSourcePropType
            }
          />
        )
      case 'Bảo vệ trước bệnh hiểm nghèo':
        return (
          <Image
            style={styles.tinyImage}
            source={
               imgProtectFatalDisease as ImageSourcePropType
            }
          />
        )
      case 'Chăm sóc Y tế':
        return (
          <Image
            style={styles.tinyImage}
            source={
              imgMedicineCare as ImageSourcePropType
            }
          />
        )
      case 'Chuẩn bị cho tuổi hưu an nhàn':
        return (
          <Image
            style={styles.tinyImage}
            source={
               imgRetirePlan as ImageSourcePropType
            }
          />
        )
      case 'Đầu tư gia tăng tài sản':
        return (
          <Image
            style={styles.tinyImage}
            source={
             imgInvestRaiseAssets as ImageSourcePropType
            }
          />
        )
      case 'Từ thiện':
        return (
          <Image
            style={styles.tinyImage}
            source={
              imgCharity as ImageSourcePropType
            }
          />
        )
      case 'Để lại thừa kế':
        return (
          <Image
            style={styles.tinyImage}
            source={
               imgInheritances as ImageSourcePropType
            }
          />
        )
      default:
        return ''
    }
  }
  function goToPage(index: number) {
    if (index >= 0 && index <= routes.length - 1) {
      setIndex(index)
    }
  }

  return (
    <View style={[styles.wrapperStyle,containerStyle]}>
      <View style={{ flexDirection: 'row', height: 60, alignItems: 'center' }}>
        <TouchableOpacity onPress={onPressPrev} disabled={index === 0? true : false}  style={{ backgroundColor: 'transparent', borderRadius: 12, padding: 10 }}>
          <View style={{ position: 'relative' }}>
            {
              index !== 0 ?
                <Image
                  style={styles.imageArrowLeft}
                  source={
                    imgArrowLeft  as ImageSourcePropType
                  }
                />
                : null
            }
            <View>{index !== 0 ? renderContentImage(routes[index - 1].title) : <View style={{ width: 46, height: 46 }}></View>}</View>
          </View>
        </TouchableOpacity>
   
        <View style={[styles.boxContent, { borderColor: renderBorder(routes[index]?.code) }]}>
          <View style={[styles.content,switchCase(routes[index].title) === 'active' && styles.contentActive]}>
            {renderContentImage(routes[index].title)}   
            {renderContent(routes[index].key)}
          </View>
      
             {switchCase(routes[index].title) === 'active' ?
                          <>
                            <CheckBox
                              title=''
                              checkedColor={'red'}
                              containerStyle={{padding: 0,margin: 0}}
                              checked={true}
                            />
                            
                          </>
                          :
                         null
                        }
        </View>
        <TouchableOpacity onPress={onPressNext}
         style={{ backgroundColor: 'transparent', borderRadius: 12, padding: 10 }}>

          <View style={{ position: 'relative' }}>
            {
              routes.length - 1 > index ?
                <Image
                  style={styles.imageArrowRight}
                  source={
                    imgArrowRight  as ImageSourcePropType
                  }
                />
                : null
            }

            {routes.length - 1 > index ? renderContentImage(routes[index + 1].title) : <View style={{ width: 46, height: 46 }}></View>}
          </View>
        </TouchableOpacity>
      </View>

    </View>
  )
}

export default TabBarView

const styles = StyleSheet.create({
  wrapperStyle: {
    paddingTop:20,paddingBottom:20,
  },
  tinyLogo: {
    width: 46,
    height: 46,
    marginRight: 12
  },
  boxContent: {
    flex: 1, borderRadius: 12, alignItems: 'center', flexDirection: 'row', margin: 5, fontSize: 12, backgroundColor: '#fff', borderWidth: 1, padding: 7,
    flexWrap: 'nowrap', width: '100%', minHeight: 62,
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    flexDirection : 'row',
    alignItems: 'center'
  }, 
  contentActive: {
    width: '82%',
  },
  contentText: {
    minWidth: 0,
    flexShrink: 1
  },
  tinyImage: {
    width: 46,
    height: 46,
  },
  titleList: {
    fontWeight: '700',
    fontSize: 13,
    paddingLeft: 5,
    color: '#70777E',
  },
  imageArrowRight: {
    width: 15,
    height: 15,
    position: 'absolute',
    top: 16,
    zIndex: 999,
    right: -7
  },
  imageArrowLeft: {
    width: 15,
    height: 15,
    position: 'absolute',
    top: 16,
    zIndex: 999,
    left: -7
  }
})
























