import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import UserInfo from '../model/UserInfo'
import AuthenService from '../services/AuthenService'
import ApiException from '../model/exception/ApiException'
import { parseHttpCode } from '../data/exceptionCode'
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'
import { getUserInfo } from './userSlice'
import { EnvironmentSingleton } from '../config'
export interface AuthenState {
  loading?: boolean
  userInfo?: UserInfo
  error?: ApiException
}

const initialState: AuthenState = {
  loading: false,
  userInfo: undefined,
  error: undefined,
}

export const signIn = createAsyncThunk('authen/signIn', async (req: any, thunkAPI: any) => {
  try {
    const response = await AuthenService.signIn(req.code, req.password, req.captcha)
    await AsyncStorage.setItem('accessToken', response.data.accessToken as string)
    await AsyncStorage.setItem('expiresIn', `${response.data.expiresIn}`)
    await AsyncStorage.setItem('refreshToken', response.data.refreshToken)
    await AsyncStorage.setItem('tokenType', response.data.tokenType)
    await AsyncStorage.setItem('huutriToken', response?.data?.huutriToken)
    await AsyncStorage.setItem('latestSuccessLogInTime', moment().toISOString())
    await AsyncStorage.setItem('logInEfforts', '0')
    thunkAPI.dispatch(getUserInfo({}))
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const authenSlice = createSlice({
  name: 'authen',
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<any>) => {
      state.loading = true
    },
    syncAuthData: (state, action: PayloadAction<any>) => {
      let profile = action.payload.auth
      let env = action.payload.env
      new EnvironmentSingleton().setEnv(env)
      let userInfo = {
        id: profile?.id,
        name: profile?.agentProfile?.bdm?.name,
        accessToken: profile?.token,
        agentCode: profile?.agentCode,
        refreshToken: profile?.refreshToken,
        role: profile?.role,
        profilePicture: profile?.agentProfile?.profilePicture?.url,
      } as UserInfo
      AsyncStorage.setItem('accessToken', profile?.token)
      state.userInfo = userInfo
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      let userInfo = {
        name: action.payload?.name,
        agentCode: action.payload?.sub,
      } as UserInfo
      state.userInfo = userInfo
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false
        console.log(action.error.message)
        let errorMessage = JSON.parse(action.error.message ?? '{}')
        if (errorMessage) {
          let statusCode = errorMessage.statusCode
          errorMessage.code = parseHttpCode(statusCode)
        }
        state.error = errorMessage
      })
  },
})

export const { syncAuthData, setUserInfo } = authenSlice.actions

const { reducer } = authenSlice
export default reducer
