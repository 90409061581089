import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CNA } from '../model/CNA'
import cnaService from '../services/CustomAnalyzeService'

export class CnaList {
  items?: Array<CNA>
  totalPages?: number
  totalRecords?: number
}

export interface CnaListState {
  loading?: boolean
  cnaList?: CnaList
  loadingMore?: boolean
  isHaveMore?: boolean
}

const initialState: CnaListState = {
  loading: false,
  loadingMore: false,
  isHaveMore: true,
  cnaList: {},
}

export const loadCnaListAsync = createAsyncThunk('cna-list/load', async (req: any) => {
  try {
    let response = await cnaService.getListCnaOfAgent(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const loadMoreCnaAsync = createAsyncThunk('cna-list/load-more', async (req: any) => {
  try {
    let response = await cnaService.getListCnaOfAgent(req)
    return response.data
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data))
  }
})

export const viewAllCnaListSlice = createSlice({
  name: 'cna-list',
  initialState,
  reducers: {
    setCnaData: (state, action) => {
      state.loading = false,
      state.loadingMore = false,
      state.isHaveMore = true,
      state.cnaList = action.payload
    },
    setLoadingMore: (state, action) => {
      state.isHaveMore = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCnaListAsync.pending, (state, action: PayloadAction<any>) => {
        state.loading = true
      })
      .addCase(loadCnaListAsync.fulfilled, (state, action) => {
        state.loading = false
        state.cnaList = action.payload
      })
      .addCase(loadMoreCnaAsync.pending, (state, action: PayloadAction<any>) => {
        state.loadingMore = true
      })
      .addCase(loadMoreCnaAsync.fulfilled, (state, action) => {
        // let newData = state.cnaList ?? {}
        // if (!newData?.items) {
        //   newData.items = []
        // }
        // if (action.payload?.items && action.payload?.items?.length > 0) {
        //   newData.items = [...newData.items, ...action.payload.items]
        // } else {
        //   state.isHaveMore = false
        // }
        // state.loadingMore = false
        // state.cnaList = newData
        let newData = state.cnaList ?? {}
        if (!newData?.items) {
          newData.items = []
        }
        if (action.payload?.items && action.payload?.items?.length > 0) {
          if (action.payload.totalRecords > 9){
            newData.items = [...newData.items, ...action.payload.items]
          }else{
            newData.items = [ ...action.payload.items]
          }
        } else {
          state.isHaveMore = false
        }
        const uniqueObjects = [...new Map(newData.items.map(item => [item._id, item])).values()]
        newData.items = uniqueObjects
        state.cnaList = {
          ...newData,
          totalRecords: action.payload.totalRecords ? action.payload.totalRecords : state.cnaList?.totalRecords,
        }
        state.loadingMore = false
      })
  },
})

export const {setCnaData, setLoadingMore} = viewAllCnaListSlice.actions

const { reducer } = viewAllCnaListSlice
export default reducer
