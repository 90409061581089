import Layout from '../constants/Layout'
import * as React from 'react'
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { styles as globalStyles } from '../common'

import { RootStackScreenProps } from '../types'
import AppHeader from 'components/AppHeader'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from 'store'

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.userInfo,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'NotFound'> & PropsFromRedux & {}

function NotFoundScreen({ navigation }: Props) {
  const isMobileDevice = Layout.isMobileDevice

  function handleRouteHome() {
    if (isMobileDevice) {
      navigation.replace('MobileHome')
    } else {
      navigation.replace('AfterLogIn')
    }
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <AppHeader navigation={navigation} />
      <View style={styles.container}>
        <Text style={styles.title}>Trang mà bạn vừa truy cập có thể không tồn tại hoặc đã bị xóa.</Text>
        <TouchableOpacity onPress={() => handleRouteHome()} style={styles.link}>
          <Text style={styles.linkText}>Về lại trang chủ</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  )
}

export default connector(NotFoundScreen)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
})
