import * as React from 'react'
import { SafeAreaView, TouchableOpacity, StyleSheet, ViewStyle, View, ActivityIndicator } from 'react-native'
import { styles } from '../constants/globalStyles'
import { Button } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import Colors from '../constants/Colors'

type IconType = {
  iconName: string
  iconSize: number
  iconColor: string
}

export type Button = {
  title: string
  icon?: IconType | undefined
  prefix?: JSX.Element
  type?: 'solid' | 'clear' | 'outline' | undefined
  buttonStyle?: Object
  containerStyle?: Object
  titleStyle?: Object
  disabled?: boolean
  loading?: boolean
  iconRight?: boolean
  buttonDisableStyle?: Object
}

export type ButtonsProps = {
  button: Button
  btnClick?: () => void
}

export function ButtonComponent({
  button: {
    title,
    titleStyle,
    type,
    buttonStyle,
    containerStyle,
    disabled,
    loading,
    icon,
    iconRight,
    prefix,
    buttonDisableStyle,
  },
  btnClick,
}: ButtonsProps) {
  return (
    <Button
      buttonStyle={[style.defaultBtn, buttonStyle]}
      disabledStyle={[style.disableStyle, buttonDisableStyle]}
      disabled={disabled}
      containerStyle={[style.defaultContainerStyle, containerStyle]}
      // loading={loading}
      icon={
        <>
          {icon && <Icon name={icon.iconName} size={icon.iconSize} color={icon.iconColor} />}
          {loading ? (
            <View style={iconRight ? { marginLeft: 8 } : { marginRight: 8 }}>
              <ActivityIndicator color="#ED1B2E" />
            </View>
          ) : prefix ? (
            prefix
          ) : (
            <></>
          )}
        </>
      }
      iconRight={iconRight}
      title={title}
      titleStyle={[style.defaultTitle, titleStyle]}
      type={type}
      onPress={btnClick}
    />
  )
}

export default ButtonComponent

const style = StyleSheet.create({
  defaultBtn: {
    color: Colors.blackColor,
    // width: 180,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.25,
    shadowRadius: 16,
    borderRadius: 30,
    padding: 12,
  },
  defaultContainerStyle: {
    alignItems: 'center',
  },
  defaultTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
  disableStyle: {
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
    // minWidth: '146px',
    width: 'auto',
    alignItems: 'center',
    borderWidth: 1,
    marginRight: 12,
    borderColor: 'rgb(224, 224, 224)',
    borderRadius: 16,
  },
})
