import * as React from 'react'
import { StyleSheet, Text, Image, View, ImageSourcePropType } from 'react-native'
import { styles as globalStyles } from '../constants/globalStyles'
import icRate from '../../assets/images/rate.png'
import icChart from '../../assets/images/chart.png'
import icCirce from '../../assets/images/circle.png'
import { useMediaQuery } from 'react-responsive'

import TextInput from './TextInput'
import Layout from '../../constants/Layout'

export type InputChartProps = {
  title: string
  quotationKey: string
  value?: number
  onChange: (key: string, value: any) => void
  style?: Record<string, unknown>
  labelStyle?: Record<string, unknown>
  bottomNumber?: number
  type?: 'text' | 'input' | string | undefined
}

export function InputChart({
  title,
  style,
  labelStyle,
  onChange,
  bottomNumber,
  type,
  quotationKey,
  value,
}: InputChartProps) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = Layout.isTabletDevice

  function getConvertValue() {
    if (!value) {
      return ''
    } else {
      return `${value}%`
    }
  }

  if (isMobileDevice) {
    return (
      <View style={[mobileStyle.button, style, value && value > 0 ? mobileStyle.highlight : null]}>
        <Text style={[mobileStyle.label, labelStyle]}>{title}</Text>
        <View style={[mobileStyle.border, type === 'text' ? mobileStyle.textSelect : null]}>
          {value && value > 0 ? (
            <Image source={icRate as ImageSourcePropType} style={mobileStyle.IconChart} />
          ) : (
            <Image source={icChart as ImageSourcePropType} style={mobileStyle.IconChart} />
          )}
          {type === 'input' ? (
            <TextInput
              placeholder={'0%'}
              containerStyle={mobileStyle.Input}
              value={getConvertValue()}
              defaultInputStyle={mobileStyle.InputText}
              defaultInputContainer={mobileStyle.inputStyle}
              onChange={(e) => onChange(quotationKey, e.replace('%', ''))}
            />
          ) : (
            <Text style={mobileStyle.textUpdate}>100%</Text>
          )}
        </View>
        <View style={mobileStyle.bottomBox}>
          <Image source={icCirce as ImageSourcePropType} style={globalStyles.Icon20} />
          <Text style={mobileStyle.textBottom}>{bottomNumber ? bottomNumber : '15,6'}%</Text>
        </View>
      </View>
    )
  }
  if (isTabletDevice) {
    return (
      <View style={[styles.button, style, value && value > 0 ? styles.highlight : null]}>
        <Text style={[styles.labelTablet, labelStyle]}>{title}</Text>
        <View style={[styles.border, type === 'text' ? styles.textSelect : null]}>
          {value && value > 0 ? (
            <Image source={icRate as ImageSourcePropType} style={styles.IconChartTablet} />
          ) : (
            <Image source={icChart as ImageSourcePropType} style={styles.IconChartTablet} />
          )}
          {type === 'input' ? (
            <TextInput
              placeholder={'0%'}
              containerStyle={styles.Input}
              value={getConvertValue()}
              onChange={(e) => onChange(quotationKey, e.replace('%', ''))}
            />
          ) : (
            <Text style={styles.textUpdateTablet}>100%</Text>
          )}
        </View>
        <View style={styles.bottomBox}>
          <Image source={icCirce as ImageSourcePropType} style={globalStyles.Icon20} />
          <Text style={styles.textBottomTablet}>{bottomNumber ? bottomNumber : '15,6'}%</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={[styles.button, style, value && value > 0 ? styles.highlight : null]}>
      <Text style={[styles.label, labelStyle]}>{title}</Text>
      <View style={[styles.border, type === 'text' ? styles.textSelect : null]}>
        {value && value > 0 ? (
          <Image source={icRate as ImageSourcePropType} style={styles.IconChart} />
        ) : (
          <Image source={icChart as ImageSourcePropType} style={styles.IconChart} />
        )}
        {type === 'input' ? (
          <TextInput
            placeholder={'0%'}
            containerStyle={styles.Input}
            value={getConvertValue()}
            onChange={(e) => onChange(quotationKey, e.replace('%', ''))}
          />
        ) : (
          <Text style={styles.textUpdate}>100%</Text>
        )}
      </View>
      <View style={styles.bottomBox}>
        <Image source={icCirce as ImageSourcePropType} style={globalStyles.Icon20} />
        <Text style={styles.textBottom}>{bottomNumber ? bottomNumber : '15,6'}%</Text>
      </View>
    </View>
  )
}

export default InputChart

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#FAFBFF',
    padding: 10,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
    height: 142,
  },
  highlight: {
    backgroundColor: '#FFFFFF',
    shadowColor: 'grey',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 8,
    borderWidth: 0,
  },
  IconChart: {
    height: 40,
    width: 28,
  },
  IconChartTablet: {
    height: 34,
    width: 24,
  },
  label: {
    fontWeight: '600',
    fontSize: 15,
    color: '#505D6F',
  },
  Input: {
    // width: 'calc(100% - 15px)',
    paddingRight: 20
  },
  border: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderBottomWidth: 2,
    borderBottomColor: '#D7DFFF',
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  textSelect: {
    alignItems: 'center',
  },
  bottomBox: {
    marginTop: 15,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelTablet: {
    fontWeight: '600',
    fontSize: 12.4375,
    color: '#505D6F',
  },
  textBottomTablet: {
    fontSize: 12.4375,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  textBottom: {
    fontSize: 15,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  InputTablet: {
    fontSize: 15,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  InputTabletTablet: {
    fontSize: 12.4375,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  textUpdateTablet: {
    fontSize: 12.4375,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  textUpdate: {
    fontSize: 12.4375,
    fontWeight: '700',
    color: '#FF9900',
    textAlign: 'center',
    width: '100%',
  },
})

const mobileStyle = StyleSheet.create({
  button: {
    backgroundColor: '#FAFBFF',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#D7DFFF',
  },
  highlight: {
    backgroundColor: '#FFFFFF',
    shadowColor: 'grey',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 8,
    borderWidth: 0,
  },
  InputText: {
    fontSize: 10,
  },
  IconChart: {
    height: 24,
    width: 17,
  },
  inputStyle: {
    height: 30,
    paddingHorizontal: 10
  },
  label: {
    fontWeight: '600',
    fontSize: 12,
    marginBottom: 10,
    color: '#505D6F',
  },
  Input: {
    // width: 'calc(100% - 15px)',
    paddingRight: 0,
    flexShrink: 1
  },
  border: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: '#D7DFFF',
    paddingBottom: 10,
  },
  textSelect: {
    alignItems: 'center',
  },
  bottomBox: {
    marginTop: 15,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textBottom: {
    fontSize: 12,
    fontWeight: '600',
    color: '#505D6F',
    textAlign: 'center',
    width: '100%',
  },
  textUpdate: {
    fontSize: 12,
    fontWeight: '700',
    color: '#FF9900',
    textAlign: 'center',
    width: '100%',
  },
})
