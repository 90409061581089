import React, { useState, useEffect } from 'react'
import {
  View,
  StyleSheet,
  Text,
  Animated,
  PanResponder,
  Image,
  ImageSourcePropType,
  ImageBackground,
  FlatList,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native'
import { RootStackScreenProps } from '../types'
import {
  styles as globalStyles,
  Block,
  SizedBox,
  Dialog,
  RadioButton,
  FilledButton,
  BackButton,
  StepButton,
} from '../common'
import { useMediaQuery } from 'react-responsive'
import {
  DEFAULT_PRODUCT_TYPE_CODE,
  QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE,
  tabletMediaConfig,
} from '../constants/Constants'
import { productTypes, defaultQuotationFundAllocation, alphabet } from '../constants/Data'
import { SafeAreaView } from 'react-native-safe-area-context'
import i18n from 'i18n-js'
import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import imgCylinderBodyHighlightBlue from '../assets/images/cylinder_body_highlight_blue.png'
import imgCylinderBodyHighlightYellow from '../assets/images/cylinder_body_highlight_yellow.png'
import imgCylinderBody from '../assets/images/cylinder_body.png'
import imgCylinderBottomBlue from '../assets/images/cylinder_bottom_blue.png'
import imgCylinderBottomYellow from '../assets/images/cylinder_bottom_yellow.png'
import imgCylinderCoverBlue from '../assets/images/cylinder_cover_blue.png'
import imgCylinderCoverLightBlue from '../assets/images/cylinder_cover_lightblue.png'
import imgCylinderCoverOrange from '../assets/images/cylinder_cover_orange.png'
import imgConversationBox from '../assets/images/conversation_box.png'
import imgArrowLeftCircle from '../assets/images/arrow-left-circle.png'
import imgArrowLeftCircleDisabled from '../assets/images/arrow-left-circle-disabled.png'
import imgArrowRightCircle from '../assets/images/arrow-right-circle.png'
import imgArrowRightCircleDisabled from '../assets/images/arrow-right-circle-disabled.png'
import imgWomanUsingLaptop from '../assets/images/woman_using_laptop.png'
import { getQuestionnaireList, setAnswer, setProductType, updateAnswers } from '../slices/advisorSlice'
import { saveCNA, getCachedCNA } from '../slices/cnaSlice'
import toastService from '../services/toast/ToastService'
import { useIsFocused } from '@react-navigation/native'
import moment from 'moment'
import StepAppHeader from '../components/StepAppHeader'
import { ViewMode, PageNumberMapping } from '../constants/Enum'
import { CreateCnaPageStep, getStatusToSave } from '../helper/CnaStatusWorkFlow'
import MobileStepIndicator from '../components/MobileStepIndicator'
import CommonAppBar from '../components/CommonAppBar'
import Layout from '../constants/Layout'

const mapStateToProps = (state: RootState) => ({
  questionnaireList: state.advisor.questionnaireList,
  selectedProductType: state.advisor.productType,
  currentHandlingCNA: state?.cna.currentHandlingCNA,
  loadingCnaDetail: state?.cna.loadingCnaDetail,
  loadingSaveDraft: state?.cna.loadingSaveDraft,
  userProtectOriginal: state.user.userProtectOriginal,
  loadingSaveContinue: state?.cna.loadingSaveContinue,
  needsCartList: state.needsCart.needsCartList,
})

const mapDispatchToProps = {
  getQuestionnaireList,
  setAnswer,
  setSelectedProductType: setProductType,
  saveCNA,
  getCachedCNA,
  updateAnswers,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'Advisor'> & PropsFromRedux & {}

function AdvisorScreen({
  route,
  navigation,
  questionnaireList,
  getQuestionnaireList,
  setAnswer,
  setSelectedProductType,
  selectedProductType,
  currentHandlingCNA,
  userProtectOriginal,
  saveCNA,
  loadingCnaDetail,
  getCachedCNA,
  updateAnswers,
  loadingSaveDraft,
  loadingSaveContinue,
  needsCartList,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const isTabletDevice = useMediaQuery(tabletMediaConfig)
  const defaultProductType = productTypes.find((e) => e.value === DEFAULT_PRODUCT_TYPE_CODE)
  const isFocused = useIsFocused()
  const maxChartMoveDistance = isMobileDevice ? 209 : isTabletDevice ? 235 : 300
  const minusAnimatedValue = new Animated.Value(-1)
  const positionRisk = new Animated.Value(-30)
  const positionProfit = new Animated.Value(-50)
  const delta = new Animated.Value(0)
  const pan = new Animated.ValueXY({ x: 0, y: 0 })
  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderGrant: () => {
      pan.setOffset({
        x: (pan.x as any)._value,
        y: (pan.y as any)._value,
      })
    },
    onPanResponderMove: (evt, gestureState) => {
      let newY = gestureState.dy
      if (
        (positionProfit as any)._value + (newY - (delta as any)._value) <= 0 &&
        (positionProfit as any)._value + (newY - (delta as any)._value) >= -maxChartMoveDistance
      ) {
        // ** Handle update animation
        // positionProfit.setValue((positionProfit as any)._value + (newY - (delta as any)._value))
        // let newPositionRisk = (positionRisk as any)._value + (newY - (delta as any)._value)
        // if (
        //   newPositionRisk <= 0 &&
        //   newPositionRisk >= -maxChartMoveDistance / 3 &&
        //   Math.abs((positionProfit as any)._value - (positionRisk as any)._value) === 20
        // ) {
        //   positionRisk.setValue((positionRisk as any)._value + (newY - (delta as any)._value))
        // }
        // delta.setValue(newY)
      }
    },
    onPanResponderRelease: () => {
      delta.setValue(0)
      pan.flattenOffset()
    },
  })

  const [showCancelWarningModel, setShowCancelWarningModel] = useState(false)
  const [showProductTypeModel, setShowProductTypeModel] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [productType, setProductType] = useState<{ value: string; text: string } | undefined>()
  const [currentMobileStackView, setCurrentMobileStackView] = useState(0)
  const [enableContinue, setEnableContinue] = useState(false)
  const [loadingSaveProductType, setLoadingSaveProductType] = useState(false)

  useEffect(() => {
    if (currentHandlingCNA?.product) {
      setProductType(productTypes.find((e) => e.value === currentHandlingCNA?.product) ?? defaultProductType)
    } else {
      setProductType(defaultProductType)
    }
  }, [currentHandlingCNA])

  useEffect(() => {
    // ** Handle show change product type
    // onShowProductType()
  }, [isFocused])

  useEffect(() => {
    if (questionnaireList) {
      if (questionnaireList.length > 0) {
        if (questionnaireList[0].userAnswer === QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE) {
          setEnableContinue(true)
          return
        } else {
          let answeredQuestionList = questionnaireList.filter(
            (e) => e.userAnswer !== null && e.userAnswer !== undefined
          )
          setEnableContinue(answeredQuestionList.length === questionnaireList.length)
          return
        }
      }
      return
    }
  }, [questionnaireList])

  useEffect(() => {
    const id = (route.params as any)?.id
    if (id && id !== 'new') {
      getCachedCNA({ id })
    }
    getQuestionList()
  }, [])

  function onShowProductType() {
    if (isFocused && checkEditable()) {
      if (!currentHandlingCNA?.product || currentHandlingCNA?.product === DEFAULT_PRODUCT_TYPE_CODE) {
        showProductTypeDialog()
      } else {
        hideProductTypeDialog()
      }
      setCurrentQuestionIndex(0)
    } else {
      setShowCancelWarningModel(false)
      hideProductTypeDialog()
    }
  }

  function showProductTypeDialog() {
    setTimeout(() => {
      setShowProductTypeModel(true)
    }, 3000)
  }

  function hideProductTypeDialog() {
    setTimeout(() => {
      setShowProductTypeModel(false)
    }, 1000)
  }

  function checkEditable() {
    return (route.params as any)?.mode !== ViewMode.READ_ONLY
  }

  function getQuestionList() {
    getQuestionnaireList({})
      .unwrap()
      .then(() => updateAnswers(currentHandlingCNA?.questionObjects))
  }

  /**
   * Handle cancel create CNA
   */
  function onCancel() {
    setShowCancelWarningModel(true)
  }

  /**
   * On go to prev question
   *
   * Desktop only
   */
  function onPrevQuestion() {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
  }

  /**
   * On go to next question
   *
   * Desktop only
   */
  function onNextQuestion() {
    if (currentQuestionIndex < (questionnaireList?.length ?? 0) - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  /**
   * Check availability of prev question
   *
   * Desktop only
   */
  function getAvailablePrevQuestion() {
    return currentQuestionIndex > 0
  }

  /**
   * Check availability of next question
   *
   * Desktop only
   */
  function getAvailableNextQuestion() {
    if (!questionnaireList || questionnaireList.length === 0) {
      return false
    }
    if (questionnaireList[0].userAnswer === QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE) {
      return false
    }
    return currentQuestionIndex < questionnaireList.length - 1
  }

  function getAvailableNextQuestionMobile(index: number) {
    if (index === 0) {
      return true
    }
    if (!questionnaireList || questionnaireList.length === 0) {
      return false
    }
    if (!questionnaireList[0].userAnswer || questionnaireList[0].userAnswer === QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE) {
      return false
    }
    return true
  }

  /**
   * Update answer
   * @param questionId
   * @param answerId
   */
  function onSetAnswer(questionIndex?: number, questionId?: string, answer?: string) {
    setAnswer({ questionId, answer })
    if (questionIndex === 0) {
      if (answer === QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE) {
        setEnableContinue(true)
        return
      } else {
        setEnableContinue(false)
        onNextQuestion()
      }
    } else if (questionIndex! < questionnaireList!.length - 1) {
      onNextQuestion()
    }
  }

  function onSubmit() {
    onSaveCna(false, true, true)
      .then((e: any) => {
        if (!e.error) {
          navigation.navigate('Advisor', route.params)
        } else {
          toastService.error(i18n.t('MESS.UNKNOWN_ERROR'))
        }
      })
      .then(() => {
        navigation.push('Solution', route.params)
      })
  }

  function haveChange() {
    return JSON.stringify(currentHandlingCNA) === JSON.stringify(userProtectOriginal)
  }

  function onSaveCna(isSavingDraft?: boolean, isSavingContinue?: boolean, isGenSolutionDate?: boolean) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      isSavingDraft: isSavingDraft,
      isSavingContinue: isSavingContinue,
      lastEditStep: !isSavingDraft ? PageNumberMapping.Solution : PageNumberMapping.Advisor,
      product: selectedProductType?.value,
      status: getStatusToSave(
        currentHandlingCNA?.status,
        CreateCnaPageStep.ADVISOR,
        currentHandlingCNA?.customerPhone,
        isSavingDraft
      ),
    } as any
    if (isGenSolutionDate) {
      body.solutionDate = moment().toISOString()
    }
    if (!body.quotationFundAllocation) {
      body.quotationFundAllocation = defaultQuotationFundAllocation
    }
    if (
      questionnaireList &&
      questionnaireList.length > 0 &&
      questionnaireList[0].userAnswer === QUESTIONNAIRE_KEY_ANSWER_TO_CONTINUE
    ) {
      let temp = [...questionnaireList]
      body.questionObjects = temp?.splice(0, 1)?.map((e) => {
        return { question: e.question, answer: e.userAnswer }
      })
    } else {
      body.questionObjects = questionnaireList
        ?.filter((e) => typeof e.userAnswer === 'string')
        ?.map((e) => {
          return { question: e.question, answer: e.userAnswer }
        })
    }
    return saveCNA(body)
  }

  function onConfirmProductType(productType?: string) {
    let body = {
      ...currentHandlingCNA,
      id: currentHandlingCNA?._id,
      lastEditStep:
        currentHandlingCNA?.lastEditStep && currentHandlingCNA?.lastEditStep > 7 ? currentHandlingCNA?.lastEditStep : 7,
      product: productType,
      topUp: 0,
      solutionDate: moment().toISOString(),
      quotationFundAllocation: defaultQuotationFundAllocation,
    } as any
    body.questionObjects = questionnaireList
      ?.filter((e) => typeof e.userAnswer === 'string')
      ?.map((e) => {
        return { question: e.question, answer: e.userAnswer }
      })
    return saveCNA(body)
  }

  function onGoBack() {
    navigation.replace('NeedsOverall', route.params)
  }

  function renderMobileAppBar() {
    return (
      <CommonAppBar
        title={i18n.t('NEEDS_CART.NEW')}
        navigation={navigation}
        onBack={onGoBack}
        onGoHome={() => onSaveCna(true, false, false)}
      />
    )
  }

  function renderChart() {
    if (isMobileDevice) {
      return (
        <View style={[globalStyles.DisplayFlexRow]}>
          <View style={mobileStyles.chartContainer}>
            <Animated.View
              style={[
                mobileStyles.chartHighlightCover,
                {
                  transform: [{ translateY: positionRisk }],
                },
              ]}
              {...panResponder.panHandlers}
            >
              <Image source={imgCylinderCoverOrange} style={mobileStyles.chartHighlightCover} />
            </Animated.View>
            <Image source={imgCylinderCoverLightBlue} style={mobileStyles.chartCover} />
            <Image source={imgCylinderBody} style={mobileStyles.chartBody} />
            <Animated.Image
              source={imgCylinderBodyHighlightYellow}
              style={[
                mobileStyles.chartBodyHighlight,
                {
                  height: Animated.multiply(positionRisk, minusAnimatedValue),
                },
              ]}
              {...panResponder.panHandlers}
            ></Animated.Image>
            <Image source={imgCylinderBottomYellow} style={mobileStyles.chartBottom} />
            <TouchableOpacity style={mobileStyles.chartLabelContainer}>
              <Text style={mobileStyles.chartLabel}>{i18n.t('COMMON.RISK')}</Text>
            </TouchableOpacity>
          </View>
          <SizedBox width={50} />
          <View style={mobileStyles.chartContainer}>
            <Animated.View
              style={[
                mobileStyles.chartHighlightCover,
                {
                  transform: [{ translateY: positionProfit }],
                },
              ]}
              {...panResponder.panHandlers}
            >
              <Image source={imgCylinderCoverBlue} style={mobileStyles.chartHighlightCover} />
            </Animated.View>
            <Image source={imgCylinderCoverLightBlue} style={mobileStyles.chartCover} />
            <Image source={imgCylinderBody} style={mobileStyles.chartBody} />
            <Animated.Image
              source={imgCylinderBodyHighlightBlue}
              style={[
                mobileStyles.chartBodyHighlight,
                {
                  height: Animated.multiply(positionProfit, minusAnimatedValue),
                },
              ]}
              {...panResponder.panHandlers}
            ></Animated.Image>
            <Image source={imgCylinderBottomBlue} style={mobileStyles.chartBottom} />
            <TouchableOpacity style={mobileStyles.chartLabelContainer}>
              <Text style={mobileStyles.chartLabel}>{i18n.t('COMMON.PROFIT')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      )
    }

    if (isTabletDevice) {
      return (
        <View style={[globalStyles.DisplayFlexRow]}>
          <View style={tabletStyles.chartContainer}>
            <Animated.View
              style={[
                tabletStyles.chartHighlightCover,
                {
                  transform: [{ translateY: positionRisk }],
                },
              ]}
              {...panResponder.panHandlers}
            >
              <Image source={imgCylinderCoverOrange} style={tabletStyles.chartHighlightCover} />
            </Animated.View>
            <Image source={imgCylinderCoverLightBlue} style={tabletStyles.chartCover} />
            <Image source={imgCylinderBody} style={tabletStyles.chartBody} />
            <Animated.Image
              source={imgCylinderBodyHighlightYellow}
              style={[
                tabletStyles.chartBodyHighlight,
                {
                  height: Animated.multiply(positionRisk, minusAnimatedValue),
                },
              ]}
              {...panResponder.panHandlers}
            ></Animated.Image>
            <Image source={imgCylinderBottomYellow} style={tabletStyles.chartBottom} />
            <TouchableOpacity style={tabletStyles.chartLabelContainer}>
              <Text style={tabletStyles.chartLabel}>{i18n.t('COMMON.RISK')}</Text>
            </TouchableOpacity>
          </View>
          <SizedBox width={50} />
          <View style={tabletStyles.chartContainer}>
            <Animated.View
              style={[
                tabletStyles.chartHighlightCover,
                {
                  transform: [{ translateY: positionProfit }],
                },
              ]}
              {...panResponder.panHandlers}
            >
              <Image source={imgCylinderCoverBlue} style={tabletStyles.chartHighlightCover} />
            </Animated.View>
            <Image source={imgCylinderCoverLightBlue} style={tabletStyles.chartCover} />
            <Image source={imgCylinderBody} style={tabletStyles.chartBody} />
            <Animated.Image
              source={imgCylinderBodyHighlightBlue}
              style={[
                tabletStyles.chartBodyHighlight,
                {
                  height: Animated.multiply(positionProfit, minusAnimatedValue),
                },
              ]}
              {...panResponder.panHandlers}
            ></Animated.Image>
            <Image source={imgCylinderBottomBlue} style={tabletStyles.chartBottom} />
            <TouchableOpacity style={tabletStyles.chartLabelContainer}>
              <Text style={tabletStyles.chartLabel}>{i18n.t('COMMON.PROFIT')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      )
    }

    return (
      <View style={[globalStyles.DisplayFlexRow]}>
        <View style={desktopStyles.chartContainer}>
          <Animated.View
            style={[
              desktopStyles.chartHighlightCover,
              {
                transform: [{ translateY: positionRisk }],
              },
            ]}
            {...panResponder.panHandlers}
          >
            <Image source={imgCylinderCoverOrange} style={desktopStyles.chartHighlightCover} />
          </Animated.View>
          <Image source={imgCylinderCoverLightBlue} style={desktopStyles.chartCover} />
          <Image source={imgCylinderBody} style={desktopStyles.chartBody} />
          <Animated.Image
            source={imgCylinderBodyHighlightYellow}
            style={[
              desktopStyles.chartBodyHighlight,
              {
                height: Animated.multiply(positionRisk, minusAnimatedValue),
              },
            ]}
            {...panResponder.panHandlers}
          ></Animated.Image>
          <Image source={imgCylinderBottomYellow} style={desktopStyles.chartBottom} />
          <TouchableOpacity style={desktopStyles.chartLabelContainer}>
            <Text style={desktopStyles.chartLabel}>{i18n.t('COMMON.RISK')}</Text>
          </TouchableOpacity>
        </View>
        <SizedBox width={50} />
        <View style={desktopStyles.chartContainer}>
          <Animated.View
            style={[
              desktopStyles.chartHighlightCover,
              {
                transform: [{ translateY: positionProfit }],
              },
            ]}
            {...panResponder.panHandlers}
          >
            <Image source={imgCylinderCoverBlue} style={desktopStyles.chartHighlightCover} />
          </Animated.View>
          <Image source={imgCylinderCoverLightBlue} style={desktopStyles.chartCover} />
          <Image source={imgCylinderBody} style={desktopStyles.chartBody} />
          <Animated.Image
            source={imgCylinderBodyHighlightBlue}
            style={[
              desktopStyles.chartBodyHighlight,
              {
                height: Animated.multiply(positionProfit, minusAnimatedValue),
              },
            ]}
            {...panResponder.panHandlers}
          ></Animated.Image>
          <Image source={imgCylinderBottomBlue} style={desktopStyles.chartBottom} />
          <TouchableOpacity style={desktopStyles.chartLabelContainer}>
            <Text style={desktopStyles.chartLabel}>{i18n.t('COMMON.PROFIT')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  function renderModals() {
    return (
      <>
        {/* Confirm cancel model */}
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.NOTI'),
            description: i18n.t('MESS.CANCEL_WARNING'),
          }}
          visible={showCancelWarningModel}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.KEEP_CANCEL')}
          cancelText={i18n.t('COMMON.CONTINUE')}
          onOk={() => {
            setShowCancelWarningModel(false)
            navigation.navigate('MobileHome')
          }}
          onCancel={() => {
            setShowCancelWarningModel(false)
          }}
        >
          <Text></Text>
        </Dialog>
        {/* Change product type modal  */}
        <Dialog
          type="default"
          visible={showProductTypeModel}
          contentStyle={{ padding: 0 }}
          dialogStyle={{ borderRadius: 12, maxWidth: 400 }}
        >
          <View style={globalStyles.Center}>
            <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
              <Text style={[globalStyles.DialogTitle, { paddingHorizontal: 10 }]}>
                {i18n.t('ADVISOR_SCREEN.CUSTOMER_INVESTMENT_EXPECTATION')}
              </Text>
            </View>
            <View style={[{ paddingHorizontal: isMobileDevice ? 0 : 20 }]}>
              <RadioButton
                title=""
                data={productTypes}
                direction="column"
                containerStyle={{ alignItems: 'flex-start', marginTop: Platform.OS === 'web' ? 15 : 0 }}
                dafaultValue={productType?.value}
                onChange={(key: string) => setProductType(productTypes.find((e) => e.value === key))}
                radioItemContainerStyle={{ maxWidth: 'none' }}
              />
            </View>
            <FilledButton
              title={i18n.t('COMMON.CONFIRM')}
              onPress={() => {
                setSelectedProductType(productType)
                setLoadingSaveProductType(true)
                onConfirmProductType(productType?.value).then((data) => {
                  setLoadingSaveProductType(false)
                  if (productType?.value === DEFAULT_PRODUCT_TYPE_CODE) {
                    hideProductTypeDialog()
                    setTimeout(() => {
                      navigation.navigate('Solution', route.params)
                    }, 100)
                  } else {
                    getQuestionList()
                    setTimeout(() => {
                      hideProductTypeDialog()
                    }, 100)
                  }
                })
              }}
              buttonStyle={mobileStyles.confirmButton}
              titleStyle={{ fontSize: 14 }}
              disabled={loadingCnaDetail}
              loading={loadingSaveProductType}
            />
          </View>
        </Dialog>
      </>
    )
  }

  function renderMobileFragmentStackView() {
    return [
      // Render title
      <View style={globalStyles.DisplayFlexCol}>
        <SizedBox height={20} />
        <View style={mobileStyles.headerContainer}>
          <Text style={mobileStyles.headerLabel}>{i18n.t('ADVISOR_SCREEN.INDEX')}</Text>
        </View>
        <View style={mobileStyles.titleContainer}>
          {/* <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
            <TouchableOpacity
              onPress={() => showProductTypeDialog()}
              style={mobileStyles.productTypeContainer}
              disabled={!checkEditable()}
            >
              <Text style={mobileStyles.productTypeLabel}>{i18n.t('ADVISOR_SCREEN.PRODUCT_TYPE')}:</Text>
              <Text style={mobileStyles.productTypeValue}>{selectedProductType?.value}</Text>
              <Image source={ImgArrowDownCircleLight} style={globalStyles.Icon25} />
            </TouchableOpacity>
          </View> */}
          <View style={mobileStyles.titlePromoContainer}>
            <Text style={mobileStyles.titlePromo}>{i18n.t('ADVISOR_SCREEN.TITLE')}</Text>
          </View>
          <View style={mobileStyles.imgIllustrationContainer}>
            <ImageBackground source={imgConversationBox as ImageSourcePropType} style={mobileStyles.questionContainer}>
              <Text style={mobileStyles.question}>{i18n.t('ADVISOR_SCREEN.SUBTITLE')}</Text>
            </ImageBackground>
            <Image source={imgWomanUsingLaptop} style={mobileStyles.imgIllustration} />
          </View>
        </View>
        <View style={{ marginTop: 100, justifyContent: 'center', width: '100%', flexDirection: 'row' }}>
          <FilledButton
            title={i18n.t('COMMON.UNDERSTOOD')}
            onPress={() => {
              setCurrentMobileStackView(1)
            }}
            buttonStyle={mobileStyles.confirmButton}
            titleStyle={{ fontSize: 14 }}
          />
        </View>
        <SizedBox height={150} />
      </View>,
      //   Render chart
      // <View style={[globalStyles.DisplayFlexCol, globalStyles.Center]}>
      //   <View style={mobileStyles.backButtonContainer}>
      //     <BackButton title={i18n.t('COMMON.BACK')} onPress={() => setCurrentMobileStackView(0)} />
      //   </View>
      //   <View style={mobileStyles.headerContainer}>
      //     <Text style={mobileStyles.headerLabel}>{i18n.t('ADVISOR_SCREEN.INDEX')}</Text>
      //   </View>
      //   <View style={[globalStyles.DisplayFlexRow, globalStyles.Center, { display: 'none' }]}>
      //     <Text style={{ fontSize: 14, fontWeight: '600', color: '#505D6F', textAlign: 'center', marginBottom: 10 }}>
      //       {i18n.t('ADVISOR_SCREEN.DIRECTION')}
      //     </Text>
      //   </View>
      //   <View style={[mobileStyles.chart, { display: 'none' }]}>{renderChart()}</View>
      //   <TouchableOpacity onPress={() => setCurrentMobileStackView(2)} style={mobileStyles.buttonDoSurvey}>
      //     <Text>{i18n.t('ADVISOR_SCREEN.INVESTMENT_POINT_OF_VIEW_QUESTION')}</Text>
      //     <Image
      //       source={icArrowRightCircleRed as ImageSourcePropType}
      //       style={[globalStyles.Icon20, { marginLeft: 8 }]}
      //     />
      //   </TouchableOpacity>
      // </View>,
      //   Render questionnaire
      <View>
        <View style={mobileStyles.backButtonContainer}>
          <BackButton
            title={i18n.t('COMMON.BACK')}
            onPress={() => setCurrentMobileStackView(currentMobileStackView - 1)}
          />
        </View>
        <View style={mobileStyles.headerContainer}>
          <Text style={mobileStyles.surveyTitle}>{i18n.t('ADVISOR_SCREEN.INVESTMENT_POINT_OF_VIEW')}</Text>
        </View>
        <View style={mobileStyles.surveyContainer} pointerEvents={checkEditable() ? 'auto' : 'none'}>
          <View>
            {
              <FlatList
                data={questionnaireList ? questionnaireList : []}
                numColumns={1}
                renderItem={(item) => {
                  return (
                    <View
                      style={[
                        mobileStyles.choicesContainer,
                        { display: getAvailableNextQuestionMobile(item.index) ? 'flex' : 'none' },
                      ]}
                    >
                      <Text>{item.item.question}</Text>
                      <FlatList
                        data={item.item.answer}
                        style={mobileStyles.choicesList}
                        numColumns={1}
                        renderItem={(item2) => {
                          return (
                            <TouchableOpacity
                              style={[
                                globalStyles.DisplayFlexRow,
                                mobileStyles.choice,
                                item.item.userAnswer === item2.item ? mobileStyles.choiceHighlight : {},
                              ]}
                              onPress={() => onSetAnswer(item.index, item.item._id, item2.item)}
                            >
                              <Text
                                style={[
                                  item.item.userAnswer === item2.item
                                    ? mobileStyles.choiceHighlightLabel
                                    : mobileStyles.choiceLabel,
                                  { fontWeight: 'bold' },
                                ]}
                              >
                                {`${alphabet[item2.index]}. `}
                              </Text>
                              <Text
                                style={
                                  item.item.userAnswer === item2.item
                                    ? mobileStyles.choiceHighlightLabel
                                    : mobileStyles.choiceLabel
                                }
                              >
                                {`${item2.item}`}
                              </Text>
                            </TouchableOpacity>
                          )
                        }}
                      />
                    </View>
                  )
                }}
              />
            }
          </View>
        </View>
        <View
          style={[globalStyles.DisplayFlexRow, globalStyles.Center, globalStyles.Width100]}
        >
          <FilledButton
            title={i18n.t('COMMON.CONTINUE')}
            onPress={onSubmit}
            buttonStyle={mobileStyles.confirmButton}
            titleStyle={{ fontSize: 14 }}
            loading={loadingSaveContinue}
            disabled={!enableContinue}
          />
        </View>
        <SizedBox height={150} />
      </View>,
    ]
  }

  if (isMobileDevice) {
    return (
      <SafeAreaView
        style={[globalStyles.mobileContainerSafeArea, globalStyles.PositionRelative]}
        edges={['right', 'left', 'top']}
      >
        <View style={globalStyles.mobileContent}>
          {renderMobileAppBar()}
          <ScrollView contentContainerStyle={[mobileStyles.contentContainer]}>
            {renderMobileFragmentStackView()[currentMobileStackView]}
          </ScrollView>
          <View style={[globalStyles.PositionAbsolute, mobileStyles.stepIndicatorContainer]}>
            <MobileStepIndicator
              route={route}
              navigation={navigation}
              cnaData={currentHandlingCNA}
              needsCartList={needsCartList}
              currentPosition={2}
              loadingSaveDraft={loadingSaveDraft}
              onSaveDraft={() => onSaveCna(true, false, false)}
            />
          </View>
        </View>
        {renderModals()}
      </SafeAreaView>
    )
  }

  if (isTabletDevice) {
    return (
      <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]} edges={['right', 'left', 'top']}>
        <StepAppHeader
          route={route}
          navigation={navigation}
          currentPosition={2}
          onSaveDraft={() => onSaveCna(true, false, false)}
          cnaData={currentHandlingCNA}
        />
        <View style={[tabletStyles.container]}>
          <View style={tabletStyles.content}>
            <View style={tabletStyles.headerContainer}>
              <Text style={tabletStyles.headerLabel}>{i18n.t('ADVISOR_SCREEN.INDEX')}</Text>
            </View>
            <View style={tabletStyles.titleContainer}>
              <View style={tabletStyles.titlePromoContainer}>
                <Text style={tabletStyles.titlePromo}>{i18n.t('ADVISOR_SCREEN.TITLE')}</Text>
                <Text style={tabletStyles.subtitlePromo}>{i18n.t('ADVISOR_SCREEN.SUBTITLE')}</Text>
              </View>
              {/* <TouchableOpacity onPress={() => showProductTypeDialog()} disabled={!checkEditable()}>
                <View style={tabletStyles.productTypeContainer}>
                  <Text style={tabletStyles.productTypeLabel}>{i18n.t('ADVISOR_SCREEN.PRODUCT_TYPE')}:</Text>
                  <Text style={tabletStyles.productTypeValue}>{selectedProductType?.value}</Text>
                  <Image source={ImgArrowDownCircleLight} style={globalStyles.Icon25} />
                </View>
              </TouchableOpacity> */}
            </View>
            <View style={tabletStyles.contentContainer} pointerEvents={checkEditable() ? 'auto' : 'none'}>
              {/* <View>
                    <View style={tabletStyles.chart}>{renderChart()}</View>
                  </View> */}
              <View style={tabletStyles.surveyContainer}>
                <Text style={tabletStyles.surveyTitle}>{i18n.t('ADVISOR_SCREEN.INVESTMENT_POINT_OF_VIEW')}</Text>
                <View>
                  <View style={globalStyles.DisplayFlexRow}>
                    <View>
                      <ImageBackground
                        source={imgConversationBox as ImageSourcePropType}
                        style={tabletStyles.questionContainer}
                      >
                        <Text style={tabletStyles.question}>
                          {questionnaireList &&
                            questionnaireList.length > 0 &&
                            questionnaireList[currentQuestionIndex].question}
                        </Text>
                      </ImageBackground>
                      <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
                        <TouchableOpacity onPress={onPrevQuestion} disabled={!getAvailablePrevQuestion()}>
                          <Image
                            source={
                              (getAvailablePrevQuestion()
                                ? imgArrowLeftCircle
                                : imgArrowLeftCircleDisabled) as ImageSourcePropType
                            }
                            style={tabletStyles.imgNavigation}
                          />
                        </TouchableOpacity>
                        <SizedBox width={16} />
                        <TouchableOpacity onPress={onNextQuestion} disabled={!getAvailableNextQuestion()}>
                          <Image
                            source={
                              (getAvailableNextQuestion()
                                ? imgArrowRightCircle
                                : imgArrowRightCircleDisabled) as ImageSourcePropType
                            }
                            style={tabletStyles.imgNavigation}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View style={tabletStyles.imgIllustrationContainer}>
                      <Image source={imgWomanUsingLaptop} style={tabletStyles.imgIllustration} />
                    </View>
                  </View>
                  <View style={tabletStyles.choicesContainer}>
                    <FlatList
                      data={
                        questionnaireList && questionnaireList.length > 0 && questionnaireList[currentQuestionIndex]
                          ? questionnaireList[currentQuestionIndex].answer
                          : []
                      }
                      style={tabletStyles.choicesList}
                      numColumns={2}
                      renderItem={(item) => {
                        return (
                          <View
                            style={{
                              width: '50%',
                              paddingLeft: item.index % 2 === 1 ? 9 : 0,
                              paddingRight: item.index % 2 === 0 ? 9 : 0,
                            }}
                          >
                            <TouchableOpacity
                              style={[
                                tabletStyles.choice,
                                questionnaireList && questionnaireList[currentQuestionIndex].userAnswer === item.item
                                  ? tabletStyles.choiceHighlight
                                  : {},
                              ]}
                              onPress={() =>
                                onSetAnswer(
                                  currentQuestionIndex,
                                  questionnaireList![currentQuestionIndex]?._id,
                                  item.item
                                )
                              }
                            >
                              <Text
                                style={
                                  questionnaireList && questionnaireList[currentQuestionIndex].userAnswer === item.item
                                    ? tabletStyles.choiceHighlightLabel
                                    : tabletStyles.choiceLabel
                                }
                              >
                                {`${item.item}`}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
            <StepButton
              onBack={onGoBack}
              onNext={onSubmit}
              styleButton={{ marginTop: 39 }}
              disabledOnNext={!enableContinue}
              loadingOnNext={loadingSaveContinue}
            />
            <SizedBox height={32.39} />
          </View>
        </View>
        {renderModals()}
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={[globalStyles.container, globalStyles.PositionRelative]}>
      <StepAppHeader
        route={route}
        navigation={navigation}
        currentPosition={2}
        onSaveDraft={() => onSaveCna(true, false, false)}
        cnaData={currentHandlingCNA}
        needsCartList={needsCartList}
      />
      <ScrollView
        contentContainerStyle={globalStyles.content}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <Block
          child={
            <View style={desktopStyles.content}>
              <View style={desktopStyles.headerContainer}>
                <Text style={desktopStyles.headerLabel}>{i18n.t('ADVISOR_SCREEN.INDEX')}</Text>
              </View>
              <View style={desktopStyles.titleContainer}>
                <View style={desktopStyles.titlePromoContainer}>
                  <Text style={desktopStyles.titlePromo}>{i18n.t('ADVISOR_SCREEN.TITLE')}</Text>
                  <Text style={desktopStyles.subtitlePromo}>{i18n.t('ADVISOR_SCREEN.SUBTITLE')}</Text>
                </View>
                {/* ** Button change product type, do not delete */}
                {/* <TouchableOpacity onPress={() => showProductTypeDialog()} disabled={!checkEditable()}>
                  <View style={desktopStyles.productTypeContainer}>
                    <Text style={desktopStyles.productTypeLabel}>{i18n.t('ADVISOR_SCREEN.PRODUCT_TYPE')}:</Text>
                    <Text style={desktopStyles.productTypeValue}>{selectedProductType?.value}</Text>
                    <Image source={ImgArrowDownCircleLight} style={globalStyles.Icon25} />
                  </View>
                </TouchableOpacity> */}
              </View>
              <View style={desktopStyles.contentContainer} pointerEvents={checkEditable() ? 'auto' : 'none'}>
                {/* TODO: */}
                {/* <View>
                  <View style={desktopStyles.chart}>{renderChart()}</View>
                </View> */}
                <View style={desktopStyles.surveyContainer}>
                  <View>
                    <View style={globalStyles.DisplayFlexRow}>
                      <View style={[globalStyles.DisplayFlexCol, globalStyles.Center, { zIndex: 10 }]}>
                        <Text style={desktopStyles.surveyTitle}>
                          {i18n.t('ADVISOR_SCREEN.INVESTMENT_POINT_OF_VIEW')}
                        </Text>
                        <ImageBackground
                          source={imgConversationBox as ImageSourcePropType}
                          style={desktopStyles.questionContainer}
                        >
                          <Text style={desktopStyles.question}>
                            {questionnaireList &&
                              questionnaireList.length > 0 &&
                              questionnaireList[currentQuestionIndex].question}
                          </Text>
                        </ImageBackground>
                        <SizedBox height={20} />
                        <View style={[globalStyles.DisplayFlexRow, globalStyles.Center]}>
                          <SizedBox width={100} />
                          <TouchableOpacity onPress={onPrevQuestion} disabled={!getAvailablePrevQuestion()}>
                            <Image
                              source={
                                (getAvailablePrevQuestion()
                                  ? imgArrowLeftCircle
                                  : imgArrowLeftCircleDisabled) as ImageSourcePropType
                              }
                              style={desktopStyles.imgNavigation}
                            />
                          </TouchableOpacity>
                          <SizedBox width={16} />
                          <TouchableOpacity onPress={onNextQuestion} disabled={!getAvailableNextQuestion()}>
                            <Image
                              source={
                                (getAvailableNextQuestion()
                                  ? imgArrowRightCircle
                                  : imgArrowRightCircleDisabled) as ImageSourcePropType
                              }
                              style={desktopStyles.imgNavigation}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={desktopStyles.imgIllustrationContainer}>
                        <Image source={imgWomanUsingLaptop} style={desktopStyles.imgIllustration} />
                      </View>
                    </View>
                    <View style={desktopStyles.choicesContainer}>
                      <FlatList
                        data={
                          questionnaireList && questionnaireList.length > 0 && questionnaireList[currentQuestionIndex]
                            ? questionnaireList[currentQuestionIndex].answer
                            : []
                        }
                        // style={desktopStyles.choicesList}
                        numColumns={2}
                        renderItem={(item) => {
                          return (
                            <View
                              style={{
                                width: '50%',
                                paddingLeft: item.index % 2 === 1 ? 9 : 0,
                                paddingRight: item.index % 2 === 0 ? 9 : 0,
                              }}
                            >
                              <TouchableOpacity
                                style={[
                                  desktopStyles.choice,
                                  questionnaireList && questionnaireList[currentQuestionIndex].userAnswer === item.item
                                    ? desktopStyles.choiceHighlight
                                    : {},
                                ]}
                                onPress={() =>
                                  onSetAnswer(
                                    currentQuestionIndex,
                                    questionnaireList![currentQuestionIndex]?._id,
                                    item.item
                                  )
                                }
                              >
                                <Text
                                  style={
                                    questionnaireList &&
                                    questionnaireList[currentQuestionIndex].userAnswer === item.item
                                      ? desktopStyles.choiceHighlightLabel
                                      : desktopStyles.choiceLabel
                                  }
                                >
                                  {`${item.item}`}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )
                        }}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <StepButton
                onBack={onGoBack}
                onNext={onSubmit}
                styleButton={{ marginTop: 39 }}
                disabledOnNext={!enableContinue}
                loadingOnNext={loadingSaveContinue}
              />
              <SizedBox height={32.39} />
            </View>
          }
        />
      </ScrollView>
      {renderModals()}
    </SafeAreaView>
  )
}

export default connector(AdvisorScreen)

const desktopStyles = StyleSheet.create({
  container: {
    padding: 16,
    paddingTop: 72,
    overflow: 'scroll',
    height: '100%',
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 8,
  },
  headerContainer: {
    backgroundColor: '#ED1B2E',
    borderRadius: 12,
    padding: 16,
  },
  headerLabel: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '700',
  },
  titleContainer: {
    padding: 33,
    flexDirection: 'row',
  },
  contentContainer: {
    padding: 37,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    marginHorizontal: 8,
    borderRadius: 20,
    borderWidth: 2,
  },
  chart: {
    paddingHorizontal: 33,
  },
  surveyContainer: {
    maxWidth: 889,
    marginLeft: 87,
    flex: 1,
  },
  box: {
    height: 150,
    width: 150,
    backgroundColor: 'blue',
    borderRadius: 5,
  },
  chartContainer: {
    width: 135,
    height: 380,
    position: 'relative',
  },
  chartCover: {
    top: 0,
    left: 0,
    right: 0,
    width: 135,
    height: 80,
    zIndex: 3,
    position: 'absolute',
  },
  chartBody: {
    top: 40,
    left: 0,
    right: 0,
    width: 135,
    bottom: 18,
    zIndex: 0,
    position: 'absolute',
  },
  chartBodyHighlight: {
    bottom: 38,
    left: 0,
    right: 0,
    width: 135,
    height: 40,
    zIndex: 1,
    position: 'absolute',
  },
  chartHighlightCover: {
    width: 135,
    height: 80,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    position: 'absolute',
  },
  chartBottom: {
    bottom: 2,
    left: 0,
    right: 0,
    width: 135,
    height: 38,
    zIndex: 0,
    position: 'absolute',
  },
  chartLabelContainer: {
    position: 'absolute',
    bottom: -440,
    left: 0,
    right: 0,
    backgroundColor: '#EDF2F4',
    borderRadius: 12,
    width: 135,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B2D42',
  },
  questionContainer: {
    width: 336,
    height: 196,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 30,
    paddingTop: 60,
    top: 30,
    right: -70,
    zIndex: 10,
  },
  question: {
    fontSize: 16,
    fontWeight: '600',
    color: '#D62828',
  },
  imgNavigation: {
    width: 34,
    height: 34,
  },
  imgIllustrationContainer: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
  },
  imgIllustration: {
    width: 439,
    height: 323,
    resizeMode: 'contain',
  },
  surveyTitle: {
    fontSize: 16,
    fontWeight: '700',
    paddingLeft: 120,
  },
  choicesContainer: {
    marginTop: 42,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  choicesList: {
    width: '100%',
  },
  choice: {
    flex: 1,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#F5F5F5',
    paddingVertical: 15,
    paddingHorizontal: 32,
    marginTop: 18,
  },
  choiceLabel: {
    fontSize: 15,
  },
  choiceHighlight: {
    backgroundColor: '#FFE6E2',
  },
  choiceHighlightLabel: {
    color: '#FC573B',
    fontSize: 15,
    fontWeight: 'bold',
  },
  titlePromoContainer: {
    flex: 1,
  },
  titlePromo: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#ED1B2E',
  },
  subtitlePromo: {
    fontWeight: '600',
    fontSize: 15,
    color: '#545454',
    marginTop: 12,
  },
  productTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 22,
    paddingVertical: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    backgroundColor: '#FAFBFF',
    borderRadius: 50,
    justifyContent: 'center',
  },
  productTypeLabel: {
    fontSize: 16,
  },
  productTypeValue: {
    fontSize: 16,
    fontWeight: '600',
    marginHorizontal: 8,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#000000AA',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  confirmButton: {
    width: 100,
    height: 41,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 0,
  },
})

const tabletStyles = StyleSheet.create({
  container: {
    overflow: 'scroll',
    paddingTop: 72,
    paddingHorizontal: 16,
    paddingBottom: 30,
    height: '100%',
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 8,
    overflow: 'scroll',
  },
  headerContainer: {
    backgroundColor: '#ED1B2E',
    borderRadius: 12,
    padding: 16,
  },
  headerLabel: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '700',
  },
  titleContainer: {
    padding: 33,
    flexDirection: 'row',
  },
  contentContainer: {
    paddingVertical: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  chart: {
    paddingHorizontal: 40,
  },
  surveyContainer: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    padding: 30,
    borderRadius: 20,
    marginHorizontal: 20,
    flex: 1,
    maxWidth: 744,
  },
  box: {
    height: 150,
    width: 150,
    backgroundColor: 'blue',
    borderRadius: 5,
  },
  chartContainer: {
    width: 112,
    height: 315,
    position: 'relative',
  },
  chartCover: {
    top: 0,
    left: 0,
    right: 0,
    width: 112,
    height: 80,
    zIndex: 3,
    position: 'absolute',
    resizeMode: 'contain',
  },
  chartBody: {
    top: 40,
    left: 0,
    right: 0,
    width: 112,
    bottom: 18,
    zIndex: 0,
    position: 'absolute',
  },
  chartBodyHighlight: {
    bottom: 38,
    left: 0,
    right: 0,
    width: 112,
    height: 40,
    zIndex: 1,
    position: 'absolute',
  },
  chartHighlightCover: {
    width: 112,
    height: 80,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    position: 'absolute',
    resizeMode: 'contain',
  },
  chartBottom: {
    bottom: 2,
    left: 0,
    right: 0,
    width: 112,
    height: 44,
    zIndex: 0,
    position: 'absolute',
    resizeMode: 'contain',
  },
  chartLabelContainer: {
    position: 'absolute',
    bottom: -440,
    left: 0,
    right: 0,
    backgroundColor: '#EDF2F4',
    borderRadius: 12,
    width: 112,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B2D42',
  },
  questionContainer: {
    width: 278,
    height: 162,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 30,
    paddingTop: 44,
    top: 0,
  },
  question: {
    fontSize: 16,
    fontWeight: '600',
    color: '#D62828',
  },
  imgNavigation: {
    width: 34,
    height: 34,
  },
  imgIllustrationContainer: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
  },
  imgIllustration: {
    width: 282,
    height: 192,
    resizeMode: 'contain',
  },
  surveyTitle: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 30,
  },
  choicesContainer: {
    marginTop: 42,
    flexDirection: 'row',
    width: '100%',
  },
  choicesList: {},
  choice: {
    flex: 1,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#F5F5F5',
    paddingVertical: 15,
    paddingHorizontal: 32,
    marginTop: 18,
  },
  choiceLabel: {
    fontSize: 15,
  },
  choiceHighlight: {
    backgroundColor: '#FFE6E2',
  },
  choiceHighlightLabel: {
    color: '#FC573B',
    fontSize: 15,
    fontWeight: 'bold',
  },
  titlePromoContainer: {
    flex: 1,
  },
  titlePromo: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#ED1B2E',
  },
  subtitlePromo: {
    fontWeight: '600',
    fontSize: 15,
    color: '#545454',
  },
  productTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 22,
    paddingVertical: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    backgroundColor: '#FAFBFF',
    borderRadius: 50,
    justifyContent: 'center',
  },
  productTypeLabel: {
    fontSize: 16,
  },
  productTypeValue: {
    fontSize: 16,
    fontWeight: '600',
    marginHorizontal: 8,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#000000AA',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  confirmButton: {
    width: 100,
    height: 41,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 0,
  },
})

const mobileStyles = StyleSheet.create({
  stepIndicatorContainer: {
    bottom: 16,
    left: 16,
    right: 16,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  container: {
    padding: 0,
    paddingTop: 56,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16,
    width: '100%',
    height: '100%',
  },
  headerContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    color: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 16,
    elevation: 16,
    marginBottom: 20,
  },
  headerLabel: {
    color: '#505D6F',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  titleContainer: {
    padding: 0,
    flexDirection: 'column',
    position: 'relative',
  },
  contentContainer: {
    paddingVertical: 0,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    padding: 0,
    paddingBottom: 0,
    marginBottom: 0,
    overflow: 'scroll',
  },
  chart: {},
  surveyContainer: {
    padding: 0,
  },
  box: {
    height: 150,
    width: 150,
    backgroundColor: 'blue',
    borderRadius: 5,
  },
  chartContainer: {
    width: 110,
    height: 290,
    position: 'relative',
  },
  chartCover: {
    top: 0,
    left: 0,
    right: 0,
    width: 110,
    height: 80,
    zIndex: 3,
    position: 'absolute',
    resizeMode: 'contain',
  },
  chartBody: {
    top: 40,
    left: 0,
    right: 0,
    width: 110,
    height: 228,
    bottom: 18,
    zIndex: 0,
    position: 'absolute',
  },
  chartBodyHighlight: {
    bottom: 38,
    left: 0,
    right: 0,
    width: 110,
    height: 40,
    zIndex: 1,
    position: 'absolute',
  },
  chartHighlightCover: {
    width: 110,
    height: 80,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    position: 'absolute',
    resizeMode: 'contain',
  },
  chartBottom: {
    bottom: 2,
    left: 0,
    right: 0,
    width: 110,
    height: 45,
    zIndex: 0,
    position: 'absolute',
    resizeMode: 'contain',
  },
  chartLabelContainer: {
    position: 'absolute',
    bottom: -1000,
    left: 0,
    right: 0,
    backgroundColor: '#EDF2F4',
    borderRadius: 12,
    width: 110,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B2D42',
  },
  questionContainer: {
    width: 231,
    height: 136,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 30,
    paddingTop: 35,
    position: 'absolute',
    top: 30,
    zIndex: 1000,
    left: 0,
  },
  question: {
    fontSize: 14,
    fontWeight: '600',
    color: '#505D6F',
  },
  imgNavigation: {
    width: 34,
    height: 34,
  },
  imgIllustrationContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
    marginTop: 30,
    height: 257,
  },
  imgIllustration: {
    position: 'absolute',
    width: 264,
    height: 178,
    top: 130,
    right: 0,
    resizeMode: 'contain',
  },
  surveyTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: '#000000',
  },
  choicesContainer: {
    marginBottom: 32,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  choicesList: {},
  choice: {
    flex: 1,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#E6E6E6',
    paddingVertical: 15,
    paddingHorizontal: 32,
    marginTop: 18,
  },
  choiceLabel: {
    fontSize: 15,
    color: '#000000',
  },
  choiceHighlight: {
    backgroundColor: '#FFE6E2',
  },
  choiceHighlightLabel: {
    color: '#FC573B',
    fontSize: 15,
    fontWeight: 'bold',
  },
  titlePromoContainer: {
    marginTop: 30,
  },
  titlePromo: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ED1B2E',
    textAlign: 'center',
  },
  subtitlePromo: {
    fontWeight: '600',
    fontSize: 15,
    color: '#545454',
  },
  productTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 22,
    paddingVertical: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    backgroundColor: '#FAFBFF',
    borderRadius: 50,
  },
  productTypeLabel: {
    fontSize: 16,
  },
  productTypeValue: {
    fontSize: 16,
    fontWeight: '600',
    marginHorizontal: 8,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#000000AA',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  confirmButton: {
    minWidth: 120,
    height: 41,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 0,
  },
  buttonDoSurvey: {
    marginTop: 85,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D7DFFF',
    backgroundColor: '#FFFFFF',
    borderRadius: 25,
    marginBottom: 150,
    flexDirection: 'row',
    padding: 11,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: 16,
    marginTop: 20,
    width: '100%',
  },
})
