import React, { useState } from 'react'
import { StyleSheet, Text, View, Image, ImageSourcePropType, TouchableOpacity } from 'react-native'
import icArrowLeftCircle from '../../assets/icon/arrow-left-circle.png'
import icArrowRightCircle from '../../assets/icon/arrow-right-circle.png'
import icArrowLeftCircleDisabled from '../../assets/icon/arrow-left-circle-disabled.png'
import icArrowRightCircleDisabled from '../../assets/icon/arrow-right-circle-disabled.png'
import { genders } from '../../constants/Data'
import Layout from '../../constants/Layout'

export type GenderSwitchProps = {
  value?: string
  onChange?: (value: string) => void
}

export type Gender = {
  code: string
  name: string
  thumbnail: any
}

const data: Array<Gender> = genders

export function GenderSwitch({ value, onChange }: GenderSwitchProps) {
  const isMobileDevice = Layout.isMobileDevice
  const [currentIndex, setCurrentIndex] = useState<number>(value ? data.findIndex((e) => e.code === value) : 0)
  function onSwitch(index: number) {
    if (index >= 0 && index <= data.length - 1) {
      setCurrentIndex(index)
      onChange && onChange(data[index]?.code)
    }
  }

  React.useEffect(() => {
    setCurrentIndex(value ? data.findIndex((e) => e.code === value) : 0)
  }, [value])

  if (isMobileDevice) {
    return (
      <View style={mobileStyles.container}>
        <TouchableOpacity onPress={() => onSwitch(currentIndex - 1)} disabled={currentIndex <= 0}>
          <Image
            source={(currentIndex > 0 ? icArrowLeftCircle : icArrowLeftCircleDisabled) as ImageSourcePropType}
            style={mobileStyles.icon}
          />
        </TouchableOpacity>
        <View style={mobileStyles.carouselContainer}>
          <View style={[mobileStyles.container]}>
            {data && data.length > 0 && (
              <View style={mobileStyles.genderItem}>
                <Image source={data[currentIndex]?.thumbnail as ImageSourcePropType} style={mobileStyles.image} />
                <Text style={mobileStyles.genderTitle}>{data[currentIndex]?.name}</Text>
              </View>
            )}
          </View>
        </View>
        <TouchableOpacity onPress={() => onSwitch(currentIndex + 1)} disabled={currentIndex >= data.length - 1}>
          <Image
            source={
              (currentIndex < data.length - 1 ? icArrowRightCircle : icArrowRightCircleDisabled) as ImageSourcePropType
            }
            style={mobileStyles.icon}
          />
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => onSwitch(currentIndex - 1)} disabled={currentIndex <= 0}>
        <Image
          source={(currentIndex > 0 ? icArrowLeftCircle : icArrowLeftCircleDisabled) as ImageSourcePropType}
          style={styles.icon}
        />
      </TouchableOpacity>
      <View style={styles.carouselContainer}>
        <View style={[styles.container]}>
          {data && data.length > 0 && (
            <View style={styles.genderItem}>
              <Image source={data[currentIndex]?.thumbnail as ImageSourcePropType} style={styles.image} />
              <Text style={styles.genderTitle}>{data[currentIndex]?.name}</Text>
            </View>
          )}
        </View>
      </View>
      <TouchableOpacity onPress={() => onSwitch(currentIndex + 1)} disabled={currentIndex >= data.length - 1}>
        <Image
          source={
            (currentIndex < data.length - 1 ? icArrowRightCircle : icArrowRightCircleDisabled) as ImageSourcePropType
          }
          style={styles.icon}
        />
      </TouchableOpacity>
    </View>
  )
}

export default GenderSwitch

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  carouselContainer: {
    flex: 1,
    alignItems: 'center',
  },
  genderItem: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: 120,
    height: 120,
    resizeMode: 'contain',
  },
  icon: {
    width: 30,
    height: 30,
  },
  genderTitle: {
    fontSize: 14,
    fontWeight: '700',
    marginTop: 8,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  carouselContainer: {
    flex: 1,
    alignItems: 'center',
  },
  genderItem: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: 74,
    height: 74,
    resizeMode: 'contain',
  },
  icon: {
    width: 30,
    height: 30,
  },
  genderTitle: {
    fontSize: 14,
    fontWeight: '700',
    marginTop: 8,
  },
})
