import { Platform, StyleSheet } from 'react-native'
import Colors from '../../common/constants/Colors'

export const desktopStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
    width: '100%',
  },
  needsDataContainerBlock: {
    flex: 1,
    paddingBottom: 30,
    paddingLeft: 10,
    width: '100%',
  },
  needsDataContainerBlockContent: {
    flex: 1,
    paddingBottom: 30,
    paddingTop: 30,
    paddingHorizontal: 25,
    width: '100%',
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 24,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    paddingVertical: 14,
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.mainTextColor,
  },
  inputInfoContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  individualDataContainer: {
    width: 345,
    marginLeft: 16,
    position: 'relative',
  },
  individualData: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  needsContainer: {
    padding: 25,
    paddingTop: 35,
  },
  needsContent: {
    paddingVertical: 15,
    paddingLeft: 6,
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderRadius: 16,
  },
  container: {
    borderRadius: 20,
    padding: 8,
    overflow: 'scroll',
    width: '100%',
    height: '100%',
    flex: 1,
    paddingTop: 72,
    paddingBottom: 20,
  },
  profileContainer: {
    borderRadius: 20,
    padding: 8,
    backgroundColor: '#FFF',
  },
  headerContainer: {
    backgroundColor: '#ED1B2E',
    borderRadius: 12,
    padding: 16,
  },
  headerLabel: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '700',
  },
  genderContainer: {
    marginTop: 12,
  },
  formItemContainer: {
    padding: 0,
    marginTop: 20,
  },
  formItemLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: Colors.borderColor,
    borderStyle: 'solid',
    backgroundColor: '#FAFBFF',
    borderRadius: 16,
  },
  textInputLabel: {
    fontSize: 15,
    fontWeight: '700',
    marginBottom: 8,
    color: '#2B2D42',
  },
  textInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderWidth: 0,
    borderColor: '#fff',
  },
  maritalStatusContainer: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
  itemSelect: {
    fontSize: 15,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
})

export const tabletStyles = StyleSheet.create({
  needsDataContainer: {
    flex: 1,
    paddingBottom: 20,
    marginTop: 20,
  },
  needsDataContainerBlock: {
    flex: 1,
    paddingBottom: 30,
    paddingLeft: 10,
    width: '100%',
  },
  needsDataContainerBlockContent: {
    flex: 1,
    paddingBottom: 30,
    paddingTop: 30,
    paddingHorizontal: 25,
    width: '100%',
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 18,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    paddingVertical: 14,
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.mainTextColor,
  },
  inputInfoContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  individualDataContainer: {
    width: 345,
    marginLeft: 24,
    position: 'relative',
  },
  individualData: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  needsContainer: {
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  needsContent: {
    paddingVertical: 15,
    paddingLeft: 6,
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderRadius: 16,
  },
  container: {
    borderRadius: 20,
    padding: 8,
    overflow: 'scroll',
    paddingTop: 72,
    paddingBottom: 20,
  },
  profileContainer: {
    borderRadius: 20,
    padding: 8,
    backgroundColor: '#FFF',
  },
  headerContainer: {
    backgroundColor: '#ED1B2E',
    borderRadius: 12,
    padding: 16,
  },
  headerLabel: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '700',
  },
  genderContainer: {
    marginTop: 12,
  },
  formItemContainer: {
    padding: 0,
    marginTop: 20,
  },
  formItemLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    backgroundColor: '#FAFBFF',
    borderRadius: 16,
  },
  textInputLabel: {
    fontSize: 15,
    fontWeight: '700',
    marginBottom: 8,
    color: '#2B2D42',
  },
  textInput: {
    fontSize: 16,
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderWidth: 0,
    borderColor: '#fff',
  },
  maritalStatusContainer: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
  itemSelect: {
    fontSize: 15,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
  },
})

export const mobileStyles = StyleSheet.create({
  needsDataContainerBlock: {
    flex: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    width: '100%',
    marginTop: 10,
  },
  needsDataContainerBlockContent: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 16,
    paddingHorizontal: 16,
    width: '100%',
  },
  inputBlockContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  inputBlockTitle: {
    color: Colors.mainTextColor,
    fontSize: 13,
    fontWeight: '700',
  },
  inputBlockContainerLeft: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    paddingVertical: 14,
    paddingHorizontal: 16,
    flex: 1,
    flexDirection: 'row',
  },
  inputBlockContainerRight: {
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderLeftWidth: 0,
    paddingVertical: 14,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
  },
  unit: {
    fontSize: 13,
    color: Colors.mainTextColor,
  },
  inputInfoContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  individualData: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  needsContent: {
    paddingVertical: 15,
    paddingLeft: 6,
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: Colors.borderColor,
    borderRadius: 16,
  },
  needsDataContainer: {
    paddingVertical: 0,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: '#F7F8FA',
    paddingTop: 20,
    paddingBottom: 0,
    marginBottom: 0,
    overflow: 'scroll',
  },
  individualDataContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'scroll',
    paddingBottom: 100,
    backgroundColor: Colors.background,
    marginTop: -1,
  },
  needsContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: 16,
    marginTop: 0,
    width: '100%',
    backgroundColor: Colors.background,
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 55,
  },
  stepIndicatorContainer: {
    bottom: 16,
    left: 16,
    right: 16,
  },
  stepIndicator: {
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  labelIndicator: {
    overflow: 'hidden',
    fontSize: 11,
    fontWeight: '400',
    color: '#BDBDBD',
  },
  container: {
    backgroundColor: '#fff',
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  headerContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },
  headerLabel: {
    fontSize: 13,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  genderContainer: {
    marginTop: 12,
  },
  formItemContainer: {
    paddingVertical: 12,
    marginTop: 0,
  },
  formItemLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1.5,
    borderColor: '#D7DFFF',
    borderStyle: 'solid',
    backgroundColor: '#FAFBFF',
    borderRadius: 8,
  },
  textInputLabel: {
    fontSize: 13,
    fontWeight: '700',
    marginBottom: 8,
  },
  textInput: {
    fontSize: 13,
    fontWeight: '600',
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: Platform.OS === 'android' ? 2 : 8,
    borderWidth: 0,
    borderColor: '#fff',
  },
  maritalStatusContainer: {
    paddingVertical: 20,
    paddingHorizontal: 0,
    paddingBottom: 0,
  },
  itemSelect: {
    fontSize: 13,
    fontWeight: '600',
    width: '100%',
    textAlign: 'left',
    color: '#000',
  },
})
