import React from 'react'
import { Calendar } from 'react-date-range'
import '../../assets/css/react-date-range/styles.css'
import '../../assets/css/react-date-range/theme/default.css'
import '../../assets/css/react-datepicker/react-datepicker.css'
import { Platform, View, StyleSheet } from 'react-native'
import { Dialog, FilledButton } from '../../common'
import i18n from 'i18n-js'
import * as Locale from 'date-fns/locale'
import moment from 'moment'

export type IProps = {
  open: boolean
  date?: Date
  color?: any
  locale?: any
  containerStyles?: any
  disabledPastDates?: boolean
  onChange?: (date?: Date) => void
  onCancel?: () => void
}

export function DatePickerWeb(props: IProps) {
  const [selectedDate, setSelectedDate] = React.useState<Date>()

  React.useEffect(() => {
    setSelectedDate(props.date)
  }, [props.date])

  function onChangeDate(date?: Date) {
    setSelectedDate(date)
  }
  if (Platform.OS === 'web') {
    return (
      <>
        <Dialog
          visible={props.open}
          type="default"
          showCloseIcon
          onRemove={props.onCancel}
          dialogStyle={{ maxWidth: 400, paddingTop: 40, paddingHorizontal: 16 }}
        >
          <View style={props.containerStyles}>
            <Calendar
              {...props}
              date={selectedDate}
              onChange={onChangeDate}
              disabledDay={(date: Date) => {
                if (props.disabledPastDates === true) {
                  return moment(date).isBefore(new Date())
                } else {
                  return false
                }
              }}
            />
            <FilledButton
              onPress={() => props.onChange && props.onChange(selectedDate)}
              title={i18n.t('COMMON.DONE')}
              type="small"
              buttonStyle={styles.butonStyle}
            />
          </View>
        </Dialog>
      </>
    )
  } else {
    return <View></View>
  }
}

const styles = StyleSheet.create({
  butonStyle: {
    marginTop: 16,
  },
})

export default DatePickerWeb
