import React from 'react'
import { StyleSheet, View, Text, ImageSourcePropType, Image } from 'react-native'
import moment from 'moment'
import icCalendar from '../../../assets/images/calendar-2.png'
import icProfile from '../../../assets/images/profile.png'
import ic2User from '../../../assets/images/2-user.png'

type IProps = {
  createdDate?: string
  gender?: string
  age?: number
  name?: string
  style?: Record<string, unknown>
}

function SummaryInfo({ createdDate, gender, age, name, style }: IProps) {
  function renderInfoItem(icon?: ImageSourcePropType, title?: string, value?: string, expanded?: boolean) {
    return (
      <View style={[desktopStyles.infoItemContainer, { flex: expanded ? 1 : undefined }]}>
        <Image source={icon as ImageSourcePropType} style={desktopStyles.infoItemIcon} />
        <Text style={desktopStyles.infoItemTitle}>{title}</Text>
        <Text style={desktopStyles.infoItemValue}>{value}</Text>
      </View>
    )
  }

  return (
    <View style={[desktopStyles.container, style]}>
      <Text style={desktopStyles.title}>Thông tin khách hàng</Text>
      <View style={desktopStyles.infoContainer}>
        {renderInfoItem(ic2User as ImageSourcePropType, '', `${name ?? ''}`, true)}
        {renderInfoItem(icProfile as ImageSourcePropType, 'Giới tính', gender)}
        {renderInfoItem(icProfile as ImageSourcePropType, 'Tuổi', `${age ?? ''}`)}
        {renderInfoItem(
          icCalendar as ImageSourcePropType,
          'Ngày Lập kế hoạch',
          createdDate ? moment(createdDate).format('DD/MM/YYYY') : ''
        )}
      </View>
    </View>
  )
}

export default SummaryInfo

const desktopStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 7,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 13,
    color: '#505D6F',
    fontWeight: '600',
    marginLeft: 23.39,
    marginRight: 70,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FAFBFF',
    borderTopLeftRadius: 41.5,
    borderTopRightRadius: 13.3,
    borderBottomRightRadius: 13.3,
    borderBottomLeftRadius: 41.5,
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  infoItemContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    paddingVertical: 2,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    marginRight: 6,
  },
  infoItemIcon: {
    width: 20,
    height: 20,
  },
  infoItemTitle: {
    color: '#505D6F',
    fontSize: 12.44,
    marginLeft: 14,
  },
  infoItemValue: {
    fontSize: 12.44,
    color: '#1F1F20',
    fontWeight: '600',
    marginLeft: 12,
  },
})
