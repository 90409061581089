import React, { useEffect } from 'react';
import { View, StyleSheet, Text, Image, ImageSourcePropType } from 'react-native'
import { RootStackScreenProps } from '../types'
import {
  styles as globalStyles,
  BlockAlysys,
  InputData,
  RadioButton,
  BoxFooter,
  ValidateRequiredError,
  FilledButton,
  SizedBox,
} from '../common'
import imgReadyAssets from '../assets/images/ready.png'
import imgNotReadyAssets from '../assets/images/not-ready.png'
import { useMediaQuery } from 'react-responsive'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { RootState } from '../store'
import { saveCNA } from '../slices/cnaSlice'
import { connect, ConnectedProps } from 'react-redux'
import { updateUserProtect } from '../slices/userSlice'
import Layout from '../constants/Layout';
import { renderError } from '../helper/CnaHelper'
import i18n from 'i18n-js'


const mapStateToProps = (state: RootState) => ({
  investment: state.investment.investment,
  profileInfo: state.needsCart.profileInfo,
  needsCartList: state.needsCart.needsCartList,
  userProtect: state.user.userProtect,
})

const mapDispatchToProps = {
  saveCNA,updateUserProtect
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props =  & PropsFromRedux & {
  dataBack: () => void
}



function InvestAssetScreen({ investment, needsCartList, userProtect, saveCNA,updateUserProtect }: Props) {


  const isMobileDevice = Layout.isMobileDevice
  const isLaptopDevice = Layout.isTabletDevice
  const isTabletSmallDevice = Layout.isTabletSmallDevice

  const [key, setKey] = React.useState(false)


  const data = [
    {
      value: true,
      text: 'Đã chuẩn bị',
    },
    {
      value: false,
      text: 'Chưa chuẩn bị',
    },
  ];


  useEffect(() => {
    if (userProtect?.investment) {
    reset(userProtect?.investment,{errors: true});
    }
}, [userProtect])



const {
  control,
  handleSubmit,
  setValue,
  reset,
  getValues,
  setError,
  clearErrors,
  watch,
  formState: { errors },
} = useForm({
  mode: 'all',
  defaultValues: investment,
});


function renderErrorMessage(error?: string) {
  if (error) {
    return (
      <View style={globalStyles.FloatLeft}>
        <ValidateRequiredError title={error} />
      </View>
    )
  } else {
    return <></>
  }
}




  function countTotal() {
    const data = Number(watch("money")) - Number(watch("saveMoney") || 0);
    setValue("subTotal", data);
  }




  useEffect(() => {
    if (userProtect) {
      const dataTotal = Number(watch("money")) - Number(watch("saveMoney") || 0);
      let cnaData = {
        ...userProtect,
        id: userProtect?._id,
        investment: {...userProtect?.investment,
          subTotal: dataTotal || 0,
          year: watch('year') || 0,
          saveMoney: watch("saveMoney") || 0,
          money: watch('money') || 0,
          isPrepare: watch('isPrepare') || false
        }
      }
      
      updateUserProtect({
        cnaData,
      })
    }
  
    // }
  }, [watch('subTotal'),watch('year'),watch('saveMoney'),watch('money'),watch('isPrepare')])

  if (isMobileDevice) {
    return (
      <View>
        <BlockAlysys
          // title="Đầu tư gia tăng tài sản"
          borderColor="#FC573B"
          child={
            <View>
              <Controller
                control={control}
                 rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title='Mục tiêu đầu tư bạn cần trong tương lai là 
                    bao nhiêu tiền?'
                    helpText='Triệu đồng'
                    type='column'
                    placeholder='Nhập'
                    value={value !== 0 ? value : ""}
                    onBlur={onBlur}
                    onChangeText={value => {
                      onChange(value);
                      if (Number(value) > watch("saveMoney") &&  watch("saveMoney")){
                        clearErrors("saveMoney")
                      }
                      countTotal();
                    }}
                    error={errors.money && renderErrorMessage(errors.money.message)}
                  />
                )}
                name="money"
                defaultValue=""
              />

            <Controller
                control={control}
                 rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <InputData
                    title='Trong vòng'
                    helpText="Năm"
allowNagitive
                    type='column'
                    placeholder='Nhập'
                    value={value !== 0 ? value : ""}
                    onBlur={onBlur}
                    onChangeText={value => {
                      onChange(value);
                      if (Number(value) > watch("saveMoney") &&  watch("saveMoney")){
                        clearErrors("saveMoney")
                      }
                      countTotal();
                    }}
                    error={errors.year && renderErrorMessage(errors.year.message)}
                  />
                )}
                name="year"
              />


              <Controller
                control={control}
                rules={{}}
                render={({ onChange, value }) => (
                  <RadioButton
                    title="Bạn đã chuẩn bị cho nhu cầu này chưa?"
                    data={data}
                    dafaultValue={value}
                    margin
                    error={errors.isPrepare && renderErrorMessage(errors?.isPrepare?.message)}
                    onChange={value => {
                      onChange(value)
                      setValue("saveMoney", 0);
                      clearErrors("saveMoney");
                      setValue("subTotal", Number(watch("money")));
                      setKey(value)
                    }}
                  />
                )}
                name="isPrepare"
                defaultValue={false}
              />


                  <View>
                  {
                      watch(`isPrepare`) ?  
                      <Image source={imgReadyAssets as ImageSourcePropType} style={mobileStyles.icon} />
                      :<Image source={imgNotReadyAssets as ImageSourcePropType} style={mobileStyles.icon} />
                  }
                  <View style={{display: watch(`isPrepare`) ? 'flex' : 'none'}}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: watch(`isPrepare`) === true? true : false,
                        message: i18n.t('VALIDATE.REQUIRED_DATA'),
                      },
                      min: {
                        value: 0.001,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                      },
                      max: {
                        value: Number(watch("money")) - 0.001 ,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_INVEST'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <InputData
                        title='Số tiền bạn đã chuẩn bị được là bao nhiêu?'
                        helpText='Triệu đồng'
                        type='column'
                        placeholder='Nhập'
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                          if (Number(value) < watch("money")){
                            clearErrors("money")
                        }
                          countTotal();
                        }}
                        error={errors.saveMoney && renderErrorMessage(errors?.saveMoney?.message)}
                      />
                    )}
                    name="saveMoney"
                    defaultValue=""
                  />

                  <Controller
                    control={control}
                     rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                    render={({ value }) => (
                      <BoxFooter
                        title='SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:'
                        backgroundColorBox='#FFE6E2'
                        titleStyle={{ color: '#FC573B' }}
                        child={<Text>{isNaN(value as any) === false &&  value && value > 0  ? value : 0}</Text>}
                      />
                    )}
                    name="subTotal"
                    defaultValue=""
                  />
                  </View>
                </View>



            </View>
          }
        />
      </View>
    )
  }

  if(isTabletSmallDevice) {
    return (
      <View  style={{flex: 1,position: 'relative'}}>  
      <BlockAlysys
          title="Đầu tư gia tăng tài sản"
          borderColor="#FC573B"
          child={
            <View>
              <View>
                <View><Text style={desktopStyles.titleTablet}>Mục tiêu đầu tư bạn cần trong tương lai là bao nhiêu tiền?</Text></View>
  
                <View style={desktopStyles.boxEduChild}>
                  <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <View>
                        <InputData
                          helpText='Triệu đồng'
                          type='row'
                          placeholder='Nhập'
                          titleStyle={{width: 0,minWidth :0 ,margin : 0}}
                          wrapHelpTextContainer={{width: '100%'}}
                          stylesInput={{ marginBottom: 0, marginRight: 10 }}
                          value={value !== 0 ? value : ""}
                          onBlur={onBlur}
                          onChangeText={value => {
                            onChange(value);
                            if (Number(value) > watch("saveMoney") &&  watch(`isPrepare`) === true   ){
                                clearErrors("saveMoney")
                            }else{
                                setError("saveMoney", {message: "Vui lòng nhập số tiền đã chuẩn bị nhỏ hơn mục tiêu đầu tư"})
                            }
                            countTotal();
                          }}
                        />
                        {errors?.money && renderErrorMessage(errors.money.message)}
                      </View>
                    )}
                    name={`money`}
                    defaultValue=""
                     rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                  />
                </View>
              </View>
  
               <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <View>
                        <InputData
                          title='Trong vòng'
                          helpText="Năm"
allowNagitive
                          type='column'
                          placeholder='Nhập'
                          stylesInput={{ width: 'auto', marginBottom: 0, }}
                          value={value !== 0 ? value : ""}
                          onBlur={onBlur}
                          onChangeText={value => {
                            onChange(value);
                          }}
                        />
                        {errors?.year && renderErrorMessage(errors.year.message)}
                      </View>
                    )}
                    name={`year`}
                    defaultValue=""
                     rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                  },
                }}
                  />
  
              <Controller
                control={control}
                rules={{  }}
                render={({ onChange, value }) => (
                  <View style={{marginTop: 20 }}>
                  <RadioButton
                    title="Bạn đã chuẩn bị cho nhu cầu này chưa?"
                    data={data}
                    margin
                    dafaultValue={value}
                    error={errors.isPrepare && renderErrorMessage(errors?.isPrepare?.message)}
                    onChange={value => {
                      onChange(value)
                      setValue("saveMoney", 0);
                      clearErrors("saveMoney");
                      setValue("subTotal", Number(watch("money")));
                      setKey(value)
                    }}
                  />
                  </View>
                )}
                name="isPrepare"
                defaultValue={false}
              />
  
  
                 <View>
                  {
                      watch(`isPrepare`) ?  
                      <Image source={imgReadyAssets as ImageSourcePropType} style={desktopStyles.imageTablet} />
                      :<Image source={imgNotReadyAssets as ImageSourcePropType} style={desktopStyles.imageTablet} />
                  }
                  <View style={{display: watch(`isPrepare`) ? 'flex' : 'none'}}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: watch(`isPrepare`) === true? true : false,
                        message: i18n.t('VALIDATE.REQUIRED_DATA'),
                      },
                      min: {
                        value: 0.001,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                      },
                      max: {
                        value: Number(watch("money")) - 0.001 ,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_INVEST'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <InputData
                        title='Số tiền bạn đã chuẩn bị được là bao nhiêu?'
                        helpText='Triệu đồng'
                        type='column'
                        placeholder='Nhập'
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                          if (Number(value) < watch("money")){
                            clearErrors("money")
                        }
                          countTotal();
                        }}
                        error={errors.saveMoney && renderErrorMessage(errors?.saveMoney?.message)}
                      />
                    )}
                    name="saveMoney"
                    defaultValue=""
                  />

                  <Controller
                    control={control}
                     rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                    render={({ value }) => (
                      <BoxFooter
                        title='SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:'
                        backgroundColorBox='#FFE6E2'
                        titleStyle={{ color: '#FC573B' }}
                        child={<Text>{isNaN(value as any) === false &&  value && value > 0  ? value : 0}</Text>}
                      />
                    )}
                    name="subTotal"
                    defaultValue=""
                  />
                  </View>
                </View>
            </View>
          }
        />
        <SizedBox width={24} />
        
        </View>
      )
  }

  if (isLaptopDevice) {
    return (
      <View  style={{flex: 1,position: 'relative'}}>  
    <BlockAlysys
        title="Đầu tư gia tăng tài sản"
        borderColor="#FC573B"
        child={
          <View>
            <View>

              <View><Text style={desktopStyles.titleTablet}>Mục tiêu đầu tư bạn cần trong tương lai là bao nhiêu tiền?</Text></View>

              <View style={desktopStyles.boxEduChild}>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <View>
                      <InputData
                        helpText='Triệu đồng'
                        type='row'
                        placeholder='Nhập'
                        titleStyle={{width: 0,minWidth :0 ,margin : 0}}
                        wrapHelpTextContainer={{width: '100%'}}
                        widthInput={130}
                        stylesInput={{ marginBottom: 0, marginRight: 30 }}
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                          if (Number(value) > watch("saveMoney") &&  watch(`isPrepare`) === true   ){
                              clearErrors("saveMoney")
                          }else{
                              setError("saveMoney", {message: "Vui lòng nhập số tiền đã chuẩn bị nhỏ hơn mục tiêu đầu tư"})
                          }
                          countTotal();
                        }}
                      />
                      {errors?.money && renderErrorMessage(errors.money.message)}
                    </View>
                  )}
                  name={`money`}
                  defaultValue=""
                   rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                />
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <View>
                      <InputData
                        title='Trong vòng'
                        helpText="Năm"
allowNagitive
                        type='row'
                        widthInput={90}
                        titleStyle={{minWidth: 75}}
                        placeholder='Nhập'
                        stylesInput={{ width: 'auto', marginBottom: 0, marginRight: 30 }}
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                        }}
                      />
                      {errors?.year && renderErrorMessage(errors.year.message)}
                    </View>
                  )}
                  name={`year`}
                  defaultValue=""
                   rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                  },
                }}
                />
              </View>

            </View>

            <Controller
              control={control}
              rules={{  }}
              render={({ onChange, value }) => (
                <RadioButton
                  title="Bạn đã chuẩn bị cho nhu cầu này chưa?"
                  data={data}
                  margin
                  dafaultValue={value}
                  error={errors.isPrepare && renderErrorMessage(errors?.isPrepare?.message)}
                  onChange={value => {
                    onChange(value)
                    setValue("saveMoney", 0);
                    clearErrors("saveMoney");
                    setValue("subTotal", Number(watch("money")));
                    setKey(value)
                  }}
                />
              )}
              name="isPrepare"
              defaultValue={false}
            />


          
                <View>
                  {
                      watch(`isPrepare`) ?  
                      <Image source={imgReadyAssets as ImageSourcePropType} style={desktopStyles.imageTablet} />
                      :<Image source={imgNotReadyAssets as ImageSourcePropType} style={desktopStyles.imageTablet} />
                  }
                  <View style={{display: watch(`isPrepare`) ? 'flex' : 'none'}}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: watch(`isPrepare`) === true? true : false,
                        message: i18n.t('VALIDATE.REQUIRED_DATA'),
                      },
                      min: {
                        value: 0.001,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                      },
                      max: {
                        value: Number(watch("money")) - 0.001 ,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_INVEST'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <InputData
                        title='Số tiền bạn đã chuẩn bị được là bao nhiêu?'
                        helpText='Triệu đồng'
                        type='column'
                        placeholder='Nhập'
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                          if (Number(value) < watch("money")){
                            clearErrors("money")
                        }
                          countTotal();
                        }}
                        error={errors.saveMoney && renderErrorMessage(errors?.saveMoney?.message)}
                      />
                    )}
                    name="saveMoney"
                    defaultValue=""
                  />

                  <Controller
                    control={control}
                     rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                    render={({ value }) => (
                      <BoxFooter
                        title='SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:'
                        backgroundColorBox='#FFE6E2'
                        titleStyle={{ color: '#FC573B' }}
                        child={<Text>{isNaN(value as any) === false &&  value && value > 0  ? value : 0}</Text>}
                      />
                    )}
                    name="subTotal"
                    defaultValue=""
                  />
                  </View>
                </View>
          </View>
        }
      />
      <SizedBox width={24} />
      
      </View>
  )
  }
  return (
      <View  style={{flex: 1,position: 'relative'}}>  
    <BlockAlysys
        title="Đầu tư gia tăng tài sản"
        borderColor="#FC573B"
        child={
          <View>
            <View>
              <View><Text style={desktopStyles.title}>Mục tiêu đầu tư bạn cần trong tương lai là bao nhiêu tiền?</Text></View>

              <View style={desktopStyles.boxEduChild}>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <View>
                      <InputData
                        helpText='Triệu đồng'
                        type='row'
                        placeholder='Nhập'
                        titleStyle={{width: 0,minWidth :0 ,margin : 0}}
                        wrapHelpTextContainer={{width: '100%'}}
                        stylesInput={{ marginBottom: 0, marginRight: 30 }}
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                          if (Number(value) > watch("saveMoney") &&  watch(`isPrepare`) === true   ){
                              clearErrors("saveMoney")
                          }else{
                              setError("saveMoney", {message: "Vui lòng nhập số tiền đã chuẩn bị nhỏ hơn mục tiêu đầu tư"})
                          }
                          countTotal();
                        }}
                      />
                      {errors?.money && renderErrorMessage(errors.money.message)}
                    </View>
                  )}
                  name={`money`}
                  defaultValue=""
                   rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                />
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <View>
                      <InputData
                        title='Trong vòng'
                        helpText="Năm"
allowNagitive
                        type='row'
                        placeholder='Nhập'
                        stylesInput={{ width: 'auto', marginBottom: 0, marginRight: 30 }}
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                        }}
                      />
                      {errors?.year && renderErrorMessage(errors.year.message)}
                    </View>
                  )}
                  name={`year`}
                  defaultValue=""
                   rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_YEAR'),
                  },
                }}
                />
              </View>

            </View>

            <Controller
              control={control}
              rules={{ }}
              render={({ onChange, value }) => (
                <RadioButton
                  title="Bạn đã chuẩn bị cho nhu cầu này chưa?"
                  data={data}
                  margin
                  dafaultValue={value}
                  error={errors.isPrepare && renderErrorMessage(errors?.isPrepare?.message)}
                  onChange={value => {
                    onChange(value)
                    setValue("saveMoney", 0);
                    clearErrors("saveMoney");
                    setValue("subTotal", Number(watch("money")));
                    setKey(value)
                  }}
                />
              )}
              name="isPrepare"
              defaultValue={false}
            />
         
                <View>
                  {
                      watch(`isPrepare`) ?  
                      <Image source={imgReadyAssets as ImageSourcePropType} style={desktopStyles.icon} />
                      :<Image source={imgNotReadyAssets as ImageSourcePropType} style={desktopStyles.icon} />
                  }
                  <View style={{display: watch(`isPrepare`) ? 'flex' : 'none'}}>
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: watch(`isPrepare`) === true? true : false,
                        message: i18n.t('VALIDATE.REQUIRED_DATA'),
                      },
                      min: {
                        value: 0.001,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                      },
                      max: {
                        value: Number(watch("money")) - 0.001 ,
                        message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY_INVEST'),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <InputData
                        title='Số tiền bạn đã chuẩn bị được là bao nhiêu?'
                        helpText='Triệu đồng'
                        type='column'
                        placeholder='Nhập'
                        value={value !== 0 ? value : ""}
                        onBlur={onBlur}
                        onChangeText={value => {
                          onChange(value);
                          if (Number(value) < watch("money")){
                            clearErrors("money")
                        }
                          countTotal();
                        }}
                        error={errors.saveMoney && renderErrorMessage(errors?.saveMoney?.message)}
                      />
                    )}
                    name="saveMoney"
                    defaultValue=""
                  />

                  <Controller
                    control={control}
                     rules={{
                  required: {
                    value: true,
                    message: i18n.t('VALIDATE.REQUIRED_DATA'),
                  },
                  min: {
                    value: 0.001,
                    message: i18n.t('VALIDATE.REQUIRED_DATA_MONEY'),
                  },
                }}
                    render={({ value }) => (
                      <BoxFooter
                        title='SỐ TIỀN THU NHẬP BẠN CẦN BẢO VỆ LÀ:'
                        backgroundColorBox='#FFE6E2'
                        titleStyle={{ color: '#FC573B' }}
                        child={<Text>{isNaN(value as any) === false &&  value && value > 0  ? value : 0}</Text>}
                      />
                    )}
                    name="subTotal"
                    defaultValue=""
                  />
                  </View>
                </View>
                
            
          </View>
        }
      />
      <SizedBox width={24} />
      
      </View>
  )
}
export default connector(InvestAssetScreen)


const desktopStyles = StyleSheet.create({
  icon: {
    height: 150,
    width: 400,
    marginBottom: 20
  },
  imageTablet: {
    height: 100,
    width: 290,
    marginBottom: 20
  },
  boxEdu: {
    flexDirection: 'column',
    paddingHorizontal: 25,
    paddingVertical: 30,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#D7DFFF'
  },
  title: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 20,
    fontSize: 16
  },
  titleTablet: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 20,
    fontSize: 13.2667
  },
  boxEduChild: {
    flexDirection: 'row',
    marginBottom: 15
  },
  boxEduText: {
    fontWeight: '600',
    color: '#373737',
    paddingBottom: 20,
    fontSize: 16
  }
})

const mobileStyles = StyleSheet.create({
  icon: {
    width: '100%',
    maxWidth: 300,
    resizeMode: 'contain',
    marginBottom: 15,
    height: 100
  },
  continueButton: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 30,
  },
})
