import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Input } from 'react-native-elements'

export type InputItem = {
  placeholder: string
  leftIconContainerStyle?: object
  value?: any
  defaultValue?: any
  label?: string
  leftIcon?: any
  defaultInputStyle?: object
  containerStyle?: object
  placeholderTextColor?: string
  defaultInputContainer?: object
  secureTextEntry?: boolean
  onChange?: (value: any) => void
  onSubmitEditing?: (value: any) => void
}

export function TextInput({
  placeholder,
  label,
  value,
  defaultValue,
  defaultInputStyle,
  containerStyle,
  defaultInputContainer,
  secureTextEntry,
  leftIconContainerStyle,
  leftIcon,
  placeholderTextColor,
  onChange,
  onSubmitEditing,
}: InputItem) {
  return (
    <Input
      placeholder={placeholder}
      leftIcon={leftIcon}
      value={value}
      defaultValue={defaultValue}
      leftIconContainerStyle={[stylesInput.defaultLeftIconContainer, leftIconContainerStyle]}
      label={label}
      containerStyle={containerStyle}
      inputStyle={[stylesInput.defaultInputStyle, defaultInputStyle]}
      placeholderTextColor={placeholderTextColor ? placeholderTextColor : '#505D6F'}
      labelStyle={stylesInput.defaultLabelStyle}
      secureTextEntry={secureTextEntry}
      errorStyle={stylesInput.errorStyle}
      renderErrorMessage={false}
      onChangeText={onChange}
      onSubmitEditing={onSubmitEditing}
      keyboardType={'numeric'}
      inputContainerStyle={[stylesInput.defaultInputContainer, defaultInputContainer]}
      autoCompleteType={undefined}
    />
  )
}

export default TextInput

const stylesInput = StyleSheet.create({
  defaultLabelStyle: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
  defaultLeftIconContainer: {
    paddingHorizontal: 10,
  },
  defaultInputStyle: {
    fontSize: 15,
    color: '#FF9900',
    width: '100%',
    fontWeight: 'bold',
  },
  errorStyle: {
    marginBottom: 0,
  },
  defaultInputContainer: {
    borderRadius: 10,
    height: 34,
    backgroundColor: '#FAFBFF',
    borderColor: '#D7DFFF',
    paddingHorizontal: 17,
    borderWidth: 1,
  },
})
