import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import CNAListScreen from '../CNAListScreen'
import ViewAllCNAScreen from '../ViewAllCNAScreen'
import imgHomeRed from '../../assets/images/home-red.png'
import imgHomeGrey from '../../assets/images/home-grey.png'
import imgDocumentRed from '../../assets/images/document-red.png'
import imgDocumentGrey from '../../assets/images/document-grey.png'
import imgNewCna from '../../assets/images/new-cna.png'
import imgNoti from '../../assets/images/noti.png'
import imgUser from '../../assets/images/user.png'
import {
  ImageSourcePropType,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Text,
  StyleSheet,
  View,
  Animated,
  ImageBackground,
  Platform,
  Image,
} from 'react-native'
import { Dialog, OutlinedButton, Spacer, styles as globalStyles } from '../../common'
import { RootStackScreenProps } from '../../types'
import i18n from 'i18n-js'
import imgMobileHeaderBg from '../../assets/images/mobile_header_bg.png'
import imgAvatarMask from '../../assets/images/avatar.png'
import { RootState } from '../../store'
import { connect, ConnectedProps } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { clearCart } from '../../slices/needsCartSlice'
import { clearUserInfo } from '../../slices/userSlice'
import { createNewLocalCNA, setCnaReview, setCnaMonthList, setCnaTodayList } from '../../slices/cnaSlice'
import { createUserProtect, createUserProtectOriginal } from '../../slices/userSlice'
import Layout from '../../constants/Layout'

const Tab = createBottomTabNavigator()

const mapStateToProps = (state: RootState) => ({
  userInfo: state?.authen?.userInfo,
})

const mapDispatchToProps = {
  clearCart,
  clearUserInfo,
  createNewLocalCNA,
  createUserProtect,
  createUserProtectOriginal,
  setCnaReview,
  setCnaMonthList,
  setCnaTodayList,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RootStackScreenProps<'MobileHome'> & PropsFromRedux & {}

function MobileHomeScreen({
  navigation,
  userInfo,
  createNewLocalCNA,
  createUserProtect,
  createUserProtectOriginal,
  setCnaReview,
  setCnaMonthList,
  setCnaTodayList,
}: Props) {
  const isMobileDevice = Layout.isMobileDevice
  const [showDevelopingDialog, setShowDevelopingDialog] = React.useState(false)
  const [showMenu, setShowMenu] = React.useState(false)
  const [showConfirmSignOutDialog, setShowConfirmSignOutDialog] = React.useState(false)
  const [showBlurBg, setShowBlurBg] = React.useState(false)
  const transformAnim = React.useRef(new Animated.Value(-335)).current
  const opacityAnim = React.useRef(new Animated.Value(0)).current
  const sideMenuOpacityAnim = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    if (showMenu) {
      Animated.timing(transformAnim, {
        toValue: -1,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      setShowBlurBg(true)
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 400,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(sideMenuOpacityAnim, {
        toValue: 1,
        duration: 400,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
    } else {
      Animated.timing(transformAnim, {
        toValue: -335,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
      Animated.timing(opacityAnim, {
        toValue: -1,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start(() => {
        setShowBlurBg(false)
      })
      Animated.timing(sideMenuOpacityAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      } as Animated.TimingAnimationConfig).start()
    }
  }, [opacityAnim, showMenu, transformAnim])

  function renderModals() {
    return (
      <>
        {/* Show developing dialog */}
        <Dialog
          type="default"
          visible={showDevelopingDialog}
          showCloseIcon={true}
          contentStyle={{ padding: 20 }}
          dialogStyle={{ margin: 20, borderRadius: 12, maxWidth: 522, minWidth: isMobileDevice ? 300 : 560 }}
          onRemove={() => setShowDevelopingDialog(false)}
        >
          <View style={[globalStyles.BackgroundTransparent, globalStyles.DisplayFlexRow, globalStyles.Width100]}>
            <Text style={[globalStyles.TextCommon, globalStyles.Width100, globalStyles.TextAlignCenter]}>{`${i18n.t(
              'COMMON.FEATURE_IS_IN_PROGRESS'
            )}`}</Text>
          </View>
        </Dialog>
        {/* Show confirm sign out dialog */}
        <Dialog
          type="confirm"
          dataDelete={{
            title: i18n.t('COMMON.SIGN_OUT'),
            description: i18n.t('MESS.CONFIRM_SIGN_OUT'),
          }}
          visible={showConfirmSignOutDialog}
          contentStyle={{ padding: 0, paddingTop: 0 }}
          dialogStyle={{ margin: 20, borderRadius: 12, padding: 0 }}
          okText={i18n.t('COMMON.NO')}
          cancelText={i18n.t('COMMON.ACCEPT')}
          onOk={() => {
            setShowConfirmSignOutDialog(false)
          }}
          onCancel={() => {
            onLogOut()
          }}
        ></Dialog>
      </>
    )
  }

  async function onLogOut() {
    setShowMenu(false)
    setShowConfirmSignOutDialog(false)
    clearCart({})
    setCnaReview({}), setCnaMonthList({}), setCnaTodayList({}), clearUserInfo()
    await clearUserData()
    navigation.reset({
      index: 1,
      routes: [
        {
          key: 'LogIn',
          name: 'LogIn',
        },
      ],
    } as any)
  }

  async function clearUserData() {
    try {
      await AsyncStorage.setItem('accessToken', '')
      await AsyncStorage.setItem('refreshToken', '')
    } catch (e) {
      console.error(e)
    }
  }

  function onCreateNewCNA() {
    clearCart({})
    createNewLocalCNA({})
    createUserProtect({})
    createUserProtectOriginal({})
    navigation.push('NeedsCart')
  }

  function renderMenu() {
    return (
      <View style={[styles.menu, { width: showMenu ? '100%' : 0 }]}>
        {showBlurBg && (
          <Animated.View style={[styles.blurBg, { opacity: opacityAnim }]}>
            <TouchableWithoutFeedback onPress={() => setShowMenu(false)} style={styles.blurBg}>
              <View style={styles.blurBg}></View>
            </TouchableWithoutFeedback>
          </Animated.View>
        )}
        <Animated.View style={[styles.menuContainer, { left: transformAnim, opacity: sideMenuOpacityAnim }]}>
          <View style={[styles.appBarContainer]}>
            <ImageBackground source={imgMobileHeaderBg as ImageSourcePropType} style={styles.appBarImageBg}>
              {userInfo?.profilePicture ? (
                <Image source={{ uri: userInfo?.profilePicture }} style={styles.avatar} />
              ) : (
                <Image source={imgAvatarMask} style={styles.avatar} />
              )}
              <View style={{ marginLeft: 20 }}>
                <Text style={{ color: '#FFF', fontSize: 16 }}>Welcome,</Text>
                <Text style={{ color: '#FFF', fontSize: 18, fontWeight: 'bold', marginTop: 8 }}>
                  ID {userInfo?.agentCode}
                </Text>
              </View>
            </ImageBackground>
          </View>
          <Spacer />
          <OutlinedButton
            title={i18n.t('COMMON.SIGN_OUT').toUpperCase()}
            buttonStyle={styles.signOutButton}
            onPress={() => setShowConfirmSignOutDialog(true)}
          />
        </Animated.View>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <Tab.Navigator
        initialRouteName="Home"
        screenOptions={{
          tabBarActiveTintColor: '#e91e63',
          tabBarStyle: styles.tabBarStyle,
          tabBarLabelStyle: styles.tabBarLabelStyle,
        }}
      >
        <Tab.Screen
          name="Home"
          component={CNAListScreen}
          options={({ route, navigation }) => ({
            headerShown: false,
            tabBarButton: (props) => (
              <TouchableOpacity onPress={() => navigation.navigate('Home')} style={styles.tabBarButton}>
                <Image
                  source={(navigation.isFocused() ? imgHomeRed : imgHomeGrey) as ImageSourcePropType}
                  style={globalStyles.Icon22}
                />
                <Text style={navigation.isFocused() ? styles.tabLabelStyleHighlight : styles.tabLabelStyle}>
                  {i18n.t('MOBILE_HOME.HOME')}
                </Text>
              </TouchableOpacity>
            ),
          })}
        />
        <Tab.Screen
          name="List"
          component={ViewAllCNAScreen}
          options={({ route, navigation }) => ({
            headerShown: false,
            tabBarButton: (props) => (
              <TouchableOpacity onPress={() => navigation.navigate('List')} style={styles.tabBarButton}>
                <Image
                  source={(navigation.isFocused() ? imgDocumentRed : imgDocumentGrey) as ImageSourcePropType}
                  style={globalStyles.Icon22}
                />
                <Text style={navigation.isFocused() ? styles.tabLabelStyleHighlight : styles.tabLabelStyle}>
                  {i18n.t('MOBILE_HOME.LIST')}
                </Text>
              </TouchableOpacity>
            ),
          })}
        />
        <Tab.Screen
          name="CreateCna"
          component={CNAListScreen}
          options={({ navigation }) => ({
            tabBarButton: (props) => (
              <TouchableOpacity {...props} onPress={onCreateNewCNA} style={{ marginTop: 0 }}>
                <Image
                  source={imgNewCna as ImageSourcePropType}
                  style={{
                    width: 48,
                    height: 48,
                  }}
                />
              </TouchableOpacity>
            ),
          })}
        />
        <Tab.Screen
          name="Noti"
          component={ViewAllCNAScreen}
          options={({ navigation }) => ({
            tabBarButton: (props) => (
              <TouchableOpacity onPress={() => setShowDevelopingDialog(true)} style={styles.tabBarButton}>
                <Image source={imgNoti} style={globalStyles.Icon22} />
                <Text style={styles.tabLabelStyle}>{i18n.t('MOBILE_HOME.NOTI')}</Text>
              </TouchableOpacity>
            ),
          })}
        />
        <Tab.Screen
          name="Profile"
          component={ViewAllCNAScreen}
          options={({ navigation }) => ({
            tabBarButton: (props) => (
              <TouchableOpacity
                onPress={() => {
                  setShowMenu(!showMenu)
                }}
                style={styles.tabBarButton}
              >
                <Image source={imgUser} style={globalStyles.Icon22} />
                <Text style={styles.tabLabelStyle}>{i18n.t('MOBILE_HOME.PROFILE')}</Text>
              </TouchableOpacity>
            ),
          })}
        />
      </Tab.Navigator>
      {renderModals()}
      {renderMenu()}
    </View>
  )
}

export default connector(MobileHomeScreen)

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#D62828',
  },
  tabBarStyle: {
    backgroundColor: '#FFF',
    borderTopWidth: 0,
    height: 56,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
    zIndex: 0,
  },
  tabBarLabelStyle: {
    fontSize: 13,
    marginBottom: Platform.OS === 'web' ? 15 : -10,
  },
  tabBarButton: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    paddingTop: 4,
  },
  tabLabelStyle: {
    fontSize: 10,
    color: 'rgb(124, 124, 125);',
    marginTop: 4,
  },
  tabLabelStyleHighlight: {
    fontSize: 10,
    color: 'rgb(233, 30, 99)',
    marginTop: 4,
  },
  menu: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 2000000,
  },
  menuContainer: {
    position: 'absolute',
    left: -335,
    top: 0,
    zIndex: 1002,
    bottom: 0,
    width: 331,
    backgroundColor: '#FFF',
  },
  blurBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
    zIndex: 1001,
  },
  appBarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: 331,
    height: 131,
    zIndex: 1000,
    flexDirection: 'column',
  },
  appBarImageBg: {
    resizeMode: 'cover',
    width: 331.5,
    height: 131,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 40,
    paddingTop: 60,
  },
  avatar: {
    width: 52,
    height: 52,
    resizeMode: 'cover',
    borderRadius: 26,
  },
  menuContent: {
    flex: 1,
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  signOutButton: {
    marginHorizontal: 40,
    marginBottom: 100,
  },
})
