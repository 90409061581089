import Colors from '../../../common/constants/Colors'
import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { SizedBox } from '../../../common'

type IProps = {}

function ReferenceBenefit({}: IProps) {
  return (
    <View style={[desktopStyles.container]}>
      <Text>Vui lòng tham khảo chi tiết quyền lợi tại</Text>
      <SizedBox width={20} />
      <a
        href="https://www.prudential.com.vn/vi/tai-lieu-va-bieu-mau-tham-khao/"
        target="_blank"
        className="hyper-link-container"
      >
        <div className="hyper-link-button">
          <span className="hyper-link-label">Tham khảo</span>
        </div>
      </a>
    </View>
  )
}

export default ReferenceBenefit

const desktopStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 8,
    paddingBottom: 3,
  },
})
